export const durationsList = [
    { name: "3", value: "3" },
    { name: "7", value: "7" },
    { name: "14", value: "14" },
    { name: "21", value: "21" },
    { name: "30", value: "30" }

    // { name: "3", value: "3" },
    // { name: "6", value: "6" },
    // { name: "9", value: "9" },
    // { name: "12", value: "12" },
    // { name: "15", value: "15" },
    // { name: "18", value: "18" },
    // { name: "21", value: "21" },
    // { name: "24", value: "24" },
    // { name: "27", value: "27" },
    // { name: "30", value: "30" }
];