import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

interface Props {
    keywordTargetingSuggestionData?: any;
    addedKeywordSuggestionData?: (data: any) => void;
    addedAiFlowKeywordSugestionData?: (data: any) => void;
    selectedTabAddedKeywordSuggestionData?: (data: any) => void;
    canceledData?: any;
    selectedProductTabData?: any
    addedGroupFlowKeywordSugestionData?: (data: any) => void;
    selectedAllGroupData?: any;
    removeAllIndex?: any;
    addAllKeyWordTargeting?: any;
    filterRemovedData?: any;
    addedProductsAsinData?: any;
    customBidValue?: any;
    bidChangeValue?: any;
    selectedBidValue?: any;
    addedKeywordSuggestionDataObj?: any;
}

export const KeywordTargetingCards = (props: Props) => {
    const [keywordSuggestionData, setKeywordSuggestionData] = useState<any>([]);
    const [addSuggestionsData, setAddSuggestionsData] = useState<any>([]);
    const [aiFlowAddSuggestionData, setAiFlowAddSuggestionData] = useState<any>([]);
    const [selectedTabAiFlowAddedSuggestionData, setSelectedTabAiFlowAddedSuggestionData] = useState<any>([])
    let { type } = useParams<{ type: any }>();
    const campaignCreationType: any = type;

    const getSuggestionChecking = () => {
        for (let i = 0; i < keywordSuggestionData?.length; i++) {
            keywordSuggestionData[i]?.bidInfo?.forEach((element: any) => {
                const isExist = props?.filterRemovedData?.find((data: any) => keywordSuggestionData[i].keyword === data.keywordText && element.matchType === data.keyword_matchType);
                if (isExist) {
                    element.btn_status = true;
                }
            });
        }
    }

    useEffect(() => {
        if (props?.addedKeywordSuggestionDataObj) {

            setAddSuggestionsData([...props?.addedKeywordSuggestionDataObj]);
            setAiFlowAddSuggestionData([...props?.addedKeywordSuggestionDataObj]);
        }
    }, [props?.addedKeywordSuggestionDataObj])
    useEffect(() => {

        if (props.canceledData) {
            for (let i = 0; i < keywordSuggestionData?.length; i++) {
                if (keywordSuggestionData[i]?.keyword === props?.canceledData?.suggestionData) {
                    for (let j = 0; j < keywordSuggestionData[i]?.bidInfo?.length; j++) {
                        if (keywordSuggestionData[i]?.bidInfo[j]?.matchType === props.canceledData.bidData.matchType) {
                            keywordSuggestionData[i].bidInfo[j].btn_status = false;
                            preparedAIFlowSuggestionData(props.canceledData.recId);
                        }
                    }
                }
            }
            setKeywordSuggestionData(props?.keywordTargetingSuggestionData);
            for (let i = 0; i < addSuggestionsData?.length; i++) {
                if (addSuggestionsData[i]?.recId === props?.canceledData?.recId) {
                    if (addSuggestionsData[i]?.bidData.matchType === props.canceledData.bidData.matchType) {
                        addSuggestionsData.splice(i, 1);
                    }
                }
            }
            setAddSuggestionsData([...addSuggestionsData]);

        } else {
            setKeywordSuggestionData(props?.keywordTargetingSuggestionData);
        }
        if (props?.removeAllIndex) {
            for (let i = 0; i < keywordSuggestionData?.length; i++) {
                for (let j = 0; j < keywordSuggestionData[i].bidInfo?.length; j++) {
                    keywordSuggestionData[i].bidInfo[j].btn_status = false;
                }
            }
            setAddSuggestionsData([...addSuggestionsData]);
            setKeywordSuggestionData([...keywordSuggestionData]);
        }
    }, [props.canceledData, props?.removeAllIndex])


    useEffect(() => {
        if (props.addAllKeyWordTargeting) {
            for (let i = 0; i < keywordSuggestionData?.length; i++) {
                for (let j = 0; j < keywordSuggestionData[i].bidInfo?.length; j++) {
                    keywordSuggestionData[i].bidInfo[j].btn_status = true;
                }
            }
            if (props?.addedGroupFlowKeywordSugestionData) {
                props?.addedGroupFlowKeywordSugestionData(keywordSuggestionData);
            }
            setKeywordSuggestionData([...props?.keywordTargetingSuggestionData]);
        }
    }, [props.addAllKeyWordTargeting, getSuggestionChecking()])


    useEffect(() => {
        if (props.filterRemovedData?.length > 0) {
            setAiFlowAddSuggestionData([...props.filterRemovedData]);
        }
    }, [props.filterRemovedData])

    useEffect(() => {
        if (props?.addedProductsAsinData <= 0) {
            setKeywordSuggestionData([]);
        }
    }, [props?.addedProductsAsinData])

    const preparedAIFlowSuggestionData = (id: any) => {
        const preparedData = aiFlowAddSuggestionData.filter((el: any) => el.recId !== id);
        setAiFlowAddSuggestionData([...preparedData]);
    }

    const onClickSuggestionAdd = (e: any, bidData: any, suggestionData: any, index: number, i: number) => {
        const status = suggestionData.bidInfo[index].btn_status;
        suggestionData.bidInfo[index].btn_status = true;
        suggestionData.bidInfo[index].bid_type = props?.selectedBidValue;
        if (campaignCreationType === "AI") {
            if (!status) {
                aiFlowAddSuggestionData.push({
                    bidData: bidData,
                    recId: suggestionData.recId,
                    suggestionData: suggestionData?.keyword,
                    custom_bid: ""
                })
                selectedTabAiFlowAddedSuggestionData.push({
                    bidData: bidData,
                    recId: suggestionData.recId,
                    suggestionData: suggestionData?.keyword,
                })
                setSelectedTabAiFlowAddedSuggestionData([...selectedTabAiFlowAddedSuggestionData]);
                if (props?.selectedTabAddedKeywordSuggestionData) {
                    props?.selectedTabAddedKeywordSuggestionData(selectedTabAiFlowAddedSuggestionData);
                }
                setAiFlowAddSuggestionData([...aiFlowAddSuggestionData]);
                if (props?.addedAiFlowKeywordSugestionData) {
                    props?.addedAiFlowKeywordSugestionData(aiFlowAddSuggestionData);
                }
                if (props?.addedGroupFlowKeywordSugestionData) {
                    props?.addedGroupFlowKeywordSugestionData(aiFlowAddSuggestionData);
                }
            }
        } else {
            if (!status) {
                addSuggestionsData.push({
                    bidData: bidData,
                    recId: suggestionData.recId,
                    suggestionData: suggestionData?.keyword
                })
                setAddSuggestionsData([...addSuggestionsData]);
                if (props?.addedKeywordSuggestionData) {
                    props?.addedKeywordSuggestionData(addSuggestionsData);
                }
                if (props?.addedGroupFlowKeywordSugestionData) {
                    props?.addedGroupFlowKeywordSugestionData(aiFlowAddSuggestionData);
                }
            }
        }
    }

    return (
        <div className="container-fluid px-0">
            <div className="row px-0">
                <div className="col-12 px-0">
                    {keywordSuggestionData?.length > 0 && keywordSuggestionData?.map((suggestionData: any, index: number) => (
                        <div key={index} className={`d-flex justify-content-between border-bottom mx-3 ${suggestionData?.bidData?.btn_status ? "added-btn-status" : ""}`}>
                            <div className='fs-11 w-25 text-start text-wrap'>
                                {suggestionData?.keyword}
                            </div>
                            <div className='fs-11 text-center w-40'>
                                {suggestionData?.bidInfo?.length > 0 && suggestionData?.bidInfo?.map((bidData: any, i: number) => (
                                    <div className="fs-11" key={i}>
                                        <div>{bidData?.matchType}</div>
                                    </div>
                                ))}
                                {suggestionData?.bidInfo?.length === 0 && (
                                    <div className="fs-11 text-center">-</div>
                                )}
                            </div>
                            <div className='fs-11 text-start w-40'>
                                {suggestionData?.bidInfo?.length > 0 && suggestionData?.bidInfo?.map((bidData: any, i: number) => (
                                    <div className="fs-11 " key={i}>
                                        <div>₹{(bidData?.suggestedBid.rangeMedian / 100).toFixed(2)}</div>
                                    </div>
                                ))}
                                {suggestionData?.bidInfo?.length === 0 && (
                                    <div className="fs-11 text-start">-</div>
                                )}
                            </div>
                            <div className='fs-11 text-start me-2'>
                                {suggestionData?.bidInfo?.length > 0 && suggestionData?.bidInfo?.map((bidData: any, i: number) => (
                                    <div className="fs-11" key={i}>
                                        <div className={`${bidData?.btn_status ? "add-products-text-color add-cursor-pointer-not-allowed fst-italic me-0 pe-0" : "custom-explore-text-color logout cursor-pointer me-2 pe-1"}`} onClick={(e) => onClickSuggestionAdd(e, bidData, suggestionData, i, index)}>
                                            {bidData?.btn_status ? "Added" : "Add"}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
