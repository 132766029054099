import React, { useState } from 'react'
interface Props {
  customPlaceholder?: any;
  searchTerm?: any;
  searchClear?: any;
}
export const Search = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState("")
  const [showWrongIcon, setShowWrongIcon] = useState<boolean>();
  const handleSearchInput = (e: any) => {
    const search = e.target.value;
    setSearchValue(e.target.value)
    setSearchTerm(search);
  }

  const onClickWrongIcons = () => {
    props?.searchClear(true)
    setSearchTerm('');
    setSearchValue("")
    setShowWrongIcon(false);
  }

  const onClickSearch = () => {
    if (searchValue) {
      props?.searchTerm(searchValue)
      if (searchValue) {
        setShowWrongIcon(true);
      } else {
        setShowWrongIcon(false);
      }
    }
  }
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onClickSearch();
    }
  };

  return (
    <div className='row'>
      <div className="col-md-2 ps-3 my-1 mb-2 input-group border-dark drop-down-input find-a-portfolio w_28">
        <input type="text" name="custom_search" className={`form-control drop-down-input border-dark ps-2 fs-13 border-end-0  px-0 ${showWrongIcon ? "border-end-0" : ""}`}
          placeholder={`${props?.customPlaceholder ? props?.customPlaceholder : "Search"}`}
          value={searchTerm} onChange={(e) => handleSearchInput(e)} onKeyPress={handleKeyPress} />
        {showWrongIcon && (
          <span className="input-group-text input drop-down-input bg-white border-dark  px-1">
            <i className="bi bi-x text-dark fw-bold cursor-pointer text-primary" onClick={onClickWrongIcons}></i>
          </span>
        )}
        {!showWrongIcon &&
          <span className="input-group-text cursor-pointer bg-white input border-dark drop-down-input  px-1" id="basic-addon1" onClick={onClickSearch}>
            <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-search search-icon-color"><path fill="rgb(130, 144, 164)" d="M194 8c102.73 0 186 83.28 186 186 0 40.17-12.73 77.37-34.39 107.77l149.31 149.31a31 31 0 0 1-43.84 43.84l-149.3-149.3A185.14 185.14 0 0 1 194 380C91.28 380 8 296.73 8 194 8 91.28 91.28 8 194 8Zm0 62c-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124 68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124Z"></path></svg>
          </span>
        }
      </div>
    </div>
  )
}
