import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { toast } from "react-toastify";
import { AdGroupSettingsStepPage } from '../campaign-creation-components/ad-group-settings';
import { ProductsCardStepPage } from '../campaign-creation-components/products-card';
import { TargetingAndManualTargetingKeywordStepPage } from '../campaign-creation-components/targeting-and-manual-targeting-keyword';
import { KeywordTargetingStepPage } from '../campaign-creation-components/keyword-targeting';
import { NegativeKeywordTargetingStepPage } from '../campaign-creation-components/negative-keyword-targeting';
import { CampaignBiddingStrategyStepPage } from '../campaign-creation-components/campaign-bidding-strategy';
import CustomStepLabel from './custom-step-label';
import { useNavigate, useParams } from 'react-router-dom';
import { CREATE_AI_GROUP_CAMPAIGN, CREATE_AI_KEYWORD_CAMPAIGN } from '../../../app/services/campaigns.service';
import { connect, useDispatch } from 'react-redux';
import { AiCampaignLoadingData } from '../../../redux/actions';

interface Props {
    campaignLoading?: (data: any) => void;
    AiCampaignLoadingDataRedcucer?: any;
    aiCampaignLoadingData?: any
}

const AiFlowCampaignCreationStepper = (props: Props) => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate: any = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [aiFlowAddedProductsdata, setAiFlowAddedProductsdata] = useState<any>([]);
    const [aiFlowAddProductsAsinData, setAiFlowAddProductsAsinData] = useState<any>([]);
    const [selectedProductAddedKeywordSuggestionData, setSelectedProductAddedKeywordSuggestionData] = useState<any>([]);
    const [selectedProductsData, setSelectedProductsData] = useState<any>([]);
    const [adGroupSettingsStepData, setAdGroupSettingsStepData] = useState<any>([]);
    const [triggerAdGroupValidation, setTriggerAdGroupValidation] = useState<any>({ value: false });
    const [triggerProductsStepValidation, setTriggerProductsStepValidation] = useState<any>({ value: false });
    const [triggerKeywordsStepValidation, setTriggerKeywordsStepValidation] = useState<any>({ value: false });
    const [triggerBiddingStrategyStepValidation, setTriggerBiddingStrategyStepValidation] = useState<any>({ value: false });
    const [buttonText, setButtonText] = useState<boolean>(false);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const [launchDisableStatus, setLaunchDisableStatus] = useState<boolean>(false);
    const [adGroupFormStatus, setAdGroupFormStatus] = useState<boolean>(false);
    const [isShowData, setisShowData] = useState<any>([]);
    const [isGroupData, setIsGroupData] = useState<any>([]);
    const [finalData, setFinalData] = useState<any>({
        ad_group_settings: "",
        products: "",
        keyword_targeting: [],
        negative_keyword_targeting: "",
        campaign_bidding_strategy: "",
        campaign_info: "",
        group_data: ''
    })
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = portfolio_id;



    useEffect(() => { }, []);

    const steps = ['Ad group settings', `${portfolioId == 2 ? "Groups" : "Products"}`, 'Targeting & Manual Targeting', 'Keyword targeting', 'Negative keyword targeting', 'Campaign bidding strategy & Ai campaign settings'];

    const stepsComponents = [
        <AdGroupSettingsStepPage validationStatus={(e) => onAdGroupForm(e)} adGroupSettingsData={(e) => getAdGroupSettingsStepData(e)}
            triggerAdGroupValidation={triggerAdGroupValidation} />,
        <ProductsCardStepPage
            aiFlowAddedProdcutsData={(e) => getAiFlowAddedProductsData(e)}
            aiFlowAddProdcutsAsinData={(e) => getAiFlowAddProductsAsinData(e)}
            triggerProductsStepValidation={triggerProductsStepValidation}
            isGroupData={isGroupData}
        />,
        <TargetingAndManualTargetingKeywordStepPage />,
        <KeywordTargetingStepPage addedProdcuts={aiFlowAddedProductsdata} addedProductsAsinData={aiFlowAddProductsAsinData}
            selectedProductAddedKeywordSuggestionData={(e) => getSelectedProductAddedKeywordSuggestionData(e)}
            selectedUniqueProductsData={(e) => getUniqueProductsData(e)} aiGroupFlowAddedKeywordsEnterListData={(e) => aiGroupFlowAddedKeywordsEnterListData(e)}
            selectedProductKeywords={(e) => getProductsKeywordsData(e)} />,
        <NegativeKeywordTargetingStepPage aiFlowNegativeKeywordTargetingData={(e) => getAiFlowNegativeKeywordTargetingData(e)} />,
        <CampaignBiddingStrategyStepPage aiCampaignLoadingData1={(e) => onLoading(e)} selectedAiFlowProdcutsData={true} addedProdcuts={aiFlowAddedProductsdata}
            addedKeywordSuggestionData={selectedProductAddedKeywordSuggestionData}
            uniqueProductsData={selectedProductsData}
            triggerBiddingStrategyStepValidation={triggerBiddingStrategyStepValidation} />,
    ];
    const [currentStepComponent, setCurrentStepComponent] = React.useState(stepsComponents[0]);
    const [isAiKeyWordsProceedWithAllProducts, setIsAiKeyWordsProceedWithAllProducts] = React.useState(false);


    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleGroup = (data: any) => {
        setIsGroupData(data)
    }

    const onAdGroupForm = (data: any) => {
        if (data) {
            setAdGroupFormStatus(data)
        }
    }

    const getAdGroupSettingsStepData = (data: any) => {
        if (data) {
            setFinalData({ ...finalData, ad_group_settings: data })
            setAdGroupSettingsStepData(data);
        }
    }

    const hasEmptyKeys = (obj: any) => {
        for (const key in obj) {
            if ((!obj[key] || obj[key].trim() === '') && key !== "end_date" && key !== "portfolio_id") {
                return true;
            }
        }
        return false;
    };

    const handleNext = (button: any) => {
        if (button === "Next") {
            if (activeStep === 0) {
                const value = { value: true }
                setTriggerAdGroupValidation({ ...value });
                if (adGroupFormStatus && adGroupSettingsStepData?.ad_group_name) {
                    const newCompleted = completed;
                    newCompleted[activeStep] = true;
                    setCompleted(newCompleted);
                    setActiveStep(activeStep + 1);
                    setCurrentStepComponent(stepsComponents[activeStep + 1]);
                }
            } else if (activeStep === 1) {
                if (portfolioId === "1") {
                    if (finalData?.products?.length > 0) {
                        const newCompleted = completed;
                        newCompleted[activeStep] = true;
                        setCompleted(newCompleted);
                        const newActiveStep = isLastStep() && !allStepsCompleted()
                            ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
                        setActiveStep(newActiveStep);
                        setCurrentStepComponent(stepsComponents[newActiveStep]);
                    }
                    else {
                        const value = { value: true }
                        setTriggerProductsStepValidation({ ...value });
                    }
                }

                if (portfolioId === "2") {
                    const value = { value: true }
                    setTriggerProductsStepValidation({ ...value });
                    let filteredData = isGroupData?.filter((item: any) => item?.products_data?.length === 0)
                    if (filteredData?.length > 0) { }
                    else {
                        if (finalData?.group_data?.length > 0) {
                            const newCompleted = completed;
                            newCompleted[activeStep] = true;
                            setCompleted(newCompleted);
                            const newActiveStep = isLastStep() && !allStepsCompleted()
                                ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
                            setActiveStep(newActiveStep);
                            setCurrentStepComponent(stepsComponents[newActiveStep]);
                        }
                    }
                }
            }
            else if (activeStep === 2) {
                const newCompleted = completed;
                newCompleted[activeStep] = true;
                setCompleted(newCompleted);
                const newActiveStep = isLastStep() && !allStepsCompleted()
                    ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
                setActiveStep(newActiveStep);
                setCurrentStepComponent(stepsComponents[newActiveStep]);
            }
            else if (activeStep === 3) {
                if (finalData?.keyword_targeting?.length <= 0) {
                    const value = { value: true }
                    setTriggerKeywordsStepValidation({ ...value });
                } else {
                    const addded_keywords_status = finalData?.keyword_targeting?.filter((el: any) => el?.add_keywords?.length > 0);
                    if (addded_keywords_status?.length > 0) {
                        const newCompleted = completed;
                        newCompleted[activeStep] = true;
                        setCompleted(newCompleted);
                        const newActiveStep = isLastStep() && !allStepsCompleted()
                            ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
                        setActiveStep(newActiveStep);
                        setCurrentStepComponent(stepsComponents[newActiveStep]);
                    } else {
                        const value = { value: true }
                        setTriggerKeywordsStepValidation({ ...value });
                    }
                }
                if (portfolioId === "2") {
                    const value = { value: true }
                    setTriggerKeywordsStepValidation({ ...value });
                    const finalGroupData = finalData?.group_data ? finalData?.group_data : [];
                    let filteredData = finalGroupData?.filter((item: any) => item?.keywords_data?.length === 0);

                    for (let i = 0; i < finalGroupData?.length; i++) {
                        const group = finalGroupData[i];
                        const hasMatchingProduct = group.products_data?.some((product:any) =>
                            group.keywords_data?.some((keyword:any) => keyword.product_uuid === product.uuid)
                        );

                        group.dummyData = hasMatchingProduct;

                        const preparedData = finalGroupData.filter((item:any) => item.dummyData);

                        if (!filteredData?.length) {
                            if (finalGroupData.length === preparedData.length) {
                                const newCompleted = completed;
                                newCompleted[activeStep] = true;
                                setCompleted(newCompleted);

                                const newActiveStep = isLastStep() && !allStepsCompleted()
                                    ? steps.findIndex((step, i) => !(i in completed))
                                    : activeStep + 1;

                                setActiveStep(newActiveStep);
                                setCurrentStepComponent(stepsComponents[newActiveStep]);
                            }
                        }
                    }


                    // for (let i = 0; i < finalGroupData?.length; i++) {
                    //     for (let j = 0; j < finalGroupData[i].products_data?.length; j++) {
                    //         const element = finalGroupData[i].products_data[j];
                    //         for (let k = 0; k < finalGroupData[i].keywords_data?.length; k++) {
                    //             const element_one = finalGroupData[i].keywords_data[k];
                    //             if (element_one.product_uuid === element.uuid) {
                    //                 finalGroupData[i].dummyData = true
                    //             }
                    //             else {
                    //                 finalGroupData[i].dummyData = false
                    //             }
                    //         }
                    //     }
                    //     const preparedData = finalGroupData.filter((item: any) => item.dummyData)
                    //     if (filteredData?.length > 0) { }
                    //     else {
                    //         if (finalGroupData?.length === preparedData?.length) {
                    //             const newCompleted = completed;
                    //             newCompleted[activeStep] = true;
                    //             setCompleted(newCompleted);
                    //             const newActiveStep = isLastStep() && !allStepsCompleted()
                    //                 ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
                    //             setActiveStep(newActiveStep);
                    //             setCurrentStepComponent(stepsComponents[newActiveStep]);
                    //         }
                    //     }
                    // }
                }
            }
            else if (activeStep === 4) {
                const value = { value: false }
                setTriggerKeywordsStepValidation({ ...value });
                setisShowData(1)
                const newCompleted = completed;
                newCompleted[activeStep] = true;
                setCompleted(newCompleted);
                const newActiveStep = isLastStep() && !allStepsCompleted()
                    ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
                setActiveStep(newActiveStep);
                setCurrentStepComponent(stepsComponents[newActiveStep]);
            }
            else if (activeStep === 5) {
                const value = { value: true }
                setTriggerBiddingStrategyStepValidation({ ...value });
                if (portfolioId == 2) {
                    const data = finalData?.group_data;
                    for (let i = 0; i < data?.length; i++) {
                        const groupElement: any = data[i];
                        const elementsWithEmptyKeys = data?.filter((element: any) => {
                            return (
                                element.hasOwnProperty('keyword_camapign_info') &&
                                hasEmptyKeys(element.keyword_camapign_info)
                            );
                        });

                        if (elementsWithEmptyKeys?.length > 0) {
                            const value = { value: true }
                            setTriggerBiddingStrategyStepValidation({ ...value });
                        } else {
                            setButtonText(true);
                        }
                    }
                }
                if (portfolioId == 1) {
                    const elementsWithEmptyKeys = finalData?.allKeywordData?.filter((element: any) => {
                        return (
                            hasEmptyKeys(element.keyword_camapign_info)
                        );
                    });
                    if (elementsWithEmptyKeys?.length > 0) {
                    } else {
                        setButtonText(true);
                    }
                }
            }
        } else if (button === "Launch camapign") {
            setLaunchDisableStatus(true);
            let addedKeywords: any = [];
            if (finalData?.keyword_targeting?.length > 0) {
                finalData?.allKeywordData?.forEach((element: any) => {
                    element.keyword_camapign_info.campaign_name = element?.keyword_camapign_info?.campaign_name?.includes('adv_SPM') ? element?.keyword_camapign_info?.campaign_name : `adv_SPM_${element.keyword_camapign_info.campaign_name}`
                    if (portfolioId == "1") {
                        element.product_info.default_bid = "3"
                        element.product_info.state = "ENABLED"
                    }
                });
                for (let index = 0; index < finalData?.keyword_targeting?.length; index++) {
                    const element = finalData?.keyword_targeting[index];
                    addedKeywords.push(
                        {
                            keyword_matchType: element?.add_keywords[index]?.bidData?.matchType.toString(),
                            keyword_state: 'ENABLED',
                            bid: element?.add_keywords[index]?.recId ? (element?.add_keywords[index]?.bidData?.bid / 100).toString() : element?.add_keywords[index]?.bidData?.bidF,
                            keyword_text: element?.add_keywords[index]?.suggestionData,
                            keyword_campaign_info: finalData?.campaign_info
                        }
                    )
                }
            }

            const data = finalData?.keyword_targeting;

            for (let i = 0; i < data?.length; i++) {
                data[i].default_bid = "3";
                data[i].state = "ENABLED";
                data[i].added_keyword_data_camapign_info = addedKeywords
            }

            if (portfolioId == 1) {
                if (props?.aiCampaignLoadingData) {
                    const value = { status: true };
                    dispatch(props?.aiCampaignLoadingData(value));
                }
                const preparedData = {
                    user_uuid: userUuid,
                    profile_id: profileID,
                    ad_group_setings_data: finalData?.ad_group_settings,
                    negative_keyword_targeting: finalData?.negative_keyword_targeting,
                    campaign_bidding_strategy: finalData?.campaign_bidding_strategy,
                    products_data: data ? data : [],
                    allKeywordData: finalData?.allKeywordData,
                    default_values: {
                        targeting_type: "MANUAL",
                        state: "PAUSED",
                        budget_type: "DAILY"
                    }
                }
                CREATE_AI_KEYWORD_CAMPAIGN(preparedData).then((res) => {
                    if (res.data.status === "error" || res?.data?.code == 500) {
                        toast.error(res.data.message);
                        setLoading(false);
                        if (props?.aiCampaignLoadingData) {
                            const value = { status: false };
                            dispatch(props?.aiCampaignLoadingData(value));
                        }
                    } else {
                        if (res) {
                            const response = res.data.data;
                            toast.success(res.data.message);
                            setLoading(false);
                            if (res.data.status === "success") {
                                if (props?.aiCampaignLoadingData) {
                                    const value = { status: false };
                                    dispatch(props?.aiCampaignLoadingData(value));
                                }
                                navigate("/dashboard/home/campaigns");
                            }
                        }
                    }
                });
            }
            else if (portfolioId == 2) {
                if (props?.aiCampaignLoadingData) {
                    const value = { status: true };
                    dispatch(props?.aiCampaignLoadingData(value));
                }

                const preparedData = {
                    user_uuid: userUuid,
                    profile_id: profileID,
                    ad_group_setings_data: finalData?.ad_group_settings,
                    negative_keyword_targeting: finalData?.negative_keyword_targeting,
                    campaign_bidding_strategy: finalData?.campaign_bidding_strategy,
                    group_data: finalData.group_data ? finalData.group_data : [],
                    default_values: {
                        targeting_type: "MANUAL",
                        state: "PAUSED",
                        budget_type: "DAILY"
                    }
                }

                CREATE_AI_GROUP_CAMPAIGN(preparedData).then((res) => {
                    if (res.data.status === "error" || res?.data?.code == 500) {
                        toast.error(res.data.message);
                        setLoading(false);
                        if (props?.aiCampaignLoadingData) {
                            const value = { status: false };
                            dispatch(props?.aiCampaignLoadingData(value));
                        }
                    } else {
                        if (res) {
                            const response = res.data.data;
                            toast.success(res.data.message);
                            setLoading(false);
                            if (res.data.status === "success") {
                                if (props?.aiCampaignLoadingData) {
                                    const value = { status: false };
                                    dispatch(props?.aiCampaignLoadingData(value));
                                }
                                navigate("/dashboard/home/campaigns");
                            }
                        }
                    }
                });
            }
        }
    };

    const onLoading = (data: any) => {
        return data;
    }

    const handleBack = () => {
        if (activeStep === 2) {
            const value = { value: false }
            setTriggerKeywordsStepValidation({ ...value });
            setIsGroupData([...finalData?.group_data])
        }
        if (activeStep === 4) {
            const value = { value: false }
            setTriggerKeywordsStepValidation({ ...value });
        }
        if (activeStep === 5) {
            setButtonText(false);
            setLaunchDisableStatus(false);
        }
        const newActiveStep = activeStep - 1;
        setActiveStep(newActiveStep);
        setCurrentStepComponent(stepsComponents[newActiveStep]);
    };

    const handleStep = (step: any) => () => {
        setActiveStep(step);
        setCurrentStepComponent(stepsComponents[step]);
    };

    const getAiFlowAddedProductsData = (data: any) => {
        setFinalData({ ...finalData, products: data })
        setAiFlowAddedProductsdata(data);
    }

    const getProductsKeywordsData = (data: any) => {
        setFinalData({ ...finalData, keyword_targeting: data })
    }

    const getAiFlowNegativeKeywordTargetingData = (data: any) => {
        setFinalData({ ...finalData, negative_keyword_targeting: data })
    }


    const getAiFlowAddProductsAsinData = (data: any) => {
        setAiFlowAddProductsAsinData(data);
    }

    const getCampaignBiddingStrategyData = (data: any) => {
        setFinalData({ ...finalData, campaign_bidding_strategy: data })
    }

    const getCampaignInfoData = (data: any) => {
        setFinalData({ ...finalData, campaign_info: data })
    }

    const getAllAiCampaignInfoData = (data: any) => {
        setFinalData({ ...finalData, allKeywordData: data })
    }

    const getSelectedProductAddedKeywordSuggestionData = (data: any) => {
        setSelectedProductAddedKeywordSuggestionData(data);
    }

    const getAiFlowGroupAddedKeywordSuggestionData = (data: any) => {
        const finalGroupData = finalData?.group_data;
        for (let i = 0; i < finalGroupData?.length; i++) {
            if (finalGroupData[i].id === isShowData) {
                finalGroupData[i].keywords_data = data
            }
        }
        setFinalData({ ...finalData })
    }
    const getUniqueProductsData = (data: any) => {
        setSelectedProductsData(data);
    }
    const aiFlowGroupsData = (data: any) => {
        setFinalData({
            ...finalData, group_data: data
        })
    }

    const isShowHandle = (data: any) => {
        setisShowData(data)
    }

    const aiKeyWordsProceedWithAllProducts = (e: any) => {
        setIsAiKeyWordsProceedWithAllProducts(e)
    }

    const aiGroupFlowAddedKeywordsEnterListData = (enterListKeyword: any) => {
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel className='cursor-pointer'>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <CustomStepLabel
                            label={label}
                            active={activeStep === index}
                            completed={completed[index]}
                            onClick={handleStep(index)}
                        />
                    </Step>
                ))}
            </Stepper>
            <div className={`${activeStep !== 5?'mx-5 px-5':'mx-3'}`}>
                {allStepsCompleted() ? (
                    <React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            <div className="step-content">
                                {activeStep === 0 && <AdGroupSettingsStepPage adGroupSettingsData={(e) => getAdGroupSettingsStepData(e)} validationStatus={(e) => onAdGroupForm(e)}
                                    triggerAdGroupValidation={triggerAdGroupValidation}
                                    adData={adGroupSettingsStepData}
                                />}
                                {activeStep === 1 && <ProductsCardStepPage
                                    aiFlowAddedProdcutsData={(e) => getAiFlowAddedProductsData(e)}
                                    aiFlowAddProdcutsAsinData={(e) => getAiFlowAddProductsAsinData(e)}
                                    triggerProductsStepValidation={triggerProductsStepValidation}
                                    aiFlowGroupsData={(e: any) => aiFlowGroupsData(e)}
                                    handleGroup={(e: any) => handleGroup(e)}
                                    finalGroupData={finalData?.group_data}
                                    isGroupData={isGroupData}
                                />}
                                {activeStep === 2 && <TargetingAndManualTargetingKeywordStepPage />}
                                {activeStep === 3 && <KeywordTargetingStepPage
                                    addedProdcuts={aiFlowAddedProductsdata}
                                    addedProductsAsinData={aiFlowAddProductsAsinData}
                                    finalStepKeywordTargetingData={finalData?.keyword_targeting}
                                    selectedProductAddedKeywordSuggestionData={(e) => getSelectedProductAddedKeywordSuggestionData(e)}
                                    aiFlowGroupaddedKeywordSuggestionData={(e) => getAiFlowGroupAddedKeywordSuggestionData(e)}
                                    selectedProductKeywords={(e) => getProductsKeywordsData(e)}
                                    aiFlowAddedGroupdata={finalData?.group_data}
                                    isShowHandle={(e: any) => isShowHandle(e)}
                                    isShowData={isShowData}
                                    triggerKeywordsStepValidation={triggerKeywordsStepValidation}
                                    finalGroupData={finalData?.group_data}
                                    finalAiData={finalData.products}
                                    proceedWithAllProducts={(e) => aiKeyWordsProceedWithAllProducts(e)}
                                />}
                                {activeStep === 4 && <NegativeKeywordTargetingStepPage aiFlowNegativeKeywordTargetingData={(e) => getAiFlowNegativeKeywordTargetingData(e)} />}

                                {activeStep === 5 && <CampaignBiddingStrategyStepPage addedProdcuts={aiFlowAddedProductsdata}
                                    addedKeywordSuggestionData={selectedProductAddedKeywordSuggestionData}
                                    aiFlowAddedKeywordsData={finalData?.keyword_targeting}
                                    campaignBiddingStrategyData={(e) => getCampaignBiddingStrategyData(e)}
                                    aiFlowCampaignInfoData={(e) => getCampaignInfoData(e)}
                                    allAiKeywordCampainInfoData={(e) => getAllAiCampaignInfoData(e)}
                                    loadingStatus={loading}
                                    aiFlowAddedGroupdata={finalData?.group_data}
                                    isShowHandle={(e: any) => isShowHandle(e)}
                                    isShowData={isShowData}
                                    triggerBiddingStrategyStepValidation={triggerBiddingStrategyStepValidation}
                                    isProceedWithAllProducts={isAiKeyWordsProceedWithAllProducts}
                                />}
                            </div>
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                className='text-capitalize btn btn-light border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-4'
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Previous
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button className={`text-capitalize request-call-button text-white rounded-pill cursor-pointer fs-13 px-5 ${launchDisableStatus ? 'request-disabled-bg-color' : ""}`}
                                disabled={launchDisableStatus} onClick={(e) => handleNext(buttonText ? 'Launch camapign' : 'Next')} sx={{ mr: 1 }}>
                                {buttonText ? 'Launch camapign' : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}

            </div>
        </Box>
    );
}


const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        aiCampaignLoadingData: (data: any) => dispatch(AiCampaignLoadingData(data)),
    };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(AiFlowCampaignCreationStepper);
export { connectedNavBar as AiFlowCampaignCreationStepper };
