import React from "react";
import { Routes, Route } from "react-router-dom";
import { CampaignSettings } from "../pages/Dashboard/campaigns/settings/list";
import { CampaignPlacements } from "../pages/Dashboard/campaigns/placements/list";
import { AdGroupsListPage } from "../pages/Dashboard/campaigns/ad-groups/ad-groups";
import { HistoryListPage } from "../pages/Dashboard/campaigns/history/history";
import { NegativeKeywordsListPage } from "../pages/Dashboard/campaigns/negative-keywords/negative-keywords";
import { BudgetRulesListPage } from "../pages/Dashboard/campaigns/budget-rules/budget-rules";
import { AdGroupInfoPage } from "../pages/Dashboard/campaigns/ad-groups/ad-group-info";

export const CampaignInfoRoutes = () => {

  return (
    <div>
      <Routes>
        <Route path="/" element={<CampaignSettings />}></Route>
        <Route path="/settings" element={<CampaignSettings />}></Route>
        <Route path="/placements" element={<CampaignPlacements />}></Route>
        <Route path="/ad-groups" element={<AdGroupsListPage />}></Route>
        <Route path="/ad-groups:ad-group-id/*" element={<AdGroupInfoPage />}></Route>
        <Route path="/budget-rules" element={<BudgetRulesListPage />}></Route>
        <Route path="/negative-keywords" element={<NegativeKeywordsListPage />}></Route>
        <Route path="/history" element={<HistoryListPage />}></Route>
      </Routes>
    </div>
  );
};
