import React from "react";
import { Routes, Route } from "react-router-dom";
import { CampaignPage } from "../pages/Dashboard/home/home";
import { PortPolioPage } from "../pages/Dashboard/portfolio/list";
import { CampaignDrafts } from "../pages/Dashboard/drafts/campaign-drafts";
import { PortfolioInfoPage } from "../pages/Dashboard/portfolio/portfolio-info";

export const DashboardHomeRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<CampaignPage />}></Route>
        <Route path="/campaigns" element={<CampaignPage />}></Route>
        <Route path="/portfolio" element={<PortPolioPage />}></Route>
        <Route path="/portfolio/:portfolio_id" element={<PortfolioInfoPage />}></Route>
        <Route path="/campaign-drafts" element={<CampaignDrafts />}></Route>
      </Routes>
    </div>
  );
};
