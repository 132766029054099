import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react'
import { GetHelpCardModal } from '../campaign-creation/get-help-card';
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../custom-tooltip/tooltipPage';

interface Props {
    adGroupName?: (data: any) => void;
    adGroupValidationStatus?: any;
    validateAdGroup?: () => boolean;
}

export const AdgroupSettingsCard = forwardRef((props: Props, ref) => {
    const [showCreateAdgroupPopup, setShowhowCreateAdgroupPopup] = useState<boolean>(false);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [adGroupData, setAdGroupData] = useState<any>({
        ad_group_name: ""
    })
    const [formErrors, setFormErrors] = useState<any>({});

    useEffect(() => {
    }, [])

    const onClickShowAdgroupModal = () => {
        setShowhowCreateAdgroupPopup(true);
    }

    const getModalStatus = (status: any) => {
        if (status) {
            setShowhowCreateAdgroupPopup(false);
        }
    };

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    };

    const tooltipContent = (tooltipTitle: string) => {
        if (tooltipTitle === "Ad group name") {
            return (
                <TooltipPage
                    tooltipMainContent={"Ad groups are a way to organise and manage ads within a campaign."}
                    tooltipSubContent={"Learn about ad groups"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        }
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setAdGroupData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        const isValid = validateForm();
        if (isValid) {
            if (props.adGroupName) {
                props?.adGroupName(adGroupData);
            }
        } else {
            setFormErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: validateSpecificFeild(name, value),
            }));
        }
    }

    const validateForm = () => {
        const errors: any = {};
        if (!adGroupData?.ad_group_name) {
            errors.ad_group_name = "Please enter ad group name"
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    useImperativeHandle(ref, () => ({
        validateForm,
    }));

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "ad_group_name") {
            if (!value) {
                return "Please enter ad group name"
            }
        }
    }

    return (
        <div className="ad-group-settings-card-container card-width mx-auto">
            <div className="card text-bg-white ad-group-settings mb-3 my-2 ">
                <div className="card-header bg-white">
                    <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">Ad group settings </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                            <span>
                                <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                            </span>
                            <span className="" onClick={onClickShowAdgroupModal}>
                                How to create an ad group
                            </span>
                        </div>
                        {showCreateAdgroupPopup && (
                            <GetHelpCardModal
                                ismodalShow={showCreateAdgroupPopup}
                                modalStatus={getModalStatus} />
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <h5 className="card-title fs-14 fw-700">
                        Ad group name{" "}
                        <span className="ms-1">
                            <Tooltip
                                title={tooltipContent("Ad group name")}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer "
                                onClick={handleClose}
                            >
                                <span className="cursor-pointer">
                                    <svg
                                        viewBox="0 0 512 512"
                                        width="13"
                                        height="13"
                                        className="fas fa-info-circle"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                        ></path>
                                    </svg>
                                </span>
                            </Tooltip>
                        </span>
                    </h5>
                    <div className="col-4 pe-3">
                        <input
                            name="ad_group_name"
                            className="form-control input border-dark fs-13"
                            placeholder='ad group name'
                            defaultValue={''}
                            onChange={handleInput} />
                        {formErrors.ad_group_name && (<div className='text-danger'>{formErrors.ad_group_name}</div>)}
                    </div>
                </div>
            </div>
        </div>
    )
})