import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import sponsored from "../../assets/images/svg_icons/sponsored.svg";
import SponsoredAdsReports from './../../assets/images/svg_icons/sponsored-ads-reports.svg';
import ActiveSponsoredAdsReports from './../../assets/images/svg_icons/active-sponsored-ads-reports.svg';
import ActiveSponsored from './../../assets/images/svg_icons/active-sponsored-ads.svg'
import Home from './../../assets/images/svg_icons/home-one.svg';
import ConfigurationSettings from './../../assets/images/svg_icons/settings.svg'
import ActiveConfigurationSettings from './../../assets/images/svg_icons/active-settings.svg';
import VendorsLink from './../../assets/images/svg_icons/vendors-link.svg';
import VendorsActiveLink from './../../assets/images/svg_icons/active-vendors-link.svg';

import { Tooltip } from "@mui/material";

interface Props {
  location?: any;
}

export const SidebarPage = (props: Props) => {
  const [showOffcanvas, setShowOffcanvas] = useState<boolean>(false);
  const [showMenuOffCanvas, setShowMenuOffCanvas] = useState<boolean>(false);
  const [offcanvasTitle, setOffcanvasTitle] = useState("");
  const offcanvasRef: any = useRef(null);
  const menuOffCanvasRef: any = useRef(null);
  const [activeMenu, setActiveMenu] = useState<string>('Sponsored ads');
  const navigate = useNavigate();

  const sidebarData: any = [
    {
      name: "Profiles",
      icon: Home,
      route: "/manage-profile"
    },
    {
      name: "Sponsored ads",
      icon: sponsored,
      activeIcon: ActiveSponsored
    },
    {
      name: "Sponsored ads reports",
      route: "/dashboard/reports",
      icon: SponsoredAdsReports,
      activeIcon: ActiveSponsoredAdsReports
    },
    {
      name: "Configuration",
      route: "/dashboard/configuration",
      icon: ConfigurationSettings,
      activeIcon: ActiveConfigurationSettings
    },
    {
      name: "Vendors link",
      route: "/dashboard/vendors-link",
      icon: VendorsLink,
      activeIcon: VendorsActiveLink
    },
  ];

  useEffect(() => {
    const currentUrl: any = window.location.href;
    const currentMenu = currentUrl?.split("/")[4]
    if (currentMenu === "configuration") {
      setActiveMenu("Configuration")
    } else if (currentMenu === "vendors-link") {
      setActiveMenu("Vendors link");
    } else if (currentMenu === "reports") {
      setActiveMenu("Sponsored ads reports");
    }
  }, [])

  const showSubSideMenu = (e: any, data: any, index: number) => {
    if (data.name === "Sponsored ads reports" || data.name === "Configuration" || data?.name === "Vendors link") {
      setShowOffcanvas(false);
      setActiveMenu(data?.name);
    } else {
      setShowOffcanvas(true);
      setShowMenuOffCanvas(false);
      setOffcanvasTitle(data?.name);
      setActiveMenu(data?.name);
    }
  }

  const handleOutsideHover = (event: any) => {
    if (offcanvasRef?.current && !offcanvasRef?.current?.contains(event.target as Node)) {
      setShowOffcanvas(false);
    }
  };

  const handleMenuOutsideHover = (event: any) => {
    if (menuOffCanvasRef?.current && !menuOffCanvasRef?.current?.contains(event.target as Node)) {
      setShowMenuOffCanvas(false);
    }
  };

  const hideOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const hideMenuOffcanvas = () => {
    setShowMenuOffCanvas(false);
  }

  const onClickCampaignManager = () => {
    navigate("/dashboard/home/campaigns")
    setShowOffcanvas(false);
  }

  const onMenuMouseHover = (e: any, data: any) => {
    if (data.name === "Sponsored ads") {
      setShowOffcanvas(true);
      setOffcanvasTitle(data?.name);
      setShowMenuOffCanvas(false);
    } else {
      setShowMenuOffCanvas(true);
      setShowOffcanvas(false);
    }
  }

  return (
    <div>
      <div className="sidebar d-flex flex-column justify-content-between side-menu-offcanvas-view">
        <ul className="nav-list px-0">
          {sidebarData.map((data: any, index: number) => (
            <NavLink
              key={index}
              to={data.route}
              className="text-decoration-none text-dark sidemenu-list-item"
            >
              <li className="py-3 pe-4 text-start ps-3 sidemenu-item" onClick={(e) => showSubSideMenu(e, data, index)} onMouseOver={(e) => onMenuMouseHover(e, data)}>
                <Tooltip title={""} placement="right" arrow className="cursor-pointer menu-tooltip"
                >
                  <img src={activeMenu === data.name ? data.activeIcon : data.icon} alt=""
                    style={{ width: "22px" }}
                  />
                </Tooltip>
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
      <div onMouseOver={(e) => handleOutsideHover(e)}>
        <div className={`offcanvas menu-offcanvas offcanvas-start ${showOffcanvas ? "show" : ""}`} tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" ref={offcanvasRef}
          onMouseLeave={hideOffcanvas}>
          <div className="offcanvas-header off-canvas-header-position">
            <h5 className="offcanvas-title fs-18 fw-bold" id="offcanvasExampleLabel">{offcanvasTitle}</h5>
            <button type="button" className="btn-close text-reset off-canvas-close-button-position d-none" onClick={() => setShowOffcanvas(false)}></button>
          </div>
          <div className="offcanvas-body  off-canvas-header-position">
            <div className="row pe-1">
              <div className="col-12 mt-5">
                <div className="list-group side-submenu-list" id="list-tab" role="tablist">
                  <a className="list-group-item list-group-item-action  active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                    <div className="d-flex justify-content-between" onClick={onClickCampaignManager}>
                      <div className="fs-13 px-2">Campaign Manager</div>
                      <div className="fs-13">
                        <svg viewBox="0 0 585 512" width="16" height="16" className="sc-storm-ui-30016670__sc-1gsqp3h-0 cyWYl far fa-house list-home-icon" data-fa-i2svg=""><path fill="currentColor" d="m61.5 248.48-.88.72c-15.49 13.45-39.74 5.1-43.65-15.04a26.65 26.65 0 0 1 9.89-26.2l226.2-185.85a62.19 62.19 0 0 1 78.88 0l226.2 185.86c16.25 12.53 12.83 37.95-6.15 45.76a26.66 26.66 0 0 1-27.61-4.53l-.9-.72v227.08A28.52 28.52 0 0 1 495.07 504H374.23a28.43 28.43 0 0 1-28.43-28.44V326.3H239.2v149.25A28.52 28.52 0 0 1 210.75 504H89.93a28.43 28.43 0 0 1-28.43-28.44V248.48ZM286.82 63.33l-172 141.44v245.91h71.06V301.44A28.52 28.52 0 0 1 214.32 273h156.35a28.5 28.5 0 0 1 28.43 28.43v149.25h71.07v-245.9l-172-141.44a8.88 8.88 0 0 0-11.35 0Z"></path></svg>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onMouseOver={(e) => handleMenuOutsideHover(e)}>
        <div className={`offcanvas menu-offcanvas offcanvas-start ${showMenuOffCanvas ? "show" : ""}`} tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" ref={menuOffCanvasRef}
          onMouseLeave={hideMenuOffcanvas}>
          <div className="offcanvas-header off-canvas-menu-header-position">
            <button type="button" className="btn-close text-reset off-canvas-close-button-position d-none" onClick={() => setShowMenuOffCanvas(false)}></button>
          </div>
          <div className="offcanvas-body  off-canvas-menu-header-position">
            <div className="row pe-1">
              <div className="col-12 ">
                <div className="list-group side-submenu-list" id="list-tab" role="tablist">
                  <ul className="nav-list px-0">
                    {sidebarData.map((data: any, index: number) => (
                      <NavLink
                        key={index}
                        to={data.route}
                        className="text-decoration-none text-dark sidemenu-list-item"
                      >
                        <li className="py-3 pe-4 list-style-type-none text-start ps-3 sidemenu-item" onClick={(e) => showSubSideMenu(e, data, index)}>
                          <div>{data?.name}</div>
                        </li>
                      </NavLink>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
