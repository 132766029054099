import React, { useEffect, useState } from 'react'

export const GroupsTabsPage = (props: any) => {
    const tabs = [
        {
            uuid: '1',
            name: "Group 1"
        },
        {
            uuid: '2',
            name: "Group 2"
        },
        {
            uuid: '3',
            name: "Group 3"
        }
    ]
    const [activeTab, setActiveTab] = useState<any>(tabs?.length > 0 ? tabs[0].uuid : null);
    const [visibleTabs, setVisibleTabs] = useState<number>(3);
    const [startIndex, setStartIndex] = useState<number>(0);

    const totalTabs = tabs?.length;

    useEffect(() => {
        if (totalTabs == 0) {
            setStartIndex(0);
            setActiveTab(tabs[0].uuid);
        }
        if (props.aiFlowAddedGroupdata) {
            for (let i = 0; i < props.aiFlowAddedGroupdata?.length; i++) {
                if (props.aiFlowAddedGroupdata[0]) {
                    if (props?.byDefaultTabData) {
                        props?.byDefaultTabData(1);
                    }
                }
            }
        }
    }, [tabs]);

    const handleGroupTabClick = (tabId: any) => {
        if (props.groupHandleClick) {
            props.groupHandleClick(tabId?.products_data[0]?.uuid)
        }
        if (props.HandleClick) {
            props.HandleClick(tabId?.products_data[0]?.uuid)
        }
        setActiveTab(tabId?.id);
        if (props.isShowHandle) {
            props.isShowHandle(tabId?.id)
        }
        if (props.handleGroupTab) {
            props.handleGroupTab(tabId)
        }
    };

    const handlePrevClick = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 2);
        }
    };

    const handleNextClick = () => {
        if (startIndex + visibleTabs < totalTabs) {
            setStartIndex(startIndex + 2);
        }
    };

    return (
        <div className='container-fluid'>
            <div className="tabs-section products-tab px-0">
                <div className="nav nav-underline" id="nav-tab" role="tablist">
                    <div className='d-flex'>
                        <button className={`nav-link text-dark fs-21 pe-3  ${startIndex === 0 ? 'disabled' : ''}`}
                            onClick={() => handlePrevClick()}
                        ><i className="bi bi-chevron-left fs-15 fw-bold"></i></button>
                        <div className='col-md-12 d-flex justify-content-between'>
                            {
                                props?.data?.map((tab: any, index: number) => {
                                    return (
                                        <button
                                            key={tab.uuid}
                                            className={`nav-link fs-13 ${tab.id === props.isShowData ? 'active custom-explore-text-color ai-group-product-bg-color' : 'text-dark'}`}
                                            onClick={() => handleGroupTabClick(tab)}
                                        >
                                            <div className={`bg-transparent d-flex justify-content-between mx-1`}>
                                                <div className={`row  ${activeTab === tab?.id ? 'active ai-campaign-product-tab product-ai-tab' : "product-ai-tab"}`}>
                                                    <div className='px-3'>{tab?.group_name}</div>
                                                </div>
                                            </div>
                                        </button>
                                    )
                                })
                            }
                        </div>
                        <button
                            className={`nav-link text-dark fs-21 ms-3 ${startIndex + visibleTabs >= totalTabs ? 'disabled' : ''}`}
                            onClick={() => handleNextClick()}
                        > <i className="bi bi-chevron-right fs-15 fw-bold"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}
