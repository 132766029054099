import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { DynamicForm } from '../../components/form-builder/form'
import { REGISTER } from '../../app/services/common.service';
import { toast } from "react-toastify";

export const RegisterPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,6}$/;

    const registerFormData = [
        {
            title: "input",
            control: "",
            type: "text",
            label: "User name",
            name: "user_name",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter user name ",
            error: "Please enter user name ",
        },
        {
            title: "input",
            control: "",
            type: "text",
            label: "Email",
            name: "user_email",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: emailRegEx,
            maxLength: "",
            placeholder: "Please enter email ",
            error: "Please enter email ",
        },
        {
            title: "input",
            control: "",
            type: "password",
            label: "Password",
            name: "user_password",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter password",
            error: "Please enter password",
            showPassword: false,
            customPasswordClass: "custom-register-password"
        },
        {
            title: "input",
            control: "",
            type: "text",
            label: "Business name",
            name: "business_name",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter business name",
            error: "Please enter business name",
        },
        {
            title: "text area",
            control: "",
            type: "text",
            rows: 3,
            label: "Registered address",
            name: "registration_address",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter registered address",
            error: "Please enter registered address",
        },
    ];

    const onSubmitRegisterForm = (data: any) => {
        const registerFormData = data.formData;
        const isValid = true;
        if (isValid) {
            const preparedData: any = {
                user_name: registerFormData.user_name,
                user_email: registerFormData.user_email,
                user_password: registerFormData.user_password,
                business_name: registerFormData.business_name,
                registration_address: registerFormData.registration_address
            }
            setLoading(true);
            REGISTER(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const response = res.data;
                        toast.success(response.message);
                        navigate("/");
                        setLoading(false);
                    }
                }
            });
        }
    }

    return (
        <div className="modal log-in-modal-border register-page">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content login-dynamic-form mx-auto">
                    <div className="login-form mx-2 mx-md-0 p-3">
                        <div className=''>
                            <div className='fs-24 text-center me-2 fw-700'>
                                Register
                            </div>
                        </div>
                        <div className="col-12">
                            <DynamicForm
                                columns={1}
                                formControls={''}
                                userFormData={registerFormData}
                                custom_btn_text={'Register'}
                                customClass={"d-flex justify-content-center"}
                                cancelRoute={undefined}
                                isEdit={false}
                                onChildEvent={(e) => onSubmitRegisterForm(e)}
                                loading={loading}
                                isNotEmptyFormAfterSubmit={true}
                            />
                        </div>
                        <div className='d-flex flex-column justify-content-center my-2 '>
                            <div className='text-center'>Already have an account?  <NavLink to="/" className="text-decoration-none ms-2"><span className='custom-explore-text-color fw-bold logout'>Login</span></NavLink> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
