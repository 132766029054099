import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { CustomTable } from '../../../../components/custom-table';
import { ConfigurationCampaingDataGridColumn } from './campaign-data-grid-cols';
import { useNavigate } from 'react-router-dom';
import { GET_USER_CAMPAIGNS } from '../../../../app/services/campaigns.service';
import { Search } from '../../../../components/configuration/search';
import { ConfigurationForm } from '../../../../components/configuration/form';
import { UPDATE_ALL_CAMPAIGNS_AI_KEYS, UPDATE_CAMPAIGN_AI_KEYS } from '../../../../app/services/ai-keys-update.service';
import { durationsList } from '../../../../app/utils/durationsList';

export const CampaingConfigurationPage = () => {
    const [pageData, setPageData] = useState({
        limit: '100',
        page: '1'
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignsData, setCampaignsData] = useState<any>();
    const [searchTermData, setSearchTermData] = useState<any>("");
    const [campaignAIStatus, setCampaignAIStatus] = useState(false);
    const [dateRangeData, setDateRangeData] = useState<any>();
    const [selectConfigDurationValue, setSelectConfigDurationValue] = useState<any>();
    const [showDateRangePopup, setShowDateRangePopup] = useState<any>(false);
    const [confirmationPopup, setConfirmationPopup] = useState<any>(false);
    const [preparedCampaignsData, setPreparedCampaignsData] = useState<any>();


    const currentDate: any = new Date();

    const [formData, setFormData] = useState<any>([
        {
            isEmpty: true,
            width: '10%'
        },
        {
            type: 'input',
            name: 'benchmark',
            placeholder: 'Benchmark',
            inputType: 'number',
            value: '',
            label: 'Benchmark',
            width: '15%'
        },
        {
            type: 'dropdown',
            inputType: 'text',
            name: 'duration',
            placeholder: 'Select duration',
            value: "",
            options: durationsList,
            label: 'Duration',
            width: '12%'
        },
        {
            type: 'input',
            inputType: 'number',
            name: 'qualifier',
            placeholder: 'Qualifier',
            value: "",
            label: 'Qualifier',
            width: '15%'
        },
        {
            type: 'switch',
            name: 'is_ai_update',
            placeholder: '',
            value: '',
            label: 'Auto Updated by AI',
            width: '19%'
        },
        {
            type: 'switch',
            name: 'is_ai_call',
            placeholder: '',
            value: '',
            label: 'Get Recommendations by AI',
            width: '25%'
        },
        {
            type: 'button',
            name: 'Save all',
            placeholder: '',
            value: '',
            label: ''
        }
    ]);
    const profileID = localStorage.getItem('profile_id');
    const userUuid = localStorage.getItem('user_uuid');
    const navigate = useNavigate();

    useEffect(() => {
        getUserCampaigns(searchTermData);
    }, []);

    const onActions = (data: any) => {
        localStorage.setItem("campaign_id", data?.data?.campaign_id);
        if (data.type === "link") {
            navigate(`/dashboard/configuration/groups`)
        } else if (data?.type === "save") {
            const preparedData = {
                user_uuid: userUuid,
                type: 'CAMPAIGNS',
                data: [
                    {
                        campaign_id: data?.data?.campaign_id,
                        uuid: data?.data?.uuid,
                        benchmark: data?.data?.benchmark,
                        duration: selectConfigDurationValue ? selectConfigDurationValue : data?.data?.duration ? data?.data?.duration : "3",
                        is_ai_update: campaignAIStatus ? campaignAIStatus : data?.data?.is_ai_update ? data?.data?.is_ai_update : false, //campaignAIStatus,
                        qualifier: data?.data?.qualifier,
                        is_ai_call: data?.data?.is_ai_call,
                        ai_start_date: data?.data?.ai_start_date ? data?.data?.ai_start_date : '',
                        ai_end_date: data?.data?.ai_end_date ? data?.data?.ai_end_date : ''
                    }
                ]
            }
            setLoading(true);
            UPDATE_CAMPAIGN_AI_KEYS(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        getUserCampaigns(searchTermData);
                        toast.success(res.data.message);
                        setLoading(false);
                    }
                }
            });
        } else if (data?.type === "Date Range") {
            setShowDateRangePopup(true);
        }
    }

    const getUserCampaigns = (searchData: any) => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            is_report_data: false,
            campaign_name: searchData ? searchData : ""
        }
        let page;
        if (searchData) {
            page = { limit: "100", page: "1" }
        } else if (searchData === "") {
            page = pageData
        }
        setLoading(true);
        GET_USER_CAMPAIGNS({ ...preparedData, ...page }).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    setCampaignsData(response?.data);
                }
                setLoading(false);
            }
        });
    }

    const getSelectAllCheckStatus = (status: any) => {
        if (status) {
            const data = campaignsData?.data
            data?.forEach((element: any) => {
                element.is_ai_call = true
            })
            setCampaignsData({ ...campaignsData, data: [...data] })
        } else {
            const data = campaignsData?.data
            data?.forEach((element: any) => {
                element.is_ai_call = false
            })
            setCampaignsData({ ...campaignsData, data: [...data] })
        }
    }

    const getEachSelectCheckStatus = (data: any) => {
        const allCampaignsData = campaignsData?.data
        if (data?.status) {
            for (let i = 0; i < allCampaignsData.length; i++) {
                if (allCampaignsData[i]?.uuid === data?.data?.uuid) {
                    allCampaignsData[i].is_ai_call = true
                }
            }
            setCampaignsData({ ...campaignsData, data: [...allCampaignsData] })
        } else {
            for (let i = 0; i < allCampaignsData.length; i++) {
                if (allCampaignsData[i]?.uuid === data?.data?.uuid) {
                    allCampaignsData[i].is_ai_call = false
                }
            }
            setCampaignsData({ ...campaignsData, data: [...allCampaignsData] })
        }
    }

    const autoUpdateAIStatus = (status: any) => {
        setCampaignAIStatus(status)
    }

    const getIndividualDateRange = (data: any) => {
        setDateRangeData({ ...dateRangeData, ai_start_date: data?.start_date, ai_end_date: data?.end_date })
    }

    const updateFormDataToTable = (formDataResult: any) => {
        const data = campaignsData;
        const formDataObj = formData;
        data?.data?.forEach((data: any) => {
            if (data[formDataResult?.name] === formDataObj[formDataResult.index]?.value || !data[formDataResult?.name]) {
                data[formDataResult?.name] = formDataResult?.value
            } else if (formDataResult?.index === -1) {
                data[formDataResult?.name] = formDataResult?.value
            }
        });
        if (formDataResult.index !== -1) {
            formDataObj[formDataResult.index].value = formDataResult?.value;
            setFormData([...formDataObj])
        }
        setCampaignsData({ ...data });
    }

    const getSearchTerm = (search: any) => {
        setSearchTermData(search)
        if (search?.trim()?.length > 0) {
            getSearchCampaigns(search);
        } else {
            setSearchTermData("");
            const searchData = ""
            getUserCampaigns(searchData);
        }
    }

    const getSearchCampaigns = (data: any) => {
        setSearchTermData(data)
        getUserCampaigns(data);
    }

    const getSearchClear = (status: any) => {
        if (status) {
            setSearchTermData("")
            const searchData = ""
            getUserCampaigns(searchData);
        }
    }
    const onSaveAll = (data: any) => {
        let updatedCampaignsData: any = {};
        const fieldsToUpdate = ['benchmark', 'qualifier', 'duration', 'ai_start_date', 'ai_end_date', 'is_ai_update', 'is_ai_call'];
        const isNonEmpty = (value: any) => value !== undefined && value !== null && value !== '';
        formData.forEach((formElement: any) => {
            if (isNonEmpty(formElement?.value) && formElement?.type !== 'button' && fieldsToUpdate.includes(formElement.name)) {
                updatedCampaignsData[formElement.name] = formElement.value;
            }
        });
        const preparedCampaignsData = {
            user_uuid: userUuid,
            profile_id: profileID,
            type: 'CAMPAIGNS',
            ...updatedCampaignsData,
            ...dateRangeData
        };
        setPreparedCampaignsData(preparedCampaignsData);
        setConfirmationPopup(true);
    }

    const onPageChange = (newPage: any) => {
        const page = pageData;
        page.page = (newPage + 1).toString()
        setPageData({ ...page });
        getUserCampaigns(searchTermData)
    }

    const rowsPerChangeData = (data: any) => {
        const page = pageData;
        page.page = '1'
        page.limit = data.toString();
        setPageData({ ...page });
        getUserCampaigns(searchTermData)
    }

    const onChangeConfiguration = (data: any) => {
        setSelectConfigDurationValue(data?.updatedDuration)
        const updatedCampaignData = data?.data
        updatedCampaignData.duration = data?.updatedDuration
        setCampaignsData({ ...updatedCampaignData })
    }

    const saveAllConfirmation = () => {
        setLoading(true);
        UPDATE_ALL_CAMPAIGNS_AI_KEYS(preparedCampaignsData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    getUserCampaigns(searchTermData);
                    toast.success(res.data.message);
                    setLoading(false);
                    setConfirmationPopup(false);
                }
            }
        });
    }

    return (
        <div className='container-fluid'>
            <div className='row fs-5 fw-bold ms-0'>Configurations</div>
            <div className=''>
                <Search searchTerm={(e: any) => getSearchTerm(e)} searchClear={(e: any) => getSearchClear(e)} />
            </div>
            {loading &&
                <div className={`text-center p-5 negative-keyword-spinner-position`}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {!loading && <>
                <ConfigurationForm formData={formData} updateFormDataToTable={(e) => updateFormDataToTable(e)} onSubmit={(e) => onSaveAll(e)}
                    updatedDateRangeData={(e) => getIndividualDateRange(e)} />
                <CustomTable
                    tableData={campaignsData}
                    TableCols={ConfigurationCampaingDataGridColumn}
                    tableWithoutFixedCoulmns={true}
                    configurationCampaigns={true}
                    toggleStatus={false}
                    tableLoading={!loading}
                    tableFooterpositionTop={true}
                    noDataFoundMesageTitle=''
                    onActions={(e) => onActions(e)}
                    selectAllCheckStatus={(e) => getSelectAllCheckStatus(e)}
                    eachSelectCheckStatus={(e) => getEachSelectCheckStatus(e)}
                    autoUpdateAIStatus={(e) => autoUpdateAIStatus(e)}
                    onPageChange={e => onPageChange(e)}
                    configureValue={(e) => onChangeConfiguration(e)}
                    rowsPerChangeData={(e) => rowsPerChangeData(e)}
                    isAPIPageNation={true}
                    configurationStatus={true}
                    pageData={pageData}
                />
            </>}
            {showDateRangePopup && (
                <div className={`modal modal-custom fade ${showDateRangePopup ? "show" : "false"}  ${showDateRangePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">Date Range</h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDateRangePopup(false)}></button>
                            </div>
                            <div className='modal-body p-3 border-bottom'>
                                <div>
                                    <div>
                                        <label className='form-label'>Start Date</label>
                                        <input type="date" className="form-control" name={"start_date"} max={currentDate} />
                                    </div>
                                    <div>
                                        <label className='form-label'>End Date</label>
                                        <input type="date" className="form-control" name={"end_date"} max={""} />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end py-3">
                                    <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-4 mx-3" >Submit</button>
                                    <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-4`} onClick={() => setShowDateRangePopup(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {confirmationPopup && (
                <div className={`modal modal-custom fade ${confirmationPopup ? "show" : "false"}  ${confirmationPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">Confirmation</h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setConfirmationPopup(false)}></button>
                            </div>
                            <div className='modal-body p-3 border-bottom'>
                                <div>
                                    <div>
                                        <p>This configuration will apply to all campaigns in this profile (profile name), Do you want to continue</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end py-3">
                                    <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-4 mx-3" onClick={() => saveAllConfirmation()}>Yes</button>
                                    <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-4`} onClick={() => setConfirmationPopup(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}