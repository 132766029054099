import React, { useState } from 'react'
import { TooltipPage } from '../custom-tooltip/tooltipPage'
import { Tooltip } from '@mui/material'
import SmallWrongIcon from "./../../assets/images/svg_icons/small-wrong.svg";
import SearchIcon from "./../../assets/images/svg_icons/search.svg";
import SearchSecondIcon from "./../../assets/images/svg_icons/search-two.svg";
import DownloadIcon from "./../../assets/images/svg_icons/download.svg";

interface Props {
  addProductsToTargetsNegativeTargeting?: boolean;
  addedProdcutsDataLength?: (data: any) => void;
  addedNegativeProductsData?: (data: any) => void;
}

export const NegativeProductTargetingCardsPage = (props: Props) => {
  const [search, setSearch] = useState<any>();
  const [showWrongIcon, setShowWrongIcon] = useState<boolean>(false);
  const [tooltipClose, setTooltipClose] = useState<boolean>(false);
  const [addBrands, setAddBrands] = useState<any>([])

  const [addProductstoTargetsData, setAddProductstoTargetsData] = useState<any>([
    {
      id: 1,
      name: "Sugar Watchers",
      exclude_btn_status: false
    },
    {
      id: 2,
      name: "Waterful",
      exclude_btn_status: false
    },
    {
      id: 3,
      name: "Sabichi",
      exclude_btn_status: false
    },
    {
      id: 4,
      name: "Barrybee",
      exclude_btn_status: false
    }
  ])
  const tooltipContent = (data: any) => {
    if (data === "manual targeting Negative product targeting optional exlude brands tab") {
      return (
        <TooltipPage
          tooltipMainContent={`An advanced strategy that prevents your ads from displaying in specific search results or detail pages. Tip: Lower your bid for specific brands instead of excluding.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (data === "manual targeting Negative product targeting optional exlude products tab") {
      return (
        <TooltipPage
          tooltipMainContent={`An advanced strategy that prevents your ads from displaying in specific search results or detail pages. Tip: Lower your bid for specific brands instead of excluding.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    }
  }

  const handleSearchInput = (e: any) => {
    const search = e.target.value;
    if (search === "") {
      setShowWrongIcon(false);
    } else {
      setShowWrongIcon(true);
    }
    setSearch(search);
  };

  const onClickWrong = () => {
    const search = "";
    setSearch(search);
    setShowWrongIcon(false);
  };

  const handleQuotationFileUpload = (e: any) => {
    const file = e.target.files[0];
  };

  const onCloseTooltip = (status: any) => {
    if (status) {
      setTooltipClose(true);
    }
  };

  const handleClose = () => {
    const tooltipStatus: any = tooltipClose;
    return tooltipStatus;
  };

  const onClickAddExclude = (e: any, item: any, index: number) => {
    const data = addProductstoTargetsData;
    data[index].exclude_btn_status = true;
    setAddProductstoTargetsData([...data]);
    addBrands.push(item);
    if (props.addedNegativeProductsData) {
      props?.addedNegativeProductsData(addBrands)
    }
    if (props?.addedProdcutsDataLength) {
      props.addedProdcutsDataLength(addBrands?.length)
    }
    setAddBrands([...addBrands]);
  }

  const onClickCanceladdedBrands = (e: any, index: number, item: any) => {
    const data = addProductstoTargetsData;
    for (let index = 0; index < data?.length; index++) {
      if (data[index].id === item?.id) {
        data[index].exclude_btn_status = false;
      }
    }
    const addBrandsData: any = addBrands;
    addBrandsData?.splice(index, 1);
    if (props.addedNegativeProductsData) {
      props?.addedNegativeProductsData(addBrandsData)
    }
    if (props?.addedProdcutsDataLength) {
      props.addedProdcutsDataLength(addBrandsData?.length)
    }
    setAddBrands([...addBrandsData]);
  }

  const onClickRemoveNegativekeywords = () => {
    if (props?.addedProdcutsDataLength) {
      const addBrands: any = [];
      props.addedProdcutsDataLength(addBrands?.length)
    }
    const data = addProductstoTargetsData;
    for (let index = 0; index < data?.length; index++) {
      data[index].exclude_btn_status = false;
    }
    setAddBrands([]);
  }

  return (
    <div className='container-fluid'>
      <div className="negative-product-targeting-container card-width mx-auto my-2">
        <div className="row text-center px-1">
          <div className="col-6 bg-white products-tabs-section border-right py-0">
            <div className="tabs-section px-0">
              <div className="nav nav-underline" id="nav-tab" role="tablist" >
                <button className="nav-link text-dark active fs-15"
                  id="nav-exclude-brands" data-bs-toggle="tab" data-bs-target="#nav-excludeBrands"
                  type="button" role="tab" aria-controls="nav-excludeBrands" aria-selected="true"
                >
                  Exclude brands
                  <Tooltip
                    title={tooltipContent(
                      "manual targeting Negative product targeting optional exlude brands tab"
                    )}
                    placement="right" arrow className="custom-tooltip-position cursor-pointer "
                  >
                    <span className="ms-1">
                      <svg
                        viewBox="0 0 512 512"
                        width="12"
                        height="12"
                        className="fas fa-info-circle"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="#8290a4"
                          d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                        ></path>
                      </svg>
                    </span>
                  </Tooltip>
                </button>
                <button className="nav-link text-dark fs-15"
                  id="nav-exclude-products"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-excludePRoducts"
                  type="button"
                  role="tab"
                  aria-controls="nav-excludePRoducts"
                  aria-selected="false"
                >
                  Exclude products
                  <Tooltip
                    title={tooltipContent(
                      "manual targeting Negative product targeting optional exlude products tab"
                    )}
                    placement="right"
                    arrow
                    className="custom-tooltip-position cursor-pointer shadow"
                  >
                    <span className="ms-1">
                      <svg
                        viewBox="0 0 512 512"
                        width="12"
                        height="12"
                        className="fas fa-info-circle"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="#8290a4"
                          d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                        ></path>
                      </svg>
                    </span>
                  </Tooltip>
                </button>
              </div>
            </div>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active suggested-tab" id="nav-excludeBrands"
                role="tabpanel"
                aria-labelledby="nav-exclude-brands"
                tab-index="0"
              >
                <div className="row border-top">
                  <div className="col-12 px-0 search-input-position exclude-product-search-input-position border-bottom custom-border-color py-2 pe-2">
                    <span>
                      <img
                        src={SearchIcon} alt=""
                        className="search-position"
                      />
                    </span>
                    <input
                      className="form-control fs-13 border-dark px-4"
                      defaultValue={search}
                      placeholder="  Search by brand name"
                      onChange={handleSearchInput}
                    />
                    <span className="d-none">
                      <img
                        src={SearchSecondIcon} alt=""
                        className="search-second-position custom-button-background"
                      />
                    </span>
                    {showWrongIcon && (
                      <span>
                        <img
                          src={SmallWrongIcon} alt=""
                          className="search-second-position wrong-icon-position"
                          onClick={onClickWrong}
                        />
                      </span>
                    )}
                  </div>
                  <div className='d-flex justify-content-between mt-4 border-bottom py-2'>
                    <div className="fs-13 fw-bold">YOUR BRANDS</div>
                    <div className={`fs-13 bg-transparent ${addProductstoTargetsData?.length == 0 ? "add-products-text-color cursor-pointer-not-allowed" : "custom-explore-text-color-two cursor-pointer logout"}`}>Exclude All</div>
                  </div>
                  {addProductstoTargetsData?.map((data: any, index: number) => (
                    <div className='d-flex justify-content-between border-bottom py-1 px-3' key={index}>
                      <div className="fs-13">{data?.name}</div>
                      <div className={`fs-13  ${data?.exclude_btn_status ? "add-products-text-color" : "custom-explore-text-color-two cursor-pointer logout"}`} onClick={(e) => onClickAddExclude(e, data, index)}>{data?.exclude_btn_status ? "Excluded" : "Exclude"}</div>
                    </div>
                  ))}
                  {addProductstoTargetsData?.length == 0 && (
                    <div className="fs-13 mt-5">
                      Search for brands you want to exclude
                    </div>
                  )}
                </div>
              </div>
              <div className="tab-pane fade enterlist-tab" id="nav-excludePRoducts"
                role="tabpanel"
                aria-labelledby="nav-exclude-products"
                tab-index="0"
              >
                <div className="row border-top">
                  <div className="col-12 px-0">
                    <div className="tabs-section px-0 mx-0">
                      <div className="nav nav-underline px-2 mx-1" id="nav-tab" role="tablist">
                        <button className="nav-link text-dark active fs-13" id="nav-exclude-products-search"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-product-excludeProducts-search"
                          type="button"
                          role="tab"
                          aria-controls="nav-product-excludeProducts-search"
                          aria-selected="true"
                        >
                          Search{" "}
                        </button>
                        <button className="nav-link text-dark fs-13" id="nav-exclude-products-enterlist"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-product-excludeProducts-enterlist"
                          type="button"
                          role="tab"
                          aria-controls="nav-product-excludeProducts-enterlist"
                          aria-selected="false"
                        >
                          Enter list
                        </button>
                        <button className="nav-link text-dark fs-13" id="nav-exclude-products-upload"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-product-excludeProducts-upload"
                          type="button"
                          role="tab"
                          aria-controls="nav-product-excludeProducts-upload"
                          aria-selected="false"
                        >
                          Upload{" "}
                        </button>
                      </div>
                      <div className="tab-content px-2 mx-0" id="nav-tabContent">
                        <div className="tab-pane fade show  active search-tab" id="nav-product-excludeProducts-search"
                          role="tabpanel"
                          aria-labelledby="nav-exclude-products-search"
                          tab-index="0"
                        >
                          <div className="row border-top">
                            <div className="col-12 px-0 search-input-position exclude-brand-search-input-position border-bottom custom-border-color py-2 px-3">
                              <span>
                                <img
                                  src={SearchIcon} alt=""
                                  className="search-position"
                                />
                              </span>
                              <input
                                className="form-control fs-13 border-dark px-4"
                                defaultValue={search}
                                placeholder="  Search by product name or ASIN"
                                onChange={handleSearchInput}
                              />
                              <span className="d-none">
                                <img
                                  src={SearchSecondIcon} alt=""
                                  className="search-second-position custom-button-background"
                                />
                              </span>
                              {showWrongIcon && (
                                <span>
                                  <img
                                    src={SmallWrongIcon} alt=""
                                    className="search-second-position wrong-icon-position"
                                    onClick={onClickWrong}
                                  />
                                </span>
                              )}
                            </div>
                            <div className="fs-13 mt-5 ">
                              Search for products you want to
                              target.
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade show  search-tab" id="nav-product-excludeProducts-enterlist"
                          role="tabpanel" aria-labelledby="nav-exclude-products-enterlist"
                          tab-index="0"
                        >
                          <div className="row border-top">
                            <div className="fs-13 my-2 mt-4">
                              <textarea
                                name="negative_product_target_text"
                                className="form-control border-dark fs-13"
                                rows={8}
                                placeholder="Enter ASINs separated by a comma, space or new line." defaultValue={''}
                              ></textarea>
                            </div>
                            <div className="fs-13 d-flex justify-content-end my-2">
                              <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                Exclude
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade show  search-tab" id="nav-product-excludeProducts-upload"
                          role="tabpanel"
                          aria-labelledby="nav-exclude-products-upload"
                          tab-index="0"
                        >
                          <div className="row fs-13 border-top mb-4 mx-0">
                            <div className="row my-2 mt-4">
                              <div className="mb-2 fs-13 text-start">
                                Use the template to make sure
                                your information uploads
                                accurately.
                              </div>
                              <div className="text-start mb-2">
                                <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                  <span className="me-2">
                                    <img src={DownloadIcon} alt="" />
                                  </span>
                                  Download the CSV template
                                </button>
                              </div>
                              <div className="upload-files">
                                <div
                                  data-takt-id="ups_upload_asins_input"
                                  className="upload-icon fs-13"
                                >
                                  <div className="mt-4 pt-2">
                                    <label
                                      htmlFor="input_file_three"
                                      className="cursor-pointer"
                                    >
                                      <div className="">
                                        <div>
                                          <i className="bi bi-upload"></i>
                                        </div>
                                        <div className="fs-13 my-1">
                                          Drag and drop file to add
                                        </div>
                                        <div className="fs-13 my-2">
                                          or click to select from your computer
                                        </div>
                                        <div className="fs-13 my-1">
                                          Accepted formats: CSV, TSV, XLSX
                                        </div>
                                      </div>
                                    </label>
                                    <input
                                      type="file"
                                      id="input_file_three"
                                      name="file"
                                      className="d-none "
                                      onChange={
                                        handleQuotationFileUpload
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 product-preview-container">
            <div className="d-flex justify-content-between  border-bottom py-2 px-2">
              <div className="fs-13 fw-bold ">{addBrands?.length} added</div>
              <div className={`fs-13 ${addBrands?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : "cursor-pointer-not-allowed remove-all-text cursor-pointer-not-allowed"}`}
                onClick={onClickRemoveNegativekeywords}>
                Remove all
              </div>
            </div>
            <div className="fs-12 pt-4 mt-2 pb-2 px-2  border-bottom text-start">
              Brands and products
            </div>
            {addBrands?.map((data: any, i: number) => (
              <div className='d-flex justify-content-between border-bottom py-1' key={i}>
                <div className="fs-13"><span className='fw-bold'>Brand : </span>{data?.name}</div>
                <div className="fs-13"><i className="bi bi-x fs-21 wrong-color fw-bold cursor-pointer" onClick={(e) => onClickCanceladdedBrands(e, i, data)}></i></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}