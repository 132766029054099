import API from "../utils/axios";
const api_url = "/api";

export const UPDATE_CAMPAIGN_AI_KEYS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/updateAiKeys`, data);
};

export const AI_SUGGESTION_REJECT = (data: any): Promise<any> => {
    return API.post(`${api_url}/ai_reports/update_ai_report`, data);
};

export const AI_SUGGESTION_ACCEPT = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/updateAIValuesToAWS`, data);
};

export const UPDATE_ALL_CAMPAIGNS_AI_KEYS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/updateAIValues`, data);
};