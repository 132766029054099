import React, { useEffect, useState } from 'react'
import WrongIcon from "./../../../assets/images/svg_icons/wrong.svg";
import { useNavigate } from 'react-router-dom';
import { AdGroupSettingsStepPage } from '../../../components/ai-flow-campaign-creation-components/campaign-creation-components/ad-group-settings';
import { ProductsCardStepPage } from '../../../components/ai-flow-campaign-creation-components/campaign-creation-components/products-card';
import { TargetingAndManualTargetingKeywordStepPage } from '../../../components/ai-flow-campaign-creation-components/campaign-creation-components/targeting-and-manual-targeting-keyword';
import { KeywordTargetingStepPage } from '../../../components/ai-flow-campaign-creation-components/campaign-creation-components/keyword-targeting';
import { CampaignBiddingStrategyStepPage } from '../../../components/ai-flow-campaign-creation-components/campaign-creation-components/campaign-bidding-strategy';
import { NegativeKeywordTargetingStepPage } from '../../../components/ai-flow-campaign-creation-components/campaign-creation-components/negative-keyword-targeting';
import { AiFlowCampaignCreationStepper } from '../../../components/ai-flow-campaign-creation-components/stepper/label-context';

export const AiCampaignCreationStepper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const [showLeavePageModal, setShowLeavePageModal] = useState<boolean>(false);
    const [nextStepStatus, setNextStepStatus] = useState<boolean>(false);

    useEffect(() => {
    }, [])

    const onClickCancel = () => {
        setShowLeavePageModal(true);
    };

    const getAdgroupSettingsData = (data: any) => {
    }

    const onClickGobacktoSettings = () => {
        navigate("/dashboard/home/campaigns");
    }

    const stepsComponents = [
        <AdGroupSettingsStepPage adGroupSettingsData={(e) => getAdgroupSettingsData(e)} />,
        <ProductsCardStepPage />,
        <TargetingAndManualTargetingKeywordStepPage />,
        <KeywordTargetingStepPage />,
        <NegativeKeywordTargetingStepPage />,
        <CampaignBiddingStrategyStepPage />,
    ];

    const goToNextStep = () => {
        setNextStepStatus(true);
        if (nextStepStatus) {
            if (activeStep < stepsComponents.length - 1) {
                setActiveStep(activeStep + 1);
            }
        };
    }

    const onLeavePage = () => {
        setShowLeavePageModal(false);
        navigate("/dashboard/campaign-type");
    }

    return (
        <div className="container-fluid campaign-creation-bg">
            <div className="row fixed-top">
                <div className="col-12 px-0 ">
                    <nav className="navbar navbar-expand-lg bg-white shadow py-3">
                        <div className="container-fluid px-4">
                            <div className="d-flex">
                                <div className="bg-transaprent border-0 me-3">
                                    <img
                                        src={WrongIcon}
                                        className="mt-1 cursor-pointer"
                                        alt=""
                                        onClick={onClickCancel}
                                    />
                                </div>
                                <h2 className="navbar-brand campaign-navbar fs-18">
                                    New campaign{" "}
                                </h2>
                            </div>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div
                                            className="custom-explore-text-color me-2 fs-13 logout cursor-pointer py-2"
                                            onClick={onClickGobacktoSettings}
                                        >
                                            Go back to campaigns
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="row ai-campaign-stepper-scroll">
                    <div className='col-12 px-5 mt-5'>
                        <AiFlowCampaignCreationStepper />
                    </div>
                </div>
            </div>
            {showLeavePageModal && (
                <div
                    className={`modal modal-custom fade ${showLeavePageModal ? "show" : "false"
                        } ${showLeavePageModal ? "modal-show" : "modal-hide"}`}
                    tab-index="-1"
                    role="dialog"
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content leave-modal mx-auto">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">
                                    Leave Page?
                                </h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowLeavePageModal(false)}></button>
                            </div>
                            <div className="modal-body border-top p-0">
                                <div className="col-12 px-2 fs-13  py-3">
                                    <div className="d-flex justify-content-start">
                                        <div className="fs-13 ms-2 mt-1">Changes you made may not be saved.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="custom-explore-text-color border-0 bg-transparent fs-13 logout"
                                    onClick={() => setShowLeavePageModal(false)}
                                >
                                    Stay
                                </button>
                                <button
                                    type="button"
                                    className="request-call-button text-white fs-11"
                                    onClick={onLeavePage}
                                >
                                    Leave
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
