
import { CustomTableCol } from "../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const campaignReportsDataGridColumn: CustomTableCol[] = [
  {
    title: "Campaign Id",
    control: "campaignId",
    sortable: true,
    canShowColumn: true,
    class: "col_table",
    style: { width: "130px", left: 0},
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  
  {
    title: "Campaign",
    control: "campaignName",
    sortable: true,
    canShowColumn: true,
    class: "col_table",
    style: { width: "385px", left: 130,wordBreak: "break-all" },
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Status",
    control: "campaignStatus",
    sortable: true,
    canShowColumn: true,
    class: "col_table tb_left_border",
    style: { width: "100px", left: 515 },
  },
  {
    title: "Budget",
    control: "campaignBudgetAmount",
    sortable: true,
    canShowColumn: true,
   
    style: { width: "125px", left: 490 },
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Budget Type",
    control: "campaignBudgetType",
    sortable: true,
    canShowColumn: true,
    style: { width: "220px", left: 214 },
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Impressions",
    control: "impressions",
    sortable: true,
    canShowColumn: true,
    style: { width: "160px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Impressions"
    },
  },
  {
    title: "Top-of-search IS",
    control: "topOfSearchImpressionShare",
    sortable: true,
    canShowColumn: true,
    style: { width: "170px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Top of Search js"
    },
  },
  {
    title: "Clicks",
    control: "clicks",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Clicks"
    },
  },
  {
    title: "CTR",
    control: "clickThroughRate",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "CTR"
    },
  },
  {
    title: "Spend ",
    control: "cost",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Spend"
    },
  },
  {
    title: "CPC",
    control: "costPerClick",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns CPC"
    },
  },
  {
    title: "Orders",
    control: "purchases30d",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Orders"
    },
  },
  {
    title: "Sales",
    control: "Sales",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Sales"
    },
  },
  {
    title: "ACOS",
    control: "ACOS",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ACOS"
    },
  },
  {
    title: "ROAS",
    control: "ROAS",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ROAS"
    },
  },
  {
    title: "Conversion rate",
    control: "conversion_rate",
    sortable: true,
    canShowColumn: true,
    style: { width: "150px" },
    icon: info,
  },
  {
    title: "Total units",
    control: "unitsSoldClicks1d",
    sortable: true,
    canShowColumn: true,
    style: { width: "130px" },
    icon: info,
  },
];
