import React, { useEffect, useState } from 'react'
import { TooltipPage } from '../../custom-tooltip/tooltipPage';
import { Tooltip } from '@mui/material';
import { AiCampaignsTabsPage } from '../../campaign-creation/ai-campaigns-tabs-page';
import { AiCampaignDataProductsListCard } from '../../campaign-creation/ai-campaign-data-products-list-card';
import { GroupsTabsPage } from './groups-tabs';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

interface Props {
    addedProdcuts?: any
    addedKeywordSuggestionData?: any;
    selectedUniqueProducts?: any;
    uniqueProductsData?: any;
    selectedAiFlowProdcutsData?: boolean;
    aiFlowAddedKeywordsData?: any;
    campaignBiddingStrategyData?: (data: any) => void;
    aiFlowCampaignInfoData?: (data: any) => void;
    allAiKeywordCampainInfoData?: (data: any) => void;
    loadingStatus?: any;
    aiFlowAddedGroupdata?: any;
    isShowHandle?: any
    isShowData?: any
    triggerBiddingStrategyStepValidation?: any;
    CampaignBiddingCampaignDetailsDataReducer?: any;
    campaignBiddingCampainDetailsData?: any;
    AiCampaignLoadingDataRedcucer?: any;
    aiCampaignLoadingData?: any;
    aiCampaignLoadingData1?: (data: boolean) => void;
    isProceedWithAllProducts?: any;
}

const CampaignBiddingStrategyStepPage = (props: Props) => {
    const [campaignBiddinStrategy, setCampaignBiddingStrategy] = useState("Dynamic bids - down only");
    const [campaignBidStrategyData, setCampaignBidStrategyData] = useState<any>("LEGACY_FOR_SALES");
    const [showDynamicCampaignBid, setShowDynamicCampaignBid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignListLoading, setCampaignListLoading] = useState<boolean>(false)
    let { portfolio_uuid } = useParams<{ portfolio_uuid: any }>();
    const [aiKeywordCamapignloading, setAiKeywordCamapignloading] = useState<boolean>(false);
    const [selectedProductTabData, setSelectedProductTabData] = useState<any>([])
    const [groupUuid, setGroupUuid] = useState<any>('')
    const [campaignCreationData, setCampaignCreationData] = useState<any>({
        dynamicBidding_percentage: "0",
        dynamicBidding_percentage_product: "0",
        dynamicBidding_percentage_rest: "0",
        dynamicBidding_placement: "PLACEMENT_TOP",
        dynamicBidding_placement_product: "PLACEMENT_PRODUCT_PAGE",
        dynamicBidding_placement_rest: "PLACEMENT_REST_OF_SEARCH",
        dynamicBidding_strategy: campaignBidStrategyData ? campaignBidStrategyData : "LEGACY_FOR_SALES"
    });
    const [selectedProductsData, setSelectedProductsData] = useState<any>([]);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [isId, setId] = useState<any>('');
    const [formErrors, setFormErrors] = useState<any>({});
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = portfolio_id;

    useEffect(() => {
        if (props?.campaignBiddingStrategyData) {
            props?.campaignBiddingStrategyData(campaignCreationData)
        }
    }, [campaignCreationData])


    useEffect(() => {
        if (props?.uniqueProductsData?.length > 0) {
            setSelectedProductsData(props?.uniqueProductsData)
        }
    }, [])

    useEffect(() => {
        for (let i = 0; i < props?.aiFlowAddedGroupdata?.length; i++) {
            setId(props?.aiFlowAddedGroupdata[0].products_data[0].uuid);
        }
    }, [])

    const groupHandleClick = (data: any) => {
        setId(data)
    }

    useEffect(() => {
        for (let i = 0; i < props?.aiFlowAddedGroupdata?.length; i++) {
            setGroupUuid(props?.aiFlowAddedGroupdata[0].products_data[0].uuid);
        }
    }, [])

    const HandleClick = (data: any) => {
        setGroupUuid(data)
    }

    useEffect(() => {
        if (props?.aiCampaignLoadingData?.status) {
            setAiKeywordCamapignloading(true);
        } else {
            setAiKeywordCamapignloading(false);
        }
    }, [props?.aiCampaignLoadingData])

    useEffect(() => {
        if (props?.triggerBiddingStrategyStepValidation?.value) {
            const isValid = vaidateCampaignBiddingForm();
            if (isValid) {
            }
        }
    }, [props?.triggerBiddingStrategyStepValidation?.value])

    useEffect(() => {
        if (portfolioId == 1) {
            const selectedProdcustWithKeywords = props?.aiFlowAddedKeywordsData
            for (let i = 0; i < selectedProdcustWithKeywords?.length; i++) {
                if (selectedProductTabData?.uuid) {
                    if (selectedProdcustWithKeywords[i]?.uuid === selectedProductTabData?.uuid) {
                        selectedProdcustWithKeywords[i].isVisibleKeyWordData = true;
                    } else {
                        selectedProdcustWithKeywords[i].isVisibleKeyWordData = false;
                    }
                } else {
                    selectedProdcustWithKeywords[0].isVisibleKeyWordData = true;
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
                const element = selectedProdcustWithKeywords[i]?.add_keywords;
                for (let j = 0; j < element?.length; j++) {
                    const data = {
                        campaign_name: ``,
                        portfolio_id: portfolio_uuid ? portfolio_uuid : "",
                        budget: "",
                        start_date: "",
                        end_date: "",
                        roas: ""
                    }
                    element[j].keyword_camapign_info = data
                }
            }
        }
        if (portfolioId == 2) {
            const selectedProdcustWithKeywords = props?.aiFlowAddedGroupdata
            for (let i = 0; i < selectedProdcustWithKeywords?.length; i++) {
                if (selectedProductTabData?.uuid) {
                    if (selectedProdcustWithKeywords[i]?.uuid === selectedProductTabData?.uuid) {
                        selectedProdcustWithKeywords[i].isVisibleKeyWordData = true;
                    } else {
                        selectedProdcustWithKeywords[i].isVisibleKeyWordData = false;
                    }
                } else {
                    selectedProdcustWithKeywords[0].isVisibleKeyWordData = true;
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
                const element = selectedProdcustWithKeywords[i]?.keywords_data;
                const data = {
                    // campaign_name:  `SP_M_GROUP_${element&&element[0]?.keywordText}_${element&&element[0]?.keyword_matchType}_${selectedProdcustWithKeywords[i]?.products_data[0]?.asin}_${moment().valueOf()}`,
                    campaign_name: `adv_SPM_kw_gr-${selectedProdcustWithKeywords[i]?.group_name}_port_${moment().format('YYYY-MM-DD')}_${i}`,
                    portfolio_id: portfolio_uuid ? portfolio_uuid : "",
                    budget: "",
                    start_date: moment().format('YYYY-MM-DD'),
                    end_date: "",
                    roas: "",
                    // qualifier: ""
                }
                selectedProdcustWithKeywords[i].keyword_camapign_info = data;
            }
        }
    }, [])

    const tooltipContent = (data: any) => {
        if (data === "Campaign bidding strategy") {
            return (
                <TooltipPage
                    tooltipMainContent={"Choose how you want to pay for clicks on your ads."}
                    tooltipSubContent={""}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        } else if (data === "Dynamic bids - up and down") {
            return (
                <TooltipPage
                    tooltipMainContent={"We’ll raise your bids in real time when your ad may be more likely to convert to a sale, and lower your bids when less likely to convert to a sale."}
                    tooltipSubContent={"Learn about dynamic bids - up and down"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        } else if (data === "Dynamic bids - down only") {
            return (
                <TooltipPage
                    tooltipMainContent={"We will lower your bids in real time when your ad may be less likely to convert to a sale."}
                    tooltipSubContent={"Learn about dynamic bids - down only"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        } else if (data === "Fixed bids") {
            return (
                <TooltipPage
                    tooltipMainContent={"We will use your exact bid and any manual adjustments you set and will not change your bids based on likelihood of a sale."}
                    tooltipSubContent={"Learn about fixed bids"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        } else if (data === "Adjust bids by placement") {
            return (
                <TooltipPage
                    tooltipMainContent={"Apply different bids by placement by entering a percentage increase to your base bid for 2 placements: top of search (first page) and product pages."}
                    tooltipSubContent={"Learn about bids by placement"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        }
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const onChangeCampaignBiddingType = (campaignBidText: string) => {
        setCampaignBiddingStrategy(campaignBidText);
        if (campaignBidText === "Dynamic bids - up and down") {
            setShowDynamicCampaignBid(true);
            setCampaignBidStrategyData("AUTO_FOR_SALES");
        } else if (campaignBidText === "Dynamic bids - down only") {
            setShowDynamicCampaignBid(false);
            setCampaignBidStrategyData("LEGACY_FOR_SALES")
        } else if (campaignBidText === "Fixed bids") {
            setShowDynamicCampaignBid(false);
            setCampaignBidStrategyData("MANUAL")
        }
    };

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        const data = campaignCreationData;
        data[name] = value;
        data['dynamicBidding_strategy'] = campaignBidStrategyData ? campaignBidStrategyData : "LEGACY_FOR_SALES"
        setCampaignCreationData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
        if (props?.campaignBiddingStrategyData) {
            props?.campaignBiddingStrategyData(data)
        } else {
            if (props?.campaignBiddingStrategyData) {
                props?.campaignBiddingStrategyData(campaignCreationData)
            }
        }
    }

    const vaidateCampaignBiddingForm = () => {
        const errors: any = {}
        if (campaignCreationData?.dynamicBidding_percentage < 0 || campaignCreationData?.dynamicBidding_percentage == "") {
            errors.dynamicBidding_percentage = "Please enter top of search value";
        }

        if (campaignCreationData?.dynamicBidding_percentage_product < 0 || campaignCreationData?.dynamicBidding_percentage_product == "") {
            errors.dynamicBidding_percentage_product = "Please enter product pages value";
        }

        if (campaignCreationData?.dynamicBidding_percentage_rest < 0 || campaignCreationData?.dynamicBidding_percentage_rest == "") {
            errors.dynamicBidding_percentage_rest = "Please enter rest of search value";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "dynamicBidding_percentage") {
            if (!value) {
                return "Please enter top of search value"
            } else if (value < 0) {
                return "Top of search value should be minimum 0."
            }
        }

        if (name === "dynamicBidding_percentage_product") {
            if (!value) {
                return "Please enter product pages value"
            } else if (value < 0) {
                return "Product pages value should be minimum 0."
            }
        }

        if (name === "dynamicBidding_percentage_rest") {
            if (!value) {
                return "Please enter rest of search value"
            } else if (value < 0) {
                return "Rest of search value should be minimum 0."
            }
        }
        return "";
    }

    const getAiFlowCampaignSettingsData = (data: any) => {
        if (data) {
            if (props?.aiFlowCampaignInfoData) {
                props?.aiFlowCampaignInfoData(data)
            }
        }
    }

    const getAllAiKeywordsData = (data: any) => {
        if (data) {
            if (props?.allAiKeywordCampainInfoData) {
                props?.allAiKeywordCampainInfoData(data)
            }
        }
    }

    const getSelectedProdcutData = (data: any) => {
        const getData: any = localStorage.getItem('final_prepared_data');
        setSelectedProductTabData(data);
        setCampaignListLoading(true);
        setTimeout(() => {
            setCampaignListLoading(false);
        }, 1000);
        setGroupUuid(data.uuid)
    }

    const getGroupSelectedProductData = (data: any) => {
        const getData: any = localStorage.getItem('final_prepared_data');
        const valueTwo = JSON.parse(getData)
        setSelectedProductTabData(data);
        setCampaignListLoading(true);
        setTimeout(() => {
            setCampaignListLoading(false);
        }, 1000);
        setGroupUuid(data.uuid)
    }

    const getByDefaultData = (data: any) => {
        props.isShowHandle(data);
    }

    return (
        <div>
            {aiKeywordCamapignloading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {!aiKeywordCamapignloading && (
                <>
                    <div className="campaign-bidding-card-container ">
                        {/* card-width mx-auto */}
                        <div className="card text-bg-white ad-group-settings mb-4 my-3">
                            <div className="card-header bg-white">
                                <div className="d-flex justify-content-between py-2">
                                    <div className="fs-24 fw-light">
                                        Campaign bidding strategy
                                        <Tooltip
                                            title={tooltipContent("Campaign bidding strategy")}
                                            placement="top"
                                            arrow
                                            className="custom-tooltip-position cursor-pointer"
                                        >
                                            <span className="ms-1 cursor-pointer">
                                                <svg
                                                    viewBox="0 0 512 512"
                                                    width="12"
                                                    height="12"
                                                    className="fas fa-info-circle"
                                                    data-fa-i2svg=""
                                                >
                                                    <path
                                                        fill="#8290a4"
                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                                        <span>
                                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                        </span>
                                        How to set your bid strategy
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input mb-3 me-3"
                                        name="campaign-bid-status"
                                        id="bidUpAndDown"
                                        checked={
                                            campaignBiddinStrategy === "Dynamic bids - up and down"
                                        }
                                        onChange={() =>
                                            onChangeCampaignBiddingType(
                                                "Dynamic bids - up and down"
                                            )
                                        }
                                    />
                                    <label className="cursor-pointer" htmlFor="bidUpAndDown">
                                        <div className="fs-13">
                                            Dynamic bids - up and down
                                            <Tooltip
                                                title={tooltipContent("Dynamic bids - up and down")}
                                                placement="top"
                                                arrow
                                                className="custom-tooltip-position cursor-pointer"
                                            >
                                                <span className="ms-1">
                                                    <svg
                                                        viewBox="0 0 512 512"
                                                        width="12"
                                                        height="12"
                                                        className="fas fa-info-circle"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="#8290a4"
                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="fs-13">
                                            We will raise your bids (by a maximum of 100%) in real
                                            time when your ad may be more likely to{" "}
                                        </div>
                                        <div className="fs-13">
                                            convert to a sale, and lower your bids when less likely
                                            to convert to a sale.
                                        </div>
                                    </label>
                                </div>
                                <div className="d-flex form-check my-2">
                                    <input
                                        type="radio"
                                        className="form-check-input mb-3 me-3"
                                        name="campaign-bid-status"
                                        id="bidDownOnly"
                                        checked={
                                            campaignBiddinStrategy === "Dynamic bids - down only"
                                        }
                                        onChange={() =>
                                            onChangeCampaignBiddingType("Dynamic bids - down only")
                                        }
                                    />
                                    <label className="cursor-pointer" htmlFor="bidDownOnly">
                                        <div className="fs-13">
                                            Dynamic bids - down only
                                            <Tooltip
                                                title={tooltipContent("Dynamic bids - down only")}
                                                placement="top"
                                                arrow
                                                className="custom-tooltip-position cursor-pointer"
                                            >
                                                <span className="ms-1">
                                                    <svg
                                                        viewBox="0 0 512 512"
                                                        width="12"
                                                        height="12"
                                                        className="fas fa-info-circle"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="#8290a4"
                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="fs-13">
                                            We will lower your bids in real time when your ad may be
                                            less likely to convert to a sale.
                                        </div>
                                    </label>
                                </div>
                                <div className="d-flex form-check my-2">
                                    <input
                                        type="radio"
                                        className="form-check-input mb-3 me-3"
                                        name="campaign-bid-status"
                                        id="fixedBids"
                                        checked={campaignBiddinStrategy === "Fixed bids"}
                                        onChange={() => onChangeCampaignBiddingType("Fixed bids")}
                                    />
                                    <label className="cursor-pointer" htmlFor="fixedBids">
                                        <div className="fs-13">
                                            Fixed bids
                                            <Tooltip
                                                title={tooltipContent("Fixed bids")}
                                                placement="top"
                                                arrow
                                                className="custom-tooltip-position cursor-pointer "
                                            >
                                                <span className="ms-1">
                                                    <svg
                                                        viewBox="0 0 512 512"
                                                        width="12"
                                                        height="12"
                                                        className="fas fa-info-circle"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="#8290a4"
                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="fs-13">
                                            We will use your exact bid and any manual adjustments
                                            you set and will not change your bids based on
                                            likelihood of a sale.
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <div
                                        className="accordion accordion-flush"
                                        id="accordionFlushAdjustPlacement"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="flush-headingAdjust"
                                            >
                                                <button
                                                    className="accordion-button adjust-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseAdjust-placement"
                                                    aria-expanded="true"
                                                    aria-controls="flush-collapseAdjust-placement"
                                                >
                                                    <span className="custom-explore-text-color fs-13 logout cursor-pointer ps-3">
                                                        Adjust bids by placement
                                                    </span>
                                                    <Tooltip
                                                        title={tooltipContent("Adjust bids by placement")}
                                                        placement="top"
                                                        arrow
                                                        className="custom-tooltip-position cursor-pointer"
                                                    >
                                                        <span className="ms-1">
                                                            <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle mb-3" data-fa-i2svg=""
                                                            >
                                                                <path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </button>
                                            </h2>
                                            <div
                                                id="flush-collapseAdjust-placement"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingAdjust"
                                                data-bs-parent="#accordionFlushAdjustPlacement"
                                            >
                                                <div className="accordion-body px-4">
                                                    <div className="fs-13">
                                                        In addition to your bidding strategy, you can
                                                        increase bids by up to 900%.Learn more
                                                    </div>
                                                    <div className="d-flex my-1">
                                                        <div className="fs-13 w-18 py-1">
                                                            Top of search (first page)
                                                        </div>
                                                        <div className="fs-13 w-15 px-3">
                                                            <input
                                                                type="number"
                                                                name="dynamicBidding_percentage"
                                                                className="form-control border-dark fs-13"
                                                                defaultValue={0}
                                                                onChange={(e) => handleInput(e)}
                                                                placeholder=""
                                                            />
                                                            <span className="percentage-position">%</span>
                                                            {formErrors.dynamicBidding_percentage && (<div className="text-danger">{formErrors?.dynamicBidding_percentage}</div>)}
                                                        </div>
                                                        {showDynamicCampaignBid && (
                                                            <div className="fs-13">
                                                                Example: A ₹3.00 bid will remain ₹3.00 for
                                                                this placement. Dynamic bidding could increase
                                                                it up to ₹6.00
                                                            </div>
                                                        )}
                                                        {!showDynamicCampaignBid && (
                                                            <div className="fs-13 py-1">
                                                                Example: A ₹3.00 bid will remain ₹3.00 for
                                                                this placement.
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="d-flex my-1">
                                                        <div className="fs-13 w-18 py-1">Rest of search</div>
                                                        <div className="fs-13 w-15 px-3">
                                                            <input
                                                                type="number"
                                                                name="dynamicBidding_percentage_rest"
                                                                className="form-control border-dark fs-13"
                                                                placeholder=""
                                                                defaultValue={0}
                                                                onChange={(e) => handleInput(e)}
                                                            />
                                                            <span className="percentage-position">%</span>
                                                            {formErrors.dynamicBidding_percentage_rest && (<div className="text-danger">{formErrors?.dynamicBidding_percentage_rest}</div>)}
                                                        </div>
                                                        {showDynamicCampaignBid && (
                                                            <div className="fs-13">
                                                                Example: A ₹3.00 bid will remain ₹3.00 for
                                                                this placement. Dynamic bidding could increase
                                                                it up to ₹4.50
                                                            </div>
                                                        )}
                                                        {!showDynamicCampaignBid && (
                                                            <div className="fs-13 py-1">
                                                                Example: A ₹3.00 bid will remain ₹3.00 for
                                                                this placement.
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="d-flex my-1">
                                                        <div className="fs-13 w-18 py-1">Product pages</div>
                                                        <div className="fs-13 w-15 px-3">
                                                            <input
                                                                type="number"
                                                                name="dynamicBidding_percentage_product"
                                                                className="form-control border-dark fs-13"
                                                                defaultValue={0}
                                                                onChange={(e) => handleInput(e)}
                                                                placeholder=""
                                                            />
                                                            <span className="percentage-position">%</span>
                                                            {formErrors.dynamicBidding_percentage_product && (<div className="text-danger">{formErrors?.dynamicBidding_percentage_product}</div>)}
                                                        </div>
                                                        {showDynamicCampaignBid && (
                                                            <div className="fs-13 ">
                                                                Example: A ₹3.00 bid will remain ₹3.00 for
                                                                this placement. Dynamic bidding could increase
                                                                it up to ₹4.50
                                                            </div>
                                                        )}
                                                        {!showDynamicCampaignBid && (
                                                            <div className="fs-13 py-1">
                                                                Example: A ₹3.00 bid will remain ₹3.00 for
                                                                this placement.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-card-container">
                        {/* card-width mx-auto */}
                        <div className="card text-bg-white ad-group-settings mb-3 my-0">
                            <div className="card-body">
                                {/* {portfolioId == 2 && (
                                    <div className="mx-1 pe-5">
                                        <h5 className='ms-3'>Groups</h5>
                                        <div className='my-2'><GroupsTabsPage data={props.aiFlowAddedGroupdata}
                                            isShowHandle={(data: any) => props.isShowHandle(data)}
                                            byDefaultTabData={getByDefaultData}
                                            groupHandleClick={(e: any) => groupHandleClick(e)}
                                            isShowData={props.isShowData}
                                            HandleClick={(e: any) => HandleClick(e)}
                                        /></div>
                                    </div>
                                )} */}
                                <>
                                    {/* <div className="my-3 mx-1 pe-4">
                                        <h5 className='ms-3'>Products</h5>
                                        <AiCampaignsTabsPage
                                            aiCampaignsProductsData={props?.aiFlowAddedKeywordsData}
                                            aiFlowKeywordSelectedTabTargetingData={(e: any) => getSelectedProdcutData(e)} cardDataShow={true}
                                            isShowData={props?.isShowData}
                                            aiFlowAddedGroupdata={props?.aiFlowAddedGroupdata}
                                            aiFlowGroupSelectedTabTargetingData={(e: any) => getGroupSelectedProductData(e)}
                                            isId={isId}
                                            isFromBiddingStratey={true}
                                        />
                                    </div> */}
                                    {campaignListLoading && (<>
                                        <div className="text-center p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    </>)
                                    }
                                    {!campaignListLoading &&
                                        <AiCampaignDataProductsListCard addedKeywordSuggestionData={props?.aiFlowAddedKeywordsData}
                                            aiFlowCampaignSettingsData={(e) => getAiFlowCampaignSettingsData(e)} selectedProductTab={selectedProductTabData}
                                            allAiKeywordData={(e) => getAllAiKeywordsData(e)}
                                            isShowData={props?.isShowData}
                                            addedGroupKeywordSuggestionData={props?.aiFlowAddedGroupdata}
                                            byDefaultTabData={getByDefaultData}
                                            triggercamapignSettingsValidation={props?.triggerBiddingStrategyStepValidation}
                                            groupUuid={groupUuid}
                                            isProceedWithAllProducts={props?.isProceedWithAllProducts}
                                            allProducts={props?.addedProdcuts}
                                        />}
                                </>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer,
        campaignBiddingCampainDetailsData: state.CampaignBiddingCampaignDetailsDataReducer,
        aiCampaignLoadingData: state.AiCampaignLoadingDataRedcucer
    };
};

const connectedNavBar = connect(mapStateToProps)(CampaignBiddingStrategyStepPage);
export { connectedNavBar as CampaignBiddingStrategyStepPage };
