import React, { useState } from 'react'
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../custom-tooltip/tooltipPage';

interface Props {
    addedNegativeKeywordTargetingData?: (data: any) => void;
}

export const NegativeKeywordTargetingCard = (props: Props) => {
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [adGroupNegativeKeywordMatchTypeStatus, setAdGroupNegativeKeywordMatchTypeStatus] = useState<boolean>(false);
    const [adGroupNegativeKeywordMatchTypeText, setAdGroupNegativeKeywordMatchTypeText] = useState<any>('Negative exact')
    const [negativeKeywordText, setNegativeKeywordText] = useState<any>({
        negative_keyword_text: ""
    })
    const [negativeKeywordTextStatus, setNegativeKeywordTextStatus] = useState<boolean>(false);
    const [negativeKeywordsData, setNegativeKeywrodsData] = useState<any>([])
    const [successMessage, setSuccessMessage] = useState('');
    const [isAddingKeywords, setIsAddingKeywords] = useState<boolean>(false);

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    };

    const tooltipContent = (tooltipTitle: string) => {
        if (tooltipTitle === "negative Keyword targeting card header optional") {
            return (
                <TooltipPage
                    tooltipMainContent={"Negative keywords prevent your ads from displaying when a shopper's search terms match your negative keywords. You can exclude irrelevant searches, reducing your advertising cost."}
                    tooltipSubContent={"Learn about ad groups"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "negative Keyword targeting Match type") {
            return (
                <TooltipPage
                    tooltipMainContent={`Negative keyword match types allow you to fine-tune which customer search 
                        terms trigger your ads.`}
                    tooltipSubContent={"Learn about negative match types"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "negative Keyword targeting second table Match type") {
            return (
                <TooltipPage
                    keyWordBroad={
                        " Contains all the keywords in any order and includes plurals, variations and related keywords."
                    }
                    keyWordPhrase={" Contains the exact phrase or sequence of keywords."}
                    keyWordExact={"Exactly matches the keyword or sequence of keywords"}
                    tooltipLearnMoreText={"Learn More"}
                    keyWordTableText={
                        "Match types allow you to fine-tune which customer searches trigger your ads."
                    }
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        }
    }

    const handleChangeAdGroupMatchType = (data: any) => {
        setAdGroupNegativeKeywordMatchTypeText(data)
        if (data === "Negative exact") {
            setAdGroupNegativeKeywordMatchTypeStatus(true);
        } else {
            setAdGroupNegativeKeywordMatchTypeStatus(false);
        }
    }
    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setNegativeKeywordText((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        const data = e.target.value;
        if (data?.trim()?.length > 0) {
            setNegativeKeywordTextStatus(true);
        } else {
            setNegativeKeywordTextStatus(false);
        }
        setNegativeKeywordText(data)
        setIsAddingKeywords(false);
    }

    const onClickAddKeywords = () => {
        setIsAddingKeywords(true);
        const data = {
            negative_keyword_matchType: adGroupNegativeKeywordMatchTypeText === "Negative exact" ? "NEGATIVE_EXACT" : "NEGATIVE_PHRASE",
            negative_keyword_text: negativeKeywordText,
            state: "ENABLED"
        }
        negativeKeywordsData.push(data)
        setSuccessMessage('1/1 keywords were added successfully.');
        setNegativeKeywrodsData([...negativeKeywordsData]);
        setNegativeKeywordText({
            ...negativeKeywordText,
            negative_keyword_text: ""
        });
        setNegativeKeywordTextStatus(false);
        if (props?.addedNegativeKeywordTargetingData) {
            props?.addedNegativeKeywordTargetingData(negativeKeywordsData)
        }
    }

    const onClickCancelNegativeKeywordData = (index: number) => {
        const data = negativeKeywordsData;
        data.splice(index, 1);
        setNegativeKeywrodsData([...data])
    }

    const onClickRemoveAllData = () => {
        setNegativeKeywrodsData([]);
        setNegativeKeywordText("")
        setSuccessMessage('');
        setNegativeKeywordTextStatus(false);
    }

    return (
        <div className="negative-keyword-targeting-container card-width mx-auto mb-3">
            <div className="accordion accordion-flush negative-keyword-container-add-group-creation" id="accordionOne">
                <div className="accordion-item border rounded">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button
                            className="accordion-button down-arrow ad-groups-negative-keyword-targeting-accordion down-arrow-one collapsed fs-24 fw-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="true"
                            aria-controls="flush-collapseOne"
                        >
                            <span className="px-3">Negative keyword targeting</span>
                            <Tooltip
                                title={tooltipContent(
                                    "negative Keyword targeting card header optional"
                                )}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer "
                            >
                                <span className="mx-1">
                                    <svg
                                        viewBox="0 0 512 512"
                                        width="12"
                                        height="12"
                                        className="fas fa-info-circle mb-3"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                        ></path>
                                    </svg>
                                </span>
                            </Tooltip>
                            <div className="d-flex justify-content-between">
                                <div className="ms-auto">
                                    <span className="fs-13 optional-text-color mt-2 ms-auto">
                                        optional
                                    </span>
                                </div>
                                <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2 keyword-target-position">
                                    <span>
                                        <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                    </span>
                                    How to use negative keywords
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionOne"
                    >
                        <div className="accordion-body border-top py-0">
                            <div className="row px-3">
                                <div className="col-6 bg-white products-tabs-section border-right">
                                    <div className="d-flex justify-content-between my-2">
                                        <div className="mx-2 fs-12 fw-bold my-1">
                                            Match type
                                            <Tooltip
                                                title={tooltipContent(
                                                    "negative Keyword targeting Match type"
                                                )}
                                                placement="top"
                                                arrow
                                                className="custom-tooltip-position cursor-pointer "
                                            >
                                                <span className="mx-1">
                                                    <svg
                                                        viewBox="0 0 512 512"
                                                        width="12"
                                                        height="12"
                                                        className="fas fa-info-circle"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="#8290a4"
                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="mx-2">
                                            <div className="d-flex form-check">
                                                <input
                                                    type="radio"
                                                    id="matchTypeTwo"
                                                    name="ad_group_match_type"
                                                    defaultChecked={adGroupNegativeKeywordMatchTypeText === "Negative exact"}
                                                    className="form-check-input mb-3 me-3"
                                                    onChange={() => handleChangeAdGroupMatchType(`Negative exact`)}
                                                />
                                                <label className="my-1 cursor-pointer" htmlFor='matchTypeTwo'>
                                                    <div className="fs-12">Negative exact</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mx-2">
                                            <div className="d-flex form-check">
                                                <input
                                                    type="radio"
                                                    name="ad_group_match_type"
                                                    id="matchType"
                                                    className="form-check-input mb-3 me-3"
                                                    defaultChecked={adGroupNegativeKeywordMatchTypeText === "Negative phrase"}
                                                    onChange={() => handleChangeAdGroupMatchType(`Negative phrase`)}
                                                />
                                                <label className="my-1 cursor-pointer" htmlFor='matchType'>
                                                    <div className="fs-12">Negative phrase</div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-1">
                                        <div className="my-0">
                                            <textarea
                                                rows={8}
                                                name="negative_keyword_text"
                                                className="form-control fs-14 border-dark"
                                                placeholder="Enter keywords separated by new line."
                                                value={negativeKeywordText?.negative_keyword_text}
                                                onChange={(e) => (handleInput(e))}
                                            ></textarea>
                                        </div>
                                        <div className=''>
                                            {successMessage && (
                                                <span className='fs-13'>
                                                    <span><i className="bi bi-check text-success fs-21"></i></span><span className="text-success ml-2 fs-13">{successMessage}</span>
                                                </span>
                                            )}
                                        </div>
                                        <div className="text-end my-2">
                                            <button className={`btn btn-dark border-0 rounded-pill  custom-button-background fs-12 ${isAddingKeywords ? "cursor-pointer-not-allowed text-secondary" : `${negativeKeywordText ? "cursor-pointer text-dark" : "cursor-pointer-not-allowed text-secondary"}`}
                                             ${negativeKeywordTextStatus ? "cursor-pointer text-dark" : "cursor-pointer-not-allowed text-secondary"}`}
                                                disabled={!negativeKeywordTextStatus}
                                                onClick={onClickAddKeywords}>
                                                Add keywords
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 product-preview-container p-0">
                                    <div className="negative-keyword-right-container ">
                                        <div className="d-flex justify-content-between py-2 px-3">
                                            <div className="fs-13 fw-bold ">{negativeKeywordsData?.length}added</div>
                                            <div className={`fs-13  ${negativeKeywordsData?.length > 0 ? "custom-explore-text-color-two cursor-pointer" : "cursor-pointer-not-allowed remove-all-text"}`}
                                                onClick={onClickRemoveAllData}>
                                                Remove all
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between border-top border-bottom py-2 px-3">
                                            <div className="fs-13">Keyword</div>
                                            <div className="fs-13 me-5 pe-4">
                                                <div className="mx-2 fs-12">
                                                    Match type
                                                    <Tooltip
                                                        title={tooltipContent(
                                                            "negative Keyword targeting second table Match type"
                                                        )}
                                                        placement="top"
                                                        arrow
                                                        className="custom-tooltip-position cursor-pointer "
                                                    >
                                                        <span className="mx-1 cursor-pointer">
                                                            <svg
                                                                viewBox="0 0 512 512"
                                                                width="12"
                                                                height="12"
                                                                className="fas fa-info-circle"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="#8290a4"
                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${negativeKeywordsData?.length > 12 ? "negative-keyword-data-scroll" : ""}`}>
                                            {negativeKeywordsData?.length > 0 && negativeKeywordsData?.map((data: any, index: number) => (
                                                <div key={index} className='d-flex justify-content-between border-bottom px-3'>
                                                    <div className='fs-13 my-2'>{data?.negative_keyword_text}</div>
                                                    <div className='d-flex justify-content-start me-4 '>
                                                        <div className='fs-13 my-2'>{data?.negative_keyword_matchType === "NEGATIVE_EXACT" ? "Negative exact" : "Negative phrase "}</div>
                                                        <div className="fs-21 ms-2 ">
                                                            <i className="bi bi-x cursor-pointer " onClick={() => onClickCancelNegativeKeywordData(index)}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
