import { CustomTableCol } from "../../../components/custom-table/types";
const downArrow = "/assets/images/svg_icons/down-arrow-fill.svg";

export const CampaignsDraftsDataGridCols: CustomTableCol[] = [
    {
        title: "",
        control: "checkbox",
        sortable: false,
        canShowColumn: true,
        style: { width: "60px", left: 0, height: "40px" },
        icon: downArrow,
    },
    {
        title: "Campaigns",
        control: "name",
        sortable: true,
        canShowColumn: true,
        style: { width: "200px", left: 0 },
    },
    {
        title: "Type",
        control: "campaign_type",
        sortable: true,
        canShowColumn: true,
        style: { width: "70px" },
    },
    {
        title: "Status",
        control: "draftCampaignState",
        sortable: true,
        canShowColumn: true,
        style: { width: "70px" },
    },
    {
        title: "Actions",
        control: "actions",
        sortable: true,
        canShowColumn: true,
        isEdit: true,
        isDelete: true,
        style: { width: '100px' },
    }
];
