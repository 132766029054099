import * as React from 'react';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

interface CustomStepLabelProps {
    active: boolean;
    label: string;
    completed: boolean;
    onClick: () => void;
}

const CustomStepLabel: React.FC<CustomStepLabelProps> = ({ label, active, completed, onClick }) => {
    return (
        <StepLabel
            className={`cursor-pointer  ${completed ? 'completed' : ''}`}
            onClick={onClick}
        >
            <div className="stepper-label">
                <Typography variant="caption" className="d-flex justify-content-center step-label-text">
                    {label}
                </Typography>
            </div>
        </StepLabel>
    );
};

export default CustomStepLabel;