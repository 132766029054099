import React from 'react'
import { Routes, Route } from "react-router-dom";
import { CampaingConfigurationPage } from '../pages/Dashboard/configuration/campaign/campaign';
import { GroupConfigurationPage } from '../pages/Dashboard/configuration/campaign/groups/groups';
import { ConfigurationGroupInfoPage } from '../pages/Dashboard/configuration/campaign/groups/group-info/group-info';

export const ConfigurationRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<CampaingConfigurationPage />}></Route>
                <Route path="/campaing" element={<CampaingConfigurationPage />}></Route>
                <Route path="/groups" element={<GroupConfigurationPage />}></Route>
                <Route path="/groups-info/*" element={<ConfigurationGroupInfoPage />}></Route>
            </Routes>
        </div>
    )
}
