import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomTable } from '../../../components/custom-table';
import { CampaignsDraftsDataGridCols } from './campaigns-drafts-data-grid-cols';

export const CampaignDrafts = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)

    const campaignDraftsTableData: any = {
        page: "0",
        limit: "10",
        total: 14,
        rows: [
            {
                id: "AC38SDZM9GWO14C",
                name: "SB_M_Idli_BKT_Enso(1)",
                draftCampaignState: "DRAFT",
                campaign_type: "Sponsored Products",
                programType: "HSA",
                budget: {
                    millicents: 5000000,
                    currencyCode: "INR",
                    budgetType: "DAILY"
                },
                creationDate: 1691087400000
            },
            {
                id: "AC38SDZM9GWO14C",
                name: "Test campaign",
                draftCampaignState: "DRAFT",
                campaign_type: "Sponsored Products",
                programType: "HSA",
                budget: {
                    millicents: 5000000,
                    currencyCode: "INR",
                    budgetType: "DAILY"
                },
                creationDate: 1691087400000
            },
        ]
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [])

    const onClikCreateCampaign = () => {
        navigate("/dashboard/campaign-type");
    }

    return (
        <div className="row">
            <div className="col-12  my-3">
                <button className="request-call-button text-white fs-13" onClick={onClikCreateCampaign}>Create campaign</button>
                {campaignDraftsTableData?.rows?.length === 0 && (
                    <div className="fs-13 my-2">
                        <span className="fs-13 ms-1">
                            <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle mb-1" data-fa-i2svg="" >
                                <path fill="rgb(0, 115, 199)"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                            </svg>
                        </span>
                        <span className="fs-12 ms-2">You do not have any draft campaigns</span>
                    </div>
                )}
            </div>
            {campaignDraftsTableData?.rows?.length > 0 && (
                <div className='col-12'>
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <CustomTable
                            tableData={campaignDraftsTableData}
                            TableCols={CampaignsDraftsDataGridCols}
                            tableLoading={!loading}
                            tableWithoutFixedCoulmns={true}
                            customNodataErrorMessage="You do not have any draft campaigns"
                        />
                    }
                </div>
            )}
        </div>
    )
}