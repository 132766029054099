import React, { useState } from 'react'
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../custom-tooltip/tooltipPage';

export const ManualTargetingCard = () => {
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [manualTargetingOption, setManualTargetingOption] = useState("keyword");
    const [showManualKeywordTargeting, setShowManualKeywordTargeting] = useState<any>(false);
    const [showManualProductTargeting, setShowManualProductTargeting] = useState<any>(false);

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const tooltipContent = (tooltipTitle: string) => {
        if (tooltipTitle === "Manual Targeting") {
            return (
                <TooltipPage
                    tooltipMainContent={"Targeting uses keywords and products to help your ads appear in search and detail pages."}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "Keyword targeting") {
            return (
                <TooltipPage
                    tooltipMainContent={"Keyword targeting allows you to choose keywords to show your products in customer searches and detail pages."}
                    tooltipSubContent={"Learn about keyword targeting"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        }
    }

    const handleChangeManualTargeting = (option: any) => {
        setManualTargetingOption(option);
        if (option === "product") {
            setShowManualKeywordTargeting(true);
            setShowManualProductTargeting(true);
        } else {
            setShowManualKeywordTargeting(false);
        }
    };
    return (
        <div className="manual-targeting-card-container card-width mx-auto mt-0 mb-1">
            <div className="card text-bg-white ad-group-settings mb-1">
                <div className="card-header bg-white">
                    <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">
                            Manual Targeting
                            <Tooltip
                                title={tooltipContent("Manual Targeting")}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer"
                            >
                                <span className="ms-1">
                                    <svg
                                        viewBox="0 0 512 512"
                                        width="12"
                                        height="12"
                                        className="fas fa-info-circle mb-1"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                        ></path>
                                    </svg>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                            <span>
                                <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                            </span>
                            How to use keywords or products for manual targeting
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex form-check">
                        <input
                            type="radio"
                            id="manualTargetingOptionTwo"
                            name="manual-target-option"
                            className="form-check-input mb-3 me-3"
                            checked={manualTargetingOption === "keyword"}
                            onChange={() => handleChangeManualTargeting("keyword")}
                        />
                        <label htmlFor='manualTargetingOptionTwo' className='cursor-pointer'>
                            <div className="fs-13">
                                Keyword targeting
                                <Tooltip
                                    title={tooltipContent("Keyword targeting")}
                                    placement="right"
                                    arrow
                                    className="custom-tooltip-position cursor-pointer"
                                >
                                    <span className="ms-1">
                                        <svg
                                            viewBox="0 0 512 512"
                                            width="12"
                                            height="12"
                                            className="fas fa-info-circle"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Tooltip>
                            </div>
                            <div className="fs-13">
                                Choose keywords to help your products appear in shopper searches.
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}
