import React from 'react'

export const CustomFooter = () => {
    return (
        <div className='footer fs-11 d-flex justify-content-center'>
            <div className=''>
                <div className="d-flex justify-content-between">
                    <div className="fs-12 "> © 2023 Advise, Inc. or its affiliates.</div>
                    <div className="fs-12 mx-3">Conditions of use</div>
                    <div className="fs-12 mx-3">Privacy notice</div>
                    <div className="fs-12">Advise Advertising Agreement</div>
                </div>
            </div>
        </div>
    )
}
