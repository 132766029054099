import { CustomTableCol } from "../../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const AdGroupsSearchTermsDataGridCols: CustomTableCol[] = [
    {
        title: "Customer search term",
        control: "searchTerm",
        sortable: false,
        canShowColumn: true,
        class: "col_table",
        style: { width: "320px", left: "0px", height: "42px" },
        infoIcon: true,
        tooltip: {
            text: "Search Terms Customer Search Term",
        },
    },
    {
        title: "Keywords",
        control: "keyword",
        sortable: false,
        canShowColumn: true,
        class: "col_table tb_left_border",
        style: { width: "295px", left: "320px", height: "42px" },
        infoIcon: true,
        tooltip: {
            text: "Search Terms Keywords",
        },
    },
    {
        title: "Target bid",
        control: "keywordBid",
        sortable: true,
        canShowColumn: true,
        style: { width: "140px" },
        infoIcon: true,
        tooltip: {
            text: "Search Terms Target bid",
        },
    },
    {
        title: "Impressions",
        control: "impressions",
        sortable: true,
        canShowColumn: true,
        style: { width: "140px" },
        infoIcon: true,
        tooltip: {
            text: "Ad Group Targeting Clicks",
        },
    },
    {
        title: "Clicks",
        control: "clicks",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        infoIcon: true,
        tooltip: {
            text: "Ad Group Targeting Clicks",
        },
    },
    {
        title: "CTR",
        control: "clickThroughRate",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        infoIcon: true,
        tooltip: {
            text: "Ad Group Targeting Clicks",
        },
    },
    {
        title: "CPC",
        control: "costPerClick",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Ad Group Targeting Clicks",
        },
    },
    {
        title: "Spend",
        control: "cost",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Ad Group Spend",
        },
    },
    {
        title: "Orders",
        control: "purchases30d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group Orders",
        },
    },
    {
        title: "Sales",
        control: "sales30d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Ad Group Sales",
        },
    },
    {
        title: "ACOS",
        control: "acosClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group ACOS",
        },
    },
    {
        title: "ROAS",
        control: "roasClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group ACOS",
        },
    },
    {
        title: "Conversion rate",
        control: "conversion_rate",
        sortable: true,
        canShowColumn: true,
        style: { width: "130px" },
        icon: info,
    },
    {
        title: "Total units",
        control: "unitsSoldClicks1d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
    },
];
