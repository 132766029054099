import { DataTableCol } from "../../../components/data-table/types";

export const KeywordTargetingDataGridCols: DataTableCol[] = [
    {
        title: 'Keyword',
        control: 'keyword',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Match Type',
        control: 'match_type',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Sugg. bid',
        control: 'suggested_bid',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Add All',
        control: 'add_all',
        sortable: true,
        canShowColumn: true
    },
]
