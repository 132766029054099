import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../../custom-tooltip/tooltipPage';
import { GetHelpCardModal } from '../../campaign-creation/get-help-card';

interface Props {
    validateStep?: any;
    validationStatus?: (data: boolean) => void;
    adGroupSettingsData?: (data: any) => void;
    triggerAdGroupValidation?: any;
    adData?: any;
}

export const AdGroupSettingsStepPage = (props: Props) => {
    const [adGroupSettingsPage, setAdGroupSettingsPage] = useState<any>({
        ad_group_name: "",
    });
    const [formErrors, setFormErrors] = useState<any>({});
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [showCreateAdgroupPopup, setShowhowCreateAdgroupPopup] = useState<boolean>(false);
    const [campaignDetailsValdationStatus, setCampaignDetailsValdationStatus] = useState<boolean>(false);

    useEffect(() => {
        const data: any = props?.adData;
        for (const property in data) {
            data[property] = data[property];
        }
        setAdGroupSettingsPage({ ...data })
        if (props?.triggerAdGroupValidation?.value) {
            setCampaignDetailsValdationStatus(props?.triggerAdGroupValidation);
            const isValid = validateForm(data);
            if (isValid) {
                if (props?.adGroupSettingsData) {
                    props?.adGroupSettingsData(data);
                }

            }
        }
    }, [props?.triggerAdGroupValidation?.value, props?.adData])

    const onClickLearnMoreText = () => {
        const redirectingUrl: any = "https://advertising.amazon.in/help?entityId=ENTITY2G8T90BNYY5QX#GKPA6T8WW3AYKV4Q";
        window.open(redirectingUrl);
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        const data = adGroupSettingsPage;
        data[name] = value;
        data['ad_group_state'] = "ENABLED";
        setAdGroupSettingsPage((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));

        if (props?.adGroupSettingsData) {
            props?.adGroupSettingsData(data);
        }

        const isValid = validateForm(data);

        if (isValid) {
            if (props.validationStatus) {
                props.validationStatus(isValid);
            }
        } else {
            if (props.validationStatus) {
                props.validationStatus(false);
            }
        }
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "ad_group_name") {
            if (!value) {
                return "Please enter ad group name"
            }
        }
        return "";
    }

    const validateForm = (data: any) => {
        const errors: any = {};
        if (!data?.ad_group_name) {
            errors.ad_group_name = "Please enter ad group name";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const tooltipContent = (data: any) => {
        if (data === "Ad group name") {
            return (
                <TooltipPage
                    tooltipMainContent={"Ad groups are a way to organise and manage ads within a campaign."}
                    tooltipSubContent={"Learn about ad groups"}
                    tooltipCloseStatus={onCloseTooltip}
                    onClickLearnMoreText={() => onClickLearnMoreNavigation()}
                />
            );
        }
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const onClickLearnMoreNavigation = () => {
        setShowhowCreateAdgroupPopup(true);
    }

    const getModalStatus = (status: any) => {
        if (status) {
            setShowhowCreateAdgroupPopup(false);
        }
    };

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    };

    return (
        <div>
            <div className="col-12 card-width mx-auto">
                <h4 className="my-2 fs-28">Ad Groups</h4>
            </div>
            <div className="col-12 card-width mx-auto">
                <div className="fs-13 mb-3">
                    Ad groups are groups of ads within a campaign that share the
                    same set of targeting tactics or creative type. They can help
                    you organise your campaigns more efficiently. Consider grouping
                    products that fall within the same category and price range. You
                    can create additional ad groups in campaign manager after you
                    launch your campaign.
                    <span className="learn-more-text logout cursor-pointer" onClick={onClickLearnMoreText}>
                        Learn more
                    </span>
                </div>
                {showCreateAdgroupPopup && (
                    <GetHelpCardModal
                        ismodalShow={showCreateAdgroupPopup}
                        modalStatus={getModalStatus} />
                )}
            </div>
            <div className="ad-group-settings-card-container card-width mx-auto">
                <div className="card text-bg-white ad-group-settings mb-3 my-2">
                    <div className="card-header bg-white">
                        <div className="d-flex justify-content-between py-2">
                            <div className="fs-24 fw-light">Ad group settings</div>
                            <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                                <span>
                                    <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                </span>
                                <span className="">
                                    How to create an ad group
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title fs-14 fw-700">
                            Ad group name{" "}
                            <span className="ms-1">
                                <Tooltip title={tooltipContent("Ad group name")} placement="top" arrow
                                    className="custom-tooltip-position cursor-pointer" onClick={handleClose}
                                >
                                    <span className="cursor-pointer">
                                        <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-info-circle" data-fa-i2svg="" >
                                            <path fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Tooltip>
                            </span>
                        </h5>
                        <div className="col-4 pe-3">
                            <input
                                name="ad_group_name"
                                className="form-control input border-dark fs-13"
                                placeholder="Ad group name"
                                defaultValue={props?.adData?.ad_group_name ? props?.adData?.ad_group_name : adGroupSettingsPage?.ad_group_name}
                                onChange={(e) => handleInput(e)}
                            />
                            {formErrors.ad_group_name && (
                                <div className="text-danger text-start w-100">
                                    {formErrors.ad_group_name}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}