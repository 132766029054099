import { CustomTableCol } from "../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const PlacementsDataGridCols: CustomTableCol[] = [
    {
        title: "Placement",
        control: "placement_name",
        sortable: true,
        canShowColumn: true,
        style: { width: "445px", left: 0, height: "41px" },
        icon: info,
        class: "col_table tb_left_border",
        infoIcon: true,
        tooltip: {
            text: "Placements Placement",
        },
    },
    {
        title: "Campaign bidding strategy",
        control: "campaign_bidding_strategy",
        sortable: false,
        canShowColumn: true,
        infoIcon: true,
        style: { width: "208px", left: 56, height: "40px" },
        tooltip: {
            text: "Placements Campaign bidding strategy",
        },
    },
    {
        title: "Bid adjustment",
        control: "percentage",
        sortable: true,
        canShowColumn: true,
        style: { width: "140px" },
        infoIcon: true,
        tooltip: {
            text: "Placements Bid adjustment",
        },
    },
    {
        title: "Impressions",
        control: "impressions",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        infoIcon: true,
        tooltip: {
            text: "Placements Impressions",
        },
    },
    {
        title: "Clicks",
        control: "clicks",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        infoIcon: true,
        tooltip: {
            text: "Placements Clicks",
        },
    },
    {
        title: "CTR",
        control: "ctr",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        infoIcon: true,
        tooltip: {
            text: "Placements CTR",
        },
    },
    {
        title: "Spend",
        control: "spend",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Placements Spend",
        },
    },
    {
        title: "CPC",
        control: "cpc",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        infoIcon: true,
        tooltip: {
            text: "Placements CPC",
        },
    },
    {
        title: "Orders",
        control: "orders",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Placements Orders",
        },
    },
    {
        title: "Sales",
        control: "sales",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Placements Sales",
        },
    },
    {
        title: "ROAS",
        control: "roas",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Placements ROAS",
        },
    },
];
