import React, { useEffect, useState } from 'react'
import { CustomTable } from '../../../../components/custom-table';
import { PlacementsDataGridCols } from './placements-data-grid-cols';
import { CustomFooter } from '../../../../components/footer/custom-footer';
import { GET_USER_CAMPAIGNS } from '../../../../app/services/campaigns.service';
import { toast } from "react-toastify";

export const CampaignPlacements = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignPlacementsData, setCamapignPlacementsData] = useState<any>()
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const tableBottomTextData = "Bid adjustment data is current, but other placement data could be delayed up to 12 hours."

    useEffect(() => {
        getUserCampaigns();
    }, [])

    const getBiddingStrategy = (biddingStrategy: any) => {
        if (biddingStrategy === "AUTO_FOR_SALES") {
            return "Dynamic bids - up and down";
        } else if (biddingStrategy === "LEGACY_FOR_SALES") {
            return "Dynamic bids - down only";
        } else {
            return "Fixed bids";
        }
    }

    const tableData = {
        page: "0",
        limit: "10",
        total: 14,
        rows: [
            {
                placement_name: "Top of search (first page)",
                percentage: campaignPlacementsData?.dynamicBidding_percentage,
                campaign_bidding_strategy: getBiddingStrategy(campaignPlacementsData?.dynamicBidding_strategy),
                placement: "PLACEMENT_TOP"
            },
            {
                placement_name: "Rest of search",
                percentage: campaignPlacementsData?.dynamicBidding_percentage_rest,
                campaign_bidding_strategy: getBiddingStrategy(campaignPlacementsData?.dynamicBidding_strategy),
                placement: "PLACEMENT_TOP"
            },
            {
                placement_name: "Product pages",
                percentage: campaignPlacementsData?.dynamicBidding_percentage_product,
                campaign_bidding_strategy: getBiddingStrategy(campaignPlacementsData?.dynamicBidding_strategy),
                placement: "PLACEMENT_TOP"
            },

        ]
    }

    const tablefooterData = [
        {
            placement_name: `Total: ${tableData?.rows?.length ? tableData?.rows?.length : 0}`,
            impressions: "-",
            clicks: "-",
            ctr: "-",
            cpc: "-",
            spend: "-",
            orders: "-",
            sales: "-",
            roas: "-",
        },
    ];

    const getUserCampaigns = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            is_report_data: false,
        }
        setLoading(true);
        GET_USER_CAMPAIGNS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    const dynamicBiddingArray = {};
                    for (let index = 0; index < response?.data?.length; index++) {
                        const element = response?.data[index];
                        element.dynamicBiddingArray = element?.dynamicBidding
                    }
                    setCamapignPlacementsData(response?.data?.data[0]);
                    setLoading(false);
                }
            }
        });
    }
    return (
        <div className='container-fluid'>
            <div className="row">
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className='col-12 ad-groups-table-container'>
                        <CustomTable
                            tableData={tableData}
                            TableCols={PlacementsDataGridCols}
                            tableWithoutFixedCoulmns={false}
                            placementClass={true}
                            tablefooterData={tablefooterData}
                            tableFooterpositionTop={true}
                            noDataFoundMesageTitle=''
                            tableBottomTextStatus={true}
                            tableLoading={!loading}
                            tableBottomText={tableBottomTextData}
                            placementBottomTextStyles={true}
                            placemnetTableContainer={true} />
                    </div>
                }
                <div className='fixed-bottom pb-3'>
                    <CustomFooter />
                </div>
            </div>
        </div>
    )
}
