import {
  CAMPAIGNDATA, ADGROUPADSDATA, LOADING, PROFILEDATA, USERDATA, USERUUIDDATA, ADGROUPSETTINGSDATA
  , CAMPAIGNBIDDINGCAMPAIGNDETAILSDATA, AICAMPAIGNLOADINGDATA, CAMPAIGNSETTINGSDATA
} from "../types";

export const Loading = (data: any) => {
  return {
    type: LOADING,
    data,
  };
};

export const UserData = (data: any) => {
  return {
    type: USERDATA,
    data: data,
  };
};

export const UserUuidData = (data: any) => {
  return {
    type: USERUUIDDATA,
    data: data,
  };
};

export const ProfileData = (data: any) => {
  return {
    type: PROFILEDATA,
    data: data,
  };
};

export const CampaignData = (data: any) => {
  return {
    type: CAMPAIGNDATA,
    data: data,
  };
};

export const AdgroupsAdsData = (data: any) => {
  return {
    type: ADGROUPADSDATA,
    data: data,
  };
};

export const AdgroupsSettingsData = (data: any) => {
  return {
    type: ADGROUPSETTINGSDATA,
    data: data,
  };
};

export const CampaignBiddingCampaignDetailsData = (data: any) => {
  return {
    type: CAMPAIGNBIDDINGCAMPAIGNDETAILSDATA,
    data: data,
  };
};

export const AiCampaignLoadingData = (data: any) => {
  return {
    type: AICAMPAIGNLOADINGDATA,
    data: data,
  };
};

export const CampaignSettingsData = (data: any) => {
  return {
    type: CAMPAIGNSETTINGSDATA,
    data: data,
  };
};



