import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { ConfigurationGroupsRoutes } from '../../../../../../Routes/configuration-groups';
import { NavLink } from 'react-router-dom';
import { Breadcum } from '../../../../../../components/breadcum';
import { GET_SPECIFIC_AD_GROUP } from '../../../../../../app/services/adgroups.service';

export const ConfigurationGroupInfoPage = () => {
    const [adGroupDataByID, setAdGroupDataByID] = useState<any>([]);
    const tabsData = [
        {
            name: 'Products',
            link: './products'
        },
        {
            name: 'Targets',
            link: './targets'
        }
    ];

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Ad Groups",
            value: "Ad Groups",
            routerLink: "/dashboard/configuration/groups",
            isActive: false,
        },
        {
            label: "Ad Group Name",
            value: "Ad Group : Ad Group One",
            routerLink: "/dashboard/configuration/groups-info/products",
            isActive: true,
        },
    ]);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const adGroupId = localStorage.getItem("ad_group_id");
    useEffect(() => {
        getAdGroupById();
    }, [])
    const getAdGroupById = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            ad_group_id: adGroupId,
            is_report_data: false
        }
        GET_SPECIFIC_AD_GROUP(preparedData).then((res) => {
            if (res?.data?.status === "error" || res?.data?.code == 500) {
                toast.error(res?.data?.message);
            } else {
                if (res) {
                    const response = res.data;
                    getBreadCumList(response?.data[0]);
                    setAdGroupDataByID(response?.data[0])
                }
            }
        });
    }

    const getBreadCumList = (data: any) => {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Ad Groups",
            value: `Ad Group : ${data?.ad_group_name ? data?.ad_group_name : ""}`,
            routerLink: "/dashboard/configuration/groups-info/products",
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }

    return (
        <div className='container-fluid' >
            <div className='fs-28'>Ad Group : <span className='fs-28 fw-bold'>{adGroupDataByID?.ad_group_name}</span></div>
            <div className='ps-2'>
                <Breadcum breadcumList={breadcumInfo}></Breadcum>
            </div>
            <div className='nav nav-underline'>
                {tabsData.map((data: any, index: number) => {
                    return <NavLink key={index} to={data?.link} className={`me-2 nav-link text-dark fs-13`}>{data?.name}</NavLink>
                })}
            </div>
            <ConfigurationGroupsRoutes />
        </div >
    )
}
