import React, { useEffect, useRef, useState } from "react";
import { CustomTableCol } from "./types";
import moment from "moment";
import { Tooltip } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { TooltipPage } from "../custom-tooltip/tooltipPage";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import { ConfigurationDateRangeField } from "../configuration/date-range-selector";
import NoProduct from './../../assets/images/no-product-image.png';

interface Props {
  tableData?: any;
  TableCols?: CustomTableCol[];
  editInfo?: any;
  deleteInfo?: any;
  viewInfo?: any;
  tableScroll?: boolean;
  onActions?: (data: string) => void;
  onPageChange?: (data: any) => void;
  onRowClickEvent?: (data: any) => void;
  rowsPerChangeData?: (data: any) => void;
  pageCount?: any;
  onSelectPageChange?: (data: any) => void;
  onSorting?: (data: any) => void;
  onChangeToggleStatusEvent?: (data: any) => void;
  onSellerChange?: (data: any) => void;
  configureValue?: (data: any) => void;
  onChangeTableInputData?: (data: any) => void;
  autoUpdateAIStatus?: (data: any) => void;
  recommendationsAIStatus?: (data: any) => void;
  onSave?: (data: any) => void;
  selectAllCheckStatus?: (data: any) => void;
  eachSelectCheckStatus?: (data: any) => void;
  isShowActions?: boolean;
  tablefooterData?: any;
  tableFooterpositionTop?: boolean;
  tableBottomTextStatus?: boolean;
  tableBottomText?: string;
  tableBottomTextSecondLine?: string;
  tableWithoutFixedCoulmns?: boolean;
  noDataFoundMesageTitle?: string;
  tableHeaderBlur?: boolean;
  noDataFoundMessageSubTitle?: string;
  customNodataErrorMessage?: string;
  placementBottomTextStyles?: boolean;
  placemnetTableContainer?: boolean;
  portfolioTableContainer?: boolean;
  portfolioCustomScroll?: boolean;
  tableColumnNameStyle?: any;
  tableLoading?: any;
  toggleStatus?: any;
  camapignNegativeTargeting?: any;
  placementClass?: boolean;
  portfolioClass?: boolean;
  configurationCampaigns?: any;
  isAPIPageNation?: any;
  portfolioCustomClass?: any;
  pageData?: any;
  configurationStatus?: any;
}

export const CustomTable: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sortingField, setSortingField] = useState(
    props?.pageCount?.sorting_field
  );
  const [sortingOrder, setSortingOrder] = useState(
    props?.pageCount?.sorting_type
  );
  const items: any[] = props?.isAPIPageNation ? props?.tableData?.data : props?.tableData?.rows || [];
  const wrapperRef = useRef<any>(null);
  const menuRef = useRef<any>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const currentDate = new Date().toISOString().split('T')[0];
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [page, setPage] = React.useState(0);
  const startIndex = page * rowsPerPage;
  const currentPageData = props?.isAPIPageNation ? props?.pageData?.page : items?.slice(startIndex, startIndex + rowsPerPage);
  const [totalItems, setTotalItems] = useState<any>(props?.isAPIPageNation ? props?.tableData?.data : currentPageData);
  useEffect(() => {
    if (props?.tableData?.data?.length > 0 && props?.isAPIPageNation) {
      setTotalItems(props?.tableData?.data)
    }
    console.log('props?.tableData?.data', props?.tableData?.data);

  }, [props?.tableData?.data, props?.isAPIPageNation])


  useEffect(() => {
    if (!props?.isAPIPageNation) {
      const startIndex = page * rowsPerPage;
      setTotalItems(items?.slice(startIndex, startIndex + rowsPerPage));
    }
  }, [items, rowsPerPage, page]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    document.addEventListener('mousedown', handleClickOutside, false);
    onSortingField(props?.pageCount?.sorting_field);

    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && isVisible) {
      setIsVisible(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target) && showMenu) {
      setShowMenu(false);
    }
  };

  const onSortingField = (data: any) => {
    const order =
      data === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(data);
    setSortingOrder(order);
    if (data) {
      const reversed = order === "asc" ? 1 : -1;
      const parseValue = (value: any) => {
        if (typeof value === 'boolean') {
          return value ? 1 : -1;
        }
        if (typeof value === 'string') {
          const numberValue = parseFloat(value.replace(/,/g, ''));
          if (!isNaN(numberValue)) {
            return numberValue;
          }
          return value.toLowerCase();
        }
        if (typeof value === 'number') {
          return value;
        }
        return value?.toString().toLowerCase() || '';
      };
      if (props?.isAPIPageNation) {
        props?.tableData?.data?.sort((a: any, b: any) => {
          const aValue = parseValue(a[data]);
          const bValue = parseValue(b[data]);

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return reversed * aValue.localeCompare(bValue);
          }

          return reversed * (aValue - bValue);
        });
      } else {
        const preparedData = totalItems?.sort((a: any, b: any) => {
          let aValue = parseValue(a[data]);
          let bValue = parseValue(b[data]);
          aValue = aValue !== '-' ? aValue : '';
          bValue = bValue !== '-' ? bValue : '';
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return reversed * aValue.localeCompare(bValue);
          }
          return reversed * (aValue - bValue);
        });
        setTotalItems([...preparedData]);
      }
    }
  };

  const onAction = (data: any, type: string) => {
    const actionData: any = { data, type };
    if (props.onActions) {
      props.onActions(actionData);
    }
  };

  const showData = (data: any) => {
    if (data) {
      var dateString = moment(data).format("MMM DD, YYYY");
      return dateString;
    } else {
      return "No date";
    }
  };
  const onClickMenu = (index: number) => {
    setShowMenu(true);
    setSelectedIndex(index);
  };


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (props?.onPageChange) {
      props?.onPageChange(newPage)
    }
    if (props?.tableLoading) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    const startIndex = newPage * rowsPerPage;
    setTotalItems([...items?.slice(startIndex, startIndex + rowsPerPage)])
    onSortingField(props?.pageCount?.sorting_field);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (props?.rowsPerChangeData) {
      props?.rowsPerChangeData({ data: event.target.value, count: 10 })
    }
    if (props?.tableLoading) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  const handleChangeAPIPage = (event: any, newPage: number) => {
    if (props?.onPageChange) {
      props?.onPageChange(newPage)
    }
    setPage(newPage);
  }

  const handleChangeRowsAPIPerPage = (event: any) => {
    if (props?.rowsPerChangeData) {
      props?.rowsPerChangeData(event.target.value)
    }
  }


  const handleActiveToggleStatus = (e: any, data: any) => {
    data.statusChecked = e.target.checked ? true : false;
    const actionData: any = { data, type: 'status' };
    if (props.onActions) {
      props.onActions(actionData);
    }
  };

  const handleEditSettings = (e: any, index: number, data: any) => {
    const actionData: any = { data, type: "edit camapign" }
    if (props.onActions) {
      props.onActions(actionData);
    }
  };

  const onCloseTooltip = (status: any) => {
  }

  const tooltipContent = (tooltipData: any) => {
    if (tooltipData === "Impressions") {
      return (
        <TooltipPage
          tooltipHeader='Impressions'
          tooltipMainContent={`The number of times ads were displayed`}
          tooltipSubContent={`Learn about impressions`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Clicks") {
      return (
        <TooltipPage
          tooltipHeader='Clicks'
          tooltipMainContent={`The number of times your ads were clicked. Note: Once identified, it may take up to 3 days to remove invalid clicks.`}
          tooltipSubContent={`Learn about clicks`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "CTR") {
      return (
        <TooltipPage
          tooltipHeader='Click-through rate (CTR)'
          tooltipMainContent={`The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipSubContent={`Learn about click-through rate (CTR)`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Spend") {
      return (
        <TooltipPage
          tooltipHeader='Spend'
          tooltipMainContent={`The total click charges for a product ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns") {
      return (
        <TooltipPage
          tooltipHeader='Campaigns'
          tooltipMainContent={`A campaign is a set of ad groups under a common advertising budget. Ad groups are used to organise your products (ads) so that you can accomplish your desired business goals. `}
          tooltipSubContent={`Learn about campaigns`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Portfolio") {
      return (
        <TooltipPage
          tooltipHeader='Portfolio'
          tooltipMainContent={`A group of campaigns with a daily or date range budget cap applied. Only sponsored products and Sponsored Brands can be grouped.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Budget") {
      return (
        <TooltipPage
          tooltipHeader='Budget'
          tooltipMainContent={`Your budget puts a limit on how much you will spend for this campaign.`}
          tooltipSubContent={`Learn about budget`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns Impressions") {
      return (
        <TooltipPage
          tooltipMainContent={`The number of times ads were displayed`}
          tooltipSubContent={`Learn about impressions`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Top-of-search IS") {
      return (
        <TooltipPage
          tooltipHeader="Top-of-search impression share (IS)"
          tooltipMainContent={`The percentage of top-of-search impressions the campaign received of all the top-of-search impressions it was eligible to receive. Eligibility is determined by various factors, including campaign status and target status. This metric is available for Sponsored Brands and Sponsored Products campaigns. Top-of-search IS data is accessible for the last 90 days.
          For Sponsored Products, this metric gives the impression share for Top-of-search (first page).`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns Clicks") {
      return (
        <TooltipPage
          tooltipHeader="Clicks"
          tooltipMainContent={`The number of times your ads were clicked. Note: Once identified, it may take up to 3 days to remove invalid clicks.`}
          tooltipSubContent={`Learn about clicks`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns Spend") {
      return (
        <TooltipPage
          tooltipHeader="Clicks"
          tooltipMainContent={`The total click or impression charges for a campaign.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipSubContent={`Learn about spend`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns CPC") {
      return (
        <TooltipPage
          tooltipHeader="Cost-per-click (CPC)"
          tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipSubContent={`Learn about cost-per-click (CPC)`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns Orders") {
      return (
        <TooltipPage
          tooltipHeader="Orders"
          tooltipMainContent={`Orders is the number of Amazon orders shoppers submitted after clicking on or viewing your ads.
          Sponsored Products: Orders from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Orders from advertised products and all products within your brand sold by Amazon and third-party Sellers within 14 days.
          It can take up to 12 hours for your orders data to update. As a result, orders data may be delayed in the Today date range. We recommend waiting until all orders data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from orders totals.`}
          tooltipSubContent={`Learn about orders`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns Sales") {
      return (
        <TooltipPage
          tooltipHeader="Sales"
          tooltipMainContent={`Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
          Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Sales from advertised products and all products within your brand sold by Amazon and third-party sellers within 14 days.
          It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
          tooltipSubContent={`Learn about sales`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns ACOS") {
      return (
        <TooltipPage
          tooltipHeader="Advertising cost of sales (ACOS)"
          tooltipMainContent={`ACOS is the percent of attributed sales spent on advertising within 14 days of clicks on your ads. This is calculated by dividing total spend by attributed sales.`}
          tooltipSubContent={`Learn about advertising cost of sales (ACOS)`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group ACOS") {
      return (
        <TooltipPage
          tooltipHeader="Advertising cost of sales (ACOS)"
          tooltipMainContent={`ACOS is the percent of attributed sales spent on advertising within 14 days of clicks on your ads. This is calculated by dividing total spend by attributed sales.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns Top of Search js") {
      return (
        <TooltipPage
          tooltipHeader="Top-of-search impression share (IS) "
          tooltipMainContent={`The percentage of top-of-search impressions the campaign received of all the top-of-search impressions it was eligible to receive. Eligibility is determined by various factors, including campaign status and target status. This metric is available for Sponsored Brands and Sponsored Products campaigns. Top-of-search IS data is accessible for the last 90 days. For Sponsored Products, this metric gives the impression share for top-of-search (first page).`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Campaigns ROAS") {
      return (
        <TooltipPage
          tooltipHeader="Return on ad spend (ROAS)"
          tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
          tooltipSubContent={`Learn about return on ad spend (ROAS)`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Portfolio Budget") {
      return (
        <TooltipPage
          tooltipHeader="Budget"
          tooltipMainContent={`Your budget puts a limit on how much you will spend for this campaign.`}
          tooltipSubContent={`Learn about budget`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Portfolio Spend") {
      return (
        <TooltipPage
          tooltipHeader="Spend"
          tooltipMainContent={`The total click or impression charges for a campaign.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipSubContent={`Learn about spend`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Portfolio Orders") {
      return (
        <TooltipPage
          tooltipHeader="Orders"
          tooltipMainContent={`Orders is the number of Amazon orders shoppers submitted after clicking on or viewing your ads.
          Sponsored Products: Orders from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Orders from advertised products and all products within your brand sold by Amazon and third-party Sellers within 14 days.
          It can take up to 12 hours for your orders data to update. As a result, orders data may be delayed in the Today date range. We recommend waiting until all orders data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from orders totals.`}
          tooltipSubContent={`Learn about orders`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Portfolio Sales") {
      return (
        <TooltipPage
          tooltipHeader="Sales"
          tooltipMainContent={`Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
          Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Sales from advertised products and all products within your brand sold by Amazon and third-party sellers within 14 days.
          It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
          tooltipSubContent={`Learn about sales`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Portfolio ROAS") {
      return (
        <TooltipPage
          tooltipHeader="Return on ad spend (ROAS)"
          tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
          tooltipSubContent={`Learn about return on ad spend (ROAS)`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group adgroups") {
      return (
        <TooltipPage
          tooltipHeader="Ad group"
          tooltipMainContent={`An ad group is made up of one or more ads, a set of targets and a maximum default bid. For the best results, place similar products in the same ad group.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Total targets") {
      return (
        <TooltipPage
          tooltipHeader="Total targets"
          tooltipMainContent={`Shows the total number of targets for keywords, products and categories.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Spend") {
      return (
        <TooltipPage
          tooltipHeader="Spend"
          tooltipMainContent={`The total click charges for an ad group.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipMainContentCaption={`Learn more`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Orders") {
      return (
        <TooltipPage
          tooltipHeader="Orders"
          tooltipMainContent={`Orders is the number of Amazon orders shoppers submitted after clicking on or viewing your ads.
          Sponsored Products: Orders from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Orders from advertised products and all products within your brand sold by Amazon and third-party Sellers within 14 days.
          It can take up to 12 hours for your orders data to update. As a result, orders data may be delayed in the Today date range. We recommend waiting until all orders data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from orders totals.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Sales") {
      return (
        <TooltipPage
          tooltipHeader="Sales"
          tooltipMainContent={`Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
          Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Sales from advertised products and all products within your brand sold by Amazon and third-party sellers within 14 days.
          It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group ROAS") {
      return (
        <TooltipPage
          tooltipHeader="Return on ad spend (ROAS)"
          tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Placement") {
      return (
        <TooltipPage
          tooltipHeader="Placements"
          tooltipMainContent={`Places where your ads can appear. E.g. Top of search results.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Campaign bidding strategy") {
      return (
        <TooltipPage
          tooltipHeader="Campaign bidding strategy"
          tooltipMainContent={`Choose how you would like to bid for your ads based on your campaign objective. You can change your bidding strategy any time from the campaign settings page. 
          Only available for Sponsored Products campaigns.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Bid adjustment") {
      return (
        <TooltipPage
          tooltipHeader="Bid increase"
          tooltipMainContent={`Apply different bids by placement by entering a percentage increase to your default bid. These adjustments will apply on all bids in the campaign. Based on your bidding strategy, your bids can change further.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Impressions") {
      return (
        <TooltipPage
          tooltipHeader="Impressions"
          tooltipMainContent={`The number of times ads were displayed`}
          questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Clicks") {
      return (
        <TooltipPage
          tooltipHeader="Clicks"
          tooltipMainContent={`The number of times your ads were clicked. Note: Once identified, it may take up to 3 days to remove invalid clicks.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements CTR") {
      return (
        <TooltipPage
          tooltipHeader="Click-through rate (CTR)"
          tooltipMainContent={`The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipMainContentCaption={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Spend") {
      return (
        <TooltipPage
          tooltipHeader="Spend"
          tooltipMainContent={`The total click or impression charges for a campaign.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipMainContentCaption={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements CPC") {
      return (
        <TooltipPage
          tooltipHeader="Cost-per-click (CPC)"
          tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipMainContentCaption={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Orders") {
      return (
        <TooltipPage
          tooltipHeader="Orders"
          tooltipMainContent={`Orders is the number of Amazon orders shoppers submitted after clicking on or viewing your ads.
          Sponsored Products: Orders from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Orders from advertised products and all products within your brand sold by Amazon and third-party Sellers within 14 days.
          It can take up to 12 hours for your orders data to update. As a result, orders data may be delayed in the Today date range. We recommend waiting until all orders data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from orders totals.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements Sales") {
      return (
        <TooltipPage
          tooltipHeader="Sales"
          tooltipMainContent={`Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
          Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 7 days.
          Sponsored Brands: Sales from advertised products and all products within your brand sold by Amazon and third-party sellers within 14 days.
          It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
          Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Placements ROAS") {
      return (
        <TooltipPage
          tooltipHeader="Return on ad spend (ROAS)"
          tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
          tooltipMainContentCaption={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Ads ROAS") {
      return (
        <TooltipPage
          tooltipHeader="Return on ad spend (ROAS)"
          tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group ads") {
      return (
        <TooltipPage
          tooltipHeader="Ads"
          tooltipMainContent={`Product featured in the ad with its current listing price. This is the current price you see on Amazon. It may or may not include taxes, depending on the region and product. Orders and sales attributed to this ad may include products not featured in the ad.`}
          tooltipSubContent={`Learn about Ads`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Targeting Match Type") {
      return (
        <TooltipPage
          tooltipHeader="Match type"
          tooltipMainContent={`Match types allow you to fine-tune which customer search queries can trigger your ads. You can choose from broad, phrase, and exact match types. Targeting groups will only use the ”Theme“ match type which targets a combination of phrase and exact match keywords. For negative keywords, you can choose from phrase and exact match types. Match type does not apply to product targeting.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Targeting bid") {
      return (
        <TooltipPage
          tooltipHeader="Bid"
          tooltipMainContent={`The maximum amount you will pay for a click when this target triggers your ad.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Targeting Keyword bid") {
      return (
        <TooltipPage
          tooltipHeader="Keyword bid"
          tooltipMainContent={`The maximum amount you will pay for a click when this target triggers your ad.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "targetings cpc") {
      return (
        <TooltipPage
          tooltipHeader="Cost-per-click (CPC)"
          tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Targeting Impressions") {
      return (
        <TooltipPage
          tooltipHeader='Impressions'
          tooltipMainContent={`The number of times ads were displayed`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Targeting Clicks") {
      return (
        <TooltipPage
          tooltipHeader='Clicks'
          tooltipMainContent={`The number of times your ads were clicked. Note: Once identified, it may take up to 3 days to remove invalid clicks.`}
          tooltipSubContent={`Learn more`} questionIconStatus={true}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Targeting Spend") {
      return (
        <TooltipPage
          tooltipHeader='Spend'
          tooltipMainContent={`The total click charges for a product ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipMainContentCaption={`Learn more`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group Targeting CPC") {
      return (
        <TooltipPage
          tooltipHeader="Cost-per-click (CPC)"
          tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipMainContentCaption={`Learn more`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group negative targteings match type") {
      return (
        <TooltipPage
          tooltipHeader="Match type"
          tooltipMainContent={`Match types allow you to fine-tune which customer search queries can trigger your ads. You can choose from broad, phrase, and exact match types. Targeting groups will only use the ”Theme“ match type which targets a combination of phrase and exact match keywords. For negative keywords, you can choose from phrase and exact match types. Match type does not apply to product targeting.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Ad Group default bid") {
      return (
        <TooltipPage
          tooltipHeader="Default bid"
          tooltipMainContent={`Default bid applies to all clicks unless you set a different bid for a keyword. Based on the bidding strategy and the placement adjustments you choose, your bids might change.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipData === "Search Terms Customer Search Term") {
      return (
        <TooltipPage
          tooltipHeader="Customer search term"
          tooltipMainContent={`Customer search terms that matched with keywords in your ad group. A search term is the exact set of words that customers enter when searching for a product on Amazon.`}
          tooltipCloseStatus={onCloseTooltip}
        />
      )
    } else if (tooltipData === "Search Terms Keywords") {
      return (
        <TooltipPage
          tooltipHeader="Keywords"
          tooltipMainContent={`Keywords that matched with the words a customers enters to search for a product on Amazon.`}
          tooltipCloseStatus={onCloseTooltip}
        />
      )
    } else if (tooltipData === "Search Terms Target bid") {
      return (
        <TooltipPage
          tooltipHeader="Target bid"
          tooltipMainContent={`The maximum amount you will pay for a click when this target triggers your ad.`}
          tooltipCloseStatus={onCloseTooltip}
        />
      )
    } else if (tooltipData === "Negative keyword match type") {
      return (
        <TooltipPage
          tooltipHeader="Match type"
          tooltipMainContent={`Match types allow you to fine-tune which customer search queries can trigger your ads. You can choose from broad, phrase, and exact match types. Targeting groups will only use the ”Theme“ match type which targets a combination of phrase and exact match keywords. For negative keywords, you can choose from phrase and exact match types. Match type does not apply to product targeting.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    }
  }

  const getStatus = (status: any, name: any) => {
    if (status?.state === "ENABLED" && name !== 'auto_update_by_AI') {
      return true;
    } else {
      return false;
    }
  }

  const onClickProductNavigation = (data: any) => {
    const redirecting_url = `https://www.amazon.in/dp/${data}`
    window.open(redirecting_url, "_blank")
  }
  const onChangeInput = (e: any, element: any, index: number, inputType: any) => {
    if (inputType === "is_ai_update") {
      const status = e.target.checked;
      const updatedStatus = [...totalItems];
      updatedStatus[index][inputType] = status;
      if (props?.autoUpdateAIStatus) {
        props?.autoUpdateAIStatus(status)
      }
      setTotalItems([...updatedStatus]);
    }
    else if (inputType === "is_ai_call") {
      const status = e.target.checked;
      const updatedStatus = [...totalItems];
      updatedStatus[index][inputType] = status;
      if (props?.recommendationsAIStatus) {
        props?.recommendationsAIStatus(status)
      }
      setTotalItems([...updatedStatus]);
    }
    else {
      const { name, value } = e.target;
      const updatedItems = [...totalItems];
      updatedItems[index][name] = value >= 0 ? value : '';
      if (props?.onChangeTableInputData) {
        props?.onChangeTableInputData(value >= 0 ? value : '')
      }
      setTotalItems([...updatedItems]);
    }
  }

  const onChangeTableInput = (e: any, element: any, index: number, selectedSellerId: any, data: any, inputType: any) => {
    const { name, value } = e.target;
    const sellerData: any = {
      seller_id: value
    }
    if (inputType == "dropdown") {
      if (props?.configureValue) {
        props?.configureValue({ data: selectedSellerId, updatedDuration: e.target.value })
      }
    }
    if (props?.onSellerChange) {
      props?.onSellerChange(sellerData)
    }
  }
  const parseDateToObject = (dateString: any) => {
    const date = moment(dateString, ['YYYY-MM-DD', 'DD-MM-YYYY']);
    if (date.isValid()) {
      return new Date(date.format('YYYY-MM-DD'));
    }
    return null;
  };

  const onSelectDate = (date: any) => {
    const updatedItems = [...totalItems];
    updatedItems[date?.index].ai_start_date = date?.start;
    updatedItems[date?.index].ai_end_date = date?.end;
  }

  return (
    <div className="">
      {loading &&
        <div className={`text-center p-5 ${props?.camapignNegativeTargeting ? "negative-keyword-spinner-position" : ""}`}>
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      }
      {!loading && (
        <div className={`outer ${props.tableScroll ? "table_scroll" : ""}`}>
          <div className={` ${props?.tableWithoutFixedCoulmns ? "inner-without-fixed-columns table_scroll" : ` ${props?.tableWithoutFixedCoulmns ? "inner-portfolio-campaigns" : "inner"}`}
        ${props?.placemnetTableContainer ? "placemnet-table-inner" : ""}
        ${props.portfolioTableContainer ? "portfolio-inner-table" : ""}`}>
            <div className="table-container">
              <table className="">
                <thead className={`${props?.tableHeaderBlur ? "negative-keyword-table-header-bg " : ""} `}>
                  {props?.TableCols?.map((el, index) => (
                    <React.Fragment key={index}>
                      {el.canShowColumn && (
                        <th
                          onClick={() => el.sortable ? onSortingField(el.control) : null} style={el.style} className={`${el.class}`}>
                          {el.control !== "checkbox" ? (
                            <>
                              {el?.title !== "Add all on page" &&
                                el?.control !== "exclamation_mark" && (
                                  <span>{el.title}</span>
                                )}
                              {el?.infoIcon && (
                                <>
                                  {el?.tooltip ? (
                                    <Tooltip title={tooltipContent(el?.tooltip?.text)} placement="top" arrow className="cursor-pointer custom-tooltip-th" >
                                      <span className="ms-2 mt-2">
                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle mb-2" data-fa-i2svg=""  >
                                          <path fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      <span className="ms-2 mt-2">
                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="" >
                                          <path fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                              {el?.title === "Add all on page" && (
                                <div className="custom-explore-text-color-two cursor-pointer logout">
                                  {el.title}
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="d-flex">
                              <label className="container_checkbox">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                              <img
                                src={el?.icon}
                                alt=""
                                className="checkbox_arrow"
                                onClick={() => setIsVisible(true)}
                              />
                              {isVisible && (
                                <div ref={wrapperRef} className="table_checkbox_option">
                                  <div>Select all</div>
                                  <div>Select none</div>
                                </div>
                              )}
                            </div>
                          )}
                          {sortingField && sortingField === el.control && (
                            <i
                              className={`${sortingOrder === "asc"
                                ? "bi bi-arrow-down cursor-pointer p-2"
                                : "bi bi-arrow-up cursor-pointer p-2"
                                }`}
                            ></i>
                          )}
                        </th>
                      )}
                    </React.Fragment>
                  ))}
                </thead>
                {props?.tableFooterpositionTop &&
                  <thead className="">
                    {props?.tablefooterData &&
                      props?.tablefooterData?.map((data: any, i: number) => (
                        <tr key={i} >
                          {props?.TableCols?.map((el: any, index: number) => (
                            <React.Fragment key={index}>
                              {el.canShowColumn && (
                                <td style={el.style}
                                  className={`${el.class} ${props?.portfolioClass ? "port_margin_space" : ""} ${props?.placementClass ? "placement_margin_space" : ""} ${props?.portfolioCustomClass ? "" : ""} portfolio_margin_space fw_700 ${el.control === "checkbox" ? "pb_17" : ""
                                    }`}>
                                  {data[el.control]}
                                </td>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                  </thead>
                }

                <tbody>
                  {totalItems?.length > 0 &&
                    totalItems?.map((data: any, i: number) => (
                      <tr key={i}>
                        {props?.TableCols?.map((el: any, index: number) => (
                          <React.Fragment key={index}>
                            {el.canShowColumn && (
                              <td style={el.style} className={`${el.class} ${el.control === "checkbox" ? "pb_17" : ""}`}>
                                {!el.isTextOverflowHide &&
                                  el.control !== "status" &&
                                  el?.control !== "state" &&
                                  el?.control !== "match_type" &&
                                  el?.title !== "Type" &&
                                  el?.title !== "Active" &&
                                  !el.isRemoveUndeerScor &&
                                  !el.isLink &&
                                  !el?.isDate &&
                                  el?.control !== "campaign_name" &&
                                  el?.title !== "Portfolios" &&
                                  el?.control !== "ad_group_name" &&
                                  el.control !== "checkbox" &&
                                  el.control !== "is_ai_call" &&
                                  el?.control !== "ai" &&
                                  el?.title !== "Bid adjustment" &&
                                  el.control !== "draftCampaignState" &&
                                  el?.title !== "Default bid" &&
                                  el?.control !== "campaign_type" &&
                                  el?.control !== "campaign_status" &&
                                  el?.title !== "Bid" &&
                                  el?.control !== "matchType" &&
                                  el?.title !== "Ads" &&
                                  el?.control !== "metric_clicks" &&
                                  el?.control !== "metric_impressions" &&
                                  el?.title !== "Link Status" &&
                                  el?.control !== "campaignBudgetType" &&
                                  !el?.isShowSwitch &&
                                  !el?.isInputField &&
                                  !el?.isDropdown &&
                                  !el?.isDateRange &&
                                  !el?.isShowRupee &&
                                  data[el.control]}
                                {el.control === "checkbox" && (
                                  <label className="container_checkbox">
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                )}
                                {(el?.control === "campaignBudgetType" && (
                                  <span>{data[el?.control] === "DAILY_BUDGET" ? "DAILY" : "-"}</span>
                                ))}

                                {(el?.isShowRupee && (
                                  <span>{`${data[el?.control] === "-" ? "-" : data[el?.control] ? `₹${data[el?.control]}` : data[el?.control]}`}</span>
                                ))}
                                {el?.title === "Link Status" && (
                                  <>
                                    {data[el?.control] == false && <span className="text-danger fw-bold">Not Linked</span>}
                                    {data[el?.control] == true && <span className="text-success fw-bold">Linked</span>}
                                  </>
                                )}
                                {el?.control === "metric_clicks" && (
                                  <div className="text-start ms-4">{data[el?.control] === undefined ? "_" : data[el.control]}</div>
                                )}
                                {el?.control === "metric_impressions" && (
                                  <div className="text-start ms-4">{data[el?.control] === undefined ? "_" : data[el.control]}</div>
                                )}
                                {el?.title === "Default bid" && (
                                  <>
                                    <div className="input-group">
                                      <span className="input-group-text table-budget-input-group bg-white border-dark keyword-below-text-color">₹</span>
                                      <input type="number" name="default_bid" className="form-control table-budget-input-group border-dark border-start-0" defaultValue={data[el?.control]} disabled />
                                    </div>
                                  </>
                                )}
                                {el?.title === "Ads" && (
                                  <div className="d-flex">
                                    <div className="me-2">
                                      <img src={data?.image_url ? data?.image_url : NoProduct} className="ad-group-product-image" />
                                    </div>
                                    <Tooltip title={data[el?.control]} placement="top" arrow>
                                      <div className="custom_link explore-modal-navigation-text-color" onClick={() =>
                                        onAction(data, "prodcut_navigation")}>
                                        {data?.title?.trim()?.length > 70 ? `${data?.title?.slice(0, 70)}...` : `${data?.title}`}
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}
                                {el?.title === "Bid" && (
                                  <>
                                    <div className="input-group">
                                      <span className="input-group-text table-budget-input-group bg-white border-dark keyword-below-text-color">₹</span>
                                      <input type="number" name="bid" className="form-control table-budget-input-group border-dark border-start-0 px-1" value={data[el.control]} disabled />
                                    </div>
                                  </>
                                )}
                                {el?.control === "ai" && (
                                  <>
                                    {el?.title === "Status" && el?.control === "state" && (<>
                                      {data[el.control] === "ENABLED" && (
                                        <span className="text-success fw-bold">Stop</span>
                                      )}
                                      {data[el.control] === "PAUSED" && (
                                        <span className="text-danger fw-bold">Stop</span>
                                      )}
                                    </>
                                    )}
                                    <span className="text-danger fw-bold">Stop</span>
                                  </>
                                )}
                                {el?.isDate && (
                                  <span className={`table_date_box ${(data[el.control]) < currentDate ? "cursor-pointer-not-allowed" : ""}`}>
                                    {showData(data[el.control])}
                                  </span>
                                )}
                                {el?.isShowSwitch && !el?.inputConfig && (
                                  <div className="form-check form-switch ps-1">
                                    <label className="switch text-start">
                                      <input type="checkbox" name="toogle" checked={props?.toggleStatus ? props?.toggleStatus : getStatus(data, el.control)} onChange={(e) =>
                                        handleActiveToggleStatus(e, data)
                                      }></input>
                                      <div className="slider round">
                                        <span className="on">
                                          <svg viewBox="0 0 512 512" width="7" height="7" className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check toggle-check-mark-position" data-fa-i2svg="">
                                            <path fill="white" d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z">
                                            </path>
                                          </svg>
                                        </span>
                                        <span className="off"></span>
                                      </div>
                                    </label>
                                  </div>
                                )}
                                {el?.isShowSwitch && el?.inputConfig && (
                                  <div className="form-check form-switch ps-1">
                                    <label className="switch text-start">
                                      <input type="checkbox" name={el?.inputConfig?.name} checked={data[el?.control]} onChange={(e) => onChangeInput(e, e, i, el?.inputConfig?.name)} />
                                      <div className="slider round">
                                        <span className="on">
                                          <svg viewBox="0 0 512 512" width="7" height="7" className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check toggle-check-mark-position" data-fa-i2svg="">
                                            <path fill="white" d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z">
                                            </path>
                                          </svg>
                                        </span>
                                        <span className="off"></span>
                                      </div>
                                    </label>
                                  </div>
                                )}
                                {el?.isInputField && (
                                  <div className="ps-1">
                                    <label className="text-start">
                                      <input type={el?.inputConfig?.inputType ? el?.inputConfig?.inputType : 'text'} name={el?.inputConfig?.name} placeholder={el?.inputConfig?.placeholder} value={data[el.control]} className="form-control" onChange={(e) => onChangeInput(e, e, i, "input")}></input>
                                    </label>
                                  </div>
                                )}
                                {el?.isDateRange && (
                                  <div className='d-flex px-2'>
                                    <ConfigurationDateRangeField dates={{ start: parseDateToObject(data['ai_start_date']), end: parseDateToObject(data['ai_end_date']) }} onSelectDateRange={(e) => onSelectDate(e)} index={i} />
                                  </div>
                                )}
                                {el?.isDropdown && (
                                  <div className="ps-1">
                                    <label className="text-start">
                                      <select name={el?.inputConfig?.name} value={data?.is_linked ? data?.seller_id : data[el.control]} className="form-control" onChange={(e) => onChangeTableInput(e, e, i, data, data[el.control], "dropdown")}>
                                        <option value="" disabled={props?.configurationStatus}>{el?.inputConfig?.placeholder}</option>
                                        {el?.inputConfig?.options?.map((dropdownOption: any) => {
                                          return <option value={dropdownOption?.value}>{dropdownOption?.name}</option>
                                        })}
                                      </select>
                                    </label>
                                  </div>
                                )}

                                {el?.title === "Bid adjustment" && (
                                  <>
                                    <div className="input-group border-dark">
                                      <input type="number" name="product-pages" className="form-control input bid-adjusment_table-input  border-dark border-end-0 fs-13" placeholder=""
                                        defaultValue={data[el?.control] ? data[el?.control] : "0"} aria-label="Username" aria-describedby="basic-addon1" disabled />
                                      <span className="input-group-text border-dark input bid-adjusment_table-input" id="basic-addon1">%</span>
                                    </div>
                                  </>
                                )}

                                {el?.control === "matchType" && (
                                  <div className="">
                                    {data[el?.control] === "NEGATIVE_EXACT" ? "Negative exact" : " Negative phrase"}
                                  </div>
                                )}
                                {el?.control === "campaign_name" || el?.control === "Portfolios" && (
                                  <span className="custom-explore-text-color cursor-pointer">
                                    {data[el.control]}
                                  </span>
                                )}
                                {el?.control === "ad_group_name" && (
                                  <span className="custom-explore-text-color ad-group-name-title logout">
                                  </span>
                                )}
                                {el?.control === "match_type" && (
                                  <span className="">
                                    {data[el?.control] === "NEGATIVE_EXACT" ? "Negative exact" : data[el?.control] === "PHRASE" ? "Phrase" : data[el.control] === "EXACT" ? "Exact" : data[el.control] === "BROAD" ? "Broad" : "Negative phrase"}
                                  </span>
                                )}
                                {el?.title === "SKU / ASIN" && (
                                  <div className="explore-modal-navigation-text-color cursor-pointer" onClick={() => onClickProductNavigation(data?.asin)}> {data?.asin}
                                    <span className="ms-2"><svg viewBox="0 0 576 512" height="14" width="14" className="fas fa-external-link-alt" data-fa-i2svg=""><path fill="currentColor" d="M226 39a31 31 0 0 1 31-31h279v279a31 31 0 1 1-62 0V113.84L247.92 339.92a31 31 0 0 1-43.84-43.84L430.16 70H257a31 31 0 0 1-31-31ZM71 70a31 31 0 0 1 31 31v341h341a31 31 0 1 1 0 62H71a31 31 0 0 1-31-31V101a31 31 0 0 1 31-31Z"></path></svg></span>
                                  </div>
                                )}
                                {el?.control === "campaign_type" && (
                                  <div className="fs-13">Sponsored Products</div>
                                )}
                                {el?.control === "add_all_on_page" && (
                                  <span className="">
                                    <button className="rounded-pill  custom-button-background border-0 text-dark px-2 py-1">Add</button>
                                  </span>
                                )}
                                {el?.control === "state" && (
                                  <>
                                    {(data[el.control] === "enabled" && (
                                      <span className="fs-12 text-success">Delivering</span>
                                    ))}
                                  </>
                                )}
                                {el?.control === "start_date" && (
                                  <div className={`${(data[el.control]) < currentDate ? "cursor-pointer-not-allowed" : ""} `}></div>
                                )}

                                {el?.control === "state" && el?.title === "Status" && (
                                  <>
                                    {(data[el.control] === "PAUSED" && (
                                      <div>
                                        <div className="fs-12">Paused</div>
                                        <div className="fs-11 optional-text-color">Details<i className="bi bi-caret-down-fill ms-1"></i></div>
                                      </div>
                                    ))}
                                    {(data[el.control] === "ENABLED" && (
                                      <div>
                                        <div className="fs-12 text-success">{data?.start_date > currentDate ? "Scheduled" : "Delivering"}</div>
                                        <div className="fs-11 optional-text-color">Details<i className="bi bi-caret-down-fill ms-1"></i></div>
                                      </div>
                                    ))}
                                    {data[el?.control] === "ARCHIVED" && (
                                      <div>
                                        <div className="fs-12 text-secondary">Archived</div>
                                      </div>
                                    )}
                                  </>
                                )}

                                {el?.control === "campaign_status" && el?.title === "Status" && (
                                  <div className="fs-13">
                                    {data[el.control] === "ENABLED" && (
                                      <div>
                                        <div className="fs-12 text-success">{data?.campaign_start_date > currentDate ? "Scheduled" : "Delivering"}</div>
                                        <div className="fs-11 optional-text-color">Details<i className="bi bi-caret-down-fill ms-1"></i></div>
                                      </div>
                                    )}
                                    {data[el.control] === "PAUSED" && (
                                      <div>
                                        <div className="fs-12 text-dark">Paused</div>
                                        <div className="fs-11 optional-text-color">Details<i className="bi bi-caret-down-fill ms-1"></i></div>
                                      </div>
                                    )}
                                    {data[el?.control] === "ARCHIVED" && (
                                      <div>
                                        <div className="fs-12 text-secondary">Archived</div>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {el?.title === "Type" && (
                                  <>
                                    {(data[el.control] === "MANUAL" && (
                                      <div className="p-0">
                                        <div className="fs-12">Sponsored Products</div>
                                        <div className="fs-11 optional-text-color">Manual targeting</div>
                                      </div>
                                    ))}
                                  </>
                                )}
                                {el.control === "draftCampaignState" && (
                                  <div className="draft-status">Draft</div>
                                )}
                                {el?.title === "To" && (
                                  <div className="edit-campaign-settings-color fs-11 cursor-pointer logout" onClick={() => onAction(data, "edit-campaign")} >
                                    Edit campaign settings
                                  </div>
                                )}
                                {el.isLink && (
                                  <span
                                    className="custom_link custom-explore-text-color"
                                    onClick={() => onAction(data, "link")}
                                  >
                                    {data[el.control]?.toString()}
                                  </span>
                                )}
                                {el?.isShowMenu && (
                                  <>
                                    <i
                                      className="bi bi-three-dots-vertical th_menu cursor-pointer p-1"
                                      onClick={() => onClickMenu(i)}
                                    ></i>
                                    {showMenu && i === selectedIndex && (
                                      <div
                                        ref={menuRef}
                                        className={`table_menu ${showMenu} ${i} ${selectedIndex} ${showMenu &&
                                          i === selectedIndex}`}
                                      >
                                        <div
                                          className="cursor-pointer edit-settings"
                                          onClick={(e) => handleEditSettings(e, i, data)}
                                        >
                                          Edit settings
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                                {el.control === "status" && (
                                  <span>
                                    {data[el.control] === 1 && (
                                      <span className="text-success fw-bold">
                                        Active
                                      </span>
                                    )}
                                    {data[el.control] === 0 && (
                                      <span className="text-danger fw-bold">
                                        In Active
                                      </span>
                                    )}
                                    {data[el.control] === "completed" && (
                                      <span className="text-success fw-bold">
                                        Completed
                                      </span>
                                    )}
                                    {data[el.control] === "pending" && (
                                      <span className="text-danger fw-bold">
                                        Pending
                                      </span>
                                    )}
                                  </span>
                                )}
                                {el.isRemoveUndeerScor && (
                                  <span>
                                    {data[el.control]
                                      ? data[el.control].replace(/_/g, " ")
                                      : ""}
                                  </span>
                                )}
                                {el.isTextOverflowHide && (
                                  <span>
                                    {data[el.control]?.length > 100
                                      ? `${data[el.control].slice(0, 100)}...`
                                      : `${data[el.control] ? data[el.control] : ""
                                      }`}
                                  </span>
                                )}
                                {el.title === "Actions" && (
                                  <div className="actions-view">
                                    <span>
                                      {el.isEdit && (
                                        <>
                                          <span>
                                            <Tooltip
                                              title="Edit"
                                              placement="top"
                                              arrow
                                              className="custom-tooltip-position"
                                            >
                                              <span
                                                className="cursor-pointer custom-explore-text-color logout px-2 fs-12"
                                                onClick={() =>
                                                  onAction(data, "edit")
                                                }
                                              >Edit</span>
                                            </Tooltip>
                                          </span>
                                        </>
                                      )}
                                      {el.isDelete && (
                                        <Tooltip
                                          title="Delete"
                                          placement="top"
                                          arrow
                                          className="custom-tooltip-position"
                                        >
                                          <span
                                            className="cursor-pointer custom-explore-text-color logout px-2 fs-12"
                                            onClick={() => onAction(data, "delete")}
                                          >Delete</span>
                                        </Tooltip>
                                      )}
                                      {el?.isModify && (
                                        <span className="custom-explore-text-color cursor-pointer logout" onClick={() => onAction(data, "modify")}>Modify</span>
                                      )}
                                      {el?.isSave && (
                                        <span className="custom-explore-text-color cursor-pointer logout" onClick={() => onAction(data, "save")}>Save</span>
                                      )}
                                      {el?.isView && (
                                        <span className="custom-explore-text-color cursor-pointer logout" onClick={() => onAction(data, "view")}><i className="bi bi-eye-fill custom-explore-text-color" ></i></span>
                                      )}
                                    </span>
                                  </div>
                                )}
                                {el?.title === "AI Actions" && (
                                  <div className="actions-view text-center">
                                    <span>
                                      {el?.isView && (
                                        <>{el?.isNotEyeIcon && <>
                                          {(<div className="row align-items-center mb-1 fw-500">
                                            <div className='col-12 text-start'>{Object.keys(data?.ai_suggestion).length == 0 ? "None" : data?.ai_suggestion?.suggestion}</div>
                                          </div>)}
                                          {data?.ai_suggestion?.suggestion !== 'Not Qualified' && data?.ai_suggestion?.suggestion && <div className="d-flex justify-content-center">
                                            <button type="button" className="request-call-button text-white rounded-pill border-0 fs-12 cursor-pointer mx-1 p-0 px-2" onClick={(e) => onAction(data, "AI Accept")}>Accept</button>
                                            <button type="button" className={`btn btn-secondary custom-button-background rounded-pill fs-12 text-dark border-0 p-0 px-2`} onClick={(e) => onAction(data, "AI Reject")}>Reject</button>
                                          </div>}
                                        </>}
                                          {!el?.isNotEyeIcon && <>
                                            <Tooltip title="View recommendations" placement="top" arrow className="cursor-pointer custom-tooltip-th">
                                              <span className="custom-explore-text-color cursor-pointer logout" onClick={() => onAction(data, "view")}><i className="bi bi-eye-fill custom-explore-text-color" ></i></span>
                                            </Tooltip></>}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                )}

                                {el?.control === "AI_Actions_view" && (
                                  <div className="actions-view text-center" style={{fontSize: '10px'}}>

                                    {data?.last_applied_records?.map((AiData: any, index: number) => {
                                      return <div key={index}>
                                        {AiData?.rejection_message ? <span>Rejected </span> : <span>Accepted </span>}|&nbsp;&nbsp;
                                        <span>{AiData?.suggestion}</span> |&nbsp;&nbsp;
                                        {/* <span>{AiData?.rejection_message}</span> | &nbsp;&nbsp; */}
                                        <span>{AiData?.applied_date ? moment(AiData?.applied_date).format('MM-DD-YYYY HH:MM') : ''}</span>
                                      </div>
                                    })}

                                  </div>
                                )}
                              </td>
                            )}
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                </tbody>

                {!props?.tableFooterpositionTop &&
                  <tfoot>
                    {props?.tablefooterData &&
                      props?.tablefooterData?.map((data: any, i: number) => (
                        <tr key={i} >
                          {props?.TableCols?.map((el: any, index: number) => (
                            <React.Fragment key={index}>
                              {el.canShowColumn && (
                                <td style={el.style}
                                  className={`${el.class} fw_700 ${el.control === "checkbox" ? "pb_17" : ""
                                    }`} >
                                  {data[el.control]}
                                </td>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                  </tfoot>
                }
              </table>
              {
                totalItems?.length === 0 && (
                  <div className="my-5">
                    <div className="text-center d-flex justify-content-center fs-17 fw-bold my-3">
                      {props?.noDataFoundMesageTitle ? props?.noDataFoundMesageTitle : "No data available "}
                    </div>
                    {props?.noDataFoundMessageSubTitle && (
                      <div className="d-flex justify-content-center fs-15 my-3 error-sub-title-text-color ">
                        {props?.noDataFoundMessageSubTitle ? props?.noDataFoundMessageSubTitle : ""}
                      </div>
                    )}
                  </div>
                )
              }
            </div>
          </div >
        </div >
      )
      }
      <div className="row">
        <div className="col-md-5 text-start mt-2">
          <p className={`table_btm_txt  ${props?.placementBottomTextStyles ? "text-dark mt-2 fs-13" : "fs-13"}`}>
            {" "}
            {props?.tableBottomText ? props?.tableBottomText : ""}
            {props?.tableBottomTextSecondLine && (
              <div className="w-100 keyword-below-text-color fs-13">
                <span className="me-2 my-2">
                  <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle mb-1" data-fa-i2svg="">
                    <path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path>
                  </svg>
                </span>
                {props?.tableBottomTextSecondLine}
              </div>
            )}
          </p>
        </div>
        <div className="col-md-7 d-flex justify-content-end mb-5">
          {props?.isAPIPageNation ? <TablePagination
            component="div"
            showFirstButton
            showLastButton
            count={props?.tableData?.total_count}
            page={Number(props?.pageData?.page) - 1}
            onPageChange={handleChangeAPIPage}
            rowsPerPage={Number(props?.pageData?.limit)}
            onRowsPerPageChange={handleChangeRowsAPIPerPage}
            rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
          /> :
            <TablePagination
              component="div"
              showFirstButton
              showLastButton
              count={items?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
            />
          }
        </div>
      </div>
    </div >
  );
};
