import React, { useState } from 'react'
import { Tooltip } from "@mui/material";

interface Props {
    categoriesTooltTipText?: any;
}

export const ProductTargetCategoriesFilter = (props: Props) => {
    const [selectedProductTargetingBidValue, setSelectedProductTargetingBidValue] = useState<any>('Suggested bid')
    const keywordTargetingBidData = [
        {
            name: "Suggested bid",
            sub_title: `Based on past bidding activity to predict bids that are more likely to win`,
            value: "Suggested bid"
        },
        {
            name: "Custom bid",
            sub_title: `Can custom bids for each keyword without overriding your set bid amount`,
            value: "Custom bid"
        },
        {
            name: "Default bid",
            sub_title: `Can raise or lower all your set amounts in bulk`,
            value: "Default bid"
        },
    ]

    const handleProductTargetingBidTextChange = (option: string) => {
        setSelectedProductTargetingBidValue(option)
    }

    return (
        <div>
            <div className="row fs-13 border-top mb-4 mx-0">
                <div className="row fs-13 border-bottom mb-4 mx-0">
                    <div className="d-flex justify-content-start m-2 px-0">
                        <div className="fs-12 fw-bold my-1">Bid
                            <Tooltip title={("Product targeting categories tab suggested bid")} placement="top" arrow className="custom-tooltip-position cursor-pointer shadow">
                                <span className='ms-1'>
                                    <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="fs-13 ms-5 ps-4">
                            <div className="btn-group">
                                <button className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selectedProductTargetingBidValue} <span>
                                        <svg viewBox="0 0 389 512" width="13" height="13" className="fas fa-angle-down show-text-icon" data-fa-i2svg=""><path fill="currentColor" d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"></path></svg>
                                    </span>
                                </button>
                                <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                    <li className='fs-13 py-1'>
                                        {keywordTargetingBidData?.map((item: any, index: number) => (
                                            <button key={index} className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedProductTargetingBidValue === item?.value ? "active" : ""}`} value={item.value} onClick={() => handleProductTargetingBidTextChange(item?.value)}>
                                                <div className='fw-400'>{item.name}</div>
                                                <div className='dropdown-sub-title-text-color'>{item.sub_title}</div>

                                            </button>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
