import React from "react";
import { Routes, Route } from "react-router-dom";
import { CampaignType } from "../pages/Dashboard/campaign-type";
import { CampaignCreationPage } from "../pages/Dashboard/campaign-type/campaign-creation";
import { DashboardHomePage } from "../pages/Dashboard/home";
import { CampaignInfo } from "../pages/Dashboard/campaigns/info";
import { AdGroupInfoPage } from "../pages/Dashboard/campaigns/ad-groups/ad-group-info";
import { PortfolioSetings } from "../pages/Dashboard/portfolio/portfolio-setings";
import { AiCampaignsPage } from "../pages/Dashboard/campaign-type/ai-campaigns";
import { Reports } from "../pages/Dashboard/reports/reports";
import { ConfigurationPage } from "../pages/Dashboard/configuration";
import { VendorsLinks } from "../pages/Dashboard/vendors-links/vendors-links";

export const DashboardRoutes = () => {

  return (
    <div>
      <Routes>
        <Route path="/campaign-type" element={<CampaignType />}></Route>
        <Route path="/campaign-type/ai-campaigns" element={<AiCampaignsPage />}></Route>
        <Route path="/campaign-type/:type/ai-campaigns/:portfolio_id" element={<AiCampaignsPage />}></Route>
        <Route path="/campaign-type/:portfolio_id" element={<CampaignType />}></Route>
        <Route path="/campaign-creation" element={<CampaignCreationPage />}></Route>
        <Route path="/campaign/*" element={<CampaignInfo />}></Route>
        <Route path="/home/*" element={<DashboardHomePage />}></Route>
        <Route path="/ad-groups/*" element={<AdGroupInfoPage />}></Route>
        <Route path="/portfolio/settings" element={<PortfolioSetings />}></Route>
        <Route path="/portfolio/settings/:portfolio_id" element={<PortfolioSetings />}></Route>
        <Route path="/reports/*" element={<Reports />} />
        <Route path="/configuration/*" element={<ConfigurationPage />} />
        <Route path="/vendors-link" element={<VendorsLinks />} />
      </Routes>
    </div>
  );
};
