import React, { createRef, useRef, useState, RefObject, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import WrongIcon from "./../../../../../assets/images/svg_icons/wrong.svg";
import { AdgroupSettingsCard } from '../../../../../components/ad-group-creation-components/adgroup-settings-card';
import { ProductsCardPage } from '../../../../../components/ad-group-creation-components/products-card';
import { CampaignBiddingStrategyCard } from '../../../../../components/ad-group-creation-components/campaign-bidding-strategy-card';
import { ManualTargetingCard } from '../../../../../components/ad-group-creation-components/manual-targeting-card';
import { KeywordTargetingCard } from '../../../../../components/ad-group-creation-components/keyword-targeting-card';
import { NegativeKeywordTargetingCard } from '../../../../../components/ad-group-creation-components/negative-keyword-targeting';
import { CustomFooter } from '../../../../../components/footer/custom-footer';
import { ShowLeavePageModal } from '../../../../../components/ad-group-creation-components/show-leave-page-modal';
import { toast } from "react-toastify";
import { CREATE_AD_GROUP } from '../../../../../app/services/adgroups.service';

interface Props {
    selcectedAdgroupKeywordTargetingBidValue?: any;
}
export const AdGroupCreationPage = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showAdGroupsLeaveModal, setShowAdGroupsLeaveModal] = useState<boolean>(false);
    const [adGroupNameData, setAdGroupNameData] = useState<any>();
    const [getKeywordSuggestionDataStatus, setGetKeywordSuggestionDataStatus] = useState<any>()
    const [negativeKeywordTargetingsData, setnegativeKeywordTargetingsData] = useState<any>();
    const [addedkeywordTargetingData, setAddedkeywordTargetingData] = useState<any>();
    const [addedProductsAsinSkuData, setAddedProductsAsinSkuData] = useState<any>([]);
    const [addedAdGroupProdcutsData, setAddedAdGroupProdcutsData] = useState<any>();
    const [addProductsFinalStep, setAddProductsFinalStep] = useState<boolean>(false);
    const [keywordsFinalStep, setKeywordsFinalStep] = useState<boolean>(false);
    const [validationStauts, setValidationStatus] = useState<any>(false);
    const [suggestedDefaultBidData, setSuggestedDefaultBidData] = useState<any>();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");

    const navigate = useNavigate();

    const adGroupSettingsCardRef: RefObject<any> = useRef(null);
    const adGroupKeywordTargetingCardRef: RefObject<any> = useRef(null);

    const validateAdGroup = () => {
        if (adGroupSettingsCardRef.current) {
            return adGroupSettingsCardRef.current.validateForm();
        }
        return false;
    }

    const validateKeyword = () => {
        if (adGroupKeywordTargetingCardRef.current) {
            return adGroupKeywordTargetingCardRef.current.validateForm();
        }
        return false;
    }

    const onClickGobacktoSettings = () => {
        navigate("/dashboard/campaign/ad-groups");
    };

    const onClickCancel = () => {
        setShowAdGroupsLeaveModal(true);
    };

    const onClickLearnMore = () => {
        const link = "https://advertising.amazon.in/help?entityId=ENTITY2G8T90BNYY5QX#GKPA6T8WW3AYKV4Q"
        window.open(link, "_blank")
    }

    const getAdGroupsLeaveModalStatus = (status: any) => {
        if (status) {
            setShowAdGroupsLeaveModal(false)
        }
    }

    const getAdeGroupsOnLeaveStatus = (status: any) => {
        if (status) {
            navigate("/dashboard/campaign/ad-groups")
        }
    }
    const getAdGroupData = (data: any) => {
        setAdGroupNameData(data);
    }

    const getKeywordSuggestionStatus = (data: boolean) => {
        setGetKeywordSuggestionDataStatus(data)
    }

    const getNegativeKeywordTargetingData = (data: any) => {
        setnegativeKeywordTargetingsData(data)
    }

    const getAddedkeywordTargetingData = (data: any) => {
        setAddedkeywordTargetingData(data);
    }

    const getSuggestedDefaultBidData = (data: any) => {
        setSuggestedDefaultBidData(data);
    }

    const getAddedProductsAsinSkuData = (data: any) => {
        if (data?.length > 0) {
            setAddedProductsAsinSkuData(data);
        } else {
            setAddedProductsAsinSkuData([]);
        }
    }

    const getAdGroupsProductsAsinData = (data: any) => {
        setAddedAdGroupProdcutsData(data)
    }

    const onCreateAdGroup = () => {
        const isValid = validateAdGroup();
        const isValidKeywordTargeting = validateKeyword();
        let addedKeywords: any = [];
        if (addedkeywordTargetingData?.length > 0) {
            for (let index = 0; index < addedkeywordTargetingData?.length; index++) {
                const element = addedkeywordTargetingData[index];
                addedKeywords.push(
                    {
                        keyword_matchType: element?.bidData?.matchType,
                        keyword_state: 'ENABLED',
                        bid_input_update_value: element?.bid_input_update_value,
                        bid_type: element?.bidData?.bid_type,
                        bid: (element?.bidData?.bid / 100).toString(),
                        keywordText: element?.suggestionData
                    }
                )
            }
            const data = addedKeywords;
            for (let i = 0; i < data?.length; i++) {
                if (data[i].bid_type === "Custom bid") {
                    data[i].bid = data[i].bid_input_update_value
                } else if (data[i].bid_type === "Default bid") {
                    data[i].bid = data[i].bid_input_update_value
                } else {
                    data[i].bid = data[i].bid
                }
            }

        }
        if (addedProductsAsinSkuData?.length > 0) {
            setAddProductsFinalStep(true);
        } else {
            toast.error("Please add atleast one product", { autoClose: 2000 })
            setAddProductsFinalStep(true);
        }

        if (addedKeywords?.length > 0) {
            setKeywordsFinalStep(true);
        } else {
            toast.error("Please add atleast one keyword", { autoClose: 2000 })
            setKeywordsFinalStep(false);
        }
        if (isValid && isValidKeywordTargeting && addProductsFinalStep && keywordsFinalStep) {
            const preparedData = {
                user_uuid: userUuid,
                profile_id: profileID,
                campaign_id: campaignID,
                ad_group_name: adGroupNameData?.ad_group_name ? adGroupNameData?.ad_group_name : "",
                default_bid: suggestedDefaultBidData?.default_bid ? suggestedDefaultBidData?.default_bid : "3",
                keywords: addedKeywords ? addedKeywords : "",
                negative_keywords: negativeKeywordTargetingsData ? negativeKeywordTargetingsData : "",
                product_ads: addedProductsAsinSkuData ? addedProductsAsinSkuData : "",
                ad_group_state: "ENABLED"
            }

            setLoading(true)
            CREATE_AD_GROUP(preparedData).then((res) => {
                if (res.data.status === "error"  || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const response = res.data.data;
                        toast.success(res.data.message);
                        navigate("/dashboard/campaign/ad-groups")
                        setLoading(false);
                        onClickGobacktoSettings();
                    }
                }
            });
        }
    }

    return (
        <div className="container-fluid campaign-creation-bg">
            <div className="row fixed-top">
                <div className="col-12 px-0">
                    <nav className="navbar navbar-expand-lg bg-white shadow py-3">
                        <div className="container-fluid px-4">
                            <div className="d-flex">
                                <div className="bg-transaprent border-0 me-3">
                                    <img
                                        src={WrongIcon}
                                        className="mt-1 cursor-pointer"
                                        onClick={onClickCancel}
                                    />
                                </div>
                                <h2 className="navbar-brand campaign-navbar fs-18">
                                    New Ad group
                                </h2>
                            </div>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div
                                            className="custom-explore-text-color fs-13 logout cursor-pointer py-2"
                                            onClick={onClickGobacktoSettings}
                                        >
                                            Go back to campaigns
                                        </div>
                                    </div>
                                    <div className="mx-2">
                                        <button
                                            className="request-call-button text-white fs-13"
                                            onClick={onCreateAdGroup}
                                        >
                                            Create ad group
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='row'>
                {loading &&
                    <div className="text-center p-5 camapign-creation-loader-position">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className="col-md-10 mx-auto mt-5 campaign-creation-form-container">
                        <div className="col-12 card-width mx-auto mt-5 pt-1 ">
                            <h4 className="my-2 fs-28">Ad Groups</h4>
                        </div>
                        <div className="col-12 card-width mx-auto">
                            <div className="fs-13 mb-3">
                                Ad groups are groups of ads within a campaign that share the
                                same set of targeting tactics or creative type. They can help
                                you organise your campaigns more efficiently. Consider grouping
                                products that fall within the same category and price range. You
                                can create additional ad groups in campaign manager after you
                                launch your campaign.
                                <span className="learn-more-text logout cursor-pointer" onClick={onClickLearnMore}>
                                    Learn more
                                </span>
                            </div>
                        </div>
                        <AdgroupSettingsCard adGroupName={getAdGroupData} adGroupValidationStatus={validationStauts}
                            ref={adGroupSettingsCardRef} />
                        <ProductsCardPage addedProductsAsinSkuData={(e) => getAddedProductsAsinSkuData(e)} adGroupsCreationStatus={true} getAdGroupKeywordSuggestionDataStatus={getKeywordSuggestionStatus}
                            addedAdGroupsProductsAsinData={(e) => getAdGroupsProductsAsinData(e)} />
                        <CampaignBiddingStrategyCard />
                        <ManualTargetingCard />
                        <KeywordTargetingCard getData={getKeywordSuggestionDataStatus}
                            addedKeywordTargetingDataAdGroup={getAddedkeywordTargetingData}
                            adGroupValidationStatus={validationStauts}
                            addedProductAsinData={addedProductsAsinSkuData}
                            addedAdGroupAsinData={addedAdGroupProdcutsData}
                            suggestedDefaultBid={getSuggestedDefaultBidData}
                            ref={adGroupKeywordTargetingCardRef}
                        />
                        <NegativeKeywordTargetingCard addedNegativeKeywordTargetingData={getNegativeKeywordTargetingData} />
                        <div className="buttons-container card-width mx-auto">
                            <div className="d-flex justify-content-end">
                                <div className="">
                                    <button className="request-call-button text-white fs-13 px-5" onClick={onCreateAdGroup}>
                                        Create ad group
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="footer-container mt-5 pt-4 pb-2">
                            <CustomFooter />
                        </div>
                        {showAdGroupsLeaveModal && (
                            <ShowLeavePageModal
                                ismodalShow={showAdGroupsLeaveModal}
                                modalStatus={getAdGroupsLeaveModalStatus}
                                OnLeavePageStatus={getAdeGroupsOnLeaveStatus} />
                        )}
                    </div>
                }
            </div>
        </div>
    )
}