import React from 'react';
import './App.css';
import './assets/css/custom.css'
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { RoutesPage } from './Routes';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from "react-redux";
import { store } from './redux/store/store';
import { ProfileIdProvider } from './components/profile';

function App() {
  return (
    <div className='App'>
      <ProfileIdProvider>
        <Provider store={store}>
          <ToastContainer />
          <RoutesPage />
        </Provider>
      </ProfileIdProvider>
    </div>
  );
}

export default App;
