import React, { useEffect, useReducer, useState } from 'react'
import { GET_USER_PORTFOLIOS } from '../../app/services/portfolios.service';
import { toast } from "react-toastify";
import { Tooltip } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { CampaignBiddingCampaignDetailsData } from '../../redux/actions';
import { useParams } from 'react-router-dom';
import NoProductImage from './../../assets/images/no-product-image.png';
import moment from 'moment';
import { durationsList } from '../../app/utils/durationsList';


interface Props {
    addedKeywordSuggestionData?: any
    aiFlowCampaignSettingsData?: (data: any) => void;
    selectedProductTab?: any;
    CampaignBiddingCampaignDetailsDataReducer?: any;
    campaignBiddingCampainDetailsData?: any;
    isShowData?: any;
    addedGroupKeywordSuggestionData?: any;
    byDefaultTabData?: (data: any) => void;
    triggercamapignSettingsValidation?: any;
    groupUuid?: any;
    allAiKeywordData?: (data: any) => void;
    isProceedWithAllProducts?: any;
    allProducts?: any;
}

const AiCampaignDataProductsListCard = (props: Props) => {
    const currentDate = new Date().toISOString().split('T')[0];
    const [portfolioData, setPortfolioData] = useState<any>([]);
    const [allAiKeywordData, setAllAiKeywordData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = Number(portfolio_id);
    let { portfolio_uuid } = useParams<{ portfolio_uuid: any }>();
    const [showKeywordDetails, setShowKeywordDetails] = useState<boolean>(false)
    const [showGroupKeywordDetails, setShowGroupKeywordDetails] = useState<boolean>(false);
    const [keyWordModalDetails, setKeyWordModalDetails] = useState<any>([])
    const [productModalDetails, setProductModalDetails] = useState<any>([])
    const [groupKeyWordModalDetails, setGroupKeywordModalDetails] = useState<any>([]);
    const [selectedTabStr, setSelectedTabStr] = useState<any>();

    const [selectedGroupProducts, setSelectedGroupProducts] = useState<any>([]);
    const [selectedGroupProdcutsData, setSelectedGroupProdcutsData] = useState<any>()
    // props?.addedGroupKeywordSuggestionData ? props?.addedGroupKeywordSuggestionData : []
    const [selectedProdcutsData, setSelectedProdcutsData] = useState<any>(props?.addedKeywordSuggestionData ? props?.addedKeywordSuggestionData : [])
    const [campaignSettingsData, setCampaignSettingsData] = useState<any>({
        campaign_name: "",
        budget: "",
        portfolio_id: portfolio_uuid ? portfolio_uuid : "",
        start_date: "",
        end_date: "",
        roas: "",
        // qualifier: ""
    })

    const [defaultcampaignSettingsData, setDefaultCampaignSettingsData] = useState<any>({
        campaign_name: "",
        budget: "",
        portfolio_id: portfolio_uuid ? portfolio_uuid : "",
        start_date: moment().format('YYYY-MM-DD'),
        end_date: "",
        roas: ""
    })
    const [formErrors, setFormErrors] = useState<any>([]);

    const aiCampaignSettingsDatatwo = [
        {
            id: "1"
        },
        {
            id: "2"
        },
        {
            id: "3"
        },
    ]
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        if (portfolioId == 1) {
            const productsPrepareData = props?.isProceedWithAllProducts ? props?.allProducts : selectedProdcutsData;
            const errorValidation: any = formErrors;
            let products = [];
            let keywords: any = [];
            let product_info: any = [];
            for (let item of productsPrepareData) {
                let product_obj = {
                    "product_name": item['asin'],
                };
                products.push(product_obj);
                product_info.push(item)
                keywords = item['add_keywords'] ? keywords.concat(item['add_keywords']) : keywords.concat([]);
            }
            let campaigns = [];
            for (let index = 0; index < products.length; index++) {
                let product = products[index];
                for (let keyword_index = 0; keyword_index < keywords.length; keyword_index++) {
                    let keyword = keywords[keyword_index];
                    let campaign_obj = {
                        keyword_camapign_info: {
                            campaign_name: `kw_Single_port_kw-${(keyword?.keywordText).substring(0, 10)}_pr-${product['product_name']}_${moment().format('YYYY-MM-DD')}_${keyword_index}`,
                            budget: "",
                            portfolio_id: "",
                            start_date: moment().format('YYYY-MM-DD'),
                            end_date: "",
                            roas: "",
                            // qualifier: "",
                        },
                        keyword_info: keywords[keyword_index],
                        product_info: product_info[index]
                    };

                    errorValidation?.push({
                        campaign_name: "",
                        budget: "",
                        start_date: "",
                        end_date: "",
                        portfolio_id: portfolio_uuid ? portfolio_uuid : "",
                        roas: ""
                    })
                    setFormErrors([...errorValidation]);
                    campaigns.push(campaign_obj);
                }
            }
            setAllAiKeywordData([...campaigns])

            for (let i = 0; i < productsPrepareData?.length; i++) {
                if (props?.selectedProductTab?.uuid) {
                    if (productsPrepareData[i]?.uuid === props?.selectedProductTab?.uuid) {
                        productsPrepareData[i].isVisibleKeyWordData = true;
                    } else {
                        productsPrepareData[i].isVisibleKeyWordData = false;
                    }
                    setSelectedProdcutsData(productsPrepareData);
                } else {
                    productsPrepareData[0].isVisibleKeyWordData = true;
                    setSelectedProdcutsData(productsPrepareData);
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            }
            getUserPortfolios();
        }
        if (portfolioId == 2) {
            const productsPrepareData = props?.addedGroupKeywordSuggestionData ? props?.addedGroupKeywordSuggestionData : selectedGroupProdcutsData;
            const errorValidation: any = formErrors;
            for (let i = 0; i < productsPrepareData?.length; i++) {
                productsPrepareData[i].keywords_data?.forEach((element: any) => {
                    errorValidation?.push({
                        campaign_name: "",
                        budget: "",
                        start_date: "",
                        end_date: "",
                        portfolio_id: portfolio_uuid ? portfolio_uuid : "",
                        roas: "",
                        // qualifier: ""
                    })
                    setFormErrors([...errorValidation]);
                });
                if (props?.selectedProductTab?.uuid) {
                    if (productsPrepareData[i]?.uuid === props?.selectedProductTab?.uuid) {
                        productsPrepareData[i].isVisibleKeyWordData = true;
                    } else {
                        productsPrepareData[i].isVisibleKeyWordData = false;
                    }
                    setSelectedGroupProdcutsData([...productsPrepareData]);
                } else {
                    productsPrepareData[0].isVisibleKeyWordData = true;
                    setSelectedGroupProdcutsData([...productsPrepareData]);
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            }
            getUserPortfolios();
        }
    }, [])

    useEffect(() => {
        if (portfolioId == 1) {
            if (props?.triggercamapignSettingsValidation?.value) {
                validateCamapignSettingsForm()
            }
        }
        if (portfolioId == 2) {
            if (props?.triggercamapignSettingsValidation?.value) {
                validateGroupsSettingsForm()
            }
        }
    }, [props?.triggercamapignSettingsValidation?.value])

    const getUserPortfolios = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            is_report_data: false
        }
        setLoading(true);
        GET_USER_PORTFOLIOS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data.data;
                    const portfoliosList = [];
                    for (let index = 0; index < response?.length; index++) {
                        const element = response[index];
                        portfoliosList.push({
                            title: element?.portfolio_name,
                            value: element?.portfolio_id
                        })
                    }
                    setPortfolioData(portfoliosList)
                    setLoading(false);
                }
            }
        });
    }
    const handleInputApplyToAll = (value: any, i: number, inputType: string) => {
        const data = campaignSettingsData;
        const preapredData = portfolioId === 1 ? allAiKeywordData : selectedGroupProdcutsData; //allAiKeywordData;
        if (inputType === "budget") {
            preapredData.forEach((element: any) => {
                element.keyword_camapign_info.budget = value;
            });
        } else if (inputType === "portfolio_id") {
            preapredData.forEach((element: any) => {
                element.keyword_camapign_info.portfolio_id = value;
            });
        } else if (inputType === "start_date") {
            preapredData.forEach((element: any) => {
                element.keyword_camapign_info.start_date = value;
            });

        } else if (inputType === "end_date") {
            preapredData.forEach((element: any) => {
                element.keyword_camapign_info.end_date = value;
            });
        } else if (inputType === "roas") {
            preapredData.forEach((element: any) => {
                element.keyword_camapign_info.roas = value;
            });
        }

        if (props?.campaignBiddingCampainDetailsData) {
            dispatch(props?.campaignBiddingCampainDetailsData(preapredData));
        }
        if (props?.aiFlowCampaignSettingsData) {
            props?.aiFlowCampaignSettingsData({ ...data });
        }

        if (props?.allAiKeywordData) {
            props?.allAiKeywordData([...preapredData]);
        }
        preapredData.forEach((element: any, index: number) => {
            const errorValidation: any = formErrors;
            errorValidation[index][inputType] = validateSpecificFeild(inputType, value);
            setFormErrors([...errorValidation]);
        })
    }
    const handleInput = (e: any, i: number, index: number, inputType: string) => {
        const { name, value } = e.target;
        const data = campaignSettingsData;
        const defaultData = defaultcampaignSettingsData;
        data[name] = value;
        const preapredData = portfolioId === 1 ? allAiKeywordData : selectedGroupProdcutsData;

        if (inputType === "Camapign_name") {
            preapredData[index].keyword_camapign_info.campaign_name = `adv_SPM_${e.target.value}`;
        } else if (inputType === "budget") {
            const firstValue = defaultData.budget;
            if (index === -1) {
                defaultData.budget = e.target.value;
                setDefaultCampaignSettingsData(defaultData);
                preapredData.forEach((element: any) => {
                    if (element.keyword_camapign_info.budget === firstValue) {
                        element.keyword_camapign_info.budget = e.target.value;
                    }
                });
            } else {
                preapredData[index].keyword_camapign_info.budget = e.target.value;
            }
        } else if (inputType === "portfolio_id") {
            const firstValue = defaultData.portfolio_id;
            if (index === -1) {
                defaultData.portfolio_id = e.target.value;
                const selectedPortfolioData = portfolioData.find((data: any) => data.value == e.target.value);
                console.log('selectedPortfolioData', selectedPortfolioData, typeof e.target.value, portfolioData);

                setDefaultCampaignSettingsData(defaultData);
                preapredData.forEach((element: any) => {
                    if (element.keyword_camapign_info.portfolio_id === firstValue) {
                        // Split the campaign_name to update the specific index
                        const parts = element.keyword_camapign_info.campaign_name.split('_');
                        console.log('parts', parts);

                        // Update the desired index (e.g., assuming portfolio is at index 2)
                        const portfolioIndex = portfolioId === 1 ? 2 : 4; // Adjust this index as per your string structure
                        if (portfolioIndex < parts.length) {
                            parts[portfolioIndex] = `port-${(selectedPortfolioData?.title).substring(0, 10)}` || `${parts[portfolioIndex]}`;
                        }
                        // Rebuild the campaign_name
                        element.keyword_camapign_info.campaign_name = parts.join('_');
                        // Update the portfolio_id
                        element.keyword_camapign_info.portfolio_id = e.target.value;
                    }
                });
            } else {
                const selectedPortfolioData = portfolioData.find((data: any) => data.value == e.target.value);
                const parts = preapredData[index].keyword_camapign_info.campaign_name.split('_');
                const portfolioIndex = portfolioId === 1 ? 2 : 4; // Adjust this index as per your string structure
                if (portfolioIndex < parts.length) {
                    parts[portfolioIndex] = `port-${(selectedPortfolioData?.title).substring(0, 10)}` || `${parts[portfolioIndex]}`;
                }
                preapredData[index].keyword_camapign_info.campaign_name = parts.join('_');
                preapredData[index].keyword_camapign_info.portfolio_id = e.target.value;
            }


            // if (index === -1) {
            //     defaultData.portfolio_id = e.target.value;
            //     const selectedPortfolioData = portfolioData.find((data: any) => data.value == e.target.value);
            //     console.log('selectedPortfolioData', selectedPortfolioData, typeof e.target.value, portfolioData);

            //     setDefaultCampaignSettingsData(defaultData);
            //     preapredData.forEach((element: any) => {
            //         if (element.keyword_camapign_info.portfolio_id === firstValue) {
            //             element.keyword_camapign_info.campaign_name = element.keyword_camapign_info.campaign_name.replace(element.keyword_camapign_info.portfolio_id ? element.keyword_camapign_info.portfolio_id : '-portfolio', `${element.keyword_camapign_info.portfolio_id ? '' : '-'}${selectedPortfolioData?.title}`);
            //             element.keyword_camapign_info.portfolio_id = e.target.value;
            //         }
            //     });
            // } else {
            //     const selectedPortfolioData = portfolioData.find((data: any) => data.value == e.target.value);
            //     preapredData[index].keyword_camapign_info.campaign_name = preapredData[index].keyword_camapign_info.campaign_name.replace('-portfolio', `-${selectedPortfolioData?.title}`);
            //     preapredData[index].keyword_camapign_info.portfolio_id = e.target.value;
            // }
        } else if (inputType === "start_date") {
            const firstValue = defaultData.start_date;
            if (index === -1) {
                defaultData.start_date = e.target.value;
                setDefaultCampaignSettingsData(defaultData);
                preapredData.forEach((element: any) => {
                    if (element.keyword_camapign_info.start_date === firstValue) {
                        element.keyword_camapign_info.start_date = e.target.value;
                    }
                });
            } else {
                preapredData[index].keyword_camapign_info.start_date = e.target.value;
            }
        } else if (inputType === "end_date") {
            const firstValue = defaultData.end_date;
            if (index === -1) {
                defaultData.end_date = e.target.value;
                setDefaultCampaignSettingsData(defaultData);
                preapredData.forEach((element: any) => {
                    if (element.keyword_camapign_info.end_date === firstValue) {
                        element.keyword_camapign_info.end_date = e.target.value;
                    }
                });
            } else {
                preapredData[index].keyword_camapign_info.end_date = e.target.value;
            }
        } else if (inputType === "roas") {
            const firstValue = defaultData.roas;
            if (index === -1) {
                defaultData.roas = e.target.value;
                setDefaultCampaignSettingsData(defaultData);
                preapredData.forEach((element: any) => {
                    if (element.keyword_camapign_info.roas === firstValue) {
                        element.keyword_camapign_info.roas = e.target.value;
                        element.keyword_camapign_info.benchmark = e.target.value;
                    }
                });
            } else {
                preapredData[index].keyword_camapign_info.roas = e.target.value;
                preapredData[index].keyword_camapign_info.benchmark = e.target.value;
            }
        }
        // else if (inputType === "benchmark") {
        //     const firstValue = defaultData.benchmark;
        //     if (index === -1) {
        //         defaultData.benchmark = e.target.value;
        //         setDefaultCampaignSettingsData(defaultData);
        //         preapredData.forEach((element: any) => {
        //             if (element.keyword_camapign_info.benchmark === firstValue) {
        //                 element.keyword_camapign_info.benchmark = e.target.value;
        //             }
        //         });
        //     } else {
        //         preapredData[index].keyword_camapign_info.benchmark = e.target.value;
        //     }
        // }

        else if (inputType === "qualifier") {
            const firstValue = defaultData.qualifier;
            if (index === -1) {
                defaultData.qualifier = e.target.value;
                setDefaultCampaignSettingsData(defaultData);
                preapredData.forEach((element: any) => {
                    if (element.keyword_camapign_info.qualifier === firstValue) {
                        element.keyword_camapign_info.qualifier = e.target.value;
                    }
                });
            } else {
                preapredData[index].keyword_camapign_info.qualifier = e.target.value;
            }
        }
        else if (inputType === "duration") {
            const firstValue = defaultData.duration;
            if (index === -1) {
                defaultData.duration = e.target.value;
                setDefaultCampaignSettingsData(defaultData);
                preapredData.forEach((element: any) => {
                    if (element.keyword_camapign_info.duration === firstValue) {
                        element.keyword_camapign_info.duration = e.target.value;
                    }
                });
            } else {
                preapredData[index].keyword_camapign_info.duration = e.target.value;
            }
        }
        if (portfolioId == "1") {
        }

        // for (let i = 0; i < preapredData.length; i++) {
        //     const element = preapredData[i];
        //     element.keyword_camapign_info.benchmark = element.keyword_camapign_info.benchmark || "2"
        // }

        if (props?.campaignBiddingCampainDetailsData) {
            dispatch(props?.campaignBiddingCampainDetailsData(preapredData));
        }
        setAllAiKeywordData([...preapredData])
        if (index !== -1) {
            setCampaignSettingsData((prevData: any) => ({
                ...prevData,
                [name]: value,
            }));
            const errorValidation: any = formErrors;
            errorValidation[index][name] = validateSpecificFeild(name, value);
            setFormErrors([...errorValidation]);
        } else {
            preapredData.forEach((element: any, index: number) => {
                const errorValidation: any = formErrors;
                errorValidation[index][name] = validateSpecificFeild(name, value);
                setFormErrors([...errorValidation]);
            });
        }
        if (props?.aiFlowCampaignSettingsData) {
            props?.aiFlowCampaignSettingsData(data)
        }

        if (props?.allAiKeywordData && portfolioId === 1) {
            props?.allAiKeywordData([...preapredData]);
        } else {

        }
    }



    const handleInputBlur = (e: any, i: number, index: number, inputType: string) => {
        const errors: any = {}
        const { name, value } = e.target;
        const data = campaignSettingsData;
        data[name] = value;
        const preapredData = selectedProdcutsData;
        if (preapredData?.length > 1) {
            const allKeywords: any = [];
            preapredData?.map((element: any) => {
                element?.add_keywords?.forEach((subElement: any) => {
                    allKeywords.push(subElement)
                });
                element?.add_keywords?.filter((subElement: any) => subElement?.keyword_camapign_info[name].replace('adv_SPM_', '') === value)
            })
            const isDataExist = allKeywords.filter((element: any) => element?.keyword_camapign_info[name].replace('adv_SPM_', '') === value);
            if (isDataExist?.length > 1) {
                if (name === 'campaign_name') {
                    const errorValidation = formErrors;
                    errorValidation[index][name] = "Campaign name already exist"
                    setFormErrors([...errorValidation])
                    return Object.keys(errorValidation).length === 0;
                }
            }
        } else {
            const isDataExist = preapredData[0].add_keywords.filter((element: any) => element?.keyword_camapign_info[name].replace('adv_SPM_', '') === value);
            if (isDataExist?.length > 1) {
                if (name === 'campaign_name') {
                    const errorValidation = formErrors;
                    errorValidation[index][name] = "Campaign name already exist"
                    setFormErrors([...errorValidation])
                    return Object.keys(errorValidation).length === 0;
                }
            }
        }
    }

    const handleGroupInput = (e: any, i: number, inputType: string) => {
        const { name, value } = e.target;
        const data = campaignSettingsData;
        data[name] = value;
        const preapredData = selectedGroupProdcutsData;
        if (inputType === "Camapign_name") {
            preapredData[i].keyword_camapign_info.campaign_name = `adv_SPM_${e.target.value}`;
        }
        else if (inputType === "budget") {
            preapredData[i].keyword_camapign_info.budget = e.target.value;
        } else if (inputType === "portfolio_id") {
            preapredData[i].keyword_camapign_info.portfolio_id = portfolio_uuid ? portfolio_uuid : e.target.value;
        } else if (inputType === "start_date") {
            preapredData[i].keyword_camapign_info.start_date = e.target.value;
        } else if (inputType === "end_date") {
            preapredData[i].keyword_camapign_info.end_date = e.target.value;
        } else if (inputType === "roas") {
            preapredData[i].keyword_camapign_info.roas = e.target.value;
            preapredData[i].keyword_camapign_info.benchmark = e.target.value;
        }
        setCampaignSettingsData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        for (let i = 0; i < preapredData.length; i++) {
            const element = preapredData[i];
            element.keyword_camapign_info.benchmark = element?.keyword_camapign_info?.roas
        }
        const errorValidation: any = formErrors;
        errorValidation[i][name] = validateSpecificFeildtwo(name, value);
        setFormErrors([...errorValidation]);
    }

    const validateSpecificFeildtwo = (name: any, value: any) => {
        if (name === "budget") {
            if (!value) {
                return "Please enter daily budget";
            }
            else if (parseInt(value) < 50) {
                return "Daily budget should be a minimum of 50.";
            }
        }

        if (name === "roas") {
            if (!value) {
                return "Please enter roas"
            } else if (value <= 0) {
                return "Roas should be a minimum of 1"
            }
        }
        return ""
    }

    const handleGroupInputBlur = (e: any, i: number, inputType: string) => {
        const { name, value } = e.target;
        const data = campaignSettingsData;
        data[name] = value;
        const preapredData = selectedGroupProdcutsData;
        if (preapredData?.length > 1) {
            const isDataExist = preapredData.filter((element: any) => element?.keyword_camapign_info.campaign_name.replace('adv_SPM_', '') === value && element?.keyword_camapign_info.campaign_name);
            if (isDataExist?.length > 1) {
                if (name === 'campaign_name') {
                    const errorValidation = formErrors;
                    errorValidation[i][name] = "Campaign name already exist"
                    setFormErrors([...errorValidation])
                    return Object.keys(errorValidation).length === 0;
                }
            }
        } else {
            if (inputType === "Camapign_name") {
                preapredData[i].keyword_camapign_info.campaign_name = `adv_SPM_${e.target.value}`;
            } else if (inputType === "budget") {
                preapredData[i].keyword_camapign_info.budget = e.target.value;
            } else if (inputType === "portfolio_id") {
                preapredData[i].keyword_camapign_info.portfolio_id = e.target.value;
            } else if (inputType === "start_date") {
                preapredData[i].keyword_camapign_info.start_date = e.target.value;
            } else if (inputType === "end_date") {
                preapredData[i].keyword_camapign_info.end_date = e.target.value;
            } else if (inputType === 'roas') {
                preapredData[i].keyword_camapign_info.roas = e.target.value;
            }
            if (props?.campaignBiddingCampainDetailsData) {
                dispatch(props?.campaignBiddingCampainDetailsData(preapredData));
            }

            setCampaignSettingsData((prevData: any) => ({
                ...prevData,
                [name]: value,
            }));

            if (props?.aiFlowCampaignSettingsData) {
                props?.aiFlowCampaignSettingsData(data)
            }
        }
    }

    const validateGroupsSettingsForm = () => {
        const errors: any = {}
        const productsPrepareData = selectedGroupProdcutsData;

        const errorValidation = formErrors;
        for (let i = 0; i < productsPrepareData?.length; i++) {
            const element: any = productsPrepareData[i];
            if (!element?.keyword_camapign_info?.campaign_name) {
                errorValidation[i].campaign_name = "Please enter campaign name"
            }
            if (!element?.keyword_camapign_info?.budget) {
                errorValidation[i].budget = "Please enter budget"
            }
            if (!element?.keyword_camapign_info?.start_date) {
                errorValidation[i].start_date = "Please select start date"
            }
            setFormErrors(errorValidation);
        }

        setFormErrors(errorValidation)
        return Object.keys(errors).length === 0;
    }
    const validateCamapignSettingsForm = () => {
        const errors: any = {}
        const productsPrepareData = allAiKeywordData;
        const errorValidation = formErrors;
        productsPrepareData?.forEach((element: any, index: number) => {
            if (!element?.keyword_camapign_info?.campaign_name) {
                errorValidation[index].campaign_name = "Please enter campaign name"
            }
            if (!element?.keyword_camapign_info?.budget) {
                errorValidation[index].budget = "Please enter budget"
            } else if (parseInt(element?.keyword_camapign_info?.budget) < 50) {
                errorValidation[index].budget = "Daily budget should be a minimum of 50."
            }

            if (!element?.keyword_camapign_info?.start_date) {
                errorValidation[index].start_date = "Please select start date"
            }

            if (!element?.keyword_camapign_info?.roas) {
                errorValidation[index].roas = "Please enter roas"
            } else if (element?.keyword_camapign_info?.roas < 0 || element?.keyword_camapign_info?.roas == "") {
                errorValidation[index].roas = "Please enter valid roas"
            }
        });
        setFormErrors(errorValidation)
        return Object.keys(errors).length === 0;
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "budget") {
            if (!value) {
                return "Please enter budget"
            }
            else if (value < 50) {
                return "Daily budget should be a minimum of 50."
            }
        }

        if (name === "roas") {
            if (!value) {
                return "Please enter roas"
            } else if (value <= 0) {
                return "Roas should be a minimum of 1"
            }
        }
        return ""
    }

    const onClickViewKeywordDetails = (i: number, index: number, keyData: any) => {
        setKeyWordModalDetails(keyData?.keyword_info)
        setProductModalDetails(keyData?.product_info)
        setShowKeywordDetails(true);
    }
    const onClickViewGroupKeywordDetails = (i: number, data: any) => {
        setSelectedTabStr('keyword')
        setSelectedGroupProducts([...data?.products_data])
        setGroupKeywordModalDetails(data?.keywords_data)
        setShowGroupKeywordDetails(true);
    }
    const selectedTab = (tab: any) => {
        setSelectedTabStr(tab)
    }
    return (
        <div className='container-fluid'>
            {/* {portfolioId == 1 &&  */}
            <>
                <div className='d-flex justify-content-between px-1'>
                    <div>
                        <h5 className="card-title fs-14 fw-700">Portfolio <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.portfolio_id, 0, "portfolio_id")}>Apply to all</span></h5>
                        <div className="">
                            <select name="portfolio_id" className='form-select campaign-drop-down-input border-dark fs-13' onChange={(e) => handleInput(e, 0, -1, "portfolio_id")}>
                                <option value={""}>Select portfolio</option>
                                {portfolioData?.map((data: any, portfolioIndex: number) => (
                                    <option className='' key={portfolioIndex} value={data?.value}>{data?.title}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <h5 className="card-title fs-14 fw-700">Daily budget <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.budget, 0, "budget")}>Apply to all</span></h5>
                        <div className="input-group mb-3 my-2">
                            <span className="input-group-text budget-input-group bg-white border-dark px-2 keyword-below-text-color">₹</span>
                            <input type="number" name="budget" min={50} className="form-control budget-input-group border-dark border-start-0 fs-13"
                                onChange={(e) => handleInput(e, 0, -1, "budget")} />
                        </div>
                        {formErrors[0]?.budget && (<div className="text-danger ">{formErrors[0]?.budget}</div>)}
                    </div>
                    <div className="start-date-position">
                        <label className="form-label card-title fs-14 fw-700">Start date <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.start_date, 0, "start_date")}>Apply to all</span></label>
                        <input
                            type="date"
                            name="start_date"
                            className="form-control input border-dark fs-13"
                            min={currentDate}
                            value={defaultcampaignSettingsData?.start_date}
                            onChange={(e) => handleInput(e, 0, -1, "start_date")}
                        />
                        {formErrors[0]?.start_date && (<div className='text-danger mt-2 pt-2'>{formErrors[0]?.start_date}</div>)}
                    </div>
                    <div className="start-date-position">
                        <label className="form-label card-title fs-14 fw-700">End date <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.end_date, 0, "end_date")}>Apply to all</span></label>
                        <input
                            type="date"
                            name="end_date"
                            className="form-control input border-dark fs-13"
                            onChange={(e) => handleInput(e, 0, -1, "end_date")}
                        />
                    </div>
                    <div className='start-date-position'>
                        <label className="form-label card-title fs-14 fw-700">Roas Benchmark <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.roas, 0, "roas")}>Apply to all</span></label>
                        <input
                            type="number"
                            name="roas"
                            placeholder='Roas Benchmark'
                            className="form-control input border-dark fs-13"
                            onChange={(e) => handleInput(e, 0, -1, "roas")}
                        />
                    </div>
                    {/* <div className='start-date-position'>
                        <label className="form-label card-title fs-14 fw-700">Benchmark <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.benchmark, 0, "benchmark")}>Apply to all</span></label>
                        <input
                            type="number"
                            name="benchmark"
                            placeholder='Benchmark'
                            className="form-control input border-dark fs-13"
                            onChange={(e) => handleInput(e, 0, -1, "benchmark")}
                        />
                    </div> */}
                    <div className='start-date-position'>
                        <label className="form-label card-title fs-14 fw-700">Qualifier <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.qualifier, 0, "qualifier")}>Apply to all</span></label>
                        <input
                            type="number"
                            name="qualifier"
                            placeholder='Qualifier'
                            className="form-control input border-dark fs-13"
                            onChange={(e) => handleInput(e, 0, -1, "qualifier")}
                        />
                    </div>
                    <div className='start-date-position'>
                        <label className="form-label card-title fs-14 fw-700">Duration <span className='fs-13 ai-products-text-color cursor-pointer' onClick={() => handleInputApplyToAll(defaultcampaignSettingsData?.duration, 0, "duration")}>Apply to all</span></label>
                        <select name="duration" className="form-control input border-dark fs-13" onChange={(e) => handleInput(e, 0, -1, "duration")}>
                            <option value={""}>Select duration</option>
                            {durationsList?.map((data: any, portfolioIndex: number) => (
                                <option className='' key={portfolioIndex} value={data?.value}>{data?.name}</option>
                            ))}
                        </select>
                        {/* <input
                            type="number"
                            name="roas"
                            placeholder='Roas'
                            className="form-control input border-dark fs-13"
                            onChange={(e) => handleInput(e, 0, -1, "roas")}
                        /> */}
                    </div>
                </div>
                <hr />
            </>
            {/* } */}

            {portfolioId == 1 &&
                <div className={`${allAiKeywordData?.length > 5 ? "campaign-settings-scroll" : ""}`}>
                    {allAiKeywordData?.map((keyData: any, index: number) => {
                        return (<>
                            <div className='d-flex justify-content-between px-1' key={index}>
                                <div className='w-20 me-2'>
                                    <h5 className="card-title fs-14 fw-700">Camapign name</h5>
                                    <div className="input-group">
                                        <span className="input-group-text campaign-drop-down-input border-dark fs-13" id="basic-addon1">adv_SPM</span>
                                        <input type="text" name="campaign_name" className="form-control campaign-drop-down-input border-dark border-start-0 fs-13 text-dark" placeholder="Campaign name" onChange={(e) => handleInput(e, 1, index, "Camapign_name")} onBlur={(e) => handleInputBlur(e, 1, index, "Camapign_name")}
                                            value={keyData?.keyword_camapign_info?.campaign_name} />
                                    </div>
                                    {formErrors[index]?.campaign_name && (<div className="text-danger mt-2 pt-2">{formErrors[index]?.campaign_name}</div>)}
                                </div>
                                <div className='me-2'>
                                    <h5 className="card-title fs-14 fw-700">Portfolio</h5>
                                    <div className="">
                                        <select name="portfolio_id" className='form-select campaign-drop-down-input border-dark fs-13' value={portfolio_uuid ? portfolio_uuid : keyData?.keyword_camapign_info?.portfolio_id} onChange={(e) => handleInput(e, 1, index, "portfolio_id")}>
                                            <option value={""}>Select portfolio</option>
                                            {portfolioData?.map((data: any, portfolioIndex: number) => (
                                                <option className='' key={portfolioIndex} value={data?.value}>{data?.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='w-10 me-2'>
                                    <h5 className="card-title fs-14 fw-700">Daily budget</h5>
                                    <div className="input-group mb-3 my-2">
                                        <span className="input-group-text budget-input-group bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                        <input type="number" name="budget" defaultValue={keyData?.keyword_camapign_info?.budget} min={50} className="form-control budget-input-group border-dark border-start-0 fs-13"
                                            onChange={(e) => handleInput(e, 1, index, "budget")} />
                                    </div>
                                    {formErrors[index]?.budget && (<div className="text-danger ">{formErrors[index]?.budget}</div>)}
                                </div>
                                <div className="w-10 start-date-position me-2">
                                    <label className="form-label card-title fs-14 fw-700">Start date</label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        className="form-control input border-dark fs-13"
                                        value={keyData?.keyword_camapign_info?.start_date}
                                        min={currentDate}
                                        onChange={(e) => handleInput(e, 1, index, "start_date")}
                                    />
                                    {formErrors[index]?.start_date && (<div className='text-danger mt-2 pt-2'>{formErrors[index]?.start_date}</div>)}
                                </div>
                                <div className="w-10 start-date-position me-2">
                                    <label className="form-label card-title fs-14 fw-700">End date</label>
                                    <input
                                        type="date"
                                        name="end_date"
                                        className="form-control input border-dark fs-13"
                                        min={keyData?.keyword_camapign_info?.start_date}
                                        defaultValue={keyData?.keyword_camapign_info?.end_date}
                                        onChange={(e) => handleInput(e, 1, index, "end_date")}
                                    />
                                </div>
                                {/* </div>
                            <div className='d-flex px-1'> */}
                                <div className='w-10 start-date-position me-2'>
                                    <label className="form-label card-title fs-14 fw-700">Roas Benchmark</label>
                                    <input
                                        type="number"
                                        name="roas"
                                        placeholder='Roas Benchmark'
                                        className="form-control input border-dark fs-13"
                                        min={keyData?.keyword_camapign_info?.roas}
                                        defaultValue={keyData?.keyword_camapign_info?.roas}
                                        onChange={(e) => handleInput(e, 1, index, "roas")}
                                    />
                                    {formErrors[index]?.roas && (<div className='text-danger mt-2 pt-2'>{formErrors[index]?.roas}</div>)}
                                </div>
                                {/* <div className='w-10 start-date-position me-2'>
                                    <label className="form-label card-title fs-14 fw-700">Benchmark</label>
                                    <input
                                        type="number"
                                        name="benchmark"
                                        placeholder='Benchmark'
                                        className="form-control input border-dark fs-13"
                                        min={keyData?.keyword_camapign_info?.benchmark}
                                        defaultValue={keyData?.keyword_camapign_info?.benchmark}
                                        onChange={(e) => handleInput(e, 1, index, "benchmark")}
                                    />
                                    {formErrors[index]?.benchmark && (<div className='text-danger mt-2 pt-2'>{formErrors[index]?.benchmark}</div>)}
                                </div> */}
                                <div className='w-10 start-date-position me-2'>
                                    <label className="form-label card-title fs-14 fw-700">Qualifier</label>
                                    <input
                                        type="number"
                                        name="qualifier"
                                        placeholder='Qualifier'
                                        className="form-control input border-dark fs-13"
                                        min={keyData?.keyword_camapign_info?.qualifier}
                                        defaultValue={keyData?.keyword_camapign_info?.qualifier}
                                        onChange={(e) => handleInput(e, 1, index, "qualifier")}
                                    />
                                    {formErrors[index]?.qualifier && (<div className='text-danger mt-2 pt-2'>{formErrors[index]?.qualifier}</div>)}
                                </div>
                                <div className='w-10 start-date-position me-2'>
                                    <label className="form-label card-title fs-14 fw-700">Duration</label>
                                    <select name="duration" className="form-control input border-dark fs-13" value={keyData?.keyword_camapign_info?.duration} onChange={(e) => handleInput(e, 1, index, "duration")}>
                                        <option value={""}>Select duration</option>
                                        {durationsList?.map((data: any, portfolioIndex: number) => (
                                            <option className='' key={portfolioIndex} value={data?.value}>{data?.name}</option>
                                        ))}
                                    </select>
                                    {/* <input
                                        type="number"
                                        name="duration"
                                        placeholder='Duration'
                                        className="form-control input border-dark fs-13"
                                        min={keyData?.keyword_camapign_info?.duration}
                                        defaultValue={keyData?.keyword_camapign_info?.duration}
                                        onChange={(e) => handleInput(e, 1, index, "duration")}
                                    /> */}
                                    {formErrors[index]?.duration && (<div className='text-danger mt-2 pt-2'>{formErrors[index]?.duration}</div>)}
                                </div>
                                <div className='mt-4'>
                                    <Tooltip
                                        title={"View keyword details"}
                                        placement="top" arrow className="custom-tooltip-position cursor-pointer" >
                                        <i className="bi bi-eye-fill" onClick={() => onClickViewKeywordDetails(1, index, keyData)}></i>
                                    </Tooltip>
                                </div>
                            </div>
                            <hr />
                        </>
                        )
                    })}
                </div>}
            {portfolioId == 2 &&
                selectedGroupProdcutsData?.map((data: any, i: number) => {
                    // if (data.id === props.isShowData) {
                    return (
                        <div key={i}>
                            {<div className={`${data?.add_keywords?.length > 5 ? "campaign-settings-scroll" : ""}`}>
                                <div className='d-flex justify-content-between px-1' >
                                    <div className='w-20 me-2'>
                                        <h5 className="card-title fs-14 fw-700">Camapign name</h5>
                                        <div className="input-group">
                                            <span className="input-group-text campaign-drop-down-input border-dark fs-13" id="basic-addon1">adv_SPM</span>
                                            <input type="text" name="campaign_name" className="form-control campaign-drop-down-input border-dark border-start-0 fs-13 text-dark" placeholder="Campaign name" onChange={(e) => handleGroupInput(e, i, "Camapign_name")} onBlur={(e) => handleGroupInputBlur(e, i, "Camapign_name")}
                                                value={data?.keyword_camapign_info?.campaign_name.replace('adv_SPM_', '')}// defaultValue={keyData?.keyword_camapign_info?.campaign_name} 
                                            />
                                        </div>
                                        {formErrors[i]?.campaign_name && (<div className="text-danger mt-2 pt-2">{formErrors[i]?.campaign_name}</div>)}
                                    </div>
                                    <div className='me-2'>
                                        <h5 className="card-title fs-14 fw-700">Portfolio</h5>
                                        <div className="">
                                            <select name="portfolio_id" className='form-select campaign-drop-down-input border-dark fs-13' value={portfolio_uuid ? portfolio_uuid : data?.keyword_camapign_info?.portfolio_id} onChange={(e) => handleGroupInput(e, i, "portfolio_id")} >
                                                <option value={""}>Select portfolio</option>
                                                {portfolioData?.map((data: any, portfolioIndex: number) => (
                                                    <option className='' key={portfolioIndex} value={data?.value}>{data?.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='w-10 me-2'>
                                        <h5 className="card-title fs-14 fw-700">Daily budget</h5>
                                        <div className="input-group mb-3 my-2">
                                            <span className="input-group-text budget-input-group bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                            <input type="number" name="budget" defaultValue={data?.keyword_camapign_info?.budget} min={50} className="form-control budget-input-group border-dark border-start-0 fs-13"
                                                onChange={(e) => handleGroupInput(e, i, "budget")} />
                                        </div>
                                        {formErrors[i]?.budget && (<div className="text-danger ">{formErrors[i]?.budget}</div>)}
                                    </div>
                                    <div className="w-15 start-date-position me-2">
                                        <label className="form-label card-title fs-14 fw-700">Start date</label>
                                        <input
                                            type="date"
                                            name="start_date"
                                            className="form-control input border-dark fs-13"
                                            defaultValue={data?.keyword_camapign_info?.start_date}
                                            min={currentDate}
                                            onChange={(e) => handleGroupInput(e, i, "start_date")}
                                        />
                                        {formErrors[i]?.start_date && (<div className='text-danger mt-2 pt-2'>{formErrors[i]?.start_date}</div>)}
                                    </div>
                                    <div className="w-15 start-date-position me-2">
                                        <label className="form-label card-title fs-14 fw-700">End date</label>
                                        <input
                                            type="date"
                                            name="end_date"
                                            className="form-control input border-dark fs-13"
                                            min={data?.keyword_camapign_info?.start_date}
                                            defaultValue={data?.keyword_camapign_info?.end_date}
                                            onChange={(e) => handleGroupInput(e, i, "end_date")}
                                        />
                                    </div>
                                    <div className='w-10 start-date-position me-2'>
                                        <label className="form-label card-title fs-14 fw-700">Roas Benchmark</label>
                                        <input
                                            type="number"
                                            name="roas"
                                            placeholder='Roas Benchmark'
                                            className="form-control input border-dark fs-13"
                                            min={data?.keyword_camapign_info?.roas}
                                            defaultValue={data?.keyword_camapign_info?.roas}
                                            onChange={(e) => handleGroupInput(e, i, "roas")}
                                        />
                                        {formErrors[i]?.roas && (<div className='text-danger mt-2 pt-2'>{formErrors[i]?.roas}</div>)}
                                    </div>
                                    {/* <div className='w-10 start-date-position me-2'>
                                        <label className="form-label card-title fs-14 fw-700">Benchmark</label>
                                        <input
                                            type="number"
                                            name="benchmark"
                                            placeholder='Benchmark'
                                            className="form-control input border-dark fs-13"
                                            min={data?.keyword_camapign_info?.benchmark}
                                            defaultValue={data?.keyword_camapign_info?.benchmark}
                                            onChange={(e) => handleInput(e, 1, i, "benchmark")}
                                        />
                                        {formErrors[i]?.benchmark && (<div className='text-danger mt-2 pt-2'>{formErrors[i]?.benchmark}</div>)}
                                    </div> */}
                                    <div className='w-10 start-date-position me-2'>
                                        <label className="form-label card-title fs-14 fw-700">Qualifier</label>
                                        <input
                                            type="number"
                                            name="qualifier"
                                            placeholder='Qualifier'
                                            className="form-control input border-dark fs-13"
                                            min={data?.keyword_camapign_info?.qualifier}
                                            defaultValue={data?.keyword_camapign_info?.qualifier}
                                            onChange={(e) => handleInput(e, 1, i, "qualifier")}
                                        />
                                        {formErrors[i]?.qualifier && (<div className='text-danger mt-2 pt-2'>{formErrors[i]?.qualifier}</div>)}
                                    </div>
                                    <div className='w-10 start-date-position me-2'>
                                        <label className="form-label card-title fs-14 fw-700">Duration</label>
                                        <select name="duration" className="form-control input border-dark fs-13" value={data?.keyword_camapign_info?.duration} onChange={(e) => handleInput(e, 1, i, "duration")}>
                                            <option value={""}>Select duration</option>
                                            {durationsList?.map((data: any, portfolioIndex: number) => (
                                                <option className='' key={portfolioIndex} value={data?.value}>{data?.name}</option>
                                            ))}
                                        </select>
                                        {/* <input
                                        type="number"
                                        name="duration"
                                        placeholder='Duration'
                                        className="form-control input border-dark fs-13"
                                        min={keyData?.keyword_camapign_info?.duration}
                                        defaultValue={keyData?.keyword_camapign_info?.duration}
                                        onChange={(e) => handleInput(e, 1, index, "duration")}
                                    /> */}
                                        {formErrors[i]?.duration && (<div className='text-danger mt-2 pt-2'>{formErrors[i]?.duration}</div>)}
                                    </div>
                                    <div className='mt-4'>
                                        <Tooltip
                                            title={"View keyword details"}
                                            placement="top"
                                            arrow
                                            className="custom-tooltip-position cursor-pointer"
                                        >
                                            <i className="bi bi-eye-fill" onClick={() => onClickViewGroupKeywordDetails(i, data)}></i>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    )
                    // }
                })}
            {showKeywordDetails && (
                <div className={`modal modal-custom fade ${showKeywordDetails ? "show" : "false"}  ${showKeywordDetails ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowKeywordDetails(false)}></button>
                            </div>
                            <div className='modal-body'>
                                <div className='my-2'>
                                    <div className='text-start'>
                                        <div className="modal-title fw-bold">
                                            Keyword Details
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between ai-campaigns-settings px-2 py-1'>
                                        <div className='fs-13 my-2'>{keyWordModalDetails?.suggestionData}</div>
                                        <div className='fs-13 my-2'>{keyWordModalDetails?.bidData?.matchType}</div>
                                        <div className='fs-13 my-2'>₹{(keyWordModalDetails?.bidData?.bid_input_update_value ? keyWordModalDetails?.bidData?.bid_input_update_value : keyWordModalDetails?.bidData?.bid)}</div>
                                        <div className='w-30'>
                                            <div className="input-group my-1 fs-13 w-50">
                                                <span className="input-group-text budget-input-group-ai-campaigns bg-white border-dark px-1" >₹</span>
                                                <input type="number" name="keyword_targeting_bid" placeholder='0' className="form-control budget-input-group-ai-campaigns border-dark border-start-0 px-0"
                                                    defaultValue={(keyWordModalDetails?.bidData?.bid_input_update_value ? keyWordModalDetails?.bidData?.bid_input_update_value : keyWordModalDetails?.bidData?.bid)} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='product-details-container ai-campaign-product-tab px-3 py-2 rounded-2'>
                                    <div className='text-start fw-bold py-2'>Product Details</div>
                                    <div className='d-flex'>
                                        <div className='col-2'><img src={productModalDetails?.image_url ? productModalDetails?.image_url : NoProductImage} className='product-card-image' style={{ height: "100px", width: "100px", objectFit: "contain" }} /></div>
                                        <div>
                                            <div className='col-10 ai-products-text-color '>{productModalDetails?.title}</div>
                                            <div className='d-flex'>
                                                <div className='amount-info fs-11'><span className='me-1'>₹</span>{productModalDetails?.price_to_pay?.amount}</div>
                                                <div className='text-dark mx-1 d-flex align-items-center position-relative product-separation-line-position'>|</div>
                                                <div className='text-danger fs-11'>{productModalDetails?.availability?.replace(/_/g, " ")}</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div className='product-details fs-11 pe-1'>ASIN : {productModalDetails?.asin}</div>
                                                <div className='text-dark mx-1 d-flex align-items-center position-relative product-separation-line-position'>|</div>
                                                <div className='product-details fs-11 ps-1'>SKU : {productModalDetails?.sku}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer border-top d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setShowKeywordDetails(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showGroupKeywordDetails && (
                <div className={`modal modal-custom fade ${showGroupKeywordDetails ? "show" : "false"}  ${showGroupKeywordDetails ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-md modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-13">
                                    <span className={`cursor_pointer me-2 ${selectedTabStr === 'keyword' ? 'border-bottom-2' : ''}`} onClick={() => selectedTab('keyword')}>Keyword Details</span> <span className={`cursor_pointer ${selectedTabStr === 'product' ? 'border-bottom-2' : ''}`} onClick={() => selectedTab('product')}>Products Details</span>
                                </h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowGroupKeywordDetails(false)}></button>
                            </div>
                            {selectedTabStr === 'keyword' && <div className='modal-body'>
                                <div className='my-2'>
                                    <div >
                                        {groupKeyWordModalDetails?.map((keyword: any, keywordIndex: number) => (
                                            <div className='d-flex justify-content-between ai-campaigns-settings p-1' key={keywordIndex}>
                                                <div className='fs-13 my-2 w-20'>{keyword?.suggestionData}</div>
                                                <div className='fs-13 my-2 text-start'>{keyword?.bidData?.matchType}</div>
                                                <div className='fs-13 my-2 text-start w-15'>₹{(keyword?.bidData?.bid_input_update_value ? keyword?.bidData?.bid_input_update_value : keyword?.bidData?.bid)}</div>
                                                <div className='w-25'>
                                                    <div className="input-group my-1 fs-13">
                                                        <span className="input-group-text budget-input-group-ai-campaigns bg-white border-dark px-1" >₹</span>
                                                        <input type="number" name="keyword_targeting_bid" placeholder='0' className="form-control budget-input-group-ai-campaigns border-dark border-start-0 px-0"
                                                            defaultValue={(keyword?.bidData?.bid_input_update_value ? keyword?.bidData?.bid_input_update_value : keyword?.bidData?.bid)} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {groupKeyWordModalDetails?.length == 0 && (
                                            <div className='text-center'>No records found</div>
                                        )}
                                    </div>
                                </div>
                            </div>}
                            {selectedTabStr === 'product' && <div className='modal-body'>
                                {selectedGroupProducts.map((product: any, index: number) => {
                                    return (
                                        <>
                                            <button
                                                key={product.uuid}
                                                className={`nav-link fs-13 text-dark`}

                                            >
                                                <div className={`bg-transparent d-flex justify-content-between mx-1`}>
                                                    <div className={`row product-ai-tab`}>
                                                        <div className='col-2'>
                                                            <img src={product?.image_url ? product?.image_url : NoProductImage} className="product-card-image" alt={''} />
                                                        </div>
                                                        <div className='col-10 pb-3'>
                                                            <div className='product-header ai-products-text-color text-start' title={product?.title}>{product?.title?.length > 100 ? `${product?.title?.substring(0, 100)}...` : `${product?.title}`}</div>
                                                            <div className='d-flex'>
                                                                <div className='text-danger fs-11'><span className='text-dark'>₹</span>{product.price_to_pay?.amount}</div>
                                                                <div className='text-dark mx-1'>|</div>
                                                                <div className='text-danger fs-11'>{product?.availability?.replace(/_/g, " ")}
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='product-details fs-11'><span className=''>ASIN:</span>{product.asin}</div>
                                                                <div className='text-dark mx-1'>|</div>
                                                                <div className='product-details fs-11'>SKU:{product.sku}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </>
                                    )
                                })}
                            </div>}
                            <div className="modal-footer border-top d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setShowGroupKeywordDetails(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="">
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer,
        campaignBiddingCampainDetailsData: state.CampaignBiddingCampaignDetailsDataReducer
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        campaignBiddingCampainDetailsData: (data: any) => dispatch(CampaignBiddingCampaignDetailsData(data)),
    };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(AiCampaignDataProductsListCard);
export { connectedNavBar as AiCampaignDataProductsListCard };