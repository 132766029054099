import { CustomTableCol } from "../../../../../components/custom-table/types";

export const AdGroupHistoryDataGridCols: CustomTableCol[] = [
    {
        title: "Change Type",
        control: "change_type_name",
        sortable: false,
        canShowColumn: true,
        style: { width: "100px", left: 114, height: "40px" },
        isShowSwitch: false,
    },
    {
        title: "From",
        control: "from",
        sortable: false,
        canShowColumn: true,
        style: { width: "100px", left: 114, height: "40px" },
    },
    {
        title: "To",
        control: "to",
        sortable: false,
        canShowColumn: true,
        style: { width: "100px", left: 114, height: "40px" },
        isShowSwitch: false,
    },
    {
        title: "Date and time",
        control: "date_and_time",
        sortable: false,
        canShowColumn: true,
        style: { width: "100px", left: 114, height: "40px" },
        isShowSwitch: false,
    },
];
