import React, { useState } from 'react'
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../custom-tooltip/tooltipPage';

export const CampaignBiddingStrategyCard = () => {
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const tooltipContent = (tooltipTitle: string) => {
        if (tooltipTitle === "Adjust bids by placement") {
            return (
                <TooltipPage
                    tooltipMainContent={"Apply different bids by placement by entering a percentage increase to your default bid. These adjustments will apply on all bids in the campaign. Based on your bidding strategy, your bids can change further."}
                    tooltipMainContentCaption={"Learn more"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "Campaign bidding strategy") {
            return (
                <TooltipPage
                    tooltipMainContent={"Choose how you want to pay for clicks on your ads."}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        }
    }
    return (
        <div className="campaign-bidding-card-container card-width mx-auto pt-2">
            <div className="card text-bg-white ad-group-settings mb-3 my-3 mt-4">
                <div className="card-header bg-white">
                    <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">
                            Campaign bidding strategy
                            <Tooltip
                                title={tooltipContent("Campaign bidding strategy")}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer"
                            >
                                <span className="ms-1 cursor-pointer">
                                    <svg
                                        viewBox="0 0 512 512"
                                        width="12"
                                        height="12"
                                        className="fas fa-info-circle mb-2"
                                        data-fa-i2svg=""
                                    >
                                        <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                        ></path>
                                    </svg>
                                </span>
                            </Tooltip>
                        </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                            <span>
                                <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                            </span>
                            How to set your bid strategy
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="fs-14 mb-2">Dynamic bids - down only</div>
                    <div className="fs-13 fw-700">
                        Adjust bids by placement
                        <Tooltip
                            title={tooltipContent("Adjust bids by placement")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                        >
                            <span className="ms-1 cursor-pointer">
                                <svg
                                    viewBox="0 0 512 512"
                                    width="12"
                                    height="12"
                                    className="fas fa-info-circle"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="#8290a4"
                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                    ></path>
                                </svg>
                            </span>
                        </Tooltip>

                    </div>
                    <div className="col-7 px-0">
                        <div className="d-flex justify-content-between">
                            <div className="fs-14">Top of search (first page)</div>
                            <div className="fs-13 d-flex justify-content-start text-start">20%</div>
                            <div className="fs-13">Example: A ₹3.00 bid will be ₹3.60 for this placement.</div>
                        </div>
                        <div className="d-flex justify-content-between my-1">
                            <div className="fs-14">Product pages</div>
                            <div className="fs-13 d-flex justify-content-start text-start ms-5 ps-3">10%</div>
                            <div className="fs-13">Example: A ₹3.00 bid will be ₹3.30 for this placement.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
