import React, { useEffect, useState } from 'react'
import { DataTable } from '../../data-table'
import { KeywordTargetingTwoDataGridCols } from '../../../pages/Dashboard/campaign-type/keyword-targeting-data-grid-cols-two'
import { TooltipPage } from '../../custom-tooltip/tooltipPage';
import { Tooltip } from '@mui/material';
import { KeywordTargetingDataGridCols } from '../../../pages/Dashboard/campaign-type/keyword-targeting-data-grid-cols';
import { KeywordTargetingCards } from '../../campaign-creation-components/keyword-targeting-cards';
import DownloadIcon from "./../../../assets/images/svg_icons/download.svg";
import RupeeIcon from "./../../../assets/images/svg_icons/rupee.svg";
import { toast } from "react-toastify";
import { AiCampaignsTabsPage } from '../../campaign-creation/ai-campaigns-tabs-page';
import { GET_KEYWORD_SUGGESTIONS } from '../../../app/services/campaigns.service';
import { GroupsTabsPage } from './groups-tabs';
import { useParams } from 'react-router-dom';

interface Props {
  addedProdcuts?: any;
  addedProductsAsinData?: any;
  selectedProductAddedKeywordSuggestionData?: (data: any) => void;
  selectedUniqueProductsData?: (data: any) => void;
  aiFlowGroupaddedKeywordSuggestionData?: (data: any) => void;
  selectedProductKeywords?: (data: any) => void;
  aiFlowGroupsData?: any;
  isShowHandle?: any
  isShowData?: any;
  aiFlowAddedGroupdata?: any;
  triggerKeywordsStepValidation?: any;
  productsValidationStatus?: (data: boolean) => void;
  handleSelectedData?: (data: any) => void;
  finalStepKeywordTargetingData?: any;
  finalGroupData?: any;
  finalAiData?: any;
  bidChangeValue?: (data: any) => void;
  proceedWithAllProducts?: (data: any) => void;
  aiGroupFlowAddedKeywordsEnterListData?: (data: any) => void;
}

export const KeywordTargetingStepPage = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [keywordsloading, setKeywordsLoading] = useState<boolean>(false);
  const [selectedKeyWordTargetingBidValue, setSelectedKeywordTargetingBidValue] = useState<any>("Suggested bid");
  const [aiFlowAddedProductsData, setAiFlowAddedProductsData] = useState<any>(props?.addedProdcuts ? props?.addedProdcuts : [])
  const [selectedKeyWordTargetingSortingValue, setSelectedKeywordTargetingSortingValue] = useState<any>("Orders");
  const [keywordSuggestionsData, setKeywordSuggestionsData] = useState<any>();
  const [addedKeywordSuggestionData, setAddedKeywordSuggesstionData] = useState<any>([]);
  const [selectedTabAddedKeywordSuggestionData, setSelectedTabAddedKeywordSuggestionData] = useState<any>([]);
  const [aiFlowAddSuggestionData, setAiFlowAddSuggestionData] = useState<any>([]);
  const [selectedTabKeywordTargetingData, setSelectedTabKeywordTargetingData] = useState<any>([])
  const [selectedAiProductTabData, setSelectedAiProductTabData] = useState<boolean>(false);
  const [aiflowAddedKeywordData, setAiflowAddedKeywordData] = useState<any>([])
  const [tabSelectedData, setTabSelectedData] = useState<any>()
  const [selectedProductsKeywords, setSelectedProductsKeywords] = useState<any>([]);
  const [originalKeywordSuggestionsData, setOriginalKeywordSuggestionsData] = useState<any>();
  const [productsWithkeywordsData, setProductsWithkeywordsData] = useState<any>()
  const [selectedProduct, setSelectedProduct] = useState<any>([])
  const [selectedTabProduct, setSelectedTabProduct] = useState<any>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [keyowrdValidationStatus, setKeyowrdValidationStatus] = useState<boolean>(false)
  const [enterListBidValue, setEnterListBidValue] = useState<any>('')

  const [checkboxes, setCheckboxes] = useState<any>({
    Broad: true,
    Exact: true,
    Phrase: true,
  });
  const [externalListCheckboxes, setExternalListCheckboxes] = useState<any>({
    Broad: true,
    Exact: true,
    Phrase: true,
  });
  const [isProceedWithAllProducts, setisProceedWithAllProducts] = useState<any>(false);
  const [tooltipClose, setTooltipClose] = useState<boolean>(false);
  const [removeIndex, setRemoveIndex] = useState<any>();
  const userUuid = localStorage.getItem('user_uuid');
  const profileID = localStorage.getItem('profile_id');
  const [uniqueProductsArray, setUniqueProductsArray] = useState<any>([])
  let { portfolio_id } = useParams<{ portfolio_id: any }>();
  const [isGroupId, setIsGroupId] = useState<any>('');
  const [selectedAllGroupData, setSelectedAllGroupData] = useState<any>('');
  const [allkeywordTargeting, setAllkeywordTargeting] = useState<boolean>(false);
  const [customBidValue, setCustomBidValue] = useState<any>({
    custom_ai_bid_input: "",
    default_ai_bid_input: ""
  });
  const [groupUuid, setGroupUuid] = useState<any>('');
  const [isId, setId] = useState<any>('')
  const [productInfo, setProductInfo] = useState<any>('');
  const [showEnterListkeywordAiFlowBidModal, setShowEnterListkeywordAiFlowBidModal] = useState<any>(false);
  const [aiFlowEnterListKeywordData, setAiFlowEnterListKeywordData] = useState<any>({
    alternate_bid: ""
  })
  const [aiFlowEnterListKeywordTextData, setAiFlowEnterListKeywordTextData] = useState<any>({
    keyword_text: ""
  })
  const [aiFlowEnterListFormErrors, setAiFlowEnterListFormErrors] = useState<any>({});
  const [aiFlowAddKeywordAiternateBidInputData, setAiFlowAddKeywordAiternateBidInputDat] = useState<boolean>(false);
  const [aiFlowEnterListAlternateBidStatus, setAiFlowEnterListAlternateBidStatus] = useState<boolean>(false);
  const [keywordTextStatus, setKeywordTextStatus] = useState<boolean>(false);
  const [aiProductFlowEnterListKeywordData, setAiProductFlowEnterListKeywordData] = useState<any>([]);
  const [successMessage, setSuccessMessage] = useState(`3/3 keywords were added successfully.`);

  const portfolioId: any = portfolio_id;

  const keywordTargetingBidData = [
    {
      id: 1,
      name: "Suggested bid",
      sub_title: `Based on past bidding activity to predict bids that are more likely to win`,
      value: "Suggested bid",
    },
    {
      id: 2,
      name: "Custom bid",
      sub_title: `Can custom bids for each keyword without overriding your set bid amount`,
      value: "Custom bid",
    },
    {
      id: 3,
      name: "Default bid",
      sub_title: `Can raise or lower all your set amounts in bulk`,
      value: "Default bid",
    },
  ];

  const keywordTargetingSortData = [
    {
      id: 1,
      name: "Orders",
      value: "Orders",
    },
    {
      id: 2,
      name: "Clicks",
      value: "Clicks",
    },
  ];

  const tabs: any = [
    { uuid: '1', title: 'Product 1' },
    { uuid: '2', title: 'Product 2' },
    { userUuidid: '3', title: 'Product 3' },
  ];

  useEffect(() => {
    if (portfolioId == 1) {
      if (props?.triggerKeywordsStepValidation?.value) {
        toast.error("Please add atleast one keyword", { autoClose: 2000 })
      }
    }
    if (portfolioId == 2) {
      if (props?.triggerKeywordsStepValidation?.value) {
        for (let i = 0; i < props.finalGroupData?.length; i++) {
          for (let j = 0; j < props.finalGroupData[i].products_data?.length; j++) {
            const element = props.finalGroupData[i].products_data[j];
            for (let k = 0; k < props.finalGroupData[i].keywords_data?.length; k++) {
              const element_one = props.finalGroupData[i].keywords_data[k];
              if (element_one.product_uuid === element.uuid) {
                props.finalGroupData[i].dummyData = true
              }
              else {
                props.finalGroupData[i].dummyData = false
              }
            }
          }
        }
        const preparedData = props?.finalGroupData?.filter((el: any) => !el.dummyData);
        toast.error(`Please add atleast one keyword ${preparedData[0]?.name} `, { autoClose: 2000 })
      }
    }

  }, [props?.triggerKeywordsStepValidation]);

  useEffect(() => {
    if (props?.addedProdcuts?.length > 0) {
      if (props?.addedProductsAsinData) {
        setSelectedTabProduct([props?.addedProdcuts[0]]);
        setSelectedTabKeywordTargetingData(props?.addedProdcuts[0]);
        getUserKeyWordSuggestion(props?.addedProductsAsinData, selectedKeyWordTargetingSortingValue);
        console.log('props?.addedProdcuts[0]?.add_keywords 1', props?.addedProdcuts[0]?.add_keywords);

        if (props?.addedProdcuts[0]?.add_keywords) {
          setAddedKeywordSuggesstionData([...props?.addedProdcuts[0]?.add_keywords]);
        }
      }
    }
  }, [props?.addedProdcuts])


  useEffect(() => {
    if (props.aiFlowAddedGroupdata?.length > 0) {
      const groupdatas: any = props?.aiFlowAddedGroupdata
      for (let i = 0; i < groupdatas?.length; i++) {
        const asinData = [];
        setSelectedTabKeywordTargetingData(groupdatas[0]?.products_data[0])
        asinData.push(groupdatas[0]?.products_data[0]?.asin);
        setGroupUuid(groupdatas[i]?.products_data[0]?.uuid);
        getUserKeyWordSuggestion(asinData, selectedKeyWordTargetingSortingValue);
        console.log('props?.addedProdcuts[0]?.add_keywords 2', groupdatas[0]?.keywords_data);

        setAddedKeywordSuggesstionData(groupdatas[0]?.keywords_data)

        let filterData = groupdatas[0]?.keywords_data.filter((item: any) => {
          return item.product_uuid === groupdatas[0].products_data[0]?.uuid
        })
        setAddedKeywordSuggesstionData(filterData)
      }
    }
  }, [props.aiFlowAddedGroupdata])

  useEffect(() => {
    for (let i = 0; i < props?.aiFlowAddedGroupdata?.length; i++) {
      setId(props?.aiFlowAddedGroupdata[0].products_data[0].uuid);
    }
    if (props.proceedWithAllProducts) {
      props.proceedWithAllProducts(isProceedWithAllProducts);
    }
  }, [])

  const getUserKeyWordSuggestion = (asindata: any, selectedOption: any) => {
    const preparedData: any = {
      user_uuid: userUuid,
      profile_id: profileID,
      product_asin: asindata ? asindata : "",
      bidding_strategy: "LEGACY_FOR_SALES",
      sortDimension: selectedOption === "Orders" ? "CONVERSIONS" : "CLICKS"
    }
    setLoading(false);
    setKeywordsLoading(true);
    GET_KEYWORD_SUGGESTIONS(preparedData).then((res) => {
      if (res.data.status === "error" || res?.data?.code == 500) {
        toast.error(res.data.message);
        setLoading(false);
        setKeywordsLoading(false);
      } else {
        if (res) {
          const response = res.data.data;
          setOriginalKeywordSuggestionsData(response);
          setKeywordSuggestionsData(response);
          setLoading(false);
          setKeywordsLoading(false);
        }
      }
    });
  }

  const handleQuotationFileUpload = (e: any) => {
    const file = e.target.files[0];
  };

  const tooltipContent = (data: any) => {
    if (data === "Keyword targeting card header") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Your keywords (word combinations and phrases) are used to match your ads with search terms shoppers are using to find products."
          }
          tooltipMainContentCaption={"See keyword targeting best practices"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (data === "keyword suggested tab") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Related keywords are based on the products in your ads. You can add the keywords that you want and edit keywords and bids after you add them."
          }
          tooltipSubContent={"Learn about Suggested keywords"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (data === "keyword suggested tab Bid") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Your bid is the cost per click you are willing to pay when a customer clicks an ad from the ad group or campaign."
          }
          tooltipSubContent={"Learn about bids"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (data === "keyword suggested tab Filter by") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Match types allow you to fine-tune which customer search terms trigger your ads."
          }
          tooltipSubContent={"Learn about match types"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (data === "keyword suggested tab Sort By") {
      return (
        <TooltipPage
          sortOrders={"Number of orders the keyword has historically generated"}
          sortClicks={"Number of clicks the keyword has historically generated"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (data === "keyword enterlist tab Bid") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Your bid is the cost per click you are willing to pay when a customer clicks an ad from the ad group or campaign."
          }
          tooltipSubContent={"Learn about bids"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (data === "keyword enterlist tab Match type") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Match types allow you to fine-tune which customer search terms trigger your ads."
          }
          tooltipSubContent={"Learn about match types"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    }
  }
  const onCloseTooltip = (status: any) => {
    if (status) {
      setTooltipClose(true);
    }
  };

  const handleKeywordTargetingBidTextChange = (option: string) => {
    setSelectedKeywordTargetingBidValue(option);
    if (props?.bidChangeValue) {
      props?.bidChangeValue(option)
    }
    if (option === 'Custom bid') {
      const data = customBidValue;
      data.bid = 3
      setCustomBidValue(data)
    }
    if (option === 'Default bid') {
      const data = customBidValue;
      data.bid = '3'
      setCustomBidValue(data)
    }

  };

  const handleKeywordTargetingSortingChange = (option: string) => {
    getUserKeyWordSuggestion(props?.addedProductsAsinData, option)
    setSelectedKeywordTargetingSortingValue(option);
  };

  const noDataMessage = () => {
    return (
      <div className="d-flex flex-column justify-content-center my-1">
        <div className="fs-12 fw-bold">No related keywords</div>
        <div className="fs-12 ">Try entering your own keywords</div>
      </div>
    );
  };

  const handleKeyWordTargetingInput = (e: any, data: any, itemIndex: number) => {
    const { name, value } = e.target;
    // if (value > 0) {
    const keywordData = addedKeywordSuggestionData;
    keywordData[itemIndex].bid = value >= 0 ? value : '';
    console.log('props?.addedProdcuts[0]?.add_keywords 3', keywordData);

    setAddedKeywordSuggesstionData([...keywordData]);
    // }
  }

  const onClickCancelKeywordSuggestion = (e: any, addedKeywordData: any, i: number) => {
    setRemoveIndex(addedKeywordData);
    const addedSuggestionData = addedKeywordSuggestionData;
    addedSuggestionData?.splice(i, 1);
    console.log('props?.addedProdcuts[0]?.add_keywords 4', addedSuggestionData);

    setAddedKeywordSuggesstionData([...addedSuggestionData]);
    if (props?.aiFlowGroupaddedKeywordSuggestionData) {
      props?.aiFlowGroupaddedKeywordSuggestionData([...addedSuggestionData]);
    }
  }

  const removeAllAddedKeywordSuggestionAiData = () => {
    const data = addedKeywordSuggestionData
    setAddedKeywordSuggesstionData([]);
  }

  const getAddedKeyWordData = (data: any) => { }

  const getAiFlowAddedKeywordData = (data: any) => {
    const selectedProduct = selectedTabProduct;
    for (let i = 0; i < data?.length; i++) {
      if (data[data.length - 1].bidData.bid_type === "Custom bid") {
        data[data.length - 1].bid = data[data.length - 1].bid
        data[data.length - 1].bidData.bid_input_update_value = data[data.length - 1].bidData.bid_type === "Custom bid" ? customBidValue?.custom_ai_bid_input ? customBidValue?.custom_ai_bid_input : "3" : "";
      } else if (data[data.length - 1].bidData.bid_type === "Default bid") {
        data[data.length - 1].bid = data[data.length - 1].bid
        data[data.length - 1].bidData.bid_input_update_value = data[data.length - 1].bidData.bid_type === "Default bid" ? customBidValue?.default_ai_bid_input ? customBidValue?.default_ai_bid_input : "3" : "";
      } else if (data[i].bidData.bid_type === "Suggested bid") {
        data[i].bid = data[i].bid
        data[i].bidData.bid_input_update_value = data[i].bidData.bid ? (data[i].bidData.bid / 100).toFixed(2) : ''
      }
      data[i].bidData.bidInputValue = data[i].bidData.bid ? (data[i].bidData.bid / 100).toFixed(2) : '';
      data[i].product_uuid = selectedTabKeywordTargetingData?.uuid
      data[i].keyword_matchType = data[i]?.bidData?.matchType
      data[i].keyword_state = 'ENABLED'
      data[i].keywordText = data[i]?.suggestionData
    }
    setSelectedTabAddedKeywordSuggestionData([...data]);
    if (props?.selectedProductAddedKeywordSuggestionData) {
      props?.selectedProductAddedKeywordSuggestionData(data);
    }

    for (let i = 0; i < selectedProduct?.length; i++) {
      if (selectedProduct[i].uuid == data[0]?.product_uuid) {
        selectedProduct[i].add_keywords = data
      }
    }
    setSelectedTabProduct([...selectedProduct])
    setAiflowAddedKeywordData(data);
    console.log('props?.addedProdcuts[0]?.add_keywords 4', data);

    setAddedKeywordSuggesstionData(data);
  }
  const getGroupFlowAddedKeywordData = (data: any) => {

    for (let i = 0; i < data?.length; i++) {
      if (data[data?.length - 1].bidData.bid_type === "Custom bid") {
        data[data?.length - 1].bid = data[data?.length - 1].bidData.bid_type === "Custom bid" ? customBidValue?.custom_ai_bid_input ? customBidValue?.custom_ai_bid_input : "3" : "";
      } else if (data[data?.length - 1].bidData.bid_type === "Default bid") {
        data[data?.length - 1].bid = data[data?.length - 1].bidData.bid_type === "Default bid" ? customBidValue?.default_ai_bid_input ? customBidValue?.default_ai_bid_input : "3" : "";
      } else if (data[i].bidData.bid_type === "Suggested bid") {
        data[i].bid = data[i].bidData.bid ? (data[i].bidData.bid / 100).toFixed(2) : ''
      }
    }

    let allKeywords: any = selectedTabAddedKeywordSuggestionData;
    allKeywords = allKeywords.filter((element: any) => element?.product_uuid !== data[0]?.product_uuid);

    const groupdatas: any = props?.aiFlowAddedGroupdata;

    for (let index = 0; index < groupdatas?.length; index++) {
      if (isGroupId === groupdatas[index].id) {
        if (groupdatas[index].keywords_data?.length > 0) {
          allKeywords = [...allKeywords, ...data];
        } else {
          allKeywords = [...data];
        }
        setSelectedTabAddedKeywordSuggestionData(allKeywords);
        if (props?.aiFlowGroupaddedKeywordSuggestionData) {
          props?.aiFlowGroupaddedKeywordSuggestionData(allKeywords)
        }
      }
    }
  }

  const getSelectedTabAiFlowKeywordTargetingData = (data: any) => {
    const checkedData = checkboxes;
    checkedData.Broad = true;
    checkedData.Exact = true;
    checkedData.Phrase = true;
    setCheckboxes({ ...checkedData })
    setSelectedAiProductTabData(true);
    setSelectedTabKeywordTargetingData(data);
    if (data) {
      console.log('data', data);

      const asinData = [];
      asinData.push(data?.asin);
      setAddedKeywordSuggesstionData([]);
      if (selectedTabProduct?.length > 0) {
        const filterData = selectedTabProduct?.filter((el: any) => el?.uuid === data?.uuid);
        console.log('props?.addedProdcuts[0]?.add_keywords 6', selectedTabProduct, filterData[0]?.add_keywords);

        setAddedKeywordSuggesstionData(filterData[0]?.add_keywords);
        getUserKeyWordSuggestion(asinData, selectedKeyWordTargetingSortingValue);
        if (filterData?.length === 0) {
          selectedTabProduct.push(data)
          setSelectedTabProduct(selectedTabProduct);
        }
      } else {
        console.log('data', data);
        
        selectedTabProduct.push(data[0])
        getUserKeyWordSuggestion(asinData, selectedKeyWordTargetingSortingValue);
        setSelectedTabProduct(selectedTabProduct);
      }
      if (props?.selectedProductKeywords) {        
        props?.selectedProductKeywords(selectedTabProduct);
      }
    }
  }

  const getSelectedGroupData = (data: any) => {
    const checkedData = checkboxes;
    checkedData.Broad = true;
    checkedData.Exact = true;
    checkedData.Phrase = true;
    setCheckboxes({ ...checkedData })
    setSelectedAllGroupData({ ...data })
    setIsGroupId(data?.groups_data?.id)
    setSelectedTabKeywordTargetingData(data.product);
    const asinData = [];
    asinData.push(data.product.asin);
    getUserKeyWordSuggestion(asinData, selectedKeyWordTargetingSortingValue);
    if (portfolioId == 1) {
      console.log('props', props?.addedProdcuts, data);


    } else {
      const groupdatas: any = props.aiFlowAddedGroupdata;
      for (let index = 0; index < groupdatas?.length; index++) {
        if (data.groups_data.id === groupdatas[index].id) {
          let filterData = groupdatas[index].keywords_data.filter((item: any) => {
            return item.product_uuid === data?.product?.uuid;
          })
          console.log('props?.addedProdcuts[0]?.add_keywords 12', groupdatas, data, filterData);

          setAddedKeywordSuggesstionData(filterData)
        }
      }
    }
  }

  const handleCheckboxChange = (checkboxName: any) => {
    let checkesData: any = []
    setCheckboxes((prevCheckboxes: any) => {
      const updatedCheckboxes = {
        ...prevCheckboxes,
        [checkboxName]: !prevCheckboxes[checkboxName],
      };
      checkesData = Object.keys(updatedCheckboxes).filter(key => updatedCheckboxes[key]);
      let newChecksData = checkesData.map((str: any) => str.toUpperCase());
      const updatedData = originalKeywordSuggestionsData?.map((keywordData: any) => {
        const updatedBidInfo = keywordData.bidInfo.filter((bid: any) => newChecksData.includes(bid.matchType));
        if (updatedBidInfo?.length > 0) {
          return {
            ...keywordData,
            bidInfo: updatedBidInfo,
          };
        }
      });
      setKeywordsLoading(true);
      setTimeout(() => {
        setKeywordSuggestionsData(checkesData?.length > 0 ? updatedData : [])
        setKeywordsLoading(false);
      }, 1);
      return updatedCheckboxes;
    });
  };

  const getSelectedTabAddedKeywordSuggestionData = (data: any) => {
  }

  const getByDefaultData = (data: any) => {
    props.isShowHandle(data);
  }

  const handleGroupTab = (data: any) => {
    if (data?.id === props.isShowData) {
      return data.products_data.map((product: any, index: number) => {
        return preparedData(product.uuid, data, product)
      })
    }
  }

  const preparedData = (puuid: any, data: any, product: any) => {
    if (props?.isShowData === data?.id) {
      setSelectedTabKeywordTargetingData(product);
      const asinData = [];
      setIsGroupId(data?.id)
      asinData.push(product.asin);
      getUserKeyWordSuggestion(asinData, selectedKeyWordTargetingSortingValue);
      console.log('props?.addedProdcuts[0]?.add_keywords 7', data.keywords_data);

      setAddedKeywordSuggesstionData(data.keywords_data)
    }
    else {
      setAddedKeywordSuggesstionData([])
    }
  }

  const getAddAllDataStatus = (status: any) => {
    const preparedData = [];
    if (status) {
      for (let i = 0; i < originalKeywordSuggestionsData?.length; i++) {
        for (let j = 0; j < originalKeywordSuggestionsData[i].bidInfo?.length; j++) {
          preparedData.push({
            bid: originalKeywordSuggestionsData[i].bidInfo[j].bid ? (originalKeywordSuggestionsData[i].bidInfo[j].bid / 100).toFixed(2) : '',
            bidData: {
              bid: originalKeywordSuggestionsData[i].bidInfo[j].bid,
              bidInputValue: originalKeywordSuggestionsData[i].bidInfo[j].bid ? (originalKeywordSuggestionsData[i].bidInfo[j].bid / 100).toFixed(2) : '',
              btn_status: true,
              matchType: originalKeywordSuggestionsData[i].bidInfo[j].matchType,
              rank: originalKeywordSuggestionsData[i].bidInfo[j].rank,
              suggestedBid: originalKeywordSuggestionsData[i].bidInfo[j].suggestedBid,
              theme: originalKeywordSuggestionsData[i].bidInfo[j].theme,
            },
            keywordText: originalKeywordSuggestionsData[i].keyword,
            keyword_matchType: originalKeywordSuggestionsData[i].bidInfo[j].matchType,
            keyword_state: "ENABLED",
            product_uuid: selectedTabKeywordTargetingData?.uuid,
            recId: originalKeywordSuggestionsData[i].recId,
            suggestionData: originalKeywordSuggestionsData[i].keyword
          })
        }
      }
      console.log('props?.addedProdcuts[0]?.add_keywords 11', preparedData);

      setAddedKeywordSuggesstionData([...preparedData]);
      setAllkeywordTargeting(true);
    }
  }

  const onChangeBidInput = (e: any) => {
    const { name, value } = e.target;
    setCustomBidValue((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validateSpecificFeild(name, value),
    }));
  }

  const validateSpecificFeild = (name: any, value: any) => {
    if (name === "custom_ai_bid_input") {
      if (!value) {
        return "Please enter custom bid value"
      } else if (value < 0) {
        return "Custom bid should be minimum 1."
      }
    }

    if (name === "default_ai_bid_input") {
      if (!value) {
        return "Please enter default bid value"
      } else if (value < 0) {
        return "Default bid should be minimum 1."
      }
    }
    return ""
  }

  const groupHandleClick = (data: any) => {
    setId(data)
  }

  const groupTabHandleClickData = (data: any) => {
    setProductInfo(data);
  }

  const handleAiFlowEnterListKeywordText = (e: any) => {
    setAiFlowEnterListKeywordTextData(false);
    const { name, value } = e.target;
    setAiFlowEnterListKeywordTextData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const data = e.target.value;
    if (data?.trim()?.length > 0) {
      setKeywordTextStatus(true);
    } else {
      setKeywordTextStatus(false);
    }
  }

  const onEnterlistkeywordAiProductFlow = () => {
    setEnterListBidValue('');
    setShowEnterListkeywordAiFlowBidModal(true);
  }

  const handleAiProductFlowAlternateInput = (e: any) => {
    const { name, value } = e.target;
    setAiFlowEnterListKeywordData((prevData: any) => ({
      ...prevData,
      [name]: value >= 0 ? value : '',
    }));
    const data = value >= 0 ? value : '';
    if (data === "") {
      setAiFlowAddKeywordAiternateBidInputDat(false);
    } else {
      setAiFlowAddKeywordAiternateBidInputDat(true);
    }
    setEnterListBidValue(data);
    setAiFlowEnterListFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validateEnterListSpecificFeild(name, value >= 0 ? value : ''),
    }));
  }

  const validateEnterListSpecificFeild = (name: any, value: any) => {
    if (name === "alternate_bid") {
      if (!value) {
        return "Please enter bid value"
      } else if (value <= 0) {
        return "Bid value should be minimum 1."
      }
    }
    return "";
  }

  const validateAIFlowBidInputForm = () => {
    const errors: any = {};
    if (!aiFlowEnterListKeywordData?.alternate_bid) {
      errors.alternate_bid = "Please enter bid";
    }
    setAiFlowEnterListFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onAddAiProductAlternateEnterListBid = () => {
    const isValid = validateAIFlowBidInputForm();
    if (isValid) {
      setAiFlowEnterListAlternateBidStatus(true);
      console.log('props', props, addedKeywordSuggestionData, selectedAllGroupData?.groups_data?.keywords_data);
      const existingAddedKeywordSuggesstionData: any = portfolioId == 1 ? addedKeywordSuggestionData || [] : selectedAllGroupData?.groups_data?.keywords_data || [];//addedKeywordSuggestionData;
      const splitData = aiFlowEnterListKeywordTextData?.keyword_text.split('\n').filter((line: any) => line.trim() !== '');

      setSelectedKeywordTargetingBidValue('AlternateBid');
      for (const property in externalListCheckboxes) {
        if (externalListCheckboxes[property]) {
          splitData.forEach((element: any) => {
            existingAddedKeywordSuggesstionData.push({
              bidData: {
                matchType: property?.toUpperCase(),
                bid: aiFlowEnterListKeywordData?.alternate_bid,
              },
              bid: aiFlowEnterListKeywordData?.alternate_bid,
              keyword_matchType: property?.toUpperCase(),
              keywordText: element,//aiFlowEnterListKeywordTextData?.keyword_text,
              keyword_state: "ENABLED",
              suggestionData: element,//aiFlowEnterListKeywordTextData?.keyword_text,
              product_uuid: selectedAllGroupData?.product?.uuid,
            })
          })
        }
      }
      setShowEnterListkeywordAiFlowBidModal(false);
      const filterKeyWords = existingAddedKeywordSuggesstionData.filter((data: any) => data?.product_uuid === selectedAllGroupData?.product?.uuid);
      console.log('props?.addedProdcuts[0]?.add_keywords 8', selectedAllGroupData, props?.finalGroupData, existingAddedKeywordSuggesstionData, filterKeyWords);
      // if (portfolioId == 1) {
      //   setAddedKeywordSuggesstionData([...existingAddedKeywordSuggesstionData])
      // } else {
      setAddedKeywordSuggesstionData([...filterKeyWords])
      // }
      if (props?.aiGroupFlowAddedKeywordsEnterListData) {
        props?.aiGroupFlowAddedKeywordsEnterListData([...existingAddedKeywordSuggesstionData])
      }
      const checkedLength = Object.values(externalListCheckboxes).filter(value => value === true).length;

      setSuccessMessage(`${checkedLength}/3 keywords were added successfully.`);
      setAiFlowEnterListKeywordTextData({ ...aiFlowEnterListKeywordTextData, keyword_text: "" })
      if (portfolioId == 1) {
        const selectedProduct = selectedTabProduct;
        for (let i = 0; i < selectedProduct?.length; i++) {
          if (selectedAllGroupData?.product?.uuid === selectedProduct[i].uuid) {
            selectedProduct[i].add_keywords = existingAddedKeywordSuggesstionData;
          }
        }
        setSelectedTabProduct([...selectedProduct])
        setAiflowAddedKeywordData([...existingAddedKeywordSuggesstionData])
      }
      if (portfolioId == 2) {
        const groupsData = props?.aiFlowAddedGroupdata;
        let allKeywords: any = [];
        for (let index = 0; index < groupsData?.length; index++) {
          if (isGroupId === groupsData[index].id) {
            if (groupsData[index].keywords_data?.length > 0) {
              allKeywords = [...allKeywords, ...existingAddedKeywordSuggesstionData];
            } else {
              allKeywords = [...existingAddedKeywordSuggesstionData];
            }
            setSelectedTabAddedKeywordSuggestionData(allKeywords);
            if (props?.aiFlowGroupaddedKeywordSuggestionData) {
              props?.aiFlowGroupaddedKeywordSuggestionData(allKeywords)
            }
          }
        }
      }
    }
  }
  const handleEnterlistCheckBoxChange = (e: any) => {
    const data = externalListCheckboxes;
    data[e.target.name] = e.target.checked;
    const checkedLength = Object.values(data).filter(value => value === true).length;

    setSuccessMessage(`${checkedLength}/3 keywords were added successfully.`);
    setExternalListCheckboxes({ ...data });
  }

  const onProceedWithAllProducts = (e: any) => {
    setisProceedWithAllProducts(e.target.checked)
    if (props.proceedWithAllProducts) {
      props.proceedWithAllProducts(e.target.checked);
    }
  }

  const onSelectKeywordType = () => {
    setSelectedKeywordTargetingBidValue("Suggested bid");
  }
  return (
    <div>
      <div className="card-width mx-auto px-0">
        {portfolioId == 2 && (
          <div className="mx-1 pe-5">
            <h5 className='ms-3'>Groups</h5>
            <div className='my-2'> <GroupsTabsPage data={props.aiFlowAddedGroupdata}
              isShowHandle={(data: any) => props.isShowHandle(data)}
              byDefaultTabData={getByDefaultData}
              isShowData={props.isShowData}
              getSelectedGroupData={getSelectedGroupData}
              groupHandleClick={(e: any) => groupHandleClick(e)}
              groupTabHandleClickData={(e: any) => groupTabHandleClickData(e)}
            /></div>
          </div>
        )}
        <div className="mx-1 pe-5">
          <h5 className='ms-3'>Products</h5>
          <AiCampaignsTabsPage cardDataShow={true}
            aiCampaignsProductsData={props?.addedProdcuts}
            aiFlowKeywordSelectedTabTargetingData={getSelectedTabAiFlowKeywordTargetingData}
            isShowData={props.isShowData}
            aiFlowAddedGroupdata={props.aiFlowAddedGroupdata}
            addedKeywordSuggestionData={addedKeywordSuggestionData}
            getSelectedGroupData={getSelectedGroupData}
            byDefaultTabData={getByDefaultData}
            isId={isId}
            productInfo={productInfo}
          />
        </div>
      </div>
      {portfolioId == 1 && (
        <div className="keyword-targeting-card-container card-width mx-auto my-0 text-end">
          <hr />
          <label className="me-2">
            <input
              type="checkbox"
              className="me-2 cursor-pointer"
              onChange={(e) => onProceedWithAllProducts(e)}
              checked={isProceedWithAllProducts}
            />
            Use selected keywords for all products
          </label>
        </div>
      )}
      <div className="keyword-targeting-card-container card-width mx-auto my-0">
        <div className="card text-bg-white ad-group-settings my-3">
          <div className="card-header bg-white">
            <div className="d-flex justify-content-between py-2">
              <div className="fs-24 fw-light">
                Keyword targeting
                <Tooltip
                  title={tooltipContent(
                    "Keyword targeting card header"
                  )}
                  placement="top"
                  arrow
                  className="custom-tooltip-position cursor-pointer"
                >
                  <span className="ms-1">
                    <svg
                      viewBox="0 0 512 512"
                      width="12"
                      height="12"
                      className="fas fa-info-circle"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="#8290a4"
                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                      ></path>
                    </svg>
                  </span>
                </Tooltip>
              </div>
              <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                <span>
                  <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                </span>
                How to choose keywords for targeting
              </div>
            </div>
          </div>
          <div className="card-body py-0">
            <div className="row text-center px-0">
              <div className="col-6 bg-white products-tabs-section border-right py-0">
                <div className="tabs-section px-0">
                  <div className="nav nav-underline" id="nav-tab" role="tablist">
                    <button className="nav-link text-dark active fs-13" id="nav-suggested"
                      data-bs-toggle="tab" data-bs-target="#nav-suggest"
                      type="button" role="tab" aria-controls="nav-suggest"
                      aria-selected="true"
                      onClick={onSelectKeywordType}
                    >
                      Suggested
                      <Tooltip title={tooltipContent("keyword suggested tab")}
                        placement="top" arrow className="custom-tooltip-position cursor-pointer"
                      >
                        <span className="ms-1">
                          <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="">
                            <path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                            ></path>
                          </svg>
                        </span>
                      </Tooltip>
                    </button>
                    <button className="nav-link text-dark fs-13" id="nav-enter-list" data-bs-toggle="tab" data-bs-target="#nav-enter-list-keyword"
                      type="button" role="tab" aria-controls="nav-enter-list-keyword" aria-selected="false"
                    >
                      Enter list
                    </button>
                  </div>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active suggested-tab"
                    id="nav-suggest"
                    role="tabpanel"
                    aria-labelledby="nav-suggested"
                    tab-index="0"
                  >
                    <div className="row border-top border-bottom">
                      <div className="col-12 px-0">
                        <div className="d-flex justify-content-start m-2 px-1">
                          <div className="fs-12 fw-bold my-1">
                            Bid
                            <Tooltip
                              title={tooltipContent(
                                "keyword suggested tab Bid"
                              )}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer"
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </div>
                          <div className="fs-13 ms-5 ps-4">
                            <div className="btn-group">
                              <button
                                className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedKeyWordTargetingBidValue}{" "}
                                <span>
                                  <svg
                                    viewBox="0 0 389 512"
                                    width="13"
                                    height="13"
                                    className="fas fa-angle-down show-text-icon"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                <li className="fs-13 py-1">
                                  {keywordTargetingBidData?.map(
                                    (item: any, targetingBidIndex: number) => (
                                      <button
                                        key={targetingBidIndex}
                                        className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingBidValue ===
                                          item?.value
                                          ? "active"
                                          : ""
                                          }`}
                                        value={item.value}
                                        onClick={() =>
                                          handleKeywordTargetingBidTextChange(
                                            item?.value
                                          )
                                        }
                                      >
                                        <div className="fw-400">
                                          {item.name}
                                        </div>
                                        <div className="dropdown-sub-title-text-color">
                                          {item.sub_title}
                                        </div>
                                      </button>
                                    )
                                  )}
                                </li>
                              </ul>
                              {selectedKeyWordTargetingBidValue === "Custom bid" && (
                                <div className="ms-2">
                                  <div className="input-group  table-input-field-width">
                                    <span className="input-group-text custom-bid-input-height bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                    <input
                                      type="text"
                                      name="custom_ai_bid_input"
                                      className="form-control custom-bid-input-height drop-down-input border-dark border-start-0 px-0"
                                      defaultValue={customBidValue?.custom_ai_bid_input ? customBidValue?.custom_ai_bid_input : "3"} placeholder="3.00"
                                      onChange={(e: any) => onChangeBidInput(e)}
                                    />
                                    {formErrors?.custom_ai_bid_input && (<div className='text-danger'>{formErrors?.custom_ai_bid_input}</div>)}
                                  </div>
                                </div>
                              )}
                              {selectedKeyWordTargetingBidValue === "Default bid" && (
                                <div className="ms-2">
                                  <div className="input-group  table-input-field-width">
                                    <span className="input-group-text custom-bid-input-height bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                    <input
                                      type="text"
                                      name="default_ai_bid_input"
                                      className="form-control custom-bid-input-height drop-down-input border-dark border-start-0 px-0"
                                      defaultValue={customBidValue?.default_ai_bid_input ? customBidValue?.default_ai_bid_input : "3"} placeholder="3.00"
                                      onChange={(e: any) => onChangeBidInput(e)}
                                    />
                                    {formErrors?.default_ai_bid_input && (<div className='text-danger'>{formErrors?.default_ai_bid_input}</div>)}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex m-2 px-1 my-3">
                          <div className="fs-12 fw-bold">
                            Filter by
                            <Tooltip
                              title={tooltipContent(
                                "keyword suggested tab Filter by"
                              )}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer"
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </div>
                          <div className="fs-13 ms-5">
                            <div className="d-flex">
                              <div>
                                <label className="me-2">
                                  <input
                                    type="checkbox"
                                    className="me-2 cursor-pointer"
                                    onChange={() => handleCheckboxChange('Broad')}
                                    checked={checkboxes.Broad}
                                  />
                                  Broad {checkboxes.Broad}
                                </label>
                              </div>
                              <div>
                                <label className="me-2">
                                  <input
                                    type="checkbox"
                                    className="me-2 cursor-pointer"
                                    onChange={() => handleCheckboxChange('Phrase')}
                                    checked={checkboxes.Phrase}
                                  />
                                  Phrase {checkboxes.Phrase}
                                </label>
                              </div>
                              <br />
                              <div>
                                <label className="me-1">
                                  <input
                                    type="checkbox"
                                    className="me-2 cursor-pointer"
                                    onChange={() => handleCheckboxChange('Exact')}
                                    checked={checkboxes.Exact}
                                  />
                                  Exact{checkboxes.Exact}
                                </label>
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-start m-2 px-1">
                          <div className="fs-12 fw-bold my-1">
                            Sort By
                            <Tooltip
                              title={tooltipContent(
                                "keyword suggested tab Sort By"
                              )}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer"
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </div>
                          <div className="fs-13 ms-5">
                            <div className="btn-group">
                              <button
                                className="btn btn-secondary btn-sm new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedKeyWordTargetingSortingValue}{" "}
                                <span>
                                  <svg
                                    viewBox="0 0 389 512"
                                    width="13"
                                    height="13"
                                    className="fas fa-angle-down show-text-icon"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="dropdown-menu newest-first-dropdown keyword-targeting-sorting-dropdown">
                                <li className="fs-13 py-1">
                                  {keywordTargetingSortData?.map(
                                    (item: any, targetingSortIndex: number) => (
                                      <button
                                        key={targetingSortIndex}
                                        className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingSortingValue ===
                                          item?.value
                                          ? "active"
                                          : ""
                                          }`}
                                        value={item.value}
                                        onClick={() =>
                                          handleKeywordTargetingSortingChange(
                                            item?.value
                                          )
                                        }
                                      >
                                        {item.name}
                                      </button>
                                    )
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-0" style={{ height: '330px', overflowY: "auto", overflowX: "hidden", width: "102.5%" }}>
                      <DataTable
                        tableData={keywordSuggestionsData ? keywordSuggestionsData : []}
                        TableCols={KeywordTargetingDataGridCols}
                        paginationStatus={true}
                        noDataFoundTitle={"No related keywords"}
                        noDataFoundSubTitle={"Try entering your own keywords"}
                        noDataFoundMessage={noDataMessage}
                        addAllStatus={getAddAllDataStatus}
                      />
                      {keywordsloading &&
                        <div className="text-center p-5">
                          <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      }
                      {!keywordsloading &&
                        <KeywordTargetingCards
                          keywordTargetingSuggestionData={keywordSuggestionsData ? keywordSuggestionsData : []}
                          selectedProductTabData={selectedTabKeywordTargetingData}
                          addedKeywordSuggestionData={getAddedKeyWordData}
                          canceledData={removeIndex}
                          selectedBidValue={selectedKeyWordTargetingBidValue}
                          addedAiFlowKeywordSugestionData={getAiFlowAddedKeywordData}
                          selectedTabAddedKeywordSuggestionData={getSelectedTabAddedKeywordSuggestionData}
                          addedGroupFlowKeywordSugestionData={getGroupFlowAddedKeywordData}
                          selectedAllGroupData={selectedAllGroupData}
                          addAllKeyWordTargeting={allkeywordTargeting}
                          filterRemovedData={addedKeywordSuggestionData}
                          customBidValue={customBidValue}
                          addedKeywordSuggestionDataObj={addedKeywordSuggestionData}
                        />
                      }
                    </div>
                  </div>
                  <div
                    className="tab-pane fade enterlist-tab"
                    id="nav-enter-list-keyword"
                    role="tabpanel"
                    aria-labelledby="nav-enter-list"
                    tab-index="0"
                  >
                    <div className="row border-top">
                      <div className="d-flex justify-content-start m-2 px-1 my-2">
                        <div className="fs-12 fw-bold ">
                          Bid
                          <Tooltip
                            title={tooltipContent(
                              "keyword enterlist tab Bid"
                            )}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="fs-13 ms-5 ps-4">
                          <div className="btn-group">
                            <button
                              className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedKeyWordTargetingBidValue}{" "}
                              <span>
                                <svg
                                  viewBox="0 0 389 512"
                                  width="13"
                                  height="13"
                                  className="fas fa-angle-down show-text-icon"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="currentColor"
                                    d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                  ></path>
                                </svg>
                              </span>
                            </button>
                            <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                              <li className="fs-13 py-1">
                                {keywordTargetingBidData?.map(
                                  (item: any, leywordTargetingBidIndex: number) => (
                                    <button
                                      key={leywordTargetingBidIndex}
                                      className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingBidValue ===
                                        item?.value
                                        ? "active"
                                        : ""
                                        }`}
                                      value={item.value}
                                      onClick={() =>
                                        handleKeywordTargetingBidTextChange(
                                          item?.value
                                        )
                                      }
                                    >
                                      <div className="fw-400">
                                        {item.name}
                                      </div>
                                      <div className="dropdown-sub-title-text-color">
                                        {item.sub_title}
                                      </div>
                                    </button>
                                  )
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex m-2 px-1 my-2">
                        <div className="fs-12 fw-bold">
                          Match type
                          <Tooltip
                            title={tooltipContent(
                              "keyword enterlist tab Match type"
                            )}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="fs-13 ms-5">
                          <div className="d-flex">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name='Broad'
                                id="flexCheckDefaultBroadThree"
                                checked={externalListCheckboxes?.Broad}
                                onChange={(e: any) => handleEnterlistCheckBoxChange(e)}
                              />
                              <label
                                className="form-check-label fs-12"
                                htmlFor="flexCheckDefaultBroadThree"
                              >
                                Broad
                              </label>
                            </div>
                            <div className="form-check mx-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name='Phrase'
                                onChange={(e: any) => handleEnterlistCheckBoxChange(e)}
                                id="flexCheckDefaultPhraseFour"
                                checked={externalListCheckboxes?.Phrase}
                              />
                              <label
                                className="form-check-label fs-12"
                                htmlFor="flexCheckDefaultPhraseFour"
                              >
                                Phrase
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name='Exact'
                                onChange={(e: any) => handleEnterlistCheckBoxChange(e)}
                                id="flexCheckDefaultExactSix"
                                checked={externalListCheckboxes?.Exact}
                              />
                              <label
                                className="form-check-label fs-12"
                                htmlFor="flexCheckDefaultExactSix"
                              >
                                Exact
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-2">
                        <textarea
                          name="keyword_text"
                          rows={6}
                          className="form-control fs-14 border-dark"
                          placeholder="Enter keywords separated by new line."
                          onChange={handleAiFlowEnterListKeywordText}
                          value={aiFlowEnterListKeywordTextData?.keyword_text}
                        ></textarea>
                      </div>
                      <div className='text-start ps-1 ms-1'>
                        {successMessage && (
                          <span className='fs-13'>
                            <span><i className="bi bi-check text-success fs-21"></i></span><span className="text-success ml-2 fs-13">{successMessage}</span>
                          </span>
                        )}
                      </div>
                      <div className="text-end">
                        <button className={`btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13  ${keywordTextStatus ? "cursor-pointer" : "cursor-pointer-not-allowed"}`}
                          onClick={onEnterlistkeywordAiProductFlow}>
                          Add keywords
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade upload-tab"
                    id="nav-upload-keyword"
                    role="tabpanel"
                    aria-labelledby="nav-upload"
                    tab-index="0"
                  >
                    <div className="row border-top">
                      <div className="mt-3 fs-13 text-start">
                        Use the template to make sure your information
                        uploads accurately.
                      </div>
                      <div className="text-start mb-3">
                        <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                          <span className="me-2">
                            <img src={DownloadIcon} alt="" />
                          </span>
                          Download the XLSX template
                        </button>
                      </div>
                      <div className="upload-files">
                        <div
                          data-takt-id="ups_upload_asins_input"
                          className="upload-icon fs-13"
                        >
                          <div className="mt-4 pt-2">
                            <label
                              htmlFor="input_file_three"
                              className="cursor-pointer"
                            >
                              <div className="">
                                <div>
                                  <i className="bi bi-upload"></i>
                                </div>
                                <div className="fs-15 my-1">
                                  Drag and drop file to add
                                </div>
                                <div className="fs-13 my-1">
                                  or click to select from your computer
                                </div>
                                <div className="fs-13 my-3">
                                  Accepted formats: CSV, TSV, XLSX
                                </div>
                              </div>
                            </label>
                            <input
                              type="file"
                              id="input_file_three"
                              name="file"
                              className="d-none "
                              onChange={handleQuotationFileUpload}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 product-preview-container px-0">
                <div className="d-flex justify-content-between border-bottom py-2 px-3 px-0">
                  <div className="fs-13 fw-bold ">{addedKeywordSuggestionData?.length} added</div>
                  <div className={`fs-13 ${addedKeywordSuggestionData?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : " cursor-pointer-not-allowed remove-all-text"}`}
                    onClick={removeAllAddedKeywordSuggestionAiData}>
                    Remove all
                  </div>
                </div>
                <div className="px-3">
                  <DataTable
                    TableCols={KeywordTargetingTwoDataGridCols}
                    keywordTargetTwo={true}
                  />
                  <div className="addedkeyword-scroll">
                    {addedKeywordSuggestionData?.length > 0 && addedKeywordSuggestionData?.map((addedKeywordData: any, ind: number) => (
                      <div key={ind} className="border-bottom">
                        <div className="d-flex justify-content-between">
                          <div className="fs-11 text-wrap w-40 text-start">
                            {addedKeywordData?.suggestionData}
                          </div>
                          <div className="fs-11 w-25 text-start">
                            {addedKeywordData?.bidData?.matchType}
                          </div>
                          {addedKeywordData?.bidData?.suggestedBid?.rangeStart && <div className="fs-11 w-25 text-start">
                            <div className="text-start budget_text-color">₹{(addedKeywordData?.bidData?.bid / 100).toFixed(2)}</div>
                            <div className="text-start budget_text-color">₹{(addedKeywordData?.bidData?.suggestedBid?.rangeStart / 100).toFixed(2)} -₹{(addedKeywordData?.bidData?.suggestedBid?.rangeEnd / 100).toFixed(2)}</div>
                          </div>}
                          {!addedKeywordData?.bidData?.suggestedBid?.rangeStart && (
                            <div className="text-start budget_text-color">-</div>
                          )}
                          <div className="fs-11 w-25">
                            <div className="d-flex">
                              <div className="input-group table-input-field-width mb-3 my-2">
                                <span className="input-group-text input bg-white border-dark px-1">
                                  <img src={RupeeIcon} className="" />
                                </span>
                                <input
                                  type="text"
                                  name="keyword_targeting_bid"
                                  min={addedKeywordData?.bidData?.suggestedBid?.rangeStart}
                                  max={addedKeywordData?.bidData?.suggestedBid?.rangeEnd}
                                  onChange={(e) => handleKeyWordTargetingInput(e, addedKeywordData, ind)}
                                  className="form-control input border-dark border-start-0 px-0"
                                  value={
                                    addedKeywordData?.bid
                                  }
                                />
                              </div>
                              <div className="fs-28 ms-1">
                                <i className="bi bi-x cursor-pointer" onClick={(e) => onClickCancelKeywordSuggestion(e, addedKeywordData, ind)}></i>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))}
                    {addedKeywordSuggestionData?.length === 0 && (
                      <div className="text-center">No Records Found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* {ai flow keyword enter list bid input modal starts} */}
            {showEnterListkeywordAiFlowBidModal && (
              <div className={`modal modal-custom fade ${showEnterListkeywordAiFlowBidModal ? "show" : "false"}  ${showEnterListkeywordAiFlowBidModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                <div className="modal-dialog modal-sm modal-dialog-centered " role="document">
                  <div className="modal-content mx-auto ">
                    <div className='modal-body p-3 border-bottom'>
                      <div className='fs-13'>3 of 3 keywords do not have a suggested bid</div>
                      <div className='d-flex justify-content-center my-2'>
                        <div className='fs-13 ai-alternate-label-width my-2'>Choose an alternate bid:</div>
                        <div className='d-flex justify-content-end'>
                          <input type="number" name="alternate_bid" className='form-control input border-dark alternate-bid-modal' onChange={(e) => handleAiProductFlowAlternateInput(e)} value={enterListBidValue} />
                        </div>
                      </div>
                      {aiFlowEnterListFormErrors.alternate_bid && (<div className='text-danger text-start'>{aiFlowEnterListFormErrors?.alternate_bid}</div>)}
                    </div>
                    <div className="modal-footer border-top d-flex justify-content-end">
                      <button
                        type="button"
                        className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                        onClick={() => setShowEnterListkeywordAiFlowBidModal(false)}
                      >
                        Do not add keywords
                      </button>
                      <button type="button" className={`request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer`}
                        onClick={onAddAiProductAlternateEnterListBid}
                      >
                        Add keywords
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {ai flow keyword enter list bid input modal ends} */}
          </div>
        </div>
      </div>
    </div>
  )
}
