import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { CustomTable } from '../../../../../components/custom-table';
import { ConfigurationGroupDataGridColumn } from './groups-data-grid-cols';
import { useNavigate } from 'react-router-dom';
import { GET_CAMPAIGN_AD_GROUPS } from '../../../../../app/services/adgroups.service';
import { Search } from '../../../../../components/configuration/search';
import { Breadcum } from '../../../../../components/breadcum';
import { GET_USER_CAMPAIGNS } from '../../../../../app/services/campaigns.service';
import { UPDATE_CAMPAIGN_AI_KEYS } from '../../../../../app/services/ai-keys-update.service';

export const GroupConfigurationPage = () => {
    const navigate = useNavigate();

    const [campainDataByID, setCampaignDataByID] = useState<any>();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const currentDate: any = new Date();
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignAdGroupsData, setCampaignAdGroupsData] = useState<any>()
    const [originalCampaignAdGroupsData, setOriginalCampaignAdGroupsData] = useState<any>([]);
    const [showDateRangePopup, setShowDateRangePopup] = useState<any>(false);
    const [adGroupAIStatus, setAdGroupAIStatus] = useState(false);

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Configurations",
            value: "Configurations",
            routerLink: "/dashboard/configuration",
            isActive: false,
        },
        {
            label: "Camapign name",
            value: "Camapign name",
            routerLink: "/dashboard/configuration/groups",
            isActive: false,
        },
    ])
    useEffect(() => {
        getCampaignAdGroups();
        getUserCampaignById();
    }, [])

    const getCampaignAdGroups = () => {
        setLoading(true);
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            is_report_data: false
        }
        setLoading(true);
        GET_CAMPAIGN_AD_GROUPS(preparedData).then((res: any) => {
            if (res?.data?.status === "error") {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response: any = res.data;
                    setCampaignAdGroupsData({
                        total: response?.data?.length,
                        rows: response?.data,
                    });
                    setOriginalCampaignAdGroupsData(response?.data)
                    setLoading(false);
                }
            }
        });
    }

    const onChangeTableInput = (data: any) => { }

    const autoUpdateAIStatus = (status: any) => {
        setAdGroupAIStatus(status)
    }

    const onActions = (data: any) => {
        if (data.type === "link") {
            localStorage.setItem("ad_group_id", data?.data?.ad_group_id);
            navigate(`/dashboard/configuration/groups-info/products`)
        } else if (data.type === "save") {
            const preparedData = {
                user_uuid: userUuid,
                type: 'ADGROUPS',
                data: [
                    {
                        ad_group_id: data?.data?.ad_group_id,
                        uuid: data?.data?.uuid,
                        benchmark: data?.data?.benchmark,
                        is_ai_update: adGroupAIStatus,
                        qualifier: data?.data?.qualifier,
                        is_ai_call: data?.data?.is_ai_call,
                        ai_end_date: data?.data?.ai_end_date,
                        ai_start_date: data?.data?.ai_start_date
                    }
                ]
            }
            UPDATE_CAMPAIGN_AI_KEYS(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        getCampaignAdGroups();
                    }
                    setLoading(false);
                }
            });
        } else if (data?.type === "Date Range") {
            setShowDateRangePopup(true);
        }
    }

    const getUserCampaignById = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            is_report_data: false,
        }
        setLoading(true);
        GET_USER_CAMPAIGNS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    getBreadCumList(response?.data?.data[0]);
                    setCampaignDataByID(response?.data?.data[0])
                    setLoading(false);
                }
            }
        });
    }

    const getBreadCumList = (data: any) => {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "All Campaigns",
            value: `Campaign : ${data?.campaign_name}`,
            routerLink: "/dashboard/configuration/groups",
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }

    const getSearchTerm = (search: any) => {
        if (search?.trim()?.length > 0) {
            const searchData = originalCampaignAdGroupsData?.map((el: any) => {
                return { ...el, dummy_ad_group_name: el.ad_group_name?.toLowerCase() };
            }).filter((el: any) => el?.dummy_ad_group_name?.includes(search.toLowerCase()));
            setCampaignAdGroupsData({ ...campaignAdGroupsData, rows: [...searchData] });
        } else {
            getCampaignAdGroups();
        }
    }

    const getSearchClear = (status: any) => {
        if (status) {
            getCampaignAdGroups();
        }
    }

    const getSelectAllCheckStatus = (status: any) => {
        if (status) {
            const data = campaignAdGroupsData?.rows
            data?.forEach((element: any) => {
                element.is_ai_call = true
            })
            setCampaignAdGroupsData({ ...campaignAdGroupsData, rows: [...data] })
        } else {
            const data = campaignAdGroupsData?.rows
            data?.forEach((element: any) => {
                element.is_ai_call = false
            })
            setCampaignAdGroupsData({ ...campaignAdGroupsData, rows: [...data] })
        }
    }
    const getEachSelectCheckStatus = (data: any) => {
        const allAdGroupsData = campaignAdGroupsData?.rows
        if (data?.status) {
            for (let i = 0; i < allAdGroupsData.length; i++) {
                if (allAdGroupsData[i]?.uuid === data?.data?.uuid) {
                    allAdGroupsData[i].is_ai_call = true
                }
            }
            setCampaignAdGroupsData({ ...campaignAdGroupsData, rows: [...allAdGroupsData] })
        } else {
            for (let i = 0; i < allAdGroupsData.length; i++) {
                if (allAdGroupsData[i]?.uuid === data?.data?.uuid) {
                    allAdGroupsData[i].is_ai_call = false
                }
            }
            setCampaignAdGroupsData({ ...campaignAdGroupsData, rows: [...allAdGroupsData] })
        }
    }

    return (
        <div className='container-fluid'>
            <div className='fs-28'>Campaign : <span className='fs-28 fw-bold'>{campainDataByID?.campaign_name}</span></div>
            <div className='row fs-5 fw-bold ms-0'>Ad Groups</div>
            <div className='my-0 ps-2 mb-2'>
                <Breadcum breadcumList={breadcumInfo}></Breadcum>
            </div>
            <div className='my-3'>
                <Search searchTerm={(e: any) => getSearchTerm(e)} searchClear={(e: any) => getSearchClear(e)} />
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {!loading &&
                <CustomTable
                    tableData={campaignAdGroupsData}
                    TableCols={ConfigurationGroupDataGridColumn}
                    tableWithoutFixedCoulmns={true}
                    toggleStatus={false}
                    tableLoading={!loading}
                    tableFooterpositionTop={true}
                    noDataFoundMesageTitle=''
                    onActions={(e) => onActions(e)}
                    onChangeTableInputData={(e) => onChangeTableInput(e)}
                    autoUpdateAIStatus={(e) => autoUpdateAIStatus(e)}
                    selectAllCheckStatus={(e) => getSelectAllCheckStatus(e)}
                    eachSelectCheckStatus={(e) => getEachSelectCheckStatus(e)}
                />
            }
            {showDateRangePopup && (
                <div className={`modal modal-custom fade ${showDateRangePopup ? "show" : "false"}  ${showDateRangePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">Date Range</h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDateRangePopup(false)}></button>
                            </div>
                            <div className='modal-body p-3 border-bottom'>
                                <div>
                                    <div>
                                        <label className='form-label'>Start Date</label>
                                        <input type="date" className="form-control" name={"start_date"} max={currentDate} />
                                    </div>
                                    <div>
                                        <label className='form-label'>End Date</label>
                                        <input type="date" className="form-control" name={"end_date"} max={""} />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end py-3">
                                    <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-4 mx-3" >Submit</button>
                                    <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-4`} onClick={() => setShowDateRangePopup(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
