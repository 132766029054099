import { CustomTableCol } from "../../../../../components/custom-table/types";

export const ConfigurationGroupDataGridColumn: CustomTableCol[] = [
  {
    title: "Ad Group",
    control: "ad_group_name",
    sortable: true,
    canShowColumn: true,
    style: { width: "250px", left: 214, height: "62px" },
    isLink: true,
    tooltip: {
      text: "Ad Group",
    },
  }
];
