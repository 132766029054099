import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

interface Props {
    formData?: any;
    updateFormDataToTable?: (data: any) => void;
    onSubmit?: (data: any) => void;
    updatedDateRangeData?: (data: any) => void;
}
export const ConfigurationForm = (props: Props) => {
    const datepickerFormRef: any = useRef();
    const currentDate: any = new Date();
    const [start, setStartDate] = useState();
    const [end, setEndDate] = useState(currentDate);
    const [formValues, setFormValues] = useState<any[]>(props?.formData);
    const [dateRangeData, setDateRangeData] = useState<any>([]);

    useEffect(() => {
        setStartDate(currentDate);
        setEndDate(currentDate);
    }, []);

    const handleInput = (element: any, index: number) => {
        const formData = formValues;
        setFormValues([...formData])
        if (props?.updateFormDataToTable) {
            if (index !== -1) {
                props?.updateFormDataToTable({ name: element.target?.name, value: element.target?.type === 'checkbox' ? element.target?.checked : element.target?.value, index });
            } else {
                props?.updateFormDataToTable({ name: element.name, value: element.value, index });
            }
        }
    }

    const onConfigurationFormSubmit = () => {
        if (props?.onSubmit) {
            props.onSubmit(formValues);
        }
    }

    const handleDateChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            if (start && end) {
                const date_range: any = {
                    start_date: moment(start).format("YYYY-MM-DD"),
                    end_date: moment(end).format("YYYY-MM-DD")
                }
                setDateRangeData(date_range);
                if (props?.updatedDateRangeData) {
                    props?.updatedDateRangeData(date_range)
                }
            } else {
                const date_range = {
                    start_date: moment(start).format("YYYY-MM-DD"),
                    end_date: moment(end).format("YYYY-MM-DD")
                };
                if (props?.updatedDateRangeData) {
                    props?.updatedDateRangeData(date_range)
                }
            }
        }
    }

    const handleDateChangeApplyAll = (dates: any, index: number) => {
        const date_range = {
            start_date: moment(dates?.start).format("YYYY-MM-DD"),
            end_date: moment(dates?.end).format("YYYY-MM-DD")
        };
        if (props?.updateFormDataToTable) {
            if (index !== -1) {
                props?.updateFormDataToTable({ name: 'ai_start_date', value: date_range?.start_date ? date_range?.start_date : dateRangeData?.start_date, index });
                props?.updateFormDataToTable({ name: 'ai_end_date', value: date_range?.end_date ? date_range?.end_date : dateRangeData?.end_date, index });
            } else {
                props?.updateFormDataToTable({ name: 'ai_start_date', value: date_range?.start_date ? date_range?.start_date : dateRangeData?.start_date, index });
                props?.updateFormDataToTable({ name: 'ai_end_date', value: date_range?.end_date ? date_range?.end_date : dateRangeData?.end_date, index });
            }
        }
    }
    return (
        <>
            <div className='d-flex my-3'>
                {props?.formData.map((data: any, index: number) => (
                    <>
                        {data?.isEmpty && <div className='' style={{ width: data?.width }}></div>}
                        {data?.type === 'input' && <div className='' style={{ width: data?.width }}>
                            <div className="ps-1">
                                <p className='mb-2'> {data?.label} <span className='fs-13 ai-products-text-color cursor-pointer ms-1' onClick={() => handleInput(data, -1)}>Apply to all</span></p>
                                <label className="text-start">
                                    <input type={data?.inputType ? data?.inputType : 'text'} name={data?.name} placeholder={data?.placeholder} className="form-control" onChange={(e) => handleInput(e, index)}></input>
                                </label>
                            </div>
                        </div >}
                        {data?.type === "datePicker" && (
                            <div className='px-2'>
                                <p className='mb-2'> {data?.label} <span className='fs-13 ai-products-text-color cursor-pointer  ms-1' onClick={() => handleDateChangeApplyAll({ start: start, end: end }, -1)}>Apply to all</span></p>
                                <div ref={datepickerFormRef} className='col-md-12 d-flex custom-button-background rounded-pill fs-13 cursor-pointer text-end text-md-start mb-1 px-2'>
                                    <div className='me-1 fs-13 d-flex align-items-center'>Date range - </div>
                                    <DatePicker
                                        selectsRange
                                        className="rounded border-0 fs-13 py-1 custom-button-background"
                                        startDate={start}
                                        endDate={end}
                                        onChange={handleDateChange}
                                        maxDate={currentDate}
                                        monthsShown={2}
                                        isClearble={true}
                                    />
                                </div>
                            </div>
                        )}
                        {data?.type === 'switch' && <div className='' style={{ width: data?.width }}>
                            <div className="form-check form-switch ps-1">
                                <p className='mb-2'> {data?.label} <span className='fs-13 ai-products-text-color cursor-pointer  ms-1' onClick={() => handleInput(data, -1)}>Apply to all</span></p>
                                <label className="switch text-start mt-2">
                                    <input type="checkbox" name={data?.name} onChange={(e) => handleInput(e, index)}></input>
                                    <div className="slider round">
                                        <span className="on">
                                            <svg viewBox="0 0 512 512" width="7" height="7" className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check toggle-check-mark-position" data-fa-i2svg="">
                                                <path fill="white" d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z">
                                                </path>
                                            </svg>
                                        </span>
                                        <span className="off"></span>
                                    </div>
                                </label>
                            </div>
                        </div>}
                        {data?.type === 'dropdown' && <div className='' style={{ width: data?.width }}>
                            <div className="ps-1">
                                <p className='mb-2'> {data?.label} <span className='fs-13 ai-products-text-color cursor-pointer ms-1' onClick={() => handleInput(data, -1)}>Apply to all</span></p>
                                <label className="text-start">
                                    <select className="form-control secondary-placeholder-color" placeholder={data?.placeholder} name={data?.name} value={data?.value} onChange={(e) => handleInput(e, index)}>
                                        <option value="" disabled >{data?.placeholder}</option>
                                        {data?.options?.map((dropdownOption: any) => {
                                            return <option value={dropdownOption?.value}>{dropdownOption?.name}</option>
                                        })}
                                    </select>
                                </label>
                            </div>
                        </div>}
                        {data?.type === 'button' && <div className='custom-explore-text-color cursor-pointer logout mt-3 pt-4' onClick={onConfigurationFormSubmit}>{data?.name}</div>}
                    </>))}
            </div >
        </>
    )
}
