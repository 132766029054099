import React, { useEffect, useRef, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChartComponent from '../../../components/charts/line-chart';
import { Tooltip } from "@mui/material";
import { TooltipPage } from '../../../components/custom-tooltip/tooltipPage';
import { Campaigns } from '../campaigns';
import { useNavigate } from 'react-router';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GET_CAMPAIGN_SUMMARY_REPORT } from '../../../app/services/portfolio-summary-report.service';
import moment from 'moment';
import { connect } from 'react-redux';
import { useProfileId } from '../../../components/profile';

interface Props {
    userUuidData?: any;
}

const CampaignPage = (props: Props) => {
    const [expanded, setExpanded] = useState(false);
    const [summaryReportsData, setSummaryReportsData] = useState<any>();
    const [summaryReportsTotalData, setSummaryReportsTotalData] = useState<any>();
    const [tooltipClose, setTooltipClose] = useState<boolean>(true);
    const navigate = useNavigate();
    const [selectedFilteredValue, setSelectedFilteredValue] = useState<any>('')
    const [selectedColumnValue, setSelectedColumnValue] = useState<any>('Columns')
    const [startDate, setStartDate] = useState(new Date());
    const datepickerRef: any = useRef(null);
    const userUuid = localStorage.getItem('user_uuid') || '';
    const profileID = localStorage.getItem('profile_id') || '';
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const defaultEndDate = currentDate;
    const dateRangeData = {
        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
    }

    const FilterTypesData = [
        {
            name: "Active status",
            value: "Active status"
        },
        {
            name: "Default bid",
            value: "Default bid"
        },
        {
            name: "Impressions",
            value: "Impressions"
        },
        {
            name: "Clicks",
            value: "Clicks"
        },
        {
            name: "Click-through rate (CTR)",
            value: "Click-through rate (CTR)"
        },
        {
            name: "Spend",
            value: "Spend"
        },
        {
            name: "Cost-per-click (CPC)",
            value: "Cost-per-click (CPC)"
        },
        {
            name: "Orders",
            value: "Orders"
        },
        {
            name: "Sales",
            value: "Sales"
        },
        {
            name: "Advertising cost of sales (ACOS)",
            value: "Advertising cost of sales (ACOS)"
        },
        {
            name: "Return on ad spend (ROAS)",
            value: "Return on ad spend (ROAS)"
        },
    ]

    const columnsTypesData = [
        {
            name: "Customise columns",
            value: "Customise columns"
        },
        {
            name: "Reset to default",
            value: "Reset to default"
        },
    ]

    const dataRangeDropdownlist = [
        {
            name: "Today",
            value: "Today"
        },
        {
            name: "Yesterday",
            value: "Yesterday"
        },
        {
            name: "Last 7 days",
            value: "Last 7 days"
        },
        {
            name: "This week",
            value: "This week"
        },
        {
            name: "Last week",
            value: "Last week"
        },
        {
            name: "Last 30 days",
            value: "Last 30 days"
        },
        {
            name: "This month",
            value: "This month"
        },
        {
            name: "Last month",
            value: "Last month"
        },
        {
            name: "Year to date",
            value: "Year to date"
        },
        {
            name: "Lifetime",
            value: "Lifetime"
        },
    ]

    const metricCardsData = [
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #ff5733",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #373782",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #fe4a4a",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #4949da",
            key: 'impressions'
        },
        {
            card_title: "CTR",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #3e1bc2",
            key: 'clickThroughRate'
        },
        {
            card_title: "Clicks",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #301934",
            key: 'clicks'
        },
        {
            card_title: "CPC",
            amount: '',
            sub_title: "AVERAGE",
            border_color: "4px solid #1d2383",
            key: 'costPerClick'
        },
        {
            card_title: "Orders",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #53f453",
            key: 'orders'
        },
        {
            card_title: "ACOS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #ff1cff",
            key: 'acoss'
        },
    ];
    const [defaultMetrics, setDefaultMetrics] = useState<any>([
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #ff5733",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #373782",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #fe4a4a",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #4949da",
            key: 'impressions'
        },
    ]);

    const [selectedMetrics, setSelectedMetrics] = useState<any>({
        sales: 'Sales',
        cost: 'Spend',
        roas: 'ROAS',
        impressions: 'Impressions'
    });
    const { profileId }: any = useProfileId();

    useEffect(() => {
        getPortfolioSummaryReport(dateRangeData, profileID, userUuid);
    }, [])


    useEffect(() => {
        if (profileId) {
            const userUuid = localStorage.getItem('user_uuid') || '';
            const profileID = profileId;
            getPortfolioSummaryReport(dateRangeData, profileID, userUuid);
        }
    }, [profileId]);

    const getPortfolioSummaryReport = (dateRangeData: any, profileID: string, userUuid: string) => {
        const preparedData = {
            profile_id: profileID,
            user_uuid: userUuid,
            start_date: dateRangeData?.start_date,
            end_date: dateRangeData?.end_date
        }
        GET_CAMPAIGN_SUMMARY_REPORT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
            } else {
                if (res) {
                    setSummaryReportsData(res?.data?.data[0]?.dateWiseData);
                    setSummaryReportsTotalData({ ...res?.data?.data[0]?.totalData });
                }
            }
        })
    }

    const handleAccordionToggle = () => {
        setExpanded(!expanded);
    };

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    }

    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "Sales") {
            return (
                <TooltipPage
                    tooltipHeader={`Sales`}
                    tooltipMainContent=
                    {`Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads. Sponsored Products: 
                    Sales from advertised products as well as other products from your inventory purchased within 14 days.
                Sponsored Brands: Sales from advertised products sold by Amazon within 14 days. Where eligible, this also includes sales from all products within your brand sold by Amazon and third-party sellers.
                It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
                Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.'}
                    tooltipSubContent={'Learn about ad groups`} tooltipSubContent={`Learn about sales`} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Spend") {
            return (
                <TooltipPage
                    tooltipHeader={`Spend`}
                    tooltipMainContent={`The total click charges for a product ad.
                Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. 
                Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipSubContent={`Learn about spend`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Return on ad spend (ROAS)") {
            return (
                <TooltipPage
                    tooltipHeader={`Return on ad spend (ROAS)`}
                    tooltipMainContent={`The revenue you receive from your advertising investment. 
                This is calculated by dividing sales attributed to your ads by your spend. 
                Attribution varies by campaign type.`}
                    tooltipSubContent={`Learn about return on ad spend (ROAS)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Impressions") {
            return (
                <TooltipPage
                    tooltipHeader={`Impressions)`}
                    tooltipMainContent={`The number of times ads were displayed.`}
                    tooltipSubContent={`Learn about impressions`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        }
    }

    const onClickCreateCampaign = () => {
        navigate("/dashboard/campaign-type");
    }

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    }

    const handleSelectedFilterType = (option: string) => {
        setSelectedFilteredValue(option)
    }

    const handleSelectedColumnType = (option: string) => {
        setSelectedColumnValue(option)
    }

    const onClickCalender = () => {
        datepickerRef?.current?.setOpen(true);
    }

    const getRupeeData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return "0"
        } else if (data === undefined) {
            return "-";
        } else {
            const preparedData = parseFloat(data ? data?.toFixed(2) : "")?.toLocaleString('en-IN');
            return `${preparedData}`
        }
    }
    const getPercentageData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return "0"
        } else if (data === undefined) {
            return "-";
        } else {
            return data ? data?.toFixed(2) : "";
        }
    }

    const getCampaignDateRangesData = (data: any) => {
        getPortfolioSummaryReport(data, profileID, userUuid);
    }

    const onSelectedMetric = (e: any, data: any, metricData: any) => {
        e.stopPropagation();
    }
    const handleChangeSelectedMetric = (
        e: React.ChangeEvent<HTMLSelectElement>,
        metricKey: string
    ) => {
        const { value } = e.target;
        const selectedMetricData = metricCardsData.find((el: any) => el.card_title === value);

        if (selectedMetricData) {
            setSelectedMetrics((prevMetrics: any) => {
                const updatedMetrics = { ...prevMetrics };
                delete updatedMetrics[metricKey];
                updatedMetrics[selectedMetricData.key] = selectedMetricData.card_title;
                return updatedMetrics;
            });
            setDefaultMetrics((prevMetrics: any) =>
                prevMetrics.map((metric: any) =>
                    metric.key === metricKey
                        ? {
                            ...metric,
                            card_title: selectedMetricData.card_title,
                            amount: selectedMetricData.amount,
                            sub_title: selectedMetricData.sub_title,
                            border_color: selectedMetricData.border_color,
                            key: selectedMetricData.key
                        }
                        : metric
                )
            );
        }
    };

    return (
        <div className='container-fluid'>
            <div className="row">
                <Accordion expanded={!expanded} className='accordion'>
                    <AccordionSummary
                        expandIcon={expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        onClick={handleAccordionToggle}
                    >
                        <div className='col-md-12'>
                            <div className='row mx-0'>
                                {defaultMetrics.map((metric: any, index: number) => (
                                    <div
                                        key={index}
                                        className={`col-md-3 py-2 py-md-0 border-bottom border-md-bottom-0 accordion-sub-menu ${selectedMetrics[metric.key] ? `${selectedMetrics[metric.key]?.toLowerCase()}-color` : ''}`}
                                        onClick={(e) => onSelectedMetric(e, metric.key, metric)}
                                    >
                                        <div className='d-flex'>
                                            <div className='my-1'>
                                                <select
                                                    value={selectedMetrics[metric.key] || ''}
                                                    onChange={(e) => handleChangeSelectedMetric(e, metric.key)}
                                                    className='form-select fs-13 rounded-pill custom-metric-select py-1'
                                                >
                                                    {metricCardsData.map((metricCard, index) => (
                                                        <option key={index} value={metricCard.card_title}>
                                                            {metricCard.card_title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='my-1'>
                                                <span>
                                                    <Tooltip
                                                        title={tooltipContent(metric.key)}
                                                        placement='top'
                                                        arrow
                                                        className='custom-tooltip-position cursor-pointer ps-2'
                                                        onClick={handleClose}
                                                    >
                                                        <span className='cursor-pointer'>
                                                            <svg
                                                                viewBox='0 0 512 512'
                                                                width='13'
                                                                height='13'
                                                                className='fas fa-info-circle'
                                                                data-fa-i2svg=''
                                                            >
                                                                <path
                                                                    fill='#8290a4'
                                                                    d='M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z'
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            {metric.key === 'roas' ? (
                                                <span className='me-2 fw-bold'>
                                                    {getPercentageData(summaryReportsTotalData?.[metric.key] || '')}
                                                </span>
                                            ) : (
                                                <span className='fw-bold'>
                                                    ₹{getRupeeData(summaryReportsTotalData?.[metric.key] || '')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className='chart_component'>
                                {summaryReportsData && <ChartComponent chartData={summaryReportsData} />}
                                {!summaryReportsData && <>
                                    <ChartComponent chartData={summaryReportsData} customCss={true} />
                                    <div className='d-flex align-items-center position-relative' style={{ top: "-200px" }}>
                                        <div className='text-center mx-auto fs-4 fw-bold'>No campaigns are currently active</div>
                                    </div>
                                </>
                                }
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <div className='row filters-background border mx-0 d-none'>
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-2 px-0'>
                                <button className='request-call-button text-white fs-13 px-3 my-2' onClick={onClickCreateCampaign}>Create campaign</button>
                            </div>
                            <div className='col-3 my-2 px-0 search-margin-left'>
                                <div className="input-group border-dark">
                                    <span className="input-group-text bg-white  px-1" id="basic-addon1">
                                        <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-search search-icon-color"><path fill="rgb(130, 144, 164)" d="M194 8c102.73 0 186 83.28 186 186 0 40.17-12.73 77.37-34.39 107.77l149.31 149.31a31 31 0 0 1-43.84 43.84l-149.3-149.3A185.14 185.14 0 0 1 194 380C91.28 380 8 296.73 8 194 8 91.28 91.28 8 194 8Zm0 62c-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124 68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124Z"></path></svg>
                                    </span>
                                    <input type="text" className="form-control drop-down-input  fs-13 border-start-0 px-0" placeholder='Find a Campaign' />
                                </div>
                            </div>
                            <div className="col-1 my-2 fs-13 px-0">
                                <div className="btn-group filterType-margin-left">
                                    <button className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedFilteredValue ? selectedFilteredValue : "Filter Type"} <span>
                                            <svg viewBox="0 0 389 512" width="13" height="13" className="fas fa-angle-down show-text-icon" data-fa-i2svg=""><path fill="currentColor" d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"></path></svg>
                                        </span>
                                    </button>
                                    <ul className="dropdown-menu newest-first-dropdown filters-dropdown">
                                        <li className='fs-13 py-1'>
                                            {FilterTypesData?.map((item: any, index: number) => (
                                                <button key={index} className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedFilteredValue === item?.value ? "active" : ""}`} value={item.value} onClick={() => handleSelectedFilterType(item?.value)}>
                                                    <div className='fw-400'>{item.name}</div>
                                                </button>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1 my-2 fs-13 px-0">
                                <div className="btn-group columns-margin-left">
                                    <button className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedColumnValue ? selectedColumnValue : "Columns"} <span>
                                            <svg viewBox="0 0 389 512" width="13" height="13" className="fas fa-angle-down show-text-icon" data-fa-i2svg=""><path fill="currentColor" d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"></path></svg>
                                        </span>
                                    </button>
                                    <ul className="dropdown-menu newest-first-dropdown columns-dropdown">
                                        <li className='fs-13 py-1'>
                                            {columnsTypesData?.map((item: any, index: number) => (
                                                <button key={index} className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedColumnValue === item?.value ? "active" : ""}`} value={item.value} onClick={() => handleSelectedColumnType(item?.value)}>
                                                    <div className='fw-400'>{item.name}</div>
                                                </button>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-4 my-2 fs-13 px-1 ">
                                <div className="btn-group date-range-filter-margin-left">
                                    <button className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={onClickCalender}>
                                        {selectedFilteredValue ? selectedFilteredValue : "Date range: Aug 14 - Aug 22, 2023"} <span>
                                            <svg viewBox="0 0 389 512" width="13" height="13" className="fas fa-angle-down show-text-icon" data-fa-i2svg="">
                                                <path fill="currentColor" d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z">
                                                </path>
                                            </svg>
                                        </span>
                                    </button>
                                    <ul className="dropdown-menu newest-first-dropdown filters-dropdown py-0 date-range-dropdown">
                                        <li className='fs-13 container py-1'>
                                            <div className='row justify-content-center py-0'>
                                                <div className='col-md-3 border-end border-dark px-0'>
                                                    {dataRangeDropdownlist?.map((data: any, index: number) => (
                                                        <li key={index} className={`list-style-type-none px-2 py-1 ${index === 0 ? "active" : false}`}>
                                                            {data?.name}
                                                        </li>
                                                    ))}
                                                </div>
                                                <div className='col-md-9'>
                                                    <DatePicker
                                                        ref={datepickerRef}
                                                        selected={startDate}
                                                        inline
                                                        onChange={(date: any) => setStartDate(date)}
                                                        monthsShown={2}
                                                    />
                                                    <div className="d-flex justify-content-end me-3">
                                                        <div className='d-flex justiy-content-end'>
                                                            <div className="custom-explore-text-color fs-13 logout mx-2 my-1">Cancel</div>
                                                            <div>
                                                                <button className=" apply-button text-white fs-13">Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1 my-2 fs-13 px-0 export-margin-left">
                                <div className='btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1 export-margin-left'>
                                    export
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Campaigns dateRangeData={(e) => getCampaignDateRangesData(e)} />
            </div>
        </div>
    )
};

const mapStateToProps = (state: any) => {
    return {
        userUuidData: state.UserUuidDataReducer
    };
};


const connectedNavBar = connect(mapStateToProps)(CampaignPage);
export { connectedNavBar as CampaignPage };
