import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { CustomTable } from '../../../../../../../components/custom-table';
import { ConfigurationGroupProductsDataGridColumn } from './products-data-grid-cols';
import { GET_AD_GROUP_PRODUCTS_ADS } from '../../../../../../../app/services/ad-groups-ads.service';
import moment from 'moment';
import { ConfigurationForm } from '../../../../../../../components/configuration/form';
import { Search } from '../../../../../../../components/configuration/search';
import { UPDATE_ALL_CAMPAIGNS_AI_KEYS, UPDATE_CAMPAIGN_AI_KEYS } from '../../../../../../../app/services/ai-keys-update.service';
import { durationsList } from './../../../../../../../app/utils/durationsList';

export const GroupProductsConfigurationPage = () => {
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const adGroupId = localStorage.getItem("ad_group_id");
    const [adgroupAdsData, setAdgroupAdsData] = useState<any>()
    const [originalAdgroupAdsData, setOriginalAdgroupAdsData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const currentDate: any = new Date();
    const [showDateRangePopup, setShowDateRangePopup] = useState<any>(false);
    const [selectConfigDurationValue, setSelectConfigDurationValue] = useState<any>();
    const [dateRange, setDateRange] = useState<any>();
    const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const defaultEndDate = currentDate;
    const dateRangeData = {
        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
    };
    const [confirmationPopup, setConfirmationPopup] = useState<any>(false);
    const [preparedCampaignsData, setPreparedCampaignsData] = useState<any>();
    const [formData, setFormData] = useState<any>([
        {
            isEmpty: true,
            width: '10%'
        },
        {
            type: 'input',
            name: 'benchmark',
            placeholder: 'Benchmark',
            inputType: 'number',
            value: '',
            label: 'Benchmark',
            width: '15%'
        },
        {
            type: 'dropdown',
            inputType: 'text',
            name: 'duration',
            placeholder: 'Select duration',
            value: "",
            options: durationsList,
            label: 'Duration',
            width: '12%'
        },
        {
            type: 'input',
            inputType: 'number',
            name: 'qualifier',
            placeholder: 'Qualifier',
            value: "",
            label: 'Qualifier',
            width: '15%'
        },
        {
            type: 'switch',
            name: 'is_ai_update',
            placeholder: '',
            value: '',
            label: 'Auto Updated by AI',
            width: '19%'
        },
        {
            type: 'switch',
            name: 'is_ai_call',
            placeholder: '',
            value: '',
            label: 'Get Recommendations by AI',
            width: '25%'
        },
        {
            type: 'button',
            name: 'Save all',
            placeholder: '',
            value: '',
            label: ''
        }
    ])

    useEffect(() => {
        getAdGroupProductAds();
    }, [])

    const getAdGroupProductAds = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            ad_group_id: adGroupId,
            start_date: dateRangeData?.start_date,
            end_date: dateRangeData?.end_date
        }

        setLoading(true);
        GET_AD_GROUP_PRODUCTS_ADS(preparedData).then((res) => {
            if (res?.data?.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    setAdgroupAdsData({ rows: response?.data, total: response?.data?.length });
                    setOriginalAdgroupAdsData(response?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onChangeConfiguration = (data: any) => {
        setSelectConfigDurationValue(data?.updatedDuration)
        const filterData = adgroupAdsData?.rows?.filter((el: any) => el?.uuid === data?.data?.uuid);
        filterData[0].duration = data?.updatedDuration
        setAdgroupAdsData({ ...adgroupAdsData, rows: [...filterData] })
    }

    const onActions = (data: any) => {
        if (data.type === "save") {
            const preparedData = {
                user_uuid: userUuid,
                type: 'PRODUCTADS',
                data: [
                    {
                        uuid: data?.data?.uuid,
                        benchmark: data?.data?.benchmark,
                        is_ai_update: data?.data?.is_ai_update,
                        is_ai_call: data?.data?.is_ai_call,
                        duration: selectConfigDurationValue ? selectConfigDurationValue : data?.data?.duration,
                        ai_end_date: data?.data?.ai_end_date,
                        ai_start_date: data?.data?.ai_start_date,
                        qualifier: data?.data?.qualifier,
                    }
                ]
            }
            setLoading(true);
            UPDATE_CAMPAIGN_AI_KEYS(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        getAdGroupProductAds();
                        toast.success(res.data.message);
                    }
                    setLoading(false);
                }
            });
        } else if (data?.type === "Date Range") {
            setShowDateRangePopup(true);
        }
    }

    const updateFormDataToTable = (formDataResult: any) => {
        const data = adgroupAdsData;
        const formDataObj = formData;
        data?.rows.forEach((data: any) => {
            if (data[formDataResult?.name] === formDataObj[formDataResult.index]?.value || !data[formDataResult?.name]) {
                data[formDataResult?.name] = formDataResult?.value
            } else if (formDataResult?.index === -1) {
                data[formDataResult?.name] = formDataResult?.value
            }
        });
        if (formDataResult.index !== -1) {
            formDataObj[formDataResult.index].value = formDataResult?.value;
            setFormData([...formDataObj])
        }
        setAdgroupAdsData({
            ...data
        });
    }

    const getSearchTerm = (search: any) => {
        if (search?.trim()?.length > 0) {
            const searchData = originalAdgroupAdsData?.map((el: any) => {
                return { ...el, dummy_title: el.title?.toLowerCase() };
            }).filter((el: any) => el?.dummy_title?.includes(search.toLowerCase()));
            setAdgroupAdsData({ ...adgroupAdsData, rows: [...searchData] });
        } else {
            getAdGroupProductAds();
        }
    }

    const getSearchClear = (status: any) => {
        if (status) {
            getAdGroupProductAds();
        }
    }

    const getSelectAllCheckStatus = (status: any) => {
        if (status) {
            const data = adgroupAdsData?.rows
            data?.forEach((element: any) => {
                element.is_ai_call = true
            })
            setAdgroupAdsData({ ...adgroupAdsData, rows: [...data] })
        } else {
            const data = adgroupAdsData?.rows
            data?.forEach((element: any) => {
                element.is_ai_call = false
            })
            setAdgroupAdsData({ ...adgroupAdsData, rows: [...data] })
        }
    }
    const getEachSelectCheckStatus = (data: any) => {
        const allAdGroupAdsData = adgroupAdsData?.rows
        if (data?.status) {
            for (let i = 0; i < allAdGroupAdsData.length; i++) {
                if (allAdGroupAdsData[i]?.uuid === data?.data?.uuid) {
                    allAdGroupAdsData[i].is_ai_call = true
                }
            }
            setAdgroupAdsData({ ...adgroupAdsData, rows: [...allAdGroupAdsData] })
        } else {
            for (let i = 0; i < allAdGroupAdsData.length; i++) {
                if (allAdGroupAdsData[i]?.uuid === data?.data?.uuid) {
                    allAdGroupAdsData[i].is_ai_call = false
                }
            }
            setAdgroupAdsData({ ...adgroupAdsData, rows: [...allAdGroupAdsData] })
        }
    }

    const onSaveAll = (data: any) => {
        let updatedProductsData: any = {};
        const fieldsToUpdate = ['benchmark', 'qualifier', 'duration', 'ai_start_date', 'ai_end_date', 'is_ai_update', 'is_ai_call'];
        const isNonEmpty = (value: any) => value !== undefined && value !== null && value !== '';
        formData.forEach((formElement: any) => {
            if (isNonEmpty(formElement?.value) && formElement?.type !== 'button' && fieldsToUpdate.includes(formElement.name)) {
                updatedProductsData[formElement.name] = formElement.value;
            }
        });

        const preparedData = {
            user_uuid: userUuid,
            profile_id: profileID,
            type: 'PRODUCTS',
            ...updatedProductsData,
            ...dateRange
        };
        setPreparedCampaignsData(preparedData);
        setConfirmationPopup(true);
    }

    const getIndividualDateRange = (data: any) => {
        setDateRange({ ...dateRange, ai_start_date: data?.start_date, ai_end_date: data?.end_date })
    }

    const saveAllConfirmation = () => {
        setLoading(true);
        UPDATE_ALL_CAMPAIGNS_AI_KEYS(preparedCampaignsData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    getAdGroupProductAds();
                    toast.success(res.data.message);
                }
                setLoading(false);
                setConfirmationPopup(false);

            }
        });
    }

    return (
        <div className='container-fluid mt-2'>
            <div className='row fs-5 fw-bold ms-0'>Products</div>
            <div>
                <Search searchTerm={(e: any) => getSearchTerm(e)} searchClear={(e: any) => getSearchClear(e)} />
            </div>
            <ConfigurationForm formData={formData} updateFormDataToTable={(e) => updateFormDataToTable(e)} onSubmit={(e) => onSaveAll(e)}
                updatedDateRangeData={(e) => getIndividualDateRange(e)} />
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {!loading &&
                <CustomTable
                    tableData={adgroupAdsData}
                    TableCols={ConfigurationGroupProductsDataGridColumn}
                    tableWithoutFixedCoulmns={true}
                    toggleStatus={false}
                    tableLoading={false}
                    tableFooterpositionTop={true}
                    noDataFoundMesageTitle=''
                    onActions={(e) => onActions(e)}
                    configurationStatus={true}
                    selectAllCheckStatus={(e) => getSelectAllCheckStatus(e)}
                    eachSelectCheckStatus={(e) => getEachSelectCheckStatus(e)}
                    configureValue={(e) => onChangeConfiguration(e)}
                />
            }
            {showDateRangePopup && (
                <div className={`modal modal-custom fade ${showDateRangePopup ? "show" : "false"}  ${showDateRangePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">Date Range</h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDateRangePopup(false)}></button>
                            </div>
                            <div className='modal-body p-3 border-bottom'>
                                <div>
                                    <div>
                                        <label className='form-label'>Start Date</label>
                                        <input type="date" className="form-control" name={"start_date"} min={currentDate} />
                                    </div>
                                    <div>
                                        <label className='form-label'>End Date</label>
                                        <input type="date" className="form-control" name={"end_date"} max={""} />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end py-3">
                                    <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-4 mx-3" >Submit</button>
                                    <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-4`} onClick={() => setShowDateRangePopup(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {confirmationPopup && (
                <div className={`modal modal-custom fade ${confirmationPopup ? "show" : "false"}  ${confirmationPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">Confirmation</h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setConfirmationPopup(false)}></button>
                            </div>
                            <div className='modal-body p-3 border-bottom'>
                                <div>
                                    <div>
                                        <p>This configuration will apply to all campaigns in this profile (profile name), Do you want to continue</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end py-3">
                                    <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-4 mx-3" onClick={() => saveAllConfirmation()}>Yes</button>
                                    <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-4`} onClick={() => setConfirmationPopup(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}