import React, { useEffect, useState } from "react";
import WrongIcon from "./../../../assets/images/svg_icons/wrong.svg";
import { useNavigate, useParams } from "react-router-dom";
import SmallWrongIcon from "./../../../assets/images/svg_icons/small-wrong.svg";
import SearchIcon from "./../../../assets/images/svg_icons/search.svg";
import SearchSecondIcon from "./../../../assets/images/svg_icons/search-two.svg";
import DownloadIcon from "./../../../assets/images/svg_icons/download.svg";
import NoProductImage from './../../../assets/images/no-product-image.png';
import { DataTable } from "../../../components/data-table";
import { KeywordTargetingDataGridCols } from "./keyword-targeting-data-grid-cols";
import { KeywordTargetingTwoDataGridCols } from "./keyword-targeting-data-grid-cols-two";
import { ProductTargetingIndividualProductsFilter } from "../../../components/campaign-creation/individual-products-filter";
import { ProductTargetingRightSideTableDataGridCols } from "./product-targeting-right-side-data-col-grids";
import { ProductTargetCategoriesFilter } from "../../../components/campaign-creation/categories-filter";
import RupeeIcon from "./../../../assets/images/svg_icons/rupee.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import { TooltipPage } from "../../../components/custom-tooltip/tooltipPage";
import { CustomFooter } from "../../../components/footer/custom-footer";
import MultiRangeSlider from "multi-range-slider-react";
import { GetHelpCardModal } from "../../../components/campaign-creation/get-help-card";
import { GET_USER_PORTFOLIOS } from "../../../app/services/portfolios.service";
import { toast } from "react-toastify";
import { CHECK_PRODUCT_ELIGIBILITY, CREATE_CAMPAIGN, GET_KEYWORD_SUGGESTIONS, GET_PROFILE_PRODUCTS } from "../../../app/services/campaigns.service";
import { KeywordTargetingCards } from "../../../components/campaign-creation-components/keyword-targeting-cards";
import { AiCampaignDataProductsListCard } from "../../../components/campaign-creation/ai-campaign-data-products-list-card";
import { AiCampaignsTabsPage } from "../../../components/campaign-creation/ai-campaigns-tabs-page";
import { durationsList } from "../../../app/utils/durationsList";

interface Props {
  addKeywordSuggestionData?: any;
  removeKeywordSuggestionData?: (data: any) => void;
  altenativeBidKeywordsData?: (data: any) => void;
  alternateBidStatus?: (data: any) => void;
}

export const CampaignCreationPage = (props: Props) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<any>("");
  const [showWrongIcon, setShowWrongIcon] = useState<boolean>(false);
  const [showManualTargeting, setShowManualTargeting] = useState<any>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [campaignLoading, setCampaignLoading] = useState<boolean>(false);
  const [keywordsloading, setKeywordsLoading] = useState<boolean>(false);
  const [portfoliosData, setPortfoliosData] = useState<any>();
  const [showManualKeywordTargeting, setShowManualKeywordTargeting] = useState<any>(false);
  const [showManualProductTargeting, setShowManualProductTargeting] = useState<any>(false);
  const [showDynamicCampaignBid, setShowDynamicCampaignBid] = useState<boolean>(false);
  const [prodcutsAddedFinalStep, setProdcutsAddedFinalStep] = useState<boolean>(false);
  const [keywordsFinalStep, setKeywordsFinalStep] = useState<boolean>(false);
  const [showSetDefaultBid, setShowSetDefaultBid] = useState<any>(true);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [tooltipClose, setTooltipClose] = useState<boolean>(false);
  const [showCreateAdgroupPopup, setShowhowCreateAdgroupPopup] = useState<boolean>(false);
  const [allkeywordTargeting, setAllkeywordTargeting] = useState<any>();
  const [showLeavePageModal, setShowLeavePageModal] = useState<boolean>(false)
  const [targetingOption, setTargetingOption] = useState("manual");
  const [manualTargetingOption, setManualTargetingOption] = useState("keyword");
  const [setDefaultBid, setSetDefaultBid] = useState("Set default bid");
  const [campaignBiddinStrategy, setCampaignBiddingStrategy] = useState("Dynamic bids - down only");
  const [campaignBidStrategyData, setCampaignBidStrategyData] = useState<any>("LEGACY_FOR_SALES");
  const keywordTargetingdata: any = [];
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [targetingGroupsStatus, setTargetingGroupsStatus] = useState<boolean>(true);
  const [selectedKeyWordTargetingBidValue, setSelectedKeywordTargetingBidValue] = useState<any>("Suggested bid");
  const [selectedProductTargetingBidValue, setSelectedProductTargetingBidValue] = useState<any>("Suggested bid");
  const [selectedKeyWordTargetingSortingValue, setSelectedKeywordTargetingSortingValue] = useState<any>("Orders");
  const [checkboxes, setCheckboxes] = useState<any>({
    Broad: true,
    Exact: true,
    Phrase: true,
  });

  const [externalListCheckboxes, setExternalListCheckboxes] = useState<any>({
    Broad: true,
    Exact: true,
    Phrase: true,
  });
  const [showRefineModal, setShowRefineModal] = useState<boolean>(false);
  const [addProductsData, setAddproductsData] = useState<any>([]);
  const [addedProductsAsinData, setAddedProductsAsinData] = useState<any>([]);
  const [addedProductsAsinSkuData, setAddedProductsAsinSkuData] = useState<any>([]);
  const [keywordSuggestionsData, setKeywordSuggestionsData] = useState<any>();
  const [originalKeywordSuggestionsData, setOriginalKeywordSuggestionsData] = useState<any>();
  const [addedKeywordSuggestionData, setAddedKeywordSuggesstionData] = useState<any>([]);
  const [campaignName, setCampaignName] = useState<any>();
  const [negativeKeywordText, setNegativeKeywordText] = useState<any>({
    negative_keyword_text: ""
  })
  const [manualEnterListKeywordText, setManualEnterListKeywordText] = useState<any>({
    keyword_text: ""
  })
  const [keywordTextStatus, setKeywordTextStatus] = useState<boolean>(false);
  const [showAlternateBidModal, setShowAlternateBidModal] = useState<any>(false);
  const [alternateBidData, setAlternateBidData] = useState<any>({
    alternate_bid: ""
  })
  const [enterlistKeywordFormErrors, setEnterlistKeywordFormErrors] = useState<any>({});
  const [alternateBidStatus, setAlternateBidStatus] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState('');
  const [negativeKeywordTextStatus, setNegativeKeywordTextStatus] = useState<boolean>(false);
  const [keywordMatchtype, setKeywordMatchtype] = useState<any>('Negative exact');
  const [addNegativeKeywordsData, setAddNegativeKeywordData] = useState<any>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  let { portfolio_id } = useParams<{ portfolio_id: any }>();
  const portfolioId: any = portfolio_id;
  let { type } = useParams<{ type: any }>();
  const campaignCreationType: any = type;
  const [campaignCreationData, setCampaignCreationData] = useState<any>({
    ad_group_name: "",
    campaign_name: "",
    budget: "",
    end_date: "",
    start_date: "",
    campaign_name_two: "",
    dynamicBidding_percentage: "0",
    dynamicBidding_percentage_product: "0",
    dynamicBidding_percentage_rest: "0",
    keyword_targeting_custom_bid: "",
    keyword_targeting_default_bid: "",
    portfolio_id: "",
    default_bid: "3",
    roas: ""
  })
  const [removeIndex, setRemoveIndex] = useState<any>();
  const [removeAllIndex, setRemoveIndexAllIndex] = useState<boolean>(false);
  const [productsData, setProductsData] = useState<any>();
  const [originalProductsData, setOriginalProductsData] = useState<any>();
  const [aiCampaignProdcutsData, setAICampaignProdcutsData] = useState<any>([]);

  const keywordTargetingBidData = [
    {
      id: 1,
      name: "Suggested bid",
      sub_title: `Based on past bidding activity to predict bids that are more likely to win`,
      value: "Suggested bid",
    },
    {
      id: 2,
      name: "Custom bid",
      sub_title: `Can custom bids for each keyword without overriding your set bid amount`,
      value: "Custom bid",
    },
    {
      id: 3,
      name: "Default bid",
      sub_title: `Can raise or lower all your set amounts in bulk`,
      value: "Default bid",
    },
  ];

  const keywordTargetingSortData = [
    {
      id: 1,
      name: "Orders",
      value: "Orders",
    },
    {
      id: 2,
      name: "Clicks",
      value: "Clicks",
    },
  ];

  const setBidsData: any = [
    {
      id: 1,
      targeting_option_data: "Close match",
    },
    {
      id: 2,
      targeting_option_data: "Loose match",
    },
    {
      id: 3,
      targeting_option_data: "Substitutes",
    },
    {
      id: 4,
      targeting_option_data: "Complements",
    },
  ];

  const accordionsData = [
    {
      id: 0,
      accordionTitle: "Watches",
    },
  ];
  const reviewStarRating = ["0", "1", "2", "3", "4", "5"];
  const [minCaption, set_minCaption] = useState("");
  const [maxCaption, set_maxCaption] = useState("");
  const userUuid = localStorage.getItem('user_uuid');
  const profileID = localStorage.getItem('profile_id');
  const sellerID = localStorage.getItem("seller_id");
  const profileType = localStorage.getItem("type");
  const currentDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    getUserPortfolios();
    getProfileProducts();
  }, [])

  useEffect(() => {
  }, [checkboxes])

  const getKeywordAIFlowData = (data: any) => {
    const aiflowAsinData: any = [];
    aiflowAsinData.push(data?.asin);
    getUserKeyWordSuggestion(aiflowAsinData, selectedKeyWordTargetingSortingValue)
  }

  const getAddedKeyWordData = (data: any) => {
    for (let i = 0; i < data?.length; i++) {
      if (data[(data?.length - 1)].bidData.bid_type === "Custom bid") {
        data[data.length - 1].bid = data[data?.length - 1]?.bidData?.bid_type === "Custom bid" ? campaignCreationData?.keyword_targeting_custom_bid ? campaignCreationData?.keyword_targeting_custom_bid : "3" : "";
        data[data.length - 1].bidData.bid_input_update_value = data[data?.length - 1]?.bidData?.bid_type === "Custom bid" ? campaignCreationData?.keyword_targeting_custom_bid ? campaignCreationData?.keyword_targeting_custom_bid : "3" : "";
      } else if (data[(data?.length - 1)].bidData.bid_type === "Default bid") {
        data[(data?.length - 1)].bid = data[(data?.length - 1)].bidData.bid_type === "Default bid" ? campaignCreationData?.keyword_targeting_default_bid ? campaignCreationData?.keyword_targeting_default_bid : "3" : "";
        data[(data?.length - 1)].bidData.bid_input_update_value = data[(data?.length - 1)].bidData.bid_type === "Default bid" ? campaignCreationData?.keyword_targeting_default_bid ? campaignCreationData?.keyword_targeting_default_bid : "3" : "";
      } else if (data[i].bidData.bid_type === "Suggested bid") {
        data[i].bid = data[i].bidData.bid ? (data[i].bidData.bid / 100).toFixed(2) : ''
        data[i].bidData.bid_input_update_value = data[i].bidData.bid ? (data[i].bidData.bid / 100).toFixed(2) : ''
      }
      data[i].bidData.bidInputValue = data[i].bidData.bid ? (data[i].bidData.bid / 100).toFixed(2) : alternateBidData?.alternate_bid ? alternateBidData?.alternate_bid : '';
    }
    setAddedKeywordSuggesstionData(data);
  }

  const getAiFlowAddedKeywordData = (data: any) => {
    if (campaignCreationType === "AI") {
      for (let i = 0; i < data?.length; i++) {
        data[i].bidData.bidInputValue = data[i].bidData.bid ? (data[i].bidData.bid / 100).toFixed(2) : alternateBidData?.alternate_bid ? alternateBidData?.alternate_bid : '';
      }
      setAddedKeywordSuggesstionData(data);
    }
  }

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setCampaignCreationData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "campaign_name") {
      setCampaignName(value);
    }

    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validateSpecificFeild(name, value),
    }));
  }

  const handleKeyWordTargetingInput = (e: any, addedKeywordData: any, ind: number) => {
    const { value } = e.target;
    const keywordData = addedKeywordSuggestionData;
    keywordData[ind].bid = value;
    setAddedKeywordSuggesstionData([...keywordData]);
  };

  const validateSpecificFeild = (name: string, value: string) => {
    if (name === "budget") {
      if (!value) {
        return "Please enter daily budget";
      }
      else if (parseInt(value) < 50) {
        return "Daily budget should be a minimum of 50.";
      }
    }

    if (name === "roas") {
      if (!value) {
        return "Please enter roas";
      }
      else if (parseInt(value) < 2) {
        return "Roas should be a minimum of 2.";
      }
    }

    if (name === "dynamicBidding_percentage") {
      if (!value) {
        return "Please enter top of search value"
      } else if (parseInt(value) < 0) {
        return "Top of search value should be minimum 0."
      }
    }

    if (name === "dynamicBidding_percentage_product") {
      if (!value) {
        return "Please enter product pages value"
      } else if (parseInt(value) < 0) {
        return "Product pages value should be minimum 0."
      }
    }

    if (name === "dynamicBidding_percentage_rest") {
      if (!value) {
        return "Please enter rest of search value"
      } else if (parseInt(value) < 0) {
        return "Rest of search value should be minimum 0."
      }
    }

    if (name === "keyword_targeting_custom_bid") {
      if (!value) {
        return "Please enter custom bid value"
      } else if (parseInt(value) <= 0) {
        return "Custom bid should be minimum 1."
      }
    }

    if (name === "keyword_targeting_default_bid") {
      if (!value) {
        return "Please enter default bid value"
      } else if (parseInt(value) <= 0) {
        return "Default bid should be minimum 1."
      }
    }
    return "";
  };

  const validateForm = () => {
    const errors: any = {};
    if (!campaignCreationData.budget) {
      errors.budget = "Please enter daily budget";
    }

    if (!campaignCreationData.ad_group_name) {
      errors.ad_group_name = "Please enter ad group name";
    }

    if (!campaignCreationData.campaign_name) {
      errors.campaign_name = "Please enter campaign name";
    }

    if (selectedKeyWordTargetingBidValue === "Default bid") {
      if (!campaignCreationData?.default_bid) {
        errors.default_bid = "Please enter a bid ";
      }
    }

    if (!campaignCreationData.start_date) {
      errors.start_date = "Please select start date";
    }

    if (campaignCreationData.dynamicBidding_percentage < 0 || campaignCreationData.dynamicBidding_percentage === "") {
      errors.dynamicBidding_percentage = "Please enter top of search value";
    }

    if (campaignCreationData.dynamicBidding_percentage_product < 0 || campaignCreationData.dynamicBidding_percentage_product === "") {
      errors.dynamicBidding_percentage_product = "Please enter product pages value";
    }

    if (campaignCreationData.dynamicBidding_percentage_rest < 0 || campaignCreationData.dynamicBidding_percentage_rest === "") {
      errors.dynamicBidding_percentage_rest = "Please enter rest of search value";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onClickCancel = () => {
    setShowLeavePageModal(true);
  };

  const getUserPortfolios = () => {
    const preparedData: any = {
      user_uuid: userUuid,
      profile_id: profileID,
      is_report_data: false
    }
    setLoading(true);
    GET_USER_PORTFOLIOS(preparedData).then((res) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
        setLoading(false);
      } else {
        if (res) {
          const response = res.data.data;
          const portfoliosList = [];
          for (let index = 0; index < response?.length; index++) {
            const element = response[index];
            portfoliosList.push({
              title: element?.portfolio_name,
              value: element?.portfolio_id
            })
          }
          setPortfoliosData(portfoliosList)
          setLoading(false);
        }
      }
    });
  }

  const getProfileProducts = () => {
    const preparedData: any = {
      user_uuid: userUuid,
      profile_id: profileType === "vendor" ? sellerID : profileID
    }

    setLoading(true);
    GET_PROFILE_PRODUCTS(preparedData).then((res) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
        setLoading(false);
      } else {
        if (res) {
          const response = res.data.data;
          setOriginalProductsData(response)
          setProductsData(response);
          if (portfolioId) {
            const res: any = response;
            const filteredProducts = res.filter((product: any) => {
              return product.availability !== "OUT_OF_STOCK";
            });

            if (filteredProducts && campaignCreationType === "AI") {
              const aiflowAsinData: any = [];
              aiflowAsinData.push(filteredProducts[0]?.asin);
              getUserKeyWordSuggestion(aiflowAsinData, selectedKeyWordTargetingSortingValue)
            }
            setAICampaignProdcutsData(filteredProducts);
          }
          setLoading(false);
        }
      }
    });
  }

  const getUserKeyWordSuggestion = (asindata: any, selcetdOption: any) => {
    const preparedData: any = {
      user_uuid: userUuid,
      profile_id: profileID,
      product_asin: asindata ? asindata : [],
      bidding_strategy: campaignBidStrategyData,
      sortDimension: selcetdOption === "Orders" ? "CONVERSIONS" : "CLICKS"
    }
    setLoading(false);
    setKeywordsLoading(true);
    GET_KEYWORD_SUGGESTIONS(preparedData).then((res) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
        setLoading(false);
        setKeywordsLoading(false);
      } else {
        if (res) {
          const response = res.data.data;
          setOriginalKeywordSuggestionsData(response);
          setKeywordSuggestionsData(response);
          setLoading(false);
          setKeywordsLoading(false);
        }
      }
    });
  }

  const onLeavePage = () => {
    setShowLeavePageModal(false);
    navigate("/dashboard/campaign-type");
  }

  const handleSearchInput = (e: any) => {
    const searchTerm = e.target.value;
    for (let i = 0; i < originalProductsData?.length; i++) {
      originalProductsData[i].dumy_title = originalProductsData[i].title.toLowerCase();
      originalProductsData[i].dumy_asin = originalProductsData[i].asin.toLowerCase();
      originalProductsData[i].dumy_sku = originalProductsData[i].sku;
    }
    const searchData = originalProductsData?.filter((el: any) => el?.dumy_title.includes(searchTerm.toLowerCase()) || el?.dumy_asin.includes(searchTerm.toLowerCase()) || el?.dumy_sku.includes(searchTerm));
    setProductsData(searchData)
    setSearch(searchTerm);
    if (searchTerm?.trim()?.length > 0) {
      setShowWrongIcon(true)
    } else if (searchTerm?.trim()?.length <= 0) {
      getProfileProducts();
      setProductsData(productsData)
      setShowWrongIcon(false)
    }
  };

  const onClickWrong = () => {
    setSearch("");
    getProfileProducts();
    setShowWrongIcon(false);
  };

  const handleQuotationFileUpload = (e: any) => {
    const file = e.target.files[0];
  };

  const handleChangeTargeting = (option: any, event: any) => {
    setTargetingOption(option);
    if (option === "manual") {
      setShowManualTargeting(true);
    } else {
      setShowManualTargeting(false);
    }
  };

  const handleChangeManualTargeting = (option: any) => {
    setManualTargetingOption(option);
    if (option === "product") {
      setShowManualKeywordTargeting(true);
      setShowManualProductTargeting(true);
    } else {
      setShowManualKeywordTargeting(false);
    }
  };

  const onChangeAutomaticTargeting = (option: any) => {
    setSetDefaultBid(option);
    if (option === "Set default bid") {
      setShowSetDefaultBid(true);
    } else {
      setShowSetDefaultBid(false);
    }
  };

  const noDataMessage = () => {
    return (
      <div className="d-flex flex-column justify-content-center my-1">
        <div className="fs-12 fw-bold">No related keywords</div>
        <div className="fs-12 ">Try entering your own keywords</div>
      </div>
    );
  };

  const onCloseTooltip = (status: any) => {
    if (status) {
      setTooltipClose(true);
    }
  };

  const handleClose = () => {
    const tooltipStatus: any = tooltipClose;
    return tooltipStatus;
  };

  const onClickLearnMoreNavigation = (e: any) => {
    setShowhowCreateAdgroupPopup(true);
  }

  const tooltipContent = (tooltipContent: any) => {
    if (tooltipContent === "Ad group name") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Ad groups are a way to organise and manage ads within a campaign."
          }
          tooltipSubContent={"Learn about ad groups"}
          tooltipCloseStatus={onCloseTooltip}
          onClickLearnMoreText={(e) => onClickLearnMoreNavigation(e)}
        />
      );
    } else if (tooltipContent === "Products") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Add products that you want to promote in this campaign."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Targeting") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Targeting uses keywords and products to show your ads on search and detail pages to relevant shoppers. There are 2 targeting strategies available in Sponsored Products campaigns: automatic and manual."
          }
          tooltipSubContent={"Learn about targeting strategies"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Automatic Targeting") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Automatic Targeting uses keywords and products to help your ads appear in search and detail pages."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Set default bid") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Default bid applies to all clicks unless you set a different bid for a keyword."
          }
          tooltipSubContent={"Learn about default bids"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Set bids by targeting group") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Targeting groups use multiple strategies to match your ads to shoppers looking for your products."
          }
          tooltipSubContent={"Learn about bids by targeting group"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "TARGETING GROUPS") {
      return (
        <TooltipPage
          tooltipMainContent={
            "You can choose different bids for groups of search terms and products matched with the product in your ad."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "SUGGESTED BID") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Suggested bids are calculated daily based on past bidding activity for ads similar to yours to predict bids that are more likely to win."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "BID") {
      return (
        <TooltipPage
          tooltipMainContent={
            "The maximum amount you will pay for a click when this target triggers your ad."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Close match") {
      return (
        <TooltipPage
          tooltipMainContent={
            'We will show your ad to shoppers who use search terms closely related to your products. If your product is "Doppler 400-count Cotton Sheets," we will show an ad when shoppers use search terms like "cotton sheets" and "400-count sheets." '
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Loose match") {
      return (
        <TooltipPage
          tooltipMainContent={
            'We will show your ad to shoppers who use search terms loosely related to your products. If your product is Doppler 400-count Cotton Sheets, we will show an ad when shoppers use search terms like "bed sheets," "bath sheets" and "bath towels."'
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Substitutes") {
      return (
        <TooltipPage
          tooltipMainContent={
            'We will show your ad to shoppers who use detail pages of products similar to yours. If your product is "Doppler 400-count Cotton Sheets," we will show an ad on detail pages that include "300-count Cotton sheets" and "queen 400-count Sheets."'
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Complements") {
      return (
        <TooltipPage
          tooltipMainContent={
            'We will show your ad to shoppers who view the detail pages of products that complement your product. If your product is "Doppler 400-count Cotton sheets," we will show an ad on detail pages that include "queen comforter" and "feather pillows."'
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Manual Targeting") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Targeting uses keywords and products to help your ads appear in search and detail pages."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Keyword targeting") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Keyword targeting allows you to choose keywords to show your products in customer searches and detail pages."
          }
          tooltipSubContent={"Learn about keyword targeting"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Product targeting") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Product targeting allows you to choose specific products, categories, brands, or other product features that are relevant to the product in your ad."
          }
          tooltipSubContent={"Learn about product targeting"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Keyword targeting card header") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Your keywords (word combinations and phrases) are used to match your ads with search terms shoppers are using to find products."
          }
          tooltipMainContentCaption={"See keyword targeting best practices"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "keyword suggested tab") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Related keywords are based on the products in your ads. You can add the keywords that you want and edit keywords and bids after you add them."
          }
          tooltipSubContent={"Learn about Suggested keywords"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "keyword suggested tab Bid") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Your bid is the cost per click you are willing to pay when a customer clicks an ad from the ad group or campaign."
          }
          tooltipSubContent={"Learn about bids"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "keyword suggested tab Filter by") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Match types allow you to fine-tune which customer search terms trigger your ads."
          }
          tooltipSubContent={"Learn about match types"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "keyword suggested tab Sort By") {
      return (
        <TooltipPage
          sortOrders={"Number of orders the keyword has historically generated"}
          sortClicks={"Number of clicks the keyword has historically generated"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "keyword enterlist tab Bid") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Your bid is the cost per click you are willing to pay when a customer clicks an ad from the ad group or campaign."
          }
          tooltipSubContent={"Learn about bids"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "keyword enterlist tab Match type") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Match types allow you to fine-tune which customer search terms trigger your ads."
          }
          tooltipSubContent={"Learn about match types"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent === "negative Keyword targeting card header optional"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={
            "Negative keywords prevent your ads from displaying when a shopper's search terms match your negative keywords. You can exclude irrelevant searches, reducing your advertising cost."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "negative Keyword targeting Match type") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Negative keyword match types allow you to fine-tune which customer search terms trigger your ads."
          }
          tooltipSubContent={"Learn about negative match types"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent === "negative Keyword targeting table Match type"
    ) {
      return (
        <TooltipPage
          keyWordBroad={
            " Contains all the keywords in any order and includes plurals, variations and related keywords."
          }
          keyWordPhrase={" Contains the exact phrase or sequence of keywords."}
          keyWordExact={"Exactly matches the keyword or sequence of keywords"}
          tooltipLearnMoreText={"Learn More"}
          keyWordTableText={
            "Match types allow you to fine-tune which customer searches trigger your ads."
          }
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Campaign bidding strategy") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Choose how you want to pay for clicks on your ads."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Dynamic bids - up and down") {
      return (
        <TooltipPage
          tooltipMainContent={
            "We’ll raise your bids in real time when your ad may be more likely to convert to a sale, and lower your bids when less likely to convert to a sale."
          }
          tooltipSubContent={"Learn about dynamic bids - up and down"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Dynamic bids - down only") {
      return (
        <TooltipPage
          tooltipMainContent={
            "We will lower your bids in real time when your ad may be less likely to convert to a sale."
          }
          tooltipSubContent={"Learn about dynamic bids - down only"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Fixed bids") {
      return (
        <TooltipPage
          tooltipMainContent={
            "We will use your exact bid and any manual adjustments you set and will not change your bids based on likelihood of a sale."
          }
          tooltipSubContent={"Learn about fixed bids"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Adjust bids by placement") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Apply different bids by placement by entering a percentage increase to your base bid for 2 placements: top of search (first page) and product pages."
          }
          tooltipSubContent={"Learn about bids by placement"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Settings Campaign name") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Choose a campaign name that you can easily identify and refer to later. The campaign name is only visible to you."
          }
          tooltipSubContent={"Learn about campaign names"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Settings Portfolio") {
      return (
        <TooltipPage
          tooltipMainContent={"Portfolios are a group of campaigns that you can organise to meet your advertising needs."}
          tooltipSubContent={"Learn about portfolios"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Settings Start") {
      return (
        <TooltipPage
          tooltipMainContent={"The date your campaign will start."}
          tooltipSubContent={"Learn about start and end date"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Settings End") {
      return (
        <TooltipPage
          tooltipMainContent={"The date your campaign will end."}
          tooltipSubContent={"Learn about start and end date"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Daily budget") {
      return (
        <TooltipPage
          tooltipMainContent={
            "The amount that you are willing to spend on this campaign each day. If the campaign spends less than your daily budget, the unspent amount can be used to increase your daily budget on the other days of the calendar month."
          }
          tooltipSubContent={"Learn about daily budget"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Product targeting card header") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Help shoppers find your product by choosing categories, products, brands or features related to your product. "
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Product targeting Categories tab") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Categories are products that are grouped by similarities like "
          }
          tooltipWomensItalicText={`women's running shoes`}
          tooltipSubContent={"Learn about product targeting by category"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipContent === "Product targeting Individual Products tab") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Target individual products that are similar to products in your ad."
          }
          tooltipSubContent={
            "Learn about product targeting by individual products"
          }
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent === "Product targeting categories tab suggested"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={
            "Suggested categories are based on the products in your ads. You can add categories and individual products that you want, and edit products, categories and bids after they are added."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent === "Product targeting individual products tab suggested"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={
            "Suggested products based on products in your ad."
          }
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent === "manual targeting Negative product targeting optional"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={`Negative product targeting prevents your ads from displaying when a shopper's search matches your negative product selections. This helps exclude irrelevant searches, reducing your advertising cost.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent ===
      "manual targeting Negative product targeting optional exlude brands tab"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={`An advanced strategy that prevents your ads from displaying in specific search results or detail pages. Tip: Lower your bid for specific brands instead of excluding.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent ===
      "manual targeting Negative product targeting optional exlude products tab"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={`An advanced strategy that prevents your ads from displaying in specific search results or detail pages. Tip: Lower your bid for specific brands instead of excluding.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent ===
      "automatic targeting Negative product targeting optional"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={`Negative product targeting prevents your ads from displaying when a shopper's search matches your negative product selections. This helps exclude irrelevant searches, reducing your advertising cost.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent ===
      "stock-info"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={`Out of stock products will not usually appear in ads. Products without a featured offer are also considered out of stock. ASINs with regionally fulfilled offers can still be added to campaigns within this tool, although the in-stock status might be incorrectly listed as 'Out of Stock.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent ===
      "add-variation"
    ) {
      return (
        <TooltipPage
          tooltipMainContent={`Add all eligible size, colour or other differences in this product.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (
      tooltipContent ===
      "ad-paused"
    ) {
      return (
        <TooltipPage
          tooltipHeader={`Ad may be paused`}
          tooltipMainContent={`Offer must be available for purchase. Being out of stock is a common reason an offer is not available for purchase. Review this information in your product inventory.`}
          tooltipSubContent={""}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    }
  };

  const onChangeCampaignBiddingType = (campaignBidText: string) => {
    setCampaignBiddingStrategy(campaignBidText);
    if (campaignBidText === "Dynamic bids - up and down") {
      setShowDynamicCampaignBid(true);
      setCampaignBidStrategyData("AUTO_FOR_SALES")
    } else if (campaignBidText === "Dynamic bids - down only") {
      setShowDynamicCampaignBid(false);
      setCampaignBidStrategyData("LEGACY_FOR_SALES")
    } else if (campaignBidText === "Fixed bids") {
      setShowDynamicCampaignBid(false);
      setCampaignBidStrategyData("MANUAL")
    }
  };


  const onClickAddNegativeKeywords = () => {
    const splitData = negativeKeywordText.split('\n').filter((line: any) => line.trim() !== '');
    splitData.forEach((element: any) => {
      const data = {
        negative_keyword_matchType: keywordMatchtype === "Negative exact" ? "NEGATIVE_EXACT" : "NEGATIVE_PHRASE",
        negative_keyword_text: element, //negativeKeywordText,
        state: "ENABLED"
      }
      addNegativeKeywordsData.push(data);
    })
    setSuccessMessage('1/1 keywords were added successfully.');
    setNegativeKeywordText({
      ...negativeKeywordText,
      negative_keyword_text: ""
    });
    setNegativeKeywordTextStatus(false);
  }

  const onClickCancelNegtaiveKeywordTargeting = (item: any, index: number) => {
    const preparedData = addNegativeKeywordsData;
    preparedData.splice(index, 1);
    setAddNegativeKeywordData([...preparedData])
  }

  const getCategoriesTooltipText = (data: any) => {
    if (data) {
      tooltipContent(data);
    }
  };

  const handleChangeAccordion = (e: any, index: number) => {
    const data = accordionsData;
    if (index === data[0]?.id) {
      setExpanded(!expanded);
    }
  };

  const handleClickAdd = (e: any, data: any) => {
    setShowAddModal(true);
  };

  const handleClickRefine = (e: any, data: any) => {
    setShowRefineModal(true);
  };

  const handleKeywordTargetingBidTextChange = (option: string) => {
    setSelectedKeywordTargetingBidValue(option);
  };

  const handleKeywordTargetingSortingChange = (option: string) => {
    getUserKeyWordSuggestion(addedProductsAsinData, option);
    setSelectedKeywordTargetingSortingValue(option);
  };

  const handleProductTargetingBidTextChange = (option: string) => {
    setSelectedProductTargetingBidValue(option);
  };

  const handleTargetingGroupsStatus = (e: any, id: number, index: number) => {
    const bidsData = setBidsData;
    const data = id;
    const filterData = bidsData.filter((el: any) => el.id === data);
    if (filterData.id) {
      const status = e.target.checked ? true : false;
      setTargetingGroupsStatus(status);
    }
  };

  const onClickGobacktoSettings = () => {
    navigate("/dashboard/home/campaigns");
  };

  const onClickLearnMore = (data: any) => {
    if (data === "ad-groups") {
      const link = "https://advertising.amazon.in/help?entityId=ENTITY2G8T90BNYY5QX#GKPA6T8WW3AYKV4Q"
      window.open(link, "_blank")
    } else if (data === "campaign-bidding-strategy") {
      const link = "https://advertising.amazon.in/help/GCU2BUWJH2W3A8Z7";
      window.open(link, "_blank");
    }
  }

  const onClickShowAdgroupModal = () => {
    setShowhowCreateAdgroupPopup(true);
  }
  const getModalStatus = (status: any) => {
    if (status) {
      setShowhowCreateAdgroupPopup(false);
    }
  };

  const onClickCancelKeywordSuggestion = (e: any, item: any, itemIndex: number) => {
    setRemoveIndex(item);
    const keywordsData = addedKeywordSuggestionData;
    keywordsData.splice(itemIndex, 1);

    setAddedKeywordSuggesstionData([...keywordsData]);
  }

  const handleCheckboxChange = (checkboxName: any) => {
    let checkesData: any = []
    setCheckboxes((prevCheckboxes: any) => {
      const updatedCheckboxes = {
        ...prevCheckboxes,
        [checkboxName]: !prevCheckboxes[checkboxName],
      };
      checkesData = Object.keys(updatedCheckboxes).filter(key => updatedCheckboxes[key]);

      let newChecksData = checkesData.map((str: any) => str.toUpperCase());
      const updatedData = originalKeywordSuggestionsData?.map((keywordData: any) => {
        const updatedBidInfo = keywordData.bidInfo.filter((bid: any) => newChecksData.includes(bid.matchType));
        if (updatedBidInfo?.length > 0) {
          return {
            ...keywordData,
            bidInfo: updatedBidInfo,
          };
        }
      });
      setKeywordsLoading(true);
      setTimeout(() => {
        setKeywordSuggestionsData(checkesData?.length > 0 ? updatedData : [])
        setKeywordsLoading(false);
      }, 1);
      return updatedCheckboxes;
    });
  };


  const onClickAddProduct = async (item: any, index: number) => {
    const checkEligibilityData: any = {
      user_uuid: userUuid,
      profile_id: profileID,
      asin: item?.asin
    }
    // setLoading(true);
    // CHECK_PRODUCT_ELIGIBILITY(checkEligibilityData).then((res) => {
    //   if (res.data.status === "error" || res?.data?.code === 500) {
    //     toast.error(res.data.message);
    //     setLoading(false);
    //   } else {
    //     if (res) {
    //       if (res.data.code === 200) {
    const productData = productsData;
    productData[index].product_btn_status = true;
    setProductsData([...productData]);
    const preparedData = addProductsData;
    preparedData.push(item);
    addedProductsAsinData.push(item?.asin);
    const asinSkuData = addedProductsAsinSkuData;
    asinSkuData.push(
      {
        state: "ENABLED",
        sku: item?.sku,
        asin: item?.asin
      })
    setAddedProductsAsinSkuData([...asinSkuData])
    setAddedProductsAsinData([...addedProductsAsinData])
    setAddproductsData([...preparedData]);
    if (addedProductsAsinData?.length > 0) {
      getUserKeyWordSuggestion(addedProductsAsinData, selectedKeyWordTargetingSortingValue);
    }
    // }
    //       toast.success(res.data.message);
    //       setLoading(false);
    //     }
    //   }
    // });
  }

  const onClickCancelProduct = (item: any, index: number) => {
    const productData = productsData;
    for (let index = 0; index < productData?.length; index++) {
      if (productData[index].uuid === item.uuid) {
        productData[index].product_btn_status = false;
      }
    }
    setProductsData([...productData]);
    const preparedData = addProductsData;
    const preparedAiCampaignData = aiCampaignProdcutsData;
    const preparedAddedAsinSkuData = addedProductsAsinSkuData;
    preparedData.splice(index, 1);
    preparedAiCampaignData.splice(index, 1);
    setAICampaignProdcutsData([...preparedAiCampaignData]);
    addedProductsAsinData.splice(index, 1)
    setAddproductsData([...preparedData]);
    preparedAddedAsinSkuData?.splice(index, 1);
    setAddedProductsAsinSkuData([...addedProductsAsinSkuData]);
    setAddedProductsAsinData([...addedProductsAsinData]);
    if (addedProductsAsinData?.length > 0) {
      getUserKeyWordSuggestion(addedProductsAsinData, selectedKeyWordTargetingSortingValue);
    } else {
      if (addedProductsAsinData?.length === 0 || addProductsData?.length === 0) {
        setTimeout(() => {
          toast.error("Please add atleast one product", { autoClose: 2000 })
          setProdcutsAddedFinalStep(false);
          setOriginalKeywordSuggestionsData([]);
          setKeywordSuggestionsData([]);
          setAddedKeywordSuggesstionData([]);
        }, 1000);
      }
    }
  }

  const onClickRemoveNegativeKeywordsData = () => {
    setAddNegativeKeywordData([]);
    setSuccessMessage('');
    setNegativeKeywordText("");
    setNegativeKeywordTextStatus(false);
  }

  const handleChangeNegativeKeywordTargetingMatchType = (selectedData: any) => {
    setKeywordMatchtype(selectedData);
  }
  const onClickRemoveAllKeywordSuggestionData = () => {
    setRemoveIndexAllIndex(true)
    setAddedKeywordSuggesstionData([]);
  }

  const handleChangeNegativeKeywordText = (e: any) => {
    const { name, value } = e.target;
    setNegativeKeywordText((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const data = e.target.value;
    if (data?.trim()?.length > 0) {
      setNegativeKeywordTextStatus(true);
    } else {
      setNegativeKeywordTextStatus(false);
    }
    setNegativeKeywordText(data)
  }


  const getAddAllDataStatus = (status: any) => {
    const preparedData = [];
    if (status) {
      for (let i = 0; i < originalKeywordSuggestionsData?.length; i++) {
        for (let j = 0; j < originalKeywordSuggestionsData[i].bidInfo?.length; j++) {
          preparedData.push({
            bid: originalKeywordSuggestionsData[i].bidInfo[j].bid ? (originalKeywordSuggestionsData[i].bidInfo[j].bid / 100).toFixed(2) : '',
            bidData: {
              bid: originalKeywordSuggestionsData[i].bidInfo[j].bid,
              bidInputValue: originalKeywordSuggestionsData[i].bidInfo[j].bid ? (originalKeywordSuggestionsData[i].bidInfo[j].bid / 100).toFixed(2) : '',
              btn_status: true,
              matchType: originalKeywordSuggestionsData[i].bidInfo[j].matchType,
              rank: originalKeywordSuggestionsData[i].bidInfo[j].rank,
              suggestedBid: originalKeywordSuggestionsData[i].bidInfo[j].suggestedBid,
              theme: originalKeywordSuggestionsData[i].bidInfo[j].theme,
            },
            keywordText: originalKeywordSuggestionsData[i].keyword,
            keyword_matchType: originalKeywordSuggestionsData[i].bidInfo[j].matchType,
            keyword_state: "ENABLED",
            product_uuid: "",
            recId: originalKeywordSuggestionsData[i].recId,
            suggestionData: originalKeywordSuggestionsData[i].keyword
          })
        }
      }
      setAddedKeywordSuggesstionData([...preparedData]);
      setAllkeywordTargeting(true);
    }
  }

  const onClickLanuchCampaign = () => {
    let addedKeywords: any = [];
    if (addedKeywordSuggestionData?.length > 0) {
      for (let index = 0; index < addedKeywordSuggestionData?.length; index++) {
        const element = addedKeywordSuggestionData[index];
        addedKeywords.push(
          {
            keyword_matchType: element?.bidData?.matchType,
            keyword_state: 'ENABLED',
            bid_type: element?.bidData?.bid_type,
            bid_input_update_value: element?.bidData?.bid_input_update_value,
            bid: element?.bid,
            keywordText: element?.suggestionData
          }
        )
      }
    }


    const data: any = addedKeywords
    for (let i = 0; i < data.length; i++) {
      data[i].bid = data[i].bid;
      data[i].bid_input_update_value = data[i].bid;
    }


    const isValid: any = validateForm();
    if (addedProductsAsinSkuData?.length > 0) {
      setProdcutsAddedFinalStep(true);
    } else {
      toast.error("Please add atleast one product", { autoClose: 2000 })
      setProdcutsAddedFinalStep(false);
    }

    if (addedKeywords?.length > 0) {
      setKeywordsFinalStep(true);
    } else {
      toast.error("Please add atleast one keyword", { autoClose: 2000 })
      setKeywordsFinalStep(false);
    }

    if (isValid && prodcutsAddedFinalStep && keywordsFinalStep) {
      const preapredData: any = {
        user_uuid: userUuid,
        profile_id: profileID,
        portfolio_id: portfolioId ? portfolioId : campaignCreationData?.portfolio_id,//selectedPortfolio?.toString(),
        end_date: campaignCreationData?.end_date,
        campaign_name: campaignName ? `adv_SPM_${campaignName}` : "",
        targeting_type: "MANUAL",
        state: "PAUSED",
        budget_type: "DAILY",
        budget: campaignCreationData?.budget,
        start_date: campaignCreationData?.start_date,
        dynamicBidding_percentage: campaignCreationData?.dynamicBidding_percentage,
        dynamicBidding_percentage_product: campaignCreationData?.dynamicBidding_percentage_product,
        dynamicBidding_percentage_rest: campaignCreationData?.dynamicBidding_percentage_rest,
        dynamicBidding_placement: "PLACEMENT_TOP",
        dynamicBidding_placement_product: "PLACEMENT_PRODUCT_PAGE",
        dynamicBidding_placement_rest: "PLACEMENT_REST_OF_SEARCH",
        dynamicBidding_strategy: campaignBidStrategyData ? campaignBidStrategyData : "LEGACY_FOR_SALES",
        ad_group_name: campaignCreationData?.ad_group_name,
        default_bid: selectedKeyWordTargetingBidValue === "Default bid" ? campaignCreationData?.keyword_targeting_default_bid ? campaignCreationData?.keyword_targeting_default_bid : "3" : selectedKeyWordTargetingBidValue === "Custom bid" ? campaignCreationData?.keyword_targeting_custom_bid ? campaignCreationData?.keyword_targeting_custom_bid : "3" : selectedKeyWordTargetingBidValue === "Suggested bid" ? "3" : "3",
        ad_group_state: "ENABLED",
        keywords: addedKeywords,
        negative_keywords: addNegativeKeywordsData,
        product_ads: addedProductsAsinSkuData,
        roas: campaignCreationData?.roas ? campaignCreationData?.roas : "2",
        benchmark: campaignCreationData?.benchmark ? campaignCreationData?.benchmark : "2",
        qualifier: campaignCreationData?.qualifier ? campaignCreationData?.qualifier : "3",
        duration: campaignCreationData?.duration ? campaignCreationData?.duration : ""
      }
      setCampaignLoading(true);

      console.log('preapredData', preapredData);

      CREATE_CAMPAIGN(preapredData).then((res) => {
        if (res.data.status === "error" || res?.data?.code === 500) {
          toast.error(res.data.message);
          setCampaignLoading(false);
        } else {
          if (res) {
            const response = res.data.data;
            toast.success(res.data.message);
            setCampaignLoading(false);
            onClickGobacktoSettings();
          }
        }
      });
    }
  }
  const onAddKeywordEnterList = () => {
    setAlternateBidData({ alternate_bid: '' });
    setShowAlternateBidModal(true);
  }

  const handleEnterListKeywordInput = (e: any) => {
    const { name, value } = e.target;
    setManualEnterListKeywordText((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const data = e.target.value;
    if (data?.trim()?.length > 0) {
      setKeywordTextStatus(true);
    } else {
      setKeywordTextStatus(false);
    }
  }

  const handleAlternateInput = (e: any) => {
    const { name, value } = e.target;
    setAlternateBidData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const data = e.target.value;
    setEnterlistKeywordFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validateEnterListSpecificFeild(name, value),
    }));
  }

  const validateEnterListSpecificFeild = (name: any, value: any) => {
    if (name === "alternate_bid") {
      if (!value) {
        return "Please enter bid value"
      } else if (value <= 0) {
        return "Bid value should be minimum 1."
      }
    }
  }

  const validateAlternateBidForm = () => {
    const errors: any = {};
    if (!alternateBidData.alternate_bid) {
      errors.alternate_bid = "Please enter bid value"
    }
    setEnterlistKeywordFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onAddAlternateEnterListBid = () => {
    const isValid = validateAlternateBidForm();
    if (isValid) {
      const existingAddedKeywordSuggesstionData = addedKeywordSuggestionData;
      setAlternateBidStatus(true)
      const splitData = manualEnterListKeywordText?.keyword_text.split('\n').filter((line: any) => line.trim() !== '');
      setSelectedKeywordTargetingBidValue('AlternateBid');
      for (const property in externalListCheckboxes) {
        if (externalListCheckboxes[property]) {
          splitData.forEach((element: any) => {
            existingAddedKeywordSuggesstionData.push({
              bidData: {
                matchType: property.toUpperCase(),
                bid: alternateBidData?.alternate_bid,
              },
              bid: alternateBidData?.alternate_bid,
              suggestionData: element
            })
          });
          // existingAddedKeywordSuggesstionData.push({
          //   bidData: {
          //     matchType: property.toUpperCase(),
          //     bid: alternateBidData?.alternate_bid,
          //   },
          //   bid: alternateBidData?.alternate_bid,
          //   suggestionData: manualEnterListKeywordText?.keyword_text
          // })
        }
      }
      setAddedKeywordSuggesstionData([...existingAddedKeywordSuggesstionData]);
      setSuccessMessage('3/3 keywords were added successfully.');
      setManualEnterListKeywordText({ ...manualEnterListKeywordText, keyword_text: "" });
      setShowAlternateBidModal(false);
      setKeywordTextStatus(true);
      if (props?.altenativeBidKeywordsData) {
        props?.altenativeBidKeywordsData(existingAddedKeywordSuggesstionData)
      }
      if (props?.alternateBidStatus) {
        props?.alternateBidStatus(alternateBidStatus)
      }
    }
  }
  const handleEnterlistCheckBoxChange = (e: any) => {
    const data = externalListCheckboxes;
    data[e.target.name] = e.target.checked;
    setExternalListCheckboxes({ ...data });
  }

  const onSelectKeywordType = () => {
    setSelectedKeywordTargetingBidValue("Suggested bid");
  }

  return (
    <div className="container-fluid campaign-creation-bg">
      <div className="row fixed-top">
        <div className="col-12 px-0 ">
          <nav className="navbar navbar-expand-lg bg-white shadow py-3">
            <div className="container-fluid px-4">
              <div className="d-flex">
                <div className="bg-transaprent border-0 me-3">
                  <img
                    src={WrongIcon}
                    className="mt-1 cursor-pointer"
                    alt=""
                    onClick={onClickCancel}
                  />
                </div>
                <h2 className="navbar-brand campaign-navbar fs-18">
                  New campaign{" "}
                </h2>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div>
                    <div
                      className="custom-explore-text-color fs-13 logout cursor-pointer py-2"
                      onClick={onClickGobacktoSettings}
                    >
                      Go back to campaigns
                    </div>
                  </div>
                  <div className="mx-2">
                    <button className="request-call-button text-white fs-13" onClick={onClickLanuchCampaign}>
                      Launch Campaign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 mx-auto mt-5 campaign-creation-form-container">
          {campaignLoading &&
            <div className="text-center p-5 camapign-creation-loader-position">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          }
          {!campaignLoading &&
            <div className="row mt-5 mb-2">
              <div className="col-12 card-width mx-auto ">
                <h4 className="my-2 fs-28">Ad Groups</h4>
              </div>
              <div className="col-12 card-width mx-auto">
                <div className=" fs-13 mb-3">
                  Ad groups are groups of ads within a campaign that share the
                  same set of targeting tactics or creative type. They can help
                  you organise your campaigns more efficiently. Consider grouping
                  products that fall within the same category and price range. You
                  can create additional ad groups in campaign manager after you
                  launch your campaign.
                  <span className="learn-more-text logout cursor-pointer" onClick={() => onClickLearnMore('ad-groups')}>
                    Learn more
                  </span>
                </div>
              </div>
              {/* Ad Groups Settings Card Starts  */}
              <div className="ad-group-settings-card-container card-width mx-auto">
                <div className="card text-bg-white ad-group-settings mb-3 my-2">
                  <div className="card-header bg-white">
                    <div className="d-flex justify-content-between py-2">
                      <div className="fs-24 fw-light">Ad group settings</div>
                      <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                        <span>
                          <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                        </span>
                        <span className="" onClick={onClickShowAdgroupModal}>
                          How to create an ad group
                        </span>
                      </div>
                      {showCreateAdgroupPopup && (
                        <GetHelpCardModal
                          ismodalShow={showCreateAdgroupPopup}
                          modalStatus={getModalStatus} />
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title fs-14 fw-700">
                      Ad group name{" "}
                      <span className="ms-1">
                        <Tooltip
                          title={tooltipContent("Ad group name")}
                          placement="top"
                          arrow
                          className="custom-tooltip-position cursor-pointer"
                          onClick={handleClose}
                        >
                          <span className="cursor-pointer">
                            <svg
                              viewBox="0 0 512 512"
                              width="13"
                              height="13"
                              className="fas fa-info-circle"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="#8290a4"
                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                              ></path>
                            </svg>
                          </span>
                        </Tooltip>
                      </span>
                    </h5>
                    <div className="col-4 pe-3">
                      <input
                        name="ad_group_name"
                        className="form-control input border-dark fs-13"
                        placeholder="Ad group name"
                        defaultValue={campaignCreationData?.ad_group_name ? campaignCreationData?.ad_group_name : ""} onChange={(e) => handleInput(e)}
                      />
                      {formErrors.ad_group_name && (
                        <div className="text-danger text-start w-100">
                          {formErrors.ad_group_name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Ad Groups Settings Card Ends  */}

              {/* {campaigns Details Card Starts} */}
              {campaignCreationType === "AI" && portfolioId && (
                <div className="ad-group-settings-card-container card-width mx-auto">
                  <div className="card text-bg-white ad-group-settings mb-3 my-2">
                    <div className="card-header bg-white">
                      <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">Campaign details</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3 col-md-3 campaign-name-width">
                          <label className="form-label">Budget</label>
                          <div className="input-group">
                            <span className="input-group-text budget-input-group bg-white border-dark">
                              <img src={RupeeIcon} alt="" className="" />
                            </span>
                            <input type="number" name="budget" defaultValue={''} min={50} className="form-control budget-input-group border-dark border-start-0"
                              onChange={(e) => handleInput(e)} />
                          </div>
                          {formErrors.budget && (
                            <div className="text-danger text-start w-100">
                              {formErrors.budget}
                            </div>)}
                        </div>
                        <div className="col-md-3">
                          <div className="campaign-name-width">
                            <label className="form-label">Start date</label>
                            <input
                              type="date"
                              name="start_date"
                              min={currentDate}
                              className="form-control input border-dark fs-13"
                              defaultValue=""
                              onChange={(e) => handleInput(e)}
                            />
                            {formErrors.start_date && (<div className="text-danger">{formErrors.start_date}</div>)}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="campaign-name-width">
                            <label className="form-label">End date</label>
                            <input
                              type="date"
                              name="end_date"
                              min={campaignCreationData?.start_date}
                              className="form-control input border-dark fs-13"
                              defaultValue=""
                              onChange={(e) => handleInput(e)}
                            />
                            {formErrors.end_date && (<div className="text-danger">{formErrors.end_date}</div>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {campaigns Details Card Ends} */}

              {/* Products Card Starts  */}
              <div className="products-card-container products-card mx-auto mb-3">
                <div className={`card text-bg-white mb-3 my-2 `}>
                  <div className="card-header bg-white">
                    <div className="d-flex justify-content-between py-2">
                      <div className="fs-24 fw-light">
                        Products
                        <Tooltip
                          title={tooltipContent("Products")}
                          placement="top"
                          arrow
                          className="custom-tooltip-position cursor-pointer"
                        >
                          <span className="ms-1">
                            <svg
                              viewBox="0 0 512 512"
                              width="12"
                              height="12"
                              className="fas fa-info-circle mb-2"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="#8290a4"
                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                              ></path>
                            </svg>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                        <span>
                          <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                        </span>
                        How to add products to your campaign
                      </div>
                    </div>
                  </div>
                  <div className="card-body py-0">
                    <div className="row text-center px-0">
                      <div className="col-6 bg-white products-tabs-section border-right py-0">
                        <div className="tabs-section px-0">
                          <div
                            className="nav nav-underline"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              className="nav-link text-dark active fs-13"
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane fade show active search-tab"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                            tab-index="0"
                          >
                            <div className="row border-top border-bottom">
                              <div className="col-12 px-2">
                                <div className="input-group my-2">
                                  <span className="input-group-text  bg-white border-dark"><img src={SearchIcon} alt="" className="" /></span>
                                  <input type="text" name="search" className={`form-control border-dark  border-start-0 px-0 fs-13  ${showWrongIcon ? "border-end-0" : "rounded-end"}`} placeholder="Search by product name, ASIN or SKU"
                                    value={search} onChange={handleSearchInput} />
                                  {showWrongIcon && (
                                    <span className="input-group-text  bg-white border-dark rounded-start-0 rounded-end">
                                      <img src={SmallWrongIcon} alt="" className="cursor-pointer fs-13" onClick={onClickWrong} />
                                    </span>
                                  )}
                                  <span className="input-group-text  border-dark border-0 ms-1 search-button rounded-end-pill cursor-pointer">
                                    <img src={SearchSecondIcon} alt="" className={``} />
                                  </span>
                                </div>
                              </div>
                            </div>
                            {loading &&
                              <div className="text-center p-5">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            }
                            {!loading &&
                              <div className={`products_scroll products_scroll_height ${productsData?.length <= 3 ? "less-prodcuts" : "pe-2"}`}>
                                {productsData?.length > 0 && productsData?.map((item: any, itemIndex: number) => (
                                  <div className={`row py-2 border-bottom ${item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "prodcuts-gray-out-bg pe-1" : ""} `} >
                                    <div className="col-2 px-0">
                                      <img src={item?.image_url ? item?.image_url : NoProductImage} alt="" className="product-image" style={{ width: "70px", height: "70px", objectFit: "contain" }} />
                                    </div>
                                    <div className="col-7 px-0 text-start">
                                      <div className="product-header">
                                        <a>{item?.title}</a>
                                      </div>
                                      <div>
                                        <span className="amount-info">
                                          <span className="">
                                            <img src={RupeeIcon} alt="" className="" />
                                          </span>
                                          {item?.price_to_pay?.amount}
                                        </span> |
                                        <span className="stock-info">
                                          {item?.availability === "OUT_OF_STOCK" && (
                                            <Tooltip
                                              title={tooltipContent("stock-info")}
                                              placement="top"
                                              arrow
                                              className="custom-tooltip-position cursor-pointer "
                                            >
                                              <span className="ms-1">
                                                <svg
                                                  viewBox="0 0 512 512"
                                                  width="12"
                                                  height="12"
                                                  className="fas fa-info-circle mb-1"
                                                  data-fa-i2svg=""
                                                >
                                                  <path
                                                    fill="#8290a4"
                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </Tooltip>
                                          )}
                                          <span className={`px-1 stock-capitalize ${item?.availability === "IN_STOCK" ? "text-secondary " : ""}`} style={{ textTransform: "lowercase" }}>{item?.availability?.replace(/_/g, " ")}</span>
                                        </span>
                                      </div>
                                      <div>
                                        <span className="product-details">ASIN: {item?.asin}</span>
                                        <span> | </span>
                                        <span className="product-details">SKU: {item?.sku}</span>
                                      </div>
                                    </div>
                                    <div className="col-3 ps-0">
                                      <div className="text-end">
                                        {item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "" :
                                          <button disabled={item?.product_btn_status} onClick={() => onClickAddProduct(item, itemIndex)}
                                            className={`btn-dark border-0 rounded-pill custom-button-background fs-13 add-btn-status ${!item?.product_btn_status ? 'cursor-pointer text-dark' : 'cursor-pointer-not-allowed keyword-below-text-color'}`}
                                          >
                                            {!item?.product_btn_status ? 'Add' : 'Added'}
                                          </button>
                                        }
                                      </div>

                                      <div className={`pt-1 ${item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "pt-3" : ""}`}>
                                        <span className="add-varaiation-label">Add variations</span>
                                        <span>
                                          <Tooltip
                                            title={tooltipContent("add-variation")}
                                            placement="top"
                                            arrow
                                            className="custom-tooltip-position cursor-pointer "
                                          >
                                            <span className="ms-1">
                                              <svg
                                                viewBox="0 0 512 512"
                                                width="12"
                                                height="12"
                                                className="fas fa-info-circle mb-1"
                                                data-fa-i2svg=""
                                              >
                                                <path
                                                  fill="#8290a4"
                                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                ></path>
                                              </svg>
                                            </span>
                                          </Tooltip>
                                        </span>
                                      </div>
                                      <div className="text-end">
                                        <Tooltip
                                          title={tooltipContent("ad-paused")}
                                          placement="top"
                                          arrow
                                          className="custom-tooltip-position cursor-pointer "
                                        >
                                          <span className="ms-1">
                                            <i className="bi bi-exclamation-triangle-fill"></i>
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                )
                                )}
                                {productsData?.length == 0 && (
                                  <div className="col-12 fs-13 my-4 pt-1">
                                    No results. Searches are case-sensitive. Enter the
                                    exact book title or ASIN, or try another search
                                    term.
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                          <div
                            className="tab-pane fade enterlist-tab"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                            tab-index="0"
                          >
                            <div className="row border-top">
                              <div className="my-3">
                                <textarea
                                  name="text-two"
                                  rows={6} defaultValue={''}
                                  className="form-control fs-14"
                                  placeholder="Enter ASINs separated by a comma, space or new line."
                                ></textarea>
                              </div>
                              <div className=" text-end">
                                <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade upload-tab"
                            id="nav-contact"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                            tab-index="0"
                          >
                            <div className="row border-top">
                              <div className="my-3 fs-13 text-start">
                                Use the template to make sure your information
                                uploads accurately.
                              </div>
                              <div className="text-start">
                                <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                  <span className="me-2">
                                    <img src={DownloadIcon} alt="" />
                                  </span>
                                  Download the CSV template
                                </button>
                              </div>
                              <div className="upload-files">
                                <div
                                  data-takt-id="ups_upload_asins_input"
                                  className="upload-icon fs-13"
                                >
                                  <div className="mt-4 pt-2">
                                    <label
                                      htmlFor="input_file_three"
                                      className="cursor-pointer"
                                    >
                                      <div className="">
                                        <div>
                                          <i className="bi bi-upload"></i>
                                        </div>
                                        <div className="fs-13 my-1">
                                          Drag and drop file to add
                                        </div>
                                        <div className="fs-13 my-1">
                                          or click to select from your computer
                                        </div>
                                        <div className="fs-13 my-1">
                                          Accepted formats: CSV, TSV, XLSX
                                        </div>
                                      </div>
                                    </label>
                                    <input
                                      type="file"
                                      id="input_file_three"
                                      name="file"
                                      className="d-none "
                                      onChange={handleQuotationFileUpload}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 product-preview-container">
                        <div className="row">
                          <div className="col-4">
                            <div className="fs-13 text-start fw-bold py-2">
                              {addProductsData?.length} product{addProductsData?.length > 1 || addProductsData?.length === 0 ? "s" : ""}
                            </div>
                          </div>
                          <div className="col-8"></div>
                        </div>

                        {(addProductsData?.length === 0) && <div className="fs-13 add-products-text-color">
                          Add Products
                        </div>}
                        <div className='added-prodcuts_scroll' style={{ height: '460px', overflowY: "auto" }}>
                          {addProductsData?.map((item: any, productIndex: number) => (
                            <div className="row py-2 border-bottom">
                              <div className="col-2 px-0">
                                <img src={item?.image_url ? item?.image_url : NoProductImage} alt="" style={{ width: "70px", height: "70px", objectFit: "contain" }} />
                              </div>
                              <div className="col-7 px-0 text-start">
                                <div className="product-header">
                                  <a>{item.title}</a>
                                </div>
                                <div>
                                  <span className="amount-info">
                                    <span className="">
                                      <img src={RupeeIcon} alt="" className="" />
                                    </span>
                                    {item?.price_to_pay?.amount}
                                  </span> |
                                  <span className="stock-info">
                                    {item?.availability === "OUT_OF_STOCK" && (
                                      <Tooltip
                                        title={tooltipContent("stock-info")}
                                        placement="top"
                                        arrow
                                        className="custom-tooltip-position cursor-pointer "
                                      >
                                        <span className="ms-1">
                                          <svg
                                            viewBox="0 0 512 512"
                                            width="12"
                                            height="12"
                                            className="fas fa-info-circle mb-1"
                                            data-fa-i2svg=""
                                          >
                                            <path
                                              fill="#8290a4"
                                              d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    )}
                                    <span className={`px-1 text-capitalize stock-capitalize ${item?.availability === "IN_STOCK" ? "text-secondary stock-capitalize" : "stock-capitalize"}`}>{item?.availability?.replace(/_/g, " ")}</span>
                                  </span>
                                </div>
                                <div>
                                  <span className="product-details">ASIN: {item?.asin}</span>
                                  <span> | </span>
                                  <span className="product-details">SKU: {item?.sku}</span>
                                </div>
                              </div>
                              <div className="col-md-3 ps-0">
                                <div className="text-end">
                                  <div className="bg-transaprent border-0">
                                    <img
                                      src={WrongIcon} alt=""
                                      className="mt-1 cursor-pointer"
                                      onClick={() => onClickCancelProduct(item, productIndex)}
                                    />
                                  </div>
                                </div>
                                <div className="text-end d-none">
                                  <Tooltip
                                    title={tooltipContent("ad-paused")}
                                    placement="top"
                                    arrow
                                    className="custom-tooltip-position cursor-pointer "
                                  >
                                    <span className="ms-1">
                                      <i className="bi bi-exclamation-triangle-fill"></i>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          )
                          )}
                          {portfolioId && campaignCreationType === "AI" && (
                            <>
                              {aiCampaignProdcutsData?.map((data: any, aiCampaignProductIndex: number) => (
                                <div className="row py-2 border-bottom" key={aiCampaignProductIndex}>
                                  <div className="col-2 px-0">
                                    <img src={data?.image_url ? data?.image_url : NoProductImage} alt="" className="product-image" style={{ width: "70px", height: "70px", objectFit: "contain" }} />
                                  </div>
                                  <div className="col-7 px-0 text-start">
                                    <div className="product-header">
                                      <a>{data.title}</a>
                                    </div>
                                    <div>
                                      <span className="amount-info">
                                        <span className="">
                                          <img src={RupeeIcon} alt="" className="" />
                                        </span>
                                        {data?.price_to_pay?.amount}
                                      </span> |
                                      <span className="stock-info">
                                        <Tooltip
                                          title={tooltipContent("stock-info")}
                                          placement="top"
                                          arrow
                                          className="custom-tooltip-position cursor-pointer "
                                        >
                                          <span className="ms-1">
                                            <svg
                                              viewBox="0 0 512 512"
                                              width="12"
                                              height="12"
                                              className="fas fa-info-circle mb-1"
                                              data-fa-i2svg=""
                                            >
                                              <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                              ></path>
                                            </svg>
                                          </span>
                                        </Tooltip>
                                        <span className="px-1">{data?.availability?.replace(/_/g, " ")}</span>
                                      </span>
                                    </div>
                                    <div>
                                      <span className="product-details">ASIN:{data?.asin}</span>
                                      <span> | </span>
                                      <span className="product-details">SKU:{data?.sku}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-3 ps-0">
                                    <div className="text-end">
                                      <div className="bg-transaprent border-0">
                                        <img
                                          src={WrongIcon} alt=""
                                          className="mt-1 cursor-pointer"
                                          onClick={() => onClickCancelProduct(data, aiCampaignProductIndex)}
                                        />
                                      </div>
                                    </div>
                                    <div className="text-end">
                                      <Tooltip
                                        title={tooltipContent("ad-paused")}
                                        placement="top"
                                        arrow
                                        className="custom-tooltip-position cursor-pointer "
                                      >
                                        <span className="ms-1">
                                          <i className="bi bi-exclamation-triangle-fill"></i>
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Products Card Ends  */}

              {/* Targeting Card Starts  */}
              <div className="targeting-card-container card-width mx-auto mt-3">
                <div className="card text-bg-white ad-group-settings mb-3 my-3">
                  <div className="card-header bg-white">
                    <div className="d-flex justify-content-between py-2">
                      <div className="fs-24 fw-light">
                        Targeting
                        <Tooltip
                          title={tooltipContent("Targeting")}
                          placement="top"
                          arrow
                          className="custom-tooltip-position cursor-pointer "
                        >
                          <span className="ms-1">
                            <svg
                              viewBox="0 0 512 512"
                              width="12"
                              height="12"
                              className="fas fa-info-circle mb-2"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="#8290a4"
                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                              ></path>
                            </svg>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                        <span>
                          <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                        </span>
                        How to choose a targeting strategy
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* <div className="d-flex form-check">
                    <input
                      type="radio"
                      name="targeting-option"
                      className="form-check-input mb-3 me-3"
                      checked={targetingOption === "automatic"}
                      onChange={(e) => handleChangeTargeting("automatic", e)}
                    />
                    <label>
                      <div className="fs-13">Automatic targeting</div>
                      <div className="fs-13">
                        Amazon will target keywords and products that are
                        similar to the product in your ad.
                      </div>
                    </label>
                  </div> */}
                    <div className="d-flex form-check my-2">
                      <input
                        type="radio"
                        id="manualTargeting"
                        name="targeting-option"
                        className="form-check-input mb-3 me-3"
                        checked={targetingOption === "manual"}
                        onChange={(e) => handleChangeTargeting("manual", e)}
                      />
                      <label className="" htmlFor="manualTargeting">
                        <div className="fs-13">Manual targeting</div>
                        <div className="fs-13">
                          Choose keywords or products to target shopper searches
                          and set custom bids.
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* Targeting Card Ends  */}

              {/* Automatic Targeting Card Starts  */}
              {!showManualTargeting && (
                <div className="automatic-targeting-card-container card-width mx-auto mt-2 mb-0">
                  <div className="card text-bg-white ad-group-settings my-3">
                    <div className="card-header bg-white">
                      <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">
                          Automatic Targeting
                          <Tooltip
                            title={tooltipContent("Automatic Targeting")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer "
                          >
                            <span className="ms-1 ">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle mb-2"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                          <span>
                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                          </span>
                          How to set bid pricing
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        className={`d-flex form-check ${showSetDefaultBid ? "border-start" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          className="form-check-input mb-3 me-3"
                          name="set-default-bid"
                          checked={setDefaultBid === "Set default bid"}
                          onChange={() =>
                            onChangeAutomaticTargeting("Set default bid")
                          }
                        />
                        <label>
                          <div className="fs-13">
                            Set default bid
                            <Tooltip
                              title={tooltipContent("Set default bid")}
                              placement="right"
                              arrow
                              className="custom-tooltip-position cursor-pointer "
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle mb-1"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </div>
                          {showSetDefaultBid && (
                            <div>
                              <div className="input-group mb-3 my-2">
                                <span className="input-group-text input bg-white border-dark">
                                  <img src={RupeeIcon} alt="" className="" />
                                </span>
                                <input
                                  type="text"
                                  className="form-control input border-dark border-start-0 default-bid-input-width"
                                  value={"3.00"}
                                />
                              </div>
                            </div>
                          )}
                        </label>
                      </div>
                      <div
                        className={`d-flex form-check my-3 ${!showSetDefaultBid ? "border-start" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          className="form-check-input mb-3 me-3"
                          name="set-default-bid"
                          checked={
                            setDefaultBid === "Set bids by targeting group"
                          }
                          onChange={() =>
                            onChangeAutomaticTargeting(
                              "Set bids by targeting group"
                            )
                          }
                        />
                        <label className="">
                          <div className="fs-13">
                            Set bids by targeting group
                            <Tooltip
                              title={tooltipContent(
                                "Set bids by targeting group"
                              )}
                              placement="right"
                              arrow
                              className="custom-tooltip-position cursor-pointer "
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </div>
                          {!showSetDefaultBid && (
                            <div className="set-bids-targeting-group-container">
                              <div className="set-bids-header">
                                <div className="d-flex justify-content-between">
                                  <div className="fs-13">
                                    TARGETING GROUPS
                                    <Tooltip
                                      title={tooltipContent("TARGETING GROUPS")}
                                      placement="right"
                                      arrow
                                      className="custom-tooltip-position cursor-pointer"
                                    >
                                      <span className="ms-1">
                                        <svg
                                          viewBox="0 0 512 512"
                                          width="12"
                                          height="12"
                                          className="fas fa-info-circle"
                                          data-fa-i2svg=""
                                        >
                                          <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <div className="fs-13 ms-5 ps-4">
                                    SUGGESTED BID
                                    <Tooltip
                                      title={tooltipContent("SUGGESTED BID")}
                                      placement="right"
                                      arrow
                                      className="custom-tooltip-position cursor-pointer "
                                    >
                                      <span className="ms-1">
                                        <svg
                                          viewBox="0 0 512 512"
                                          width="12"
                                          height="12"
                                          className="fas fa-info-circle"
                                          data-fa-i2svg=""
                                        >
                                          <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <div className="fs-13 me-5 pe-2">
                                    BID
                                    <Tooltip
                                      title={tooltipContent("BID")}
                                      placement="right"
                                      arrow
                                      className="custom-tooltip-position cursor-pointer"
                                    >
                                      <span className="ms-1">
                                        <svg
                                          viewBox="0 0 512 512"
                                          width="12"
                                          height="12"
                                          className="fas fa-info-circle"
                                          data-fa-i2svg=""
                                        >
                                          <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                              <div className="set-bids-body">
                                {setBidsData?.length > 0 &&
                                  setBidsData?.map(
                                    (bidData: any, bidIndex: number) => (
                                      <div
                                        className="d-flex justify-content-between border-bottom my-3 pb-2"
                                        key={bidIndex}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div className="form-check form-switch">
                                            {targetingGroupsStatus && (
                                              <span className="right-mark-position targeting-groups-right-mark-position">
                                                <svg
                                                  viewBox="0 0 512 512"
                                                  width="7"
                                                  height="7"
                                                  className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check"
                                                  data-fa-i2svg=""
                                                >
                                                  <path
                                                    fill="white"
                                                    d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z"
                                                  ></path>
                                                </svg>
                                              </span>
                                            )}
                                            <input
                                              className="form-check-input toggle-hieght"
                                              type="checkbox"
                                              role="switch"
                                              id="flexSwitchCheckChecked"
                                              onChange={(e) =>
                                                handleTargetingGroupsStatus(
                                                  e,
                                                  bidData.id,
                                                  bidIndex
                                                )
                                              }
                                              checked={targetingGroupsStatus}
                                            />
                                            <label className="form-check-label my-1 d-none">
                                              {targetingGroupsStatus
                                                ? "Active"
                                                : "Paused"}
                                            </label>
                                          </div>
                                          <div className="fs-13">
                                            {bidData?.targeting_option_data}
                                            <Tooltip
                                              title={tooltipContent(
                                                bidData?.targeting_option_data
                                              )}
                                              placement="right"
                                              arrow
                                              className="custom-tooltip-position cursor-pointer"
                                            >
                                              <span className="ms-1">
                                                <svg
                                                  viewBox="0 0 512 512"
                                                  width="12"
                                                  height="12"
                                                  className="fas fa-info-circle"
                                                  data-fa-i2svg=""
                                                >
                                                  <path
                                                    fill="#8290a4"
                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="fs-13">-</div>
                                        <div className="fs-13 setbid-input-width">
                                          <div className="fs-13 set-default-bid-rupee-position">
                                            <span className="me-2">
                                              <img
                                                src={RupeeIcon} alt=""
                                                className="rupee-icon-position"
                                              />
                                            </span>
                                            <input
                                              type="text"
                                              className="form-control fs-13 border-dark"
                                              value="  3.00"
                                            //  disabled={bidDisabled(index)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Automatic Targeting Card Ends  */}

              {/* Manual Targeting Card Starts  */}
              {showManualTargeting && (
                <div className="manual-targeting-card-container card-width mx-auto mt-0 mb-1">
                  <div className="card text-bg-white ad-group-settings mb-1">
                    <div className="card-header bg-white">
                      <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">
                          Manual Targeting
                          <Tooltip
                            title={tooltipContent("Manual Targeting")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                          <span>
                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                          </span>
                          How to use keywords or products for manual targeting
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="fs-13 mb-2">
                        You can add multiple ad groups to your campaign, but you
                        can choose only one targeting type per ad group.
                      </div>
                      <div className="d-flex form-check">
                        <input
                          type="radio"
                          name="manual-target-option"
                          id="manualTargetinOptioTwo"
                          className="form-check-input mb-3 me-3"
                          checked={manualTargetingOption === "keyword"}
                          onChange={() => handleChangeManualTargeting("keyword")}
                        />
                        <label className="cusror-pointer" htmlFor="manualTargetinOptioTwo">
                          <div className="fs-13">
                            Keyword targeting
                            <Tooltip
                              title={tooltipContent("Keyword targeting")}
                              placement="right"
                              arrow
                              className="custom-tooltip-position cursor-pointer"
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </div>
                          <div className="fs-13">
                            Choose keywords to help your products appear in
                            shopper searches.
                          </div>
                        </label>
                      </div>
                      {/* <div className="d-flex form-check my-2">
                      <input
                        type="radio"
                        name="manual-target-option"
                        className="form-check-input mb-3 me-3"
                        checked={manualTargetingOption === "product"}
                        onChange={() => handleChangeManualTargeting("product")}
                      />
                      <label className="">
                        <div className="fs-13">
                          Product targeting
                          <Tooltip
                            title={tooltipContent("Product targeting")}
                            placement="right"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="fs-13">
                          Choose specific products, categories, brands or other
                          product features to target your ads.
                        </div>
                      </label>
                    </div> */}
                    </div>
                  </div>
                </div>
              )}
              {/* Manual Targeting Card Ends  */}

              {portfolioId && campaignCreationType === "AI" && (
                <div className="card-width mx-auto px-0">
                  {/* <div className="tabs-section px-0">
                  <div className="nav nav-underline" id="nav-tab" role="tablist">
                    {tabs.map((tab: any) => (
                      <button
                        key={tab.id}
                        className={`nav-link text-dark fs-13 ${activeTab === tab.id ? 'active' : ''}`}
                        id={`tab-${tab.id}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#tab-${tab.id}-keyword`}
                        type="button"
                        role="tab"
                        aria-controls={`tab-${tab.id}-keyword`}
                        aria-selected={activeTab === tab.id}
                        onClick={() => handleTabClick(tab.id)}
                      >
                        {tab.label}
                      </button>
                    ))}
                  </div>
                </div> */}
                  <div className="mx-1 d-flex justify-content-center pe-5">
                    <AiCampaignsTabsPage aiCampaignsProductsData={aiCampaignProdcutsData} cardDataShow={true} aiFlowKeywordSelectedTabTargetingData={getKeywordAIFlowData} />
                  </div>
                </div>
              )}
              {/*  Keyword targeting Card Starts  */}
              {!showManualKeywordTargeting && showManualTargeting && (
                <div className="keyword-targeting-card-container card-width mx-auto my-0">
                  <div className="card text-bg-white ad-group-settings my-3">
                    <div className="card-header bg-white">
                      <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">
                          Keyword targeting
                          <Tooltip
                            title={tooltipContent(
                              "Keyword targeting card header"
                            )}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                          <span>
                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                          </span>
                          How to choose keywords for targeting
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-0">
                      <div className="row text-center px-0">
                        <div className="col-6 bg-white products-tabs-section border-right py-0">
                          <div className="tabs-section px-0">
                            <div className="nav nav-underline" id="nav-tab" role="tablist">
                              <button
                                className="nav-link text-dark active fs-13"
                                id="nav-suggested"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-suggest"
                                type="button"
                                role="tab"
                                aria-controls="nav-suggest"
                                aria-selected="true"
                                onClick={onSelectKeywordType}
                              >
                                Suggested
                                <Tooltip
                                  title={tooltipContent("keyword suggested tab")}
                                  placement="top"
                                  arrow
                                  className="custom-tooltip-position cursor-pointer"
                                >
                                  <span className="ms-1">
                                    <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="">
                                      <path
                                        fill="#8290a4"
                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </Tooltip>
                              </button>
                              <button
                                className="nav-link text-dark fs-13" id="nav-enter-list"
                                data-bs-toggle="tab" data-bs-target="#nav-enter-list-keyword"
                                type="button" role="tab" aria-controls="nav-enter-list-keyword"
                                aria-selected="false">
                                Enter list
                              </button>
                              {/* <button
                              className="nav-link text-dark fs-13" id="nav-upload"
                              data-bs-toggle="tab" data-bs-target="#nav-upload-keyword"
                              type="button" role="tab" aria-controls="nav-upload-keyword"
                              aria-selected="false">
                              Upload
                            </button> */}
                            </div>
                          </div>
                          <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active suggested-tab" id="nav-suggest" role="tabpanel" aria-labelledby="nav-suggested" tab-index="0">
                              <div className="row border-top border-bottom">
                                <div className="col-12 px-0">
                                  <div className="d-flex justify-content-start m-2 px-1">
                                    <div className="fs-12 fw-bold my-1">
                                      Bid
                                      <Tooltip title={tooltipContent("keyword suggested tab Bid")} placement="top" arrow className="custom-tooltip-position cursor-pointer">
                                        <span className="ms-1">
                                          <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="">
                                            <path fill="#8290a4"
                                              d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    </div>
                                    <div className="fs-13 ms-5 ps-4">
                                      <div className="btn-group">
                                        <button
                                          className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          {selectedKeyWordTargetingBidValue}{" "}
                                          <span>
                                            <svg
                                              viewBox="0 0 389 512"
                                              width="13"
                                              height="13"
                                              className="fas fa-angle-down show-text-icon"
                                              data-fa-i2svg=""
                                            >
                                              <path
                                                fill="currentColor"
                                                d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                              ></path>
                                            </svg>
                                          </span>
                                        </button>
                                        <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                          <li className="fs-13 py-1">
                                            {keywordTargetingBidData?.map(
                                              (item: any, targetingBidIndex: number) => (
                                                <button
                                                  key={targetingBidIndex}
                                                  className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingBidValue ===
                                                    item?.value
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                  value={item.value}
                                                  onClick={() =>
                                                    handleKeywordTargetingBidTextChange(
                                                      item?.value
                                                    )
                                                  }
                                                >
                                                  <div className="fw-400">
                                                    {item.name}
                                                  </div>
                                                  <div className="dropdown-sub-title-text-color">
                                                    {item.sub_title}
                                                  </div>
                                                </button>
                                              )
                                            )}
                                          </li>
                                        </ul>
                                        {selectedKeyWordTargetingBidValue === "Custom bid" && (
                                          <div className="ms-2">
                                            <div className="input-group table-input-field-width">
                                              <span className="input-group-text custom-bid-input-height bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                              <input
                                                type="number"
                                                name="keyword_targeting_custom_bid"
                                                className="form-control custom-bid-input-height drop-down-input border-dark border-start-0 px-0"
                                                onChange={(e) => handleInput(e)} defaultValue={campaignCreationData?.keyword_targeting_custom_bid ? campaignCreationData?.keyword_targeting_custom_bid : "3"} placeholder="3.00"
                                              />
                                            </div>
                                            {formErrors?.keyword_targeting_custom_bid && (<div className="text-danger">{formErrors?.keyword_targeting_custom_bid}</div>)}
                                          </div>
                                        )}
                                        {selectedKeyWordTargetingBidValue === "Default bid" && (
                                          <>
                                            <div className="ms-2">
                                              <div className="input-group table-input-field-width">
                                                <span className="input-group-text custom-bid-input-height bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                                <input type="number" name="keyword_targeting_default_bid"
                                                  className="form-control custom-bid-input-height drop-down-input border-dark border-start-0 px-0"
                                                  onChange={(e) => handleInput(e)} defaultValue={campaignCreationData?.keyword_targeting_default_bid ? campaignCreationData?.keyword_targeting_default_bid : "3"} placeholder="3.00"
                                                />
                                              </div>
                                              {formErrors?.keyword_targeting_default_bid && (<div className="text-danger me-5">{formErrors?.keyword_targeting_default_bid}</div>)}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex m-2 px-1 my-3">
                                    <div className="fs-12 fw-bold">
                                      Filter by
                                      <Tooltip title={tooltipContent("keyword suggested tab Filter by")} placement="top" arrow className="custom-tooltip-position cursor-pointer">
                                        <span className="ms-1">
                                          <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="">
                                            <path fill="#8290a4"
                                              d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    </div>
                                    <div className="fs-13 ms-5">
                                      <div className="d-flex">
                                        <div>
                                          <label className="me-2">
                                            <input
                                              type="checkbox"
                                              className="me-2 cursor-pointer"
                                              defaultChecked={checkboxes.Broad}
                                              onChange={() => handleCheckboxChange('Broad')}
                                            />
                                            Broad
                                          </label>
                                        </div>
                                        <div>
                                          <label className="me-2">
                                            <input
                                              type="checkbox"
                                              className="me-2 cursor-pointer"
                                              defaultChecked={checkboxes.Phrase}
                                              onChange={() => handleCheckboxChange('Phrase')}
                                            />
                                            Phrase
                                          </label>
                                        </div>
                                        <br />
                                        <div>
                                          <label className="me-1">
                                            <input
                                              type="checkbox"
                                              className="me-2 cursor-pointer"
                                              defaultChecked={checkboxes.Exact}
                                              onChange={() => handleCheckboxChange('Exact')}
                                            />
                                            Exact
                                          </label>
                                        </div>
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-start m-2 px-1">
                                    <div className="fs-12 fw-bold my-1">
                                      Sort By
                                      <Tooltip title={tooltipContent("keyword suggested tab Sort By")} placement="top" arrow className="custom-tooltip-position cursor-pointer" >
                                        <span className="ms-1">
                                          <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="">
                                            <path fill="#8290a4"
                                              d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    </div>
                                    <div className="fs-13 ms-5">
                                      <div className="btn-group">
                                        <button
                                          className="btn btn-secondary btn-sm new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          {selectedKeyWordTargetingSortingValue}{" "}
                                          <span>
                                            <svg viewBox="0 0 389 512" width="13" height="13" className="fas fa-angle-down show-text-icon" data-fa-i2svg="" >
                                              <path fill="currentColor"
                                                d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                              ></path>
                                            </svg>
                                          </span>
                                        </button>
                                        <ul className="dropdown-menu newest-first-dropdown keyword-targeting-sorting-dropdown">
                                          <li className="fs-13 py-1">
                                            {keywordTargetingSortData?.map(
                                              (item: any, targetingSortIndex: number) => (
                                                <button
                                                  key={targetingSortIndex}
                                                  className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingSortingValue ===
                                                    item?.value
                                                    ? "active"
                                                    : ""
                                                    }`}
                                                  value={item.value}
                                                  onClick={() =>
                                                    handleKeywordTargetingSortingChange(
                                                      item?.value
                                                    )
                                                  }
                                                >
                                                  {item.name}
                                                </button>
                                              )
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="px-0" style={{ height: '330px', overflowY: "auto", overflowX: "hidden", width: "102.5%" }}>
                                <DataTable
                                  tableData={keywordSuggestionsData ? keywordSuggestionsData : []}
                                  TableCols={KeywordTargetingDataGridCols}
                                  paginationStatus={true}
                                  noDataFoundTitle={"No related keywords"}
                                  noDataFoundSubTitle={"Try entering your own keywords"}
                                  noDataFoundMessage={noDataMessage}
                                  addAllStatus={getAddAllDataStatus}
                                />
                                {keywordsloading &&
                                  <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only"></span>
                                    </div>
                                  </div>
                                }
                                {!keywordsloading &&
                                  <KeywordTargetingCards
                                    keywordTargetingSuggestionData={keywordSuggestionsData}
                                    addedProductsAsinData={addedProductsAsinData}
                                    addedKeywordSuggestionData={getAddedKeyWordData}
                                    canceledData={removeIndex}
                                    selectedBidValue={selectedKeyWordTargetingBidValue}
                                    removeAllIndex={removeAllIndex}
                                    addAllKeyWordTargeting={allkeywordTargeting}
                                    addedKeywordSuggestionDataObj={addedKeywordSuggestionData}
                                    addedAiFlowKeywordSugestionData={getAiFlowAddedKeywordData} />
                                }
                              </div>
                            </div>
                            <div
                              className="tab-pane fade enterlist-tab"
                              id="nav-enter-list-keyword"
                              role="tabpanel"
                              aria-labelledby="nav-enter-list"
                              tab-index="0"
                            >
                              <div className="row border-top">
                                <div className="d-flex justify-content-start m-2 px-1 my-2">
                                  <div className="fs-12 fw-bold ">
                                    Bid
                                    <Tooltip
                                      title={tooltipContent(
                                        "keyword enterlist tab Bid"
                                      )}
                                      placement="top"
                                      arrow
                                      className="custom-tooltip-position cursor-pointer"
                                    >
                                      <span className="ms-1">
                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="" >
                                          <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <div className="fs-13 ms-5 ps-4">
                                    <div className="btn-group">
                                      <button
                                        className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        {selectedKeyWordTargetingBidValue}{""}
                                        <span>
                                          {/* <i className="bi bi-chevron-down fs-13 fw-bold show-text-icon"></i> */}
                                          <svg viewBox="0 0 389 512" width="13" height="13" className="fas fa-angle-down show-text-icon" data-fa-i2svg="">
                                            <path
                                              fill="currentColor"
                                              d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </button>
                                      <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                        <li className="fs-13 py-1">
                                          {keywordTargetingBidData?.map(
                                            (item: any, leywordTargetingBidIndex: number) => (
                                              <button
                                                key={leywordTargetingBidIndex}
                                                className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingBidValue ===
                                                  item?.value
                                                  ? "active"
                                                  : ""
                                                  }`}
                                                value={item.value}
                                                onClick={() =>
                                                  handleKeywordTargetingBidTextChange(
                                                    item?.value
                                                  )
                                                }
                                              >
                                                <div className="fw-400">
                                                  {item.name}
                                                </div>
                                                <div className="dropdown-sub-title-text-color">
                                                  {item.sub_title}
                                                </div>
                                              </button>
                                            )
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex m-2 px-1 my-2">
                                  <div className="fs-12 fw-bold">
                                    Match type
                                    <Tooltip
                                      title={tooltipContent(
                                        "keyword enterlist tab Match type"
                                      )}
                                      placement="top"
                                      arrow
                                      className="custom-tooltip-position cursor-pointer"
                                    >
                                      <span className="ms-1">
                                        <svg
                                          viewBox="0 0 512 512"
                                          width="12"
                                          height="12"
                                          className="fas fa-info-circle"
                                          data-fa-i2svg=""
                                        >
                                          <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <div className="fs-13 ms-5">
                                    <div className="d-flex">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          name="Broad"
                                          type="checkbox"
                                          // defaultChecked={enterlistCheckBoxes?.Broad}
                                          checked={externalListCheckboxes?.Broad}
                                          id="flexCheckDefaultBroadThree"
                                          onChange={(e: any) => handleEnterlistCheckBoxChange(e)}
                                        />
                                        <label
                                          className="form-check-label fs-12"
                                          htmlFor="flexCheckDefaultBroadThree"
                                        >
                                          Broad
                                        </label>
                                      </div>
                                      <div className="form-check mx-2">
                                        <input name="Phrase" className="form-check-input" type="checkbox" id="flexCheckDefaultPhraseFour"
                                          // defaultChecked={enterlistCheckBoxes?.Phrase}
                                          checked={externalListCheckboxes?.Phrase}
                                          onChange={(e) => handleEnterlistCheckBoxChange(e)} />
                                        <label className="form-check-label fs-12" htmlFor="flexCheckDefaultPhraseFour">
                                          Phrase
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input name="Exact" className="form-check-input" type="checkbox" id="flexCheckDefaultExactSix"
                                          // defaultChecked={enterlistCheckBoxes?.Exact}
                                          checked={externalListCheckboxes?.Exact}
                                          onChange={(e) => handleEnterlistCheckBoxChange(e)} />
                                        <label className="form-check-label fs-12" htmlFor="flexCheckDefaultExactSix">
                                          Exact
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="my-2">
                                  <textarea
                                    name="keyword_text"
                                    rows={6}
                                    className="form-control fs-14 border-dark"
                                    placeholder="Enter keywords separated by new line."
                                    value={manualEnterListKeywordText?.keyword_text}
                                    onChange={handleEnterListKeywordInput}
                                  ></textarea>
                                </div>
                                <div className='text-start ps-1 ms-1'>
                                  {successMessage && (
                                    <span className='fs-13'>
                                      <span><i className="bi bi-check text-success fs-21"></i></span><span className="text-success ml-2 fs-13">{successMessage}</span>
                                    </span>
                                  )}
                                </div>
                                <div className="text-end">
                                  <button className={`btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 ${keywordTextStatus ? "cursor-pointer" : "cursor-pointer-not-allowed"}`}
                                    disabled={!keywordTextStatus}
                                    onClick={onAddKeywordEnterList}>
                                    Add keyword
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade upload-tab"
                              id="nav-upload-keyword"
                              role="tabpanel"
                              aria-labelledby="nav-upload"
                              tab-index="0"
                            >
                              <div className="row border-top">
                                <div className="mt-3 fs-13 text-start">
                                  Use the template to make sure your information
                                  uploads accurately.
                                </div>
                                <div className="text-start mb-3">
                                  <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                    <span className="me-2">
                                      <img src={DownloadIcon} alt="" />
                                    </span>
                                    Download the XLSX template
                                  </button>
                                </div>
                                <div className="upload-files">
                                  <div
                                    data-takt-id="ups_upload_asins_input"
                                    className="upload-icon fs-13"
                                  >
                                    <div className="mt-4 pt-2">
                                      <label
                                        htmlFor="input_file_three"
                                        className="cursor-pointer"
                                      >
                                        <div className="">
                                          <div>
                                            <i className="bi bi-upload"></i>
                                          </div>
                                          <div className="fs-15 my-1">
                                            Drag and drop file to add
                                          </div>
                                          <div className="fs-13 my-1">
                                            or click to select from your computer
                                          </div>
                                          <div className="fs-13 my-3">
                                            Accepted formats: CSV, TSV, XLSX
                                          </div>
                                        </div>
                                      </label>
                                      <input
                                        type="file"
                                        id="input_file_three"
                                        name="file"
                                        className="d-none "
                                        onChange={handleQuotationFileUpload}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 product-preview-container px-0">
                          <div className="d-flex justify-content-between border-bottom py-2 px-3 px-0">
                            <div className="fs-13 fw-bold ">{addedKeywordSuggestionData?.length} added</div>
                            <div className={`fs-13 ${addedKeywordSuggestionData?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : " cursor-pointer-not-allowed remove-all-text"}`}
                              onClick={onClickRemoveAllKeywordSuggestionData}>
                              Remove all
                            </div>
                          </div>
                          <div className="px-3">
                            <DataTable
                              // tableData={''}
                              TableCols={KeywordTargetingTwoDataGridCols}
                              keywordTargetTwo={true}
                              noDataFoundMessage={noDataMessage}
                            />
                            <div className="addedkeyword-scroll">
                              {addedKeywordSuggestionData?.length > 0 && addedKeywordSuggestionData?.map((addedKeywordData: any, ind: number) => (
                                <div key={ind} className="border-bottom">
                                  <div className="d-flex justify-content-between">
                                    <div className="fs-11 text-wrap w-40 text-start">
                                      {addedKeywordData?.suggestionData}
                                    </div>
                                    <div className="fs-11 w-25 text-start">
                                      {addedKeywordData?.bidData?.matchType}
                                    </div>
                                    <div className="fs-11 w-25 text-start">
                                      {addedKeywordData?.bidData?.suggestedBid?.rangeStart && (
                                        <>
                                          <div className={`text-start  ${selectedKeyWordTargetingBidValue === "Custom bid" ? "custom-explore-text-color" : "budget_text-color"}`}>₹{(addedKeywordData?.bidData?.bid / 100).toFixed(2)}</div>
                                          <div className="text-start budget_text-color">₹{(addedKeywordData?.bidData?.suggestedBid?.rangeStart / 100).toFixed(2)} -₹{(addedKeywordData?.bidData?.suggestedBid?.rangeEnd / 100).toFixed(2)}</div>
                                        </>
                                      )}
                                      {!addedKeywordData?.bidData?.suggestedBid?.rangeStart && (
                                        <div className="text-start budget_text-color">-</div>
                                      )}
                                    </div>
                                    <div className="fs-11 w-25">
                                      <div className="d-flex">
                                        <div className="input-group table-input-field-width mb-3 my-2">
                                          <span className="input-group-text input bg-white border-dark px-1">
                                            <img src={RupeeIcon} className="" />
                                          </span>
                                          <input
                                            type="text"
                                            name="keyword_targeting_bid"
                                            min={addedKeywordData?.bidData?.suggestedBid?.rangeStart}
                                            max={addedKeywordData?.bidData?.suggestedBid?.rangeEnd}
                                            onChange={(e) => handleKeyWordTargetingInput(e, addedKeywordData, ind)}
                                            className="form-control input border-dark border-start-0 px-0"
                                            value={
                                              addedKeywordData?.bid //? addedKeywordData?.bid : addedKeywordData?.bidData?.bid
                                              // selectedKeyWordTargetingBidValue === "Custom bid"
                                              //   ? campaignCreationData?.keyword_targeting_custom_bid
                                              //   : selectedKeyWordTargetingBidValue === "Default bid"
                                              //     ? campaignCreationData?.keyword_targeting_default_bid
                                              //     : selectedKeyWordTargetingBidValue === "Suggested bid"
                                              //       ? addedKeywordData?.bidData?.bidInputValue : selectedKeyWordTargetingBidValue === 'AlternateBid' ? alternateBidData?.alternate_bid : "3"
                                            }
                                          />
                                        </div>
                                        <div className="fs-28 ms-1">
                                          <i className="bi bi-x cursor-pointer" onClick={(e) => onClickCancelKeywordSuggestion(e, addedKeywordData, ind)}></i>
                                        </div>
                                      </div>
                                      {formErrors.keyword_targeting_bid && (
                                        <div className="text-danger text-start w-100">
                                          {formErrors.keyword_targeting_bid}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                </div>
                              ))}
                              {addedKeywordSuggestionData?.length === 0 && (
                                <div className="text-center">No records found</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*  Keyword targeting Card Ends  */}

              {/*  Product targeting Card Starts  */}
              {showManualKeywordTargeting && showManualTargeting && (
                <div className="product-targeting-card-container card-width mx-auto my-1">
                  <div className="card text-bg-white ad-group-settings my-1">
                    <div className="card-header bg-white">
                      <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">
                          Product targeting
                          <Tooltip
                            title={tooltipContent(
                              "Product targeting card header"
                            )}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                          <span>
                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                          </span>
                          How to use product targetings
                        </div>
                      </div>
                    </div>
                    <div className="card-body py-0">
                      <div className="row text-center px-0">
                        <div className="col-md-6 bg-white products-tabs-section border-right py-0 position-relative">
                          <div className="tabs-section px-0">
                            <div
                              className="nav nav-underline"
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                className="nav-link text-dark active fs-15"
                                id="nav-categories"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-product-targeting-categories"
                                type="button"
                                role="tab"
                                aria-controls="nav-product-targeting-categories"
                                aria-selected="true"
                              >
                                Categories
                                <Tooltip
                                  title={tooltipContent(
                                    "Product targeting Categories tab"
                                  )}
                                  placement="right"
                                  arrow
                                  className="custom-tooltip-position cursor-pointer"
                                >
                                  <span className="ms-1">
                                    <svg
                                      viewBox="0 0 512 512"
                                      width="12"
                                      height="12"
                                      className="fas fa-info-circle"
                                      data-fa-i2svg=""
                                    >
                                      <path
                                        fill="#8290a4"
                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </Tooltip>
                              </button>
                              <button
                                className="nav-link text-dark fs-15"
                                id="nav-individual-products"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-product-individual-products"
                                type="button"
                                role="tab"
                                aria-controls="nav-product-individual-products"
                                aria-selected="false"
                              >
                                Individual Products
                                <Tooltip
                                  title={tooltipContent(
                                    "Product targeting Individual Products tab"
                                  )}
                                  placement="right"
                                  arrow
                                  className="custom-tooltip-position cursor-pointer "
                                >
                                  <span className="ms-1">
                                    <svg
                                      viewBox="0 0 512 512"
                                      width="12"
                                      height="12"
                                      className="fas fa-info-circle"
                                      data-fa-i2svg=""
                                    >
                                      <path
                                        fill="#8290a4"
                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </Tooltip>
                              </button>
                            </div>
                          </div>
                          <div className="tab-content" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active suggested-tab"
                              id="nav-product-targeting-categories"
                              role="tabpanel"
                              aria-labelledby="nav-categories"
                              tab-index="0"
                            >
                              <div className="row border-top ">
                                <div className="col-12 px-0">
                                  <div className="tabs-section px-2 mx-1">
                                    <div
                                      className="nav nav-underline"
                                      id="nav-tab"
                                      role="tablist"
                                    >
                                      <button
                                        className="nav-link text-dark active fs-13"
                                        id="nav-product-categories-suggested"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-productcategories-suggested"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-productcategories-suggested"
                                        aria-selected="true"
                                      >
                                        Suggested
                                        <Tooltip
                                          title={tooltipContent(
                                            "Product targeting categories tab suggested"
                                          )}
                                          placement="top"
                                          arrow
                                          className="custom-tooltip-position cursor-pointer "
                                        >
                                          <span className="ms-1">
                                            <svg
                                              viewBox="0 0 512 512"
                                              width="12"
                                              height="12"
                                              className="fas fa-info-circle"
                                              data-fa-i2svg=""
                                            >
                                              <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                              ></path>
                                            </svg>
                                          </span>
                                        </Tooltip>
                                      </button>
                                      <button
                                        className="nav-link text-dark fs-13"
                                        id="nav-product-categories-search"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-productcategories-search"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-productcategories-search"
                                        aria-selected="false"
                                      >
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-content"
                                    id="nav-tabContent"
                                  >
                                    <div
                                      className="tab-pane fade show active suggested-tab"
                                      id="nav-productcategories-suggested"
                                      role="tabpanel"
                                      aria-labelledby="nav-product-categories-suggested"
                                      tab-index="0"
                                    >
                                      <ProductTargetCategoriesFilter
                                        categoriesTooltTipText={
                                          getCategoriesTooltipText
                                        }
                                      />
                                      <div className="d-flex flex-column justify-content-center my-1 mt-5">
                                        <div className="fs-13 fw-bold">
                                          No relevant categories available
                                        </div>
                                        <div className="fs-13 custom-explore-text-color logout cursor-pointer">
                                          Try searching for categories
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade show  search-tab"
                                      id="nav-productcategories-search"
                                      role="tabpanel"
                                      aria-labelledby="nav-product-categories-search"
                                      tab-index="0"
                                    >
                                      <ProductTargetCategoriesFilter />
                                      <div className="col-12 px-3 search-input-position product-categories-searchinput-position border-bottom  custom-border-color pb-2">
                                        <span>
                                          <img
                                            src={SearchIcon} alt=""
                                            className="search-position"
                                          />
                                        </span>
                                        <input
                                          className="form-control fs-13 border-dark px-4"
                                          defaultValue={search}
                                          placeholder="  Search by product name or ASIN"
                                          onChange={handleSearchInput}
                                        />
                                        <span className="d-none">
                                          <img
                                            src={SearchSecondIcon} alt=""
                                            className="search-second-position custom-button-background"
                                          />
                                        </span>
                                        {showWrongIcon && (
                                          <span>
                                            <img
                                              src={SmallWrongIcon} alt=""
                                              className="search-second-position wrong-icon-position"
                                              onClick={onClickWrong}
                                            />
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-12 mt-3 pt-3 pb-2 border-bottom border-top product-categories-search-list-header">
                                        <div className="product-categories-search-list-header">
                                          <div className="search-header-list px-3">
                                            <div className="fs-13">
                                              All categories
                                            </div>
                                            <div className="fs-13 me-5 pe-5">
                                              Sugg. bid
                                              <Tooltip
                                                title={tooltipContent(
                                                  "Product targeting categories tab suggested"
                                                )}
                                                placement="top"
                                                arrow
                                                className="custom-tooltip-position cursor-pointer "
                                              >
                                                <span className="ms-1">
                                                  <svg
                                                    viewBox="0 0 512 512"
                                                    width="12"
                                                    height="12"
                                                    className="fas fa-info-circle"
                                                    data-fa-i2svg=""
                                                  >
                                                    <path
                                                      fill="#8290a4"
                                                      d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="categories-accordions-list">
                                        {accordionsData?.length > 0 &&
                                          accordionsData?.map(
                                            (item: any, accordionsDataIndex: number) => (
                                              <Accordion
                                                key={accordionsDataIndex}
                                                expanded={expanded}
                                                onChange={(e) =>
                                                  handleChangeAccordion(e, accordionsDataIndex)
                                                }
                                              >
                                                <AccordionSummary
                                                  className="categories-accordion-header "
                                                  aria-controls="panel1d-content"
                                                  id="panel1d-header"
                                                >
                                                  <i className="bi bi-chevron-down fs-12 fw-bold mt-1"></i>
                                                  <Typography className="categories-title-header ms-2">
                                                    <div className="categories-accordion-title-container">
                                                      <div className="fs-13 text-wrap">
                                                        {item?.accordionTitle}
                                                      </div>
                                                      <div className="fs-13 text-center">
                                                        -
                                                      </div>
                                                      <div className="fs-13">
                                                        <div className="d-flex justify-content-center">
                                                          <div
                                                            className="fs-13 custom-explore-text-color border-right me-3"
                                                            onClick={(e) =>
                                                              handleClickAdd(
                                                                e,
                                                                item
                                                              )
                                                            }
                                                          >
                                                            Add
                                                          </div>
                                                          <div
                                                            className="fs-13 custom-explore-text-color border-left"
                                                            onClick={(e) =>
                                                              handleClickRefine(
                                                                e,
                                                                item
                                                              )
                                                            }
                                                          >
                                                            Refine
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <Typography>
                                                    <Accordion
                                                      expanded={expanded}
                                                      className="px-0 border-top"
                                                    >
                                                      <AccordionSummary
                                                        className=""
                                                        aria-controls="panel1d-content"
                                                        id="panel1d-header"
                                                      >
                                                        <i className="bi bi-chevron-down fs-12 fw-bold mt-1"></i>
                                                        <Typography className="categories-title-header ms-2">
                                                          <div className="categories-accordion-title-container">
                                                            <div className="fs-13">
                                                              watches
                                                            </div>
                                                            <div className="fs-13">
                                                              -
                                                            </div>
                                                            <div className="fs-13">
                                                              <div className="d-flex justify-content-center">
                                                                <div className="fs-13 custom-explore-text-color border-right me-3 ">
                                                                  Add
                                                                </div>
                                                                <div className="fs-13 custom-explore-text-color">
                                                                  Refine
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Typography>
                                                      </AccordionSummary>
                                                      <AccordionDetails>
                                                        <Typography></Typography>
                                                      </AccordionDetails>
                                                    </Accordion>
                                                  </Typography>
                                                </AccordionDetails>
                                              </Accordion>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade enterlist-tab"
                              id="nav-product-individual-products"
                              role="tabpanel"
                              aria-labelledby="nav-individual-products"
                              tab-index="0"
                            >
                              <div className="row border-top ">
                                <div className="col-12 px-0">
                                  <div className="tabs-section px-0 mx-0">
                                    <div
                                      className="nav nav-underline px-2 mx-1"
                                      id="nav-tab"
                                      role="tablist"
                                    >
                                      <button
                                        className="nav-link text-dark active fs-13"
                                        id="nav-product-individual-products-suggested"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-product-individualProducts-suggested"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-product-individualProducts-suggested"
                                        aria-selected="true"
                                      >
                                        Suggested
                                        <Tooltip
                                          title={tooltipContent(
                                            "Product targeting individual products tab suggested"
                                          )}
                                          placement="right"
                                          arrow
                                          className="custom-tooltip-position cursor-pointer "
                                        >
                                          <span className="ms-1">
                                            <svg
                                              viewBox="0 0 512 512"
                                              width="12"
                                              height="12"
                                              className="fas fa-info-circle"
                                              data-fa-i2svg=""
                                            >
                                              <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                              ></path>
                                            </svg>
                                          </span>
                                        </Tooltip>
                                      </button>
                                      <button
                                        className="nav-link text-dark fs-13"
                                        id="nav-product-individual-products-search"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-product-individualProducts-search"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-product-individualProducts-search"
                                        aria-selected="false"
                                      >
                                        Search{" "}
                                      </button>
                                      <button
                                        className="nav-link text-dark fs-13"
                                        id="nav-product-individual-products-enterlist"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-product-individualProducts-enterlist"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-product-individualProducts-enterlist"
                                        aria-selected="false"
                                      >
                                        Enter list
                                      </button>
                                      <button
                                        className="nav-link text-dark fs-13"
                                        id="nav-product-individual-products-upload"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-product-individualProducts-upload"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-product-individualProducts-upload"
                                        aria-selected="false"
                                      >
                                        Upload{" "}
                                      </button>
                                    </div>
                                    <div
                                      className="tab-content px-2 mx-0"
                                      id="nav-tabContent"
                                    >
                                      <div
                                        className="tab-pane fade show active suggested-tab"
                                        id="nav-product-individualProducts-suggested"
                                        role="tabpanel"
                                        aria-labelledby="nav-product-individual-products-suggested"
                                        tab-index="0"
                                      >
                                        <div className="row fs-13 border-top border-bottom mb-4 mx-0 d-none">
                                          <div className="d-flex justify-content-start m-2 px-3">
                                            <div className="fs-12 fw-bold my-1">
                                              Bid
                                              <span className="ms-1">
                                                <svg
                                                  viewBox="0 0 512 512"
                                                  width="12"
                                                  height="12"
                                                  className="fas fa-info-circle"
                                                  data-fa-i2svg=""
                                                >
                                                  <path
                                                    fill="#8290a4"
                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </div>
                                            <div className="fs-13 ms-5 ps-4">
                                              <select className="form-select custom-drop-down suggested-drop-down rounded-pill py-1 text-start">
                                                <option
                                                  value="Suggested bid"
                                                  className="suggested-dropdown-box-shadow "
                                                >
                                                  <div className="">
                                                    <div className="fs-14">
                                                      Suggested bid
                                                    </div>
                                                  </div>
                                                </option>
                                                <option value="Custom bid">
                                                  Custom bid
                                                </option>
                                                <option value="Default bid">
                                                  Default bid
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="d-flex m-2 px-1 my-2">
                                            <div className="fs-12 fw-bold">
                                              Filter by
                                              <span className="ms-1">
                                                <svg
                                                  viewBox="0 0 512 512"
                                                  width="12"
                                                  height="12"
                                                  className="fas fa-info-circle"
                                                  data-fa-i2svg=""
                                                >
                                                  <path
                                                    fill="#8290a4"
                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </div>
                                            <div className="fs-13 ms-5">
                                              <div className="d-flex">
                                                <div className="form-check mx-2">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefaultProductTargetingExact"
                                                  />
                                                  <label
                                                    className="form-check-label fs-12"
                                                    htmlFor="flexCheckDefaultProductTargetingExact"
                                                  >
                                                    Exact
                                                  </label>
                                                </div>
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefaultProductTargetingExpanded"
                                                    defaultChecked
                                                  />
                                                  <label
                                                    className="form-check-label fs-12"
                                                    htmlFor="flexCheckDefaultProductTargetingExpanded"
                                                  >
                                                    Expanded
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <ProductTargetingIndividualProductsFilter />
                                        <div className="d-flex flex-row justify-content-center my-1 mt-5">
                                          <div className="d-flex justify-content-center">
                                            <div className="fs-13 fw-bold">
                                              No suggestions available.
                                            </div>
                                            <div className="fs-13 custom-explore-text-color logout cursor-pointer ">
                                              Try searching for products.
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="tab-pane fade show  search-tab"
                                        id="nav-product-individualProducts-search"
                                        role="tabpanel"
                                        aria-labelledby="nav-product-individual-products-search"
                                        tab-index="0"
                                      >
                                        <ProductTargetingIndividualProductsFilter />
                                        <div className="col-12 px-0 search-input-position individual-product-searchinput-position border-bottom custom-border-color pb-2">
                                          <span>
                                            <img
                                              src={SearchIcon} alt=""
                                              className="search-position"
                                            />
                                          </span>
                                          <input
                                            className="form-control fs-13 border-dark px-4"
                                            defaultValue={search}
                                            placeholder="  Search by product name or ASIN"
                                            onChange={handleSearchInput}
                                          />
                                          <span className="d-none">
                                            <img
                                              src={SearchSecondIcon} alt=""
                                              className="search-second-position custom-button-background"
                                            />
                                          </span>
                                          {showWrongIcon && (
                                            <span>
                                              <img
                                                src={SmallWrongIcon} alt=""
                                                className="search-second-position wrong-icon-position"
                                                onClick={onClickWrong}
                                              />
                                            </span>
                                          )}
                                        </div>
                                        <div className="fs-13 mt-5 ">
                                          Search for products you want to target.
                                        </div>
                                      </div>
                                      <div
                                        className="tab-pane fade show  search-tab"
                                        id="nav-product-individualProducts-enterlist"
                                        role="tabpanel"
                                        aria-labelledby="nav-product-individual-products-enterlist"
                                        tab-index="0"
                                      >
                                        <ProductTargetingIndividualProductsFilter />
                                        <div className="fs-13">
                                          <textarea
                                            name="text-three"
                                            className="form-control border-dark fs-13"
                                            rows={8} defaultValue={''}
                                            placeholder="Enter ASINs separated by a comma, space or new line."
                                          ></textarea>
                                        </div>
                                        <div className="fs-13 d-flex justify-content-end my-2">
                                          <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                            Target
                                          </button>
                                        </div>
                                      </div>
                                      <div
                                        className="tab-pane fade show  search-tab"
                                        id="nav-product-individualProducts-upload"
                                        role="tabpanel"
                                        aria-labelledby="nav-product-individual-products-upload"
                                        tab-index="0"
                                      >
                                        <div className="row fs-13 border-top mb-4 mx-0">
                                          <div className="row fs-13 border-bottom mb-3 mx-0">
                                            <div className="d-flex justify-content-start m-2 px-0">
                                              <div className="fs-12 fw-bold my-1">
                                                Bid
                                                <span className="ms-1">
                                                  <svg
                                                    viewBox="0 0 512 512"
                                                    width="12"
                                                    height="12"
                                                    className="fas fa-info-circle"
                                                    data-fa-i2svg=""
                                                  >
                                                    <path
                                                      fill="#8290a4"
                                                      d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </div>
                                              <div className="fs-13 ms-5 ps-4">
                                                <div className="btn-group">
                                                  <button
                                                    className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    {
                                                      selectedProductTargetingBidValue
                                                    }{" "}
                                                    <span>
                                                      <svg
                                                        viewBox="0 0 389 512"
                                                        width="13"
                                                        height="13"
                                                        className="fas fa-angle-down show-text-icon"
                                                        data-fa-i2svg=""
                                                      >
                                                        <path
                                                          fill="currentColor"
                                                          d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                  </button>
                                                  <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                                    <li className="fs-13 py-1">
                                                      {keywordTargetingBidData?.map(
                                                        (
                                                          item: any,
                                                          i: number
                                                        ) => (
                                                          <button
                                                            key={i}
                                                            className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedProductTargetingBidValue ===
                                                              item?.value
                                                              ? "active"
                                                              : ""
                                                              }`}
                                                            value={item.value}
                                                            onClick={() =>
                                                              handleProductTargetingBidTextChange(
                                                                item?.value
                                                              )
                                                            }
                                                          >
                                                            <div className="fw-400">
                                                              {item.name}
                                                            </div>
                                                            <div className="dropdown-sub-title-text-color">
                                                              {item.sub_title}
                                                            </div>
                                                          </button>
                                                        )
                                                      )}
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row ">
                                            <div className="mb-2 fs-13 text-start">
                                              Use the template to make sure your
                                              information uploads accurately.
                                            </div>
                                            <div className="text-start mb-2">
                                              <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                                <span className="me-2">
                                                  <img src={DownloadIcon} alt="" />
                                                </span>
                                                Download the CSV template
                                              </button>
                                            </div>
                                            <div className="upload-files">
                                              <div
                                                data-takt-id="ups_upload_asins_input"
                                                className="upload-icon fs-13"
                                              >
                                                <div className="mt-4 pt-2">
                                                  <label
                                                    htmlFor="input_file_three"
                                                    className="cursor-pointer"
                                                  >
                                                    <div className="">
                                                      <div>
                                                        <i className="bi bi-upload"></i>
                                                      </div>
                                                      <div className="fs-13 my-1">
                                                        Drag and drop file to add
                                                      </div>
                                                      <div className="fs-13 my-2">
                                                        or click to select from
                                                        your computer
                                                      </div>
                                                      <div className="fs-13 my-1">
                                                        Accepted formats: CSV,
                                                        TSV, XLSX
                                                      </div>
                                                    </div>
                                                  </label>
                                                  <input
                                                    type="file"
                                                    id="input_file_three"
                                                    name="file"
                                                    className="d-none "
                                                    onChange={
                                                      handleQuotationFileUpload
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {showRefineModal && (
                            <div className="product_targeting_categories_search_refine">
                              <div className="product_targeting_categories_search_refine_child_subchild">
                                <div className="refine_header_parent">
                                  <span className="refine_header">
                                    Refine category: Watches
                                  </span>
                                  <p className="refine_header_subtitle">
                                    Refine category by specific brands, price
                                    range, star ratings and Prime shipping
                                    eligibility.
                                  </p>
                                </div>
                                <hr className="m-0" />
                                <div className="gBHflH">
                                  <div className="fxhSbD">
                                    <span className="refine_header">Brand</span>
                                    <div className="col-8">
                                      <button className="btn form-control btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer">
                                        Select
                                      </button>
                                    </div>
                                  </div>
                                  <div className="fxhSbD">
                                    <span className="refine_header">
                                      Price range
                                    </span>
                                    <div className="d-flex">
                                      <div className=" input-group my-2 mt-0">
                                        <span className="input-group-text input bg-white border-dark">
                                          <img src={RupeeIcon} alt="" className="" />
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control input border-dark border-start-0 default-bid-input-width ps-0"
                                          placeholder="No min"
                                        />
                                      </div>
                                      <div className="Price_range_dash">-</div>
                                      <div className="input-group my-2 mt-0">
                                        <span className="input-group-text input bg-white border-dark">
                                          <img src={RupeeIcon} alt="" className="" />
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control input border-dark border-start-0 default-bid-input-width ps-0"
                                          placeholder="No max"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fxhSbD">
                                    <span className="refine_header">
                                      Review stars rating
                                    </span>
                                    <div className="d-flex">
                                      <MultiRangeSlider
                                        labels={reviewStarRating}
                                        min={0}
                                        max={6}
                                        minValue={1}
                                        maxValue={5}
                                        step={1}
                                        className="mutlirange-slider"
                                        barInnerColor="rgb(0, 115, 199)"
                                        thumbLeftColor="rgb(0, 115, 199)"
                                        thumbRightColor="rgb(0, 115, 199)"
                                        barRightColor="rgb(206, 211, 220)"
                                        barLeftColor="rgb(206, 211, 220)"
                                        ruler={false}
                                        minCaption={minCaption}
                                        maxCaption={maxCaption}
                                        onInput={(e: any) => {
                                          set_minCaption(
                                            reviewStarRating[e.minValue]
                                          );
                                          set_maxCaption(
                                            reviewStarRating[e.maxValue]
                                          );
                                        }}
                                      />
                                      <span className="refine_header mt-2">
                                        All stars
                                      </span>
                                    </div>
                                  </div>
                                  <div className="fxhSbD">
                                    <span className="refine_header">
                                      Shipping
                                    </span>
                                    <div>
                                      <span>
                                        <input
                                          type="radio"
                                          className="form-check-input mb-3 me-1"
                                          name="shippingStatus"
                                        // checked={
                                        //   campaignBiddinStrategy ===
                                        //   "Dynamic bids - down only"
                                        // }
                                        // onChange={() =>
                                        //   onChangeCampaignBiddingType(
                                        //     "Dynamic bids - down only"
                                        //   )
                                        // }
                                        />
                                        <span className="dikvWV me-3">All</span>
                                      </span>
                                      <span>
                                        <input
                                          type="radio"
                                          className="form-check-input mb-3 me-1"
                                          name="shippingStatus"
                                        // checked={
                                        //   campaignBiddinStrategy ===
                                        //   "Dynamic bids - down only"
                                        // }
                                        // onChange={() =>
                                        //   onChangeCampaignBiddingType(
                                        //     "Dynamic bids - down only"
                                        //   )
                                        // }
                                        />
                                        <span className="dikvWV me-3">
                                          Prime eligible
                                        </span>
                                      </span>
                                      <span>
                                        <input
                                          type="radio"
                                          className="form-check-input mb-3 me-1"
                                          name="shippingStatus"
                                        // checked={
                                        //   campaignBiddinStrategy ===
                                        //   "Dynamic bids - down only"
                                        // }
                                        // onChange={() =>
                                        //   onChangeCampaignBiddingType(
                                        //     "Dynamic bids - down only"
                                        //   )
                                        // }
                                        />
                                        <span className="dikvWV me-3">
                                          Not Prime eligible
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <hr className="m-0" />
                                <div className="gBHflH mt-3">
                                  <div className="row">
                                    <div className="col-6">
                                      <span className="refine_header">
                                        Products targeted: 6 – 10
                                      </span>
                                    </div>
                                    <div className="col-6 text-end">
                                      <span
                                        className="custom-explore-text-color fs-13 logout cursor-pointer py-2 me-2"
                                        onClick={() => setShowRefineModal(false)}
                                      >
                                        Cancel
                                      </span>
                                      <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer">
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-md-6 product-preview-container px-0">
                          <div className="d-flex justify-content-between border-bottom py-2 px-3 px-0">
                            <div className="fs-13 fw-bold ">0 added</div>
                            <div className="fs-13 cursor-pointer-not-allowed remove-all-text ">
                              Remove all
                            </div>
                          </div>
                          <div className="px-3">
                            <DataTable
                              tableData={keywordTargetingdata}
                              TableCols={
                                ProductTargetingRightSideTableDataGridCols
                              }
                              keywordTargetTwo={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*  Product targeting Card Ends  */}

              {/*  Negative keyword targeting Card Starts  */}
              {((!showManualKeywordTargeting && showManualTargeting) ||
                !showManualTargeting) && (
                  <div className="negative-keyword-targeting-container card-width mx-auto mb-3">
                    <div className="accordion " id="accordionOne">
                      <div className="accordion-item border rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button down-arrow down-arrow-one campaign-creation-negative-keyword-targeting-accordion collapsed fs-24 fw-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="true"
                            aria-controls="flush-collapseOne"
                          >
                            <span className="px-3">Negative keyword targeting</span>
                            <Tooltip
                              title={tooltipContent(
                                "negative Keyword targeting card header optional"
                              )}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer "
                            >
                              <span className="mx-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle mb-3"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                            <div className="d-flex justify-content-between">
                              <div className="ms-auto">
                                <span className="fs-13 optional-text-color mt-2 ms-auto">
                                  optional
                                </span>
                              </div>
                              <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2 keyword-target-position campaign-creation-keyword-target-position campaign-creation-keyword-target-position">
                                <span>
                                  <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                </span>
                                How to use negative keywords
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionOne"
                        >
                          <div className="accordion-body border-top py-0">
                            <div className="row px-3">
                              <div className="col-6 bg-white products-tabs-section border-right">
                                <div className="d-flex justify-content-between my-2">
                                  <div className="mx-2 fs-12 fw-bold my-1">
                                    Match type
                                    <Tooltip
                                      title={tooltipContent(
                                        "negative Keyword targeting Match type"
                                      )}
                                      placement="top"
                                      arrow
                                      className="custom-tooltip-position cursor-pointer"
                                    >
                                      <span className="mx-1">
                                        <svg
                                          viewBox="0 0 512 512"
                                          width="12"
                                          height="12"
                                          className="fas fa-info-circle"
                                          data-fa-i2svg=""
                                        >
                                          <path
                                            fill="#8290a4"
                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <div className="mx-2">
                                    <div className="d-flex form-check">
                                      <input
                                        type="radio"
                                        name="negative_keyword_targeting"
                                        id="MatchTypeExact"
                                        className="form-check-input mb-3 me-3"
                                        defaultChecked={keywordMatchtype === 'Negative exact'}
                                        onChange={() => handleChangeNegativeKeywordTargetingMatchType('Negative exact')}
                                      />
                                      <label className="my-1 cursor-pointer" htmlFor="MatchTypeExact">
                                        <div className="fs-12">Negative exact</div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mx-2">
                                    <div className="d-flex form-check">
                                      <input
                                        type="radio"
                                        name="negative_keyword_targeting"
                                        id="negativeKeywordMAtchType"
                                        className="form-check-input mb-3 me-3"
                                        defaultChecked={keywordMatchtype === 'Negative phrase'}
                                        onChange={() => handleChangeNegativeKeywordTargetingMatchType('Negative phrase')}
                                      />
                                      <label className="my-1 cursor-pointer" htmlFor="negativeKeywordMAtchType">
                                        <div className="fs-12">Negative phrase</div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row my-1">
                                  <div className="my-0">
                                    <textarea
                                      rows={8}
                                      className="form-control fs-14 border-dark"
                                      placeholder="Enter keywords separated by new line."
                                      name="negative_keyword_text"
                                      onChange={handleChangeNegativeKeywordText}
                                      value={negativeKeywordText?.negative_keyword_text}
                                    ></textarea>
                                  </div>
                                  <div className=''>
                                    {successMessage && (
                                      <span className='fs-13'>
                                        <span><i className="bi bi-check text-success fs-21"></i></span><span className="text-success ml-2 fs-13">{successMessage}</span>
                                      </span>
                                    )}
                                  </div>
                                  <div className="text-end my-2">
                                    <button className={`btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-12
                                    ${negativeKeywordTextStatus ? "cursor-pointer text-dark" : "cursor-pointer-not-allowed"}`}
                                      disabled={!negativeKeywordTextStatus}
                                      onClick={onClickAddNegativeKeywords}
                                    >
                                      Add keywords
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 product-preview-container p-0">
                                <div className="negative-keyword-right-container ">
                                  <div className="d-flex justify-content-between py-2 px-3">
                                    <div className="fs-13 fw-bold ">{addNegativeKeywordsData?.length} added</div>
                                    <div className={`fs-13 ${addNegativeKeywordsData?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : "cursor-pointer-not-allowed remove-all-text"}`}
                                      onClick={onClickRemoveNegativeKeywordsData}>
                                      Remove all
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between border-top border-bottom py-2 px-3">
                                    <div className="fs-13">Keyword</div>
                                    <div className="fs-13 me-5 pe-4">
                                      <div className="mx-2 fs-12 ">
                                        Match type
                                        <Tooltip
                                          title={tooltipContent(
                                            "negative Keyword targeting table Match type"
                                          )}
                                          placement="top"
                                          arrow
                                          className="custom-tooltip-position cursor-pointer "
                                        >
                                          <span className="mx-1 cursor-pointer">
                                            <svg
                                              viewBox="0 0 512 512"
                                              width="12"
                                              height="12"
                                              className="fas fa-info-circle"
                                              data-fa-i2svg=""
                                            >
                                              <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                              ></path>
                                            </svg>
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`${addNegativeKeywordsData?.length > 13 ? "negative-keyword-data-scroll" : ""}`}>
                                    {addNegativeKeywordsData?.map((keywordData: any, keywordDataIndex: number) => (
                                      <div key={keywordDataIndex} className='d-flex justify-content-between px-3 border-bottom'>
                                        <div className="my-1">{keywordData?.negative_keyword_text}</div>
                                        <div className='d-flex justify-content-start'>
                                          <div className='text-start me-2 my-1'>{`${keywordData?.negative_keyword_matchType === "NEGATIVE_EXACT" ? "Negative exact" : "Negative phrase"}`}</div>
                                          <div className="fs-21 ms-1">
                                            <i className="bi bi-x cursor-pointer" onClick={() => onClickCancelNegtaiveKeywordTargeting(keywordData, keywordDataIndex)} ></i>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/*  Negative keyword targeting Card Ends  */}

              {/*  Negative product targeting Card Starts  */}
              {((showManualKeywordTargeting &&
                showManualTargeting &&
                !showManualProductTargeting) ||
                !showManualTargeting) && (
                  <div className="negative-product-targeting-container card-width mx-auto mt-2 mb-3">
                    <div
                      className="accordion accordion-flush my-2"
                      id="accordionTwo"
                    >
                      <div className="accordion-item border rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button down-arrow down-arrow-two collapsed fs-24 fw-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="true"
                            aria-controls="flush-collapseTwo"
                          >
                            <span className="px-3">Negative product targeting</span>
                            <Tooltip
                              title={tooltipContent(
                                "automatic targeting Negative product targeting optional"
                              )}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer "
                            >
                              <span className="mx-1 mb-4">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                            <div className="d-flex justify-content-between">
                              <div className="ms-auto">
                                <span className="fs-13 optional-text-color mt-2 ">
                                  optional
                                </span>
                              </div>
                              <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2 keyword-product-position">
                                <span>
                                  <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                </span>
                                How to choose negative products for targeting
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse "
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionTwo"
                        >
                          <div className="accordion-body border-top">
                            <div className="row text-center px-3">
                              <div className="col-6 bg-white products-tabs-section border-right py-0">
                                <div className="tabs-section px-0">
                                  <div
                                    className="nav nav-underline"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <button
                                      className="nav-link text-dark active fs-13"
                                      id="nav-negative-product-search"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-negative-productSearch"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-negative-productSearch"
                                      aria-selected="true"
                                    >
                                      Search
                                    </button>
                                    <button
                                      className="nav-link text-dark fs-13"
                                      id="nav-negative-product-enterlist"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-negative-productEnterlist"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-negative-productEnterlist"
                                      aria-selected="false"
                                    >
                                      Enter list
                                    </button>
                                    <button
                                      className="nav-link text-dark fs-13"
                                      id="nav-negative-product-upload"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-negative-productUpload"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-negative-productUpload"
                                      aria-selected="false"
                                    >
                                      Upload
                                    </button>
                                  </div>
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                  <div
                                    className="tab-pane fade show active search-tab"
                                    id="nav-negative-productSearch"
                                    role="tabpanel"
                                    aria-labelledby="nav-negative-product-search"
                                    tab-index="0"
                                  >
                                    <div className="row border-top">
                                      <div className="col-12 px-0 search-input-position pe-5 ps-2">
                                        <span>
                                          <img
                                            src={SearchIcon} alt=""
                                            className="search-position"
                                          />
                                        </span>
                                        <input
                                          className="form-control fs-13 border-dark px-4"
                                          defaultValue={search}
                                          placeholder="  Search by product name or ASIN"
                                          onChange={handleSearchInput}
                                        />
                                        <span className="d-none">
                                          <img
                                            src={SearchSecondIcon} alt=""
                                            className="search-second-position custom-button-background"
                                          />
                                        </span>
                                        {showWrongIcon && (
                                          <span>
                                            <img
                                              src={SmallWrongIcon} alt=""
                                              className="search-second-position wrong-icon-position"
                                              onClick={onClickWrong}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="row">
                                      {!showWrongIcon && (
                                        <div className="col-12 fs-13 my-4 border-top pt-4">
                                          Search for products you want to exclude.
                                        </div>
                                      )}
                                      {showWrongIcon && (
                                        <div className="col-12 fs-13 my-3 border-top pt-4">
                                          No results. Searches are case-sensitive.
                                          Enter the exact book title or ASIN, or try
                                          another search term.
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade enterlist-tab"
                                    id="nav-negative-productEnterlist"
                                    role="tabpanel"
                                    aria-labelledby="nav-negative-product-enterlist"
                                    tab-index="0"
                                  >
                                    <div className="row border-top">
                                      <div className="my-3">
                                        <textarea
                                          name="text-four"
                                          rows={6} defaultValue={''}
                                          className="form-control fs-14 border-dark"
                                          placeholder="Enter ASINs separated by a comma, space or new line."
                                        ></textarea>
                                      </div>
                                      <div className=" text-end">
                                        <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                          Exclude
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade upload-tab"
                                    id="nav-negative-productUpload"
                                    role="tabpanel"
                                    aria-labelledby="nav-negative-product-upload"
                                    tab-index="0"
                                  >
                                    <div className="row border-top">
                                      <div className="my-3 fs-13 text-start">
                                        Use the template to make sure your
                                        information uploads accurately.
                                      </div>
                                      <div className="text-start">
                                        <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                          <span className="me-2">
                                            <img src={DownloadIcon} alt="" />
                                          </span>
                                          Download the CSV template
                                        </button>
                                      </div>
                                      <div className="upload-files">
                                        <div
                                          data-takt-id="ups_upload_asins_input"
                                          className="upload-icon fs-13"
                                        >
                                          <div className="mt-4 pt-2">
                                            <label
                                              htmlFor="input_file_three"
                                              className="cursor-pointer"
                                            >
                                              <div className="">
                                                <div>
                                                  <i className="bi bi-upload"></i>
                                                </div>
                                                <div className="fs-13 my-1">
                                                  Drag and drop file to add
                                                </div>
                                                <div className="fs-13 my-1">
                                                  or click to select from your
                                                  computer
                                                </div>
                                                <div className="fs-13 my-1">
                                                  Accepted formats: CSV, TSV, XLSX
                                                </div>
                                              </div>
                                            </label>
                                            <input
                                              type="file"
                                              id="input_file_three"
                                              name="file"
                                              className="d-none "
                                              onChange={handleQuotationFileUpload}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 product-preview-container">
                                <div className="d-flex justify-content-between  border-bottom py-2 px-2">
                                  <div className="fs-13 fw-bold ">0 added</div>
                                  <div className="fs-13 cursor-pointer-not-allowed remove-all-text ">
                                    Remove all
                                  </div>
                                </div>
                                <div className="fs-12 pt-4 mt-2 pb-2 px-2  border-bottom text-start">
                                  Brands and products
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/*  Negative product targeting Card Ends  */}

              {/*  Negative product targeting for  product targeting Card Starts  */}
              {showManualProductTargeting &&
                showManualKeywordTargeting &&
                showManualTargeting && (
                  <div className="negative-product-targeting-container card-width mx-auto my-2">
                    <div
                      className="accordion accordion-flush my-2"
                      id="accordionThree"
                    >
                      <div className="accordion-item border rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button down-arrow down-arrow-two collapsed fs-24 fw-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="true"
                            aria-controls="flush-collapseThree"
                          >
                            <span className="px-3">
                              Negative product targeting
                            </span>
                            <Tooltip
                              title={tooltipContent(
                                "manual targeting Negative product targeting optional"
                              )}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer "
                            >
                              <span className="mx-1 mb-3">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="12"
                                  height="12"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                            <div className="d-flex justify-content-between">
                              <div className="ms-auto">
                                <span className="fs-13 optional-text-color mt-2">
                                  optional
                                </span>
                              </div>
                              <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2 keyword-product-position">
                                <span>
                                  <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                </span>
                                How to choose negative products for targeting
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse "
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionThree"
                        >
                          <div className="accordion-body border-top">
                            <div className="row text-center px-3">
                              <div className="col-6 bg-white products-tabs-section border-right py-0">
                                <div className="tabs-section px-0">
                                  <div
                                    className="nav nav-underline"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <button
                                      className="nav-link text-dark active fs-15"
                                      id="nav-exclude-brands"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-excludeBrands"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-excludeBrands"
                                      aria-selected="true"
                                    >
                                      Exclude brands
                                      <Tooltip
                                        title={tooltipContent(
                                          "manual targeting Negative product targeting optional exlude brands tab"
                                        )}
                                        placement="right"
                                        arrow
                                        className="custom-tooltip-position cursor-pointer "
                                      >
                                        <span className="ms-1">
                                          <svg
                                            viewBox="0 0 512 512"
                                            width="12"
                                            height="12"
                                            className="fas fa-info-circle"
                                            data-fa-i2svg=""
                                          >
                                            <path
                                              fill="#8290a4"
                                              d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    </button>
                                    <button
                                      className="nav-link text-dark fs-15"
                                      id="nav-exclude-products"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-excludePRoducts"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-excludePRoducts"
                                      aria-selected="false"
                                    >
                                      Exclude products
                                      <Tooltip
                                        title={tooltipContent(
                                          "manual targeting Negative product targeting optional exlude products tab"
                                        )}
                                        placement="right"
                                        arrow
                                        className="custom-tooltip-position cursor-pointer shadow"
                                      >
                                        <span className="ms-1">
                                          <svg
                                            viewBox="0 0 512 512"
                                            width="12"
                                            height="12"
                                            className="fas fa-info-circle"
                                            data-fa-i2svg=""
                                          >
                                            <path
                                              fill="#8290a4"
                                              d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </Tooltip>
                                    </button>
                                  </div>
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                  <div
                                    className="tab-pane fade show active suggested-tab"
                                    id="nav-excludeBrands"
                                    role="tabpanel"
                                    aria-labelledby="nav-exclude-brands"
                                    tab-index="0"
                                  >
                                    <div className="row border-top ">
                                      <div className="col-12 px-0 search-input-position exclude-product-search-input-position border-bottom custom-border-color py-2 ">
                                        <span>
                                          <img
                                            src={SearchIcon} alt=""
                                            className="search-position"
                                          />
                                        </span>
                                        <input
                                          className="form-control fs-13 border-dark px-4"
                                          defaultValue={search}
                                          placeholder="  Search by brand name"
                                          onChange={handleSearchInput}
                                        />
                                        <span className="d-none">
                                          <img
                                            src={SearchSecondIcon} alt=""
                                            className="search-second-position custom-button-background"
                                          />
                                        </span>
                                        {showWrongIcon && (
                                          <span>
                                            <img
                                              src={SmallWrongIcon} alt=""
                                              className="search-second-position wrong-icon-position"
                                              onClick={onClickWrong}
                                            />
                                          </span>
                                        )}
                                      </div>
                                      <div className="fs-13 mt-5 ">
                                        Search for brands you want to exclude
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tab-pane fade enterlist-tab"
                                    id="nav-excludePRoducts"
                                    role="tabpanel"
                                    aria-labelledby="nav-exclude-products"
                                    tab-index="0"
                                  >
                                    <div className="row border-top ">
                                      <div className="col-12 px-0">
                                        <div className="tabs-section px-0 mx-0">
                                          <div
                                            className="nav nav-underline px-2 mx-1"
                                            id="nav-tab"
                                            role="tablist"
                                          >
                                            <button
                                              className="nav-link text-dark fs-13"
                                              id="nav-exclude-products-search"
                                              data-bs-toggle="tab"
                                              data-bs-target="#nav-product-excludeProducts-search"
                                              type="button"
                                              role="tab"
                                              aria-controls="nav-product-excludeProducts-search"
                                              aria-selected="true"
                                            >
                                              Search{" "}
                                            </button>
                                            <button
                                              className="nav-link text-dark fs-13"
                                              id="nav-exclude-products-enterlist"
                                              data-bs-toggle="tab"
                                              data-bs-target="#nav-product-excludeProducts-enterlist"
                                              type="button"
                                              role="tab"
                                              aria-controls="nav-product-excludeProducts-enterlist"
                                              aria-selected="false"
                                            >
                                              Enter list
                                            </button>
                                            <button
                                              className="nav-link text-dark fs-13"
                                              id="nav-exclude-products-upload"
                                              data-bs-toggle="tab"
                                              data-bs-target="#nav-product-excludeProducts-upload"
                                              type="button"
                                              role="tab"
                                              aria-controls="nav-product-excludeProducts-upload"
                                              aria-selected="false"
                                            >
                                              Upload{" "}
                                            </button>
                                          </div>
                                          <div
                                            className="tab-content px-2 mx-0"
                                            id="nav-tabContent"
                                          >
                                            <div
                                              className="tab-pane fade show  search-tab"
                                              id="nav-product-excludeProducts-search"
                                              role="tabpanel"
                                              aria-labelledby="nav-exclude-products-search"
                                              tab-index="0"
                                            >
                                              <div className="row border-top">
                                                <div className="col-12 px-0 search-input-position exclude-brand-search-input-position border-bottom custom-border-color py-2 px-3">
                                                  <span>
                                                    <img
                                                      src={SearchIcon} alt=""
                                                      className="search-position"
                                                    />
                                                  </span>
                                                  <input
                                                    className="form-control fs-13 border-dark px-4"
                                                    defaultValue={search}
                                                    placeholder="  Search by product name or ASIN"
                                                    onChange={handleSearchInput}
                                                  />
                                                  <span className="d-none">
                                                    <img
                                                      src={SearchSecondIcon} alt=""
                                                      className="search-second-position custom-button-background"
                                                    />
                                                  </span>
                                                  {showWrongIcon && (
                                                    <span>
                                                      <img
                                                        src={SmallWrongIcon} alt=""
                                                        className="search-second-position wrong-icon-position"
                                                        onClick={onClickWrong}
                                                      />
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="fs-13 mt-5 ">
                                                  Search for products you want to
                                                  target.
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="tab-pane fade show  search-tab"
                                              id="nav-product-excludeProducts-enterlist"
                                              role="tabpanel"
                                              aria-labelledby="nav-exclude-products-enterlist"
                                              tab-index="0"
                                            >
                                              <div className="row border-top">
                                                <div className="fs-13 my-2 mt-4">
                                                  <textarea
                                                    name="text-five"
                                                    className="form-control border-dark fs-13"
                                                    rows={8} defaultValue={''}
                                                    placeholder="Enter ASINs separated by a comma, space or new line."
                                                  ></textarea>
                                                </div>
                                                <div className="fs-13 d-flex justify-content-end my-2">
                                                  <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                                    Exclude
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="tab-pane fade show  search-tab"
                                              id="nav-product-excludeProducts-upload"
                                              role="tabpanel"
                                              aria-labelledby="nav-exclude-products-upload"
                                              tab-index="0"
                                            >
                                              <div className="row fs-13 border-top mb-4 mx-0">
                                                <div className="row my-2 mt-4">
                                                  <div className="mb-2 fs-13 text-start">
                                                    Use the template to make sure
                                                    your information uploads
                                                    accurately.
                                                  </div>
                                                  <div className="text-start mb-2">
                                                    <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                                      <span className="me-2">
                                                        <img src={DownloadIcon} alt="" />
                                                      </span>
                                                      Download the CSV template
                                                    </button>
                                                  </div>
                                                  <div className="upload-files">
                                                    <div
                                                      data-takt-id="ups_upload_asins_input"
                                                      className="upload-icon fs-13"
                                                    >
                                                      <div className="mt-4 pt-2">
                                                        <label
                                                          htmlFor="input_file_three"
                                                          className="cursor-pointer"
                                                        >
                                                          <div className="">
                                                            <div>
                                                              <i className="bi bi-upload"></i>
                                                            </div>
                                                            <div className="fs-13 my-1">
                                                              Drag and drop file
                                                              to add
                                                            </div>
                                                            <div className="fs-13 my-2">
                                                              or click to select
                                                              from your computer
                                                            </div>
                                                            <div className="fs-13 my-1">
                                                              Accepted formats:
                                                              CSV, TSV, XLSX
                                                            </div>
                                                          </div>
                                                        </label>
                                                        <input
                                                          type="file"
                                                          id="input_file_three"
                                                          name="file"
                                                          className="d-none "
                                                          onChange={
                                                            handleQuotationFileUpload
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 product-preview-container">
                                <div className="d-flex justify-content-between  border-bottom py-2 px-2">
                                  <div className="fs-13 fw-bold ">0 added</div>
                                  <div className="fs-13 cursor-pointer-not-allowed remove-all-text ">
                                    Remove all
                                  </div>
                                </div>
                                <div className="fs-12 pt-4 mt-2 pb-2 px-2  border-bottom text-start">
                                  Brands and products
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/*  Negative product targeting for  product targeting Card Ends  */}

              {/*   Campaign bidding strategy Card Starts  */}
              <div className="mx-auto card-width campaign-heading">
                <h1 className="fs-28">Campaign</h1>
              </div>
              <div className="campaign-bidding-card-container card-width mx-auto">
                <div className="card text-bg-white ad-group-settings mb-4 my-3">
                  <div className="card-header bg-white">
                    <div className="d-flex justify-content-between py-2">
                      <div className="fs-24 fw-light">
                        Campaign bidding strategy
                        <Tooltip
                          title={tooltipContent("Campaign bidding strategy")}
                          placement="top"
                          arrow
                          className="custom-tooltip-position cursor-pointer"
                        >
                          <span className="ms-1 cursor-pointer">
                            <svg
                              viewBox="0 0 512 512"
                              width="12"
                              height="12"
                              className="fas fa-info-circle"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="#8290a4"
                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                              ></path>
                            </svg>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                        <span>
                          <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                        </span>
                        How to set your bid strategy
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex form-check">
                      <input
                        type="radio"
                        className="form-check-input mb-3 me-3"
                        name="campaign-bid-status"
                        id="bidUpAndDown"
                        checked={
                          campaignBiddinStrategy === "Dynamic bids - up and down"
                        }
                        onChange={() =>
                          onChangeCampaignBiddingType(
                            "Dynamic bids - up and down"
                          )
                        }
                      />
                      <label className="cursor-pointer" htmlFor="bidUpAndDown">
                        <div className="fs-13">
                          Dynamic bids - up and down
                          <Tooltip
                            title={tooltipContent("Dynamic bids - up and down")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="fs-13">
                          We will raise your bids (by a maximum of 100%) in real
                          time when your ad may be more likely to{" "}
                        </div>
                        <div className="fs-13">
                          convert to a sale, and lower your bids when less likely
                          to convert to a sale.
                        </div>
                      </label>
                    </div>
                    <div className="d-flex form-check my-2">
                      <input
                        type="radio"
                        className="form-check-input mb-3 me-3"
                        name="campaign-bid-status"
                        id="bidDownOnly"
                        checked={
                          campaignBiddinStrategy === "Dynamic bids - down only"
                        }
                        onChange={() =>
                          onChangeCampaignBiddingType("Dynamic bids - down only")
                        }
                      />
                      <label className="cursor-pointer" htmlFor="bidDownOnly">
                        <div className="fs-13">
                          Dynamic bids - down only
                          <Tooltip
                            title={tooltipContent("Dynamic bids - down only")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="fs-13">
                          We will lower your bids in real time when your ad may be
                          less likely to convert to a sale.
                        </div>
                      </label>
                    </div>
                    <div className="d-flex form-check my-2">
                      <input
                        type="radio"
                        className="form-check-input mb-3 me-3"
                        name="campaign-bid-status"
                        id="fixedBids"
                        checked={campaignBiddinStrategy === "Fixed bids"}
                        onChange={() => onChangeCampaignBiddingType("Fixed bids")}
                      />
                      <label className="cursor-pointer" htmlFor="fixedBids">
                        <div className="fs-13">
                          Fixed bids
                          <Tooltip
                            title={tooltipContent("Fixed bids")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer "
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="12"
                                height="12"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="fs-13">
                          We will use your exact bid and any manual adjustments
                          you set and will not change your bids based on
                          likelihood of a sale.
                        </div>
                      </label>
                    </div>
                    <div>
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushAdjustPlacement"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="flush-headingAdjust"
                          >
                            <button
                              className="accordion-button adjust-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseAdjust-placement"
                              aria-expanded="true"
                              aria-controls="flush-collapseAdjust-placement"
                            >
                              <span className="custom-explore-text-color fs-13 logout cursor-pointer ps-3">
                                Adjust bids by placement
                              </span>
                              <Tooltip
                                title={tooltipContent("Adjust bids by placement")}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer"
                              >
                                <span className="ms-1">
                                  <svg
                                    viewBox="0 0 512 512"
                                    width="12"
                                    height="12"
                                    className="fas fa-info-circle mb-3"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="#8290a4"
                                      d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                    ></path>
                                  </svg>
                                </span>
                              </Tooltip>
                            </button>
                          </h2>
                          <div
                            id="flush-collapseAdjust-placement"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-headingAdjust"
                            data-bs-parent="#accordionFlushAdjustPlacement"
                          >
                            <div className="accordion-body px-4">
                              <div className="fs-13">
                                In addition to your bidding strategy, you can
                                increase bids by up to 900%.<span className="back-to-help-text logout cursor-pointer" onClick={() => onClickLearnMore('campaign-bidding-strategy')}>Learn more</span>
                              </div>
                              <div className="d-flex my-1">
                                <div className="fs-13 w-18">
                                  Top of search (first page)
                                </div>
                                <div className="fs-13 w-15 px-3">
                                  <input
                                    type="number"
                                    name="dynamicBidding_percentage"
                                    className="form-control border-dark fs-13"
                                    defaultValue={0}
                                    onChange={(e) => handleInput(e)}
                                    placeholder=""
                                  />
                                  <span className="percentage-position">%</span>
                                  {formErrors.dynamicBidding_percentage && (<div className="text-danger">{formErrors?.dynamicBidding_percentage}</div>)}
                                </div>
                                {showDynamicCampaignBid && (
                                  <div className="fs-13">
                                    Example: A ₹3.00 bid will remain ₹3.00 for
                                    this placement. Dynamic bidding could increase
                                    it up to ₹6.00
                                  </div>
                                )}
                                {!showDynamicCampaignBid && (
                                  <div className="fs-13">
                                    Example: A ₹3.00 bid will remain ₹3.00 for
                                    this placement.
                                  </div>
                                )}
                              </div>
                              <div className="d-flex my-1">
                                <div className="fs-13 w-18">Rest of search </div>
                                <div className="fs-13 w-15 px-3">
                                  <input
                                    type="number"
                                    name="dynamicBidding_percentage_rest"
                                    className="form-control border-dark fs-13"
                                    placeholder=""
                                    defaultValue={0}
                                    onChange={(e) => handleInput(e)}
                                  />
                                  <span className="percentage-position">%</span>
                                  {formErrors.dynamicBidding_percentage_rest && (<div className="text-danger">{formErrors?.dynamicBidding_percentage_rest}</div>)}
                                </div>
                                {showDynamicCampaignBid && (
                                  <div className="fs-13">
                                    Example: A ₹3.00 bid will remain ₹3.00 for
                                    this placement. Dynamic bidding could increase
                                    it up to ₹4.50
                                  </div>
                                )}
                                {!showDynamicCampaignBid && (
                                  <div className="fs-13">
                                    Example: A ₹3.00 bid will remain ₹3.00 for
                                    this placement.
                                  </div>
                                )}
                              </div>
                              <div className="d-flex my-1">
                                <div className="fs-13 w-18">Product pages</div>
                                <div className="fs-13 w-15 px-3">
                                  <input
                                    type="number"
                                    name="dynamicBidding_percentage_product"
                                    className="form-control border-dark fs-13"
                                    placeholder=""
                                    defaultValue={0}
                                    onChange={(e) => handleInput(e)}
                                  />
                                  <span className="percentage-position">%</span>
                                  {formErrors.dynamicBidding_percentage_product && (<div className="text-danger">{formErrors?.dynamicBidding_percentage_product}</div>)}
                                </div>
                                {showDynamicCampaignBid && (
                                  <div className="fs-13">
                                    Example: A ₹3.00 bid will remain ₹3.00 for
                                    this placement. Dynamic bidding could increase
                                    it up to ₹4.50
                                  </div>
                                )}
                                {!showDynamicCampaignBid && (
                                  <div className="fs-13">
                                    Example: A ₹3.00 bid will remain ₹3.00 for
                                    this placement.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*   Campaign bidding strategy Card Ends  */}

              {/* Settings Card Starts  */}
              {campaignCreationType !== "AI" && (
                <div className="settings-card-container card-width mx-auto">
                  <div className="card text-bg-white ad-group-settings mb-3 my-0 ">
                    <div className="card-header bg-white">
                      <div className="d-flex justify-content-between py-2">
                        <div className="fs-24 fw-light">Settings </div>
                        <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                          <span>
                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                          </span>
                          How to set up your campaign
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div>
                        <h5 className="card-title fs-14 fw-700">
                          Campaign name
                          <Tooltip
                            title={tooltipContent("Settings Campaign name")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-info-circle" data-fa-i2svg="">
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </h5>
                        <div className="row d-flex justify-content-start">
                          <div className="input-group mb-3 w-50">
                            <span className="input-group-text campaign-drop-down-input border-dark fs-13" id="basic-addon1">adv_SPM_</span>
                            <input type="text" name="campaign_name" className="form-control campaign-drop-down-input border-dark border-start-0" placeholder="Campaign name" aria-label="Username"
                              aria-describedby="basic-addon1" onChange={(e) => handleInput(e)} defaultValue={campaignCreationData?.campaign_name ? campaignCreationData?.campaign_name : ""} />
                            {formErrors.campaign_name && (
                              <div className="text-danger text-start w-100">
                                {formErrors.campaign_name}
                              </div>
                            )}
                          </div>
                          <div className="w-50">{campaignName?.trim().length > 0 ? <>{`adv_SPM_${campaignName}`} </> : <></>}</div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <h5 className="card-title fs-14 fw-700">
                          Portfolio
                          <Tooltip
                            title={tooltipContent("Settings Portfolio")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer"
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="13"
                                height="13"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </h5>
                        <div className="col-4 pe-3 campaign-name-width">
                        </div>
                        <div>
                          <select name="portfolio_id" className='form-select w-25 border-dark input' value={portfolioId ? portfolioId : campaignCreationData?.portfolio_id} onChange={handleInput}>
                            <option value={""}>Select portfolio</option>
                            {portfoliosData?.map((data: any, portfolioIndex: number) => (
                              <option className='' key={portfolioIndex} value={data?.value}>{data?.title}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex my-2 py-2">
                        <div className="co-12">
                          <h5 className="card-title fs-14 fw-700">
                            Start
                            <Tooltip
                              title={tooltipContent("Settings Start")}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer"
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="13"
                                  height="13"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </h5>
                          <div className="pe-3 campaign-name-width">
                            <input
                              type="date"
                              name="start_date"
                              className="form-control input border-dark fs-13"
                              defaultValue=""
                              min={currentDate}
                              onChange={(e) => handleInput(e)}
                            />
                            {formErrors.start_date && (<div className="text-danger">{formErrors.start_date}</div>)}
                          </div>
                        </div>
                        <div>
                          <h5 className="card-title fs-14 fw-700">
                            End
                            <Tooltip
                              title={tooltipContent("Settings End")}
                              placement="top"
                              arrow
                              className="custom-tooltip-position cursor-pointer"
                            >
                              <span className="ms-1">
                                <svg
                                  viewBox="0 0 512 512"
                                  width="13"
                                  height="13"
                                  className="fas fa-info-circle"
                                  data-fa-i2svg=""
                                >
                                  <path
                                    fill="#8290a4"
                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                  ></path>
                                </svg>
                              </span>
                            </Tooltip>
                          </h5>
                          <div className="pe-3 campaign-name-width">
                            <input
                              type="date"
                              name="end_date"
                              min={campaignCreationData?.start_date}
                              className="form-control input border-dark fs-13"
                              defaultValue=""
                              onChange={(e) => handleInput(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <h5 className="card-title fs-14 fw-700 mb-0">Country </h5>
                        <div className="fs-14">India </div>
                      </div>
                      <div className="my-2">
                        <h5 className="card-title fs-14 fw-700">
                          Daily budget
                          <Tooltip
                            title={tooltipContent("Daily budget")}
                            placement="top"
                            arrow
                            className="custom-tooltip-position cursor-pointer "
                          >
                            <span className="ms-1">
                              <svg
                                viewBox="0 0 512 512"
                                width="13"
                                height="13"
                                className="fas fa-info-circle"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="#8290a4"
                                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                ></path>
                              </svg>
                            </span>
                          </Tooltip>
                        </h5>
                        <div className="col-3 col-md-2 pe-4 campaign-name-width">
                          <div className="input-group mb-3 my-2">
                            <span className="input-group-text input bg-white border-dark">
                              <img src={RupeeIcon} alt="" className="" />
                            </span>
                            <input
                              type="number"
                              name="budget"
                              defaultValue={''}
                              min={50}
                              className="form-control input border-dark border-start-0"
                              onChange={(e) => handleInput(e)}
                            />
                          </div>
                        </div>
                        {formErrors.budget && (
                          <div className="text-danger text-start w-100">
                            {formErrors.budget}
                          </div>
                        )}

                      </div>
                      <div className="my-2">
                        <h5 className="card-title fs-14 fw-700">
                          Roas
                        </h5>
                        <div className="col-3 col-md-2 pe-4 campaign-name-width">
                          <div className="input-group mb-3 my-2">
                            <span className="input-group-text input bg-white border-dark">
                              <img src={RupeeIcon} alt="" className="" />
                            </span>
                            <input
                              type="number"
                              name="roas"
                              defaultValue={'2'}
                              className="form-control input border-dark border-start-0"
                              onChange={(e) => handleInput(e)}
                            />
                          </div>
                        </div>
                        {formErrors.roas && (
                          <div className="text-danger text-start w-100">
                            {formErrors.roas}
                          </div>
                        )}

                      </div>
                      <div className="my-2">
                        <h5 className="card-title fs-14 fw-700">
                          Benchmark
                        </h5>
                        <div className="col-3 col-md-2 pe-4 campaign-name-width">
                          <div className="input-group mb-3 my-2">
                            <input
                              type="number"
                              name="benchmark"
                              className="form-control input border-dark"
                              onChange={(e) => handleInput(e)}
                            />
                          </div>
                        </div>
                        {formErrors.benchmark && (
                          <div className="text-danger text-start w-100">
                            {formErrors.benchmark}
                          </div>
                        )}

                      </div>
                      <div className="my-2">
                        <h5 className="card-title fs-14 fw-700">
                          Qualifier
                        </h5>
                        <div className="col-3 col-md-2 pe-4 campaign-name-width">
                          <div className="input-group mb-3 my-2">
                            <input
                              type="number"
                              name="qualifier"
                              className="form-control input border-dark"
                              onChange={(e) => handleInput(e)}
                            />
                          </div>
                        </div>
                        {formErrors.qualifier && (
                          <div className="text-danger text-start w-100">
                            {formErrors.qualifier}
                          </div>
                        )}

                      </div>
                      <div className="my-2">
                        <h5 className="card-title fs-14 fw-700">
                          Duration
                        </h5>
                        <div className="col-3 col-md-2 pe-4 campaign-name-width">
                          <div className="input-group mb-3 my-2">
                            <select name="duration" className='form-select w-25 border-dark input' value={campaignCreationData?.duration} onChange={handleInput}>
                              <option value={""}>Select duration</option>
                              {durationsList?.map((data: any, portfolioIndex: number) => (
                                <option className='' key={portfolioIndex} value={data?.value}>{data?.name}</option>
                              ))}
                            </select>
                            {/* <input
                              type="number"
                              name="duration"
                              className="form-control input border-dark border-start-0"
                              onChange={(e) => handleInput(e)}
                            /> */}
                          </div>
                        </div>
                        {formErrors.duration && (
                          <div className="text-danger text-start w-100">
                            {formErrors.duration}
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Settings Card Ends  */}

              {/* AiCampaignDataProductsListCard */}
              {portfolioId && campaignCreationType === "AI" && (
                <div className="settings-card-container card-width mx-auto">
                  <div className="card text-bg-white ad-group-settings mb-3 my-0">
                    <div className="card-body">
                      <div className="my-3 mx-1 pe-4">
                        <AiCampaignsTabsPage aiCampaignsProductsData={aiCampaignProdcutsData} cardDataShow={true} />
                      </div>
                      <AiCampaignDataProductsListCard />
                    </div>
                  </div>
                </div>
              )}

              <div className="buttons-container card-width mx-auto">
                <div className="d-flex justify-content-end">
                  <div className="">
                    <button
                      className="request-call-button text-white fs-13 px-4 px-sm-5"
                      onClick={onClickLanuchCampaign}
                    >
                      Launch Campaign
                    </button>
                  </div>
                </div>
              </div>
              <div className="footer-container campaign-creation-footer mt-5 pt-5 pb-2">
                <CustomFooter />
              </div>
              {/* Show add modal for prodcut targeting Starts  */}
              {showAddModal && (
                <div
                  className={`modal modal-custom fade ${showAddModal ? "show" : "false"
                    } ${showAddModal ? "modal-show" : "modal-hide"}`}
                  tab-index="-1"
                  role="dialog"
                >
                  <div
                    className="modal-dialog modal-dialog-centered add-modal"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header border-bottom-0">
                        <h5 className="modal-title fs-13">
                          The target does not have a suggested bid
                        </h5>
                      </div>
                      <div className="modal-body border-top-0 p-0">
                        <div className="col-12 px-2 fs-13 pb-2">
                          <div className="d-flex justify-content-start">
                            <div className="fs-13 ms-2 mt-1">Choose bid:</div>
                            <div className="input-group mx-1 choose-bid">
                              <span className="input-group-text input bg-white border-dark">
                                <img src={RupeeIcon} alt="" className="" />
                              </span>
                              <input
                                type="text"
                                className="form-control input border-dark border-start-0 choose-bid-input-width"
                                value={"3.00"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="custom-explore-text-color border-0 bg-transparent fs-13 logout"
                          onClick={() => setShowAddModal(false)}
                        >
                          Do not add target
                        </button>
                        <button
                          type="button"
                          className="request-call-button text-white fs-11"
                        >
                          add target
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Show add modal for prodcut targeting Ends  */}

              {/* Show Leave Page Modal Starts  */}
              {showLeavePageModal && (
                <div
                  className={`modal modal-custom fade ${showLeavePageModal ? "show" : "false"
                    } ${showLeavePageModal ? "modal-show" : "modal-hide"}`}
                  tab-index="-1"
                  role="dialog"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content leave-modal mx-auto">
                      <div className="modal-header border-bottom-0">
                        <h5 className="modal-title fs-17 fw-400">
                          Leave Page?
                        </h5>
                        <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowLeavePageModal(false)}></button>
                      </div>
                      <div className="modal-body border-top p-0">
                        <div className="col-12 px-2 fs-13  py-3">
                          <div className="d-flex justify-content-start">
                            <div className="fs-13 ms-2 mt-1">Changes you made may not be saved.</div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="custom-explore-text-color border-0 bg-transparent fs-13 logout"
                          onClick={() => setShowLeavePageModal(false)}
                        >
                          Stay
                        </button>
                        <button
                          type="button"
                          className="request-call-button text-white fs-11"
                          onClick={onLeavePage}
                        >
                          Leave
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Show Leave Page Modal Ends  */}
              {showAlternateBidModal && (
                <div className={`modal modal-custom fade ${showAlternateBidModal ? "show" : "false"}  ${showAlternateBidModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                  <div className="modal-dialog modal-sm modal-dialog-centered " role="document">
                    <div className="modal-content mx-auto ">
                      <div className='modal-body p-3 border-bottom'>
                        <div className='fs-13'>3 of 3 keywords do not have a suggested bid</div>
                        <div className='d-flex justify-content-center my-2'>
                          <div className='fs-13 alternate-label-width my-2'>Choose an alternate bid:</div>
                          <div className='d-flex justify-content-end'>
                            <input type="number" name="alternate_bid" className='form-control input border-dark alternate-bid-modal' onChange={(e) => handleAlternateInput(e)} defaultValue={alternateBidData?.alternate_bid} />
                          </div>
                        </div>
                        {enterlistKeywordFormErrors.alternate_bid && (<div className='text-danger text-start'>{enterlistKeywordFormErrors?.alternate_bid}</div>)}
                      </div>
                      <div className="modal-footer border-top d-flex justify-content-end">
                        <button
                          type="button"
                          className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                          onClick={() => setShowAlternateBidModal(false)}
                        >
                          Do not add keywords
                        </button>
                        <button type="button" className={`request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer`}
                          onClick={onAddAlternateEnterListBid}
                        >
                          Add keywords
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
};