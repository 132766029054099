import React, { useEffect, useRef, useState } from 'react'

interface Props {
    ismodalShow: any;
    modalStatus: any;
    OnLeavePageStatus?: any;
}

export const ShowLeavePageModal = (props: Props) => {
    const [showLeaveModal, setShowLeaveModal] = useState<boolean>(props.ismodalShow);
    const modalRef: any = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowLeaveModal(false);
            }
        };

        const handleEscapeKey = (event: any) => {
            if (event.key === 'Escape') {
                setShowLeaveModal(false);
            }
        };

        if (showLeaveModal) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [showLeaveModal]);

    const onLeavePage = () => {
        props.OnLeavePageStatus(true)
    }

    return (
        <>
            {showLeaveModal &&
                <div
                    className={`modal modal-custom fade ${showLeaveModal ? "show" : "false"
                        } ${showLeaveModal ? "modal-show" : "modal-hide"}`}
                    tab-index="-1"
                    role="dialog"
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        role="document" ref={modalRef}
                    >
                        <div className="modal-content leave-modal mx-auto">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">
                                    Leave Page?
                                </h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowLeaveModal(false)}></button>
                            </div>
                            <div className="modal-body border-top p-0">
                                <div className="col-12 px-2 fs-13  py-3">
                                    <div className="d-flex justify-content-start">
                                        <div className="fs-13 ms-2 mt-1">Changes you made may not be saved.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="custom-explore-text-color border-0 bg-transparent fs-13 logout"
                                    onClick={() => setShowLeaveModal(false)}
                                >
                                    Stay
                                </button>
                                <button
                                    type="button"
                                    className="request-call-button text-white fs-11"
                                    onClick={onLeavePage}
                                >
                                    Leave
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
