import { DataTableCol } from "../../../components/data-table/types";

export const ProductTargetingRightSideTableDataGridCols: DataTableCol[] = [
    {
        title: 'Categories and products',
        control: 'property_type',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Sugg. bid',
        control: 'userName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Bid',
        control: 'userName',
        sortable: true,
        canShowColumn: true
    },
]
    