import {
    CAMPAIGNDATA, ADGROUPADSDATA, LOADING, PROFILEDATA, USERDATA, USERUUIDDATA, ADGROUPSETTINGSDATA
    , CAMPAIGNBIDDINGCAMPAIGNDETAILSDATA, AICAMPAIGNLOADINGDATA, CAMPAIGNSETTINGSDATA
} from "../types";
import {
    CampaignDataReducerState,
    AdGroupdAdsDataReducerState,
    LoadingReducerState,
    ProfileDataReducerState,
    UserDataReducerState,
    UserUuidDataReducerState,
    AdGroupdSettingsDataReducerState,
    CampaignBiddingCampaignDetailsDataReducerState,
    AiCampaignLoadingDataReducerState,
    CampaignSettingsDataReducerState,
} from "./user-reducer-state";

const loadingInitialState: LoadingReducerState = {
    loading: false,
};

const userDataInitialState: UserDataReducerState = {
    userData: {},
};

const profileDataInitialState: ProfileDataReducerState = {
    profileData: {},
}

const campaignDataInitialState: CampaignDataReducerState = {
    campaignData: {},
}

const adGroupsAdsDataInitialState: AdGroupdAdsDataReducerState = {
    adgroupsAdsData: {},
}

const adGroupsSettingsDataInitialState: AdGroupdSettingsDataReducerState = {
    adgroupsSettingsData: {},
}

const campaignSettingsDataInitialState: CampaignSettingsDataReducerState = {
    campaignSettingsData: {},
}

const campaignBiddingCampaignDetailsDataInitialState: CampaignBiddingCampaignDetailsDataReducerState = {
    campaignBiddingCampainDetailsData: {},
}


const aiCampaignLoadingDataInitialState: AiCampaignLoadingDataReducerState = {
    aiCampaignLoadingData: {},
}


const userUuidDataInitialState: UserUuidDataReducerState = {
    userUuidData: {},
}

export const LoadingReducer: any = (
    loading: LoadingReducerState = loadingInitialState,
    action: any
) => {
    switch (action.type) {
        case LOADING:
            return { loading: action.data };
        default:
            return loading;
    }
};

export const UserData: any = (
    isLogin: UserDataReducerState = userDataInitialState,
    action: any
) => {
    switch (action.type) {
        case USERDATA:
            return action.data;
        default:
            return isLogin;
    }
};

export const UserUuidData: any = (
    isLogin: UserUuidDataReducerState = userUuidDataInitialState,
    action: any
) => {
    switch (action.type) {
        case USERUUIDDATA:
            return action.data;
        default:
            return isLogin;
    }
};

export const ProfileData: any = (
    isLogin: ProfileDataReducerState = profileDataInitialState,
    action: any
) => {
    switch (action.type) {
        case PROFILEDATA:
            return action.data;
        default:
            return isLogin;
    }
}

export const CampaignData: any = (
    isLogin: CampaignDataReducerState = campaignDataInitialState,
    action: any
) => {
    switch (action.type) {
        case CAMPAIGNDATA:
            return action.data;
        default:
            return isLogin;
    }
}

export const AdgroupsAdsData: any = (
    isLogin: AdGroupdAdsDataReducerState = adGroupsAdsDataInitialState,
    action: any
) => {
    switch (action.type) {
        case ADGROUPADSDATA:
            return action.data;
        default:
            return isLogin;
    }
}

export const AdgroupsSettingsData: any = (
    isLogin: AdGroupdSettingsDataReducerState = adGroupsSettingsDataInitialState,
    action: any
) => {
    switch (action.type) {
        case ADGROUPSETTINGSDATA:
            return action.data;
        default:
            return isLogin;
    }
}

export const CampaignBiddingCampaignDetailsData: any = (
    isLogin: CampaignBiddingCampaignDetailsDataReducerState = campaignBiddingCampaignDetailsDataInitialState,
    action: any
) => {
    switch (action.type) {
        case CAMPAIGNBIDDINGCAMPAIGNDETAILSDATA:
            return action.data;
        default:
            return isLogin;
    }
}

export const AiCampaignLoadingData: any = (
    isLogin: AiCampaignLoadingDataReducerState = aiCampaignLoadingDataInitialState,
    action: any
) => {
    switch (action.type) {
        case AICAMPAIGNLOADINGDATA:
            return action.data;
        default:
            return isLogin;
    }
}

export const CampaignSettingsData: any = (
    isLogin: CampaignSettingsDataReducerState = campaignSettingsDataInitialState,
    action: any
) => {
    switch (action.type) {
        case CAMPAIGNSETTINGSDATA:
            return action.data;
        default:
            return isLogin;
    }
}
