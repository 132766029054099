import API from "../utils/axios";
const api_url = "/api";

export const GET_USER_CAMPAIGNS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/getUserCampaign`, data);
};

export const GET_PROFILE_PRODUCTS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/getProfileProducts`, data);
};

export const GET_KEYWORD_SUGGESTIONS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/getKeywordSuggestions`, data);
};

export const CREATE_CAMPAIGN = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/createCampaign`, data);
};

export const UPDATE_CAMPAIGN_STATUS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/updateCampaignStatus`, data);
};


export const CREATE_AI_KEYWORD_CAMPAIGN = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/createCampaignAI`, data);
};

export const CREATE_AI_GROUP_CAMPAIGN = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/createCampaignAIGroups`, data);
};

export const UPDATE_CAMPAIGN_SETTINGS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/updateCampaign`, data);
};


export const CHECK_PRODUCT_ELIGIBILITY = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/checkProductEligibility`, data);
};

export const EXPORT_CAMPAIGNS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/exportCampaignsData`, data);
};