import React, { useEffect } from 'react'
import GlobeIcon from './../../assets/images/svg_icons/globe.svg';
import ActiveSponsored from './../../assets/images/svg_icons/active-sponsored-ads.svg'
import { SidebarPage } from '../sidebar/sidebar';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';

interface Props {
    UserDataReducer?: any;
    userData?: any;
    profileHomeIcon?: any;
}

const Header = (props: Props) => {
    const navigate = useNavigate();
    const userName = localStorage.getItem('user_name');
    const userEmail = localStorage.getItem('user_email');

    const onClickLogout = () => {
        navigate("/");
    };

    const onSignout = () => {
        localStorage.clear();
        navigate("/");
    }

    return (
        <div className="col-12 px-0">
            <nav className="navbar navbar-expand-md navbar-light custom-background-color text-white top_menu offcanvas-view">
                <div className="container-fluid">
                    <div className="d-sm-flex justify-content-center">
                        <div className='my-1 my-sm-0'>
                            <a className="navbar-brand text-white ps-1 pe-3">Advise</a>
                        </div>
                        <div>
                            <div className="d-flex justify-content-center">
                                <div className="cursor-pointer">
                                    <img src={ActiveSponsored} className="dashboard-advice-beside-icon" />
                                </div>
                                <div className="mx-3">
                                    <div className="fs-12">Sponsored ads</div>
                                    <div className="fs-12 fw-semibold">Campaign manager</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="navbar-toggler d-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon bg-white rounded"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-block d-md-none">
                            <aside>
                                <SidebarPage />
                            </aside>
                        </ul>
                        <div className="d-flex justify-content-center d-block d-md-none ">
                            <button
                                className="btn btn-outline-light"
                                type="submit"
                                onClick={onClickLogout}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                    <div className="d-flex d-block d-md-block">
                        <div className="d-flex justify-content-between">
                            <div className="d-none d-flex justify-content-center notification-beside-text rounded-pill cursor-pointer user_profile_style">
                                <div className="me-2  pt_6px">
                                    <img src={GlobeIcon} className="globe-image" />
                                </div>
                                <div className="pt_4px">
                                    <div className="fs-12 profile_name">MRSOFT</div>
                                    <div className="fs-12 sponsredads-text-color profile_name">Sponsored ads, India</div>
                                </div>
                            </div>
                            <button
                                className="btn border-0 "
                                type="submit"
                            >
                                <div className="d-flex">
                                    <div className="logout-hover p-2 rounded">
                                        <div className="dropdown">
                                            <i className="bi bi-person-circle text-white dashboard-icon-style cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                            <ul className="dropdown-menu profile-dropdown profile">
                                                <li>
                                                    <div className="dropdown-item border-bottom">
                                                        <div className="fs-17 custom-dropdown-listitem-color fw-400">{userName}</div>
                                                        <div className="fs-13 py-1">{userEmail}</div>
                                                    </div>
                                                </li>
                                                <li><a className="dropdown-item fs-13 custom-explore-text-color logout" onClick={onSignout}>Sign out</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.UserDataReducer,
    };
};

const connectedNavBar = connect(mapStateToProps)(Header);
export { connectedNavBar as Header };
