import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const AiCampaignsPage = () => {
    const navigate = useNavigate();
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = portfolio_id;

    const aiCamapignsData: any = [
        {
            id: "1",
            campaign_title: "Product Campaign",
            camapign_sub_title: "Define campaign objective",
            campaign_description: "Details of the campaign objective for the user to select the specific rules that govern the campaign",
            campaign_images: [
                { url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_1.svg" },
                { url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_2.svg" },
                { url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_1.svg" }
            ],
            ad_group_name: "test ad group 1"
        },
        {
            id: "2",
            campaign_title: "Group's Campaign",
            camapign_sub_title: "Define campaign objective",
            campaign_description: "Details of the campaign objective for the user to select the specific rules that govern the campaign",
            campaign_images: [
                { url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_1.svg" },
                { url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_2.svg" },
                { url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_1.svg" }
            ],
            ad_group_name: "test ad group 2"
        },
    ]

    const onClickSelect = (e: any, data: any, index: number) => {
        if (data?.id) {
            const type = "AI";
            navigate(`/new-campaign/AI/${type}/${data?.id}`);
            if (portfolioId) {
                navigate(`/new-camapign/portfolio/${portfolioId}/${type}/${data?.id}`)
            }
        }
    }

    const onViewCampaignTypes = () => {
        navigate(`/dashboard/campaign-type`)
    }

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className="d-md-flex justify-content-between my-4 mx-auto cards-container">
                        <h1 className="fs-28 choose-campaign-font-family">Choose your campaigns </h1>
                        <div className="">
                            <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer" onClick={onViewCampaignTypes}>Back to campaign types</button>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row d-flex justify-content-center '>
                            {aiCamapignsData?.map((cardData: any, index: number) => (
                                <div key={index} className='campaign-card my-2'>
                                    <div className="card text-center shadow-sm">
                                        <div className="card-header bg-white text-start fs-17 fw-700">
                                            {cardData?.campaign_title}
                                        </div>
                                        <div className="card-body">
                                            <div id={`carouselExampleCaptions-${index}`} className="carousel slide">
                                                <div className="carousel-indicators normal-carousel-indicators">
                                                    {cardData?.campaign_images?.map((image: any, i: number) => (
                                                        <button
                                                            key={i}
                                                            type="button"
                                                            data-bs-target={`#carouselExampleCaptions-${index}`}
                                                            data-bs-slide-to={i}
                                                            className={i === 0 ? 'active slide-icon' : 'slide-icon'}
                                                            aria-label={`Slide ${i + 1}`}
                                                        ></button>
                                                    ))}
                                                </div>
                                                <div className="carousel-inner">
                                                    {cardData?.campaign_images?.map((image: any, i: number) => (
                                                        <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                                                            <img src={image?.url} className="d-block w-100" alt="..." />
                                                            <div className="carousel-caption d-none d-md-block"></div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                            <h5 className="card-title text-start mt-4 my-2 fs-13 fw-700">{cardData?.camapign_sub_title}</h5>
                                            <p className="card-text text-start card-description fs-13 fw-400" >{cardData?.campaign_description}</p>
                                            <button className="btn btn-primary custom-button-background-color col-12 rounded-pill fs-13 cursor-pointer" onClick={(e) => { onClickSelect(e, cardData, index) }}>SELECT</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
