import React, { useState } from 'react'
import { ConfigurationRoutes } from '../../../Routes/configuration';

export const ConfigurationPage = () => {


    return (
        <div className='container-fluid'>
            <ConfigurationRoutes />
        </div>
    )
}
