import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GET_SPECIFIC_AD_GROUP, UPDATE_AD_GROUP_STATUS } from '../../../../../app/services/adgroups.service';
import { toast } from "react-toastify";
import { connect, useDispatch } from 'react-redux';
import { AdgroupsSettingsData } from '../../../../../redux/actions';

interface Props {
    CampaignDataReducer?: any;
    campaignData?: any;
    AdGroupSettingsDataReducer?: any;
    adgroupsSettingsData?: any;
}

const AdGroupSettingsPage = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [activeStatus, setActiveStatus] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [adsUpdateData, setAdsUpdateData] = useState<any>([{
        ad_group_name: "",
        default_bid: "",
    }]);
    const [adGroupDataByID, setAdGroupDataByID] = useState<any>([]);
    const [formErrors, setFormErrors] = useState<any>({});
    const navigate = useNavigate();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const currentDate = new Date().toISOString().split('T')[0];
    const adGRoupID: any = localStorage.getItem("ad_group_id");

    const adGroupSettingsData = [
        {
            campaingn_title: "Ad group name"
        },
        {
            campaingn_title: "Ad group ID"
        },
        {
            campaingn_title: "Active / Paused"
        },
        {
            campaingn_title: "Status"
        },
        {
            campaingn_title: "Default bid"
        },
    ]

    useEffect(() => {
        getAdGroupById(adGRoupID);
    }, [])


    const getAdGroupById = (adGroupID: any) => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            ad_group_id: adGroupID
        }
        setLoading(true);
        GET_SPECIFIC_AD_GROUP(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    const camapignData: any = localStorage.getItem('campaign_data');
                    const sessionCamapignData = JSON.parse(camapignData);
                    response?.data?.forEach((element: any) => {
                        element.campaign_state = props?.campaignData?.state ? props?.campaignData?.state : sessionCamapignData?.state;
                        element.campaign_start_date = props?.campaignData?.start_date ? props?.campaignData?.start_date : sessionCamapignData?.start_date;
                    });
                    setAdGroupDataByID(response?.data[0])
                    setAdsUpdateData(response?.data[0])
                    setLoading(false);
                }
            }
        });
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setAdsUpdateData((prevData: any) => ({
            ...prevData,
            [name]: value
        }))
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "ad_group_name") {
            if (!value) {
                return "Please enter ad group name"
            }
        }
        if (name === "default_bid") {
            if (!value) {
                return "Please enter default bid"
            } else if (value <= 0) {
                return "Default bid value should be start from 1"
            }
        }
    }

    const validateForm = () => {
        const errors: any = {};

        if (!adsUpdateData?.ad_group_name) {
            errors.ad_group_name = "Please enter ad group name";
        }

        if (!adsUpdateData?.default_bid) {
            errors.default_bid = "Please enter default bid";
        }

        setFormErrors(errors);
        return Object.keys(errors)?.length === 0;
    }

    const handleActiveChanges = (e: any) => {
        const status = e.target.checked ? true : false;
        setActiveStatus(status);
    }

    const onClickCancelAdGroupSettings = () => {
        navigate("/dashboard/campaign/ad-groups")
    }

    const onClickSaveChanges = () => {
        const isValid = validateForm();
        if (isValid) {
            const preparedData = {
                user_uuid: adsUpdateData?.user_uuid,
                profile_id: adsUpdateData?.profile_id,
                ad_group_id: adsUpdateData?.ad_group_id,
                campaign_id: campaignID,
                state: activeStatus ? "ENABLED" : "PAUSED",
                ad_group_name: adsUpdateData?.ad_group_name,
                default_bid: adsUpdateData?.default_bid
            }

            setLoading(true);
            UPDATE_AD_GROUP_STATUS(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        toast.success(res.data.message);
                        navigate("/dashboard/ad-groups/ad-group-settings")
                        setTimeout(() => {
                            getAdGroupById(adGRoupID);
                            if (res.data.status === "success") {
                                if (props?.adgroupsSettingsData) {
                                    const value = { status: true }
                                    dispatch(props?.adgroupsSettingsData(value));
                                }
                            }
                        }, 1);
                        setLoading(false);
                    }
                }
            });
        }
    }

    return (
        <div className="container-fluid">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {!loading && (
                <div>
                    {adGroupSettingsData?.map((adGroupData: any, index: number) => (
                        <div key={index} className={`row mx-2 border-top border-start border-bottom  ${index == 0 ? "mt-3" : ""}`}>
                            <div className="col-4 py-2 default-budget-width border-right feedback-modal-body-background">
                                <div className='fs-13 fw-bold'>{adGroupData?.campaingn_title}<span>{adGroupData?.campaingn_title === "Campaign bidding strategy" ?
                                    <span className="badge text-bg-success ms-2 badge-custom-bg rounded-0  badge-new">New</span> : <></>}</span>
                                </div>
                            </div>
                            <div className='col-8 py-2'>
                                {adGroupData?.campaingn_title === "Ad group name" && (
                                    <div>
                                        <input type="text" name="ad_group_name" className="form-control fs-13 w-50 input border-dark" placeholder="ad group name" defaultValue={adGroupDataByID?.ad_group_name ? adGroupDataByID?.ad_group_name : adsUpdateData?.ad_group_name} onChange={handleInput} />
                                        {formErrors?.ad_group_name && (<div className='text-danger'>{formErrors?.ad_group_name}</div>)}
                                    </div>
                                )}
                                {adGroupData?.campaingn_title === "Ad group ID" && (
                                    <div className='fs-13'>{adGroupDataByID?.ad_group_id}</div>
                                )}
                                {adGroupData?.campaingn_title === "Active / Paused" && (
                                    <div>
                                        <div className="form-check form-switch ps-1 d-flex">
                                            <label className="switch text-start mt-1">
                                                <input type="checkbox" className='' name="toogle" checked={activeStatus} onChange={(e) => handleActiveChanges(e)} />
                                                <div className="slider round">
                                                    <span className="on">
                                                        <svg viewBox="0 0 512 512" width="7" height="7" className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check toggle-check-mark-position" data-fa-i2svg="">
                                                            <path fill="white" d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                    <span className="off"></span>
                                                </div>
                                            </label>
                                            <div className='ms-2'> {activeStatus ? <span className='custom-explore-text-color fs-13'>Active</span> : <span className='text-dark fs-13'>Paused</span>}</div>
                                        </div>
                                        <div>{activeStatus ? <div className='custom-explore-text-color logout cursor-pointer fs-13'>Archive this ad group</div> : <></>}</div>
                                    </div>
                                )}
                                {adGroupData?.campaingn_title === "Status" && (
                                    <div className='fs-13'>
                                        <span className={`${adGroupDataByID?.campaign_state === "ENABLED" ? "text-success" : "text-dark"}`}>{adGroupDataByID?.campaign_state === "ENABLED" ? adGroupDataByID?.campaign_start_date > currentDate ? "Scheduled" : "Delivering" : "Paused"}</span>
                                    </div>
                                )}
                                {adGroupData?.campaingn_title === "Default bid" && (
                                    <div className='fs-13'>
                                        <input type="number" name="default_bid" className='form-control input w-25 border-dark fs-13' defaultValue={adGroupDataByID?.default_bid ? adGroupDataByID?.default_bid : adsUpdateData?.default_bid} onChange={handleInput} />
                                        {formErrors?.default_bid && (<div className='text-danger'>{formErrors?.default_bid}</div>)}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="d-flex justify-content-end mt-3">
                        <div className="mx-2">
                            <button className="custom-explore-text-color logout bg-transparent border-0" onClick={onClickCancelAdGroupSettings} >
                                Cancel
                            </button>
                        </div>
                        <div className="">
                            <button className="request-call-button text-white fs-13" onClick={onClickSaveChanges} >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        adgroupsSettingsData: (data: any) => dispatch(AdgroupsSettingsData(data)),
    };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(AdGroupSettingsPage);
export { connectedNavBar as AdGroupSettingsPage };
