import { CustomTableCol } from "../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const PortfolioInfoCampaignsDataGridCols: CustomTableCol[] = [
  {
    title: "AI Actions",
    control: "actions",
    sortable: false,
    canShowColumn: true,
    isView:true,
    class: "col_table",
    style: {  width: "81px",left: 0, height: "52px" },
  },
  {
    title: "Active",
    control: "state",
    sortable: false,
    canShowColumn: true,
    class: "col_table",
    style: { width: "65px", left: 81, height: "52px" },
    isShowSwitch: true,
  },
  {
    title: "Campaign ID",
    control: "campaign_id",
    sortable: true,
    canShowColumn: true,
    class: "col_table",
    style: { width: "170px", left: 145, height: "52px",wordBreak: "break-all" },
  },
  {
    title: "Campaigns",
    control: "campaign_name",
    sortable: true,
    canShowColumn: true,
    class: "col_table tb_left_border",
    style: { width: "301px", left: 314, height: "52px",wordBreak: "break-all" },
    icon: info,
    infoIcon: true,
    isLink: true,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Status",
    control: "state",
    class: "",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px", height: "52px" },
  },
  {
    title: "Type",
    control: "targeting_type",
    sortable: true,
    canShowColumn: true,
    style: { width: "130px" },
  },
  {
    title: "Start date",
    control: "start_date",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    isDate: true,
  },
  {
    title: "End date",
    control: "end_date",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    isDate: true,
  },
  {
    title: "Budget",
    control: "budget",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Budget",
    },
  },
  {
    title: "Impressions",
    control: "impressions",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Impressions",
    },
  },
  {
    title: "Top-of-search IS",
    control: "topOfSearchImpressionShare",
    sortable: true,
    canShowColumn: true,
    style: { width: "150px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Top of Search js"
    },
  },
  {
    title: "Clicks",
    control: "clicks",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Clicks",
    },
  },
  {
    title: "CTR",
    control: "clickThroughRate",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "CTR"
    },
  },
  {
    title: "Spend",
    control: "cost",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Campaigns Spend",
    },
  },
  {
    title: "CPC",
    control: "costPerClick",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Campaigns CPC",
    },
  },
  {
    title: "Orders",
    control: "purchases30d",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Orders",
    },
  },
  {
    title: "Sales",
    control: "sales30d",
    isShowRupee: true,
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Sales",
    },
  },
  {
    title: "Benchmark",
    control: "benchmark",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: false,
  },
  {
    title: "Qualifier",
    control: "qualifier",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: false,
  },
  {
    title: "ACOS",
    control: "acosClicks14d",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ACOS",
    },
  },
  {
    title: "ROAS",
    control: "roasClicks14d",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ROAS",
    },
  },
  {
    title: "Conversion rate",
    control: "conversion_rate",
    sortable: true,
    canShowColumn: true,
    style: { width: "130px" },
    icon: info,
  },
  {
    title: "Total units",
    control: "unitsSoldClicks1d",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
  },
];
