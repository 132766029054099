import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { CustomTable } from '../../../components/custom-table';
import { campaignReportsDataGridColumn } from './reports-campaign-data-grid-cols';
import { GET_REPORTS_DETAILS } from '../../../app/services/reports.service';
import { adGroupReportsDataGridColumn } from './report-info/ad-group-reports/ad-group-report-data-grid-cols';
import { targetingKeywordsReportsDataGridColumn } from './report-info/target-keyword-reports/targeting-keywords-reports-data-grid-cols';
import { productAdsReportsDataGridColumn } from './report-info/product-ads/product-ads-data-grid-cols';
import moment from 'moment';
import * as XLSX from 'xlsx';

export const Reports = () => {
  const [formErrors, setFormErrors] = useState<any>({});
  const [campaignReportsData, setCampaignReportsData] = useState<any>([]);
  const [adGroupsReportsData, setAdGroupsReportsData] = useState<any>([]);
  const [productAdsReportsData, setProductAdsReportsData] = useState<any>([]);
  const [tabLoading, setTabLoading] = useState<any>('Campaigns');
  const [targetingKeywordsReportsData, setTargetingKeywordsReportsData] = useState<any>([]);
  const [reportsExcelData, setReportsExcelData] = useState<any>([]);
  const [campaignsReportsExcelData, setCampaignsReportsExcelData] = useState<any>([]);
  const [adGroupsReportsExcelData, setAdGroupsReportsExcelData] = useState<any>([]);
  const [productAdsReportsExcelData, setProductAdsReportsExcelData] = useState<any>([]);
  const [showReportsData, setShowReportsData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [showExport, setShowExport] = useState<boolean>(false);
  const [reportsData, setReportsData] = useState<any>({
    start_date: "",
    end_date: ""
  })
  const currentDate = new Date().toISOString().split('T')[0];
  const userUuid = localStorage.getItem('user_uuid');
  const profileID = localStorage.getItem('profile_id');
  const profileName = localStorage.getItem('profile_name');
  const [isCumulative, setIsCumulative] = useState<boolean>(false);
  const campaignReports = {
    total: 10,
    rows: campaignReportsData
  }
  const adGroupsReport = {
    total: 10,
    rows: adGroupsReportsData
  }

  const productAdsReport = {
    total: 10,
    rows: productAdsReportsData
  }

  const targetingKeywordReport = {
    total: 10,
    rows: targetingKeywordsReportsData
  }

  const tabClick = (event: any) => {
    setLoading(true);
    setTabLoading(event);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }
  const getReportsDetailsData = (data: any) => {
    const adGroupPayload = data;
    const payload: any = {
      user_uuid: data?.user_uuid,
      profile_id: data?.profile_id,
      start_date: data?.start_date,
      end_date: data?.end_date,
      is_cummulative_report: data?.is_cummulative_report,
      is_web: false,
      report_type: "CAMPAIGNS"
    };

    setLoading(true);
    setIsDisabled(true);
    setShowReportsData(true)
    GET_REPORTS_DETAILS(payload).then((res: any) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
        setIsDisabled(false);
        setLoading(false);
      } else {
        if (res) {
          const response = res.data;
          setReportsExcelData(response?.data);
          setCampaignsReportsExcelData(response?.data);
          for (let i = 0; i < response?.data?.campaign_data?.length; i++) {
            const element = response?.data?.campaign_data[i];
            element.impressions = element?.impressions ? element?.impressions?.toLocaleString('en-IN') : '-';
            element.clicks = element?.clicks ? element?.clicks?.toLocaleString('en-IN') : '-';
            element.topOfSearchImpressionShare = element?.topOfSearchImpressionShare?.toFixed(2);
            element.clickThroughRate = element?.clickThroughRate !== "-" ? getCTRData(element?.clickThroughRate?.toFixed(2)) : "";
            element.costPerClick = element?.costPerClick !== "-" ? getRupeeData(element?.costPerClick) : "-";
            element.Sales = getRupeeData(element?.Sales);
            element.cost = element?.cost !== "-" ? getRupeeData(element.cost) : "-";
            element.ACOS = element?.ACOS !== "-" ? getPercentageData(element?.ACOS) : "-";
            element.ROAS = element?.ROAS !== "-" ? element?.ROAS?.toFixed(2) : "-";
            element.conversion_rate = element?.conversion_rate !== "-" ? getPercentageData(element?.conversion_rate) : "-";
          }
          setCampaignReportsData(response?.data?.campaign_data)
          getAdGroupsReportsData(adGroupPayload);
          setLoading(false);
        }
      }
    })
  }

  const getCTRData = (data: any) => {
    if (data === "NaN") {
      return "-"
    } else if (data === undefined) {
      return "-";
    } else {
      return data + "%";
    }
  }

  const getPercentageData = (data: any) => {
    if (data === "-") {
      return "-";
    } else if (data === 0) {
      return "0";
    } else if (data === undefined) {
      return "-";
    } else {
      return data?.toFixed(2) + '%';
    }
  }

  const getRupeeData = (data: any) => {
    if (data === "-") {
      return "-";
    } else if (data === 0) {
      return 0;
    } else if (data === undefined) {
      return "-";
    } else {
      const preparedData = parseFloat(data?.toFixed(2))?.toLocaleString('en-IN');
      return `${preparedData}`
    }
  }

  const onClickSubmitReports = async () => {
    const isValid = validateForm();
    if (isValid) {
      const payload = {
        user_uuid: userUuid,
        profile_id: profileID,
        start_date: reportsData.start_date,
        end_date: reportsData.end_date,
        is_cummulative_report: isCumulative,
        is_web: false,
      };
      getReportsDetailsData(payload);
    }
  };

  const getAdGroupsReportsData = (data: any) => {
    const productAdsPayload = data;
    const payload: any = {
      user_uuid: data?.user_uuid,
      profile_id: data?.profile_id,
      start_date: data?.start_date,
      end_date: data?.end_date,
      is_cummulative_report: data?.is_cummulative_report,
      is_web: false,
      report_type: "ADGROUPS"
    };

    setLoading(true);
    GET_REPORTS_DETAILS(payload).then((res: any) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
        setIsDisabled(false);
        setLoading(false);
      } else {
        if (res) {
          const response = res.data;
          setAdGroupsReportsExcelData(response?.data)
          for (let i = 0; i < response?.data?.ad_group_data?.length; i++) {
            const element = response?.data?.ad_group_data[i];
            element.impressions = element?.impressions ? element?.impressions?.toLocaleString('en-IN') : '-';
            element.clicks = element?.clicks ? element?.clicks?.toLocaleString('en-IN') : '-';
            element.topOfSearchImpressionShare = element?.topOfSearchImpressionShare?.toFixed(2);
            element.clickThroughRate = element?.clickThroughRate !== "-" ? getCTRData(element?.clickThroughRate?.toFixed(2)) : "";
            element.costPerClick = element?.costPerClick !== "-" ? getRupeeData(element?.costPerClick) : "-";
            element.Sales = getRupeeData(element?.Sales);
            element.cost = element?.cost !== "-" ? getRupeeData(element.cost) : "-";
            element.ACOS = element?.ACOS !== "-" ? getPercentageData(element?.ACOS) : "-";
            element.ROAS = element?.ROAS !== "-" ? element?.ROAS?.toFixed(2) : "-";
            element.conversion_rate = element?.conversion_rate !== "-" ? getPercentageData(element?.conversion_rate) : "-";
          }
          setAdGroupsReportsData(response?.data?.ad_group_data)
          getProductAdsReportsData(productAdsPayload);
          setLoading(false);
        }
      }
    })
  }

  const getProductAdsReportsData = (data: any) => {
    const keywordsReportsPayload = data;
    const payload: any = {
      user_uuid: data?.user_uuid,
      profile_id: data?.profile_id,
      start_date: data?.start_date,
      end_date: data?.end_date,
      is_cummulative_report: data?.is_cummulative_report,
      is_web: false,
      report_type: "PRODUCTADS"
    };

    setLoading(true);
    GET_REPORTS_DETAILS(payload).then((res: any) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
        setIsDisabled(false);
        setLoading(false);
      } else {
        if (res) {
          const response = res.data;
          setProductAdsReportsExcelData(response?.data)
          for (let i = 0; i < response?.data?.product_data?.length; i++) {
            const element = response?.data?.product_data[i];
            element.impressions = element?.impressions ? element?.impressions?.toLocaleString('en-IN') : '-';
            element.clicks = element?.clicks ? element?.clicks?.toLocaleString('en-IN') : '-';
            element.topOfSearchImpressionShare = element?.topOfSearchImpressionShare?.toFixed(2);
            element.clickThroughRate = element?.clickThroughRate !== "-" ? getCTRData(element?.clickThroughRate?.toFixed(2)) : "";
            element.costPerClick = element?.costPerClick !== "-" ? getRupeeData(element?.costPerClick) : "-";
            element.Sales = getRupeeData(element?.Sales);
            element.cost = element?.cost !== "-" ? getRupeeData(element.cost) : "-";
            element.ACOS = element?.ACOS !== "-" ? getPercentageData(element?.ACOS) : "-";
            element.ROAS = element?.ROAS !== "-" ? element?.ROAS?.toFixed(2) : "-";
            element.conversion_rate = element?.conversion_rate !== "-" ? getPercentageData(element?.conversion_rate) : "-";
          }
          setProductAdsReportsData(response?.data?.product_data)
          getKeywordsReportsData(keywordsReportsPayload);
          setLoading(false);
        }
      }
    })
  }

  const getKeywordsReportsData = (data: any) => {
    const payload: any = {
      user_uuid: data?.user_uuid,
      profile_id: data?.profile_id,
      start_date: data?.start_date,
      end_date: data?.end_date,
      is_cummulative_report: data?.is_cummulative_report,
      is_web: false,
      report_type: "KEYWORDS"
    };

    setLoading(true);
    GET_REPORTS_DETAILS(payload).then((res: any) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
        setIsDisabled(false);
        setLoading(false);
      } else {
        if (res) {
          const response = res.data;
          setReportsExcelData(response?.data)
          setShowExport(true);
          for (let i = 0; i < response?.data?.keyword_data?.length; i++) {
            const element = response?.data?.keyword_data[i];
            element.keyword = element?.keyword + "-" + moment(element?.date).format("DD/MM/YYYY")
            element.impressions = element?.impressions ? element?.impressions?.toLocaleString('en-IN') : '-';
            element.clicks = element?.clicks ? element?.clicks?.toLocaleString('en-IN') : '-';
            element.topOfSearchImpressionShare = element?.topOfSearchImpressionShare?.toFixed(2);
            element.clickThroughRate = element?.clickThroughRate !== "-" ? getCTRData(element?.clickThroughRate?.toFixed(2)) : "";
            element.costPerClick = element?.costPerClick !== "-" ? getRupeeData(element?.costPerClick) : "-";
            element.Sales = getRupeeData(element?.Sales);
            element.cost = element?.cost !== "-" ? getRupeeData(element.cost) : "-";
            element.ACOS = element?.ACOS !== "-" ? getPercentageData(element?.ACOS) : "-";
            element.ROAS = element?.ROAS !== "-" ? element?.ROAS?.toFixed(2) : "-";
            element.conversion_rate = element?.conversion_rate !== "-" ? getPercentageData(element?.conversion_rate) : "-";
          }
          setTargetingKeywordsReportsData(response?.data?.keyword_data)
          setIsDisabled(false);
          setLoading(false);
        }
      }
    })
  }

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setReportsData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));

    setFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validateSpecificFeild(name, value),
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCumulative(e.target.checked);
  };

  const validateSpecificFeild = (name: any, value: any) => {
    return "";
  };

  const validateForm = () => {
    const errors: any = {};
    if (!reportsData.start_date) {
      errors.start_date = "Please select start date";
    }
    if (!reportsData.end_date) {
      errors.end_date = "Please select end date";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  function safeTrim(value: any, symbol: any) {
    if (typeof value === 'string') {
      return value.replace(symbol, '');
    }
    return value;
  }

  const onReportsExport = () => {
    const csvData = campaignsReportsExcelData?.campaign_data?.map((e: any) => {
      var campaign_data;
      if (isCumulative) {
        campaign_data = {
          "portfolioId": e?.portfolioId,
          "profileId": e?.profileId,
          "campaignId": e?.campaignId,
          "campaignName": e?.campaignName,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "topOfSearchImpressionShare": e?.topOfSearchImpressionShare,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "sum_top_of_search_cal_value": e?.sum_top_of_search_cal_value,
          "my_impressions": e?.my_impressions,
        };
      } else {
        campaign_data = {
          "date": e?.date,
          "adGroupId": e?.adGroupId,
          "portfolioId": e?.portfolioId,
          "profileId": e?.profileId,
          "campaignId": e?.campaignId,
          "campaignName": e?.campaignName,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "topOfSearchImpressionShare": e?.topOfSearchImpressionShare,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "sum_top_of_search_cal_value": e?.sum_top_of_search_cal_value,
          "my_impressions": e?.my_impressions,
          "Spends/ Budget": e['Spends/ Budget'],
          "total_budget": e?.total_budget,
          "total_spend": e?.total_spend,
          "total_sales": e?.total_sales,
          'Benchmark': e?.Benchmark,
          'Actual Roas / Benchmark': e['Actual Roas / Benchmark'],
          "total_spend / total_budget": e['total_spend / total_budget'],
          "Qualifier": e?.Qualifier
        };
      }
      return campaign_data;
    });

    const adGroupsCSVData = adGroupsReportsExcelData?.ad_group_data?.map((e: any) => {
      var ad_group_data;
      if (isCumulative) {
        ad_group_data = {
          "adGroupId": e?.adGroupId,
          "portfolioId": e?.portfolioId,
          "profileId": e?.profileId,
          "campaignId": e?.campaignId,
          "campaignName": e?.campaignName,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "adGroupName": e?.adGroupName,
          "adKeywordStatus": e?.adKeywordStatus,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "topOfSearchImpressionShare": e?.topOfSearchImpressionShare,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "sum_top_of_search_cal_value": e?.sum_top_of_search_cal_value,
          "my_impressions": e?.my_impressions
        };
      } else {
        ad_group_data = {
          "date": e?.date,
          "adGroupId": e?.adGroupId,
          "portfolioId": e?.portfolioId,
          "profileId": e?.profileId,
          "campaignId": e?.campaignId,
          "campaignName": e?.campaignName,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "adGroupName": e?.adGroupName,
          "adKeywordStatus": e?.adKeywordStatus,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "topOfSearchImpressionShare": e?.topOfSearchImpressionShare,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "sum_top_of_search_cal_value": e?.sum_top_of_search_cal_value,
          "my_impressions": e?.my_impressions
        };
      }
      return ad_group_data;
    });

    const productsCSVData = productAdsReportsExcelData?.product_data?.map((e: any) => {
      var product_ads_data;
      if (isCumulative) {
        product_ads_data = {
          "profileId": e?.profileId,
          "campaignId": e?.campaignId,
          "campaignName": e?.campaignName,
          "advertisedAsin": e?.advertisedAsin,
          "advertisedSku": e?.advertisedSku,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "Spends/ Budget": e['Spends/ Budget'],
          "total_budget": e?.total_budget,
        };
      } else {
        product_ads_data = {
          "date": e?.date,
          "adId": e?.adId,
          "portfolioId": e?.portfolioId,
          "profileId": e?.profileId,
          "campaignId": e?.campaignId,
          "campaignName": e?.campaignName,
          "advertisedAsin": e?.advertisedAsin,
          "advertisedSku": e?.advertisedSku,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "Spends/ Budget": e['Spends/ Budget'],
          "total_budget": e?.total_budget,
          "total_spend": e?.total_spend,
          "total_sales": e?.total_sales,
          'Benchmark': e?.Benchmark,
          'Actual Roas / Benchmark': e['Actual Roas / Benchmark'],
          "total_spend / total_budget": e['total_spend / total_budget'],
          "Qualifier": e?.Qualifier
        };
      }
      return product_ads_data;
    });

    const keywordsCSVData = reportsExcelData?.keyword_data?.map((e: any) => {
      var targeting_keywords_data;
      if (isCumulative) {
        targeting_keywords_data = {
          "portfolioId": e?.portfolioId,
          "campaignId": e?.campaignId,
          "adGroupId": e?.adGroupId,
          "campaignName": e?.campaignName,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "adGroupName": e?.adGroupName,
          "keyword": e?.keyword,
          "keywordId": e?.keywordId,
          "keywordBid": e?.keywordBid,
          "keywordType": e?.keywordType,
          "adKeywordStatus": e?.adKeywordStatus,
          "matchType": e?.matchType,
          "targeting": e?.targeting,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "topOfSearchImpressionShare": e?.topOfSearchImpressionShare,
          "cat_impression": e?.cat_impression,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "sum_top_of_search_cal_value": e?.sum_top_of_search_cal_value,
          "my_impressions": e?.my_impressions,
        };
      } else {
        targeting_keywords_data = {
          "date": e?.date,
          "profile_id": e?.profile_id,
          "portfolioId": e?.portfolioId,
          "campaignId": e?.campaignId,
          "adGroupId": e?.adGroupId,
          "campaignName": e?.campaignName,
          "campaignStatus": e?.campaignStatus,
          "campaignBudgetAmount": e?.campaignBudgetAmount,
          "campaignBudgetCurrencyCode": e?.campaignBudgetCurrencyCode,
          "campaignBudgetType": e?.campaignBudgetType,
          "adGroupName": e?.adGroupName,
          "keyword": e?.keyword,
          "keywordId": e?.keywordId,
          "keywordBid": e?.keywordBid,
          "keywordType": e?.keywordType,
          "adKeywordStatus": e?.adKeywordStatus,
          "matchType": e?.matchType,
          "targeting": e?.targeting,
          "clicks": e?.clicks,
          "impressions": e?.impressions,
          "topOfSearchImpressionShare": e?.topOfSearchImpressionShare,
          "cat_impression": e?.cat_impression,
          "clickThroughRate": e?.clickThroughRate,
          "costPerClick": safeTrim(e?.costPerClick, "₹"),
          "purchases30d": e?.purchases30d,
          "Sales": safeTrim(e?.Sales, "₹"),
          "ACOS": e?.ACOS,
          "ROAS": e?.ROAS,
          "cost": safeTrim(e?.cost, "₹"),
          "conversion_rate": e?.conversion_rate,
          "unitsSoldClicks1d": e?.unitsSoldClicks1d,
          "sum_top_of_search_cal_value": e?.sum_top_of_search_cal_value,
          "my_impressions": e?.my_impressions,
          "impress cum": e['impress cum'],
          "cat cum impression": e['cat cum impression'],
          "total_spend": e?.total_spend,
          "Total impress share": e['Total impress share'],
          "total_sales": e?.total_sales,
          'Benchmark': e?.Benchmark,
          'Actual Roas / Benchmark': e['Actual Roas / Benchmark'],
          "total_spend / total_budget": e['total_spend / total_budget'],
        };
      }
      return targeting_keywords_data;
    });

    const wb = XLSX.utils.book_new();
    if (csvData?.length) {
      const ws1 = XLSX.utils.json_to_sheet(csvData);
      XLSX.utils.book_append_sheet(wb, ws1, 'Campaigns');
    } else {
      const ws1 = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws1, 'Campaigns');
    }

    if (adGroupsCSVData?.length) {
      const ws2 = XLSX.utils.json_to_sheet(adGroupsCSVData);
      XLSX.utils.book_append_sheet(wb, ws2, 'Ad Groups');
    } else {
      const ws2 = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws2, 'Ad Groups');
    }

    if (productsCSVData?.length) {
      const ws3 = XLSX.utils.json_to_sheet(productsCSVData);
      XLSX.utils.book_append_sheet(wb, ws3, 'Products');
    } else {
      const ws3 = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws3, 'Products');
    }

    if (keywordsCSVData?.length) {
      const ws4 = XLSX.utils.json_to_sheet(keywordsCSVData);
      XLSX.utils.book_append_sheet(wb, ws4, 'Keywords');
    } else {
      const ws4 = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws4, 'Keywords');
    }

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadURL;
    link.download = `${profileName}_${reportsData?.start_date}_to_${reportsData?.end_date}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadURL);
  }

  return (
    <div className='container-fluid'>
      <div className='row fs-5 fw-bold ms-0'>Sponsored ads reports</div>
      <div>
        <div className='row my-3 px-0'>
          <div className='col-md-3 mb-3'>
            <label htmlFor="exampleFormControlInput1" className="form-label">Start Date</label>
            <input type="date" min="2023-12-01" name="start_date" className="form-control" id="exampleFormControlInput1" placeholder="" disabled={isDisabled} defaultValue={reportsData?.start_date} onChange={(e) => handleChangeInput(e)} />
            {formErrors.start_date && (<div className="text-danger">{formErrors.start_date}</div>)}
          </div>
          <div className='col-md-3 mb-3'>
            <label htmlFor="exampleFormControlInput1" className="form-label">End Date</label>
            <input type="date" name="end_date" max={currentDate} className="form-control" id="exampleFormControlInput1" placeholder="" disabled={isDisabled} defaultValue={reportsData?.end_date} onChange={(e) => handleChangeInput(e)} />
            {formErrors.end_date && (<div className="text-danger">{formErrors.end_date}</div>)}
          </div>
          <div className='col-md-3 mt-4 pt-2 mx-auto'>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled={isDisabled} onChange={(e) => handleCheckboxChange(e)} />
              <label className="form-check-label" htmlFor="flexCheckDefault">Is Cummulative Report</label>
            </div>
          </div>
          <div className='col-md-3 mt-4 pt-1'>
            <button className='btn btn-primary request-call-button rounded-pill px-4' disabled={isDisabled} onClick={onClickSubmitReports}>{isDisabled ? "Fetching reports..." : "Submit"}</button>
          </div>
        </div>
      </div>
      <div>
        {loading &&
          <div className="text-center p-5 mt-5">
            <div className="spinner-border " role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        }
        {showReportsData && !loading && (
          <>
            {showExport && (
              <div className='my-3 text-end d-flex justify-content-end'>
                <button className='btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1 ms-2 ms-md-2 custom-export-button d-flex align-items-center' onClick={onReportsExport}>Export</button>
              </div>
            )}
            <div className='col-md-12'>
              <div className="tabs-section px-0">
                <div className="nav nav-underline border-bottom" id="nav-tab" role="tablist">
                  <button
                    className={`nav-link text-dark fs-15 ${tabLoading === 'Campaigns' ? 'active' : ''}`}
                    type="button"
                    onClick={() => tabClick('Campaigns')}
                  >
                    Campaigns
                  </button>
                  <button
                    className={`nav-link text-dark fs-15 ${tabLoading === 'Ad Groups' ? 'active' : ''}`}
                    onClick={() => tabClick('Ad Groups')}>
                    Ad Groups
                  </button>
                  <button
                    className={`nav-link text-dark fs-15 ${tabLoading === 'Products' ? 'active' : ''}`}
                    onClick={() => tabClick('Products')}>
                    Products
                  </button>
                  <button
                    className={`nav-link text-dark fs-15 ${tabLoading === 'Keywords' ? 'active' : ''}`}
                    onClick={() => tabClick('Keywords')}
                  >
                    Keywords
                  </button>
                </div>
                {showReportsData && (
                  <div className="tab-content" id="nav-tabContent">
                    <div>
                      {loading &&
                        <div className="text-center p-5 mt-5">
                          <div className="spinner-border " role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      }
                      {!loading &&
                        <>
                          {tabLoading === 'Campaigns' && <div className='my-1'>
                            <CustomTable
                              tableData={campaignReports}
                              TableCols={campaignReportsDataGridColumn}
                              pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                            />
                          </div>}
                        </>
                      }
                    </div>
                    <div>
                      {loading &&
                        <div className="text-center p-5 mt-5">
                          <div className="spinner-border " role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      }
                      {!loading &&
                        <>
                          {tabLoading === 'Ad Groups' && <div className='my-1'>
                            <CustomTable
                              tableData={adGroupsReport}
                              TableCols={adGroupReportsDataGridColumn}
                              pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                            />
                          </div>}
                        </>
                      }
                    </div>
                    <div>
                      {loading &&
                        <div className="text-center p-5 mt-5">
                          <div className="spinner-border " role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      }
                      {!loading &&
                        <>
                          {tabLoading === 'Products' && <div className='my-1'>
                            <CustomTable
                              tableData={productAdsReport}
                              TableCols={productAdsReportsDataGridColumn}
                              pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                            />
                          </div>
                          }</>
                      }
                    </div>
                    <div>
                      {loading &&
                        <div className="text-center p-5 mt-5">
                          <div className="spinner-border " role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      }
                      {!loading &&
                        <>
                          {tabLoading === 'Keywords' && <div className='my-1'>
                            <CustomTable
                              tableData={targetingKeywordReport}
                              TableCols={targetingKeywordsReportsDataGridColumn}
                              pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                            />
                          </div>}
                        </>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
