import API from "../utils/axios";
const api_url = "/api";

export const GET_PORTFOLIOS_SUMMARY_REPORT = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/v1/getPortfolioSummaryReport`, data);
};

export const GET_CAMPAIGN_SUMMARY_REPORT = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/v1/getCampaignSummaryReport`, data);
};

export const GET_AD_GROUP_SUMMARY_REPORT = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/v1/getAdGroupSummaryReport`, data);
};

export const GET_KEYWORDS_SUMMARY_REPORT = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/v1/getKeywordSummaryReport`, data);
};

export const GET_PRODUCT_ADS_SUMMARY_REPORT = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/v1/getProductAdSummaryReport`, data);
};
