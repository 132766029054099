import axios from "axios";
import appConfig from "../../config/constant";

const API = axios.create({
  baseURL: appConfig.BASE_URL,
});

API.interceptors.request.use(function (config: any) {
  const token = localStorage.getItem("user_session");
  const contentType = config.headers["Content-Type"];

  if (contentType === "multipart/form-data") {
    config.headers["Content-Type"] = "multipart/form-data";
  } else {
    config.headers["Content-Type"] = "application/json";
  }
  config.headers.Authorization = token ? `${token}` : "";
  return config;
});

API.interceptors.response.use(
  (response: any) => {
    if (response.headers?.['content-type'] == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return response
    } else {
      if (response.data.data) {
        return response.data;
      } else {
        return response.data ? response.data : {};
      }
    }
  },
  (error: any) => {
    if (error.code === "ERR_NETWORK") {
      window.location.href = "/";
    }
    
    if (error?.response?.status === 401) {
      window.location.href = "/";
    }
    return error.response && error.response.data ? error.response.data : {};
  }
);

export default API;
