import { CustomTableCol } from "../../../../../../../components/custom-table/types";
import { durationsList } from "../../../../../../../app/utils/durationsList";

export const ConfigurationGroupTargetsDataGridColumn: CustomTableCol[] = [
  {
    title: "Keyword",
    control: "keyword_text",
    sortable: true,
    canShowColumn: true,
    style: { width: "300px", left: 0 },
  },
  {
    title: "Benchmark",
    control: "benchmark",
    sortable: true,
    canShowColumn: true,
    style: { width: "160px", left: 214, height: "62px" },
    isInputField: true,
    inputConfig: {
      placeholder: 'Benchmark',
      name: 'benchmark',
      inputType: "number"
    }
  },
  {
    title: "Duration",
    control: "duration",
    canShowColumn: true,
    style: { width: "180px", left: 214 },
    isDropdown: true,
    inputConfig: {
      placeholder: 'Select duration',
      name: 'duration',
      value: "",
      options: durationsList,
      inputType: "dropdown"
    }
  },
  {
    title: "Qualifier",
    control: "qualifier",
    sortable: true,
    canShowColumn: true,
    style: { width: "160px", left: 214 },
    isInputField: true,
    inputConfig: {
      placeholder: 'Qualifier',
      name: 'qualifier',
      inputType: "number"
    }
  },
  {
    title: "Auto Updated by AI",
    control: "is_ai_update",
    sortable: false,
    canShowColumn: true,
    style: { width: "150px", left: 0, height: "40px" },
    isShowSwitch: true,
    inputConfig: {
      placeholder: 'Auto Updated by AI',
      name: 'is_ai_update',
    }
  },
  {
    title: "Get Recommendations by AI",
    control: "is_ai_call",
    sortable: false,
    canShowColumn: true,
    style: { width: "200px", left: 0, height: "40px" },
    isShowSwitch: true,
    inputConfig: {
      placeholder: 'Get Recommendations by AI',
      name: 'is_ai_call',
    }
  },
  {
    title: "Actions",
    control: "actions",
    sortable: true,
    canShowColumn: true,
    isSave: true,
    style: { width: '100px' },
  }
];
