import React, { useEffect, useState } from 'react'
import { CampaignInfoRoutes } from '../../../Routes/campaign-info';
import { NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
import { GET_USER_CAMPAIGNS, UPDATE_CAMPAIGN_STATUS } from '../../../app/services/campaigns.service';
import moment from "moment";
import { connect } from 'react-redux';
import { Breadcum } from '../../../components/breadcum';

interface Props {
    ProfileDataReducer?: any;
    profileData?: any;
    UserDataReducer?: any;
    userData?: any;
    CampaignDataReducer?: any;
    campaignData?: any;
    CampaignSettingsDataReducer?: any;
    campaignSettingsData?: any;
}

const CampaignInfo = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [activeStatus, setActiveStatus] = useState<boolean>(false);
    const [campainDataByID, setCampaignDataByID] = useState<any>();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const currentDate = new Date().toISOString().split('T')[0];

    const sidemenuData = [
        {
            name: "Ad groups",
            isActive: false,
            route: '/dashboard/campaign/ad-groups'
        },
        {
            name: "Placements",
            isActive: false,
            route: '/dashboard/campaign/placements'
        },
        {
            name: "Negative keywords",
            isActive: false,
            route: '/dashboard/campaign/negative-keywords'
        },
        {
            name: "Budget rules",
            isActive: false,
            route: '/dashboard/campaign/budget-rules'
        },
        {
            name: "Campaign settings",
            isActive: true,
            route: '/dashboard/campaign/settings'
        },
    ]

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "All Camapigns",
            value: "All Camapigns",
            routerLink: "/dashboard/home/campaigns",
            isActive: false,
        },
        {
            label: "Camapign name",
            value: "Camapign name",
            routerLink: "/dashboard/campaign/ad-groups",
            isActive: false,
        },
    ])

    useEffect(() => {
        const campaignID: any = localStorage.getItem("campaign_id");
        getUserCampaignById(campaignID);
    }, [])

    useEffect(() => {
        if (props?.campaignSettingsData) {
            const campaignID: any = localStorage.getItem("campaign_id");
            getUserCampaignById(campaignID);
        }
    }, [props?.campaignSettingsData])


    const getUserCampaignById = (campaignID: any) => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            is_report_data: false,
        }
        setLoading(true);
        GET_USER_CAMPAIGNS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    setCampaignDataByID(response?.data?.data[0])
                    getBreadCumList(response?.data?.data[0]);
                    const status = response?.data?.data[0]?.state;
                    if (status === "ENABLED") {
                        setActiveStatus(true);
                    } else {
                        setActiveStatus(false);
                    }
                    setLoading(false);
                }
            }
        });
    }

    const getBreadCumList = (data: any) => {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "All Campaigns",
            value: `Campaign : ${data?.campaign_name}`,
            routerLink: "/dashboard/campaign/ad-groups",
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }

    const handleActiveChanges = (e: any, campaignData: any) => {
        const status = e.target.checked ? true : false;
        setActiveStatus(status);

        const toggleStatusPreparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignData?.campaign_id,
            state: status ? "ENABLED" : "PAUSED"
        }
        setLoading(true);
        UPDATE_CAMPAIGN_STATUS(toggleStatusPreparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data.data;
                    toast.success(res.data.message);
                    getUserCampaignById(campainDataByID?.campaign_id)
                    setLoading(false);
                }
            }
        });
    }

    const showData = (data: any) => {
        if (data) {
            var dateString = moment(data).format("MMM DD, YYYY");
            return dateString;
        } else {
            return "No date";
        }
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className='sub-header-container dashboard-side-menu-container border-bottom py-3'>
                        <div className='fs-28'>Campaign : <span className='fs-28 fw-bold'>{campainDataByID?.campaign_name}</span></div>
                        <div className='d-md-flex my-2'>
                            <div>
                                <div className="form-check form-switch ps-1">
                                    <label className="switch text-start mt-1">
                                        <input type="checkbox" name="state" defaultChecked={activeStatus} onChange={(e) => handleActiveChanges(e, campainDataByID)} />
                                        <div className="slider round ">
                                            <span className="on">
                                                <svg viewBox="0 0 512 512" width="7" height="7" className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check toggle-check-mark-position" data-fa-i2svg="">
                                                    <path fill="white" d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z">
                                                    </path>
                                                </svg>
                                            </span>
                                            <span className="off"></span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='fs-13 my-1 mx-2'><span className='fw-600'>Status:</span> <span className={`${campainDataByID?.state === "PAUSED" ? "text-dark" : campainDataByID?.state === "ENABLED" ? "text-success" : "out-of-budget-text-color"}`}>{campainDataByID?.state === "PAUSED" ? "Paused" : campainDataByID?.state === "ENABLED" ? campainDataByID?.start_date > currentDate ? "Scheduled" : "Delivering" : "Out of budget"}</span></div>
                            <div className='fs-13 my-1 mx-2'><span className='fw-600'>Type:</span> <span>Sponsored Products - {campainDataByID?.targeting_type === "MANUAL" ? "Manual Targeting" : ""}</span></div>
                            <div className='fs-13 my-1 mx-2'><span className='fw-600'>Country :</span> <span>India</span></div>
                            <div className='fs-13 my-1 mx-2'><span className='fw-600'>Schedule:</span> <span>{showData(campainDataByID?.start_date)} - {campainDataByID?.end_date === "" ? <>No end date</> : <>{campainDataByID?.end_date}</>}</span></div>
                            <div className='fs-13 my-1 mx-2'><span className='fw-600'>Budget:</span> <span>₹{campainDataByID?.budget}.00 - {campainDataByID?.budget_type === "DAILY" && <>Daily</>}</span></div>
                        </div>
                        <div className='my-0 ps-2'>
                            <Breadcum breadcumList={breadcumInfo}></Breadcum>
                        </div>
                    </div>
                }
            </div>
            <div className='row'>
                <div className='col-2 dashboard-side-menu-container campaign-creation-bg'>
                    {sidemenuData?.map((menu: any, index: number) => (
                        <NavLink to={menu.route} className='text-decoration-none text-dark'>
                            <li key={index} className={`fs-13`}>
                                {menu.name}
                            </li>
                        </NavLink>
                    ))}
                </div>
                <div className='col-10'>
                    <CampaignInfoRoutes />
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer,
        campaignSettingsData: state.CampaignSettingsDataReducer
    };
};

const connectedNavBar = connect(mapStateToProps)(CampaignInfo);
export { connectedNavBar as CampaignInfo };
