import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const SignInPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        navigate(`/manage-profile?code=${code}`)
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [])

    return (
        <div className='container-fluid my-auto pt-5 mt-5'>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {!loading &&
                <div></div>
            }
        </div>
    )
}

