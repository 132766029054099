import React, { useEffect, useState } from 'react'
import { CustomFooter } from '../../../components/footer/custom-footer'
import { Tooltip } from '@mui/material';
import { toast } from "react-toastify";
import { TooltipPage } from '../../../components/custom-tooltip/tooltipPage';
import RupeeIcon from "./../../../assets/images/svg_icons/rupee.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { GET_USER_PORTFOLIOS, UPDATE_PORTFOLIO } from '../../../app/services/portfolios.service';

export const PortfolioSetings = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [portfolioName, setPortfolioName] = useState<any>({
        portfolio_name: "",
        amount: "",
        start_date: "",
        end_date: "",
    })
    const [formErrors, setFormErrors] = useState<any>({});
    const [recurringMonthlyEnds, setRecurringMonthlyEnds] = useState<any>('Never');
    const [recurringMonthlyEndsType, setRecurringMonthlyEndsType] = useState<boolean>(true);
    const [selectedBudgetCap, setSelectedBudgetCap] = useState<any>('');
    const navigate = useNavigate();
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = portfolio_id;
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const currentDate = new Date().toISOString().split('T')[0];

    useEffect(() => {
        getUserPortfolioByID();
    }, [])


    const budgetCap = [
        {
            name: "No Budget cap",
            value: ""
        },
        {
            name: "Date range",
            value: "Date range"
        },
        {
            name: "Recurring monthly",
            value: "Recurring monthly"
        }
    ]

    const getUserPortfolioByID = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            portfolio_id: portfolioId,
            is_report_data:false
        }
        setLoading(true);
        GET_USER_PORTFOLIOS(preparedData).then((res) => {
            if (res.data.status === "error"  || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    setPortfolioName(response?.data[0])
                    setLoading(false);
                }
            }
        });
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setPortfolioName((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "portfolio_name") {
            if (!value) {
                return "Please enter portfolio name"
            }
        }

        if (portfolioName?.policy === "Date range") {
            if (name === "amount") {
                if (!value) {
                    return "Please enter date range budget";
                } else if (parseInt(value) < 0) {
                    return "Please enter valid date range budget";
                }
            }
        }
        if (portfolioName?.policy === "Recurring monthly") {
            if (name === "amount") {
                if (!value) {
                    return "Please enter monthly budget cap";
                } else if (parseInt(value) < 0) {
                    return "Please enter valid  monthly budget cap";
                }
            }
        }
        return "";
    }

    const validateForm = () => {
        const errors: any = {};
        if (!portfolioName?.portfolio_name) {
            errors.portfolio_name = "Please enter portfolio name";
        }

        if (selectedBudgetCap === "Date range" || portfolioName?.policy === "Date range") {
            if (!portfolioName?.amount) {
                errors.amount = "Please enter date range budget";
            } else if (portfolioName?.amount < 0) {
                errors.amount = "Please enter valid date range budget";
            }

            if (!portfolioName?.start_date) {
                errors.start_date = "Please select budget start date";
            }

            if (!portfolioName?.end_date) {
                errors.end_date = "Please select budget end date";
            }
        }

        if (selectedBudgetCap === "Recurring monthly" || portfolioName?.policy === "Recurring monthly") {
            if (!portfolioName?.amount) {
                errors.amount = "Please enter monthly budget cap";
            }
            if (recurringMonthlyEnds === "On") {
                if (!portfolioName?.end_date) {
                    errors.end_date = "Please select end date";
                }
            }
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onClickCancelPortfolio = () => {
        navigate(`/dashboard/home/portfolio/${portfolioId}`)
    }

    const onClickSaveChangesPortofolio = () => {
        const isValid: boolean = validateForm();
        if (isValid) {
            const updatedData = {
                user_uuid: userUuid,
                profile_id: profileID,
                portfolio_id: portfolioId,
                portfolio_name: portfolioName?.portfolio_name,
                policy: portfolioName?.policy ? portfolioName?.policy : selectedBudgetCap ? selectedBudgetCap : "",
                amount: portfolioName?.policy === "" ? "" : portfolioName?.amount ? portfolioName?.amount : portfolioName?.amount ? portfolioName?.amount : "",
                currency_code: "USD",
                in_budget: true,
                start_date: portfolioName?.policy === "" ? "" : portfolioName?.policy === "Recurring monthly" ? "" : portfolioName?.start_date ? portfolioName?.start_date : "",
                end_date: portfolioName?.policy === "" ? "" : (recurringMonthlyEnds === "Never" && portfolioName?.policy === "Recurring monthly") ? "" :
                    portfolioName?.end_date ? portfolioName?.end_date : portfolioName?.end_date ? portfolioName?.end_date : ""
            }
            setLoading(true);
            UPDATE_PORTFOLIO(updatedData).then((res: any) => {
                if (res.data.status === "error"  || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        toast.success(res.data.message);
                        navigate(`/dashboard/home/portfolio/${portfolioId}`)
                        setLoading(false);
                    }
                }
            });
        }
    }

    const onClickLearMoreText = () => {
        const redirectingUrl = "https://advertising.amazon.in/help/GU7E77PGF96FMW3X";
        window.open(redirectingUrl, '_blank');
    }

    const tooltipContent = (data: any) => {
        if (data === "Date range budget cap") {
            return (
                <TooltipPage
                    tooltipHeader='Budget cap'
                    tooltipMainContent={"The maximum spend by all campaigns in your portfolio for the budget date range. When the budget cap is reached, all campaigns in the portfolio will stop delivering."}
                    tooltipMainContentCaption={'Learn more'} />
            )
        } else if (data === "Monthly budget cap") {
            return (
                <TooltipPage
                    tooltipHeader='Budget cap'
                    tooltipMainContent={"The maximum spend for all campaigns in the portfolio for the current month. When the budget cap is reached, all campaigns in the portfolio will stop delivering."}
                    tooltipMainContentCaption={'Learn more'} />
            )
        }
    }

    const handleChangeRecurringMonthlyStatus = (selectedData: any) => {
        setRecurringMonthlyEnds(selectedData);
        if (selectedData === "Never") {
            setRecurringMonthlyEndsType(true);
        } else {
            setRecurringMonthlyEndsType(false);
        }
    }

    return (
        <div className="container-fluid">
            <div className="row ">
                <div className="col-md-10 mx-auto mt-2 ">
                    <div className="settings-card-container portfolio-setting-card mx-auto">
                        <div className="card text-bg-white ad-group-settings mb-3 my-0 ">
                            <div className="card-header bg-white">
                                <div className="d-flex justify-content-between py-2">
                                    <div className="fs-24 fw-bold">Settings </div>
                                </div>
                            </div>
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!loading && (
                                <div className="card-body">
                                    <div>
                                        <h5 className="card-title fs-13 fw-700">Portfolio name</h5>
                                        <div className='my-3 portfolio-name'>
                                            <input type="text" name="portfolio_name" className='form-control border-dark input fs-13' defaultValue={portfolioName?.portfolio_name} placeholder="Portfolio name"
                                                onChange={handleInput} />
                                            {formErrors.portfolio_name && (
                                                <div className="text-danger text-start w-100"> {formErrors.portfolio_name}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="">
                                        <h5 className="card-title fs-14 fw-700 mb-0 fs-13">Budget cap</h5>
                                        <div className="fs-14 w-75 budget_text-color my-1">Set a budget cap for a date range or to recur monthly. Your campaigns will stop delivering when your spend reaches the budget cap amount or the budget end date is reached.
                                            <span className='explore-modal-navigation-text-color cursor-pointer' onClick={onClickLearMoreText}>Learn more</span></div>
                                    </div>
                                    <div className="my-3 portfolio-name">
                                        <select name="policy" className="form-select border-dark input fs-13 ms-1" placeholder='no budgetcap' value={portfolioName?.policy ? portfolioName?.policy : ""} onChange={handleInput}>
                                            {budgetCap?.map((data: any, i: number) => (
                                                <option className="" value={data?.value}>{data?.name}</option>
                                            ))}
                                        </select>
                                        <div className='ms-2 my-2 border-start'>
                                            {(selectedBudgetCap === "Date range" || portfolioName?.policy === "Date range") && (
                                                <>
                                                    <div className='ms-3 w-100'>
                                                        <div className='fs-13 fw-700'>Date range budget cap
                                                            <span>
                                                                <Tooltip title={tooltipContent("Date range budget cap")} placement="top" arrow className="custom-tooltip-position cursor-pointer shadow">
                                                                    <span className="ms-1">
                                                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="" >
                                                                            <path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                        <div className='my-2'>
                                                            <div className="input-group mb-3 my-2">
                                                                <span className="input-group-text input bg-white border-dark">
                                                                    <img src={RupeeIcon} alt="" className="" />
                                                                </span>
                                                                <input type="number" name="amount" defaultValue={portfolioName?.amount}
                                                                    className="form-control input border-dark border-start-0 ps-0" placeholder='Enter amount'
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                            </div>
                                                            {formErrors.amount && (
                                                                <div className="text-danger text-start col-12"> {formErrors.amount}</div>
                                                            )}
                                                            <div className='d-flex justify-content-start'>
                                                                <div className="fs-13">
                                                                    <label className="form-label fs-13">Budget start</label>
                                                                    <input type="date" className='form-control border-dark input fs-13' name="start_date" min={currentDate} defaultValue={portfolioName?.start_date} onChange={(e) => handleInput(e)} />
                                                                    {formErrors.start_date && (
                                                                        <div className="text-danger text-start w-100"> {formErrors.start_date}</div>
                                                                    )}
                                                                </div>
                                                                <div className="fs-13 ms-4">
                                                                    <label className="form-label fs-13 fw-normal">Budget end</label>
                                                                    <input type="date" className='form-control border-dark input fs-13' name="end_date" min={portfolioName?.start_date} defaultValue={portfolioName?.end_date} onChange={(e) => handleInput(e)} />
                                                                    {formErrors.end_date && (
                                                                        <div className="text-danger text-start w-100"> {formErrors.end_date}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='card rounded-0 budget-message-container ms-0'>
                                                        <div className='fs-14'>
                                                            <span className='dropdown-sub-title-text-color fw-200'>Budget cap : </span>
                                                            <span className='text-dark'> -  total spend, starting Sep 21, 2023</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {selectedBudgetCap === "Recurring monthly" || portfolioName?.policy === "Recurring monthly" && (
                                                <>
                                                    <div className='ms-3'>
                                                        <div className='fs-13 fw-700'>Monthly budget cap
                                                            <span>
                                                                <Tooltip title={tooltipContent("Monthly budget cap")} placement="top" arrow className="custom-tooltip-position cursor-pointer shadow">
                                                                    <span className="ms-1">
                                                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="" >
                                                                            <path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mb-1 mt-2">
                                                                <span className="input-group-text input bg-white border-dark">
                                                                    <img src={RupeeIcon} alt="" className="" />
                                                                </span>
                                                                <input type="number" name="amount" defaultValue={portfolioName?.amount}
                                                                    className="form-control input border-dark border-start-0 ps-0 rounded-start-0 rounded" placeholder='Enter amount'
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {formErrors.amount && (
                                                                    <div className="text-danger text-start w-100"> {formErrors.amount}</div>
                                                                )}
                                                                <div className='fs-13 budget_text-color mb-1'>Restarts on the 1st of each month.</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='fs-13 fw-bold'>Ends</div>
                                                            <div className="mx-2">
                                                                <div className="d-flex form-check">
                                                                    <input
                                                                        type="radio"
                                                                        id="check-radio"
                                                                        name="negative_keyword_targeting"
                                                                        className="form-check-input me-2"
                                                                        defaultChecked={recurringMonthlyEnds === 'Never'}
                                                                        onChange={() => handleChangeRecurringMonthlyStatus('Never')}
                                                                    />
                                                                    <label className="my-1 cursor-pointer" htmlFor='check-radio'>
                                                                        <div className="fs-13">Never</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="mx-2">
                                                                <div className="d-flex form-check">
                                                                    <input
                                                                        type="radio"
                                                                        id="check-radio-one"
                                                                        name="negative_keyword_targeting"
                                                                        className="form-check-input me-2"
                                                                        defaultChecked={recurringMonthlyEnds === 'On'}
                                                                        onChange={() => handleChangeRecurringMonthlyStatus('On')}
                                                                    />
                                                                    <label className="my-1 cursor-pointer" htmlFor='check-radio-one'>
                                                                        <div className="fs-13">On</div>
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input type="date" className='form-control' name="end_date" defaultValue={portfolioName?.end_date}
                                                                        onChange={(e) => handleInput(e)} disabled={recurringMonthlyEndsType} />
                                                                    {formErrors.end_date && (
                                                                        <div className="text-danger text-start w-100"> {formErrors.end_date}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='card rounded-0 budget-message-container ms-0'>
                                                        <div className='fs-14'>
                                                            <span className='dropdown-sub-title-text-color fw-200'>Budget cap : </span>
                                                            <span className='text-dark'> -  each month</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="buttons-container custom-z-index card-width mx-auto my-2">
                        <div className="d-flex justify-content-end">
                            <div className="mx-2">
                                <button
                                    className="btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-4 py-1"
                                    onClick={onClickCancelPortfolio}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="">
                                <button
                                    className="request-call-button text-white fs-13 cursor-pointer"
                                    onClick={onClickSaveChangesPortofolio}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="footer-container mt-5 pt-2 pb-3 card-width fixed-bottom d-flex justify-content-center mx-auto">
                        <CustomFooter />
                    </div>
                </div>
            </div>
        </div >
    )
}