export const LOGOUT = "logout";
export const LOADING = "loading";
export const LOGINPOPUP = "loginPopup";
export const USERDATA = "userData";
export const USERUUIDDATA = "userUuidData";
export const PROFILEDATA = "profileData";
export const CAMPAIGNDATA = "campaignData";
export const ADGROUPADSDATA= "adgroupsAdsData";
export const ADGROUPSETTINGSDATA= "adgroupsSettingsData";
export const CAMPAIGNSETTINGSDATA= "campaignSettingsData";
export const CAMPAIGNBIDDINGCAMPAIGNDETAILSDATA= "campaignBiddingCampainDetailsData";
export const AICAMPAIGNLOADINGDATA= "aiCampaignLoadingData";
