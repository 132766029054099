import { CustomTableCol } from "../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const NegativeKeywordsDataGridCols: CustomTableCol[] = [
  {
    title: "Keyword",
    control: "keywordText",
    sortable: false,
    canShowColumn: true,
    style: { width: "300px", left: 114, height: "40px" },
  },
  {
    title: "Match type",
    control: "matchType",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Negative keyword match type"
    },
  },
];
