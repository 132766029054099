import API from "../utils/axios";
const api_url = "/api";

export const CREATE_REPORTS = (data: any): Promise<any> => {
    return API.post(`${api_url}/day_wise_reports/fetch_day_wise_report`, data);
};

export const GET_REPORTS_DETAILS = (data: any): Promise<any> => {
    return API.post(`${api_url}/day_wise_reports/fetch_day_wise_report`, data);
};
