import React, { useEffect, useState } from 'react'

interface Props {
    camapaignInfo?: any;
    onTabActions?: (data: any) => void;
    aiActionRejectSubmit?: (data: any) => void;
    aiActionRejectCancel?: (data: any) => void;
    campaigns?: any;
    adGroups?: any;
    productAds?: any;
    targeting?: any;
    isProductAdsActions?: any;
    isShowReject?: any;
}

export const AiActionsTabs = (props: Props) => {
    const [manualActionData, setManualActionData] = useState<any>({
        action_type: "",
        action_value: ""
    })
    const [manualAcceptFormErrors, setManualAcceptFormErrors] = useState<any>({})
    const [showAiActionRejectInput, setShowAiActionRejectInput] = useState<boolean>(props?.isShowReject ? true : false);
    const [showAiActionButtons, setShowAiActionButtons] = useState<boolean>(props?.isShowReject ? true : false);
    const [aiRejectReasonData, setAiRejectReasonData] = useState<any>({
        rejection_message: ""
    })
    const [aiRejectFormErrors, setAiRejectFormErrors] = useState<any>({})
    const isAISuggestionEmpty: any = Object.keys(props?.camapaignInfo.ai_suggestion === undefined ? true : props?.camapaignInfo.ai_suggestion).length === 0;
    const isNotQualifiedAISuggestion = props?.camapaignInfo?.ai_suggestion?.suggestion === "Not Qualified";

    const manualActionsList = [
        {
            label: "Increased by",
            value: "increase"
        },
        {
            label: "Decreased by",
            value: "reduce"
        },
    ]

    const manualProductAdsList = [
        {
            label: "Pause",
            value: "pause"
        },
        {
            label: "Continue",
            value: "continue"
        },
    ]

    const handleManualActionInput = (e: any) => {
        const { name, value } = e.target;
        setManualActionData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        setManualAcceptFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateAISpecificFeild(name, value),
        }));
    }

    const handleRejectComments = (e: any) => {
        const { name, value } = e.target;
        setAiRejectReasonData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        setAiRejectFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateAISpecificFeild(name, value),
        }));
    }

    const validateAISpecificFeild = (name: any, value: any) => {
        if (name === "rejection_message") {
            if (!value) {
                return "Please enter comments"
            }
        }
        if (name === "action_type") {
            if (!value) {
                return "Please select action type"
            }
        }
        if (name === "action_value") {
            if (!value) {
                return "Please enter the value"
            } else if (value < 0) {
                return "Action values minimum should be 1."
            }
        }
        return "";
    }

    const onTabsActions = (data: any, type: any) => {
        if (type === "AI Accept") {
            const actionData = { data: data, type: type }
            if (props?.onTabActions) {
                props?.onTabActions(actionData)
            }
        } else if (type === "Manual Accept") {
            const isvalid = validateManualAcceptForm();
            if (isvalid) {
                const actionData = { data: manualActionData, campaignInfo: props?.camapaignInfo, type: type }
                if (props?.onTabActions) {
                    props?.onTabActions(actionData)
                }
            }
        } else {
            const actionData = { data: data, type: type }
            if (props?.onTabActions) {
                props?.onTabActions(actionData)
            }
        }
    }

    const onAiActionReject = () => {
        setAiRejectReasonData({ ...aiRejectReasonData, rejection_message: "" })
        setShowAiActionRejectInput(true);
        setShowAiActionButtons(true);
    }

    const onAiActionRejectCancel = () => {

        if (props?.isShowReject) {
            if (props?.aiActionRejectCancel) {
                props?.aiActionRejectCancel(true);
            }
        } else {
            setShowAiActionRejectInput(false);
            setShowAiActionButtons(false);
        }
    }

    const validateManualAcceptForm = () => {
        const errors: any = {};
        if (!manualActionData?.action_type) {
            errors.action_type = "Please select action type"
        }

        if (!props?.isProductAdsActions) {
            if (!manualActionData.action_value) {
                errors.action_value = "Please enter action value"
            }
        }
        setManualAcceptFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const validateAiRejectionForm = () => {
        const errors: any = {}
        if (!aiRejectReasonData?.rejection_message) {
            errors.rejection_message = "Please enter comments"
        }
        setAiRejectFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onAiRejectSubmit = (rejectData: any) => {
        const isValid = validateAiRejectionForm();
        if (isValid) {
            aiRejectReasonData.uuid = rejectData?.ai_suggestion?.uuid
            if (props?.aiActionRejectSubmit) {
                props?.aiActionRejectSubmit(aiRejectReasonData)
            }
        }
    }

    return (
        <div>
            <div className="col-12 px-3 bg-white py-0">
                {/* <div className="tabs-section px-0">
                    <div className="nav nav-underline border-bottom border-secondary" id="nav-tab" role="tablist">
                        <button className="nav-link text-dark active fs-13" id="nav-suggested" data-bs-toggle="tab" data-bs-target="#nav-suggest"
                            type="button" role="tab" aria-controls="nav-suggest" aria-selected="true">AI </button>
                        <button
                            className="nav-link text-dark fs-13" id="nav-enter-list" data-bs-toggle="tab" data-bs-target="#nav-enter-list-keyword"
                            type="button" role="tab" aria-controls="nav-enter-list-keyword" aria-selected="false">Manual</button>
                    </div>
                </div> */}
                <div className="tab-content px-3" id="nav-tabContent">
                    <div className="tab-pane fade show active suggested-tab" id="nav-suggest" role="tabpanel" aria-labelledby="nav-suggested" tab-index="0">
                        <div className="row my-3">
                            <div className='col-12 mx-auto'>
                                {props?.campaigns && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Campaign</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.campaign_name}</div></div>)}
                                {props?.adGroups && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Ad group</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.ad_group_name}</div> </div>)}
                                {props?.productAds && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Product ad</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.title}</div> </div>)}
                                {props?.targeting && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Keyword</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.keyword_text}</div> </div>)}
                                {(props?.campaigns || props?.productAds || props?.productAds) && (<div className="row align-items-center mb-3 fw-500">
                                    <div className='col-4 text-start ps-4'>Suggestion</div><div className='col-1 text-center'>:</div><div className='col-7 text-start'>{Object.keys(props?.camapaignInfo?.ai_suggestion).length == 0 ? "None" : props?.camapaignInfo?.ai_suggestion?.suggestion}</div>
                                </div>)}
                                {props.targeting && <div className="row align-items-center mb-3 fw-500">
                                    <div className='col-4 text-start ps-4'>Suggestion</div><div className='col-1 text-center'>:</div><div className='col-7 text-start'>{Object.keys(props?.camapaignInfo?.ai_suggestion).length == 0 ? "None" : props?.camapaignInfo?.ai_suggestion?.suggestion?.replace(/budget/g, 'bid')}</div>
                                </div>}
                                {showAiActionRejectInput && (
                                    <div className='py-3'>
                                        <label className='form-labe d-flex justify-content-start'>Comments</label>
                                        <textarea rows={3} className="form-control" name="rejection_message" defaultValue={aiRejectReasonData?.rejection_message} placeholder="Please enter comments" onChange={(e: any) => handleRejectComments(e)}></textarea>
                                        {aiRejectFormErrors?.rejection_message && (<div className="text-danger">{aiRejectFormErrors?.rejection_message}</div>)}
                                    </div>
                                )}
                                {showAiActionRejectInput && showAiActionButtons && (
                                    <div className="d-flex justify-content-center py-3">
                                        <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 mx-3" onClick={(e) => onAiRejectSubmit(props?.camapaignInfo)}>Submit</button>
                                        <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-5`} onClick={onAiActionRejectCancel}>Cancel</button>
                                    </div>
                                )}
                                {(!props?.camapaignInfo?.is_ai_update && !showAiActionButtons && !isAISuggestionEmpty && !props?.adGroups && !isNotQualifiedAISuggestion) && (
                                    <div className="d-flex justify-content-center py-3">
                                        <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 mx-3" onClick={(e) => onTabsActions(props?.camapaignInfo, "AI Accept")}>Accept</button>
                                        <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-5`} onClick={onAiActionReject}>Reject</button>
                                    </div>
                                )}
                                {props?.camapaignInfo?.is_ai_update && (
                                    <div className="d-flex justify-content-center py-3">
                                        <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 mx-3" onClick={(e) => onTabsActions(props?.camapaignInfo, "navigate")}>Navigate to configurations</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade enterlist-tab"
                        id="nav-enter-list-keyword" role="tabpanel"
                        aria-labelledby="nav-enter-list" tab-index="0"
                    >
                        <div className="row my-3">
                            <div className='col-12'>
                                {props?.campaigns && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Campaign</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.campaign_name}</div></div>)}
                                {props?.adGroups && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Ad group</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.ad_group_name}</div> </div>)}
                                {props?.productAds && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Product ad</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.title}</div> </div>)}
                                {props?.targeting && (<div className='row align-items-center mb-3 fw-500'><div className='col-4 text-start ps-4'>Keyword</div><div className='col-1 text-center'>: </div><div className='col-7 text-start'>{props?.camapaignInfo?.keyword_text}</div> </div>)}
                                <div className={`row my-3 ${props?.isProductAdsActions ? "d-flex justify-content-center mx-auto" : ""}`}>
                                    <div className={`${props?.isProductAdsActions ? "col-md-8" : "col-md-6"}`}>
                                        <label className='form-label'>Action Type</label>
                                        <select className='form-select placeholder-color' name="action_type" value={manualActionData?.action_type} onChange={(e) => handleManualActionInput(e)}>
                                            <option value="">Select Action Type</option>
                                            {!props?.isProductAdsActions && manualActionsList?.map((action: any, index: number) => (
                                                <option key={index} value={action?.value}>{action?.label}</option>
                                            ))}
                                            {props?.isProductAdsActions && manualProductAdsList?.map((action: any, index: number) => (
                                                <option key={index} value={action?.value}>{action?.label}</option>
                                            ))}
                                        </select>
                                        {manualAcceptFormErrors?.action_type && (<div className='text-danger'>{manualAcceptFormErrors?.action_type}</div>)}
                                    </div>
                                    {!props?.isProductAdsActions && (
                                        <div className='col-md-6'>
                                            <label className='form-label'>Action Value</label>
                                            <input type="number" className='form-control placeholder-color' name="action_value" placeholder='Please enter action value' defaultValue={manualActionData?.action_value} onChange={(e) => handleManualActionInput(e)} />
                                            {manualAcceptFormErrors?.action_value && (<div className='text-danger'>{manualAcceptFormErrors?.action_value}</div>)}
                                        </div>
                                    )}
                                </div>
                                {(!props?.camapaignInfo?.is_ai_update || Object.keys(props?.camapaignInfo?.ai_suggestion).length == 0) && (
                                    <div className="d-flex justify-content-center py-3">
                                        <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 py-2 mx-3" onClick={(e) => onTabsActions(props?.camapaignInfo, "Manual Accept")} >Submit</button>
                                    </div>
                                )}
                                {props?.camapaignInfo?.is_ai_update && (
                                    <div className="d-flex justify-content-center py-3">
                                        <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 mx-3" >Navigate to configurations</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}