import React from 'react'
import { Routes, Route } from "react-router-dom";
import { GroupProductsConfigurationPage } from '../pages/Dashboard/configuration/campaign/groups/group-info/products/products';
import { GroupTargetsConfigurationPage } from '../pages/Dashboard/configuration/campaign/groups/group-info/targets/targets';

export const ConfigurationGroupsRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<GroupProductsConfigurationPage />}></Route>
                <Route path="/products" element={<GroupProductsConfigurationPage />}></Route>
                <Route path="/targets" element={<GroupTargetsConfigurationPage />}></Route>
            </Routes>
        </div>
    )
}
