import React, { useState } from 'react'
import { TooltipPage } from '../../custom-tooltip/tooltipPage'
import { Tooltip } from '@mui/material'

interface Props {
    aiFlowNegativeKeywordTargetingData?: (data: any) => void;
}
export const NegativeKeywordTargetingStepPage = (props: Props) => {
    const [keywordMatchtype, setKeywordMatchtype] = useState<any>('Negative exact');
    const [negativeKeywordText, setNegativeKeywordText] = useState<any>({
        negative_keyword_text: ""
    })
    const [negativeKeywordTextStatus, setNegativeKeywordTextStatus] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [addNegativeKeywordsData, setAddNegativeKeywordData] = useState<any>([]);

    const tooltipContent = (data: any) => {
        if (data === "negative Keyword targeting card header optional") {
            return (
                <TooltipPage
                    tooltipMainContent={
                        "Negative keywords prevent your ads from displaying when a shopper's search terms match your negative keywords. You can exclude irrelevant searches, reducing your advertising cost."
                    }
                    tooltipSubContent={""}
                />
            );
        } else if (data === "negative Keyword targeting Match type") {
            return (
                <TooltipPage
                    tooltipMainContent={
                        "Negative keyword match types allow you to fine-tune which customer search terms trigger your ads."
                    }
                    tooltipSubContent={"Learn about negative match types"}
                />
            );
        } else if (data === "negative Keyword targeting table Match type") {
            return (
                <TooltipPage
                    keyWordBroad={
                        " Contains all the keywords in any order and includes plurals, variations and related keywords."
                    }
                    keyWordPhrase={" Contains the exact phrase or sequence of keywords."}
                    keyWordExact={"Exactly matches the keyword or sequence of keywords"}
                    tooltipLearnMoreText={"Learn More"}
                    keyWordTableText={
                        "Match types allow you to fine-tune which customer searches trigger your ads."
                    }
                />
            );
        }
    }

    const handleChangeNegativeKeywordTargetingMatchType = (selectedData: any) => {
        setKeywordMatchtype(selectedData);
    }

    const handleChangeNegativeKeywordText = (e: any) => {
        const { name, value } = e.target;
        setNegativeKeywordText((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        const data = e.target.value;
        if (data?.trim()?.length > 0) {
            setNegativeKeywordTextStatus(true);
        } else {
            setNegativeKeywordTextStatus(false);
        }
        setNegativeKeywordText(data)
    }

    const onClickAddNegativeKeywords = () => {
        const preparedData = addNegativeKeywordsData;
        const splitData = negativeKeywordText.split('\n').filter((line: any) => line.trim() !== '');
        splitData.forEach((element: any) => {
            const data = {
                negative_keyword_matchType: keywordMatchtype === "Negative exact" ? "NEGATIVE_EXACT" : "NEGATIVE_PHRASE",
                negative_keyword_text: element, // negativeKeywordText,
                state: "ENABLED"
            }
            preparedData.push(data);
        });

        setSuccessMessage('1/1 keywords were added successfully.');
        setAddNegativeKeywordData([...preparedData])
        if (props?.aiFlowNegativeKeywordTargetingData) {
            props?.aiFlowNegativeKeywordTargetingData(preparedData)
        }
        setNegativeKeywordText({
            ...negativeKeywordText,
            negative_keyword_text: ""
        });
        setNegativeKeywordTextStatus(false);
    }

    const onClickCancelNegtaiveKeywordTargeting = (item: any, index: number) => {
        const preparedData = addNegativeKeywordsData;
        preparedData.splice(index, 1);
        setAddNegativeKeywordData([...preparedData])
    }

    const onClickRemoveNegativeKeywordsData = () => {
        setAddNegativeKeywordData([]);
        setSuccessMessage('');
        setNegativeKeywordText("");
        setNegativeKeywordTextStatus(false);
    }
    return (
        <div>
            <div className="negative-keyword-targeting-container card-width mx-auto mb-3">
                <div className="accordion " id="accordionOne">
                    <div className="accordion-item border rounded">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button
                                className="accordion-button down-arrow down-arrow-one campaign-creation-negative-keyword-targeting-accordion collapsed fs-24 fw-light"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="true"
                                aria-controls="flush-collapseOne"
                            >
                                <span className="px-4">Negative keyword targeting</span>
                                <Tooltip
                                    title={tooltipContent(
                                        "negative Keyword targeting card header optional"
                                    )}
                                    placement="top"
                                    arrow
                                    className="custom-tooltip-position cursor-pointer "
                                >
                                    <span className="mx-1">
                                        <svg
                                            viewBox="0 0 512 512"
                                            width="12"
                                            height="12"
                                            className="fas fa-info-circle mb-3"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Tooltip>
                                <div className="d-flex justify-content-between">
                                    <div className="ms-auto">
                                        <span className="fs-13 optional-text-color mt-2 ms-auto">
                                            optional
                                        </span>
                                    </div>
                                    <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2 keyword-target-position campaign-creation-keyword-target-position campaign-creation-keyword-target-position">
                                        <span>
                                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                        </span>
                                        How to use negative keywords
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionOne"
                        >
                            <div className="accordion-body border-top py-0">
                                <div className="row px-3">
                                    <div className="col-6 bg-white products-tabs-section border-right">
                                        <div className="d-flex justify-content-between my-2">
                                            <div className="mx-2 fs-12 fw-bold my-1">
                                                Match type
                                                <Tooltip
                                                    title={tooltipContent("negative Keyword targeting Match type"
                                                    )}
                                                    placement="top" arrow className="custom-tooltip-position cursor-pointer">
                                                    <span className="mx-1">
                                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg="" >
                                                            <path fill="#8290a4"
                                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div className="mx-2">
                                                <div className="d-flex form-check">
                                                    <input
                                                        type="radio"
                                                        name="negative_keyword_targeting"
                                                        id="MatchTypeExact"
                                                        className="form-check-input mb-3 me-3"
                                                        defaultChecked={keywordMatchtype === 'Negative exact'}
                                                        onChange={() => handleChangeNegativeKeywordTargetingMatchType('Negative exact')}
                                                    />
                                                    <label className="my-1 cursor-pointer" htmlFor="MatchTypeExact">
                                                        <div className="fs-12">Negative exact</div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mx-2">
                                                <div className="d-flex form-check">
                                                    <input
                                                        type="radio"
                                                        name="negative_keyword_targeting"
                                                        id="negativeKeywordMAtchType"
                                                        className="form-check-input mb-3 me-3"
                                                        defaultChecked={keywordMatchtype === 'Negative phrase'}
                                                        onChange={() => handleChangeNegativeKeywordTargetingMatchType('Negative phrase')}
                                                    />
                                                    <label className="my-1 cursor-pointer" htmlFor="negativeKeywordMAtchType">
                                                        <div className="fs-12">Negative phrase</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row my-1">
                                            <div className="my-0">
                                                <textarea
                                                    rows={8}
                                                    className="form-control fs-14 border-dark"
                                                    placeholder="Enter keywords separated by new line."
                                                    name="negative_keyword_text"
                                                    onChange={handleChangeNegativeKeywordText}
                                                    value={negativeKeywordText?.negative_keyword_text}
                                                ></textarea>
                                            </div>
                                            <div className=''>
                                                {successMessage && (
                                                    <span className='fs-13'>
                                                        <span><i className="bi bi-check text-success fs-21"></i></span><span className="text-success ml-2 fs-13">{successMessage}</span>
                                                    </span>
                                                )}
                                            </div>
                                            <div className="text-end my-2">
                                                <button className={`btn btn-dark border-0 rounded-pill text-secondary 
                                                custom-button-background fs-12 ${negativeKeywordTextStatus ? "cursor-pointer text-dark" : "cursor-pointer-not-allowed"}`}
                                                    onClick={onClickAddNegativeKeywords}
                                                >
                                                    Add keywords
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 product-preview-container p-0">
                                        <div className="negative-keyword-right-container ">
                                            <div className="d-flex justify-content-between py-2 px-3">
                                                <div className="fs-13 fw-bold ">{addNegativeKeywordsData?.length} added</div>
                                                <div className={`fs-13 ${addNegativeKeywordsData?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : "cursor-pointer-not-allowed remove-all-text"}`}
                                                    onClick={onClickRemoveNegativeKeywordsData}>
                                                    Remove all
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between border-top border-bottom py-2 px-3">
                                                <div className="fs-13">Keyword</div>
                                                <div className="fs-13 me-5 pe-4">
                                                    <div className="mx-2 fs-12 ">
                                                        Match type
                                                        <Tooltip
                                                            title={tooltipContent(
                                                                "negative Keyword targeting table Match type"
                                                            )}
                                                            placement="top"
                                                            arrow
                                                            className="custom-tooltip-position cursor-pointer "
                                                        >
                                                            <span className="mx-1 cursor-pointer">
                                                                <svg
                                                                    viewBox="0 0 512 512"
                                                                    width="12"
                                                                    height="12"
                                                                    className="fas fa-info-circle"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="#8290a4"
                                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            {addNegativeKeywordsData?.map((keywordData: any, keywordDataIndex: number) => (
                                                <div key={keywordDataIndex} className='d-flex justify-content-between px-3 border-bottom'>
                                                    <div className="my-1">{keywordData?.negative_keyword_text}</div>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className='text-start me-2 my-1'>{`${keywordData?.negative_keyword_matchType === "NEGATIVE_EXACT" ? "Negative exact" : "Negative phrase"}`}</div>
                                                        <div className="fs-21 ms-1">
                                                            <i className="bi bi-x cursor-pointer" onClick={() => onClickCancelNegtaiveKeywordTargeting(keywordData, keywordDataIndex)} ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
