import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@mui/material';
import { CustomFilter } from '../../../components/custom-filter/custom-filter';
import ChartComponent from '../../../components/charts/line-chart';
import { CustomTable } from '../../../components/custom-table';
import { PortfoliosDataGridCols } from './portfolios-data-grid-cols';
import PlusIcon from "../../../assets/images/svg_icons/plus.svg";
import { TooltipPage } from '../../../components/custom-tooltip/tooltipPage';
import { CustomFooter } from '../../../components/footer/custom-footer';
import { CREATE_PORTFOLIO, GET_USER_PORTFOLIOS } from '../../../app/services/portfolios.service';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_PORTFOLIOS_SUMMARY_REPORT } from '../../../app/services/portfolio-summary-report.service';
import moment from 'moment';

interface Props {
    ProfileDataReducer?: any;
    profileData?: any;
    UserDataReducer?: any;
    userData?: any;
    UserUuidDataReducer?: any;
    userUuidData?: any;
    campaignData?: any;
    campaignDataReducer?: any;
}

const PortPolioPage = (props: Props) => {
    const [showChart, setShowChart] = useState<boolean>(true);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [canShowAddMetric, setCanShowAddMetric] = useState<boolean>(false);
    const [selectedMetricIndex, setSelectedMetricIndex] = useState<number | null>(null);
    const [portfolioName, setPortfolioName] = useState<any>({
        portfolio_name: ""
    });
    const [portfolioButtonStatus, setPortfolioButtonStatus] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const modalRef: any = useRef(null);
    const navigate = useNavigate();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const [loading, setLoading] = useState<boolean>(false);
    const [portfoliosData, setPortfoliosData] = useState<any>();
    const [portfoliosOriginalData, setPortfoliosOriginalData] = useState<any>();
    const loginUserUuid = props?.userUuidData?.loginUserUuidData;
    const [summaryReportsData, setSummaryReportsData] = useState<any>();
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const defaultEndDate = currentDate;
    const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
    const updatedData = JSON.parse(sessionUpdateDateRange);

    const [dateRangeData, setDateRangeData] = useState<any>({
        start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
    })

    const [metricCardsData, setMetricCardsData] = useState<any>([
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #ff5733",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #373782",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #fe4a4a",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #4949da",
            key: 'impressions'
        },
        {
            card_title: "CTR",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #3e1bc2",
            key: 'clickThroughRate'
        },
        {
            card_title: "Clicks",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #301934",
            key: 'clicks'
        },
        {
            card_title: "CPC",
            amount: '',
            sub_title: "AVERAGE",
            border_color: "4px solid #1d2383",
            key: 'costPerClick'
        },
        {
            card_title: "Orders",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #53f453",
            key: 'orders'
        },
        {
            card_title: "ACOS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #ff1cff",
            key: 'acoss'
        },
    ]);
    const [grpahCardsData, setGrpahCardsData] = useState(
        [metricCardsData[0], metricCardsData[1], metricCardsData[2], metricCardsData[3]]
    );

    const yOneAxisData = {
        type: 'linear',
        position: 'right',
        min: 0,
        max: 25,
        stepSize: 5,
    }

    useEffect(() => {
        const loginUserUuid = props?.userUuidData?.loginUserUuidData;
        if (loginUserUuid) {
            getUserPortfolios(props?.userUuidData?.loginUserUuidData, dateRangeData);
        }
    }, [props?.userUuidData?.loginUserUuidData])

    const tableData = {
        page: "0",
        limit: "10",
        total: 14,
        rows: portfoliosData
    }

    useEffect(() => {
        const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
        const updatedData = JSON.parse(sessionUpdateDateRange);
        if (dateRangeData) {
            setDateRangeData({ ...dateRangeData, start_date: updatedData?.start_date, end_date: updatedData?.end_date })
        }
    }, [])

    useEffect(() => {
        getPortfolioSummaryReport(dateRangeData);
    }, [])

    const tablefooterData = [
        {
            portfolio_name: `Total: ${portfoliosData?.length ? portfoliosData?.length : 0}`,
            impressions: '-',
            clicks: '-',
            clickThroughRate: '-',
            cost: '-',
            costPerClick: "-",
            purchasesSameSku14d: "-",
            sales30d: "-",
            acosClicks14d: "-",
            roasClicks14d: "-",
        }
    ];

    const tableBottomTextData = 'Campaign status is current, but other campaign data could be delayed up to 12 hours.'

    const getUserPortfolios = (userUuid: any, dateRangeData: any,) => {
        const sessionDateRange: any = localStorage?.getItem('updated_date_range')
        const dateRange = JSON.parse(sessionDateRange)
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            start_date: dateRange?.start_date ? dateRange?.start_date : dateRangeData?.start_date,
            end_date: dateRange?.end_date ? dateRange?.end_date : dateRangeData?.end_date,
            is_report_data: true
        }
        setLoading(true);
        GET_USER_PORTFOLIOS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data;
                    for (let i = 0; i < response?.data?.length; i++) {
                        const metricsData: any = response.data[i]?.metricsData || {};
                        response.data[i].impressions = metricsData?.impressions ? metricsData?.impressions?.toLocaleString('en-IN') : '-';
                        response.data[i].clicks = metricsData?.clicks ? metricsData?.clicks?.toLocaleString('en-IN') : '-';
                        response.data[i].clickThroughRate = metricsData?.clickThroughRate ? getCTRData(Number(metricsData.clickThroughRate).toFixed(2)) : '-';
                        response.data[i].cost = metricsData?.cost ? (getRupeeData(metricsData?.cost)) : '-';
                        response.data[i].costPerClick = metricsData?.costPerClick ? getRupeeData(metricsData?.costPerClick) : '-';
                        response.data[i].purchasesSameSku14d = metricsData?.purchasesSameSku14d ? metricsData?.purchasesSameSku14d?.toLocaleString('en-IN') : '-';
                        response.data[i].sales30d = metricsData?.sales30d ? (getRupeeData(metricsData?.sales30d)) : '-';
                        response.data[i].acosClicks14d = metricsData?.acosClicks14d ? (getPercentageData(metricsData?.acosClicks14d)) : '-';
                        response.data[i].roasClicks14d = isNaN(parseFloat(metricsData?.roasClicks14d))
                            ? "-"
                            : parseFloat(metricsData?.roasClicks14d)?.toFixed(2);
                    }
                    setPortfoliosData(response?.data)
                    setPortfoliosOriginalData(response?.data)
                    setLoading(false);
                }
            }
        });
    }

    const getPercentageData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return "0";
        } else if (data === undefined) {
            return "-";
        } else {
            return data?.toFixed(2) + '%';
        }
    }

    const getCTRData = (data: any) => {
        if (data === "NaN") {
            return "-"
        } else if (data === undefined) {
            return "-";
        } else {
            return data + "%";
        }
    }

    const getRupeeData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return 0;
        } else if (data === undefined) {
            return "-";
        } else {
            const preparedData = parseFloat(data?.toFixed(2))?.toLocaleString('en-IN');
            return `${preparedData}`
        }
    }

    const getshowChatStatus = (status: any) => {
        setShowChart(status);
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowFeedbackModal(false);
            }
        };

        const handleEscapeKey = (event: any) => {
            if (event.key === 'Escape') {
                setShowFeedbackModal(false);
            }
        };

        if (showFeedbackModal) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [showFeedbackModal]);

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    }

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    }

    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "Spend") {
            return (
                <TooltipPage
                    tooltipHeader='Spend'
                    tooltipMainContent={`The total click charges for a product ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipSubContent={'Learn about spend'} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Sales") {
            return (
                <TooltipPage
                    tooltipHeader='Sales'
                    tooltipMainContent={` Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
                    Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 14 days.
                    Sponsored Brands: Sales from advertised products sold by Amazon within 14 days. Where eligible, this also includes sales from all products within your brand sold by Amazon and third-party sellers.
                    It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
                    Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
                    tooltipSubContent={'Learn about sales'} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "ROAS") {
            return (
                <TooltipPage
                    tooltipHeader='Return on ad spend (ROAS)'
                    tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
                    tooltipSubContent={'Learn about return on ad spend (ROAS)'} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CPC") {
            return (
                <TooltipPage
                    tooltipHeader='Cost-per-click (CPC)'
                    tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipSubContent={'Learn about cost-per-click (CPC)'} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Impressions") {
            return (
                <TooltipPage
                    tooltipHeader='Impressions'
                    tooltipMainContent={`The number of times ads were displayed`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Clicks") {
            return (
                <TooltipPage
                    tooltipHeader='Clicks'
                    tooltipMainContent={`The number of times your ads were clicked. Note: Once identified, it may take up to 3 days to remove invalid clicks.`}
                    tooltipSubContent={`Learn about clicks`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CTR") {
            return (
                <TooltipPage
                    tooltipHeader='Click-through rate (CTR)'
                    tooltipMainContent={`The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipSubContent={`Learn about click-through rate (CTR)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Orders") {
            return (
                <TooltipPage
                    tooltipHeader="Orders"
                    tooltipMainContent={`Orders is the number of Amazon orders shoppers submitted after clicking on or viewing your ads.
                    Sponsored Products: Orders from advertised products as well as other products from your inventory purchased within 7 days.
                    Sponsored Brands: Orders from advertised products and all products within your brand sold by Amazon and third-party Sellers within 14 days.
                    It can take up to 12 hours for your orders data to update. As a result, orders data may be delayed in the Today date range. We recommend waiting until all orders data is populated before evaluating campaign performance.
                    Payment failures and orders that are cancelled within 72 hours will be removed from orders totals.`}
                    tooltipSubContent={`Learn about orders`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "ACOS") {
            return (
                <TooltipPage
                    tooltipHeader="Advertising cost of sales (ACOS)"
                    tooltipMainContent={`ACOS is the percent of attributed sales spent on advertising within 14 days of clicks on your ads. This is calculated by dividing total spend by attributed sales.`}
                    tooltipSubContent={`Learn about advertising cost of sales (ACOS)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        }
    }

    const onCreatePortfolio = (data: any) => {
        setShowFeedbackModal(true);
    }

    const onChangePortfolioName = (e: any) => {
        const { name, value } = e.target;
        setPortfolioName((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        if (portfolioName?.portfolio_name?.trim()?.length > 0 || formErrors[name]) {
            setPortfolioButtonStatus(true);
        } else {
            setPortfolioButtonStatus(false);
        }
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "portfolio_name") {
            if (!value) {
                return "Please enter portfolio name"
            }
        }
        return "";
    }

    const validateForm = () => {
        const errors: any = {};
        if (!portfolioName?.portfolio_name) {
            errors.portfolio_name = "Please enter portfolio name";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onClickAddMetric = () => {
        setCanShowAddMetric(!canShowAddMetric);
    }

    const onSelectMetric = (data: any, index: any) => {
        const preparedData = grpahCardsData;
        preparedData.push(data);
        setGrpahCardsData([...preparedData]);
        setCanShowAddMetric(false);
    }

    function onRemoveMetric(data: any, index: number) {
        const preparedData = grpahCardsData;
        preparedData.splice(index, 1);
        setGrpahCardsData([...preparedData]);
    }

    const onClickCreatePortfolio = () => {
        const isValid: boolean = validateForm();
        if (isValid) {
            const portfolio = portfolioName;
            const preparedData = {
                user_uuid: userUuid,
                profile_id: profileID,
                portfolio_name: portfolio?.portfolio_name
            }
            setLoading(true)
            CREATE_PORTFOLIO(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const response = res.data.data;
                        setShowFeedbackModal(true);
                        navigate(`/dashboard/home/portfolio/${response?.portfolio_id}`);
                        toast.success(res.data.message);
                        setLoading(false);
                    }
                }
            });
        }
    }

    const onActions = (data: any) => {
        if (data.type === "link") {
            navigate(`/dashboard/home/portfolio/${data?.data?.portfolio_id}`);
        } else if (data.type === "modify") {
            localStorage.setItem('portfolio_settings_id', data?.data?.portfolio_ids)
            navigate(`/dashboard/portfolio/settings/${data?.data?.portfolio_id}`)
        }
    }

    const handleCustomSearch = (data: any) => {
        if (data?.trim()?.length > 0) {
            for (let i = 0; i < portfoliosOriginalData?.length; i++) {
                portfoliosOriginalData[i].dummy_portfolio_name = portfoliosOriginalData[i].portfolio_name.toLowerCase();
            }
            const searchData = portfoliosOriginalData?.filter((el: any) => el?.dummy_portfolio_name?.includes(data.toLowerCase()));
            setPortfoliosData(searchData)
        }
    }

    const handleClickSearchWrong = (status: any) => {
        if (!status) {
            if (loginUserUuid) {
                const dateRangeData = {
                    start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                    end_date: moment(defaultEndDate).format("YYYY-MM-DD")
                }
                getUserPortfolios(loginUserUuid, dateRangeData);
            }
        }
    }

    const handleCustomFilter = (filterData: any) => {
        if (filterData === "enabled") {
            const searchData = portfoliosOriginalData?.filter((el: any) => el?.state === "enabled");
            setPortfoliosData(searchData);
        } else if (filterData === "paused") {
            const searchData = portfoliosOriginalData?.filter((el: any) => el?.state === "paused");
            setPortfoliosData(searchData);
        } else if (filterData === "archived") {
            const searchData = portfoliosOriginalData?.filter((el: any) => el?.state === "archived");
            setPortfoliosData(searchData);
        } else if (filterData === "all") {
            if (loginUserUuid) {
                getUserPortfolios(loginUserUuid, dateRangeData);
            }
        } else {
            if (loginUserUuid) {
                getUserPortfolios(loginUserUuid, dateRangeData);
            }
        }
    }

    const getChartsData = (data: any) => {
    }

    const selectedGraphMetric = (data: any, index: number) => {
        setSelectedMetricIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const isMetricSelected = (metric: any) => {
        return (
            grpahCardsData.some((selectedMetric: any) => selectedMetric.card_title === metric.card_title)
        );
    };

    const getPortfolioSummaryReport = (data: any) => {
        const preparedData = {
            profile_id: profileID,
            user_uuid: userUuid,
            start_date: data?.start_date,
            end_date: data?.end_date
        }
        GET_PORTFOLIOS_SUMMARY_REPORT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                setLoading(false);
            } else {
                if (res) {
                    setSummaryReportsData(res.data.data[0]?.dateWiseData)
                    const totalDataObj = metricCardsData
                    const totalData = res.data.data[0]?.totalData;
                    if (totalData !== undefined) {
                        Object.keys(totalData).map(function (key) {
                            totalDataObj.forEach((element: any) => {
                                if (element.key === key) {
                                    element.amount = totalData[key]
                                    if (key === 'cost' || key === 'sales' || key === 'sales') {
                                        element.amount = `₹${getRupeeDataNavbrd(totalData[key])}`;
                                    } else if (key === "impressions") {
                                        element.amount = `${(totalData[key])?.toLocaleString('en-IN')}`
                                    } else {
                                        element.amount = getPercentageDataNavbrd(totalData[key]);
                                    }
                                }
                            });
                        });
                    }
                    setMetricCardsData([...totalDataObj])
                }
            }
        })
    }

    const getRupeeDataNavbrd = (data: any) => {
        if (data === "-") {
            return "-";
        } else {
            const preparedData = parseFloat(data?.toFixed(2)).toLocaleString('en-IN');
            return `${preparedData}`
        }
    }

    const getPercentageDataNavbrd = (data: any) => {
        if (data === "-") {
            return "-";
        } else {
            return data?.toFixed(2);
        }
    }

    const getPortfoloDateRangeFilterData = (data: any) => {
        if (loginUserUuid) {
            getUserPortfolios(loginUserUuid, data);
        }
        getPortfolioSummaryReport(data);
    }

    return (
        <div className="container-fluid">
            <div className='mt-3'>
                {showChart && (
                    <div className='row px-3'>
                        <div className="col-12 d-flex flex-wrap justify-content-between col_padding">
                            {grpahCardsData?.map((data: any, index: number) => (
                                <div key={index} className={`card col-12 col-md-6 col_md_6 col-lg-2 col_lg_2 col_xl_2 col-xl-2 my-2 my-lg-0 text-bg-white rounded-0 cursor-pointer ${index === selectedMetricIndex ? 'selected-"metric"-card' : ''}`} style={{ borderTop: `${index === selectedMetricIndex ? data?.border_color : ""}` }} onClick={() => selectedGraphMetric(data, index)}>
                                    <div className='card-header bg-white border-0 add_metrics-bg'>
                                        <div className="d-flex justify-content-between " >
                                            <div className="fs-13">{data?.card_title}
                                                <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                    <span className='ms-1 fs-15'>
                                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div className="fs-13 cross-icon cursor-pointer" onClick={() => onRemoveMetric(data, index)}>
                                                <i className="bi bi-x "></i>
                                            </div>
                                        </div>
                                        <div><span className='fs-21'>{data?.amount}</span><span>{!data?.amount && "-"}</span>&nbsp;<span className='fs-11'>{data?.sub_title}</span></div>
                                    </div>
                                </div>
                            ))}
                            {grpahCardsData?.length < 5 && <div className={`${canShowAddMetric ? 'card col-12 col-md-6 col-lg-2 col-xl-2 add-metric-border bg-white cursor-pointer' : 'card  py-3 col-12 col-md-6 col-lg-2 col-xl-2  border-1 border-dark card-border-style bg-white  rounded-0 cursor-pointer'}`} onClick={onClickAddMetric}>
                                <div className='card-header text-center bg-white border-0'>
                                    <span><img src={PlusIcon} alt='plus'/></span>
                                    add metric
                                    <div className={`${canShowAddMetric ? 'one' : ''}`}></div>
                                    <div className={`${canShowAddMetric ? 'two' : ''}`}></div>
                                </div>
                            </div>}
                        </div>
                        {canShowAddMetric && <div className='add-metric-list col-md-12'>
                            <div className='row add-metric-info d-flex justify-content-between'>
                                {metricCardsData?.map((data: any, index: number) => (
                                    <div key={index} className={`card col-12 col-md-4 col-lg-3 metrics-cols-extra-large metrics-cols-large my-2 col-xl-2 add_metrics-bg text-bg-white rounded-0 cursor-pointer col_margin ${isMetricSelected(data) ? "disabled-metric cursor-pointer-not-allowed" : ""}`} onClick={() => onSelectMetric(data, index)}>
                                        <div className='card-header bg-white border-0 add_metrics-bg'>
                                            <div className="d-flex justify-content-between">
                                                <div className="fs-13">{data?.card_title}
                                                    <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                        <span className='ms-1 fs-15'>
                                                            <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 cross-icon">
                                                    <i className="bi bi-x"></i>
                                                </div>
                                            </div>
                                            <div>{data?.amount}<span className='fs-11'>{data?.sub_title}</span></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}
                        {!canShowAddMetric && summaryReportsData &&
                            <ChartComponent yOneScale={yOneAxisData} chartData={summaryReportsData}
                                chartsData={(e) => getChartsData(e)} />
                        }
                        {!summaryReportsData && <>
                            <ChartComponent yOneScale={yOneAxisData} customCss={true} chartData={summaryReportsData}
                                chartsData={(e) => getChartsData(e)} />
                            <div className='d-flex align-items-center position-relative' style={{ top: "-200px" }}>
                                <div className='text-center mx-auto fs-4 fw-bold'>No data available</div>
                            </div>
                        </>
                        }
                    </div>
                )}
            </div>
            <div className='mt-3'>
                <CustomFilter
                    customCreateButtonText='Create portfolio'
                    customDateRangeText='Year to date'
                    customPlaceholder='Find a portfolio'
                    showChartStatus={getshowChatStatus}
                    handleCustomSearch={(e) => handleCustomSearch(e)}
                    handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
                    handleCustomFilter={(e) => handleCustomFilter(e)}
                    onClickButton={onCreatePortfolio}
                    showPortfolioDateRangeFilter={true}
                    portfolioExportFilter={true}
                    dateRangeFilterData={(e) => getPortfoloDateRangeFilterData(e)}
                    dateRangeData={dateRangeData}
                />
            </div>
            <div className="row">
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border text-dark" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className="col-12 px-3 pb-3">
                        <CustomTable
                            TableCols={PortfoliosDataGridCols}
                            tableData={tableData}
                            tablefooterData={tablefooterData}
                            tableFooterpositionTop={true}
                            tableBottomTextStatus={false}
                            tableScroll={true}
                            portfolioClass={true}
                            portfolioCustomClass={true}
                            tableLoading={!loading}
                            tableBottomText={tableBottomTextData}
                            portfolioTableContainer={true}
                            portfolioCustomScroll={true}
                            onActions={(e) => onActions(e)}
                            pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                        />
                    </div>
                }
                <div className={`footer-container pb-2`}>
                    <CustomFooter />
                </div>
            </div>
            {showFeedbackModal && (
                <div className={`modal modal-custom fade ${showFeedbackModal ? "show" : "false"}  ${showFeedbackModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >

                        <div className="modal-content portfolio-modal-content mx-auto">
                            <div className="modal-header">
                                <h5 className="modal-title portfolio-title fs-17">Create a portfolio</h5>
                                <button type="button" className="btn-close fs-12" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowFeedbackModal(false)}></button>
                            </div>
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!loading &&
                                <div className="modal-body p-1 modal-profolio-body">
                                    <div className="col-12 portfolio-modal-text px-3 my-2 fs-13">
                                        Organise your campaigns by business line, product category or season and manage total spending with budget caps.
                                        <span className='ms-1 back-to-help-text cursor-pointer logout'>Learn more</span>
                                    </div>
                                    <div className='d-flex px-3 pt-2'>
                                        <div className='portfolio-label'><span>Portfolio name</span></div>
                                        <div className='px-3'>
                                            <input type='text' name="portfolio_name" className='form-control input border-dark fs-13 portfolio-name-width' defaultValue={portfolioName.portfolio_name} onChange={onChangePortfolioName} />
                                            {formErrors.portfolio_name && (<div className="text-danger text-start w-100"> {formErrors.portfolio_name} </div>)}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="modal-footer mb-4">
                                <button
                                    type="button"
                                    className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setShowFeedbackModal(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className={`rounded-pill border-0 fs-13 ${portfolioButtonStatus ? "request-call-button text-white cursor-pointer" : "custom-cancel-btn create-portfolio-btn custom-button-background cursor-pointer-not-allowed"}`}
                                    disabled={!portfolioButtonStatus}
                                    onClick={onClickCreatePortfolio}>
                                    Create a portfolio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    )
}
const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        userUuidData: state.UserUuidDataReducer,
        campaignData: state.CampaignDataReducer
    };
};

const connectedNavBar = connect(mapStateToProps)(PortPolioPage);
export { connectedNavBar as PortPolioPage };
