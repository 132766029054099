import React, { useEffect, useState } from 'react'
import { CustomFilter } from '../../../../../components/custom-filter/custom-filter'
import { CustomTable } from '../../../../../components/custom-table'
import { AdGroupsSearchTermsDataGridCols } from './search-terms-data-grid-cols'
import { CustomFooter } from '../../../../../components/footer/custom-footer';
import { toast } from "react-toastify";
import { GET_AD_GROUP_SEARCH_TERMS } from '../../../../../app/services/ad-groups-search-terms.service';

export const SearchTermsPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const userUuid = localStorage.getItem('user_uuid');
    const adGroupId = localStorage.getItem("ad_group_id");
    const [searchTermsData, setSearchTermsData] = useState<any>([])

    const [originalSearchTermsData, setOriginalSearchTermsData] = useState<any>([]);

    const tableData = {
        page: "0",
        limit: "10",
        total: 14,
        rows: searchTermsData
    }

    const tablefooterData = [
        {
            searchTerm: `Total: ${searchTermsData?.length ? searchTermsData?.length : 0}`,
            clicks: "-",
            spend: "-",
            orders: "-",
            sales: "-",
            acos: "-",
        },
    ];

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        getSearchTerms();
    }, [])

    const getSearchTerms = () => {
        const preaparedData = {
            user_uuid: userUuid,
            ad_group_id: adGroupId,
        }
        GET_AD_GROUP_SEARCH_TERMS(preaparedData).then((res: any) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data?.keywords_data;
                    for (let i = 0; i < response?.length; i++) {
                        response[i].impressions = response[i]?.impressions ? response[i]?.impressions?.toLocaleString('en-IN') : "-";
                        response[i].clicks = response[i]?.clicks ? response[i]?.clicks?.toLocaleString('en-IN') : "-";
                        response[i].cost = response[i]?.cost ? getRupeeData(response[i]?.cost?.toFixed(2)) : "-";
                        response[i].purchases30d = response[i]?.purchases30d ? response[i]?.purchases30d : "-";
                        response[i].sales30d = response[i]?.sales30d ? getRupeeData(response[i]?.sales30d) : "-";
                        response[i].costPerClick = response[i]?.costPerClick ? getRupeeData(response[i]?.costPerClick?.toFixed(2)) : "-";
                        response[i].clickThroughRate = response[i]?.clickThroughRate ? getCTRData(response[i]?.clickThroughRate?.toFixed(2)) : "-";
                        response[i].acosClicks14d = typeof response[i]?.acosClicks14d === 'number' ? getPercentageData(response[i]?.acosClicks14d?.toFixed(2)) : "-";
                        response[i].roasClicks14d = response[i]?.roasClicks14d ? response[i]?.roasClicks14d.toFixed(2) : "-";
                        response[i].conversion_rate = typeof response[i]?.conversion_rate === "number" ? getPercentageData(response[i]?.conversion_rate?.toFixed(2)) : "-";
                        response[i].unitsSoldClicks1d = response[i]?.unitsSoldClicks1d ? response[i]?.unitsSoldClicks1d?.toFixed(2) : "-";
                    }
                    setSearchTermsData(response)
                    setOriginalSearchTermsData(response)
                    setLoading(false);
                }
            }
        });
    }

    const getCTRData = (data: any) => {
        if (data === "NaN") {
            return "-"
        } else if (data === undefined) {
            return "-";
        } else {
            return data + "%";
        }
    }

    const getPercentageData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return "0";
        } else if (data === undefined) {
            return "-";
        } else {
            return data + '%';
        }
    }

    const getRupeeData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return 0;
        } else if (data === undefined) {
            return "-";
        } else {
            const preparedData = parseFloat(data)?.toLocaleString('en-IN');
            return `${preparedData}`
        }
    }

    const handleCustomSearch = (data: any) => {
        if (data?.trim()?.length > 0) {
            for (let i = 0; i < originalSearchTermsData?.length; i++) {
                originalSearchTermsData[i].dummy_search_term = originalSearchTermsData[i].searchTerm?.toLowerCase();
            }
            const searchData = originalSearchTermsData?.filter((el: any) => el?.dummy_search_term?.includes(data?.toLowerCase()));
            setSearchTermsData(searchData);
        } else {
            getSearchTerms();
        }
    }
    const handleClickSearchWrong = (status: any) => {
        if (!status) {
            getSearchTerms();
        }
    }

    return (
        <div className="conaitner-fluid">
            <div className="row">
                <div className="col-12 mt-2 px-1">
                    <CustomFilter
                        customDateRangeText='Today'
                        searchTermFilter={true}
                        customPlaceholder='Find a search term'
                        handleCustomSearch={(e) => handleCustomSearch(e)}
                        handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
                        searchTermExportFilter={true}
                    />
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className='col-12 px-2'>
                        <CustomTable
                            tableData={tableData}
                            TableCols={AdGroupsSearchTermsDataGridCols}
                            tablefooterData={tablefooterData}
                            tableFooterpositionTop={true}
                            placementClass={true}
                            tableScroll={true}
                            tableWithoutFixedCoulmns={false}
                            tableHeaderBlur={true}
                            tableBottomText="Only search terms with clicks in the last 65 days are available."
                            noDataFoundMesageTitle='No data available'
                            noDataFoundMessageSubTitle="Please try adjusting your filters to see performance data" />
                    </div>
                }
            </div>
            <div className='footer-container'>
                <CustomFooter />
            </div>
        </div>
    )
}