import React, { useEffect, useState } from 'react'
import RupeeIcon from '../../../../assets/images/svg_icons/rupee.svg';
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../../../../components/custom-tooltip/tooltipPage';
import { CustomFooter } from '../../../../components/footer/custom-footer';
import { GET_USER_CAMPAIGNS, UPDATE_CAMPAIGN_SETTINGS } from '../../../../app/services/campaigns.service';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { GET_USER_PORTFOLIOS } from '../../../../app/services/portfolios.service';
import { useNavigate } from 'react-router-dom';
import { CampaignSettingsData } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
const currentDate = new Date().toISOString().split('T')[0];

interface Props {
    ProfileDataReducer?: any;
    profileData?: any;
    UserDataReducer?: any;
    userData?: any;
    CampaignDataReducer?: any;
    campaignData?: any;
    CampaignSettingsDataReducer?: any;
    campaignSettingsData?: any;
}

const CampaignSettings = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [campaignBiddingStrategy, setCampaignBiddingStrategy] = useState<any>('Dynamic bids - down only');
    const [campaignBiddingStrategyData, setCampaignBiddingStrategyData] = useState<any>("LEGACY_FOR_SALES")
    const productPageValue: any = '10';
    const topOfSearchValue: any = '20';
    const [campainDataByID, setCampaignDataByID] = useState<any>();
    const [portfoliosData, setPortfoliosData] = useState<any>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const [activeStatus, setActiveStatus] = useState<boolean>(false);
    const [campaignSettingsUpdateData, setCampaignSettingsUpdateData] = useState<any>({})
    const [formErrors, setFormErrors] = useState<any>({});

    const campaignSettingsData = [
        {
            campaingn_title: "Campain name"
        },
        {
            campaingn_title: "Campain ID"
        },
        {
            campaingn_title: "Portfolio"
        },
        {
            campaingn_title: "Type"
        },
        {
            campaingn_title: "Active / Paused"
        },
        {
            campaingn_title: "Status"
        },
        {
            campaingn_title: "Schedule"
        },
        {
            campaingn_title: "Daily Budget"
        },
        {
            campaingn_title: "Campaign targeting"
        },
        {
            campaingn_title: "Campaign bidding strategy"
        },
        {
            campaingn_title: "Adjust bids by placement"
        },
    ]

    useEffect(() => {
        const campaignID: any = localStorage.getItem("campaign_id");
        getUserCampaignById(campaignID);
        getUserPortfolios();
    }, [])

    const getUserCampaignById = (campaignID: any) => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            is_report_data: false,
        }
        setLoading(true);
        GET_USER_CAMPAIGNS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    if (response?.data?.data?.length > 0) {
                        setCampaignDataByID(response?.data?.data[0])
                        getPreparedData(response?.data?.data[0]?.dynamicBidding?.strategy);
                        setCampaignSettingsUpdateData(response?.data?.data[0])
                        const campaignStatus = response?.data?.data[0]?.state;
                        if (campaignStatus === "ENABLED") {
                            setActiveStatus(true);
                        } else {
                            setActiveStatus(false);
                        }
                        setLoading(false);
                    }
                }
            }
        });
    }

    const getPreparedData = (data: any) => {
        if (data === 'AUTO_FOR_SALES') {
            setCampaignBiddingStrategy("Dynamic bids - up and down");
        } else if (data === 'MANUAL') {
            setCampaignBiddingStrategy("Fixed bids");
        } else if (data === 'LEGACY_FOR_SALES') {
            setCampaignBiddingStrategy("Dynamic bids - down only");
        }
    }

    const getUserPortfolios = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            is_report_data: false
        }
        setLoading(true);
        GET_USER_PORTFOLIOS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data.data;
                    const portfoliosList = [];
                    for (let index = 0; index < response?.length; index++) {
                        const element = response[index];
                        portfoliosList.push({
                            title: element?.portfolio_name,
                            value: element?.portfolio_id
                        })
                    }
                    setPortfoliosData(portfoliosList)
                    setLoading(false);
                }
            }
        });
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    }

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    }

    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "Add budget rule") {
            return (
                <TooltipPage tooltipMainContent={'Add a rule to increase your daily budget to recommended events or performance metrics. This can help prevent your campaigns from going out of budget.'}
                    tooltipSubContent={'Learn more'} questionIconStatus={true} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Rule-based bidding input") {
            return (
                <TooltipPage tooltipMainContent={'This ROAS guardrail guidance is based on campaigns similar to yours. You can use this or choose your own value.'}
                    tooltipMainContentCaption={'Learn more'} questionIconStatus={true} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Adjust bids by placement") {
            return (
                <TooltipPage
                    tooltipHeader={`Adjust bids by placement`}
                    tooltipMainContent={'Apply different bids by placement by entering a percentage increase to your default bid. These adjustments will apply on all bids in the campaign. Based on your bidding strategy, your bids can change further.'}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        }
    }

    const handleActiveChanges = (e: any, item: any) => {
        const status = e.target.checked ? true : false;
        setActiveStatus(status);
    }

    const handleChangeCampaignBiddingStrategy = (e: any, option: string) => {
        e.stopPropagation();
        setCampaignBiddingStrategy(option)
        if (option === "Dynamic bids - down only") {
            setCampaignBiddingStrategyData("LEGACY_FOR_SALES");
        } else if (option === "Dynamic bids - up and down") {
            setCampaignBiddingStrategyData("AUTO_FOR_SALES");
        } else if (option === "Fixed bids") {
            setCampaignBiddingStrategyData("MANUAL");
        }
    }

    const onCampaignBidStrategyDynamicBidsDownOnlyLearnmore = (event: any, LearnMoreText: string) => {
        event.stopPropagation();
        if (LearnMoreText === "Dynamic bids - down only") {
            const redirectingUrl = "https://advertising.amazon.in/help/GCU2BUWJH2W3A8Z7";
            window.open(redirectingUrl, '_blank');
        } else if (LearnMoreText === "Dynamic bids - up and down") {
            const redirectingUrl = "https://advertising.amazon.in/help/GCU2BUWJH2W3A8Z7";
            window.open(redirectingUrl, "_blank")
        } else if (LearnMoreText === "Fixed bids") {
            const redirectingUrl = "https://advertising.amazon.in/help/GCU2BUWJH2W3A8Z7";
            window.open(redirectingUrl, "_blank")
        } else if (LearnMoreText === "Rule-based bidding") {
            const redirectingUrl = "https://advertising.amazon.in/help/GDLQ5D2BNFCAU3TW";
            window.open(redirectingUrl, "_blank");
        } else if (LearnMoreText === "Adjust bids by placement") {
            const redirectingUrl = "https://advertising.amazon.in/help/GYYZVM7LGSRYGWV5";
            window.open(redirectingUrl, "_blank");
        }
    }

    const onClickCancelCampaignSettings = () => {
        navigate("/dashboard/home/campaigns")
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setCampaignSettingsUpdateData((prevData: any) => ({
            ...prevData,
            [name]: value
        }))
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "campaign_name") {
            if (!value) {
                return "Please enter campaign name";
            }
        }

        if (name === "budget") {
            if (!value) {
                return "Please enter budget";
            } else if (parseInt(value) < 50) {
                return "Daily budget should be a minimum of 50.";
            }
        }

        if (name === "dynamicBidding_percentage") {
            if (!value) {
                return "Please enter top of search value";
            } else if (parseInt(value) < 0) {
                return "Please enter valid top of search value";
            }
        }
        if (name === "dynamicBidding_percentage_rest") {
            if (!value) {
                return "Please enter rest of search value";
            } else if (parseInt(value) < 0) {
                return "Please enter valid rest of search value";
            }
        }
        if (name === "dynamicBidding_percentage_product") {
            if (!value) {
                return "Please enter product pages value";
            } else if (parseInt(value) < 0) {
                return "Please enter valid product pages value";
            }
        }
    }

    const validateForm = () => {
        const errors: any = {};
        if (!campaignSettingsUpdateData?.campaign_name) {
            errors.campaign_name = "Please enter budget";
        }

        if (!campaignSettingsUpdateData?.budget) {
            errors.budget = "Please enter budget";
        }

        if (!campaignSettingsUpdateData?.start_date) {
            errors.start_date = "Please select start date";
        }

        if (campaignSettingsUpdateData?.dynamicBidding_percentage < 0) {
            errors.dynamicBidding_percentage = "Please enter top of search value";
        }
        if (campaignSettingsUpdateData?.dynamicBidding_percentage_rest < 0) {
            errors.dynamicBidding_percentage_rest = "Please enter rest of search value";
        }
        if (campaignSettingsUpdateData?.dynamicBidding_percentage_product < 0) {
            errors.dynamicBidding_percentage_product = "Please enter product pages value";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onClickSaveChanges = () => {
        const isValid = validateForm();
        if (isValid) {
            const preapredData = {
                user_uuid: userUuid,
                profile_id: profileID,
                campaign_id: campaignSettingsUpdateData?.campaign_id,
                portfolio_id: campaignSettingsUpdateData?.portfolio_id,
                campaign_name: campaignSettingsUpdateData?.campaign_name,
                budget: campaignSettingsUpdateData?.budget,
                state: campaignSettingsUpdateData?.state,
                targeting_type: "MANUAL",
                budget_type: "DAILY",
                start_date: campaignSettingsUpdateData?.start_date,
                end_date: campaignSettingsUpdateData?.end_date,
                dynamicBidding_placement: "PLACEMENT_TOP",
                dynamicBidding_placement_product: "PLACEMENT_PRODUCT_PAGE",
                dynamicBidding_placement_rest: "PLACEMENT_REST_OF_SEARCH",
                dynamicBidding_percentage: campaignSettingsUpdateData?.dynamicBidding_percentage || "0",
                dynamicBidding_percentage_rest: campaignSettingsUpdateData?.dynamicBidding_percentage_rest || "0",
                dynamicBidding_percentage_product: campaignSettingsUpdateData?.dynamicBidding_percentage_product || "0",
                dynamicBidding_strategy: campaignBiddingStrategyData ? campaignBiddingStrategyData : "LEGACY_FOR_SALES",
            }
            setLoading(true);
            UPDATE_CAMPAIGN_SETTINGS(preapredData).then((res) => {
                if (res.data.status === "error" || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const response = res.data.data;
                        toast.success(res.data.message);
                        setTimeout(() => {
                            getUserCampaignById(campaignID);
                        }, 1);
                        if (res.data.status === "success") {
                            if (props?.campaignSettingsData) {
                                const value = { status: true }
                                dispatch(props?.campaignSettingsData(value));
                            }
                        }
                        setLoading(false);
                    }
                }
            });
        }
    }

    return (
        <div className='container-fluid'>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    {campaignSettingsData?.map((campaignData: any, index: number) => (
                        <div key={index} className={`row mx-2 border-top border-start border-bottom  ${index == 0 ? "mt-3" : ""}`}>
                            <div className="col-4 py-2 default-budget-width border-right feedback-modal-body-background">
                                <div className='fs-13 fw-bold'>{campaignData?.campaingn_title}<span>{campaignData?.campaingn_title === "Campaign bidding strategy" ?
                                    <span className="badge text-bg-success ms-2 badge-custom-bg rounded-0  badge-new">New</span> : <></>}</span>
                                    {campaignData?.campaingn_title === "Adjust bids by placement" && (
                                        <Tooltip title={tooltipContent('Adjust bids by placement')} placement="top-start" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                            <span className='cursor-pointer ms-2'><svg viewBox="0 0 512 512" width="11" height="11" className="fas fa-info-circle" data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg></span>
                                        </Tooltip>
                                    )}
                                </div>
                                {campaignData?.campaingn_title === "Campaign bidding strategy" && (
                                    <div className='fs-13'>
                                        <div className='custom-explore-text-color logout'>
                                            <span><i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i></span>Bid optimisation</div>
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Adjust bids by placement" && (
                                    <div className='fs-13 fw-bold'>(replaces Bid+)</div>
                                )}
                            </div>
                            <div className="col-8 py-2">
                                {campaignData?.campaingn_title === "Campain name" && (
                                    <div>
                                        <input type="text" name="campaign_name" className="form-control fs-13 w-50 input border-dark" value={campaignSettingsUpdateData?.campaign_name}
                                            onChange={(e) => handleInput(e)} />
                                        {formErrors.campaign_name && (
                                            <div className='text-danger'>{formErrors.campaign_name}</div>
                                        )}
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Campain ID" && (
                                    <div className='fs-13'>
                                        <span>{campainDataByID?.campaign_id}</span>
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Portfolio" && (
                                    <>
                                        <div>
                                            <select name="portfolio_id" className='form-select w-50 fs-13 border-dark input' id="" value={campaignSettingsUpdateData?.portfolio_id}
                                                onChange={(e) => handleInput(e)}>
                                                <option value={""}>Select portfolio</option>
                                                {portfoliosData?.map((data: any, index: number) => (
                                                    <option className='' key={index} value={data?.value}>{data?.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                )}
                                {campaignData?.campaingn_title === "Type" && (
                                    <div className='fs-13'>
                                        {campainDataByID?.targeting_type === "MANUAL" && (
                                            <span>Sponsored Products</span>
                                        )}
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Active / Paused" && (
                                    <div className='fs-13'>
                                        <div className="form-check form-switch ps-1 d-flex">
                                            <label className="switch text-start mt-1">
                                                <input type="checkbox" className='' name="state" defaultChecked={activeStatus} onChange={(e) => handleActiveChanges(e, campainDataByID?.state)} />
                                                <div className="slider round">
                                                    <span className="on">
                                                        <svg viewBox="0 0 512 512" width="7" height="7" className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check toggle-check-mark-position" data-fa-i2svg="">
                                                            <path fill="white" d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                    <span className="off"></span>
                                                </div>
                                            </label>
                                            <div className='ms-2 my-1'> {activeStatus ? <span className='custom-explore-text-color fs-13'>Active</span> : <span className='text-dark fs-13'>Paused</span>}</div>
                                        </div>
                                        <div className='custom-explore-text-color logout cursor-pointer'>Archive this campaign</div>
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Status" && (
                                    <div className='fs-13'>
                                        {campainDataByID?.state === "PAUSED" && (
                                            <div className=''>Paused</div>
                                        )}
                                        {campainDataByID?.state === "ENABLED" && (
                                            <div className="fs-12 text-success">{campainDataByID?.start_date > currentDate ? "Scheduled" : "Delivering"}</div>
                                        )}
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Schedule" && (
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="row d-flex justify-content-between">
                                                <div className="col-12 w-50">
                                                    <label className='form-label fs-14'>Start Date</label>
                                                    <input type="date" name="start_date" min={currentDate ? currentDate : campaignSettingsUpdateData?.start_date} className="form-control input border-dark type fs-13" defaultValue={campaignSettingsUpdateData?.start_date}
                                                        onChange={(e) => handleInput(e)} />
                                                    {formErrors.start_date && (
                                                        <div className='text-danger'>{formErrors?.start_date}</div>
                                                    )}
                                                </div>
                                                <div className="col-12 w-50">
                                                    <label className='form-label fs-14'>End Date</label>
                                                    <input type="date" name="end_date" min={campaignSettingsUpdateData?.start_date} className="form-control input border-dark fs-13" defaultValue={campaignSettingsUpdateData?.end_date}
                                                        onChange={(e) => handleInput(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Daily Budget" && (
                                    <div>
                                        <div className='fs-13 border-bottom'>
                                            <div className="input-group mb-3 campaing-settings-daily-budget my-2">
                                                <span className="input-group-text input bg-white border-dark"><img src={RupeeIcon} className='' /></span>
                                                <input type="text" name="budget" className="form-control input border-dark border-start-0 ps-0 fs-13" defaultValue={`${campaignSettingsUpdateData?.budget ? campaignSettingsUpdateData?.budget : "0"}.00`}
                                                    onChange={(e) => handleInput(e)} />
                                            </div>
                                            {formErrors.budget && (
                                                <div className='text-danger'>{formErrors?.budget}</div>
                                            )}
                                        </div>
                                        <div className='fs-13'>
                                            <div className='custom-explore-text-color pt-1'>
                                                Add budget rule
                                                <Tooltip title={tooltipContent('Add budget rule')} placement="top" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                    <span className='cursor-pointer ms-2'><svg viewBox="0 0 512 512" width="11" height="11" className="fas fa-info-circle" data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg></span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Campaign targeting" && (
                                    <div className='fs-13'>
                                        {campainDataByID?.targeting_type === "MANUAL" && (
                                            <span>Manual Targeting</span>
                                        )}
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Campaign bidding strategy" && (
                                    <div className='fs-13'>
                                        <div className="d-flex form-check">
                                            <input
                                                type="radio"
                                                id="DynamicBidsDownOnly"
                                                name="Dynamic bids - down only"
                                                className="form-check-input mb-3 me-3"
                                                checked={campaignBiddingStrategy === "Dynamic bids - down only"}
                                                onChange={(e) => handleChangeCampaignBiddingStrategy(e, "Dynamic bids - down only")}
                                            />
                                            <label className="cursor-pointer" htmlFor='DynamicBidsDownOnly'>
                                                <div className="fs-13">Dynamic bids - down only</div>
                                                <div className="fs-13 budget-type-sub-text-color">We will lower your bids in real time when your ad may be less likely to convert to a sale.
                                                    <span className='back-to-help-text  learn-more-text-color logout cursor-pointer'
                                                        onClick={(e) => onCampaignBidStrategyDynamicBidsDownOnlyLearnmore(e, 'Dynamic bids - down only')}>
                                                        Learn more
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="d-flex form-check">
                                            <input
                                                type="radio"
                                                id="dynamicBidUpAndDown"
                                                name="Dynamic bids - up and down"
                                                className="form-check-input mb-3 me-3 campaign-settings-circle"
                                                checked={campaignBiddingStrategy === "Dynamic bids - up and down"}
                                                onChange={(e) => handleChangeCampaignBiddingStrategy(e, "Dynamic bids - up and down")}
                                            />
                                            <label className='cursor-pointer' htmlFor='dynamicBidUpAndDown'>
                                                <div className="fs-13">Dynamic bids - up and down</div>
                                                <div className="fs-13 budget-type-sub-text-color">We will raise your bids (by a maximum of 100%) in real time when your ad may be more likely to convert to a sale, and lower your bids when less likely to convert to a sale.
                                                    <span className='back-to-help-text learn-more-text-color logout cursor-pointer'
                                                        onClick={(e) => onCampaignBidStrategyDynamicBidsDownOnlyLearnmore(e, 'Dynamic bids - up and down')}
                                                    >Learn more</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="d-flex form-check">
                                            <input
                                                type="radio"
                                                name="Fixed bids"
                                                id="fixedBids"
                                                className="form-check-input mb-3 me-3"
                                                checked={campaignBiddingStrategy === "Fixed bids"}
                                                onChange={(e) => handleChangeCampaignBiddingStrategy(e, "Fixed bids")}
                                            />
                                            <label className='cursor-pointer' htmlFor='fixedBids'>
                                                <div className="fs-13">Fixed bids</div>
                                                <div className="fs-13 budget-type-sub-text-color">We will use your exact bid and any manual adjustments you set and will not change your bids based on likelihood of a sale.
                                                    <span className='back-to-help-text learn-more-text-color logout cursor-pointer'
                                                        onClick={(e) => onCampaignBidStrategyDynamicBidsDownOnlyLearnmore(e, 'Fixed bids')}
                                                    >Learn more</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                {campaignData?.campaingn_title === "Adjust bids by placement" && (
                                    <div>
                                        <div className="fs-13 budget-type-sub-text-color">In addition to your bidding strategy, you can increase bids by up to 900%.
                                            <span className='back-to-help-text  logout cursor-pointer'
                                                onClick={(e,) => onCampaignBidStrategyDynamicBidsDownOnlyLearnmore(e, 'Adjust bids by placement')}
                                            >Learn more</span>
                                        </div>
                                        <div className="d-flex my-2">
                                            <div className="fs-13 mt-2 me-4">Top of search (first page)</div>
                                            <div className="fs-13">
                                                <div className="fs-13 budget-increase">
                                                    <div className="input-group border-dark">
                                                        <input type="number" name="dynamicBidding_percentage" className="form-control input border-dark border-end-0 fs-13" placeholder=""
                                                            defaultValue={campaignSettingsUpdateData?.dynamicBidding_percentage ? campaignSettingsUpdateData?.dynamicBidding_percentage : "0"}
                                                            aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => handleInput(e)} />
                                                        <span className="input-group-text border-dark input " id="basic-addon1">%</span>
                                                    </div>
                                                </div>
                                                {formErrors.dynamicBidding_percentage && (
                                                    <div className="text-danger">{formErrors.dynamicBidding_percentage}</div>
                                                )}
                                                <div className="fs-12 add-products-text-color my-1">
                                                    Example: A ₹1.00 bid will be ₹1.{topOfSearchValue < 10 ? "0" + topOfSearchValue : topOfSearchValue} for this placement.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex my-2">
                                            <div className="fs-13 mt-2 me-4">Rest of search <span className="badge  text-bg-secondary ms-2 rounded-0  badge-new">New</span></div>
                                            <div className="fs-13  ms-md-4 ">
                                                <div className="fs-13 budget-increase">
                                                    <div className="input-group border-dark">
                                                        <input type="number" name="dynamicBidding_percentage_rest" className="form-control input border-dark border-end-0 fs-13" placeholder=""
                                                            defaultValue={campaignSettingsUpdateData?.dynamicBidding_percentage_rest ? campaignSettingsUpdateData?.dynamicBidding_percentage_rest : "0"} aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => handleInput(e)} />
                                                        <span className="input-group-text border-dark input" id="basic-addon1">%</span>
                                                    </div>
                                                </div>
                                                {formErrors.dynamicBidding_percentage_rest && (
                                                    <div className="text-danger">{formErrors.dynamicBidding_percentage_rest}</div>
                                                )}
                                                <div className="fs-12 add-products-text-color my-1">Example: A ₹1.00 bid will be ₹1.{productPageValue < 10 ? "0" + productPageValue : productPageValue} for this placement. </div>
                                            </div>
                                        </div>
                                        <div className="d-flex my-2">
                                            <div className="fs-13 mt-2 me-4">Product pages</div>
                                            <div className="fs-13 ms-md-5 ps-md-3">
                                                <div className="fs-13 budget-increase">
                                                    <div className="input-group border-dark">
                                                        <input type="number" name="dynamicBidding_percentage_product" className="form-control input border-dark border-end-0 fs-13" placeholder=""
                                                            defaultValue={campaignSettingsUpdateData?.dynamicBidding_percentage_product ? campaignSettingsUpdateData?.dynamicBidding_percentage_product : "0"} aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => handleInput(e)} />
                                                        <span className="input-group-text border-dark input" id="basic-addon1">%</span>
                                                    </div>
                                                </div>
                                                {formErrors.dynamicBidding_percentage_product && (
                                                    <div className="text-danger">{formErrors.dynamicBidding_percentage_product}</div>
                                                )}
                                                <div className="fs-12 add-products-text-color my-1">Example: A ₹1.00 bid will be ₹1.{productPageValue < 10 ? "0" + productPageValue : productPageValue} for this placement. </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                    }
                    <div className="d-flex justify-content-end mt-3">
                        <div className="mx-2">
                            <button className="custom-explore-text-color logout bg-transparent border-0" onClick={onClickCancelCampaignSettings} >
                                Cancel
                            </button>
                        </div>
                        <div className="">
                            <button className="request-call-button text-white fs-13" onClick={onClickSaveChanges} >
                                Save changes
                            </button>
                        </div>
                    </div>
                    <div className='pt-4'>
                        <CustomFooter />
                    </div>
                </div >
            }
        </div >
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        campaignSettingsData: (data: any) => dispatch(CampaignSettingsData(data)),
    };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(CampaignSettings);
export { connectedNavBar as CampaignSettings };
