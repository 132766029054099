import { CustomTableCol } from "../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const BudgetRulesDataGridCols: CustomTableCol[] = [
  {
    title: "Active",
    control: "status",
    sortable: false,
    canShowColumn: true,
    style: { width: "100px", left: 114, height: "40px" },
    isShowSwitch: true,
  },
  {
    title: "Rules",
    control: "name",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px", left: 214 },
    icon: info,
    infoIcon: true,
    tooltip: {
      header: "Campaigns",
      text:
        "A campaign is a set of ad groups under a common advertising budget. Ad groups are used to organise your products (ads) so that you can accomplish your desired business goals.",
    },
    isShowMenu: true,
  },
  {
    title: "Status",
    control: "status",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
  },
  {
    title: "Type",
    control: "type",
    sortable: true,
    canShowColumn: true,
    infoIcon: false,
    style: { width: "130px" },
  },
  {
    title: "Date range",
    control: "date_range",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
  },
  {
    title: "Recurrence",
    control: "recurrence",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    tooltip: {
      header: "Budget",
      text:
        "The total click or impression charges for a campaign. Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.",
    },
  },

  {
    title: "Increase",
    control: "increase",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    tooltip: {
      header: "Budget",
      text:
        "The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.",
    },
  },
];
