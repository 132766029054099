import React, { useState } from 'react'
import { Tooltip } from "@mui/material";

interface Props {
    ismodalShow?: any;
    modalStatus?: any;
}

export const GetHelpCardModal = (props: Props) => {
    const [showCardModal, setShowCardModal] = useState<boolean>(props?.ismodalShow);

    const onClickCancel = () => {
        if (props.modalStatus) {
            props.modalStatus(false)
            setShowCardModal(false);
        }
    }

    return (
        <>
            {showCardModal && (
                <div className={`modal fade ${showCardModal ? "show" : "false"}  ${showCardModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog  modal-dialog-centered explore-help-modal explore-get-help-modal " role="document">
                        <div className="modal-content get-help-create-ad-group-modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-17 fw-700">Get Help</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClickCancel()}></button>
                            </div>
                            <div className="modal-body explore-modal-body">
                                <div className='col-12 text-start'>
                                    <div className='fs-13 back-to-help-text logout'>Back to help</div>
                                    <h5 className='fs-21 fw-bold'>Create an ad group</h5>
                                </div>
                                <div className='col-12 text-start fs-12'>
                                    Ad groups are a way to organize, manage, and track performance of the products within your campaign. You can use ad groups to group your ads by brand, product, category, price range, or other classifications like theme or targeting strategy.
                                </div>
                                <div className='col-12 fs-12'>
                                    Products placed together in an ad group share the same bids and targets (keywords or products). We recommend creating ad groups with products that are in the same category. This helps optimize your bidding strategy, relevancy, and targeting.
                                </div>
                                <div className='col-12 fs-12'>
                                    The first ad group is created when you create a campaign. You can add more ad groups to the campaign after you've saved it.
                                </div>
                                <div className='col-12 text-start fs-13'>

                                </div>
                            </div>

                            <div className="modal-footer py-1">
                                <div className='col-12 text-start ps-2 fs-13'>
                                    Need more help?
                                </div>
                                <div className='col-12 text-start ps-2'>
                                    <button className='request-call-button text-white'>
                                        <span className='me-2'><svg viewBox="0 0 512 512" width="16" height="16" className="fas fa-phone" data-fa-i2svg=""><path fill="white" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg></span>
                                        Request a call</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
