import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration } from 'chart.js/auto';

interface Props {
    yOneScale?: any;
    chartsData?: (data: any) => void;
    chartData?: any;
    customCss?: any;
}

const ChartComponent = (props: Props) => {
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart>();

    useEffect(() => {

        const myChartRef: any = chartRef.current?.getContext('2d');
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        const chartConfig: ChartConfiguration = {
            type: 'line',
            data: {
                labels: props?.chartData?.map((row: any) => row.date),
                datasets: [
                    {
                        label: 'Sales',
                        data: props?.chartData?.map((row: any) => row.sales),
                        borderColor: 'rgba(255, 87, 51)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'Spend',
                        data: props?.chartData?.map((row: any) => row.cost),
                        borderColor: 'rgb(25, 25, 112)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'ROAS',
                        data: props?.chartData?.map((row: any) => row.roas),
                        borderColor: 'rgba(255,0,0)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'Impressions',
                        data: props?.chartData?.map((row: any) => row.impressions),
                        borderColor: 'rgba(0,0,205)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'Clicks',
                        data: props?.chartData?.map((row: any) => row.clicks),
                        borderColor: 'rgb(48, 25, 52)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'CTR',
                        data: props?.chartData?.map((row: any) => row.clickThroughRate),
                        borderColor: 'rgba(50, 12, 192, 10)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'ACOS',
                        data: props?.chartData?.map((row: any) => row.acos),
                        borderColor: 'rgba(255,0,255)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'CPC',
                        data: props?.chartData?.map((row: any) => row.costPerClick),
                        borderColor: 'rgba(5, 12, 120, 10)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        label: 'Orders',
                        data: props?.chartData?.map((row: any) => row.orders),
                        borderColor: 'rgba(0,255,0)',
                        borderWidth: 2,
                        fill: false,
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'category',
                        labels: props?.chartData?.map((row: any) => row.date),
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        grid: {
                            display: false
                        }
                    },
                } as any,
            },
        };
        if (props?.chartsData) {
            props?.chartsData(chartConfig)
        }

        chartInstanceRef.current = new Chart(myChartRef, chartConfig);
    }, [props?.chartData]);

    return (
        <div className={`line-chart ${props?.customCss ? "chart-background-blur" : ""}`} >
            <canvas ref={chartRef} className='chart_styles' width="1100" height="200" />
        </div>
    );
};

export default ChartComponent;