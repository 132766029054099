import React, { useEffect, useState } from "react";
import { DataTableCol } from "./types";
import { Tooltip } from "@mui/material";
import { TooltipPage } from "../custom-tooltip/tooltipPage";

interface Props {
  tableData?: any;
  TableCols?: DataTableCol[];
  noDataFoundMessage?: any;
  keywordTargetTwo?: boolean;
  paginationStatus?: boolean;
  noDataFoundTitle?: string;
  noDataFoundSubTitle?: string;
  addAllStatus?: (data: any) => void;
}

export const DataTable: React.FC<Props> = (props: Props) => {
  const [addAllStatus, setAddAllStatus] = useState<boolean>(false);
  const items = props?.tableData;

  const onCloseTooltip = (status: any) => {

  };

  const tooltipContent = (tooltipText: string) => {
    if (tooltipText === "keyword suggested tab Table IS|IR") {
      return (
        <TooltipPage
          keyWordIS={
            " is the percentage of impressions you are getting on this term relative to all the impressions it is generating."
          }
          keyWordIR={
            " is where your IS stands relative to other advertiser’s share of impressions for the same term."
          }
          keyWordTableText={
            "Use these to understand your impression coverage and help you decide how much to bid."
          }
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipText === "keyword suggested tab Table Macth Type") {
      return (
        <TooltipPage
          keyWordBroad={
            " Contains all the keywords in any order and includes plurals, variations and related keywords."
          }
          keyWordPhrase={" Contains the exact phrase or sequence of keywords."}
          keyWordExact={"Exactly matches the keyword or sequence of keywords"}
          tooltipLearnMoreText={"Learn More"}
          keyWordTableText={
            "Match types allow you to fine-tune which customer searches trigger your ads."
          }
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipText === "keyword suggested tab Table Sugg. bid") {
      return (
        <TooltipPage
          tooltipMainContent={
            "Suggested bids are calculated daily based on past bidding activity for ads similar to yours to predict bids that are more likely to win."
          }
          tooltipMainContentCaption={"Learn More"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    } else if (tooltipText === "keyword suggested tab Table bid") {
      return (
        <TooltipPage
          tooltipMainContent={
            "The amount you will pay for a click when a keyword triggers your ad."
          }
          tooltipMainContentCaption={"Learn More"}
          tooltipCloseStatus={onCloseTooltip}
        />
      );
    }
  };

  const onclickAddAll = () => {
    setAddAllStatus(!addAllStatus);
    if (props?.addAllStatus) {
      props?.addAllStatus(!addAllStatus);
    }
  }

  return (
    <div>
      <div className="container-fluid ps-1 px-0">
        <div className="row px-0">
          <div className="col-12 px-0">
            <div className="table-responsive">
              <table className="table table_new_ui mb-2">
                <thead className="text-start border-bottom">
                  <tr>
                    {props?.TableCols?.map((el, index) => (
                      <React.Fragment key={index}>
                        {el.canShowColumn && (
                          <th
                          >
                            {el.title !== "Add All" && (
                              <span className="my-2">{el.title}</span>
                            )}
                            {el.title === "Keyword" && (
                              <div className="keyword-below-text-color keyword-witdh">
                                IS | IR{" "}
                                <span className="fs-12">
                                  <Tooltip
                                    title={tooltipContent(
                                      "keyword suggested tab Table IS|IR"
                                    )}
                                    placement="top"
                                    arrow
                                    className="custom-tooltip-position cursor-pointer shadow"
                                  >
                                    <span className="ms-1">
                                      <svg
                                        viewBox="0 0 512 512"
                                        width="12"
                                        height="12"
                                        className="fas fa-info-circle"
                                        data-fa-i2svg=""
                                      >
                                        <path
                                          fill="#8290a4"
                                          d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                        ></path>
                                      </svg>
                                    </span>
                                  </Tooltip>
                                </span>
                              </div>
                            )}
                            {el.title === "Match Type" && (
                              <Tooltip
                                title={tooltipContent(
                                  "keyword suggested tab Table Macth Type"
                                )}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer shadow"
                              >
                                <span className="ms-1">
                                  <svg
                                    viewBox="0 0 512 512"
                                    width="12"
                                    height="12"
                                    className="fas fa-info-circle"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="#8290a4"
                                      d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                    ></path>
                                  </svg>
                                </span>
                              </Tooltip>
                            )}
                            {el.title === "Sugg. bid" && (
                              <Tooltip
                                title={tooltipContent(
                                  "keyword suggested tab Table Sugg. bid"
                                )}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer shadow"
                              >
                                <span className="ms-1">
                                  <svg
                                    viewBox="0 0 512 512"
                                    width="12"
                                    height="12"
                                    className="fas fa-info-circle"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="#8290a4"
                                      d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                    ></path>
                                  </svg>
                                </span>
                              </Tooltip>
                            )}
                            {el.title === "Sugg. bid" &&
                              props?.keywordTargetTwo && (
                                <div className="keyword-below-text-color cursor-pointer-not-allowed bg-transparent">
                                  Apply All
                                </div>
                              )}
                            {el.title === "Add All" && (
                              <span className="ms-1 fst-italic">
                                <span className={`ms-4 ps-2 ${items?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : "cursor-pointer-not-allowed keyword-below-text-color"}`} onClick={onclickAddAll}>Add All</span>
                              </span>
                            )}
                            {el.title === "Bid" && (
                              <Tooltip
                                title={tooltipContent(
                                  "keyword suggested tab Table bid"
                                )}
                                placement="top"
                                arrow
                                className="custom-tooltip-position cursor-pointer shadow"
                              >
                                <span className="ms-1">
                                  <svg
                                    viewBox="0 0 512 512"
                                    width="12"
                                    height="12"
                                    className="fas fa-info-circle"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="#8290a4"
                                      d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                    ></path>
                                  </svg>
                                </span>
                              </Tooltip>
                            )}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-start">
                </tbody>
              </table>
            </div>
            {items?.length === 0 && (
              <div className="mt-3 text-center">
                {props?.noDataFoundTitle && props?.noDataFoundSubTitle && (
                  <div className="d-flex flex-column justify-content-center my-5">
                    <div className="fs-13 fw-bold">{props?.noDataFoundTitle}</div>
                    <div className="fs-12 my-2">{props?.noDataFoundSubTitle}</div>
                  </div>
                )}
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};
