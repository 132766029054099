import React, { useEffect, useState } from 'react'
import { TooltipPage } from '../../../../../components/custom-tooltip/tooltipPage';
import ChartComponent from '../../../../../components/charts/line-chart';
import { Tooltip } from '@mui/material';
import PlusIcon from "../../../../../assets/images/svg_icons/plus.svg";
import { CustomFilter } from '../../../../../components/custom-filter/custom-filter';
import { CustomTable } from '../../../../../components/custom-table';
import { AdGroupsTargetingsDataGridCols } from './targetings-data-grid-cols';
import { CustomFooter } from '../../../../../components/footer/custom-footer';
import { KeywordTargetingCard } from '../../../../../components/ad-group-creation-components/keyword-targeting-card';
import { ProdcutTargetingCards } from '../../../../../components/campaign-creation-components/prodcut-targeting-cards';
import { CREATE_AD_GROUP_KEYWORD, GET_AD_GROUP_KEYWORDS, UPDATE_AD_GROUP_KEYWORD } from '../../../../../app/services/ad-groups-keywords.service';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { GET_KEYWORDS_SUMMARY_REPORT } from '../../../../../app/services/portfolio-summary-report.service';
import moment from 'moment';
import { AI_SUGGESTION_ACCEPT, AI_SUGGESTION_REJECT } from '../../../../../app/services/ai-keys-update.service';
import { AiActionsTabs } from '../../../../../components/ai-actions-tabs/ai-actions-tabs';
import { useNavigate } from 'react-router-dom';

interface Props {
    adProductsData?: any;
    CampaignDataReducer?: any;
    campaignData?: any;
    AdGroupAdsDataReducer?: any;
    adgroupsAdsData?: any;
}

const TargetingsPage = (props: Props) => {
    const [showChart, setShowChart] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [showTargetingKeywordBidModal, setShowTargetingKeywordBidModal] = useState<boolean>(false);
    const [targetingKeywordBidModalData, setTargetingKeywordBidModal] = useState<any>();
    const [modalBidInput, setModalBidInput] = useState<any>(
        {
            default_bid: ""
        }
    )
    const [formErrors, setFormErrors] = useState<any>({});
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [canShowAddMetric, setCanShowAddMetric] = useState<boolean>(false);
    const [addedkeywordsLength, setAddedKeywordsLength] = useState<any>();
    const [adGroupsTargetingAddKeywordModal, setAdGroupsTargetingAddKeywordModal] = useState<any>(false)
    const [adGroupsTargetingAddProductsTargetsModal, setAdGroupsTargetingAddProductsTargetsModal] = useState<any>(false)
    const customButtonTextStatus = true;
    const [adgroupKeywordsData, setAdgroupKeywordsData] = useState<any>([]);
    const [searchStr, setSearchStr] = useState<any>('');
    const [originalAdgroupKeywordsData, setOriginalAdgroupKeywordsData] = useState<any>()
    const [alternativeBidKeywordsData, setAlternativeBidKeywordsData] = useState<any>([]);
    const [selectedMetricIndex, setSelectedMetricIndex] = useState<number | null>(null);
    const [addedKeywordTargetingData, setAddedKeywordTargetingData] = useState<any>([]);
    const [adsProdcutAsinData, setAdsProdcutAsinData] = useState<any>([]);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID = localStorage.getItem("campaign_id");
    const adGroupId = localStorage.getItem("ad_group_id");
    const navigate = useNavigate();
    const [summaryReportsData, setSummaryReportsData] = useState<any>();
    const [showTargetingAiActionsModal, setShowTargetingAiActionsModal] = useState<boolean>(false);
    const [selectedTargetingData, setSelectedTargetingData] = useState<any>([]);
    const [showTargetingAiActionRejectConfirmationModal, setShowTargetingAiActionRejectConfirmationModal] = useState<boolean>(false);
    const [aiRejectFormErrors, setAiRejectFormErrors] = useState<any>({})
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const defaultEndDate = currentDate;

    const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
    const updatedData = JSON.parse(sessionUpdateDateRange);

    const [dateRangeData, setDateRangeData] = useState<any>({
        start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
    })

    const [grpahCardsData, setGrpahCardsData] = useState([
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #ff5733",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #373782",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #fe4a4a",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #4949da",
            key: 'impressions'
        },
    ]);

    const [metricCardsData, setMetricCardsData] = useState([
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #ff5733",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #373782",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #fe4a4a",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #4949da",
            key: 'impressions'
        },
        {
            card_title: "CTR",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #3e1bc2",
            key: 'clickThroughRate'
        },
        {
            card_title: "Clicks",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #301934",
            key: 'clicks'
        },
        {
            card_title: "CPC",
            amount: '',
            sub_title: "AVERAGE",
            border_color: "4px solid #1d2383",
            key: 'costPerClick'
        },
        {
            card_title: "Orders",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #53f453",
            key: 'orders'
        },
        {
            card_title: "ACOS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #ff1cff",
            key: 'acoss'
        },
    ])

    const tableData = {
        page: "0",
        limit: "10",
        total: 14,
        rows: adgroupKeywordsData
    }

    const tablefooterData = [
        {
            keyword_text: `Total: ${adgroupKeywordsData?.length ? adgroupKeywordsData?.length : 0}`,
            impressions: "-",
            topOfSearchImpressionShare: "-",
            clicks: "-",
            clickThroughRate: "-",
            cost: "-",
            costPerClick: "-",
            purchases30d: "-",
            sales30d: "-",
            acosClicks14d: "-",
            benchmark: "-",
            qualifier: "-",
            roasClicks14d: "-",
            conversion_rate: "-",
            unitsSoldClicks1d: "-"
        },
    ];

    useEffect(() => {
        const AdGroupsData = props?.adgroupsAdsData;
        let adsProductsAsinData = [];
        for (let i = 0; i < AdGroupsData?.length; i++) {
            adsProductsAsinData.push(AdGroupsData[i].asin)
        }
        setAdsProdcutAsinData([...adsProductsAsinData])
    }, [props?.adgroupsAdsData])

    useEffect(() => {
        const updatedDateRangeData: any = localStorage.getItem('updated_date_range')
        const dateRange: any = JSON.parse(updatedDateRangeData)
        if (dateRange) {
            const data = dateRangeData;
            data.start_date = dateRange?.start_date
            data.end_date = dateRange?.end_date
            setDateRangeData({ ...dateRangeData, start_date: dateRange?.start_date, end_date: dateRange?.end_date })
        }
    }, [])

    useEffect(() => {
        getAdgroupsKeywords(dateRangeData, searchStr);
        getPortfolioSummaryReport(dateRangeData);
    }, [])

    const selectedGraphMetric = (data: any, index: number) => {
        setSelectedMetricIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const isMetricSelected = (metric: any) => {
        return (
            grpahCardsData.some((selectedMetric: any) => selectedMetric.card_title === metric.card_title)
        );
    };

    const getAdgroupsKeywords = (dateRangeData: any, search: string) => {
        const sessionDateRange: any = localStorage?.getItem('updated_date_range')
        const dateRange = JSON.parse(sessionDateRange)
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            ad_group_id: adGroupId,
            start_date: dateRange?.start_date ? dateRange?.start_date : dateRangeData?.start_date,
            end_date: dateRange?.end_date ? dateRange?.end_date : dateRangeData?.end_date,
        }
        setLoading(true);
        GET_AD_GROUP_KEYWORDS(preparedData).then((res) => {
            if (res?.data?.status === "error") {
                toast.error(res?.data?.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data;
                    const camapignData: any = localStorage.getItem('campaign_data');
                    const sessionCamapignData = JSON.parse(camapignData);
                    response?.data?.forEach((element: any) => {
                        element.campaign_status = props?.campaignData?.state ? props?.campaignData?.state : sessionCamapignData?.state;
                        element.campaign_start_date = props?.campaignData?.start_date ? props?.campaignData?.start_date : sessionCamapignData?.start_date;
                        element.ai_suggestion.suggestion = element.ai_suggestion.suggestion ? element.ai_suggestion.suggestion.replace('budget', 'bid') : '';

                    });
                    for (let i = 0; i < response?.data?.length; i++) {
                        const metricsData: any = response.data[i]?.metricsData || {};
                        response.data[i].impressions = metricsData?.impressions ? metricsData?.impressions?.toLocaleString('en-IN') : '-';
                        response.data[i].clicks = metricsData?.clicks ? metricsData?.clicks?.toLocaleString('en-IN') : '-';
                        response.data[i].clickThroughRate = metricsData?.clickThroughRate ? getCTRData(parseFloat(response?.data[i]?.metricsData?.clickThroughRate)?.toFixed(2)) : '-';
                        response.data[i].cost = metricsData?.cost ? (getRupeeData(metricsData?.cost)) : '-';
                        response.data[i].costPerClick = metricsData?.costPerClick ? getRupeeData(metricsData?.costPerClick) : '-';
                        response.data[i].purchases30d = metricsData?.purchases30d ? metricsData?.purchases30d?.toLocaleString('en-IN') : '-';
                        response.data[i].sales30d = metricsData?.sales30d ? (getRupeeData(metricsData?.sales30d)) : '-';
                        response.data[i].acosClicks14d = metricsData?.acosClicks14d ? (getPercentageData(metricsData?.acosClicks14d)) : '-';
                        response.data[i].roasClicks14d = isNaN(parseFloat(response.data[i]?.metricsData?.roasClicks14d))
                            ? "-"
                            : parseFloat(response.data[i]?.metricsData?.roasClicks14d)?.toFixed(2);
                        response.data[i].topOfSearchImpressionShare = metricsData?.topOfSearchImpressionShare ? (getPercentageData(metricsData?.topOfSearchImpressionShare)) : "-";
                        response.data[i].conversion_rate = metricsData?.conversion_rate ? (getPercentageData(metricsData?.conversion_rate)) : "-";
                        response.data[i].unitsSoldClicks1d = metricsData?.unitsSoldClicks1d ? metricsData?.unitsSoldClicks1d : "-";
                        response.data[i].benchmark = response.data[i].benchmark ? response.data[i].benchmark : "-";
                        response.data[i].qualifier = response.data[i].qualifier ? response.data[i].qualifier : "-";
                    }
                    setOriginalAdgroupKeywordsData(response?.data)

                    if (search) {
                        for (let i = 0; i < originalAdgroupKeywordsData?.length; i++) {
                            originalAdgroupKeywordsData[i].dummy_keyword_text = originalAdgroupKeywordsData[i].keyword_text.toLowerCase();
                        }
                        const searchData = originalAdgroupKeywordsData?.filter((el: any) => el?.dummy_keyword_text?.includes(search.toLowerCase()));
                        setAdgroupKeywordsData(searchData);
                    } else {
                        setAdgroupKeywordsData(response?.data)
                    }
                    setLoading(false);
                }
            }
        });
    }

    const handleKeywordBidInputChnage = (e: any) => {
        const { name, value } = e.target
        setModalBidInput((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateBidForm = () => {
        const errors: any = {};
        if (!modalBidInput?.default_bid) {
            errors.default_bid = "Please enter bid value"
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }
    const getPercentageData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data == 0) {
            return "0";
        } else if (data == undefined) {
            return "-";
        } else {
            return data?.toFixed(2) + '%';
        }
    }

    const getCTRData = (data: any) => {
        if (data === "NaN") {
            return "-"
        } else if (data == undefined) {
            return "-";
        } else {
            return data + "%";
        }
    }

    const getRupeeData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data == 0) {
            return 0;
        } else if (data === undefined) {
            return "-";
        } else {
            const preparedData = parseFloat(data?.toFixed(2))?.toLocaleString('en-IN');
            return `${preparedData}`
        }
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "default_bid") {
            if (!value) {
                return "Please enter bid value";
            } else if (parseInt(value) <= 0) {
                return "Bid value should be minimum 1.";
            }
        }
        return "";
    }

    const onClickUpdateKeywordBid = () => {
        const isValid: any = validateBidForm();
        if (isValid) {
            const preparedData: any = {
                user_uuid: userUuid,
                profile_id: profileID,
                campaign_id: campaignID,
                keywords: [
                    {
                        keyword_state: "ENABLED",
                        bid: modalBidInput?.default_bid,
                        keyword_id: targetingKeywordBidModalData?.keyword_id
                    }
                ]
            }
            setLoading(true);
            UPDATE_AD_GROUP_KEYWORD(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        toast.success(res.data.message);
                        setLoading(false);
                        setShowTargetingKeywordBidModal(false);
                        getAdgroupsKeywords(dateRangeData, searchStr);
                    }
                }
            });
        }
    }

    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "Spend") {
            return (
                <TooltipPage
                    tooltipHeader='Spend'
                    tooltipMainContent={`The total click charges for a product ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Sales") {
            return (
                <TooltipPage
                    tooltipHeader='Sales'
                    tooltipMainContent={` Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
                  Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 14 days.
                  Sponsored Brands: Sales from advertised products sold by Amazon within 14 days. Where eligible, this also includes sales from all products within your brand sold by Amazon and third-party sellers.
                  It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
                  Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CPC") {
            return (
                <TooltipPage
                    tooltipHeader='Cost-per-click (CPC)'
                    tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CTR") {
            return (
                <TooltipPage
                    tooltipHeader='Click-through rate (CTR)'
                    tooltipMainContent={`The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Impressions") {
            return (
                <TooltipPage
                    tooltipHeader='Impressions'
                    tooltipMainContent={`The number of times ads were displayed`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "ROAS") {
            return (
                <TooltipPage
                    tooltipHeader="Return on ad spend (ROAS)"
                    tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
                    tooltipSubContent={`Learn about return on ad spend (ROAS)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Ad Group ACOS") {
            return (
                <TooltipPage
                    tooltipHeader="Advertising cost of sales (ACOS)"
                    tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
                    tooltipSubContent={`Learn about return on ad spend (ROAS)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        }
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    }

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    }

    const onClickAddMetric = () => {
        setCanShowAddMetric(!canShowAddMetric);
    }

    const onSelectMetric = (data: any, index: any) => {
        const preparedData = grpahCardsData;
        preparedData.push(data);
        setGrpahCardsData([...preparedData]);
        setCanShowAddMetric(false);
    }

    function onRemoveMetric(data: any, index: number) {
        const preparedData = grpahCardsData;
        preparedData.splice(index, 1);
        setGrpahCardsData([...preparedData]);
    }

    const getshowChatStatus = (status: any) => {
        setShowChart(status);
    }

    const onCreateButtonNavigation = (data: string) => {
        const navigationData: any = data;
        if (navigationData?.buttonText === "Add keywords") {
            setAdGroupsTargetingAddKeywordModal(true);
        } else if (navigationData?.buttonText === "Add product targets") {
            setAdGroupsTargetingAddProductsTargetsModal(true);
        }
    }

    const onActions = (data: any) => {
        if (data?.type === "edit") {
            setShowTargetingKeywordBidModal(true);
            setTargetingKeywordBidModal(data?.data)
        } else if (data?.type === "view") {
            setSelectedTargetingData(data?.data);
            setShowTargetingAiActionsModal(true);
        } else if (data?.type === "AI Accept") {
            onAiActionAccept(data?.data, data?.type);
        } else if (data?.type === "AI Reject") {
            setSelectedTargetingData(data?.data);
            setShowTargetingAiActionsModal(true);
        }
    }

    const onClickAdGroupAdAddKeywords = () => {
        let addedAlternateKeywords = [];
        if (alternativeBidKeywordsData?.length > 0) {
            if (alternativeBidKeywordsData?.length > 0) {
                for (let index = 0; index < alternativeBidKeywordsData?.length; index++) {
                    const element = alternativeBidKeywordsData[index];
                    addedAlternateKeywords.push(
                        {
                            keyword_matchType: element?.bidData?.matchType.toUpperCase(),
                            keyword_state: 'ENABLED',
                            bid: element?.bidData?.bid.toString(),
                            keywordText: element?.suggestionData
                        }
                    )
                }
            }
        }
        let addedKeywords = [];
        if (addedKeywordTargetingData?.length > 0) {
            if (addedKeywordTargetingData?.length > 0) {
                for (let index = 0; index < addedKeywordTargetingData?.length; index++) {
                    const element = addedKeywordTargetingData[index];
                    addedKeywords.push(
                        {
                            keyword_matchType: element?.bidData?.matchType.toUpperCase(),
                            keyword_state: 'ENABLED',
                            bid_type: element?.bidData?.bid_type,
                            bid_input_update_value: element?.bid_input_update_value,
                            bid: (element?.bidData?.bid / 100).toString(),
                            keywordText: element?.suggestionData
                        }
                    )
                }

                const data = addedKeywords;
                for (let i = 0; i < data?.length; i++) {
                    if (data[i].bid_type === "Custom bid") {
                        data[i].bid = data[i].bid_input_update_value
                    } else if (data[i].bid_type === "Default bid") {
                        data[i].bid = data[i].bid_input_update_value
                    } else {
                        data[i].bid = data[i].bid
                    }
                }
            }
        }
        if (addedKeywords?.length > 0 || addedAlternateKeywords?.length > 0) {
            const preparedData = {
                user_uuid: userUuid,
                profile_id: profileID,
                campaign_id: campaignID,
                ad_group_id: adGroupId,
                keywords: addedAlternateKeywords?.length > 0 ? addedAlternateKeywords : addedKeywords
            }

            setModalLoading(true);
            CREATE_AD_GROUP_KEYWORD(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                    setModalLoading(false);
                } else {
                    if (res) {
                        getAdgroupsKeywords(dateRangeData, searchStr);
                        setAdGroupsTargetingAddKeywordModal(false)
                        setModalLoading(false);
                        setAddedKeywordTargetingData([]);
                        setAlternativeBidKeywordsData([]);
                        setLoading(false);
                    }
                }
            });
        } else {
            toast.error("Please add atleast one keyword", { autoClose: 1000 })
        }
    }

    const getAlternativeBidKeywordsData = (data: any) => {
        setAlternativeBidKeywordsData(data);
    }

    const getAddedKeywordData = (data: any) => {
        setAddedKeywordTargetingData(data)
    }

    const handleCustomSearch = (data: any) => {
        setSearchStr(data)
        if (data?.trim()?.length > 0) {
            for (let i = 0; i < originalAdgroupKeywordsData?.length; i++) {
                originalAdgroupKeywordsData[i].dummy_keyword_text = originalAdgroupKeywordsData[i].keyword_text.toLowerCase();
            }
            const searchData = originalAdgroupKeywordsData?.filter((el: any) => el?.dummy_keyword_text?.includes(data.toLowerCase()));
            setAdgroupKeywordsData(searchData);
        } else {
            getAdgroupsKeywords(dateRangeData, '');
        }
    }

    const handleClickSearchWrong = (status: any) => {
        setSearchStr('')
        if (!status) {
            getAdgroupsKeywords(dateRangeData, '');
        }
    }

    const handleCustomFilter = (filterData: any) => {
        if (filterData === "enabled") {
            const searchData = originalAdgroupKeywordsData?.filter((el: any) => el?.state === "ENABLED");
            setAdgroupKeywordsData(searchData);
        } else if (filterData === "paused") {
            const searchData = originalAdgroupKeywordsData?.filter((el: any) => el?.state === "PAUSED");
            setAdgroupKeywordsData(searchData);
        } else if (filterData === "archived") {
            const searchData = originalAdgroupKeywordsData?.filter((el: any) => el?.state === "ARCHIVED");
            setAdgroupKeywordsData(searchData);
        } else if (filterData === "all") {
            getAdgroupsKeywords(dateRangeData, searchStr);
        } else {
            getAdgroupsKeywords(dateRangeData, searchStr);
        }
    }

    const handleExport = (status: any) => {
        if (status) {
            setExportLoading(true);
            setTimeout(() => {
                setExportLoading(false);
            }, 500);
        }
    }

    const getPortfolioSummaryReport = (data: any) => {
        const sessionDateRange: any = localStorage?.getItem('updated_date_range')
        const dateRange = JSON.parse(sessionDateRange)
        const preparedData = {
            profile_id: profileID,
            user_uuid: userUuid,
            campaign_id: campaignID,
            adgroup_id: adGroupId,
            start_date: dateRange?.start_date ? dateRange?.start_date : data?.start_date,
            end_date: dateRange?.end_date ? dateRange?.end_date : data?.end_date
        }
        GET_KEYWORDS_SUMMARY_REPORT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                setLoading(false);
            } else {
                if (res) {
                    setSummaryReportsData(res?.data?.data[0]?.dateWiseData);
                    const totalDataObj = metricCardsData;
                    const grpahCardsDataobj = grpahCardsData;
                    const totalData = res.data.data[0]?.totalData;
                    if (totalData !== undefined) {
                        Object.keys(totalData).map(function (key) {
                            totalDataObj.forEach((element: any) => {
                                if (element.key === key) {
                                    element.amount = totalData[key]
                                    if (key === 'cost' || key === 'sales' || key === 'sales') {
                                        element.amount = `₹${getRupeeDataNavbrd(totalData[key])}`;
                                    } else if (key === "impressions") {
                                        element.amount = `${(totalData[key])?.toLocaleString('en-IN')}`
                                    } else {
                                        element.amount = getPercentageDataNavbrd(totalData[key]);
                                    }
                                }
                            });
                        });
                        Object.keys(totalData).map(function (key) {
                            grpahCardsDataobj.forEach((element: any) => {
                                if (element.key === key) {
                                    element.amount = totalData[key]
                                    if (key === 'cost' || key === 'sales' || key === 'sales') {
                                        element.amount = `₹${getRupeeDataNavbrd(totalData[key])}`;
                                    } else if (key === "impressions") {
                                        element.amount = `${(totalData[key])?.toLocaleString('en-IN')}`
                                    } else {
                                        element.amount = getPercentageDataNavbrd(totalData[key]);
                                    }
                                }
                            });
                        });
                    }
                    setMetricCardsData([...totalDataObj])
                    setGrpahCardsData([...grpahCardsDataobj])
                }
            }
        })
    }

    const getRupeeDataNavbrd = (data: any) => {
        if (data === "-") {
            return "-";
        } else {
            const preparedData = parseFloat(data?.toFixed(2)).toLocaleString('en-IN');
            return `${preparedData}`
        }
    }

    const getPercentageDataNavbrd = (data: any) => {
        if (data === "-") {
            return "-";
        } else {
            return data?.toFixed(2);
        }
    }

    const getAdGroupTargetingDateRangeFilterData = (data: any) => {
        getAdgroupsKeywords(data, searchStr);
        getPortfolioSummaryReport(data);
    }

    const getTargetingSuggestion = (data: any, type: any) => {
        if (type === "AI Accept") {
            return data?.ai_suggestion?.suggestion ? data?.ai_suggestion?.suggestion : ""
        } else if (type === "Manual Accept") {
            if (data?.action_type === "increase") {
                return `${data?.action_type} budget by ${data?.action_value}%`
            } else if (data?.action_type === "reduce") {
                return `${data?.action_type} budget by ${data?.action_value}%`
            }
        }
    }

    const onAiActionAccept = (aiAcceptData: any, acceptType: any) => {
        const preparedData = {
            user_uuid: userUuid,
            keyword_id: aiAcceptData?.targeting_data?.keyword_id || aiAcceptData?.keyword_id,
            type: "KEYWORDS",
            profile_id: acceptType === "Manual Accept" ? aiAcceptData?.targeting_data?.profile_id?.toString() : profileID?.toString(),
            campaign_id: acceptType === "Manual Accept" ? aiAcceptData?.targeting_data?.campaign_id : selectedTargetingData?.campaign_id,
            suggestion: getTargetingSuggestion(aiAcceptData, acceptType),
            uuid: aiAcceptData?.ai_suggestion?.uuid
        }
        setLoading(true)
        AI_SUGGESTION_ACCEPT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    setShowTargetingAiActionsModal(false);
                    getAdgroupsKeywords(dateRangeData, searchStr);
                    getPortfolioSummaryReport(dateRangeData);
                    setLoading(false);
                }
            }
        });
    }

    const handleRejectComments = (e: any) => {
        const { name, value } = e.target;
        setAiRejectFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateAiSpecificFeild(name, value),
        }));
    }

    const validateAiSpecificFeild = (name: any, value: any) => {
        if (name === "rejection_message") {
            if (!value) {
                return "Please enter the comments"
            }
        }
    }

    const onAiActionRejectSubmit = (data: any) => {
        const preparedData = {
            user_uuid: userUuid,
            uuid: data?.uuid ? data?.uuid : "",
            rejection_message: data?.rejection_message
        }
        setLoading(true)
        AI_SUGGESTION_REJECT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    setShowTargetingAiActionsModal(false);
                    setShowTargetingAiActionRejectConfirmationModal(false);
                    getAdgroupsKeywords(dateRangeData, searchStr);
                    getPortfolioSummaryReport(dateRangeData);
                    setLoading(false);
                }
            }
        });
    }

    const onTabActions = (data: any) => {
        if (data?.type === "AI Accept") {
            onAiActionAccept(data?.data, data?.type)
        } else if (data?.type === "Manual Accept") {
            data.data.targeting_data = data?.campaignInfo
            onAiActionAccept(data?.data, data?.type)
        } else if (data?.type === "navigate") {
            navigate("/dashboard/configuration/groups-info/targets")
        }
    }

    const getAiRejectSubmit = (data: any) => {
        onAiActionRejectSubmit(data)
    }


    const getAiRejectCancel = (data: any) => {
        setShowTargetingAiActionsModal(false);
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mt-3 px-4">
                    {showChart && (
                        <div className='row px-3'>
                            <div className="col-12 d-flex flex-wrap justify-content-between col_padding">
                                {grpahCardsData?.map((data: any, index: number) => (
                                    <div key={index} className={`card col-12 col-md-6 col_md_6 col-lg-2 col_lg_2 col_xl_2 col-xl-2 my-2 my-lg-0 text-bg-white rounded-0 ${index === selectedMetricIndex ? 'selected-"metric"-card' : ''}`} style={{ borderTop: `${index === selectedMetricIndex ? data?.border_color : ""}` }} onClick={() => selectedGraphMetric(data, index)}>
                                        <div className='card-header bg-white border-0 add_metrics-bg cursor-pointer'>
                                            <div className="d-flex justify-content-between">
                                                <div className="fs-13">{data?.card_title}
                                                    <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                        <span className='ms-1 fs-15'>
                                                            <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 cross-icon cursor-pointer" onClick={() => onRemoveMetric(data, index)}>
                                                    <i className="bi bi-x "></i>
                                                </div>
                                            </div>
                                            <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>}<span className='fs-11'>{data?.sub_title}</span></div>
                                        </div>
                                    </div>
                                ))}
                                {grpahCardsData?.length < 5 && <div className={`${canShowAddMetric ? 'col-12 col-md-6 col-lg-2 col-xl-2 add-metric-border bg-white cursor-pointer' : 'card  py-3 col-12 col-md-6 col-lg-2 col-xl-2 border-1 border-dark card-border-style bg-white  rounded-0 cursor-pointer'}`} onClick={onClickAddMetric}>
                                    <div className='card-header text-center bg-white border-0'>
                                        <span><img src={PlusIcon} /></span>
                                        add metric
                                        <div className={`${canShowAddMetric ? 'one' : ''}`}></div>
                                        <div className={`${canShowAddMetric ? 'two' : ''}`}></div>
                                    </div>
                                </div>}
                            </div>
                            {canShowAddMetric && <div className='add-metric-list col-md-12 my-3'>
                                <div className='row add-metric-info '>
                                    {metricCardsData?.map((data: any, index: number) => (
                                        <div key={index} className={`card col-12 col-md-4 col-lg-3 metrics-cols-extra-large metrics-cols-large my-2 col-xl-2 add_metrics-bg text-bg-white rounded-0 cursor-pointer col_margin ${isMetricSelected(data) ? "disabled-metric cursor-pointer-not-allowed" : ""}`} onClick={() => onSelectMetric(data, index)}>
                                            <div className='card-header bg-white border-0 add_metrics-bg'>
                                                <div className="d-flex justify-content-between">
                                                    <div className="fs-13">{data?.card_title}
                                                        <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                            <span className='ms-1 fs-15'>
                                                                <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="fs-13 cross-icon">
                                                        <i className="bi bi-x "></i>
                                                    </div>
                                                </div>
                                                <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>}&nbsp; <span className='fs-11'>{data?.sub_title}</span></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                            {!canShowAddMetric && summaryReportsData &&
                                <ChartComponent chartData={summaryReportsData} />
                            }
                            {!summaryReportsData &&
                                <>
                                    <ChartComponent chartData={summaryReportsData} customCss={true} />
                                    <div className='d-flex align-items-center position-relative' style={{ top: "-200px" }}>
                                        <div className='text-center mx-auto fs-4 fw-bold'>No keywords are currently active</div>
                                    </div>
                                </>
                            }
                        </div>
                    )}
                </div>
                <div className="col-12 px-4">
                    <CustomFilter showChartStatus={getshowChatStatus}
                        customCreateButtonText={customButtonTextStatus ? 'Add keywords' : "Add product targets"}
                        customPlaceholder='Find a keyword'
                        customDateRangeText='Today'
                        exportData={"Targetings"}
                        exportTableData={tableData}
                        onClickButton={(e) => onCreateButtonNavigation(e)}
                        handleCustomSearch={(e) => handleCustomSearch(e)}
                        handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
                        handleCustomFilter={(e) => handleCustomFilter(e)}
                        showAdgroupTargetingDateRangeFilter={true}
                        campaignExport={(e) => handleExport(e)}
                        exportLoadingStatus={exportLoading}
                        dateRangeFilterData={(e) => getAdGroupTargetingDateRangeFilterData(e)}
                        dateRangeData={dateRangeData}
                    />
                </div>
                <div className='col-12 ad-groups-table-container'>
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <CustomTable
                            tableData={tableData}
                            TableCols={AdGroupsTargetingsDataGridCols}
                            noDataFoundMesageTitle=''
                            tablefooterData={tablefooterData}
                            tableLoading={!loading}
                            onActions={(e) => onActions(e)}
                            isAPIPageNation={false}
                            pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                        />
                    }
                    {showTargetingAiActionsModal && (
                        <div className={`modal modal-custom fade ${showTargetingAiActionsModal ? "show" : "false"}  ${showTargetingAiActionsModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                            <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                                <div className="modal-content mx-auto ">
                                    <div className="modal-header border-bottom-0">
                                        <h5 className="modal-title fs-17 fw-400">AI actions</h5>
                                        <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowTargetingAiActionsModal(false)}></button>
                                    </div>
                                    {loading &&
                                        <div className="text-center p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                    {!loading &&
                                        <div className='modal-body p-3 border-bottom'>
                                            <AiActionsTabs camapaignInfo={selectedTargetingData} targeting={true}
                                                onTabActions={(e) => onTabActions(e)}
                                                aiActionRejectSubmit={(e) => getAiRejectSubmit(e)}
                                                aiActionRejectCancel={(e) => getAiRejectCancel(e)}
                                                isShowReject={true} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    {showTargetingAiActionRejectConfirmationModal && (
                        <div className={`modal modal-custom fade ${showTargetingAiActionRejectConfirmationModal ? "show" : "false"}  ${showTargetingAiActionRejectConfirmationModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                            <div className="modal-dialog modal modal-dialog-centered " role="document">
                                <div className="modal-content mx-auto ">
                                    <div className="modal-header border-bottom-0">
                                        <h5 className="modal-title fs-17 fw-400">AI Reject</h5>
                                        <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowTargetingAiActionRejectConfirmationModal(false)}></button>
                                    </div>
                                    <div className='modal-body p-3 border-bottom'>
                                        <div className='custom-explore-text-color text-start'><span className='fw-bold text-dark'>Keyword name :</span> {selectedTargetingData?.keyword_text}</div>
                                        <div>
                                            <label className="form-label d-flex jsutify-content-start">Comments</label>
                                            <textarea rows={3} className="form-control" name="rejection_message" defaultValue={""} placeholder="Please enter comments" onChange={(e: any) => handleRejectComments(e)}></textarea>
                                            {aiRejectFormErrors?.rejection_message && (<div className="text-danger">{aiRejectFormErrors?.rejection_message}</div>)}
                                        </div>
                                        <div className="d-flex justify-content-center py-3">
                                            <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 mx-3" onClick={onAiActionRejectSubmit}>Submit</button>
                                            <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-5`} onClick={() => setShowTargetingAiActionRejectConfirmationModal(false)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`pb-3 ${!showChart ? "fixed-bottom " : ""}`}>
                    <CustomFooter />
                </div>
            </div>
            {adGroupsTargetingAddKeywordModal && (
                <div className={`modal modal-custom fade ${adGroupsTargetingAddKeywordModal ? "show" : "false"}  ${adGroupsTargetingAddKeywordModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered ad-groups-targeting-modal" role="document">
                        <div className="modal-content mx-auto">
                            <div className="modal-header">
                                <h5 className="modal-title portfolio-title fs-17">Add keywords</h5>
                                <button type="button" className="btn-close fs-12" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAdGroupsTargetingAddKeywordModal(false)}></button>
                            </div>
                            {modalLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!modalLoading &&
                                <div className='modal-body negative-keyword-body py-0'>
                                    <KeywordTargetingCard adGroupkeywordTargeting={true} altenativeBidKeywordsData={(e) => getAlternativeBidKeywordsData(e)}
                                        addedKeywordData={(e) => getAddedKeywordData(e)}
                                        adsProductAsinData={adsProdcutAsinData}
                                        suggestedKeywordsScroll={true} />
                                </div>
                            }
                            <div className="modal-footer">
                                <button type="button" className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setAdGroupsTargetingAddKeywordModal(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className={`rounded-pill border-0 fs-13  ${addedkeywordsLength > 0 || alternativeBidKeywordsData?.length > 0 || adsProdcutAsinData?.length > 0 ? "request-call-button text-white cursor-pointer" : "custom-cancel-btn create-portfolio-btn custom-button-background cursor-pointer-not-allowed"}`}
                                    onClick={onClickAdGroupAdAddKeywords}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showTargetingKeywordBidModal && (
                <div className={`modal modal-custom fade ${showTargetingKeywordBidModal ? "show" : "false"}  ${showTargetingKeywordBidModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-md modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto">
                            <div className="modal-header">
                                <h5 className="modal-title portfolio-title fs-17">Added keyword details</h5>
                                <button type="button" className="btn-close fs-12" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowTargetingKeywordBidModal(false)}></button>
                            </div>
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!loading && (
                                <div className='modal-body  py-5 px-5'>
                                    <div className='fs-13 d-flex justify-content-between px-3'>
                                        <div className='mt-1'>{targetingKeywordBidModalData?.keyword_text}</div>
                                        <div className='mt-1'>{targetingKeywordBidModalData?.match_type}</div>
                                        <div >
                                            <input type="number" name="default_bid" className='form-control fs-13 border-dark' defaultValue={modalBidInput?.default_bid ? modalBidInput?.default_bid : targetingKeywordBidModalData?.bid} onChange={handleKeywordBidInputChnage} />
                                            {formErrors.default_bid && (<div className='text-danger'>{formErrors?.default_bid}</div>)}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="modal-footer">
                                <button type="button" className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setShowTargetingKeywordBidModal(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className="rounded-pill border-0 fs-13 request-call-button text-white cursor-pointer"
                                    onClick={onClickUpdateKeywordBid}>
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {adGroupsTargetingAddProductsTargetsModal && (
                <div className={`modal modal-custom fade ${adGroupsTargetingAddProductsTargetsModal ? "show" : "false"}  ${adGroupsTargetingAddProductsTargetsModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered ad-groups-targeting-modal" role="document">
                        <div className="modal-content mx-auto">
                            <div className="modal-header">
                                <h5 className="modal-title portfolio-title fs-17">Add product targets</h5>
                                <button type="button" className="btn-close fs-12" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAdGroupsTargetingAddProductsTargetsModal(false)}></button>
                            </div>
                            <div className='modal-body negative-keyword-body py-0'>
                                <ProdcutTargetingCards adGroupTargetingAddProductsToCards={true} />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setAdGroupsTargetingAddProductsTargetsModal(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className={`rounded-pill border-0 fs-13 cursor-pointer-not-allowed ${addedkeywordsLength > 0 ? "request-call-button text-white" : "custom-cancel-btn create-portfolio-btn custom-button-background "}`}
                                >
                                    Add product targets
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer,
        adgroupsAdsData: state.AdGroupAdsDataReducer
    };
};

const connectedNavBar = connect(mapStateToProps)(TargetingsPage);
export { connectedNavBar as TargetingsPage };
