import React, { useEffect, useState } from 'react'
import { CustomFilter } from '../../../../components/custom-filter/custom-filter'
import { CustomTable } from '../../../../components/custom-table'
import { BudgetRulesDataGridCols } from './budget-rules-data-grid-cols'
import { useNavigate } from 'react-router-dom'
import { CustomFooter } from '../../../../components/footer/custom-footer'
import { GET_CAMPAIGN_BUDGET_RULES } from '../../../../app/services/budget-rules.service'
import { toast } from "react-toastify";
import { connect } from 'react-redux';

interface Props {
    ProfileDataReducer?: any;
    profileData?: any;
    UserDataReducer?: any;
    userData?: any;
    CampaignDataReducer?: any;
    campaignData?: any;
}

const BudgetRulesListPage = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");

    useEffect(() => {
        const UserProfileID = props.profileData?.profile_id?.toString();
        const userUuidd = props.userData?.uuid;
        const userCampaignId = props.campaignData?.campaign_id;
        if (UserProfileID && userUuidd && userCampaignId) {
            getCampaignBudgetRules();
        }
        getCampaignBudgetRules();
    }, [props.profileData, props.userData, props.campaignData]);



    const onCreateButtonNavigation = (data: string) => {
        const navigationData = data;
        if (navigationData) {
            navigate("/new-budget-rule")
        }
    }

    const getCampaignBudgetRules = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID
        }
        setLoading(true);
        GET_CAMPAIGN_BUDGET_RULES(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mt-3">
                    <CustomFilter customCreateButtonText='Add budget rule'
                        budgetRuleFilter={true}
                        onClickButton={(e) => onCreateButtonNavigation(e)}
                    />
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className="col-12 budget-rules-table-container">
                        <CustomTable TableCols={BudgetRulesDataGridCols}
                            tableWithoutFixedCoulmns={true}
                            tableLoading={!loading}
                            noDataFoundMesageTitle='No budget rules added'
                            noDataFoundMessageSubTitle='Add a rule to increase your daily budget for recommended events or performance metrics.' />
                    </div>
                }
                <div className='fixed-bottom pb-3'>
                    <CustomFooter />
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        profileData: state?.ProfileDataReducer,
        userData: state?.UserDataReducer,
        campaignData: state?.CampaignDataReducer
    };
};

const connectedNavBar = connect(mapStateToProps)(BudgetRulesListPage);
export { connectedNavBar as BudgetRulesListPage };

