import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardRoutes } from "../../Routes/dashboard";
import { SidebarPage } from "../../components/sidebar/sidebar";
import CustomModel from "../../components/modal/custom-model";
import GlobeIcon from './../../assets/images/svg_icons/globe.svg';
import ActiveSponsored from './../../assets/images/svg_icons/active-sponsored-ads.svg'
import SmileEmojiOne from './../../assets/images/svg_icons/smily-emoji-one.svg';
import SmileEmojiNuetral from './../../assets/images/svg_icons/smile-emoji-two.svg';
import SmileEmojiSmile from './../../assets/images/svg_icons/smile-emoji-four.svg';
import { Tooltip } from "@mui/material";
import { GET_USER_PROFILES } from "../../app/services/userProfiles.service";
import { toast } from "react-toastify";
import { ProfileData, UserUuidData } from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { useProfileId } from '../../components/profile/index';

interface Props {
  UserUuidDataReducer: any;
  userUuidData?: any;
  profileDataChangingByID?: (data: string) => void;
  useProfileData?: any;
  profileData?: any;
}

const DashboardPage = (props: Props) => {
  const [sponsoredAdsAccountsData, setSponsoredAdsAccountsData] = useState<any>()
  const [filteredProfileData, setFilteredProfileData] = useState<any>();
  const [showlogoutModal, setShowLogoutModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<boolean>(false);
  const [profileDetails, setProfileDetails] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUrl = window.location.href;
  const currentCampaignUrl = currentUrl.split("/")[4];
  const aiCampaignsUrl = currentUrl.split("/")[5];
  const userName = localStorage.getItem('user_name');
  const userEmail = localStorage.getItem('user_email');
  const userUuid = localStorage.getItem("user_uuid");
  const profile_id = localStorage.getItem('profile_id');
  const { setProfileId }: any = useProfileId();

  useEffect(() => {
    if (userUuid) {
      const res = {
        loginUserUuidData: userUuid
      }
      if (props?.userUuidData) {
        dispatch(props.userUuidData(res));
      }
    }
    if (userUuid) {
      dispatch(props.userUuidData(
        {
          loginUserUuidData: userUuid,
          profileID: profile_id
        }
      ));
    }
    window.scrollTo(0, 0);
    getUserProfiles();
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    
  }, []);


  const getUserProfiles = () => {
    const preparedData: any = {
      user_uuid: userUuid,
    }
    GET_USER_PROFILES(preparedData).then((res) => {
      if (res.data.status === "error" || res?.data?.code === 500) {
        toast.error(res.data.message);
      } else {
        if (res) {
          const response = res.data;
          const profiles_data = response.data[0]?.profiles_data;
          const profileList: any = [];
          for (let index = 0; index < profiles_data?.length; index++) {
            const element = profiles_data[index];
            profileList.push({
              label: element?.account_info.name,
              country: element?.country_code === "IN" ? "India" : "",
              value: element?.account_info.name,
              uuid: element?.uuid,
              profile_id: element?.profile_id?.toString()
            })
          }
          const filterData = profileList?.filter((el: any) => el.profile_id === profile_id);
          setFilteredProfileData(filterData[0])
          setSponsoredAdsAccountsData(profileList)
        }
      }
    });
  }

  const handleProfileSearch = (e: any) => {
    const data = e.target.value;
    if (data?.trim()?.length > 0) {
      const sponsoredData = sponsoredAdsAccountsData;
      const searchData = sponsoredData?.filter((el: any) => el?.label.toLowerCase().includes(data.toLowerCase()));
      setSponsoredAdsAccountsData(searchData)
    }
    else if (data?.trim()?.length <= 0) {
      getUserProfiles();
    }
  }

  const onClickLogout = () => {
    setShowLogoutModal(true);
  };

  const getModalStatus = (status: any) => {
    setShowLogoutModal(false);
  };

  const onClickfeedback = () => {
    setShowFeedbackModal(true);
  }

  const onClickBudgetsettings = () => {
    navigate("/dashboard/campaign-type")
  }

  const onSignout = () => {
    localStorage.clear();
    navigate("/");
  }

  const handleProfileListChange = (data: any) => {

    localStorage.setItem('profile_user_uuid', data?.uuid)
    localStorage.setItem('profile_id', data?.profile_id);
    localStorage.setItem('profile_name', data?.account_info?.name);
    localStorage.setItem('seller_id', data?.seller_id);
    localStorage.setItem('type', data?.account_info?.type);
    if (props?.profileData) {
        dispatch(props.profileData(data));
    }


    // localStorage.setItem('profile_id ', data?.profile_id);
    setSelectedProfile(data?.profile_id)
    setProfileId(data?.profile_id);
    setProfileDetails(data);
    setFilteredProfileData(data);
    navigate("/dashboard/home/campaigns");
  }

  return (
    <div className="container-fluid">
      <div className="row px-0">
        <div className="col-12 px-0">
          <nav className="navbar navbar-expand-md navbar-light custom-background-color text-white top_menu offcanvas-view p-0">
            <div className="container-fluid">
              <div className="d-sm-flex justify-content-center">
                <div className="text-center">
                  <a className="navbar-brand text-white ps-1 pe-3 ">Advise</a>
                </div>
                <div className="my-2 my-sm-0">
                  <div className="d-flex justify-content-center">
                    <div className="cursor-pointer">
                      <img src={ActiveSponsored} className="dashboard-advice-beside-icon" alt="" />
                    </div>
                    <div className="mx-3">
                      <div className="fs-12">Sponsored ads</div>
                      <div className="fs-12 fw-semibold">Campaign manager</div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="navbar-toggler d-none d-sm-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon bg-white rounded"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-block d-md-none">
                  <aside>
                    <SidebarPage />
                  </aside>
                </ul>
                <div className="d-flex justify-content-center d-block d-md-none ">
                  <button
                    className="btn btn-outline-light"
                    type="submit"
                    onClick={onClickLogout}
                  >
                    Logout
                  </button>
                </div>
              </div>
              <div className="d-flex d-block d-sm-block d-md-block flex-nowrap">
                <div className="d-sm-flex justify-content-between">
                  <div className="dropdown order-1 order-sm-0">
                    <div className="d-flex justify-content-center notification-beside-text rounded-pill cursor-pointer user_profile_style  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <div className="me-2 pt_6px">
                        <img src={GlobeIcon} className="globe-image" alt="" />
                      </div>
                      <div className="pt_4px">
                        <div className="fs-12 profile_name">{profileDetails?.label ? profileDetails?.label : filteredProfileData?.label}</div>
                        <div className="fs-12 sponsredads-text-color profile_name">
                          Sponsored ads,{profileDetails?.country ? profileDetails?.country : filteredProfileData?.country}
                        </div>
                      </div>
                    </div>
                    <ul className="dropdown-menu profiles-list-container">
                      <div className="profiles-details-container p-2 border rounded-2">
                        <div className="fs-17 fw-700 ">{profileDetails?.label ? profileDetails?.label : filteredProfileData?.label}</div>
                        <div className="fs-12 profile-detail-label-text-color py-1">Type: <span className="profile-detail-label-value-text">Sponsored ads</span></div>
                        <div className="fs-12 profile-detail-label-text-color py-1">Country: <span className="profile-detail-label-value-text">{profileDetails?.country ? profileDetails?.country : filteredProfileData?.country}</span></div>
                      </div>
                      <div className="my-2">
                        <div className="input-group border-dark mx-2 mt-0 ms-0">
                          <span className="input-group-text bg-white border-dark px-1" id="basic-addon1">
                            <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-search search-icon-color"><path fill="rgb(130, 144, 164)" d="M194 8c102.73 0 186 83.28 186 186 0 40.17-12.73 77.37-34.39 107.77l149.31 149.31a31 31 0 0 1-43.84 43.84l-149.3-149.3A185.14 185.14 0 0 1 194 380C91.28 380 8 296.73 8 194 8 91.28 91.28 8 194 8Zm0 62c-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124 68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124Z"></path></svg>
                          </span>
                          <input type="text" name="profile_search" className="form-control drop-down-input border-dark fs-13 border-start-0 px-0" placeholder="Search accounts" onChange={(e) => handleProfileSearch(e)} />
                        </div>
                      </div>
                      <div className="border-bottom border-top px-0 p-0 sponsored-ads-accounts">
                        <div className="fs-11 fw-bold pt-1">SPONSORED ADS ACCOUNTS</div>
                        {sponsoredAdsAccountsData?.map((data: any, index: number) => (
                          <li key={index} className={`list-style-type-none profile-list p-1 px-2 cursor-pointer ${selectedProfile === data?.profile_id || filteredProfileData?.portfolio_id === data?.profile_id ? "active" : ""}`} onClick={() => handleProfileListChange(data)}>
                            <div className={`fs-13`}>{data?.label}</div>
                            <div className="fs-11 profile-detail-label-text-color">{data?.country}</div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>
                  <div className="order-0 order-sm-1">
                    <button className="btn border-0" type="submit">
                      <div className="d-flex">
                        <div className="d-none logout-hover p-2 rounded">
                          <div className="dropdown">
                            <i className="bi bi-bell text-white dashboard-icon-style cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <div className="dropdown-menu notification-dropdown d-none">
                              <div className="d-flex justify-content-between px-2">
                                <div className="fs-11">NOTIFICATIONS</div>
                                <div className="custom-explore-text-color logout fs-12">see all</div>
                              </div>
                              <div className="notification-container px-2 py-3">
                                <div className="card notification-card-bg border-0 py-2">
                                  <div className="card-title px-3">
                                    <span className="me-2 fs-17">
                                      <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg=""><path fill="#326295" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                    </span>Budget spend settings increased</div>
                                  <div className="card-body py-2 ms-3 fs-12">Unspent campaign budgets can be used to increase your daily budget on the other days of the month.</div>
                                  <div className="card-footer bg-transparent border-top-0 ms-3">
                                    <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer" onClick={onClickBudgetsettings}>Go to budjet spend settings </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-none logout-hover p-2 rounded mx-2 mx-md-3">
                          <div className="dropdown">
                            <i className="bi bi-question-circle  text-white dashboard-icon-style cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul className="dropdown-menu help-dropdown d-none">
                              <li>
                                <div className="dropdown-item fs-13 py-2 ps-3">
                                  Support center
                                </div>
                              </li>
                              <li>
                                <div className="dropdown-item fs-13 py-2 ps-3">
                                  Training courses
                                  <span className="ms-2"><svg viewBox="0 0 576 512" height="14" width="14" className="fas fa-external-link-alt" data-fa-i2svg=""><path fill="currentColor" d="M226 39a31 31 0 0 1 31-31h279v279a31 31 0 1 1-62 0V113.84L247.92 339.92a31 31 0 0 1-43.84-43.84L430.16 70H257a31 31 0 0 1-31-31ZM71 70a31 31 0 0 1 31 31v341h341a31 31 0 1 1 0 62H71a31 31 0 0 1-31-31V101a31 31 0 0 1 31-31Z"></path></svg></span>
                                </div>
                              </li>
                              <li>
                                <div className="dropdown-item fs-13 border-bottom py-2 ps-3" >
                                  Status
                                  <span className="ms-2"><svg viewBox="0 0 576 512" height="14" width="14" className="fas fa-external-link-alt" data-fa-i2svg=""><path fill="currentColor" d="M226 39a31 31 0 0 1 31-31h279v279a31 31 0 1 1-62 0V113.84L247.92 339.92a31 31 0 0 1-43.84-43.84L430.16 70H257a31 31 0 0 1-31-31ZM71 70a31 31 0 0 1 31 31v341h341a31 31 0 1 1 0 62H71a31 31 0 0 1-31-31V101a31 31 0 0 1 31-31Z"></path></svg></span>
                                </div>
                              </li>
                              <li>
                                <div className="dropdown-item fs-13 py-2 ps-3">
                                  What is new <span className="badge badge-background">3</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="logout-hover p-2 rounded">
                          <div className="dropdown">
                            <i className="bi bi-person-circle text-white dashboard-icon-style cursor-pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul className="dropdown-menu profile-dropdown profile">
                              <li>
                                <div className="dropdown-item border-bottom">
                                  <div className="fs-17 custom-dropdown-listitem-color fw-400">{userName}</div>
                                  <div className="fs-13 py-1">{userEmail}</div>
                                </div>
                              </li>
                              <li className="d-none">
                                <div className="dropdown-item border-bottom py-2">
                                  <div className="fs-11 languages-text py-1"><span className="me-2"><img src={GlobeIcon} className="languages-globe-icon" alt="" /></span>LANGUAGE</div>
                                  <div className="d-flex py-1">
                                    <div className="">
                                      <select className="form-select custom-drop-down rounded-pill py-1">
                                        <option value="English(US)">English(US)</option>
                                        <option value="Hindi">Hindi</option>
                                        <option value="French">French</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li><a className="dropdown-item fs-13 custom-explore-text-color logout" onClick={onSignout}>Sign out</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="row d-flex">
        <div className="d-block d-md-block px-0 px-sm-0 w-4">
          <div className="text-center">
            <SidebarPage />
          </div>
        </div>
        <div className="content-scroll w-96 col-12">
          <div className={`my-2 py-1 bg-white shadow-sm country-india-btn-container ms-5 ms-md-3 ms-lg-3 ms-xl-0  ${currentCampaignUrl === "campaign" || currentCampaignUrl === "ad-groups" ? "d-none" : "d-block"}`}>
            <div className="d-none">
              {currentCampaignUrl === "campaign-type" && (
                <button className={`btn btn-dark border-0 text-dark custom-button-background fs-13 cursor-pointer feedback-button feedback-button-position ${aiCampaignsUrl === "ai-campaigns" ? "d-none" : "d-block"}`} onClick={onClickfeedback}>feedback</button>
              )}
            </div>
            <span className="country-header-background text-white rounded-5 px-3 py-2 ms-4 fs-13 cursor-pointer">Country: India</span>
          </div>
          <div className="d-flex flex-column justify-content-center ms-5 ms-md-3 ms-lg-3 ms-xl-0">
            <DashboardRoutes />
          </div>
        </div>
      </div>
      {showlogoutModal && (
        <CustomModel
          ismodalShow={showlogoutModal}
          modalStatus={getModalStatus}
        />
      )}
      {showFeedbackModal && (
        <div className={`modal fade d-none ${showFeedbackModal ? "show" : "false"}  ${showFeedbackModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered feedback-modal-position" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-17">Share your feedback with us</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowFeedbackModal(false)}></button>
              </div>
              <div className="modal-body p-0">
                <div className="col-12 feedback-modal-body-background px-3 fs-13 py-2">Based on your experience with campaign creation, please provide feedback</div>
                <div className="col-12 experience-container px-3 my-2">
                  <div className="fs-13 fw-700">How satisfied are you with this experience?</div>
                  <div className="d-flex justify-content-between py-2 ">
                    <div className="text-center">
                      <div className="fs-13">Very dissatisfied</div>
                      <img src={SmileEmojiOne} alt="" />
                    </div>
                    <div className="text-center mt-3 pt-1">
                      <img src={SmileEmojiOne} alt="" />
                    </div>
                    <div className="text-center mt-3 pt-1 mx-3">
                      <img src={SmileEmojiNuetral} alt="" />
                    </div>
                    <div className="text-center mt-3 pt-1 ">
                      <img src={SmileEmojiSmile} alt="" />
                    </div>
                    <div className="text-center">
                      <div className="fs-13">Very satisfied</div>
                      <img src={SmileEmojiSmile} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-12 feeback-container px-3 my-2">
                  <div className="fs-13 fw-700">What is your feedback?</div>
                  <textarea rows={2} placeholder="Do not include any personal or sensitive data in your comment" className="form-control fs-13"></textarea>
                  <div className="fs-12 my-1">500 characters remaining.</div>
                  <div className="fs-11 my-3">What is personal or sensitive data? <span>
                    <Tooltip title={'Examples of personal or sensitive data include names, addresses, telephone numbers or email addresses. Personal or sensitive data is not necessary to complete this survey. By submitting your response, you agree you have not included this data.'} placement="top" arrow className=" cursor-pointer shadow">
                      <span className='mx-1 mb-3'>
                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle" data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                      </span>
                    </Tooltip>
                  </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="custom-explore-text-color border-0 bg-transparent"
                  onClick={() => setShowFeedbackModal(false)}
                >
                  Cancel
                </button>
                <button type="button" className="custom-cancel-btn text-dark custom-button-background  rounded-pill border-0 px-3 py-1">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row custom-background-color fixed-bottom text-center d-none">
        <div className="col-12 text-white py-1">
          <div>Copyright © 2023 Advise.</div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserUuidDataReducer: state.UserUuidDataReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    userUuidData: (data: any) => dispatch(UserUuidData(data)),
    profileData: (data: any) => dispatch(ProfileData(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
export { connectedNavBar as DashboardPage };

