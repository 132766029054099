import React from "react";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import { LoginPage } from "../pages/login/login";
import { RegisterPage } from "../pages/login/register";
import { ForgotPasswordPage } from "../pages/login/forgot-password";
import { DashboardPage } from "../pages/Dashboard/dashboard";
import { CampaignCreationPage } from "../pages/Dashboard/campaign-type/campaign-creation";
import { ManageProfile } from "../pages/Dashboard/profile/manage-profile";
import { AdGroupCreationPage } from "../pages/Dashboard/campaigns/ad-groups/ad-group-creation/ad-group-creation";
import { BudgetRuleCreation } from "../pages/Dashboard/campaigns/budget-rules/budget-rule-creation/budget-rule-creation";
import { SignInPage } from "../pages/login/sign-in/sign-in";
import { AiCampaignCreationStepper } from "../pages/Dashboard/campaign-type/ai-campaign-creation-stepper";
import { Reports } from "../pages/Dashboard/reports/reports";
import { toast } from "react-toastify";

interface Props { }

export const RoutesPage = (props: Props) => {
  const userUuid = localStorage.getItem("user_uuid");
  const currentUrl = window.location.href;
  const currentCampaignUrl = currentUrl.split("/")[3];
  if (currentCampaignUrl && (!currentCampaignUrl.includes('login') || !currentCampaignUrl.includes('register') || !currentCampaignUrl.includes('forgot-password') || !currentCampaignUrl.includes('signin')) && !userUuid) {
    toast.error('Not authenticated, redirecting to the login page.');
    setTimeout(() => {
      window.location.href = `${currentUrl.split("/")[0]}/${currentUrl.split("/")[1]}/${currentUrl.split("/")[2]}`
    }, 2000);
  }
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/register" element={<RegisterPage />}></Route>
          <Route path="/forgot-password" element={<ForgotPasswordPage />}></Route>
          <Route path="/dashboard/*" element={<DashboardPage />}></Route>
          <Route path="/signin" element={<SignInPage />}></Route>
          <Route path="/new-campaign" element={<CampaignCreationPage />}></Route>
          <Route path="/new-campaign/AI/:type/:portfolio_id" element={<AiCampaignCreationStepper />}></Route>
          <Route path="/new-camapign/portfolio/:portfolio_uuid/:type/:portfolio_id" element={<AiCampaignCreationStepper />}></Route>
          <Route path="/new-campaign/:type/:portfolio_id" element={<CampaignCreationPage />}></Route>
          <Route path="/new-adgroup" element={<AdGroupCreationPage />}></Route>
          <Route path="/new-budget-rule" element={<BudgetRuleCreation />}></Route>
          <Route path="/manage-profile" element={<ManageProfile />}></Route>
          <Route path="/manage-profile?:code" element={<ManageProfile />}></Route>
          <Route path="/reports" element={<Reports />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
