import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../components/form-builder/form';

export const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const [showChangePasswordFields, setShowChangePasswordFields] = useState<boolean>(false);
    const [showEmailFields, setShowEmailFields] = useState<boolean>(true);
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,6}$/;
    const forgotPasswordFormData = [
        {
            title: "input",
            control: "",
            type: "text",
            label: "Email",
            name: "email",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: emailRegEx,
            maxLength: "",
            placeholder: "Please enter email",
            error: "Pease enter email",
        },
    ];
    const passwordFieldsFormData = [
        {
            title: "input",
            control: "",
            type: "password",
            label: "New password",
            name: "new_password",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter new password ",
            error: "Pease enter new password ",
        },
        {
            title: "input",
            control: "",
            type: "password",
            label: "Confirm your password",
            name: "confirm_password",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter your confirm password",
            error: "Pease enter your confirm password",
        },
    ];

    const onSubmitPasswordForm = (data: any) => {
        if (data.formData.email) {
            setShowChangePasswordFields(true);
            setShowEmailFields(false);
        }
    }

    const onSubmitResetForm = (data: any) => {
        if (data?.formData.new_password && data?.formData?.confirm_password) {
            navigate("/");
        }
    }

    return (
        <div className="modal log-in-modal-border forgot-password-page">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content login-dynamic-form mx-auto">
                    <div className="login-form mx-2 mx-md-0 p-3">
                        <div className=''>
                            <div className='fs-24 text-center me-2 fw-700 '>
                                {showChangePasswordFields ? "Reset password" : "Forgot password"}
                            </div>
                        </div>
                        <div className="col-12">
                            {forgotPasswordFormData && showEmailFields && (
                                <DynamicForm
                                    columns={1}
                                    formControls={""}
                                    userFormData={forgotPasswordFormData}
                                    custom_btn_text={'Send email'}
                                    customClass={"d-flex justify-content-center"}
                                    cancelRoute={undefined}
                                    isEdit={false}
                                    onChildEvent={(e) => onSubmitPasswordForm(e)}
                                    loading={false}
                                    isNotEmptyFormAfterSubmit={true}
                                />
                            )}
                        </div>
                        {showChangePasswordFields && (
                            <DynamicForm
                                columns={1}
                                formControls={""}
                                userFormData={passwordFieldsFormData}
                                custom_btn_text={'Reset Password'}
                                customClass={"d-flex justify-content-center"}
                                cancelRoute={undefined}
                                isEdit={false}
                                onChildEvent={(e) => onSubmitResetForm(e)}
                                loading={false}
                                isNotEmptyFormAfterSubmit={true}
                            />
                        )}
                        <div className='d-flex justify-content-center my-2 me-2'>
                            <div className='text-center'><NavLink to="/" className="text-decoration-none"><span className='custom-explore-text-color logout cursor-pointer fw-bold' >Login</span></NavLink> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
