import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import NoProductImage from './../../assets/images/no-product-image.png';

interface Props {
    aiCampaignsProductsData?: any;
    cardDataShow?: boolean;
    aiFlowKeywordSelectedTabTargetingData?: (data: any) => void;
    uniqueData?: any;
    isShowData?: any;
    aiFlowAddedGroupdata?: any;
    addedKeywordSuggestionData?: any;
    getSelectedGroupData?: (data: any) => void;
    byDefaultTabData?: (data: any) => void;
    aiFlowGroupSelectedTabTargetingData?: (data: any) => void;
    isId?: any;
    productInfo?: any;
    isFromBiddingStratey?: any;
}

export const AiCampaignsTabsPage = (props: Props) => {
    const tabs = props?.aiCampaignsProductsData || [];
    const [activeTab, setActiveTab] = useState<any>(0);
    const visibleTabs = 3;
    const [startIndex, setStartIndex] = useState<number>(0);
    const totalTabs = tabs.length;
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = Number(portfolio_id);

    useEffect(() => {
        if (totalTabs > 0) {
            setStartIndex(0);
            if (tabs[0].uuid) {
                console.log('tabs[0].uuid', tabs[0].uuid);

                // handleTabClick(tabs[0].uuid, tabs[0]);
                setActiveTab(tabs[0].uuid);
            }
        }
        if (props.aiFlowAddedGroupdata) {
            for (let i = 0; i < props.aiFlowAddedGroupdata?.length; i++) {
                if (props?.aiFlowAddedGroupdata[0]) {
                    if (props?.byDefaultTabData) {
                        props?.byDefaultTabData(1);
                    }
                }
            }
        }
    }, [tabs]);

    useEffect(() => {
        if (portfolioId == 2) {
            setActiveTab(props.isId)
            const preparedData = groupData(props.aiFlowAddedGroupdata, props.isId);
            if (preparedData && preparedData.products_data.length > 0) {
                handleGroupClick(props.isId, preparedData, preparedData.products_data[0])
            }
        }
        if (portfolioId == 1) {
            const preparedData = props.aiCampaignsProductsData;

            setActiveTab(props.isId || preparedData[0]?.uuid)
            if (preparedData && preparedData.length > 0) {
                handleTabClick(preparedData[0].uuid, preparedData, preparedData[0])
            }
        }
    }, [props.aiFlowAddedGroupdata, props.aiCampaignsProductsData, props.isId])

    const groupData = (data: any, id: any) => {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].products_data.length; j++) {
                if (id == data[i].products_data[j].uuid) {
                    return data[i];
                }
            }
        }
    }


    const handleTabClick = (tabId: any, tab: any, product: any) => {
        if (!props?.isFromBiddingStratey) {
            setActiveTab(tabId);
            if (props?.getSelectedGroupData) {
                const prepareData = {
                    groups_data: tab,
                    product: product
                }
                props?.getSelectedGroupData(prepareData);
            }            
            if (props?.aiFlowKeywordSelectedTabTargetingData) {
                props?.aiFlowKeywordSelectedTabTargetingData(tab)
            }
        } else {
        }
    };

    const handleGroupClick = (tabId: any, data: any, product: any) => {
        if (!props?.isFromBiddingStratey) {
            setActiveTab(tabId);
            if (props?.getSelectedGroupData) {
                const prepareData = {
                    groups_data: data,
                    product: product
                }
                props?.getSelectedGroupData(prepareData);
            }
            if (props?.aiFlowGroupSelectedTabTargetingData) {
                props?.aiFlowGroupSelectedTabTargetingData(product)
            }
        }

    }
    const handlePrevClick = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 2);
        }
    };

    const handleNextClick = () => {
        if (startIndex + visibleTabs < totalTabs) {
            setStartIndex(startIndex + 2);
        }
    };

    const visibleTabRange = tabs.slice(startIndex, startIndex + visibleTabs);

    const renderProductsMethod = (data: any, index: number) => {
        if (data.id === props.isShowData) {
            return data.products_data.map((product: any, index: number) => {
                return (
                    <>
                        <button
                            key={product.uuid}
                            className={`nav-link fs-13 ${activeTab === product.uuid ? 'active custom-explore-text-color ai-product-bg-color' : 'text-dark'}`}
                            onClick={() => handleGroupClick(product.uuid, data, product)}
                        >
                            <div className={`bg-transparent d-flex justify-content-between mx-1`}>
                                <div className={`row  ${activeTab === product.uuid ? 'active ai-campaign-product-tab product-ai-tab' : "product-ai-tab"}`}>
                                    <div className='col-2'>
                                        <img src={product?.image_url ? product?.image_url : NoProductImage} className="product-card-image" alt={''} />
                                    </div>
                                    <div className='col-10 pb-3'>
                                        <div className='product-header ai-products-text-color text-start' title={product?.title}>{product?.title?.length > 100 ? `${product?.title?.substring(0, 100)}...` : `${product?.title}`}</div>
                                        <div className='d-flex'>
                                            <div className='text-danger fs-11'><span className='text-dark'>₹</span>{product.price_to_pay?.amount}</div>
                                            <div className='text-dark mx-1'>|</div>
                                            <div className='text-danger fs-11'>{product?.availability?.replace(/_/g, " ")}
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='product-details fs-11'><span className=''>ASIN:</span>{product.asin}</div>
                                            <div className='text-dark mx-1'>|</div>
                                            <div className='product-details fs-11'>SKU:{product.sku}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </>
                )
            })
        }
    }

    return (
        <div className='container-fluid'>
            <div className="tabs-section products-tab px-0">
                <div className="nav nav-underline" id="nav-tab" role="tablist">
                    <div className='d-flex justify-content-between'>
                        <div className={`${props?.cardDataShow ? "my-auto me-2" : ""}`}>
                            {tabs?.length > 8 && (
                                <button
                                    className={`nav-link text-dark fs-21 ${startIndex === 0 ? 'disabled' : ''}`}
                                    onClick={() => handlePrevClick()}
                                ><i className="bi bi-chevron-left fs-15 fw-bold"></i></button>
                            )}
                        </div>
                        {portfolioId == 1 && (
                            <div className='col-md-12 d-flex justify-content-between'>
                                {visibleTabRange.map((tab: any, index: number) => (
                                    <button
                                        key={tab.uuid}
                                        className={`nav-link fs-13 ${activeTab === tab.uuid ? 'active custom-explore-text-color ai-product-bg-color' : 'text-dark'}`}
                                        disabled={props?.isFromBiddingStratey}
                                        onClick={() => handleTabClick(tab.uuid, tab, tab)}
                                    >
                                        {props?.cardDataShow && (
                                            <div className={`bg-transparent d-flex justify-content-between mx-1`}>
                                                <div className={`row  ${activeTab === tab.uuid ? 'active ai-campaign-product-tab product-ai-tab' : "product-ai-tab"}`}>
                                                    <div className='col-2'>
                                                        <img src={tab?.image_url} className="product-card-image" alt={''} />
                                                    </div>
                                                    <div className='col-10 pb-3'>
                                                        <div className='product-header ai-products-text-color text-start' title={tab?.title}>{tab?.title?.length > 100 ? `${tab?.title?.substring(0, 100)}...` : `${tab?.title}`}</div>
                                                        <div className='d-flex'>
                                                            <div className='amount-info fs-11'><span className='product-details'>₹</span>{tab.price_to_pay?.amount}</div>
                                                            <div className='text-dark mx-1'>|</div>
                                                            <div className='stock-info fs-11'>{tab?.availability?.replace(/_/g, " ")}</div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='product-details fs-11'><span className=''>ASIN:</span>{tab.asin}</div>
                                                            <div className='text-dark mx-1'>|</div>
                                                            <div className='product-details fs-11'>SKU:{tab.sku}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </button>
                                ))}
                            </div>
                        )}
                        {
                            portfolioId == 2 && props?.aiFlowAddedGroupdata?.map((tab: any, index: any) => {
                                return renderProductsMethod(tab, index)
                            })
                        }
                        <div className={`${props?.cardDataShow ? "my-auto ms-2" : ""}`}>
                            {tabs?.length > 8 && (
                                <button
                                    className={`nav-link text-dark fs-21 ${startIndex + visibleTabs >= totalTabs ? 'disabled' : ''}`}
                                    onClick={() => handleNextClick()}
                                > <i className="bi bi-chevron-right fs-15 fw-bold"></i></button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
