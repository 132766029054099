import React, { useEffect, useState } from "react";
import { CampaignTypeCard } from "../../../components/campaign-type-card/campaign-type-card";
import { CustomFooter } from "../../../components/footer/custom-footer";
import { useNavigate } from "react-router-dom";

export const CampaignType = () => {
  const navigate = useNavigate();

  const campaignCardsData = [
    {
      card_title: "Sponsored Products",
      card_images: [
        {
          url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_1.svg",
          captionData: "Sponsored Products ad format on top of search"
        },
        {
          url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredProducts/Sponsored_Products_Image_2.svg",
          captionData: "Sponsored Products ad format on top of search on mobile",
        },
      ],
      card_sub_title: "Promote product listings",
      card_description: "Sponsored Products can help promote products to shoppers actively searching with related keywords or viewing similar products on Amazon",
      card_explore_text: "Explore Sponsored Products",
      custom_button_text: "Continue",
      card_high_lights: [
        "Automatically find keywords",
        "Target keywords or products",
        "Track return on ad spend (ROAS)"
      ],
      card_help_text_data: "Sponsored Products is a cost-per-click advertising solution that enables you to promote the products you sell with ads that may appear in highly visible placements on Amazon.",
      video_url: "https://www.youtube.com/watch?v=7PIji8OubXU",
      card_help_text_data_two: "Available multilanguage captions are best viewed in the Google Chrome browser.Available multilanguage captions are best viewed in the Google Chrome browser.Available multilanguage captions are best viewed in the Google Chrome browser.Available multilanguage captions are best viewed in the Google Chrome browser.",
      sponsored_brand: true
    },
    {
      card_title: "AI Campaigns",
      card_images: [
        {
          url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredBrands/Sponsored_Brands_Image_1.svg",
          captionData: "Sponsored Brands video ad format in search"
        },
        {
          url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredBrands/Sponsored_Brands_Image_2.svg",
          captionData: "Sponsored Brands Product collection ASIN-only ad format on top of search"
        },
        {
          url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredBrands/Sponsored_Brands_Image_3.svg",
          captionData: "Sponsored Brands Store spotlight ad format on top of search"
        },
        {
          url: "https://m.media-amazon.com/images/G/01/Aloha/Ingress/ProductTilesSlideshows/SponsoredBrands/Sponsored_Brands_Image_4.svg",
          captionData: "Sponsored Brands Product collection custom image on top of search on mobile"
        },
      ],
      card_sub_title: "Promote products with automated campaigns",
      card_description: "AI Campaigns automates and monitors creation, publishing and bidding of campaigns actively and gives you real time suggestions to run multiple campaigns",
      custom_button_text: "Continue",
      sponsored_brand: true
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" text-center mx-auto cards-container" >
          <div className="d-md-flex justify-content-between  my-4 mx-auto">
            <h1 className="fs-28 choose-campaign-font-family">Choose your campaign type</h1>
            <div className="">
              <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer">View drafts</button>
            </div>
          </div>
          <div className="row">
            <CampaignTypeCard campaignCardsData={campaignCardsData} />
          </div>
          <div className="fs-12 py-5  mt-4">
            Want help from an Advise Advertising specialist to create your first campaign?
            <span className="fs-13 back-to-help-text logout cursor-pointer"> Request a call</span>
          </div>
          <div className="footer=container">
            <CustomFooter />
          </div>
        </div>
      </div>
    </div>
  );
};
