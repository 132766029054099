import API from "../utils/axios";
const api_url = "/api";

export const GET_USER_PORTFOLIOS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/getUserPortfolio`, data);
};

export const CREATE_PORTFOLIO = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/createUserPortfolio`, data);
};

export const UPDATE_PORTFOLIO = (data: any): Promise<any> => {
    return API.post(`${api_url}/userPortfolio/updateUserPortfolio`, data);
};