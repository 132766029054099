import { CustomTableCol } from "../../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const AdGroupsNegativeTargetingDataGridCols: CustomTableCol[] = [
    {
        title: "Keyword",
        control: "keyword_text",
        sortable: false,
        canShowColumn: true,
        style: { width: "100px", left: 0, height: "40px" },
    },
    {
        title: "Match type",
        control: "match_type",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group negative targteings match type",
        },
    },
];
