import API from "../utils/axios";
const api_url = "/api";

export const LOGIN = (data: any): Promise<any> => {
    return API.post(`${api_url}/users/login`, data);
};

export const REGISTER = (data: any): Promise<any> => {
    return API.post(`${api_url}/users/create`, data);
};

export const IS_USER_LINKED_AWS_ACCOUNT = (data: any): Promise<any> => {
    return API.post(`${api_url}/users/isUserLinkedAwsAccount`, data);
};

export const GET_AWS_ACCOUNT_LINK_URL = (): Promise<any> => {
    return API.get(`${api_url}/users/getAwsAccountLinkUrl`,);
};

export const USER_CODE_CREATION = (data: any): Promise<any> => {
    return API.post(`${api_url}/users/userCodeCreation`, data);
};
