import { DataTableCol } from "../../../components/data-table/types";

export const KeywordTargetingTwoDataGridCols: DataTableCol[] = [
    {
        title: 'Keyword',
        control: 'keyword_two',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Match Type',
        control: 'match_type_two',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Sugg. bid',
        control: 'suggested_bid_two',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Bid',
        control: 'userName',
        WrongIcon:true,
        sortable: true,
        canShowColumn: true
    },
]
    