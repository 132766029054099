import React, { useEffect, useRef, useState } from 'react'
import CheckWithCircle from './../../assets/images/svg_icons/check-with-circle.svg';
import PreviewIcon from './../../assets/images/svg_icons/preview.svg';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
    campaignCardsData?: any;
}

export const CampaignTypeCard = (props: Props) => {
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showExploreModal, setShowExploreModal] = useState(false);
    const [campaignCardData, setCampaignCardData] = useState<any>()
    const [exploreCampaignCardData, setExploreCampaignCardData] = useState<any>();
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = portfolio_id;
    const videoRef: any = useRef()
    const navigate: any = useNavigate();
    const modalRef: any = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowPreviewModal(false);
            }
        };

        const handleEscapeKey = (event: any) => {
            if (event.key === 'Escape') {
                setShowPreviewModal(false);
            }
        };

        if (showPreviewModal) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [showPreviewModal]);

    const onClickPreviewImages = (e: any, data: any) => {
        setShowPreviewModal(true);
        setCampaignCardData(data)
    }

    const onClickExploreText = (e: any, data: any) => {
        setShowExploreModal(true)
        setExploreCampaignCardData(data)
    }

    const onClickContinue = (e: any, data: any) => {
        if (portfolioId) {
            if (data?.card_title === "Sponsored Products") {
                if (data?.custom_button_text === "Continue") {
                    const type = "portoflio";
                    navigate(`/new-campaign/${type}/${portfolioId}`)
                }
            } else {
                if (data?.card_title === "AI Campaigns" && data?.custom_button_text === "Continue") {
                    const type = "portfolio";
                    navigate(`/dashboard/campaign-type/${type}/ai-campaigns/${portfolioId}`)
                }
            }
        } else if (data?.card_title === "AI Campaigns" && data?.custom_button_text === "Continue") {
            navigate("/dashboard/campaign-type/ai-campaigns")
        } else {
            if (data?.custom_button_text === "Continue") {
                navigate("/new-campaign")
            }
        }
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <div className='row d-flex justify-content-start card-left'>
                        {props?.campaignCardsData?.map((cardData: any, index: number) => (
                            <div key={index} className='campaign-card my-2'>
                                <div className="card text-center shadow-sm campaign-card-type-height">
                                    <div className="card-header bg-white text-start fs-17 fw-700">
                                        {cardData?.card_title}
                                    </div>
                                    <div></div>
                                    <div className="card-body">
                                        <div className='px-0 py-2 border-bottom'>
                                            <div id={`carouselExampleCaptions-${index}`} className="carousel slide">
                                                <div className="carousel-indicators normal-carousel-indicators">
                                                    {cardData?.card_images.map((imageUrl: any, index: number) => (
                                                        <button
                                                            key={index}
                                                            type="button"
                                                            data-bs-target={`#carouselExampleCaptions-${index}`}
                                                            data-bs-slide-to={index}
                                                            className={index === 0 ? "active slide-icon" : "slide-icon"}
                                                            aria-current={index === 0}
                                                            aria-label={`Slide ${index + 1}`}
                                                        ></button>
                                                    ))}
                                                </div>
                                                <div className='text-end mb-1 preview-icon-position' onClick={(e) => onClickPreviewImages(e, cardData)}>
                                                    <img src={PreviewIcon} className='fw-bold' />
                                                </div>
                                                <div className="carousel-inner">
                                                    {cardData?.card_images.map((imageUrl: any, index: number) => (
                                                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                            <img src={imageUrl?.url} className="d-block w-100" alt={`Slide ${index + 1}`} />
                                                            <div className="carousel-caption d-none d-md-block"></div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                        <h5 className="card-title text-start mt-3 my-2 fs-13 fw-700">{cardData?.card_sub_title}</h5>
                                        <p className="card-text text-start card-description fs-13 fw-400" >{cardData?.card_description}</p>

                                        {cardData?.card_title === "Sponsored Products" && (<div className='text-start my-2'>
                                            <a className='text-start text-decoration-none'>
                                                <span><i className="bi bi-question-circle-fill me-2 mb-1 custom-explore-text-color"></i></span>
                                                <span className='mt-2 pt-1 custom-explore-text-color fs-13 logout cursor-pointer' onClick={(e) => onClickExploreText(e, cardData)}>
                                                    {cardData?.card_explore_text}
                                                </span>
                                            </a>
                                        </div>
                                        )}
                                        <button className={`btn btn-primary custom-button-background-color col-12 rounded-pill fs-13 cursor-pointer ${cardData?.card_title === "AI Campaigns" ? "ai-continue-position" : ""}`} onClick={(e) => { onClickContinue(e, cardData) }}>{cardData?.custom_button_text}</button>
                                    </div>
                                    <div className="card-footer bg-white text-start text-body-secondary fw-bold">
                                        <div className="accordion accordion-flush" id={`accordionFlushExample-${index}`}>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <h5 className="accordion-button cursor-pointer collapsed text-start p-0 fs-13 fw-700 pb-2" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne-${index}`} aria-expanded="false" aria-controls={`flush-collapseOne-${index}`}>
                                                        Highlights
                                                    </h5>
                                                </h2>
                                                <div id={`flush-collapseOne-${index}`} className="accordion-collapse collapse" data-bs-parent={`#accordionFlushExample-${index}`}>
                                                    <div className="accordion-body">
                                                        {cardData?.card_high_lights?.map((data: any, index: number) => (
                                                            <div key={index} className='fw-normal fs-13 text-start'><span className=''><img src={CheckWithCircle} className='mb-1 me-2' /></span>{data}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {showPreviewModal && (
                <div className={`modal modal-custom fade ${showPreviewModal ? "show" : "false"}  ${showPreviewModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-preview m-auto mt-5" role="document" ref={modalRef}>
                        <div className="modal-content sponsored-products-modal-body">
                            <div className="modal-header">
                                <h5 className="modal-title fs-17">{campaignCardData?.card_title}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPreviewModal(false)}></button>
                            </div>
                            <div className="modal-body p-0">
                                <div id={`carouselExampleCaptionsPreview`} className="carousel slide">
                                    <div className="carousel-indicators  preview-carousel-indicators">
                                        {campaignCardData?.card_images.map((data: any, index: number) => (
                                            <button
                                                key={index} type="button"
                                                data-bs-target={`#carouselExampleCaptionsPreview`}
                                                data-bs-slide-to={index}
                                                className={index === 0 ? "active slide-icon" : "slide-icon"}
                                                aria-current={index === 0}
                                                aria-label={`Slide ${index + 1}`}
                                            ></button>
                                        ))}
                                    </div>
                                    <div className="carousel-inner">
                                        {campaignCardData?.card_images.map((data: any, index: number) => (
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <img src={data?.url} className="d-block mx-auto w-100 p-5 m-3 preview-image-view preview-modal-image" alt={`Slide ${index + 1}`} />
                                                <div className="carousel-caption d-none d-md-block fs-12 text-dark mt-2">
                                                    {data?.captionData}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptionsPreview`} data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptionsPreview`} data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showExploreModal && (
                <div className={`modal fade ${showExploreModal ? "show" : "false"}  ${showExploreModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog  modal-dialog-centered explore-help-modal explore-get-help-modal " role="document" >
                        <div className="modal-content get-help-create-ad-group-modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-17 fw-700">Get Help</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowExploreModal(false)}></button>
                            </div>
                            <div className="modal-body explore-modal-body">
                                <div className='col-12 text-start'>
                                    <h5 className='fs-21 fw-bold'>{exploreCampaignCardData?.card_title}</h5>
                                </div>
                                <div className='col-12 text-start fs-13'>{exploreCampaignCardData?.card_help_text_data}</div>
                                <div className='col-12'>
                                    <video ref={videoRef} controls autoPlay>
                                        <source src={exploreCampaignCardData?.video_url} type="video/mp4" />
                                    </video>
                                </div>
                                <div className='col-12 text-start fs-13'>
                                    {exploreCampaignCardData?.card_help_text_data_two}
                                </div>
                            </div>
                            {exploreCampaignCardData?.card_title === "Sponsored Products" && (
                                <div>
                                    <div className='border-top fs-11 fw-normal pt-2 text-start px-3'>
                                        NEXT TOPIC
                                    </div>
                                    <div className='fs-13 text-start px-3 pb-2 explore-modal-navigation-text-color fw-400 cursor-pointer' >
                                        Sponsored Brands
                                    </div>
                                </div>
                            )}
                            {exploreCampaignCardData?.card_title === "Sponsored Brands" && (
                                <div>
                                    <div className='border-top fs-11 fw-normal pt-2 text-start px-3'>
                                        NEXT TOPIC
                                    </div>
                                    <div className='fs-13 text-start px-3 pb-2 explore-modal-navigation-text-color fw-400 cursor-pointer' >
                                        Sponsored Display
                                    </div>
                                </div>
                            )}
                            <div className="modal-footer py-1">
                                <div className='col-12 text-start ps-2 fs-13'>
                                    Need more help?
                                </div>
                                <div className='col-12 text-start ps-2'>
                                    <button className='request-call-button text-white'>
                                        <span className='me-2'><svg viewBox="0 0 512 512" width="16" height="16" className="fas fa-phone" data-fa-i2svg=""><path fill="white" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg></span>
                                        Request a call</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    )
}
