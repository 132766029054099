import { CustomTableCol } from "../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const PortfoliosDataGridCols: CustomTableCol[] = [
    {
        title: "Portfolios",
        control: "portfolio_name",
        sortable: true,
        canShowColumn: true,
        class: "col_table tb_left_border",
        style: { width: "455px", left: 0, height: "42px" },
        icon: info,
        isLink: true,
    },
    {
        title: "Status",
        control: "state",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
    },
    {
        title: "Budget",
        control: "amount",
        sortable: true,
        canShowColumn: true,
        style: { width: "150px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Portfolio Budget",
        },
    },
    {
        title: "Budget start",
        control: "start_date",
        sortable: true,
        canShowColumn: true,
        style: { width: "110px" },
    },
    {
        title: "Budget end",
        control: "end_date",
        sortable: true,
        canShowColumn: true,
        style: { width: "110px" },
    },

    {
        title: "Impressions",
        control: "impressions",
        sortable: true,
        canShowColumn: true,
        style: { width: "160px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Impressions"
        },
    },

    {
        title: "Clicks",
        control: "clicks",
        sortable: true,
        canShowColumn: true,
        style: { width: "140px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Clicks"
        },
    },

    {
        title: "CTR",
        control: "clickThroughRate",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "CTR"
        },
    },

    {
        title: "Spend ",
        control: "cost",
        sortable: true,
        canShowColumn: true,
        style: { width: "125px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Portfolio Spend"
        },
    },

    {
        title: "CPC",
        control: "costPerClick",
        sortable: true,
        canShowColumn: true,
        style: { width: "130px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Campaigns CPC"
        },
    },
    {
        title: "Orders",
        control: "purchasesSameSku14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "130px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Campaigns Orders"
        },
    },
    {
        title: "Sales",
        control: "sales30d",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Portfolio Sales"
        },
    },
    {
        title: "ACOS",
        control: "acosClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "130px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Campaigns ACOS"
        },
    },
    {
        title: "ROAS",
        control: "roasClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "130px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Portfolio ROAS"
        },
    },
    {
        title: "Actions",
        control: "actions",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        isModify: true,
    },
];
