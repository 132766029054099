import React from 'react'
import { NavLink } from 'react-router-dom';

interface Props {
    breadcumList: any;
}

export const Breadcum: React.FC<Props> = (props: Props) => {
    return (
        <div className='container-fluid px-0'>
            <div className='row'>
                <div className='col-12 px-0'>
                    <div className='px-0'>
                        <nav aria-label="breadcrumb" className="first">
                            <ol className="breadcrumb indigo lighten-6 my-0 navbar-light ">
                                {props.breadcumList.map((item: any, i: number) => (
                                    <li key={i} className={`${item.isActive ? 'breadcum-active-label' : 'breadcrumb-item font-weight-bold font-italic ms-1'}`}>
                                        {item?.routerLink === "" ? <div>{item.value}</div> :
                                            <NavLink to={item.isActive ? undefined : item.routerLink} className={`${item.isActive ? 'black-text  active-1 text-decoration-none breadcum-text-color disabled-link' : 'black-text breadcum-text-color text-decoration-none'}`}>
                                                <span className={`${item.isActive ? 'breadcum-label' : 'breadcum-label mr-md-3 mr-2'}`}>{item.value}</span>
                                            </NavLink>
                                        }
                                        <i className={`${item.isActive ? '' : 'bi bi-chevron-right breadcum-arrow text-dark'}`}></i>
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}
