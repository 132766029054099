import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TooltipPage } from '../../custom-tooltip/tooltipPage'
import SmallWrongIcon from "./../../../assets/images/svg_icons/small-wrong.svg";
import SearchIcon from "./../../../assets/images/svg_icons/search.svg";
import SearchSecondIcon from "./../../../assets/images/svg_icons/search-two.svg";
import DownloadIcon from "./../../../assets/images/svg_icons/download.svg";
import RupeeIcon from "./../../../assets/images/svg_icons/rupee.svg";
import WrongIcon from "./../../../assets/images/svg_icons/wrong.svg";
import { GET_PROFILE_PRODUCTS } from '../../../app/services/campaigns.service';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { ProductsCardPage } from '../../ad-group-creation-components/products-card';
import NoProductImage from './../../../assets/images/no-product-image.png';

interface Props {
    aiFlowAddedProdcutsData?: (data: any) => void;
    aiFlowAddProdcutsAsinData?: (data: any) => void;
    triggerProductsStepValidation?: any;
    aiFlowGroupsData?: any;
    productsValidationStatus?: (data: boolean) => void;
    handleGroup?: (data: any) => void;
    finalGroupData?: any;
    isGroupData?: any;
}

export const ProductsCardStepPage = (props: Props) => {
    const [search, setSearch] = useState<any>();
    const [showWrongIcon, setShowWrongIcon] = useState<boolean>(false);
    const [showProductsModal, setShowProductsModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState("Newest First");
    const [productsData, setProductsData] = useState<any>();
    const [originalAiProdcutsData, setOriginalAiProdcutsData] = useState<any>();
    const [addProductsData, setAddproductsData] = useState<any>([]);
    const [aiFlowAsinData, setAiFlowAsinData] = useState<any>([]);
    const [aiCampaignProdcutsData, setAICampaignProdcutsData] = useState<any>([]);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [groupProductsData, setGroupProdcutsData] = useState<any>();
    const [groups, setGroups] = useState<any>([
        { id: 1, group_name: '', isError: false, products_data: [] }
    ]);
    const [nextGroupId, setNextGroupId] = useState(2);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const sellerID = localStorage.getItem("seller_id");
    const profileType = localStorage.getItem("type");
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = portfolio_id;
    const [groupID, setGroupID] = useState<any>('');
    const [groupProducts, setGroupProducts] = useState<any>([]);

    const showButtonTextData = [
        {
            id: 1,
            name: "Newest First",
            value: "Newest First",
        },
        {
            id: 2,
            name: "Oldest First",
            value: "Oldest First",
        },
        {
            id: 3,
            name: "Suggested",
            value: "Suggested",
        },
    ];

    useEffect(() => {
        getProfileProducts();
    }, [])

    useEffect(() => {
        if (props.finalGroupData) {
            setGroups([...props.finalGroupData])
        }
    }, [props.finalGroupData])

    if (props.handleGroup) {
        props.handleGroup(groups);
    }

    useEffect(() => {
        if (addProductsData?.length > 0) {
            if (props?.productsValidationStatus) {
                props?.productsValidationStatus(true)
            }
        }
        getProfileProducts();
    }, [props?.triggerProductsStepValidation])

    useEffect(() => {
        if (portfolioId === "2") {
            if (props?.triggerProductsStepValidation?.value) {
                let groupData = props?.isGroupData;
                let filteredData = groupData?.filter((item: any) => item?.products_data?.length === 0);
                if (filteredData?.length > 0) {
                    toast.error(`Please add products to Group ${filteredData[0]?.id} `, { autoClose: 2000 })
                }
            }
        } else if (portfolioId === "1") {
            if (props?.triggerProductsStepValidation?.value) {
                toast.error("Please add atleast one product", { autoClose: 2000 })
            }
        }
    }, [props?.triggerProductsStepValidation])

    const getProfileProducts = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileType === "vendor" ? sellerID : profileID
        }
        setLoading(true);
        GET_PROFILE_PRODUCTS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data.data;
                    setOriginalAiProdcutsData(response)
                    setProductsData(response);
                    if (portfolioId == 1) {
                        const res: any = response;
                        const filteredProducts = res.filter((product: any) => {
                            return product.availability !== "OUT_OF_STOCK";
                        });
                        // for (let i = 0; i < filteredProducts?.length; i++) {
                        //     filteredProducts[i].product_btn_status = true;
                        // }
                        if (filteredProducts) {
                            const aiflowAsinData: any = [];
                            aiFlowAsinData.push(filteredProducts[0]?.asin);
                            setAiFlowAsinData([...aiflowAsinData]);
                            if (props?.aiFlowAddProdcutsAsinData) {
                                props?.aiFlowAddProdcutsAsinData(aiFlowAsinData);
                            }
                        }
                        // if (props?.aiFlowAddedProdcutsData) {
                        //     props?.aiFlowAddedProdcutsData(filteredProducts);
                        // }
                        // setAddproductsData(filteredProducts)
                        // setAICampaignProdcutsData(filteredProducts);
                    }
                    setLoading(false);
                }
            }
        });
    }

    const tooltipContent = (data: any) => {
        if (data === "Products") {
            return (
                <TooltipPage
                    tooltipMainContent={
                        "Add products that you want to promote in this campaign."
                    }
                    tooltipSubContent={""}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        } else if (data === "Add Group") {
            return (
                <TooltipPage
                    tooltipMainContent={"Add Group"}
                    crossMarkClose={true}
                />
            );
        } else if (data === "stock-info") {
            return (
                <TooltipPage
                    tooltipMainContent={`Out of stock products will not usually appear in ads. Products without a featured offer are also considered out of stock. ASINs with regionally fulfilled offers can still be added to campaigns within this tool, although the in-stock status might be incorrectly listed as 'Out of Stock.`}
                    tooltipSubContent={""}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        } else if (data === "add-variation") {
            return (
                <TooltipPage
                    tooltipMainContent={`Add all eligible size, colour or other differences in this product.`}
                    tooltipSubContent={""}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        } else if (data === "ad-paused") {
            return (
                <TooltipPage
                    tooltipHeader={`Ad may be paused`}
                    tooltipMainContent={`Offer must be available for purchase. Being out of stock is a common reason an offer is not available for purchase. Review this information in your product inventory.`}
                    tooltipSubContent={""}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        }
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const handleSearchInput = (e: any) => {
        const searchTerm = e.target.value;
        if (searchTerm?.trim()?.length > 0) {
            const data = productsData
            for (let i = 0; i < originalAiProdcutsData?.length; i++) {
                originalAiProdcutsData[i].dummy_two_title = originalAiProdcutsData[i].title.toLowerCase();
                originalAiProdcutsData[i].dummy_two_asin = originalAiProdcutsData[i].asin.toLowerCase();
                originalAiProdcutsData[i].dummy_two_sku = originalAiProdcutsData[i].sku;
            }
            const searchData = originalAiProdcutsData?.filter((el: any) => el?.dummy_two_title.includes(searchTerm.toLowerCase()) || el?.dummy_two_asin.includes(searchTerm.toLowerCase()) || el?.dummy_two_sku.includes(searchTerm))
            setProductsData(searchData)
        } else {
            setProductsData(originalAiProdcutsData)
            // getProfileProducts();
        }
        setSearch(searchTerm);
        if (searchTerm?.trim()?.length > 0) {
            setShowWrongIcon(true)
        } else if (searchTerm?.trim()?.length <= 0) {
            setShowWrongIcon(false)
        }
    };

    const onClickWrong = () => {
        setSearch("");
        // getProfileProducts();
        setProductsData(originalAiProdcutsData)
        setShowWrongIcon(false);
    };

    const handleButtonTextChange = (newValue: string) => {
        setSelectedValue(newValue);
    };

    const onClickAddProduct = (item: any, index: number) => {
        if (portfolioId) {
            const productData = productsData;
            productData[index].product_btn_status = true;
            setProductsData([...productData]);
            const aiProductsData = aiCampaignProdcutsData;
            aiProductsData.push(item);
            setAddproductsData([...aiProductsData])
            setAICampaignProdcutsData([...aiProductsData]);
            if (props?.aiFlowAddedProdcutsData) {
                props?.aiFlowAddedProdcutsData(aiCampaignProdcutsData)
            }
        }
    }

    const onClickCancelProduct = (item: any, productIndex: number) => {
        if (portfolioId) {
            const data = productsData;
            for (let i = 0; i < data?.length; i++) {
                if (data[i].uuid === item.uuid) {
                    data[i].product_btn_status = false;
                }
            }
            const addedProductsData = addProductsData;
            addedProductsData?.splice(productIndex, 1);
            const addedAiProductsData = aiCampaignProdcutsData;
            addedAiProductsData?.splice(productIndex, 1);
            setAICampaignProdcutsData([...aiCampaignProdcutsData])
            if (props?.aiFlowAddedProdcutsData) {
                props?.aiFlowAddedProdcutsData(aiCampaignProdcutsData)
            }
            setAddproductsData([...addedProductsData])
        }
    }
    const handleQuotationFileUpload = (e: any) => {
        const file = e.target.files[0];
    };



    const addGroup = () => {
        const newGroup = { id: nextGroupId, group_name: "", isError: false, products_data: [] };
        const updatedGroups: any = [...groups, newGroup];
        setGroups(updatedGroups);
        setNextGroupId(nextGroupId + 1);
        if (props.handleGroup) {
            props.handleGroup(updatedGroups);
        }
    };

    const removeGroup = (groupId: any) => {
        if (props?.finalGroupData) {
            let updatedData = props?.finalGroupData?.filter((item: any) => {
                return item.id !== groupId
            })
            setGroups(updatedData)
            if (props?.aiFlowGroupsData) {
                props?.aiFlowGroupsData(updatedData)
            }
        }
        else {
            setGroups(groups.filter((group: any) => group.id !== groupId));
        }
    };

    const onAddProductsToGroups = (index: number, groupId: any, groupData: any) => {
        setGroupID(groupId);
        setGroupProducts(groupData.products_data);
        if (groupData.group_name) {
            setShowProductsModal(true);
        }
        const prepareData = groups;

        for (let i = 0; i < prepareData?.length; i++) {
            if (prepareData[i].group_name === '') {
                prepareData[i].isError = true
            }
            else {
                prepareData[i].isError = false
            }
            setGroups([...prepareData]);
        }

        if (prepareData[index].products_data?.length > 0) {
            setGroupProdcutsData(prepareData[index].products_data);
        } else {
            setGroupProdcutsData([]);
        }
    }

    const onClickAddProductstoGroup = () => {
        if (groupProductsData?.length === 0) {
            toast.error("Please add atleast one product", { autoClose: 2000 })
        }
        else {
            let preparedData = [];
            for (let i = 0; i < groups?.length; i++) {
                preparedData.push({
                    id: groups[i].id,
                    group_name: groups[i].group_name,
                    products_data: groups[i].products_data?.length > 0 ? groups[i].products_data : getPreparedProducts(groups[i].id),
                    keywords_data: []
                })
            }
            if (props?.aiFlowGroupsData) {
                props?.aiFlowGroupsData(preparedData)
            }
            if (props.handleGroup) {
                props.handleGroup(preparedData);
            }
            if (productsData?.length > 0) {
                setShowProductsModal(false);
            }
            setGroups([...preparedData]);
        }
    }


    const getPreparedProducts = (id: any) => {
        const data = [];
        for (let i = 0; i < groupProductsData?.length; i++) {
            if (groupProductsData[i].groupId === id) {
                data.push(groupProductsData[i]);
            }
        }
        return data;
    }

    const getGroupProductsData = (data: any) => {
        setGroupProdcutsData(data);
    }

    const onChangeGroup = (e: any, index: any) => {
        const data = groups;
        data[index].group_name = e.target.value;
        setGroups(data);
    }

    return (
        <div style={{ height: "auto", overflowY: "auto" }}>
            {portfolioId == 2 && (
                <div className="products-card-container card-width mx-auto mb-3" style={{ marginTop: "-45px" }}>
                    <div className="d-flex justify-content-end " style={{ position: "relative", left: "7%", top: "55px" }}>
                        <Tooltip title={tooltipContent("Add Group")} placement='top' arrow className="custom-tooltip-position cursor-pointer">
                            <button className='request-call-button text-white fs-20 cursor-pointer' style={{ borderRadius: "50%", padding: "8px 14px" }} onClick={addGroup}><i className="bi bi-plus text-white fs-21"></i></button>
                        </Tooltip>
                    </div>
                    {groups?.map((groupData: any, index: number) => (
                        <div className='' key={index}>
                            <div className="card text-bg-white mb-3 my-2" >
                                <div className="card-header bg-white"><div className='fs-24 fw-light'>Group {groupData.id}</div>
                                </div>
                                <div className="card-body py-0">
                                    <div className='d-flex justify-content-between'>
                                        <div className="fs-13 my-3 w-30">
                                            <label className='form-label'>Group name</label>
                                            <input type="text" className="form-control border-dark input fs-13" placeholder='Please enter group name' onChange={(e) => onChangeGroup(e, index)} defaultValue={groupData.group_name} />
                                            {groupData.isError && <span className='text-danger'>please enter the group name</span>}
                                        </div>
                                        <div className="fs-13 mt-4 pt-1">
                                            <button className='request-call-button text-white mt-3' onClick={() => onAddProductsToGroups(index, groupData?.id, groupData)}>Add products in to group</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index !== 0 && (
                                <div className='d-flex justify-content-end delete-button-position'>
                                    <button className="bg-transparent border-0 cursor-pointer"
                                        onClick={() => removeGroup(groupData?.id)}>
                                        <i className="bi bi-trash text-danger fs-4" ></i>
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {portfolioId == 1 && (
                <>
                    <div className="products-card-container products-card mx-auto mb-3">
                        <div className="card text-bg-white mb-3 my-2">
                            <div className="card-header bg-white">
                                <div className="d-flex justify-content-between py-2">
                                    <div className="fs-24 fw-light">
                                        Products
                                        <Tooltip
                                            title={tooltipContent("Products")}
                                            placement="top"
                                            arrow
                                            className="custom-tooltip-position cursor-pointer"
                                        >
                                            <span className="ms-1">
                                                <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle mb-2" data-fa-i2svg=""
                                                >
                                                    <path
                                                        fill="#8290a4"
                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                                        <span>
                                            <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                        </span>
                                        How to add products to your campaign
                                    </div>
                                </div>
                            </div>
                            <div className="card-body py-0">
                                <div className="row text-center px-0">
                                    <div className="col-6 bg-white products-tabs-section border-right py-0">
                                        <div className="tabs-section px-0">
                                            <div className="nav nav-underline" id="nav-tab" role="tablist">
                                                <button
                                                    className="nav-link text-dark active fs-13"
                                                    id="nav-home-tab" data-bs-toggle="tab"
                                                    data-bs-target="#nav-home" type="button" role="tab"
                                                    aria-controls="nav-home" aria-selected="true"
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div
                                                className="tab-pane fade show active search-tab"
                                                id="nav-home"
                                                role="tabpanel"
                                                aria-labelledby="nav-home-tab"
                                                tab-index="0"
                                            >
                                                <div className="row border-top border-bottom">
                                                    <div className="col-12 px-2">
                                                        <div className="input-group my-2">
                                                            <span className="input-group-text  bg-white border-dark"><img src={SearchIcon} alt="" className="" /></span>
                                                            <input type="text" name="search" className={`form-control border-dark  border-start-0 px-0 fs-13  ${showWrongIcon ? "border-end-0" : "rounded-end"}`} placeholder="Search by product name, ASIN or SKU"
                                                                value={search} onChange={handleSearchInput} />
                                                            {showWrongIcon && (
                                                                <span className="input-group-text  bg-white border-dark rounded-start-0 rounded-end">
                                                                    <img src={SmallWrongIcon} alt="" className="cursor-pointer fs-13" onClick={onClickWrong} />
                                                                </span>
                                                            )}
                                                            <span className="input-group-text  border-dark border-0 ms-1 search-button rounded-end-pill cursor-pointer">
                                                                <img src={SearchSecondIcon} alt="" className={``} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row border-bottom d-none">
                                                    <div className="col-12 px-0 my-2 fw-bold text-start ms-2 fs-13">
                                                        <div className="d-flex">
                                                            <div>Show :</div>
                                                            <div className="w-25 ms-2">
                                                                <div className="btn-group">
                                                                    <button
                                                                        className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                                                        type="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {selectedValue}{" "}
                                                                        <span>
                                                                            <svg viewBox="0 0 389 512" width="13" height="13" className="fas fa-angle-down show-text-icon" data-fa-i2svg="" >
                                                                                <path fill="currentColor"
                                                                                    d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                    <ul className="dropdown-menu newest-first-dropdown">
                                                                        <li className="fs-13 py-1">
                                                                            {showButtonTextData?.map(
                                                                                (item: any, indexNumber: number) => (
                                                                                    <button
                                                                                        key={indexNumber}
                                                                                        className={`drop-down-item border-0 bg-transparent text-center py-1 ${selectedValue === item?.value ? "active" : ""}`}
                                                                                        value={item.value} onClick={() => handleButtonTextChange(item?.value)}>
                                                                                        {item.name}
                                                                                    </button>
                                                                                )
                                                                            )}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {loading &&
                                                    <div className="text-center p-5">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    </div>
                                                }
                                                {!loading &&
                                                    <div className={`products_scroll  products_scroll_height products_scroll_ai_height ${productsData?.length <= 3 ? "less-prodcuts" : "pe-2"}`}>
                                                        {productsData?.length > 0 && productsData?.map((item: any, itemIndex: number) => (
                                                            <div className={`row py-2 border-bottom ${item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "prodcuts-gray-out-bg pe-1" : ""} `} >
                                                                <div className="col-2 px-0">
                                                                    <img src={item?.image_url ? item?.image_url : NoProductImage} alt="" style={{ width: '70px', height: '70px', objectFit: "contain" }} />
                                                                </div>
                                                                <div className="col-7 px-0 text-start">
                                                                    <div className="product-header">
                                                                        <a>{item?.title}</a>
                                                                    </div>
                                                                    <div>
                                                                        <span className="amount-info">
                                                                            <span className="">
                                                                                <img src={RupeeIcon} alt="" className="" />
                                                                            </span>
                                                                            {item?.price_to_pay?.amount}
                                                                        </span> |
                                                                        <span className="stock-info">
                                                                            {item?.availability === "OUT_OF_STOCK" && (
                                                                                <Tooltip
                                                                                    title={tooltipContent("stock-info")}
                                                                                    placement="top"
                                                                                    arrow
                                                                                    className="custom-tooltip-position cursor-pointer "
                                                                                >
                                                                                    <span className="ms-1">
                                                                                        <svg
                                                                                            viewBox="0 0 512 512"
                                                                                            width="12"
                                                                                            height="12"
                                                                                            className="fas fa-info-circle mb-1"
                                                                                            data-fa-i2svg=""
                                                                                        >
                                                                                            <path
                                                                                                fill="#8290a4"
                                                                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                            ></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            )}
                                                                            <span className={`px-1 stock-capitalize ${item?.availability === "IN_STOCK" ? "text-secondary text-capitalize" : ""}`}>{item?.availability?.replace(/_/g, " ")}</span>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="product-details">ASIN: {item?.asin}</span>
                                                                        <span> | </span>
                                                                        <span className="product-details">SKU: {item?.sku}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 ps-0">
                                                                    <div className="text-end">
                                                                        {item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "" :
                                                                            <button disabled={item?.product_btn_status} style={{ padding: '3px 10px' }} onClick={() => onClickAddProduct(item, itemIndex)}
                                                                                className={`btn-dark border-0 rounded-pill custom-button-background fs-13 ${!item?.product_btn_status ? 'cursor-pointer text-dark' : 'cursor-pointer-not-allowed keyword-below-text-color'}`}
                                                                            >
                                                                                {!item?.product_btn_status ? 'Add' : 'Added'}
                                                                            </button>
                                                                        }
                                                                    </div>

                                                                    <div className={`pt-1 ${item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "pt-3" : ""}`}>
                                                                        <span className="add-varaiation-label">Add variations</span>
                                                                        <span>
                                                                            <Tooltip
                                                                                title={tooltipContent("add-variation")}
                                                                                placement="top"
                                                                                arrow
                                                                                className="custom-tooltip-position cursor-pointer "
                                                                            >
                                                                                <span className="ms-1">
                                                                                    <svg
                                                                                        viewBox="0 0 512 512"
                                                                                        width="12"
                                                                                        height="12"
                                                                                        className="fas fa-info-circle mb-1"
                                                                                        data-fa-i2svg=""
                                                                                    >
                                                                                        <path
                                                                                            fill="#8290a4"
                                                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </Tooltip>
                                                                        </span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <Tooltip
                                                                            title={tooltipContent("ad-paused")}
                                                                            placement="top"
                                                                            arrow
                                                                            className="custom-tooltip-position cursor-pointer "
                                                                        >
                                                                            <span className="ms-1">
                                                                                <i className="bi bi-exclamation-triangle-fill"></i>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        )}
                                                        {productsData?.length == 0 && (
                                                            <div className="col-12 fs-13 my-4 pt-1">
                                                                No results. Searches are case-sensitive. Enter the
                                                                exact book title or ASIN, or try another search
                                                                term.
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                            <div
                                                className="tab-pane fade enterlist-tab"
                                                id="nav-profile"
                                                role="tabpanel"
                                                aria-labelledby="nav-profile-tab"
                                                tab-index="0"
                                            >
                                                <div className="row border-top">
                                                    <div className="my-3">
                                                        <textarea
                                                            name="text-two"
                                                            rows={6} defaultValue={''}
                                                            className="form-control fs-14 "
                                                            placeholder="Enter ASINs separated by a comma, space or new line."
                                                        ></textarea>
                                                    </div>
                                                    <div className=" text-end">
                                                        <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade upload-tab"
                                                id="nav-contact"
                                                role="tabpanel"
                                                aria-labelledby="nav-contact-tab"
                                                tab-index="0"
                                            >
                                                <div className="row border-top">
                                                    <div className="my-3 fs-13 text-start">
                                                        Use the template to make sure your information
                                                        uploads accurately.
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                                            <span className="me-2">
                                                                <img src={DownloadIcon} alt="" />
                                                            </span>
                                                            Download the CSV template
                                                        </button>
                                                    </div>
                                                    <div className="upload-files">
                                                        <div
                                                            data-takt-id="ups_upload_asins_input"
                                                            className="upload-icon fs-13"
                                                        >
                                                            <div className="mt-4 pt-2">
                                                                <label
                                                                    htmlFor="input_file_three"
                                                                    className="cursor-pointer"
                                                                >
                                                                    <div className="">
                                                                        <div>
                                                                            <i className="bi bi-upload"></i>
                                                                        </div>
                                                                        <div className="fs-13 my-1">
                                                                            Drag and drop file to add
                                                                        </div>
                                                                        <div className="fs-13 my-1">
                                                                            or click to select from your computer
                                                                        </div>
                                                                        <div className="fs-13 my-1">
                                                                            Accepted formats: CSV, TSV, XLSX
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    id="input_file_three"
                                                                    name="file"
                                                                    className="d-none "
                                                                    onChange={handleQuotationFileUpload}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 product-preview-container">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="fs-13 text-start fw-bold py-2">
                                                    {addProductsData?.length} product{addProductsData?.length > 1 || addProductsData?.length === 0 ? "s" : ""}
                                                </div>
                                            </div>
                                            <div className="col-8"></div>
                                        </div>
                                        {(addProductsData?.length === 0) && <div className="fs-13 add-products-text-color">
                                            Add Products
                                        </div>}
                                        <div className='added-prodcuts_scroll' style={{ height: '460px', overflowY: "auto" }}>
                                            {addProductsData?.map((item: any, productIndex: number) => (
                                                <div className="row py-2 border-bottom">
                                                    <div className="col-2 px-0">
                                                        <img src={item?.image_url ? item?.image_url : NoProductImage} alt="" style={{ width: '70px', height: '70px', borderRadius: "10%", objectFit: "contain" }} />
                                                    </div>
                                                    <div className="col-7 px-0 text-start">
                                                        <div className="product-header">
                                                            <a>{item.title}</a>
                                                        </div>
                                                        <div>
                                                            <span className="amount-info">
                                                                <span className="">
                                                                    <img src={RupeeIcon} alt="" className="" />
                                                                </span>
                                                                {item?.price_to_pay?.amount}
                                                            </span> |
                                                            <span className="stock-info">
                                                                {item?.availability === "OUT_OF_STOCK" && (
                                                                    <Tooltip
                                                                        title={tooltipContent("stock-info")}
                                                                        placement="top"
                                                                        arrow
                                                                        className="custom-tooltip-position cursor-pointer "
                                                                    >
                                                                        <span className="ms-1">
                                                                            <svg
                                                                                viewBox="0 0 512 512"
                                                                                width="12"
                                                                                height="12"
                                                                                className="fas fa-info-circle mb-1"
                                                                                data-fa-i2svg=""
                                                                            >
                                                                                <path
                                                                                    fill="#8290a4"
                                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                                <span className={`px-1 text-capitalize ${item?.availability === "IN_STOCK" ? "text-secondary" : ""}`}>{item?.availability?.replace(/_/g, " ")}</span>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="product-details">ASIN: {item?.asin}</span>
                                                            <span> | </span>
                                                            <span className="product-details">SKU: {item?.sku}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 ps-0">
                                                        <div className="text-end">
                                                            <div className="bg-transaprent border-0">
                                                                <img
                                                                    src={WrongIcon} alt=""
                                                                    className="mt-1 cursor-pointer"
                                                                    onClick={() => onClickCancelProduct(item, productIndex)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="text-end d-none">
                                                            <Tooltip
                                                                title={tooltipContent("ad-paused")}
                                                                placement="top"
                                                                arrow
                                                                className="custom-tooltip-position cursor-pointer "
                                                            >
                                                                <span className="ms-1">
                                                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showProductsModal && (
                <div className={`modal modal-custom fade ${showProductsModal ? "show" : "false"}  ${showProductsModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered mx-auto mt-4 pt-4" role="document" style={{ maxWidth: '65%' }} >
                        <div className="modal-content mx-auto">
                            <div className="modal-header">
                                <h5 className="modal-title portfolio-title fs-17">Add products to group</h5>
                                <button type="button" className="btn-close" style={{ fontSize: 'x-small' }} data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowProductsModal(false)}></button>
                            </div>
                            <div className='modal-body negative-keyword-body negative-keyword-body-ads_modal py-0'>
                                <ProductsCardPage linkStatus={true} addedGroupsProductsData={(e) => getGroupProductsData(e)} groupsData={groupProductsData} groupId={groupID} groupProducts={groupProducts} />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setShowProductsModal(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className={`rounded-pill border-0 fs-13 request-call-button text-white`}
                                    onClick={onClickAddProductstoGroup}>
                                    Add products to group
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    )
}
