import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { AdgroupsAdsData, AdgroupsSettingsData, AiCampaignLoadingData, CampaignBiddingCampaignDetailsData, CampaignData, CampaignSettingsData, LoadingReducer, ProfileData, UserData, UserUuidData } from "../user-data/reducer";

export const store = createStore(
  combineReducers({
    LoadingReducer: LoadingReducer,
    UserDataReducer: UserData,
    UserUuidDataReducer: UserUuidData,
    ProfileDataReducer: ProfileData,
    CampaignDataReducer: CampaignData,
    AdGroupAdsDataReducer: AdgroupsAdsData,
    AdGroupSettingsDataReducer: AdgroupsSettingsData,
    CampaignBiddingCampaignDetailsDataReducer: CampaignBiddingCampaignDetailsData,
    AiCampaignLoadingDataRedcucer: AiCampaignLoadingData,
    CampaignSettingsDataReducer: CampaignSettingsData,

  }),
  applyMiddleware(thunk)
);

