import React from 'react'
import { Routes, Route } from "react-router-dom";
import { AdgroupAdsPage } from '../pages/Dashboard/campaigns/ad-groups/ads/adgroup-ads';
import { TargetingsPage } from '../pages/Dashboard/campaigns/ad-groups/targetings/targetings';
import { NegativeTargetingsPage } from '../pages/Dashboard/campaigns/ad-groups/negative-targetings/negative-targetings';
import { SearchTermsPage } from '../pages/Dashboard/campaigns/ad-groups/search-terms/search-terms';
import { AdGroupSettingsPage } from '../pages/Dashboard/campaigns/ad-groups/ad-group-settings/ad-group-settings';
import { AdGroupHistoryPage } from '../pages/Dashboard/campaigns/ad-groups/history/history';

export const AdGroupInfoRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<AdgroupAdsPage />}></Route>
                <Route path="/ads" element={<AdgroupAdsPage />}></Route>
                <Route path="/targeting" element={<TargetingsPage />}></Route>
                <Route path="/negative-targeting" element={<NegativeTargetingsPage />}></Route>
                <Route path="/search-terms" element={<SearchTermsPage />}></Route>
                <Route path="/ad-group-settings" element={<AdGroupSettingsPage />}></Route>
                <Route path="/history" element={<AdGroupHistoryPage />}></Route>
            </Routes>
        </div>
    )
}
