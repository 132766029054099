import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react'
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../custom-tooltip/tooltipPage';
import { DataTable } from '../data-table';
import { KeywordTargetingDataGridCols } from '../../pages/Dashboard/campaign-type/keyword-targeting-data-grid-cols';
import DownloadIcon from "./../../assets/images/svg_icons/download.svg";
import { KeywordTargetingTwoDataGridCols } from '../../pages/Dashboard/campaign-type/keyword-targeting-data-grid-cols-two';
import { GET_KEYWORD_SUGGESTIONS } from '../../app/services/campaigns.service';
import { toast } from "react-toastify";
import RupeeIcon from "./../../assets/images/svg_icons/rupee.svg";

interface Props {
    adGroupkeywordTargeting?: boolean;
    getData?: any;
    addedKeywordTargetingDataAdGroup?: (data: any) => void;
    suggestedDefaultBid?: (data: any) => void;
    adGroupValidationStatus?: any;
    validateAdGroup?: () => boolean;
    validateKeyword?: () => boolean;
    altenativeBidKeywordsData?: (data: any) => void;
    alternateBidStatus?: (data: any) => void;
    adsProductAsinData?: any;
    addedProductAsinData?: any;
    addedKeywordData?: (data: any) => void;
    alternateBiddingData?: any;
    keywordflowTargetingBidData?: any;
    addedAdGroupAsinData?: any;
    suggestedKeywordsScroll?: any;
    selcectedAdgroupKeywordTargetingBidValue?: (data: any) => void;
}

export const KeywordTargetingCard = forwardRef((props: Props, ref) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [addedKeywordTargetingData, setAddedKeywordTargetingData] = useState<any>([]);
    const [keywordTargetingData, setKeywordTargetingData] = useState<any>();
    const [selectedKeyWordTargetingBidValue, setSelectedKeywordTargetingBidValue] = useState<any>("Suggested bid");
    const [selectedKeyWordTargetingSortingValue, setSelectedKeywordTargetingSortingValue,] = useState<any>("Orders");
    const [adsProductsAsinData, setAdsProductsAsinData] = useState<any>([])
    const [addedProdcutsAsinData, setAddedProdcutsAsinData] = useState<any>([]);
    const [keywordTargetingBidValue, setKeywordTargetingBidValue] = useState<any>()
    const [keywordTextStatus, setKeywordTextStatus] = useState<boolean>(false);
    const [adGroupCheckBoxes, setAdGroupCheckBoxes] = useState<any>({
        Broad: true,
        Exact: true,
        Phrase: true,
    });
    const [originalKeywordTargetingData, setOriginalKeywordTargetingData] = useState<any>();
    const [successMessage, setSuccessMessage] = useState('');
    const [showAlternateBidModal, setShowAlternateBidModal] = useState<boolean>(false);
    const [addedAdGroupCeationProdcutsData, setAddedAdGroupCeationProdcutsData] = useState<any>([]);
    const [alternateBidInputData, setAlternateBidInputData] = useState<boolean>(true);
    const [alternateBidStatus, setAlternateBidStatus] = useState<boolean>(false)
    const [keywordData, setKeywordData] = useState<any>([]);
    const [alternateBidData, setAlternateBidData] = useState<any>({
        alternate_bid: ""
    })
    const [manualKeywordText, setManualKeywordText] = useState<any>({
        keyword_text: ""
    })
    const [formErrors, setFormErrors] = useState<any>({});
    const [suggestedBidValue, setSuggestedBidValue] = useState<any>({
        default_bid: "3",
        add_group_default_bid: "3",
        add_group_custom_bid: "3"
    })
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const propsGetData = props?.getData;

    const [externalListCheckboxes, setExternalListCheckboxes] = useState<any>({
        Broad: true,
        Exact: true,
        Phrase: true,
    });

    const keywordTargetingBidData = [
        {
            name: "Suggested bid",
            sub_title: `Based on past bidding activity to predict bids that are more likely to win`,
            value: "Suggested bid",
        },
        {
            name: "Custom bid",
            sub_title: `Can custom bids for each keyword without overriding your set bid amount`,
            value: "Custom bid",
        },
        {
            name: "Default bid",
            sub_title: `Can raise or lower all your set amounts in bulk`,
            value: "Default bid",
        },
    ];

    const keywordTargetingSortData = [
        {
            name: "Orders",
            value: "Orders",
        },
        {
            name: "Clicks",
            value: "Clicks",
        },
    ];

    useEffect(() => {
        if (propsGetData?.data?.status) {
            getKeywordTargetingData(propsGetData?.data.asinData, selectedKeyWordTargetingSortingValue);
        }
    }, [propsGetData]);

    useEffect(() => {
        if (props?.adsProductAsinData?.length > 0) {
            setAdsProductsAsinData(props?.adsProductAsinData)
            getKeywordTargetingData(props?.adsProductAsinData, selectedKeyWordTargetingSortingValue);
        }
        else if (props?.adsProductAsinData?.length <= 0) {
            toast.info("Please add keywords through enter list", { autoClose: 2000 })
        }
    }, [props?.adsProductAsinData]);

    useEffect(() => {
        if (props?.addedProductAsinData?.length <= 0) {
            setKeywordTargetingData([]);
        }
        else {
            if (props?.addedProductAsinData?.length > 0) {
                setAddedProdcutsAsinData(props?.addedProductAsinData)
            }
        }
    }, [props?.addedProductAsinData])

    useEffect(() => {
        if (props?.addedAdGroupAsinData?.length > 0) {
            setAddedAdGroupCeationProdcutsData(props?.addedAdGroupAsinData)
        }
    }, [props?.addedAdGroupAsinData])

    const getKeywordTargetingData = (asinData: any, selectedKeyWordTargetingSortingValue: any) => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            product_asin: asinData ? asinData : [],
            bidding_strategy: "LEGACY_FOR_SALES",
            sortDimension: selectedKeyWordTargetingSortingValue === "Orders" ? "CONVERSIONS" : "CLICKS"
        }
        setLoading(true);
        GET_KEYWORD_SUGGESTIONS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data.data;
                    setOriginalKeywordTargetingData(response)
                    setKeywordTargetingData(response);
                    setLoading(false);
                }
            }
        });
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const handleQuotationFileUpload = (e: any) => {
        const file = e.target.files[0];
    };

    const tooltipContent = (tooltipTitle: string) => {
        if (tooltipTitle === "Keyword targeting card header") {
            return (
                <TooltipPage
                    tooltipMainContent={"Your keywords (word combinations and phrases) are used to match your ads with search terms shoppers are using to find products."}
                    tooltipMainContentCaption={"See keyword targeting best practices"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "keyword suggested tab") {
            return (
                <TooltipPage
                    tooltipMainContent={`Related keywords are based on the products in your ads.
                    You can add the keywords that you want and edit keywords and bids after you add them.`}
                    tooltipSubContent={"Learn about Suggested keywords"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "keyword suggested tab Bid") {
            return (
                <TooltipPage
                    tooltipMainContent={`Your bid is the cost per click you are willing to pay when a customer
                    clicks an ad from the ad group or campaign.`}
                    tooltipSubContent={"Learn about bids"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "keyword suggested tab Filter by") {
            return (
                <TooltipPage
                    tooltipMainContent={`Match types allow you to fine-tune which customer search terms 
                    trigger your ads.`}
                    tooltipSubContent={"Learn about match types"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        } else if (tooltipTitle === "keyword suggested tab Sort By") {
            return (
                <TooltipPage
                    sortOrders={"Number of orders the keyword has historically generated"}
                    sortClicks={"Number of clicks the keyword has historically generated"}
                    tooltipCloseStatus={onCloseTooltip}
                />
            );
        }
    }

    const handleKeywordTargetingBidTextChange = (option: string) => {
        setSelectedKeywordTargetingBidValue(option);
    };

    const handleKeywordTargetingSortingChange = (option: string) => {
        getKeywordTargetingData(addedAdGroupCeationProdcutsData, option);
        setSelectedKeywordTargetingSortingValue(option);
    };

    const onClickAddKeywordTargetingData = (data: any, i: number, item: any, index: number) => {
        const status = data.bidInfo[index].btn_status
        data.bidInfo[index].btn_status = true;
        data.bidInfo[index].bid_type = selectedKeyWordTargetingBidValue

        if (!status) {
            const targetinData: any = {
                bidData: item,
                recId: data.recId,
                bid_input_update_value: selectedKeyWordTargetingBidValue === "Custom bid" ? suggestedBidValue?.add_group_custom_bid ? suggestedBidValue?.add_group_custom_bid : "3" : selectedKeyWordTargetingBidValue === "Default bid" ? suggestedBidValue?.add_group_default_bid ? suggestedBidValue?.add_group_default_bid : "3" : "",
                suggestionData: data?.keyword
            }
            addedKeywordTargetingData.push(targetinData);
            setAddedKeywordTargetingData([...addedKeywordTargetingData]);
            if (props?.addedKeywordTargetingDataAdGroup) {
                props?.addedKeywordTargetingDataAdGroup(addedKeywordTargetingData)
            }
            if (props?.addedKeywordData) {
                props?.addedKeywordData(addedKeywordTargetingData)
            }
        }
    }
    const onClickCancelKeywordTargeting = (data: any, index: number) => {
        const keywordData = addedKeywordTargetingData
        for (let i = 0; i < keywordData?.length; i++) {
            if (keywordData[i]?.bidData?.matchType === data?.bidData?.matchType) {
                keywordData[i].bidData.btn_status = false;
            }
        }
        const preapredData: any = addedKeywordTargetingData;
        preapredData.splice(index, 1);
        setKeywordData([...preapredData]);
        setAddedKeywordTargetingData([...preapredData]);
        if (props?.addedKeywordData) {
            props?.addedKeywordData(addedKeywordTargetingData)
        }
    }

    const handleAdGroupCheckboxChange = (checkboxName: any) => {
        let adGroupCheckesData: any = []
        setAdGroupCheckBoxes((prevCheckboxes: any) => {
            const updatedAdGroupCheckboxes = {
                ...prevCheckboxes,
                [checkboxName]: !prevCheckboxes[checkboxName],
            };
            adGroupCheckesData = Object.keys(updatedAdGroupCheckboxes).filter(key => updatedAdGroupCheckboxes[key]);
            let newAdGroupsChecksData = adGroupCheckesData.map((str: any) => str.toUpperCase());
            const updatedData = originalKeywordTargetingData?.map((keywordData: any) => {
                const updatedBidInfo = keywordData.bidInfo.filter((bid: any) => newAdGroupsChecksData.includes(bid.matchType));
                if (updatedBidInfo?.length > 0) {
                    return {
                        ...keywordData,
                        bidInfo: updatedBidInfo,
                    };
                }
            });
            setLoading(true);
            setTimeout(() => {
                setKeywordTargetingData(adGroupCheckesData?.length > 0 ? updatedData : [])
                setLoading(false);
            }, 1);
            return updatedAdGroupCheckboxes;
        });
    };

    const onClickRemoveAllData = () => {
        const keywordData = addedKeywordTargetingData;
        for (let i = 0; i < keywordData?.length; i++) {
            keywordData[i].bidData.btn_status = false;
        }
        const keywordDataTwo = keywordTargetingData;
        for (let i = 0; i < keywordDataTwo?.length; i++) {
            for (let j = 0; j < keywordDataTwo[i]?.bidInfo?.length; j++) {
                keywordDataTwo[i].bidInfo[j].btn_status = false;
            }
        }
        setAddedKeywordTargetingData([]);
    }

    const handleChangeInputValue = (e: any, index: number, data: any) => {
        const { name, value } = e.target;
        const keywordData = addedKeywordTargetingData?.map((negativeData: any, i: number) => {
            if (i === index) {
                return {
                    ...negativeData,
                    bidData: {
                        ...negativeData.bidData,
                        bidInputValue: value,
                    }
                }
            }
            return negativeData;
        })
        setKeywordTargetingBidValue(keywordData)
    }

    const hadnleInput = (e: any) => {
        const { name, value } = e.target;
        setSuggestedBidValue((prevData: any) => ({
            ...prevData,
            [name]: value
        }));

        const isValid = validateForm();

        if (isValid) {
            if (props.suggestedDefaultBid) {
                props?.suggestedDefaultBid(suggestedBidValue);
            }
        } else {
            setFormErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: validateSpecificFeild(name, value),
            }));
        }
    }

    const validateForm = () => {
        const errors: any = {};
        if (selectedKeyWordTargetingBidValue === "Default bid") {
            if (!suggestedBidValue?.default_bid) {
                errors.default_bid = "Please enter a bid"
            }
        }
        setFormErrors(errors);
        return Object.keys(errors)?.length === 0;
    }

    useImperativeHandle(ref, () => ({
        validateForm,
    }));

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "add_group_custom_bid") {
            if (!value) {
                return "Please enter custom bid"
            } else if (value <= 0) {
                return "Custom bid should be minimum 1."
            }
        }

        if (name === "add_group_default_bid") {
            if (!value) {
                return "Please enter default bid"
            } else if (value <= 0) {
                return "Default bid should be minimum 1."
            }
        }

        if (name === "alternate_bid") {
            if (!value) {
                return "Please enter bid value"
            } else if (value <= 0) {
                return "Bid value should be minimum 1."
            }
        }
        return "";
    }

    const noDataMessage = () => {
        return (
            <div className="d-flex flex-column justify-content-center my-1">
                <div className="fs-12 fw-bold">No related keywords</div>
                <div className="fs-12 ">Try entering your own keywords</div>
            </div>
        );
    };

    const onClickAddManualKeywordData = () => {
        setShowAlternateBidModal(true);
    }

    const handleKeywordInput = (e: any) => {
        const { name, value } = e.target;
        setManualKeywordText((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        const data = e.target.value;
        if (data?.trim()?.length > 0) {
            setKeywordTextStatus(true);
        } else {
            setKeywordTextStatus(false);
        }
    }

    const handleAlternateInput = (e: any) => {
        const { name, value } = e.target;
        setAlternateBidData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        const data = e.target.value;
        if (data === "") {
            setAlternateBidInputData(false);
        } else {
            setAlternateBidInputData(true);
        }
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateAlternateBidForm = () => {
        const errors: any = {};
        if (!alternateBidData.alternate_bid) {
            errors.alternate_bid = "Please enter bid value"
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onAddAlternateBid = () => {
        const isValid = validateAlternateBidForm();
        if (isValid) {
            setAlternateBidStatus(true)
            const splitData = manualKeywordText?.keyword_text.split('\n').filter((line: any) => line.trim() !== '');
            console.log('externalListCheckboxes', externalListCheckboxes);

            for (const property in externalListCheckboxes) {
                if (externalListCheckboxes[property]) {
                    splitData.forEach((element: any) => {
                        keywordData.push({
                            bidData: {
                                matchType: property?.toUpperCase(),
                                bid: alternateBidData?.alternate_bid,
                            },
                            bid: alternateBidData?.alternate_bid,
                            suggestionData: element,//aiFlowEnterListKeywordTextData?.keyword_text,
                        })
                    })
                }
            }
            // const exactData = {
            //     bidData: {
            //         matchType: "Exact",
            //         bid: alternateBidData?.alternate_bid,
            //     },
            //     suggestionData: manualKeywordText?.keyword_text
            // }
            // const phraseData = {
            //     bidData: {
            //         matchType: "Phrase",
            //         bid: alternateBidData?.alternate_bid,
            //     },
            //     suggestionData: manualKeywordText?.keyword_text,
            // }
            // const broadData = {
            //     bidData: {
            //         matchType: "Broad",
            //         bid: alternateBidData?.alternate_bid,
            //     },
            //     suggestionData: manualKeywordText?.keyword_text,
            // }
            // keywordData.push(exactData, phraseData, broadData)
            setKeywordData([...keywordData])
            setAddedKeywordTargetingData([...keywordData])
            setSuccessMessage('3/3 keywords were added successfully.');
            setShowAlternateBidModal(false);
            setManualKeywordText({ ...manualKeywordText, keyword_text: "" });
            setAlternateBidData({ ...alternateBidData, alternate_bid: "" })
            if (props?.altenativeBidKeywordsData) {
                props?.altenativeBidKeywordsData(keywordData)
            }
            if (props?.alternateBidStatus) {
                props?.alternateBidStatus(alternateBidStatus)
            }
        }
    }

    const getAddAllDataStatus = (status: any) => {
        const data = keywordTargetingData;
        for (let i = 0; i < data?.length; i++) {
            const element = data[i];
            for (let j = 0; j < element?.bidInfo?.length; j++) {
                element.bidInfo[j].btn_status = true
            }
        }
        setKeywordTargetingData(data);
        const preparedData = [];
        if (status) {
            for (let i = 0; i < originalKeywordTargetingData?.length; i++) {
                for (let j = 0; j < originalKeywordTargetingData[i].bidInfo?.length; j++) {
                    preparedData.push({
                        bid: originalKeywordTargetingData[i].bidInfo[j].bid ? (originalKeywordTargetingData[i].bidInfo[j].bid / 100).toFixed(2) : '',
                        bidData: {
                            bid: originalKeywordTargetingData[i].bidInfo[j].bid,
                            bidInputValue: originalKeywordTargetingData[i].bidInfo[j].bid ? (originalKeywordTargetingData[i].bidInfo[j].bid / 100).toFixed(2) : '',
                            matchType: originalKeywordTargetingData[i].bidInfo[j].matchType,
                            rank: originalKeywordTargetingData[i].bidInfo[j].rank,
                            suggestedBid: originalKeywordTargetingData[i].bidInfo[j].suggestedBid,
                            theme: originalKeywordTargetingData[i].bidInfo[j].theme,
                        },
                        keywordText: originalKeywordTargetingData[i].keyword,
                        keyword_matchType: originalKeywordTargetingData[i].bidInfo[j].matchType,
                        keyword_state: "ENABLED",
                        product_uuid: "",
                        recId: originalKeywordTargetingData[i].recId,
                        suggestionData: originalKeywordTargetingData[i].keyword
                    })
                }
            }
            setAddedKeywordTargetingData([...preparedData]);
        }
    }

    const handleEnterlistCheckBoxChange = (e: any) => {
        const data = externalListCheckboxes;
        data[e.target.name] = e.target.checked;
        const checkedLength = Object.values(data).filter(value => value === true).length;

        setSuccessMessage(`${checkedLength}/3 keywords were added successfully.`);
        setExternalListCheckboxes({ ...data });
    }

    return (
        <div className={`keyword-targeting-card-container card-width mx-auto my-0 `}>
            <div className={`card text-bg-white ad-group-settings  ${props?.adGroupkeywordTargeting ? "border-0 my-2" : "my-3"}`}>
                {!props?.adGroupkeywordTargeting &&
                    <div className="card-header bg-white">
                        <div className="d-flex justify-content-between py-2">
                            <div className="fs-24 fw-light">
                                Keyword targeting
                                <Tooltip
                                    title={tooltipContent(
                                        "Keyword targeting card header"
                                    )}
                                    placement="top"
                                    arrow
                                    className="custom-tooltip-position cursor-pointer "
                                >
                                    <span className="ms-1">
                                        <svg
                                            viewBox="0 0 512 512"
                                            width="12"
                                            height="12"
                                            className="fas fa-info-circle mb-1"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                }
                <div className={`card-body ${props?.adGroupkeywordTargeting ? "p-0" : "py-0"}`}>
                    <div className="row text-center px-0">
                        <div className="col-6 bg-white products-tabs-section border-right py-0">
                            <div className="tabs-section px-0">
                                <div
                                    className="nav nav-underline"
                                    id="nav-tab"
                                    role="tablist"
                                >
                                    <button
                                        className="nav-link text-dark active fs-13"
                                        id="nav-suggested" data-bs-toggle="tab" data-bs-target="#nav-suggest"
                                        type="button" role="tab" aria-controls="nav-suggest"
                                        aria-selected="true"
                                    >
                                        Suggested
                                        <Tooltip
                                            title={tooltipContent("keyword suggested tab")}
                                            placement="top"
                                            arrow
                                            className="custom-tooltip-position cursor-pointer"
                                        >
                                            <span className="ms-1">
                                                <svg
                                                    viewBox="0 0 512 512"
                                                    width="12"
                                                    height="12"
                                                    className="fas fa-info-circle"
                                                    data-fa-i2svg=""
                                                >
                                                    <path
                                                        fill="#8290a4"
                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </Tooltip>
                                    </button>
                                    <button
                                        className="nav-link text-dark fs-13"
                                        id="nav-enter-list"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-enter-list-keyword"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-enter-list-keyword"
                                        aria-selected="false"
                                    >
                                        Enter list
                                    </button>
                                </div>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active suggested-tab"
                                    id="nav-suggest"
                                    role="tabpanel"
                                    aria-labelledby="nav-suggested"
                                    tab-index="0"
                                >
                                    <div className="row border-top border-bottom">
                                        <div className="col-12 px-0">
                                            <div className="d-flex justify-content-start m-2 px-1">
                                                <div className="fs-12 fw-bold my-1">
                                                    Bid
                                                    <Tooltip
                                                        title={tooltipContent(
                                                            "keyword suggested tab Bid"
                                                        )}
                                                        placement="top"
                                                        arrow
                                                        className="custom-tooltip-position cursor-pointer"
                                                    >
                                                        <span className="ms-1">
                                                            <svg
                                                                viewBox="0 0 512 512"
                                                                width="12"
                                                                height="12"
                                                                className="fas fa-info-circle"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="#8290a4"
                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 ms-5 ps-4">
                                                    <div className="btn-group">
                                                        <button
                                                            className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            {selectedKeyWordTargetingBidValue}{" "}
                                                            <span>
                                                                <svg
                                                                    viewBox="0 0 389 512"
                                                                    width="13"
                                                                    height="13"
                                                                    className="fas fa-angle-down show-text-icon"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                                            <li className="fs-13 py-1">
                                                                {keywordTargetingBidData?.map(
                                                                    (item: any, index: number) => (
                                                                        <button key={index}
                                                                            className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingBidValue ===
                                                                                item?.value ? "active" : ""}`}
                                                                            value={item.value}
                                                                            onClick={() => handleKeywordTargetingBidTextChange(item?.value)} >
                                                                            <div className="fw-400">
                                                                                {item.name}
                                                                            </div>
                                                                            <div className="dropdown-sub-title-text-color">
                                                                                {item.sub_title}
                                                                            </div>
                                                                        </button>
                                                                    )
                                                                )}
                                                            </li>
                                                        </ul>
                                                        {selectedKeyWordTargetingBidValue === "Custom bid" && (
                                                            <div className="ms-2">
                                                                <div className="input-group table-input-field-width">
                                                                    <span className="input-group-text custom-bid-input-height bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                                                    <input
                                                                        type="text"
                                                                        name="add_group_custom_bid"
                                                                        className="form-control custom-bid-input-height drop-down-input border-dark border-start-0 px-0"
                                                                        defaultValue={suggestedBidValue?.add_group_custom_bid ? suggestedBidValue?.add_group_custom_bid : '3'} placeholder="3.00" onChange={hadnleInput}
                                                                    />
                                                                </div>
                                                                {formErrors.add_group_custom_bid && (<div className='text-danger'>{formErrors.add_group_custom_bid}</div>)}
                                                            </div>
                                                        )}
                                                        {selectedKeyWordTargetingBidValue === "Default bid" && (
                                                            <div className="ms-2">
                                                                <div className="input-group table-input-field-width">
                                                                    <span className="input-group-text custom-bid-input-height bg-white border-dark px-2 keyword-below-text-color">₹</span>
                                                                    <input type="number" name="add_group_default_bid"
                                                                        className="form-control custom-bid-input-height drop-down-input border-dark border-start-0 px-0"
                                                                        defaultValue={suggestedBidValue?.add_group_default_bid ? suggestedBidValue?.add_group_default_bid : "3"} placeholder="3.00"
                                                                        onChange={hadnleInput}
                                                                    />
                                                                </div>
                                                                {formErrors.add_group_default_bid && (<div className='text-danger'>{formErrors.add_group_default_bid}</div>)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex m-2 px-1 my-3">
                                                <div className="fs-12 fw-bold">
                                                    Filter by
                                                    <Tooltip
                                                        title={tooltipContent(
                                                            "keyword suggested tab Filter by"
                                                        )}
                                                        placement="top"
                                                        arrow
                                                        className="custom-tooltip-position cursor-pointer"
                                                    >
                                                        <span className="ms-1">
                                                            <svg
                                                                viewBox="0 0 512 512"
                                                                width="12"
                                                                height="12"
                                                                className="fas fa-info-circle"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="#8290a4"
                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 ms-5">
                                                    <div className="d-flex">
                                                        <div>
                                                            <label className="me-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="me-2 cursor-pointer"
                                                                    defaultChecked={adGroupCheckBoxes.Broad}
                                                                    onChange={() => handleAdGroupCheckboxChange('Broad')}
                                                                />
                                                                Broad
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label className="me-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="me-2 cursor-pointer"
                                                                    defaultChecked={adGroupCheckBoxes.Phrase}
                                                                    onChange={() => handleAdGroupCheckboxChange('Phrase')}
                                                                />
                                                                Phrase
                                                            </label>
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <label className="me-1">
                                                                <input
                                                                    type="checkbox"
                                                                    className="me-2 cursor-pointer"
                                                                    defaultChecked={adGroupCheckBoxes.Exact}
                                                                    onChange={() => handleAdGroupCheckboxChange('Exact')}
                                                                />
                                                                Exact
                                                            </label>
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`d-flex justify-content-start m-2 px-1 ${props?.adGroupkeywordTargeting ? "d-none" : "d-block"}`}>
                                                <div className="fs-12 fw-bold my-1">
                                                    Sort By
                                                    <Tooltip
                                                        title={tooltipContent(
                                                            "keyword suggested tab Sort By"
                                                        )}
                                                        placement="top"
                                                        arrow
                                                        className="custom-tooltip-position cursor-pointer"
                                                    >
                                                        <span className="ms-1">
                                                            <svg
                                                                viewBox="0 0 512 512"
                                                                width="12"
                                                                height="12"
                                                                className="fas fa-info-circle"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="#8290a4"
                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 ms-5">
                                                    <div className="btn-group">
                                                        <button
                                                            className="btn btn-secondary btn-sm new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            {selectedKeyWordTargetingSortingValue}{" "}
                                                            <span>
                                                                <svg
                                                                    viewBox="0 0 389 512"
                                                                    width="13"
                                                                    height="13"
                                                                    className="fas fa-angle-down show-text-icon"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        <ul className="dropdown-menu newest-first-dropdown keyword-targeting-sorting-dropdown">
                                                            <li className="fs-13 py-1">
                                                                {keywordTargetingSortData?.map(
                                                                    (item: any, index: number) => (
                                                                        <button key={index} className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingSortingValue ===
                                                                            item?.value ? "active" : ""}`} value={item.value}
                                                                            onClick={() => handleKeywordTargetingSortingChange(item?.value)} >
                                                                            {item.name}
                                                                        </button>
                                                                    )
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-0">
                                        <DataTable
                                            tableData={[]}
                                            TableCols={KeywordTargetingDataGridCols}
                                            noDataFoundMessage={noDataMessage}
                                            addAllStatus={getAddAllDataStatus}
                                        />
                                    </div>
                                    {loading &&
                                        <div className="text-center p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                    {!loading && (
                                        <div className='' style={{ height: props?.suggestedKeywordsScroll ? "295px" : "260px", overflowY: "auto" }}>
                                            {keywordTargetingData?.length > 0 && keywordTargetingData?.map((data: any, inde: number) => (
                                                <div className='d-flex justify-content-between border-bottom py-1' key={inde}>
                                                    <div className='fs-11 text-wrap w-50 text-start'>{data?.keyword}</div>
                                                    <div className='fs-11 text-start w-40'>
                                                        {data?.bidInfo?.length > 0 && data?.bidInfo.map((targetBidData: any, index: number) => (
                                                            <data className='fs-11' key={index}>
                                                                <div>{targetBidData?.matchType}</div>
                                                            </data>
                                                        ))}
                                                        {data?.bidInfo?.length === 0 && (
                                                            <div>-</div>
                                                        )}
                                                    </div>
                                                    <div className='fs-11 text-start w-40'>
                                                        {data?.bidInfo?.length > 0 && data?.bidInfo.map((targetBidData: any, index: number) => (
                                                            <data className='fs-11' key={index}>
                                                                <div>₹{(targetBidData?.bid / 100).toFixed(2)}</div>
                                                            </data>
                                                        ))}
                                                        {data?.bidInfo?.length === 0 && (
                                                            <div>-</div>
                                                        )}
                                                    </div>
                                                    <div className='fs-11 text-start w-40'>
                                                        {data?.bidInfo?.length > 0 && data?.bidInfo.map((targetBidData: any, index: number) => (
                                                            <data className='fs-11' key={index}>
                                                                <div className={`${targetBidData?.btn_status ? "add-products-text-color add-cursor-pointer-not-allowed fst-italic ms-5 ps-2" : "custom-explore-text-color cursor-pointer logout ms-5 ps-3"}`} onClick={() => onClickAddKeywordTargetingData(data, inde, targetBidData, index)}>
                                                                    {targetBidData?.btn_status ? "Added" : "Add"}
                                                                </div>
                                                            </data>
                                                        ))}
                                                        {data?.bidInfo?.length === 0 && (
                                                            <div className='ms-4'>-</div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {keywordTargetingData?.length === 0 && (
                                                <div className="d-flex flex-column justify-content-center my-5">
                                                    <div className="fs-12 fw-bold">
                                                        No related keywords
                                                    </div>
                                                    <div className="fs-12">
                                                        Try entering your own keywords
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="tab-pane fade enterlist-tab"
                                    id="nav-enter-list-keyword"
                                    role="tabpanel"
                                    aria-labelledby="nav-enter-list"
                                    tab-index="0"
                                >
                                    <div className="row border-top">
                                        <div className="d-flex justify-content-start m-2 px-1 my-2">
                                            <div className="fs-12 fw-bold ">
                                                Bid
                                                <Tooltip
                                                    title={tooltipContent(
                                                        "keyword enterlist tab Bid"
                                                    )}
                                                    placement="top"
                                                    arrow
                                                    className="custom-tooltip-position cursor-pointer"
                                                >
                                                    <span className="ms-1">
                                                        <svg
                                                            viewBox="0 0 512 512"
                                                            width="12"
                                                            height="12"
                                                            className="fas fa-info-circle"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="#8290a4"
                                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div className="fs-13 ms-5 ps-4">
                                                <div className="btn-group">
                                                    <button
                                                        className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        {selectedKeyWordTargetingBidValue}{" "}
                                                        <span>
                                                            <svg
                                                                viewBox="0 0 389 512"
                                                                width="13"
                                                                height="13"
                                                                className="fas fa-angle-down show-text-icon"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                                                ></path>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                                        <li className="fs-13 py-1">
                                                            {keywordTargetingBidData?.map(
                                                                (item: any, index: number) => (
                                                                    <button
                                                                        key={index}
                                                                        className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedKeyWordTargetingBidValue ===
                                                                            item?.value
                                                                            ? "active"
                                                                            : ""
                                                                            }`}
                                                                        value={item.value}
                                                                        onClick={() =>
                                                                            handleKeywordTargetingBidTextChange(
                                                                                item?.value
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="fw-400">
                                                                            {item.name}
                                                                        </div>
                                                                        <div className="dropdown-sub-title-text-color">
                                                                            {item.sub_title}
                                                                        </div>
                                                                    </button>
                                                                )
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex m-2 px-1 my-2">
                                            <div className="fs-12 fw-bold">
                                                Match type
                                                <Tooltip
                                                    title={tooltipContent(
                                                        "keyword enterlist tab Match type"
                                                    )}
                                                    placement="top"
                                                    arrow
                                                    className="custom-tooltip-position cursor-pointer"
                                                >
                                                    <span className="ms-1">
                                                        <svg
                                                            viewBox="0 0 512 512"
                                                            width="12"
                                                            height="12"
                                                            className="fas fa-info-circle"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="#8290a4"
                                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div className="fs-13 ms-5">
                                                <div className="d-flex">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name='Broad'
                                                            id="flexCheckDefaultBroadThree"
                                                            checked={externalListCheckboxes?.Broad}
                                                            onChange={(e: any) => handleEnterlistCheckBoxChange(e)}
                                                        />
                                                        <label
                                                            className="form-check-label fs-12"
                                                            htmlFor="flexCheckDefaultBroadThree"
                                                        >
                                                            Broad
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name='Phrase'
                                                            onChange={(e: any) => handleEnterlistCheckBoxChange(e)}
                                                            id="flexCheckDefaultPhraseFour"
                                                            checked={externalListCheckboxes?.Phrase}
                                                        />
                                                        <label
                                                            className="form-check-label fs-12"
                                                            htmlFor="flexCheckDefaultPhraseFour"
                                                        >
                                                            Phrase
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name='Exact'
                                                            onChange={(e: any) => handleEnterlistCheckBoxChange(e)}
                                                            id="flexCheckDefaultExactSix"
                                                            checked={externalListCheckboxes?.Exact}
                                                        />
                                                        <label
                                                            className="form-check-label fs-12"
                                                            htmlFor="flexCheckDefaultExactSix"
                                                        >
                                                            Exact
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <textarea
                                                name="keyword_text"
                                                rows={6}
                                                className="form-control fs-14 border-dark"
                                                placeholder="Enter keywords separated by new line."
                                                value={manualKeywordText?.keyword_text}
                                                onChange={handleKeywordInput}
                                            ></textarea>
                                        </div>
                                        <div className='text-start ps-1 ms-1'>
                                            {successMessage && (
                                                <span className='fs-13'>
                                                    <span><i className="bi bi-check text-success fs-21"></i></span><span className="text-success ml-2 fs-13">{successMessage}</span>
                                                </span>
                                            )}
                                        </div>
                                        <div className="text-end">
                                            <button className={`btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 ${keywordTextStatus ? "cursor-pointer" : "cursor-pointer-not-allowed"}`}
                                                disabled={!keywordTextStatus}
                                                onClick={onClickAddManualKeywordData}>
                                                Add keywords
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade upload-tab"
                                    id="nav-upload-keyword"
                                    role="tabpanel"
                                    aria-labelledby="nav-upload"
                                    tab-index="0"
                                >
                                    <div className="row border-top">
                                        <div className="mt-3 fs-13 text-start">
                                            Use the template to make sure your information
                                            uploads accurately.
                                        </div>
                                        <div className="text-start mb-3">
                                            <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                                <span className="me-2">
                                                    <img src={DownloadIcon} alt="" />
                                                </span>
                                                Download the XLSX template
                                            </button>
                                        </div>
                                        <div className="upload-files">
                                            <div
                                                data-takt-id="ups_upload_asins_input"
                                                className="upload-icon fs-13"
                                            >
                                                <div className="mt-4 pt-2">
                                                    <label
                                                        htmlFor="input_file_three"
                                                        className="cursor-pointer"
                                                    >
                                                        <div className="">
                                                            <div>
                                                                <i className="bi bi-upload"></i>
                                                            </div>
                                                            <div className="fs-15 my-1">
                                                                Drag and drop file to add
                                                            </div>
                                                            <div className="fs-13 my-1">
                                                                or click to select from your computer
                                                            </div>
                                                            <div className="fs-13 my-3">
                                                                Accepted formats: CSV, TSV, XLSX
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="input_file_three"
                                                        name="file"
                                                        className="d-none"
                                                        onChange={handleQuotationFileUpload}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 product-preview-container px-0">
                            <div className="d-flex justify-content-between border-bottom py-2 px-3 px-0">
                                <div className="fs-13 fw-bold ">{addedKeywordTargetingData?.length} added</div>
                                <div className={`fs-13 ${addedKeywordTargetingData?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : "cursor-pointer-not-allowed remove-all-text"}`}
                                    onClick={onClickRemoveAllData}>
                                    Remove all
                                </div>
                            </div>
                            <div className="px-3">
                                <DataTable
                                    TableCols={KeywordTargetingTwoDataGridCols}
                                    keywordTargetTwo={true}
                                />
                            </div>
                            <div className="addedkeyword-scroll">
                                {addedKeywordTargetingData?.length > 0 && addedKeywordTargetingData?.map((data: any, index: number) => (
                                    <div className='d-flex justify-content-between border-bottom py-1 mx-2'>
                                        <div className='fs-11 text-wrap w-40 text-start'>{data?.suggestionData}</div>
                                        <div className='fs-11 w-25 text-start'>{data?.bidData?.matchType}</div>
                                        {alternateBidStatus && (<div className='fs-11 w-25 text-start'>-</div>)}
                                        {!alternateBidStatus && (<div className='fs-11 w-25 text-start'>
                                            <div>₹{(data?.bidData?.bid / 100).toFixed(2)}</div>
                                            <div>₹{(data?.bidData?.suggestedBid?.rangeStart / 100).toFixed(2)} - ₹{(data?.bidData?.suggestedBid?.rangeEnd / 100).toFixed(2)}</div>
                                        </div>
                                        )}
                                        <div className='d-flex justify-content-start'>
                                            <div className="input-group table-input-field-width keyword-added-input-field my-1">
                                                <span className="input-group-text input bg-white border-dark px-1">
                                                    <img src={RupeeIcon} className="" alt="" />
                                                </span>
                                                <input type="text" name="keyword_targeting_bid" placeholder='0'
                                                    className="form-control input border-dark border-start-0 px-0"
                                                    defaultValue={selectedKeyWordTargetingBidValue === "Custom bid" ? suggestedBidValue?.add_group_custom_bid ? suggestedBidValue?.add_group_custom_bid : "3" : selectedKeyWordTargetingBidValue === "Default bid" ? suggestedBidValue?.add_group_default_bid ? suggestedBidValue?.add_group_default_bid : "3" : selectedKeyWordTargetingBidValue === "Suggested bid" ? alternateBidStatus ? data?.bidData?.bid : (data?.bidData?.bid / 100).toFixed(2) : alternateBidData?.alternate_bid ? alternateBidData?.alternate_bid : "3"}
                                                    onChange={(e) => handleChangeInputValue(e, index, data)}
                                                />
                                            </div>
                                            <div className='my-1'><i className="bi bi-x fs-21 cursor-pointer" onClick={() => onClickCancelKeywordTargeting(data, index)}></i></div>
                                        </div>
                                    </div>
                                ))}
                                {addedKeywordTargetingData?.length === 0 && (
                                    <div className='fs-13'>No records found
                                    </div>)}
                            </div>
                        </div>
                        {/* showAlternateBidModal */}
                        {showAlternateBidModal && (
                            <div className={`modal modal-custom fade ${showAlternateBidModal ? "show" : "false"}  ${showAlternateBidModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                                <div className="modal-dialog modal-sm modal-dialog-centered " role="document">
                                    <div className="modal-content mx-auto ">
                                        <div className='modal-body p-3 border-bottom'>
                                            <div className='fs-13'>3 of 3 keywords do not have a suggested bid</div>
                                            <div className='d-flex justify-content-center my-2'>
                                                <div className='fs-13 alternate-label-width my-2'>Choose an alternate bid:</div>
                                                <div className='d-flex justify-content-end'>
                                                    <input type="number" name="alternate_bid" className='form-control input border-dark alternate-bid-modal' onChange={handleAlternateInput} defaultValue={alternateBidData?.alternate_bid} />
                                                </div>
                                            </div>
                                            {formErrors.alternate_bid && (<div className='text-danger text-start'>{formErrors?.alternate_bid}</div>)}
                                        </div>
                                        <div className="modal-footer border-top d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                                onClick={() => setShowAlternateBidModal(false)}
                                            >
                                                Do not add keywords
                                            </button>
                                            <button type="button" className={`request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer`}
                                                onClick={onAddAlternateBid}
                                            >
                                                Add keywords
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
})
