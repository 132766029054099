import React, { useEffect, useState } from 'react'
import WrongIcon from "./../../../../../assets/images/svg_icons/wrong.svg";
import { useNavigate } from 'react-router-dom';
import { CustomFooter } from '../../../../../components/footer/custom-footer';
import { ShowLeavePageModal } from '../../../../../components/ad-group-creation-components/show-leave-page-modal';

export const BudgetRuleCreation = () => {
    const [budgetType, setBudgetType] = useState<any>('Schedule');
    const [budgetRecurrence, setBudgetRecurrence] = useState<any>('Daily');
    const [showAdGroupsLeaveModal, setShowAdGroupsLeaveModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const currentDate = new Date().toISOString().split('T')[0];
    const [budgetRuleCreationData, setBudgetRuleCreationData] = useState<any>({
        budget_rule_name: "",
        budget_start_date: "",
        budget_end_date: "",
        budget_increase: ""
    })
    const [formErrors, setFormErrors] = useState<any>({});

    const addBudgetRulesData = [
        {
            title: "Rule Name"
        },
        {
            title: "Type"
        },
        {
            title: "Date range"
        },
        {
            title: "Recurrence"
        },
        {
            title: "Increase"
        },
    ]

    useEffect(() => {
        const endDateInput: any = document.querySelector('input[name="budget_end_date"]');
        if (endDateInput) {
            endDateInput.min = budgetRuleCreationData.budget_start_date;
        }
    }, [budgetRuleCreationData.budget_start_date]);

    const onClickCancel = () => {
        setShowAdGroupsLeaveModal(true);
    };

    const onClickLearnMore = () => {
        const link = "https://advertising.amazon.in/help?entityId=ENTITY2G8T90BNYY5QX#GKPA6T8WW3AYKV4Q"
        window.open(link, "_blank")
    }

    const onClickGobacktoSettings = () => {
        navigate("/dashboard/campaign/budget-rules");
    };

    const handleChangeBudgetType = (option: string) => {
        setBudgetType(option)
    }

    const handleChangeBudgetRecurrence = (option: string) => {
        setBudgetRecurrence(option)
    }

    const getAdGroupsLeaveModalStatus = (status: any) => {
        if (status) {
            setShowAdGroupsLeaveModal(false)
        }
    }

    const getAdeGroupsOnLeaveStatus = (status: any) => {
        if (status) {
            navigate("/dashboard/campaign/budget-rules")
        }
    }

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setBudgetRuleCreationData((prevData: any) => ({
            ...prevData, [name]: value
        }))
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "budget_rule_name") {
            if (!value) {
                return "Please enter rule name"
            }
        }
        if (name === "budget_increase") {
            if (value < 0) {
                return "Please enter valid increase"
            }
        }
        return "";
    }

    const validateForm = () => {
        const errors: any = {};
        if (!budgetRuleCreationData.budget_rule_name) {
            errors.budget_rule_name = "Please enter rule name";
        }

        if (!budgetRuleCreationData.budget_increase) {
            errors.budget_increase = "Please enter increase";
        } else if (budgetRuleCreationData.budget_increase < 0) {
            errors.budget_increase = "Please enter valid increase";
        }

        if (!budgetRuleCreationData.budget_start_date) {
            errors.budget_start_date = "Please select start date";
        }
        if (!budgetRuleCreationData.budget_end_date) {
            errors.budget_end_date = "Please select end date";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onCreateBudgetRule = () => {
        validateForm();
    }

    return (
        <div className="container-fluid campaign-creation-bg bg-white">
            <div className="row fixed-top budget-rule-header-bg">
                <div className="col-12 px-0 ">
                    <nav className="navbar navbar-expand-lg bg-white shadow py-3">
                        <div className="container-fluid px-4">
                            <div className="d-flex">
                                <div className="bg-transaprent border-0 me-3">
                                    <img
                                        src={WrongIcon}
                                        className="mt-1 cursor-pointer"
                                        onClick={onClickCancel}
                                        alt='wrong'
                                    />
                                </div>
                                <h2 className="navbar-brand campaign-navbar fs-18">
                                    Add budget rule
                                </h2>
                            </div>
                            <div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div
                                            className="custom-explore-text-color fs-13 logout cursor-pointer py-2"
                                            onClick={onClickGobacktoSettings}
                                        >
                                            Cancel
                                        </div>
                                    </div>
                                    <div className="mx-2">
                                        <button
                                            className="request-call-button text-white fs-13"
                                            onClick={onCreateBudgetRule}
                                        >
                                            Add budget rule
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="col-md-10 mx-auto mt-5 campaign-creation-form-container">
                <div className="col-12 card-width mx-auto mt-5 pt-5" >
                    <h4 className="my-2 fs-28 mt-5 pt-3">Add budget rule</h4>
                </div>
                <div className='col-12 card-width mx-auto my-3 mb-4'>
                    <div className="fs-13">Increase daily budgets for recommended events. <span className='custom-explore-text-color cursor-pointer logout' onClick={onClickLearnMore}>Learn more</span></div>
                </div>
                <div className='budget-settings-card-container card-width mx-auto my-3'>
                    <div className='settings-card-inner-padding'>
                        <div className="settings-container pb-4 border-bottom">
                            <div className='fs-21 fw-700'>Settings</div>
                            <div className='fs-13 budget-rule-campaign-budget-text'>Campaign daily budget : ₹50.00 </div>
                        </div>
                        {addBudgetRulesData?.map((budgetData: any, index: number) => (
                            <div className={`row  mx-3 py-2 ${index === 2 || index === addBudgetRulesData?.length - 1 ? "border-bottom-0 budget-bootom-border" : "border-bottom"}`} key={index}>
                                <div className="col-2 py-2 fw-700 text-end">{budgetData?.title}</div>
                                <div className='col-10 py-2 px-4'>
                                    {budgetData?.title === "Rule Name" && (
                                        <div>
                                            <div className='rule-name-width'>
                                                <input type="text" name="budget_rule_name" className='form-control input fs-13 border-dark' defaultValue={``} placeholder={`Example: Valentine's Day rule`}
                                                    onChange={handleInput} />
                                            </div>
                                            {formErrors.budget_rule_name && (
                                                <div className="text-danger text-start w-100">
                                                    {formErrors.budget_rule_name}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {budgetData?.title === "Type" && (
                                        <div>
                                            <div className="d-flex form-check">
                                                <input
                                                    type="radio"
                                                    name="manual-target-option"
                                                    id="schduleType"
                                                    className="form-check-input mb-3 me-3"
                                                    checked={budgetType === "Schedule"}
                                                    onChange={() => handleChangeBudgetType("Schedule")}
                                                />
                                                <label className='cursor-pointer' htmlFor='schduleType'>
                                                    <div className="fs-13">Schedule</div>
                                                    <div className="fs-13 budget-type-sub-text-color"> Increases your budget during high-traffic times or specific date ranges </div>
                                                </label>
                                            </div>
                                            <div className="d-flex form-check">
                                                <input
                                                    type="radio"
                                                    name="manual-target-option"
                                                    id="performanceType"
                                                    className="form-check-input mb-3 me-3"
                                                    checked={budgetType === "Performance"}
                                                    onChange={() => handleChangeBudgetType("Performance")}
                                                />
                                                <label className='cursor-pointer' htmlFor='performanceType'>
                                                    <div className="fs-13">Performance</div>
                                                    <div className="fs-13 budget-type-sub-text-color">Increases your budget for top-of-search impression share (IS), % of sales new-to-brand (NTB) or return on ad spend (ROAS)</div>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    {budgetData?.title === "Date range" && (
                                        <div className='col-12 d-flex'>
                                            <div className="budget-date-range-date-width">
                                                <label className="fs-13 form-label fw-bold">START</label>
                                                <input type="date" name="budget_start_date" min={currentDate} className='form-control input border-dark' defaultValue={``}
                                                    onChange={handleInput} />
                                                {formErrors.budget_start_date && (
                                                    <div className="text-danger text-start w-100">
                                                        {formErrors.budget_start_date}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="budget-date-range-date-width mx-3">
                                                <label className="fs-13 form-label  fw-bold">END</label>
                                                <input type="date" name="budget_end_date" min={budgetRuleCreationData.budget_start_date} className='form-control input border-dark' defaultValue={``}
                                                    onChange={handleInput} />
                                                {formErrors.budget_end_date && (
                                                    <div className="text-danger text-start w-100">
                                                        {formErrors.budget_end_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {budgetData?.title === "Recurrence" && (
                                        <div className='col-12'>
                                            <div className="">
                                                <input
                                                    type="radio"
                                                    name="recurrence_status"
                                                    id="recurrence_daily"
                                                    className="form-check-input mb-3 me-3"
                                                    checked={budgetRecurrence === "Daily"}
                                                    onChange={() => handleChangeBudgetRecurrence("Daily")}
                                                />
                                                <label className='cursor-pointer' htmlFor='recurrence_daily'>Daily</label>
                                            </div>
                                            <div className="mt-0">
                                                <input
                                                    type="radio"
                                                    name="recurrence_status"
                                                    id="recurrence_weekly"
                                                    className="form-check-input mb-3 me-3"
                                                    checked={budgetRecurrence === "Weekly"}
                                                    onChange={() => handleChangeBudgetRecurrence("Weekly")}
                                                />
                                                <label className='cursor-pointer' htmlFor='recurrence_weekly'>Weekly</label>
                                            </div>
                                        </div>
                                    )}
                                    {budgetType === "Performance" && (
                                        <React.Fragment>
                                            {budgetData?.title === "Performance metric" && (
                                                <div className="row">
                                                    <div className='col-3'>
                                                        <select name="" className="form-select border-dark input fs-13" defaultValue={''}>
                                                            <option value="ROAS">ROAS</option>
                                                            <option value="ROAS">ACOS</option>
                                                            <option value="ROAS">CTR</option>
                                                            <option value="ROAS">CVR</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-4'>
                                                        <select name="" className="form-select border-dark input fs-13" defaultValue={''}>
                                                            <option value="greater than or equal to">greater than or equal to </option>
                                                            <option value="greater than">greater than</option>
                                                            <option value="less than or equal to">less than or equal to</option>
                                                            <option value="less than">less than</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-3'>
                                                        <input type="number" name="performance_metric_value" className='form-control input border-dark fs-13' defaultValue={''} />
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {budgetData?.title === "Increase" && (
                                        <div className='col-12'>
                                            <div className="budget-increase">
                                                <div className="input-group border-dark">
                                                    <input type="number" name="budget_increase" className="form-control input border-dark border-end-0" placeholder="0" aria-label="Username" aria-describedby="basic-addon1"
                                                        onChange={handleInput} />
                                                    <span className="input-group-text border-dark input" id="basic-addon1">%</span>
                                                </div>
                                            </div>
                                            {formErrors.budget_increase && (
                                                <div className="text-danger text-start w-100">
                                                    {formErrors.budget_increase}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="buttons-container card-width mx-auto">
                    <div className="d-flex justify-content-end">
                        <div className="mx-2">
                            <button
                                className="custom-explore-text-color logout bg-transparent border-0"
                                onClick={onClickGobacktoSettings}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="">
                            <button
                                className="request-call-button text-white fs-13 "
                                onClick={onCreateBudgetRule}
                            >
                                Add budget rule
                            </button>
                        </div>
                    </div>
                </div>
                <div className="footer-container mt-4 pt-2 pb-3 card-width position-sticky  d-flex justify-content-center mx-auto">
                    <CustomFooter />
                </div>
                {showAdGroupsLeaveModal && (
                    <ShowLeavePageModal
                        ismodalShow={showAdGroupsLeaveModal}
                        modalStatus={getAdGroupsLeaveModalStatus}
                        OnLeavePageStatus={getAdeGroupsOnLeaveStatus} />
                )}
            </div>
        </div>
    )
}
