import React, { useEffect, useRef, useState } from 'react'
import { CustomFilter } from '../../../../components/custom-filter/custom-filter'
import { CustomTable } from '../../../../components/custom-table'
import { NegativeKeywordsDataGridCols } from './negative-keywords-data-grid-cols'
import { Tooltip } from "@mui/material";
import { TooltipPage } from '../../../../components/custom-tooltip/tooltipPage';
import { CustomFooter } from '../../../../components/footer/custom-footer';
import { CREATE_NEGATIVE_KEYWORDS, GET_CAMPAIGN_NEGATIVE_KEYWORDS } from '../../../../app/services/negative-keywords.service';
import { toast } from "react-toastify";
import { connect } from 'react-redux';

interface Props {
    ProfileDataReducer?: any;
    profileData?: any;
    UserDataReducer?: any;
    userData?: any;
    CampaignDataReducer?: any;
    campaignData?: any;
}

const NegativeKeywordsListPage = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignNegativeKeywordsData, setCampaignNegativeKeywordsData] = useState<any>();
    const [originalCampaignNegativeKeywordsData, setOriginalCampaignNegativeKeywordsData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [negativeKeywordText, setNegativeKeywordText] = useState<any>({
        negative_keyword_text: ""
    })
    const [negativeKeywordTextStatus, setNegativeKeywordTextStatus] = useState<boolean>(false)
    const [addNegativeKeywordsData, setAddNegativeKeywordsData] = useState<any>([]);
    const [isAddingKeywords, setIsAddingKeywords] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [keywordMatchtype, setKeywordMatchtype] = useState<any>('Negative exact');
    const modalRef: any = useRef(null);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowPreviewModal(false);
            }
        };

        const handleEscapeKey = (event: any) => {
            if (event.key === 'Escape') {
                setShowPreviewModal(false);
            }
        };

        if (showPreviewModal) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [showPreviewModal]);

    useEffect(() => {
        getCampaignNegativeKeywords();
    }, [])

    const tableData = {
        page: "0",
        limit: "10",
        total: 14,
        rows: campaignNegativeKeywordsData
    }

    const tablefooterData = [
        {
            keywordText: `Total: ${campaignNegativeKeywordsData?.length ? campaignNegativeKeywordsData?.length : 0}`,
        },
    ];

    const getCampaignNegativeKeywords = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID
        }
        setLoading(true);
        GET_CAMPAIGN_NEGATIVE_KEYWORDS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    setOriginalCampaignNegativeKeywordsData(response?.data[0]?.negative_keywords_data)
                    setCampaignNegativeKeywordsData(response?.data[0]?.negative_keywords_data);
                    setLoading(false);
                }
            }
        });
    }

    const onClickButtonText = (data: any) => {
        setShowPreviewModal(true);
    }

    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "negative Keyword targeting Match type") {
            return (
                <TooltipPage
                    keyWordBroad={
                        " Contains all the keywords in any order and includes plurals, variations and related keywords."
                    }
                    keyWordPhrase={" Contains the exact phrase or sequence of keywords."}
                    keyWordExact={"Exactly matches the keyword or sequence of keywords"}
                    tooltipLearnMoreText={""}
                    keyWordTableText={
                        "Match types allow you to fine-tune which customer searches trigger your ads."
                    }
                />
            );
        } else if (tooltipContent === "negative Keyword targeting table Match type") {
            return (
                <TooltipPage
                    keyWordBroad={
                        " Contains all the keywords in any order and includes plurals, variations and related keywords."
                    }
                    keyWordPhrase={" Contains the exact phrase or sequence of keywords."}
                    keyWordExact={"Exactly matches the keyword or sequence of keywords"}
                    tooltipLearnMoreText={"Learn More"}
                    keyWordTableText={
                        "Match types allow you to fine-tune which customer searches trigger your ads."
                    }
                />
            );
        }
    }

    const handleChangeNegativeKeywordText = (e: any) => {
        const { name, value } = e.target;
        setNegativeKeywordText((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        const data = e.target.value;
        if (data?.trim()?.length > 0) {
            setNegativeKeywordTextStatus(true);
        } else {
            setNegativeKeywordTextStatus(false);
        }
        setNegativeKeywordText(data)
        setIsAddingKeywords(false);
    }

    const handleChangekeywordMatchType = (selectedData: any) => {
        setKeywordMatchtype(selectedData);
    }

    const onClickAddNegativeKeywords = () => {
        setIsAddingKeywords(true);
        const data = {
            negative_keyword_matchType: keywordMatchtype === "Negative exact" ? "NEGATIVE_EXACT" : "NEGATIVE_PHRASE",
            negative_keyword_text: negativeKeywordText,
            state: "ENABLED"
        }
        addNegativeKeywordsData.push(data)
        setSuccessMessage('1/1 keywords were added successfully.');
        setNegativeKeywordText({
            ...negativeKeywordText,
            negative_keyword_text: ""
        });
        setNegativeKeywordTextStatus(false);
    }

    const onClickCancelAddNegativeKeywordsData = (index: number) => {
        const data = addNegativeKeywordsData;
        data?.splice(index, 1);
        setAddNegativeKeywordsData([...data]);
    }

    const onNegativekeywordsCreate = () => {
        if (addNegativeKeywordsData?.length > 0) {
            const preapredData = {
                user_uuid: userUuid,
                profile_id: profileID,
                campaign_id: campaignID,
                negative_keywords: addNegativeKeywordsData
            }
            setLoading(true);
            CREATE_NEGATIVE_KEYWORDS(preapredData).then((res) => {
                if (res.data.status === "error" || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setShowPreviewModal(false);
                    setLoading(false);
                } else {
                    if (res) {
                        const response = res.data.data;
                        toast.success(res.data.message);
                        setShowPreviewModal(false);
                        getCampaignNegativeKeywords();
                        setAddNegativeKeywordsData([])
                        setLoading(false);
                    }
                }
            });
        } else {
            toast.error("Please add atleast one negative keyword", { autoClose: 2000 })
        }
    }

    const onClickRemoveAllData = () => {
        setAddNegativeKeywordsData([]);
        setSuccessMessage('');
        setNegativeKeywordText("");
        setNegativeKeywordTextStatus(false);
    }

    const handleCustomSearch = (data: any) => {
        if (data?.trim()?.length > 0) {
            for (let i = 0; i < originalCampaignNegativeKeywordsData?.length; i++) {
                originalCampaignNegativeKeywordsData[i].dummy_keywordText = originalCampaignNegativeKeywordsData[i].keywordText.toLowerCase();
            }
            const searchData = originalCampaignNegativeKeywordsData?.filter((el: any) => el?.dummy_keywordText?.includes(data.toLowerCase()));
            setCampaignNegativeKeywordsData(searchData);
        } else {
            getCampaignNegativeKeywords();
        }
    }

    const handleClickSearchWrong = (status: any) => {
        if (!status) {
            getCampaignNegativeKeywords();
        }
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className="col-12 mt-3">
                    <CustomFilter customCreateButtonText='Add negative keywords'
                        customPlaceholder='Find a negative keyword'
                        negativeKeywordFilter={true} onClickButton={onClickButtonText}
                        handleCustomSearch={(e) => handleCustomSearch(e)}
                        handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
                        campaignNegativeTargetingExport={true}
                    />
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className='col-12 negative-keyword-table-container'>
                        <CustomTable
                            tableData={tableData}
                            TableCols={NegativeKeywordsDataGridCols}
                            tableWithoutFixedCoulmns={true}
                            tableHeaderBlur={true}
                            tableLoading={!loading}
                            camapignNegativeTargeting={true}
                            tablefooterData={tablefooterData}
                            tableFooterpositionTop={true}
                            noDataFoundMesageTitle='No data available'
                            noDataFoundMessageSubTitle="Please try adjusting your filters to see performance data" />
                    </div>
                }
                <div className='fixed-bottom pb-3'>
                    <CustomFooter />
                </div>
            </div>
            {showPreviewModal && (
                <div className={`modal modal-custom fade ${showPreviewModal ? "show" : "false"}  ${showPreviewModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-xl modal-dialog-centered mx-auto add-negative-keywords-modal-width" role="document" ref={modalRef}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-17">Add negative keywords</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPreviewModal(false)}></button>
                            </div>
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!loading &&
                                <div className="modal-body negative-keyword-body py-0">
                                    <div className='row model-negative-keyword-content'>
                                        <div className='col-md-6 bg-white border-end pt-3'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <div className="fs-12 fw-bold my-1">
                                                        Match type
                                                        <Tooltip
                                                            title={tooltipContent(
                                                                "negative Keyword targeting Match type"
                                                            )}
                                                            placement="top"
                                                            arrow
                                                            className="custom-tooltip-position cursor-pointer "
                                                        >
                                                            <span className="mx-1">
                                                                <svg
                                                                    viewBox="0 0 512 512"
                                                                    width="12"
                                                                    height="12"
                                                                    className="fas fa-info-circle"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="#8290a4"
                                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className="d-flex">
                                                        <div className="form-check">
                                                            <input
                                                                type="radio"
                                                                id='exactMatchType'
                                                                name="negative_keyword_matchType"
                                                                className="form-check-input mb-3"
                                                                defaultChecked={keywordMatchtype === "Negative exact"}
                                                                onChange={(e) => handleChangekeywordMatchType('Negative exact')}
                                                            />
                                                            <label className="my-1 cursor-pointer" htmlFor='exactMatchType'>
                                                                <div className="fs-12">Negative exact</div>
                                                            </label>
                                                        </div>

                                                        <div className="mx-5 form-check">
                                                            <input
                                                                type="radio"
                                                                id="phraseMatchType"
                                                                className="form-check-input mb-3"
                                                                name="negative_keyword_matchType"
                                                                defaultChecked={keywordMatchtype === "Negative phrase"}
                                                                onChange={(e) => handleChangekeywordMatchType('Negative phrase')}
                                                            />
                                                            <label className="my-1 cursor-pointer" htmlFor='phraseMatchType'>
                                                                <div className="fs-12">Negative phrase</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row my-1">
                                                <div className="my-0">
                                                    <textarea
                                                        rows={8}
                                                        className="form-control fs-14 border-dark"
                                                        name="negative_keyword_text"
                                                        placeholder="Enter keywords separated by new line."
                                                        onChange={handleChangeNegativeKeywordText}
                                                        value={negativeKeywordText?.negative_keyword_text}
                                                    ></textarea>
                                                </div>
                                                <div className=''>
                                                    {successMessage && (
                                                        <span className='fs-13'>
                                                            <span><i className="bi bi-check text-success fs-21"></i></span><span className="text-success ml-2 fs-13">{successMessage}</span>
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="text-end my-2">
                                                    <button className={`btn btn-dark border-0 rounded-pill ${isAddingKeywords ? "text-secondary cursor-pointer-not-allowed" : `${negativeKeywordText ? "cursor-pointer text-dark" : "text-secondary cursor-pointer-not-allowed"}`}
                                                custom-button-background fs-12 `}
                                                        disabled={!negativeKeywordTextStatus}
                                                        onClick={onClickAddNegativeKeywords}>
                                                        Add keywords
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 product-preview-container pt-3">
                                            <div className="negative-keyword-right-container ">
                                                <div className="d-flex justify-content-between px-3">
                                                    <div className="fs-13 fw-bold ">{addNegativeKeywordsData?.length} added</div>
                                                    <div className={`fs-13 ${addNegativeKeywordsData?.length > 0 ? "custom-explore-text-color-two cursor-pointer logout" : "cursor-pointer-not-allowed remove-all-text"}`}
                                                        onClick={onClickRemoveAllData}>
                                                        Remove all
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between border-top border-bottom py-2 px-3">
                                                    <div className="fs-13">Keyword</div>
                                                    <div className="fs-13 me-5 pe-3">
                                                        <div className="mx-2 fs-12 ms-3">
                                                            Match type
                                                            <Tooltip
                                                                title={tooltipContent(
                                                                    "negative Keyword targeting table Match type"
                                                                )}
                                                                placement="top"
                                                                arrow
                                                                className="custom-tooltip-position cursor-pointer "
                                                            >
                                                                <span className="mx-1 cursor-pointer">
                                                                    <svg
                                                                        viewBox="0 0 512 512"
                                                                        width="12"
                                                                        height="12"
                                                                        className="fas fa-info-circle"
                                                                        data-fa-i2svg=""
                                                                    >
                                                                        <path
                                                                            fill="#8290a4"
                                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                        ></path>
                                                                    </svg>
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${addNegativeKeywordsData?.length > 12 ? "negative-keyword-data-scroll" : ""}`}>
                                                    {addNegativeKeywordsData?.map((keywordData: any, index: number) => (
                                                        <div key={index} className='d-flex justify-content-between border-bottom mx-3'>
                                                            <div className='my-1'>{keywordData?.negative_keyword_text}</div>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='text-start me-2 my-1'>{`${keywordData?.negative_keyword_matchType === "NEGATIVE_EXACT" ? "Negative exact" : "Negative phrase"}`}</div>
                                                                <div className="fs-21 ms-1">
                                                                    <i className="bi bi-x cursor-pointer" onClick={() => onClickCancelAddNegativeKeywordsData(index)} ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="custom-cancel-btn text-dark custom-button-background rounded-pill border-0 px-3 py-1 fs-13"
                                    onClick={() => setShowPreviewModal(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className={`rounded-pill border-0 px-3 py-1 fs-13 
                                    ${addNegativeKeywordsData?.length > 0 ? "request-call-button text-white cursor-pointer" : "custom-cancel-btn text-dark  custom-button-background  cursor-pointer"}`}
                                    onClick={onNegativekeywordsCreate}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state?.ProfileDataReducer,
        userData: state?.UserDataReducer,
        campaignData: state?.CampaignDataReducer
    };
};

const connectedNavBar = connect(mapStateToProps)(NegativeKeywordsListPage);
export { connectedNavBar as NegativeKeywordsListPage };