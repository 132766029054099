import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { DynamicForm } from '../../components/form-builder/form';
import { toast } from "react-toastify";
import { LOGIN } from '../../app/services/common.service';
import { UserData } from "../../redux/actions";
import { connect, useDispatch } from "react-redux";

interface Props {
    UserDataReducer: any;
    userData?: any;
}

const LoginPage = (props: Props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,6}$/;

    const loginFormData = [
        {
            title: "input",
            control: "",
            type: "text",
            label: "Email",
            name: "user_email",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: emailRegEx,
            maxLength: "",
            placeholder: "Please enter email ",
            error: "Pease enter email ",
        },
        {
            title: "input",
            control: "",
            type: 'password',
            label: "Password",
            name: "user_password",
            value: "",
            required: true,
            hide_mandatory_field: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter password",
            error: "Pease enter password",
            showPassword: false,
            customPasswordClass: "custom-login-password"
        },
    ];

    const onSubmitLoginForm = (data: any) => {
        const loginData = data.formData;
        const isEmailReg = /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,6}$/;
        const isEmail = isEmailReg.test(loginData.user_email);

        if (loginData?.user_email && loginData?.user_password) {
            const preparedData = {
                user_email: isEmail ? loginData.user_email : "",
                user_password: loginData.user_password,
            };
            setLoading(true);
            LOGIN(preparedData).then((res) => {
                if (res?.data?.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res?.data?.status === "success") {
                        if (props?.userData) {
                            dispatch(props.userData(res?.data?.data[0]));
                        }
                        const response = res.data;
                        const user_uuid = response.data[0].uuid;
                        localStorage.setItem("user_uuid", user_uuid);
                        localStorage.setItem('user_name', response.data[0].user_name);
                        localStorage.setItem('user_email', response.data[0].user_email);
                        toast.success(response.message, { autoClose: 2000 });
                        navigate("/manage-profile");
                        setLoading(false);
                    }
                }
            });
        }
        else {
            toast.error("Invalid Credentials", { autoClose: 2000 })
        }
    }

    const handlePasswordKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            navigate("/manage-profile");
        }
    };

    return (
        <div className="modal log-in-modal-border login-page">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content login-dynamic-form mx-auto">
                    <div className="login-form mx-2 mx-md-0 p-3">
                        <div className=''>
                            <div className='fs-24 text-center me-2 fw-700'>
                                Advise
                            </div>
                        </div>
                        <div className="col-12">
                            {loginFormData &&
                                <DynamicForm
                                    columns={1}
                                    formControls={loginFormData.map(control => {
                                        if (control.name === "password") {
                                            return { ...control, onKeyPress: handlePasswordKeyPress };
                                        }
                                        return control;
                                    })}
                                    userFormData={loginFormData}
                                    custom_btn_text={'Log in'}
                                    customClass={"d-flex justify-content-center"}
                                    cancelRoute={undefined}
                                    isEdit={false}
                                    onChildEvent={(e) => onSubmitLoginForm(e)}
                                    loading={loading}
                                    isNotEmptyFormAfterSubmit={true}
                                />
                            }
                        </div>
                        <div className='d-flex justify-content-center my-2 me-2'>
                            <NavLink to={"/forgot-password"} className='text-decoration-none custom-explore-text-color '><div className="fs-14 cursor-pointer fw-bold">Forgot Password</div></NavLink>
                        </div>
                        <div className='fs-14 text-center'>
                            <div className='fs-14'>Don't have an account?
                                <NavLink className='text-decoration-none ms-2' to="/register"><span className="fs-14 custom-explore-text-color logout cursor-pointer fw-bold">Register</span></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        UserDataReducer: state.UserDataReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        userData: (data: any) => dispatch(UserData(data)),
    };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export { connectedNavBar as LoginPage };
