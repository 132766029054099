import React, { useState } from 'react'

interface Props {
    tooltipHeader?: string;
    tooltipMainContent?: any;
    tooltipSubContent?: any;
    tooltipCloseStatus?: any;
    tooltipMainContentCaption?: any;
    sortOrders?: any;
    sortClicks?: any;
    keyWordIS?: any;
    keyWordIR?: any;
    keyWordTableText?: any;
    keyWordBroad?: string;
    keyWordPhrase?: string;
    keyWordExact?: string;
    tooltipLearnMoreText?: string;
    tooltipWomensItalicText?: string;
    questionIconStatus?: boolean;
    onClickLearnMoreText?: (data: any) => void;
    crossMarkClose?: boolean;
}

export const TooltipPage = (props: Props) => {
    const [tooltipCloseStatus, setTooltipCloseStatus] = useState<any>()
    const onClickCloseTooltip = () => {
        setTooltipCloseStatus(true);
        props?.tooltipCloseStatus(true);
    }

    const onClickTooltipSubContent = (data: any) => {
        if (props.onClickLearnMoreText) {
            props.onClickLearnMoreText({ tooltipLearnData: data })
        }
    }

    return (
        <div className='bg-white text-dark'>
            <div className='d-flex justify-content-between'>
                <div>
                    <h3 className='tooltip-header'>{props?.tooltipHeader}</h3>
                    {props?.tooltipMainContent}
                    {props?.tooltipWomensItalicText ? <em>{props?.tooltipWomensItalicText}</em> : ""}
                    {props?.tooltipMainContentCaption ? <span className='custom-explore-text-color fs-13 logout cursor-pointer py-2'> {props?.tooltipMainContentCaption}</span> : ""}
                    {props?.sortOrders && props?.sortClicks && (
                        <>
                            <div className='my-2 mb-3'><strong>Oders: </strong>{props?.sortOrders}</div>
                            <div><strong>Clicks: </strong>{props?.sortClicks}</div>
                        </>
                    )}
                    {props?.keyWordIS && props?.keyWordIR && props?.keyWordTableText && (
                        <div>
                            <div className=""><strong>Impression share (IS) </strong>{props?.keyWordIS}</div>
                            <div className="mb-3"><strong>Impression rank </strong>{props?.keyWordIR}</div>
                            <div className="">{props?.keyWordTableText}</div>
                        </div>
                    )}
                    {props?.keyWordBroad && props?.keyWordExact && props?.keyWordPhrase && props?.tooltipLearnMoreText && props?.keyWordTableText && (
                        <div>
                            <div className="">{props?.keyWordTableText}</div>
                            <div className=""><strong>Broad:</strong>{props?.keyWordBroad}</div>
                            <div className=""><strong>Phrase:</strong>{props?.keyWordPhrase}</div>
                            <div className=""><strong>Exact:</strong>{props?.keyWordExact} <span className='custom-explore-text-color fs-13 logout cursor-pointer py-2'>{props?.tooltipLearnMoreText}</span></div>
                        </div>
                    )}
                </div>
                {!props?.crossMarkClose && (
                    <div className="fs-13 mb-2"><i className="bi bi-x tooltip-wrong-icon text-secondary cursor-pointer fs-18 " onClick={onClickCloseTooltip}></i></div>
                )}
            </div>
            {props?.tooltipSubContent && (
                <div className="fs-13">
                    <div className='custom-explore-text-color fs-13 logout cursor-pointer py-2'>
                        <span>
                            {!props?.questionIconStatus && (
                                <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                            )}
                        </span> <span onClick={() => onClickTooltipSubContent(props?.tooltipSubContent)}>{props?.tooltipSubContent ? props?.tooltipSubContent : ""}</span>
                    </div>
                </div>
            )}
        </div>
    )
}
