import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material';
import { TooltipPage } from '../custom-tooltip/tooltipPage';
import SmallWrongIcon from "./../../assets/images/svg_icons/small-wrong.svg";
import SearchIcon from "./../../assets/images/svg_icons/search.svg";
import SearchSecondIcon from "./../../assets/images/svg_icons/search-two.svg";
import DownloadIcon from "./../../assets/images/svg_icons/download.svg";
import RupeeIcon from "./../../assets/images/svg_icons/rupee.svg";
import WrongIcon from "./../../assets/images/svg_icons/wrong.svg";
import NoProductImage from './../../assets/images/no-product-image.png';
import { CHECK_PRODUCT_ELIGIBILITY, GET_PROFILE_PRODUCTS } from '../../app/services/campaigns.service';
import { toast } from "react-toastify";

interface Props {
    productsAddToAvertise?: boolean;
    addProdcutsLength?: any;
    addedProdcutsLength?: (data: any) => void;
    adGroupsCreationStatus?: boolean;
    getAdGroupKeywordSuggestionDataStatus?: (data: any) => void;
    addedProductsData?: (data: any) => void;
    addedProductsAsinSkuData?: (data: any) => void;
    addedGroupsProductsData?: (data: any) => void;
    addedAdGroupsProductsAsinData?: (data: any) => void;
    groupsData?: any;
    linkStatus?: boolean;
    groupId?: any;
    groupProducts?: any
}

export const ProductsCardPage = (props: Props) => {
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [search, setSearch] = useState<any>();
    const [showWrongIcon, setShowWrongIcon] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState("Newest First");
    const [loading, setLoading] = useState<boolean>(false);
    const [productsData, setProductsData] = useState<any>();
    const [originalProductsData, setOriginalProductsData] = useState<any>();
    const [addProductsData, setAddproductsData] = useState<any>(props?.groupsData ? props?.groupsData : []);
    const [addedProductsAsinData, setAddedProductsAsinData] = useState<any>([]);
    const [addedProductsDataToGroups, setAddedProductsDataToGroups] = useState<any>([])
    const [addedProductsAsinSkuData, setAddedProductsAsinSkuData] = useState<any>([]);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const sellerID = localStorage.getItem("seller_id");
    const profileType = localStorage.getItem("type");


    const showButtonTextData = [
        {
            id: 1,
            name: "Newest First",
            value: "Newest First",
        },
        {
            id: 2,
            name: "Oldest First",
            value: "Oldest First",
        },
        {
            id: 3,
            name: "Suggested",
            value: "Suggested",
        },
    ];

    useEffect(() => {
        getProfileProducts();
    }, [])


    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    };

    const handleSearchInput = (e: any) => {
        const searchTerm = e.target.value;
        if (searchTerm?.trim()?.length > 0) {
            for (let i = 0; i < originalProductsData?.length; i++) {
                originalProductsData[i].dummyy_title = originalProductsData[i].title.toLowerCase();
                originalProductsData[i].dummyy_asin = originalProductsData[i].asin.toLowerCase();
                originalProductsData[i].dummyy_sku = originalProductsData[i].sku;
            }
            const searchData = originalProductsData?.filter((el: any) => el?.dummyy_title.includes(searchTerm.toLowerCase()) || el?.dummyy_asin.includes(searchTerm.toLowerCase()) || el?.dummyy_sku.includes(searchTerm));
            setProductsData(searchData)
        } else {
            setProductsData(originalProductsData)
            getProfileProducts();
        }
        setSearch(searchTerm);
        if (searchTerm?.trim()?.length > 0) {
            setShowWrongIcon(true)
        } else if (searchTerm?.trim()?.length <= 0) {
            setShowWrongIcon(false)
        }
    };

    const onClickWrong = () => {
        const search = "";
        setSearch(search);
        setProductsData(originalProductsData)
        // getProfileProducts();
        setShowWrongIcon(false);
    };

    const handleButtonTextChange = (newValue: string) => {
        setSelectedValue(newValue);
    };

    const handleQuotationFileUpload = (e: any) => {
        const file = e.target.files[0];
    };

    const tooltipContent = (tooltipTitle: string) => {
        if (tooltipTitle === "Products") {
            return (
                <TooltipPage
                    tooltipMainContent={"Add products that you want to promote in this campaign."}
                    tooltipSubContent={""}
                    tooltipCloseStatus={onCloseTooltip}
                />
            )
        }
    }

    const getProductsChecking = (response: any) => {
        for (let i = 0; i < props?.groupProducts?.length; i++) {
            for (let k = 0; k < response?.length; k++) {
                if (response[k].asin === props.groupProducts[i].asin) {
                    response[k].isAdded = true;
                }
            }
        }
    }
    const getProfileProducts = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileType === "vendor" ? sellerID : profileID
        }
        setLoading(true);
        GET_PROFILE_PRODUCTS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data.data;
                    getProductsChecking(response);
                    setOriginalProductsData(response)
                    setProductsData(response);
                    setLoading(false);
                }
            }
        });
    }

    const onClickCancelProduct = (item: any, itemIndex: number) => {
        item.groupId = props.groupId;
        const productData = productsData;
        for (let index = 0; index < productData?.length; index++) {
            if (productData[index].product_id === item.product_id) {
                productData[index].product_btn_status = false;
            }
        }
        const groupsProductsData = productData;
        setProductsData([...productData]);
        const preparedData = addProductsData;
        preparedData.splice(itemIndex, 1);
        if (props?.addedProductsData) {
            props?.addedProductsData(preparedData);
        }
        if (props?.addedProdcutsLength) {
            props.addedProdcutsLength(preparedData?.length);
        }
        if (props?.addedGroupsProductsData) {
            props?.addedGroupsProductsData(preparedData)
        }
        addedProductsAsinData.splice(itemIndex, 1)
        if (props?.addedAdGroupsProductsAsinData) {
            props?.addedAdGroupsProductsAsinData(addedProductsAsinData)
        }
        addedProductsAsinSkuData.splice(item?.asin, 1)
        if (props?.addedProductsAsinSkuData) {
            props?.addedProductsAsinSkuData(addedProductsAsinSkuData)
        }
        setAddproductsData([...preparedData]);
        setAddedProductsDataToGroups([...preparedData])
        setAddedProductsAsinData([...addedProductsAsinData]);
    }

    const onClickAddProduct = (item: any, productIndex: number) => {

        const checkEligibilityData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            asin: item?.asin
        }
        // setLoading(true)
        // CHECK_PRODUCT_ELIGIBILITY(checkEligibilityData).then((res) => {
        //     if (res.data.status === "error" || res?.data?.code == 500) {
        //         toast.error(res.data.message);
        //         setLoading(false);
        //     } else {
        //         if (res) {
        //             if (res.data.code === 200) {
        item.groupId = props.groupId;
        item.default_bid = 3;
        item.state = "ENABLED";
        const productData = productsData;
        productData[productIndex].product_btn_status = true;
        setProductsData([...productData]);
        const preparedData = addProductsData;
        preparedData.push(item);
        if (props?.addedProductsData) {
            props?.addedProductsData(preparedData)
        }
        if (props?.addedProdcutsLength) {
            props.addedProdcutsLength(preparedData?.length)
        }
        if (props?.addedGroupsProductsData) {
            props?.addedGroupsProductsData(preparedData)
        }
        addedProductsAsinData.push(item?.asin);
        if (props?.addedAdGroupsProductsAsinData) {
            props?.addedAdGroupsProductsAsinData(addedProductsAsinData)
        }
        const asinSkuData = addedProductsAsinSkuData;
        asinSkuData.push(
            {
                state: "ENABLED",
                sku: item?.sku,
                asin: item?.asin
            })
        setAddedProductsAsinSkuData([...asinSkuData])
        if (props?.addedProductsAsinSkuData) {
            props?.addedProductsAsinSkuData(addedProductsAsinSkuData)
        }
        setAddedProductsAsinData([...addedProductsAsinData])
        setAddproductsData([...preparedData]);
        setAddedProductsDataToGroups([...preparedData])
        if (props?.adGroupsCreationStatus) {
            if (props?.getAdGroupKeywordSuggestionDataStatus) {
                props?.getAdGroupKeywordSuggestionDataStatus({
                    data:
                    {
                        asinData: addedProductsAsinData,
                        status: true
                    }
                });
            }
        }
        // }
        //             toast.success(res.data.message);
        //             setLoading(false);
        //         }
        //     }
        // });

    }

    return (
        <div className={`products-card-container products-card mx-auto ${props.productsAddToAvertise ? "prodcut-card-ads" : ""}`}>
            <div className={`card text-bg-white  my-2 ${props.productsAddToAvertise ? "border-0" : "mb-3"}`}>
                {!props?.productsAddToAvertise && (
                    <div className={`card-header bg-white ${props?.productsAddToAvertise ? "d-none" : "d-block"}`}>
                        <div className="d-flex justify-content-between py-2">
                            <div className="fs-24 fw-light">
                                Products
                                <Tooltip
                                    title={tooltipContent("Products")}
                                    placement="top"
                                    arrow
                                    className="custom-tooltip-position cursor-pointer"
                                >
                                    <span className="ms-1">
                                        <svg
                                            viewBox="0 0 512 512"
                                            width="12"
                                            height="12"
                                            className="fas fa-info-circle mb-2"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="#8290a4"
                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Tooltip>
                            </div>
                            {!props?.linkStatus && (
                                <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                                    <span>
                                        <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                    </span>
                                    How to add products to your campaign
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={`card-body py-0 ${props?.productsAddToAvertise ? "p-0" : ""}`}>
                    <div className="row text-center px-0">
                        <div className="col-6 bg-white products-tabs-section border-right py-0">
                            <div className="tabs-section px-0">
                                <div
                                    className="nav nav-underline"
                                    id="nav-tab"
                                    role="tablist"
                                >
                                    <button
                                        className="nav-link text-dark active fs-13"
                                        id="nav-home-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-home" type="button" role="tab"
                                        aria-controls="nav-home" aria-selected="true"
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active search-tab"
                                    id="nav-home"
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                    tab-index="0"
                                >
                                    <div className="row border-top border-bottom">
                                        <div className="col-12 px-2">
                                            <div className="input-group my-2">
                                                <span className="input-group-text  bg-white border-dark"><img src={SearchIcon} alt="" className="" /></span>
                                                <input type="text" name="search" className={`form-control border-dark  border-start-0 px-0 fs-13  ${showWrongIcon ? "border-end-0" : "rounded-end"}`} placeholder="Search by product name, ASIN or SKU"
                                                    value={search} onChange={handleSearchInput} />
                                                {showWrongIcon && (
                                                    <span className="input-group-text  bg-white border-dark rounded-start-0 rounded-end">
                                                        <img src={SmallWrongIcon} alt="" className="cursor-pointer fs-13" onClick={onClickWrong} />
                                                    </span>
                                                )}
                                                <span className="input-group-text  border-dark border-0 ms-1 search-button rounded-end-pill cursor-pointer">
                                                    <img src={SearchSecondIcon} alt="" className={``} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row border-bottom d-none">
                                        <div className="col-12 px-0 my-2 fw-bold text-start ms-2 fs-13">
                                            <div className="d-flex">
                                                <div>Show :</div>
                                                <div className="w-25 ms-2">
                                                    <div className="btn-group">
                                                        <button
                                                            className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            {selectedValue}{" "}
                                                            <span>
                                                                <svg
                                                                    viewBox="0 0 389 512"
                                                                    width="13"
                                                                    height="13"
                                                                    className="fas fa-angle-down show-text-icon"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                        <ul className="dropdown-menu newest-first-dropdown">
                                                            <li className="fs-13 py-1">
                                                                {showButtonTextData?.map(
                                                                    (item: any, itemIndex: number) => (
                                                                        <button
                                                                            key={itemIndex}
                                                                            className={`drop-down-item border-0 bg-transparent text-center py-1 ${selectedValue === item?.value
                                                                                ? "active"
                                                                                : ""
                                                                                }`}
                                                                            value={item.value}
                                                                            onClick={() =>
                                                                                handleButtonTextChange(
                                                                                    item?.value
                                                                                )
                                                                            }
                                                                        >
                                                                            {item.name}
                                                                        </button>
                                                                    )
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {loading &&
                                        <div className="text-center p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                    {!loading &&
                                        <div className={`products_scroll products_scroll_ai_group_height ${productsData?.length <= 3 ? "less-prodcuts" : "pe-2"}`}>
                                            {productsData?.length > 0 && productsData?.map((item: any, index: number) => (
                                                <div className={`row py-2 border-bottom ${item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "prodcuts-gray-out-bg pe-1" : ""} `} >
                                                    <div className="col-2 px-0">
                                                        <img src={item?.image_url ? item?.image_url : NoProductImage} alt="" style={{ width: '70px', height: '70px', objectFit: "contain" }} />
                                                    </div>
                                                    <div className="col-7 px-0 text-start">
                                                        <div className="product-header">
                                                            <a>{item?.title}</a>
                                                        </div>
                                                        <div>
                                                            <span className="amount-info">
                                                                <span className="">
                                                                    <img src={RupeeIcon} alt="" className="" />
                                                                </span>
                                                                {item?.price_to_pay?.amount}
                                                            </span> |
                                                            <span className="stock-info">
                                                                {item?.availability === "OUT_OF_STOCK" && (
                                                                    <Tooltip
                                                                        title={tooltipContent("stock-info")}
                                                                        placement="top"
                                                                        arrow
                                                                        className="custom-tooltip-position cursor-pointer "
                                                                    >
                                                                        <span className="ms-1">
                                                                            <svg
                                                                                viewBox="0 0 512 512"
                                                                                width="12"
                                                                                height="12"
                                                                                className="fas fa-info-circle mb-1"
                                                                                data-fa-i2svg=""
                                                                            >
                                                                                <path
                                                                                    fill="#8290a4"
                                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                                <span className={`px-1 ${item?.availability === "IN_STOCK" ? "text-secondary text-capitalize" : ""}`}>{item?.availability?.replace(/_/g, " ")}</span>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="product-details">ASIN: {item?.asin}</span>
                                                            <span> | </span>
                                                            <span className="product-details">SKU: {item?.sku}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 ps-0">
                                                        <div className="text-end">
                                                            {item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "" :
                                                                <button disabled={item?.product_btn_status} style={{ padding: '3px 10px' }} onClick={() => onClickAddProduct(item, index)}
                                                                    className={`btn-dark border-0 rounded-pill custom-button-background fs-13  ${!item?.product_btn_status ? 'cursor-pointer text-dark' : 'cursor-pointer-not-allowed keyword-below-text-color'}`}
                                                                >
                                                                    {item?.product_btn_status || item?.isAdded ? 'Added' : 'Add'}
                                                                </button>
                                                            }
                                                        </div>

                                                        <div className={`pt-1 ${item?.availability?.replace(/_/g, " ") === "OUT OF STOCK" ? "pt-3" : ""}`}>
                                                            <span className="add-varaiation-label">Add variations</span>
                                                            <span>
                                                                <Tooltip
                                                                    title={tooltipContent("add-variation")}
                                                                    placement="top"
                                                                    arrow
                                                                    className="custom-tooltip-position cursor-pointer "
                                                                >
                                                                    <span className="ms-1">
                                                                        <svg
                                                                            viewBox="0 0 512 512"
                                                                            width="12"
                                                                            height="12"
                                                                            className="fas fa-info-circle mb-1"
                                                                            data-fa-i2svg=""
                                                                        >
                                                                            <path
                                                                                fill="#8290a4"
                                                                                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>
                                                                </Tooltip>
                                                            </span>
                                                        </div>
                                                        <div className="text-end">
                                                            <Tooltip
                                                                title={tooltipContent("ad-paused")}
                                                                placement="top"
                                                                arrow
                                                                className="custom-tooltip-position cursor-pointer "
                                                            >
                                                                <span className="ms-1">
                                                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                            {productsData?.length == 0 && (
                                                <div className="col-12 fs-13 my-4 pt-1">
                                                    Enter a search term to find products sold on Amazon.
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>
                                <div
                                    className="tab-pane fade enterlist-tab"
                                    id="nav-profile"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                    tab-index="0"
                                >
                                    <div className="row border-top">
                                        <div className="my-3">
                                            <textarea
                                                rows={6}
                                                name="products-card-text"
                                                className="form-control fs-14 border-dark"
                                                placeholder="Enter ASINs separated by a comma, space or new line."
                                                defaultValue={''}
                                            ></textarea>
                                        </div>
                                        <div className=" text-end">
                                            <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade upload-tab"
                                    id="nav-contact"
                                    role="tabpanel"
                                    aria-labelledby="nav-contact-tab"
                                    tab-index="0"
                                >
                                    <div className="row border-top">
                                        <div className="my-3 fs-13 text-start">
                                            Use the template to make sure your information
                                            uploads accurately.
                                        </div>
                                        <div className="text-start">
                                            <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                                <span className="me-2">
                                                    <img src={DownloadIcon} alt="" />
                                                </span>
                                                Download the CSV template
                                            </button>
                                        </div>
                                        <div className="upload-files">
                                            <div
                                                data-takt-id="ups_upload_asins_input"
                                                className="upload-icon fs-13"
                                            >
                                                <div className="mt-4 pt-2">
                                                    <label
                                                        htmlFor="input_file_three"
                                                        className="cursor-pointer"
                                                    >
                                                        <div className="">
                                                            <div>
                                                                <i className="bi bi-upload"></i>
                                                            </div>
                                                            <div className="fs-13 my-1">
                                                                Drag and drop file to add
                                                            </div>
                                                            <div className="fs-13 my-1">
                                                                or click to select from your computer
                                                            </div>
                                                            <div className="fs-13 my-1">
                                                                Accepted formats: CSV, TSV, XLSX
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="input_file_three"
                                                        name="file"
                                                        className="d-none "
                                                        onChange={handleQuotationFileUpload}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 product-preview-container">
                            <div className="fs-13 text-start fw-bold py-2">
                                {addProductsData?.length} products
                            </div>
                            {addProductsData?.length === 0 && <div className="fs-13 add-products-text-color">
                                Add Products
                            </div>}
                            <div className='added-prodcuts_scroll' style={{ height: '460px', overflowY: "auto" }}>
                                {addProductsData?.map((item: any, index: number) => (
                                    <div className="row py-2 border-bottom">
                                        <div className="col-2 px-0">
                                            <img src={item?.image_url ? item?.image_url : NoProductImage} alt="" style={{ width: '70px', height: '70px', objectFit: "contain" }} />
                                        </div>
                                        <div className="col-7 px-0 text-start">
                                            <div className="product-header">
                                                <a>{item.title}</a>
                                            </div>
                                            <div>
                                                <span className="amount-info">
                                                    <span className="">
                                                        <img src={RupeeIcon} alt="" className="" />
                                                    </span>
                                                    {item?.price_to_pay?.amount}
                                                </span> |
                                                <span className="stock-info">
                                                    {item?.availability === "OUT_OF_STOCK" && (
                                                        <Tooltip
                                                            title={tooltipContent("stock-info")}
                                                            placement="top"
                                                            arrow
                                                            className="custom-tooltip-position cursor-pointer "
                                                        >
                                                            <span className="ms-1">
                                                                <svg
                                                                    viewBox="0 0 512 512"
                                                                    width="12"
                                                                    height="12"
                                                                    className="fas fa-info-circle mb-1"
                                                                    data-fa-i2svg=""
                                                                >
                                                                    <path
                                                                        fill="#8290a4"
                                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                    <span className={`px-1 stock-capitalize ${item?.availability === "IN_STOCK" ? "text-secondary text-capitalize" : ""}`}>{item?.availability?.replace(/_/g, " ")}</span>
                                                </span>
                                            </div>
                                            <div>
                                                <span className="product-details">ASIN: {item?.asin}</span>
                                                <span> | </span>
                                                <span className="product-details">SKU: {item?.sku}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 ps-0">
                                            <div className="text-end">
                                                <div className="bg-transaprent border-0">
                                                    <img
                                                        src={WrongIcon} alt=""
                                                        className="mt-1 cursor-pointer"
                                                        onClick={() => onClickCancelProduct(item, index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-end d-none">
                                                <Tooltip
                                                    title={tooltipContent("ad-paused")}
                                                    placement="top"
                                                    arrow
                                                    className="custom-tooltip-position cursor-pointer "
                                                >
                                                    <span className="ms-1">
                                                        <i className="bi bi-exclamation-triangle-fill"></i>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
