import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  columns?: any;
  formControls?: any;
  userFormData: any;
  cancelRoute: any;
  customClass?: any;
  custom_btn_text?: string;
  isEdit?: boolean;
  isView?: boolean;
  editData?: any;
  customClearButton?: any;
  clearStatus?: any;
  loading?: any;
  onChildEvent: (data: string) => void;
  isNotEmptyFormAfterSubmit?: boolean;
  onParentDropdownEvent?: (data: any) => void;
  showPasswordIconStatus?: (data: any) => void;
}

export const
  DynamicForm = (props: Props) => {
    const [formData, setFormData] = useState<any>({});
    const clearStatus = true;
    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();
    const [formFieldsArray, setFormFieldsArray] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      const formsValues: any = {};
      formFieldsArray.forEach((element: any) => {
        formsValues[element.name] = element.value;
      });
      setFormData(formsValues);
      setFormFieldsArray([...props?.userFormData]);
    }, [props]);

    const handleInput = (e: any, index: number, data: any) => {
      const formData = formFieldsArray;
      const { name, value } = e.target;
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));

      const field = formFieldsArray[index];
      const { regex, label } = field;
      const newErrors: any = { ...errors };

      if (regex && value && !RegExp(regex).test(value)) {
        newErrors[name] = `Please enter a valid ${label.toLowerCase()}`;
      } else {
        delete newErrors[name];
      }
      formData[index].value = value;
      setFormFieldsArray([...formData]);
      setErrors(newErrors);
      if (field?.isParendDropDown) {
        if (props?.onParentDropdownEvent) {
          props?.onParentDropdownEvent({ name, value });
        }
      }
    };

    const onClickSubmit = (e: any) => {
      e.preventDefault();
      checkFormValidations();
    };

    const checkFormValidations = () => {
      setIsLoading(true);
      const isValid = validateForm();
      if (isValid) {
        setErrors({});
        const data: any = { formData, type: props.isEdit ? "edit" : "create" };
        props.onChildEvent(data);
        if (!props?.isNotEmptyFormAfterSubmit) {
          formFieldsArray.forEach((element: any) => {
            element.value = "";
          });
        }
        setIsLoading(false);
      } else {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    }

    const validateForm = () => {
      let isValid = true;
      const newErrors: any = {};

      formFieldsArray?.forEach((field: any) => {
        const { name, label, required, regex, error } = field;
        const value =
          typeof formData[name] === "string"
            ? formData[name]?.trim() || field.value
            : formData[name] || field.value;
        if (required && (!value || value === "")) {
          newErrors[name] = error || `Please enter a ${label.toLowerCase()}`;
          isValid = false;
        }
        if (regex && value && !RegExp(regex).test(value)) {
          newErrors[name] =
            error || `Please enter a valid ${label.toLowerCase()}`;
          isValid = false;
        }
      });

      setErrors(newErrors);
      return isValid;
    };

    const onClickCancel = () => {
      navigate(props.cancelRoute);
    };

    const onClickClear = (event: any) => {
      props.clearStatus(clearStatus);
    };

    const handleKeyPress = (event: any) => {
      const code = event.keyCode || event.which;
      if (code === 13 && props.custom_btn_text === "Log in") {
        checkFormValidations();
        event.preventDefault();
      }
    };

    const togglePasswordVisibility = (index: any) => {
      const updatedFormData = [...formFieldsArray];
      updatedFormData[index].showPassword = !updatedFormData[index].showPassword;
      updatedFormData[index].type = updatedFormData[index].showPassword ? 'text' : 'password';
      setFormFieldsArray([...updatedFormData]);
    };

    return (
      <div className="row px-2">
        {formFieldsArray?.map((data: any, index: number) => (
          <React.Fragment key={index}>
            {data.title === "input" && (
              <div className={`col-md-${12 / props?.columns} mb-2`}>
                <label className="form-label fs-17 d-flex justify-content-start">
                  {data.label}
                  {data.required && (
                    <span className="text-danger fw-bold mx-2">
                      {data.hide_mandatory_field ? <span></span> : <span>*</span>}
                    </span>
                  )}
                </label>
                <input
                  type={data.type}
                  name={data.name}
                  className={`form-control fs-14 ${data?.label === "Password" ? "position-relative" : ""}`}
                  placeholder={data.placeholder}
                  onChange={(event) => {
                    handleInput(event, index, data);
                  }}
                  onKeyPress={handleKeyPress}
                  required={data.required}
                  maxLength={data.maxLength}
                  value={data.value}
                />
                {data?.label === "Password" && (
                  <i
                    className={data.showPassword === true ? `bi bi-eye-slash-fill cursor-pointer position-absolute ${data?.customPasswordClass}` : `bi bi-eye-fill cursor-pointer position-absolute ${data?.customPasswordClass}`}
                    onClick={() => togglePasswordVisibility(index)}
                  ></i>
                )}
                {Object.keys(errors).length > 0 && (
                  <div className="text-danger text-start">
                    {errors[data.name] || ""}
                  </div>
                )}
              </div>
            )}
            {data.title === "text area" && (
              <div className={`col-md-${12 / props?.columns} mb-2`}>
                <label className="form-label d-flex justify-content-start">
                  {data.label}
                  {data.required && (
                    <span className="text-danger fw-bold mx-2">
                      {data.hide_mandatory_field ? <span></span> : <span>*</span>}
                    </span>
                  )}
                </label>
                <textarea
                  name={data.name}
                  rows={data.rows}
                  className="form-control"
                  placeholder={data.placeholder}
                  onChange={(event) => {
                    handleInput(event, index, data);
                  }}
                  onKeyPress={handleKeyPress}
                  required={data.required}
                  maxLength={data.maxLength}
                  value={data.value}
                ></textarea>
                {Object.keys(errors).length > 0 && (
                  <div className="text-danger text-start">
                    {errors[data.name] || ""}
                  </div>
                )}
              </div>
            )}
            {data.title === "dropdown" && (
              <div className={`col-md-${12 / props?.columns} mb-2`}>
                <label className="form-label d-flex justify-content-start">
                  {data.label} {data.required}
                  {data.required && (
                    <span className="text-danger fw-bold mx-2">*</span>
                  )}
                </label>
                <select
                  name={data.name}
                  className="form-select custom-select"
                  placeholder={data.placeholder}
                  onChange={(event) => {
                    handleInput(event, index, data);
                  }}
                  value={data.value}
                >
                  <option value="" disabled={data?.required}>
                    {data.placeholder}
                  </option>
                  {data.option.map((option: any, index: number) => {
                    return (
                      <option key={index} value={option?.value}>
                        {option?.lable}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </React.Fragment>
        ))}
        <div
          className={`col-md-${12 / props?.columns} ${props?.customClass ? props?.customClass : `d-flex `
            } mt-4 mb-2`}
        >
          <button
            className={
              props?.custom_btn_text
                ? `custom-bg-btn rounded border-0 custom-shadow px-4 text-white py-2 d-flex justify-content-center me-3 ${props?.custom_btn_text === "Log in" ? "w-35 fs-18 fw-bold" : ""}`
                : "border-0 bg-transparent"
            }
            id={props.loading ? "custom-bg-loading" : ""}
            onClick={onClickSubmit}
            disabled={props.loading || isLoading}
          >
            {props?.custom_btn_text === "Log in" ||
              props?.custom_btn_text === "Reset Password" ||
              props?.custom_btn_text === "Verify" ||
              props?.custom_btn_text === "Submit" ? (
              <>
                {props.loading ? "" : props?.custom_btn_text}
                {isLoading ||
                  (props.loading && (
                    <div className="text-center px-3">
                      <div
                        className="spinner-border login-spinner-border text-white"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>{props?.custom_btn_text}</>
            )}
          </button>
          {props.cancelRoute && (
            <button
              className="custom-cancel-btn rounded border-0 px-4 py-1"
              onClick={onClickCancel}
            >
              Cancel
            </button>
          )}
          {props.customClearButton ? (
            <button
              className="custom-clear-btn rounded border-0 px-4 py-1"
              onClick={onClickClear}
            >
              {props.customClearButton ? props.customClearButton : ""}
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
