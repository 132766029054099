import { CustomTableCol } from "../../../components/custom-table/types";

export const vendorDataGridColumn: CustomTableCol[] = [
  {
    title: "Vendor Name",
    control: "vendor_name",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px", left: 214 },
    isLink: true,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Sellers",
    control: "selller",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    isDropdown: true,
    inputConfig: {
      placeholder: 'Select seller',
      name: 'seller',
      options: [
        {
          name: 'Hampa wellness',
          value: '1'
        },
        {
          name: 'Tarbull',
          value: '2'
        },
        {
          name: 'Sugar Watchers',
          value: '3'
        }
      ]
    }
  },
  {
    title: "Link Status",
    control: "is_linked",
    sortable: true,
    canShowColumn: true,
    style: { width: "200px", left: 214 },
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Actions",
    control: "actions",
    sortable: true,
    canShowColumn: true,
    isSave: true,
    style: { width: '100px' },
  }
];
