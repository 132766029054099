import React, { useState } from 'react'
import { TooltipPage } from '../custom-tooltip/tooltipPage';
import { ProductTargetCategoriesFilter } from '../campaign-creation/categories-filter';
import SmallWrongIcon from "./../../assets/images/svg_icons/small-wrong.svg";
import SearchIcon from "./../../assets/images/svg_icons/search.svg";
import SearchSecondIcon from "./../../assets/images/svg_icons/search-two.svg";
import { ProductTargetingIndividualProductsFilter } from '../campaign-creation/individual-products-filter';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography, } from "@mui/material";
import DownloadIcon from "./../../assets/images/svg_icons/download.svg";
import RupeeIcon from "./../../assets/images/svg_icons/rupee.svg";
import MultiRangeSlider from "multi-range-slider-react";

interface Props {
    adGroupTargetingAddProductsToCards?: boolean;
}

export const ProdcutTargetingCards = (props: Props) => {
    const [search, setSearch] = useState<any>();
    const [showWrongIcon, setShowWrongIcon] = useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [showRefineModal, setShowRefineModal] = useState<boolean>(false);
    const [selectedProductTargetingBidValue, setSelectedProductTargetingBidValue] = useState<any>("Suggested bid");

    const reviewStarRating = ["0", "1", "2", "3", "4", "5"];
    const [minCaption, set_minCaption] = useState("");
    const [maxCaption, set_maxCaption] = useState("");

    const accordionsData = [
        {
            id: 0,
            accordionTitle: "Watches",
        },
    ];

    const keywordTargetingBidData = [
        {
            name: "Suggested bid",
            sub_title: `Based on past bidding activity to predict bids that are more likely to win`,
            value: "Suggested bid",
        },
        {
            name: "Custom bid",
            sub_title: `Can custom bids for each keyword without overriding your set bid amount`,
            value: "Custom bid",
        },
        {
            name: "Default bid",
            sub_title: `Can raise or lower all your set amounts in bulk`,
            value: "Default bid",
        },
    ];
    const tooltipContent = (data: any) => {
        if (data === " ") {
            return (<TooltipPage />)
        }
    }

    const getCategoriesTooltipText = (data: any) => {
        if (data) {
            tooltipContent(data);
        }
    };

    const handleSearchInput = (e: any) => {
        const search = e.target.value;
        if (search === "") {
            setShowWrongIcon(false);
        } else {
            setShowWrongIcon(true);
        }
        setSearch(search);
    };

    const onClickWrong = () => {
        const search = "";
        setSearch(search);
        setShowWrongIcon(false);
    };

    const handleChangeAccordion = (e: any, index: number) => {
        const data = accordionsData;
        if (index === data[0]?.id) {
            setExpanded(!expanded);
        }
    };

    const handleClickAdd = (e: any, data: any) => {
    };

    const handleClickRefine = (e: any, data: any) => {
        setShowRefineModal(true);
    };

    const handleProductTargetingBidTextChange = (option: string) => {
        setSelectedProductTargetingBidValue(option);
    };

    const handleQuotationFileUpload = (e: any) => {
        const file = e.target.files[0];
    };

    return (
        <div className='container-fluid'>
            <div className="product-targeting-card-container card-width mx-auto my-1">
                <div className={`card text-bg-white ad-group-settings my-1 ${props?.adGroupTargetingAddProductsToCards ? "border-0" : ""}`}>
                    {!props?.adGroupTargetingAddProductsToCards && (
                        <div className="card-header bg-white">
                            <div className="d-flex justify-content-between py-2">
                                <div className="fs-24 fw-light">
                                    Product targeting
                                    <Tooltip
                                        title={tooltipContent(
                                            "Product targeting card header"
                                        )}
                                        placement="top"
                                        arrow
                                        className="custom-tooltip-position cursor-pointer"
                                    >
                                        <span className="ms-1">
                                            <svg
                                                viewBox="0 0 512 512"
                                                width="12"
                                                height="12"
                                                className="fas fa-info-circle"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="#8290a4"
                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                ></path>
                                            </svg>
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className="custom-explore-text-color fs-13 logout cursor-pointer py-2">
                                    <span>
                                        <i className="bi bi-question-circle-fill custom-explore-text-color me-2"></i>
                                    </span>
                                    How to use product targetings
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={`card-body  ${props?.adGroupTargetingAddProductsToCards ? "p-0" : "py-0"}`}>
                        <div className="row text-center px-0">
                            <div className="col-md-6 bg-white products-tabs-section border-right py-0 position-relative">
                                <div className="tabs-section px-0">
                                    <div
                                        className="nav nav-underline"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        <button
                                            className="nav-link text-dark active fs-15"
                                            id="nav-categories"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-product-targeting-categories"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-product-targeting-categories"
                                            aria-selected="true"
                                        >
                                            Categories
                                            <Tooltip
                                                title={tooltipContent(
                                                    "Product targeting Categories tab"
                                                )}
                                                placement="right"
                                                arrow
                                                className="custom-tooltip-position cursor-pointer"
                                            >
                                                <span className="ms-1">
                                                    <svg
                                                        viewBox="0 0 512 512"
                                                        width="12"
                                                        height="12"
                                                        className="fas fa-info-circle"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="#8290a4"
                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                        </button>
                                        <button
                                            className="nav-link text-dark fs-15"
                                            id="nav-individual-products"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-product-individual-products"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-product-individual-products"
                                            aria-selected="false"
                                        >
                                            Individual Products
                                            <Tooltip
                                                title={tooltipContent(
                                                    "Product targeting Individual Products tab"
                                                )}
                                                placement="right"
                                                arrow
                                                className="custom-tooltip-position cursor-pointer "
                                            >
                                                <span className="ms-1">
                                                    <svg
                                                        viewBox="0 0 512 512"
                                                        width="12"
                                                        height="12"
                                                        className="fas fa-info-circle"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="#8290a4"
                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </Tooltip>
                                        </button>
                                    </div>
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                    <div
                                        className="tab-pane fade show active suggested-tab"
                                        id="nav-product-targeting-categories"
                                        role="tabpanel"
                                        aria-labelledby="nav-categories"
                                        tab-index="0"
                                    >
                                        <div className="row border-top ">
                                            <div className="col-12 px-0">
                                                <div className="tabs-section px-2 mx-1">
                                                    <div
                                                        className="nav nav-underline"
                                                        id="nav-tab"
                                                        role="tablist"
                                                    >
                                                        <button
                                                            className="nav-link text-dark active fs-13"
                                                            id="nav-product-categories-suggested"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-productcategories-suggested"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-productcategories-suggested"
                                                            aria-selected="true"
                                                        >
                                                            Suggested
                                                            <Tooltip
                                                                title={tooltipContent(
                                                                    "Product targeting categories tab suggested"
                                                                )}
                                                                placement="top"
                                                                arrow
                                                                className="custom-tooltip-position cursor-pointer "
                                                            >
                                                                <span className="ms-1">
                                                                    <svg
                                                                        viewBox="0 0 512 512"
                                                                        width="12"
                                                                        height="12"
                                                                        className="fas fa-info-circle"
                                                                        data-fa-i2svg=""
                                                                    >
                                                                        <path
                                                                            fill="#8290a4"
                                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                        ></path>
                                                                    </svg>
                                                                </span>
                                                            </Tooltip>
                                                        </button>
                                                        <button
                                                            className="nav-link text-dark fs-13"
                                                            id="nav-product-categories-search"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-productcategories-search"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-productcategories-search"
                                                            aria-selected="false"
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-content"
                                                    id="nav-tabContent"
                                                >
                                                    <div
                                                        className="tab-pane fade show active suggested-tab"
                                                        id="nav-productcategories-suggested"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-product-categories-suggested"
                                                        tab-index="0"
                                                    >
                                                        <ProductTargetCategoriesFilter
                                                            categoriesTooltTipText={
                                                                getCategoriesTooltipText
                                                            }
                                                        />
                                                        <div className="d-flex flex-column justify-content-center my-1 mt-5">
                                                            <div className="fs-13 fw-bold">
                                                                No relevant categories available
                                                            </div>
                                                            <div className="fs-13 custom-explore-text-color logout cursor-pointer">
                                                                Try searching for categories
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tab-pane fade show  search-tab"
                                                        id="nav-productcategories-search"
                                                        role="tabpanel"
                                                        aria-labelledby="nav-product-categories-search"
                                                        tab-index="0"
                                                    >
                                                        <ProductTargetCategoriesFilter />
                                                        <div className="col-12 px-3 search-input-position product-categories-searchinput-position border-bottom  custom-border-color pb-2">
                                                            <span>
                                                                <img
                                                                    src={SearchIcon} alt=""
                                                                    className="search-position"
                                                                />
                                                            </span>
                                                            <input
                                                                className="form-control fs-13 border-dark px-4"
                                                                defaultValue={search}
                                                                placeholder="  Search by product name or ASIN"
                                                                onChange={handleSearchInput}
                                                            />
                                                            <span className="d-none">
                                                                <img
                                                                    src={SearchSecondIcon} alt=""
                                                                    className="search-second-position custom-button-background"
                                                                />
                                                            </span>
                                                            {showWrongIcon && (
                                                                <span>
                                                                    <img
                                                                        src={SmallWrongIcon} alt=""
                                                                        className="search-second-position wrong-icon-position"
                                                                        onClick={onClickWrong}
                                                                    />
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="col-12 mt-3 pt-3 pb-2 border-bottom border-top product-categories-search-list-header">
                                                            <div className="product-categories-search-list-header">
                                                                <div className="search-header-list px-3">
                                                                    <div className="fs-13">
                                                                        All categories
                                                                    </div>
                                                                    <div className="fs-13 me-5 pe-5">
                                                                        Sugg. bid
                                                                        <Tooltip
                                                                            title={tooltipContent(
                                                                                "Product targeting categories tab suggested"
                                                                            )}
                                                                            placement="top"
                                                                            arrow
                                                                            className="custom-tooltip-position cursor-pointer "
                                                                        >
                                                                            <span className="ms-1">
                                                                                <svg
                                                                                    viewBox="0 0 512 512"
                                                                                    width="12"
                                                                                    height="12"
                                                                                    className="fas fa-info-circle"
                                                                                    data-fa-i2svg=""
                                                                                >
                                                                                    <path
                                                                                        fill="#8290a4"
                                                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="categories-accordions-list">
                                                            {accordionsData?.length > 0 &&
                                                                accordionsData?.map(
                                                                    (item: any, index: number) => (
                                                                        <Accordion
                                                                            key={index}
                                                                            expanded={expanded}
                                                                            onChange={(e) =>
                                                                                handleChangeAccordion(e, index)
                                                                            }
                                                                        >
                                                                            <AccordionSummary
                                                                                className="categories-accordion-header "
                                                                                aria-controls="panel1d-content"
                                                                                id="panel1d-header"
                                                                            >
                                                                                <i className="bi bi-chevron-down fs-12 fw-bold mt-1"></i>
                                                                                <Typography className="categories-title-header ms-2">
                                                                                    <div className="categories-accordion-title-container">
                                                                                        <div className="fs-13 text-wrap">
                                                                                            {item?.accordionTitle}
                                                                                        </div>
                                                                                        <div className="fs-13 text-center">
                                                                                            -
                                                                                        </div>
                                                                                        <div className="fs-13">
                                                                                            <div className="d-flex justify-content-center">
                                                                                                <div
                                                                                                    className="fs-13 custom-explore-text-color border-right me-3"
                                                                                                    onClick={(e) =>
                                                                                                        handleClickAdd(
                                                                                                            e,
                                                                                                            item
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Add
                                                                                                </div>
                                                                                                <div
                                                                                                    className="fs-13 custom-explore-text-color border-left"
                                                                                                    onClick={(e) =>
                                                                                                        handleClickRefine(
                                                                                                            e,
                                                                                                            item
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Refine
                                                                                                </div>
                                                                                                <div></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Typography>
                                                                                    <Accordion
                                                                                        expanded={expanded}
                                                                                        className="px-0 border-top"
                                                                                    >
                                                                                        <AccordionSummary
                                                                                            className=""
                                                                                            aria-controls="panel1d-content"
                                                                                            id="panel1d-header"
                                                                                        >
                                                                                            <i className="bi bi-chevron-down fs-12 fw-bold mt-1"></i>
                                                                                            <Typography className="categories-title-header ms-2">
                                                                                                <div className="categories-accordion-title-container">
                                                                                                    <div className="fs-13">
                                                                                                        watches
                                                                                                    </div>
                                                                                                    <div className="fs-13">
                                                                                                        -
                                                                                                    </div>
                                                                                                    <div className="fs-13">
                                                                                                        <div className="d-flex justify-content-center">
                                                                                                            <div className="fs-13 custom-explore-text-color border-right me-3 ">
                                                                                                                Add
                                                                                                            </div>
                                                                                                            <div className="fs-13 custom-explore-text-color">
                                                                                                                Refine
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Typography>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            <Typography></Typography>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Typography>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade enterlist-tab"
                                        id="nav-product-individual-products"
                                        role="tabpanel"
                                        aria-labelledby="nav-individual-products"
                                        tab-index="0"
                                    >
                                        <div className="row border-top ">
                                            <div className="col-12 px-0">
                                                <div className="tabs-section px-0 mx-0">
                                                    <div
                                                        className="nav nav-underline px-2 mx-1"
                                                        id="nav-tab"
                                                        role="tablist"
                                                    >
                                                        <button
                                                            className="nav-link text-dark active fs-13"
                                                            id="nav-product-individual-products-suggested"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-product-individualProducts-suggested"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-product-individualProducts-suggested"
                                                            aria-selected="true"
                                                        >
                                                            Suggested
                                                            <Tooltip
                                                                title={tooltipContent(
                                                                    "Product targeting individual products tab suggested"
                                                                )}
                                                                placement="right"
                                                                arrow
                                                                className="custom-tooltip-position cursor-pointer "
                                                            >
                                                                <span className="ms-1">
                                                                    <svg
                                                                        viewBox="0 0 512 512"
                                                                        width="12"
                                                                        height="12"
                                                                        className="fas fa-info-circle"
                                                                        data-fa-i2svg=""
                                                                    >
                                                                        <path
                                                                            fill="#8290a4"
                                                                            d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                        ></path>
                                                                    </svg>
                                                                </span>
                                                            </Tooltip>
                                                        </button>
                                                        <button
                                                            className="nav-link text-dark fs-13"
                                                            id="nav-product-individual-products-search"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-product-individualProducts-search"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-product-individualProducts-search"
                                                            aria-selected="false"
                                                        >
                                                            Search{" "}
                                                        </button>
                                                        <button
                                                            className="nav-link text-dark fs-13"
                                                            id="nav-product-individual-products-enterlist"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-product-individualProducts-enterlist"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-product-individualProducts-enterlist"
                                                            aria-selected="false"
                                                        >
                                                            Enter list
                                                        </button>
                                                        <button
                                                            className="nav-link text-dark fs-13"
                                                            id="nav-product-individual-products-upload"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-product-individualProducts-upload"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="nav-product-individualProducts-upload"
                                                            aria-selected="false"
                                                        >
                                                            Upload{" "}
                                                        </button>
                                                    </div>
                                                    <div
                                                        className="tab-content px-2 mx-0"
                                                        id="nav-tabContent"
                                                    >
                                                        <div
                                                            className="tab-pane fade show active suggested-tab"
                                                            id="nav-product-individualProducts-suggested"
                                                            role="tabpanel"
                                                            aria-labelledby="nav-product-individual-products-suggested"
                                                            tab-index="0"
                                                        >
                                                            <div className="row fs-13 border-top border-bottom mb-4 mx-0 d-none">
                                                                <div className="d-flex justify-content-start m-2 px-3">
                                                                    <div className="fs-12 fw-bold my-1">
                                                                        Bid
                                                                        <span className="ms-1">
                                                                            <svg
                                                                                viewBox="0 0 512 512"
                                                                                width="12"
                                                                                height="12"
                                                                                className="fas fa-info-circle"
                                                                                data-fa-i2svg=""
                                                                            >
                                                                                <path
                                                                                    fill="#8290a4"
                                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div className="fs-13 ms-5 ps-4">
                                                                        <select className="form-select custom-drop-down suggested-drop-down rounded-pill py-1 text-start">
                                                                            <option
                                                                                value="Suggested bid"
                                                                                className="suggested-dropdown-box-shadow "
                                                                            >
                                                                                <div className="">
                                                                                    <div className="fs-14">
                                                                                        Suggested bid
                                                                                    </div>
                                                                                </div>
                                                                            </option>
                                                                            <option value="Custom bid">
                                                                                Custom bid
                                                                            </option>
                                                                            <option value="Default bid">
                                                                                Default bid
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex m-2 px-1 my-2">
                                                                    <div className="fs-12 fw-bold">
                                                                        Filter by
                                                                        <span className="ms-1">
                                                                            <svg
                                                                                viewBox="0 0 512 512"
                                                                                width="12"
                                                                                height="12"
                                                                                className="fas fa-info-circle"
                                                                                data-fa-i2svg=""
                                                                            >
                                                                                <path
                                                                                    fill="#8290a4"
                                                                                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <div className="fs-13 ms-5">
                                                                        <div className="d-flex">
                                                                            <div className="form-check mx-2">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id="flexCheckDefaultExactFour"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label fs-12"
                                                                                    htmlFor="flexCheckDefaultExactFour"
                                                                                >
                                                                                    Exact
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    value=""
                                                                                    id="flexCheckDefaultExpandedTwo"
                                                                                    defaultChecked
                                                                                />
                                                                                <label
                                                                                    className="form-check-label fs-12"
                                                                                    htmlFor="flexCheckDefaultExpandedTwo"
                                                                                >
                                                                                    Expanded
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ProductTargetingIndividualProductsFilter />
                                                            <div className="d-flex flex-row justify-content-center my-1 mt-5">
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="fs-13 fw-bold">
                                                                        No suggestions available.
                                                                    </div>
                                                                    <div className="fs-13 custom-explore-text-color logout cursor-pointer ">
                                                                        Try searching for products.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="tab-pane fade show  search-tab"
                                                            id="nav-product-individualProducts-search"
                                                            role="tabpanel"
                                                            aria-labelledby="nav-product-individual-products-search"
                                                            tab-index="0"
                                                        >
                                                            <ProductTargetingIndividualProductsFilter />
                                                            <div className="col-12 px-0 search-input-position individual-product-searchinput-position border-bottom custom-border-color pb-2">
                                                                <span>
                                                                    <img
                                                                        src={SearchIcon} alt=""
                                                                        className="search-position"
                                                                    />
                                                                </span>
                                                                <input
                                                                    className="form-control fs-13 border-dark px-4"
                                                                    defaultValue={search}
                                                                    placeholder="  Search by product name or ASIN"
                                                                    onChange={handleSearchInput}
                                                                />
                                                                <span className="d-none">
                                                                    <img
                                                                        src={SearchSecondIcon} alt=""
                                                                        className="search-second-position custom-button-background"
                                                                    />
                                                                </span>
                                                                {showWrongIcon && (
                                                                    <span>
                                                                        <img
                                                                            src={SmallWrongIcon} alt=""
                                                                            className="search-second-position wrong-icon-position"
                                                                            onClick={onClickWrong}
                                                                        />
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="fs-13 mt-5 ">
                                                                Search for products you want to target.
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="tab-pane fade show  search-tab"
                                                            id="nav-product-individualProducts-enterlist"
                                                            role="tabpanel"
                                                            aria-labelledby="nav-product-individual-products-enterlist"
                                                            tab-index="0"
                                                        >
                                                            <ProductTargetingIndividualProductsFilter />
                                                            <div className="fs-13">
                                                                <textarea
                                                                    name="text-one"
                                                                    className="form-control border-dark fs-13"
                                                                    rows={8} defaultValue={''}
                                                                    placeholder="Enter ASINs separated by a comma, space or new line."
                                                                ></textarea>
                                                            </div>
                                                            <div className="fs-13 d-flex justify-content-end my-2">
                                                                <button className="btn btn-dark border-0 rounded-pill text-secondary custom-button-background fs-13 cursor-pointer-not-allowed">
                                                                    Target
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="tab-pane fade show  search-tab"
                                                            id="nav-product-individualProducts-upload"
                                                            role="tabpanel"
                                                            aria-labelledby="nav-product-individual-products-upload"
                                                            tab-index="0"
                                                        >
                                                            <div className="row fs-13 border-top mb-4 mx-0">
                                                                <div className="row fs-13 border-bottom mb-3 mx-0">
                                                                    <div className="d-flex justify-content-start m-2 px-0">
                                                                        <div className="fs-12 fw-bold my-1">
                                                                            Bid
                                                                            <span className="ms-1">
                                                                                <svg
                                                                                    viewBox="0 0 512 512"
                                                                                    width="12"
                                                                                    height="12"
                                                                                    className="fas fa-info-circle"
                                                                                    data-fa-i2svg=""
                                                                                >
                                                                                    <path
                                                                                        fill="#8290a4"
                                                                                        d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        <div className="fs-13 ms-5 ps-4">
                                                                            <div className="btn-group">
                                                                                <button
                                                                                    className="btn btn-secondary btn-sm  dropdown-toggle new-dropdown-toggle btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3 py-1"
                                                                                    type="button"
                                                                                    data-bs-toggle="dropdown"
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    {
                                                                                        selectedProductTargetingBidValue
                                                                                    }{" "}
                                                                                    <span>
                                                                                        <svg
                                                                                            viewBox="0 0 389 512"
                                                                                            width="13"
                                                                                            height="13"
                                                                                            className="fas fa-angle-down show-text-icon"
                                                                                            data-fa-i2svg=""
                                                                                        >
                                                                                            <path
                                                                                                fill="currentColor"
                                                                                                d="M18.93 147.3a37.3 37.3 0 0 1 52.75 0L194.5 270.12 317.33 147.3a37.3 37.3 0 1 1 52.75 52.75L194.5 375.62 18.93 200.06a37.3 37.3 0 0 1 0-52.75Z"
                                                                                            ></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                                <ul className="dropdown-menu newest-first-dropdown keyword-targeting-bid-dropdown">
                                                                                    <li className="fs-13 py-1">
                                                                                        {keywordTargetingBidData?.map(
                                                                                            (
                                                                                                item: any,
                                                                                                index: number
                                                                                            ) => (
                                                                                                <button
                                                                                                    key={index}
                                                                                                    className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedProductTargetingBidValue ===
                                                                                                        item?.value
                                                                                                        ? "active"
                                                                                                        : ""
                                                                                                        }`}
                                                                                                    value={item.value}
                                                                                                    onClick={() =>
                                                                                                        handleProductTargetingBidTextChange(
                                                                                                            item?.value
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <div className="fw-400">
                                                                                                        {item.name}
                                                                                                    </div>
                                                                                                    <div className="dropdown-sub-title-text-color">
                                                                                                        {item.sub_title}
                                                                                                    </div>
                                                                                                </button>
                                                                                            )
                                                                                        )}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row ">
                                                                    <div className="mb-2 fs-13 text-start">
                                                                        Use the template to make sure your
                                                                        information uploads accurately.
                                                                    </div>
                                                                    <div className="text-start mb-2">
                                                                        <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 ">
                                                                            <span className="me-2">
                                                                                <img src={DownloadIcon} alt="" />
                                                                            </span>
                                                                            Download the CSV template
                                                                        </button>
                                                                    </div>
                                                                    <div className="upload-files">
                                                                        <div
                                                                            data-takt-id="ups_upload_asins_input"
                                                                            className="upload-icon fs-13"
                                                                        >
                                                                            <div className="mt-4 pt-2">
                                                                                <label
                                                                                    htmlFor="input_file_three"
                                                                                    className="cursor-pointer"
                                                                                >
                                                                                    <div className="">
                                                                                        <div>
                                                                                            <i className="bi bi-upload"></i>
                                                                                        </div>
                                                                                        <div className="fs-13 my-1">
                                                                                            Drag and drop file to add
                                                                                        </div>
                                                                                        <div className="fs-13 my-2">
                                                                                            or click to select from
                                                                                            your computer
                                                                                        </div>
                                                                                        <div className="fs-13 my-1">
                                                                                            Accepted formats: CSV,
                                                                                            TSV, XLSX
                                                                                        </div>
                                                                                    </div>
                                                                                </label>
                                                                                <input
                                                                                    type="file"
                                                                                    id="input_file_three"
                                                                                    name="file"
                                                                                    className="d-none "
                                                                                    onChange={
                                                                                        handleQuotationFileUpload
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showRefineModal && (
                                    <div className="product_targeting_categories_search_refine">
                                        <div className="product_targeting_categories_search_refine_child_subchild">
                                            <div className="refine_header_parent">
                                                <span className="refine_header">
                                                    Refine category: Watches
                                                </span>
                                                <p className="refine_header_subtitle">
                                                    Refine category by specific brands, price
                                                    range, star ratings and Prime shipping
                                                    eligibility.
                                                </p>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="gBHflH">
                                                <div className="fxhSbD">
                                                    <span className="refine_header">Brand</span>
                                                    <div className="col-8">
                                                        <button className="btn form-control btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer">
                                                            Select
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="fxhSbD">
                                                    <span className="refine_header">
                                                        Price range
                                                    </span>
                                                    <div className="d-flex">
                                                        <div className=" input-group my-2 mt-0">
                                                            <span className="input-group-text input bg-white border-dark">
                                                                <img src={RupeeIcon} alt="" className="" />
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control input border-dark border-start-0 default-bid-input-width ps-0"
                                                                placeholder="No min"
                                                            />
                                                        </div>
                                                        <div className="Price_range_dash">-</div>
                                                        <div className="input-group my-2 mt-0">
                                                            <span className="input-group-text input bg-white border-dark">
                                                                <img src={RupeeIcon} alt="" className="" />
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control input border-dark border-start-0 default-bid-input-width ps-0"
                                                                placeholder="No max"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fxhSbD">
                                                    <span className="refine_header">
                                                        Review stars rating
                                                    </span>
                                                    <div className="d-flex">
                                                        <MultiRangeSlider
                                                            labels={reviewStarRating}
                                                            min={0}
                                                            max={6}
                                                            minValue={1}
                                                            maxValue={5}
                                                            step={1}
                                                            className='mutlirange-slider'
                                                            barInnerColor="rgb(0, 115, 199)"
                                                            thumbLeftColor="rgb(0, 115, 199)"
                                                            thumbRightColor="rgb(0, 115, 199)"
                                                            barRightColor="rgb(206, 211, 220)"
                                                            barLeftColor="rgb(206, 211, 220)"
                                                            ruler={false}
                                                            minCaption={minCaption}
                                                            maxCaption={maxCaption}
                                                            onInput={(e: any) => {
                                                                set_minCaption(
                                                                    reviewStarRating[e.minValue]
                                                                );
                                                                set_maxCaption(
                                                                    reviewStarRating[e.maxValue]
                                                                );
                                                            }}

                                                        />
                                                        <span className="refine_header mt-2">
                                                            All stars
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="fxhSbD">
                                                    <span className="refine_header">
                                                        Shipping
                                                    </span>
                                                    <div>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                className="form-check-input mb-3 me-1"
                                                                name="shippingStatus"
                                                            // checked={
                                                            //   campaignBiddinStrategy ===
                                                            //   "Dynamic bids - down only"
                                                            // }
                                                            // onChange={() =>
                                                            //   onChangeCampaignBiddingType(
                                                            //     "Dynamic bids - down only"
                                                            //   )
                                                            // }
                                                            />
                                                            <span className="dikvWV me-3">All</span>
                                                        </span>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                className="form-check-input mb-3 me-1"
                                                                name="shippingStatus"
                                                            // checked={
                                                            //   campaignBiddinStrategy ===
                                                            //   "Dynamic bids - down only"
                                                            // }
                                                            // onChange={() =>
                                                            //   onChangeCampaignBiddingType(
                                                            //     "Dynamic bids - down only"
                                                            //   )
                                                            // }
                                                            />
                                                            <span className="dikvWV me-3">
                                                                Prime eligible
                                                            </span>
                                                        </span>
                                                        <span>
                                                            <input
                                                                type="radio"
                                                                className="form-check-input mb-3 me-1"
                                                                name="shippingStatus"
                                                            />
                                                            <span className="dikvWV me-3">
                                                                Not Prime eligible
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="m-0" />
                                            <div className="gBHflH mt-3">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <span className="refine_header">
                                                            Products targeted: 6 – 10
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        <span
                                                            className="custom-explore-text-color fs-13 logout cursor-pointer py-2 me-2"
                                                            onClick={() => setShowRefineModal(false)}
                                                        >
                                                            Cancel
                                                        </span>
                                                        <button className="btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer">
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-6 product-preview-container px-0">
                                <div className="d-flex justify-content-between border-bottom py-2 px-3 px-0">
                                    <div className="fs-13 fw-bold ">0 added</div>
                                    <div className="fs-13 cursor-pointer-not-allowed remove-all-text ">
                                        Remove all
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
