import React, { useState } from 'react'
import { CustomFilter } from '../../../../components/custom-filter/custom-filter'
import { HistoryDataGridCols } from './history-data-grid-cols'
import { CustomTable } from '../../../../components/custom-table'
import ChartComponent from '../../../../components/charts/line-chart';
import { Tooltip } from '@mui/material';
import PlusIcon from "../../../../assets/images/svg_icons/plus.svg";
import { TooltipPage } from '../../../../components/custom-tooltip/tooltipPage';
import { CustomFooter } from '../../../../components/footer/custom-footer';
import { useNavigate } from 'react-router-dom';
export const HistoryListPage = () => {
    const [showChart, setShowChart] = useState<boolean>(true);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [canShowAddMetric, setCanShowAddMetric] = useState<boolean>(false);
    const navigate = useNavigate();

    const [grpahCardsData, setGrpahCardsData] = useState([
        {
            card_title: "Spend",
            amount: `₹24,031.23`,
            sub_title: "TOTAL"
        },
        {
            card_title: "Sales",
            amount: `₹6,934.23`,
            sub_title: "TOTAL"
        },
        {
            card_title: "ROAS",
            amount: `0.26`,
            sub_title: "AVERAGE"
        },
        {
            card_title: "Impressions",
            amount: `40,641.82`,
            sub_title: "TOTAL"
        }
    ]);

    const tableData: any = {
        page: "0",
        limit: "10",
        total: 2,
        rows: [
            {
                change_type_name: "Campaign status",
                from: "Paused",
                to: "Delivering",
                date_and_time: "Aug 24, 2023 1:11 PM"
            },
            {
                change_type_name: "Campaign status",
                from: "Delivering",
                to: "Paused",
                date_and_time: "Aug 24, 2023 11:29 AM"
            },
        ]
    }

    const getshowChatStatus = (status: any) => {
        setShowChart(status);
    }

    const metricCardsData = [
        {
            card_title: "Spend",
            amount: `₹24,031.23`,
            sub_title: "TOTAL"
        },
        {
            card_title: "Sales",
            amount: `₹6,934.23`,
            sub_title: "TOTAL"
        },
        {
            card_title: "ROAS",
            amount: `0.26`,
            sub_title: "AVERAGE"
        },
        {
            card_title: "Impressions",
            amount: `40,641.82`,
            sub_title: "TOTAL"
        }
    ];

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    }

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    }

    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "Spend") {
            return (
                <TooltipPage
                    tooltipHeader='Spend'
                    tooltipMainContent={`The total click charges for a product ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Sales") {
            return (
                <TooltipPage
                    tooltipHeader='Sales'
                    tooltipMainContent={` Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
                  Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 14 days.
                  Sponsored Brands: Sales from advertised products sold by Amazon within 14 days. Where eligible, this also includes sales from all products within your brand sold by Amazon and third-party sellers.
                  It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
                  Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "ROAS") {
            return (
                <TooltipPage
                    tooltipHeader='Return on ad spend (ROAS)'
                    tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
                    tooltipSubContent={'Learn more'} questionIconStatus={true} tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Impressions") {
            return (
                <TooltipPage
                    tooltipHeader='Impressions'
                    tooltipMainContent={`The number of times ads were displayed`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        }
    }

    const onClickAddMetric = () => {
        setCanShowAddMetric(!canShowAddMetric);
    }

    const onSelectMetric = (data: any, index: any) => {
        const preparedData = grpahCardsData;
        preparedData.push(data);
        setGrpahCardsData([...preparedData]);
        setCanShowAddMetric(false);
    }

    function onRemoveMetric(data: any, index: number) {
        const preparedData = grpahCardsData;
        preparedData.splice(index, 1);
        setGrpahCardsData([...preparedData]);
    }

    const onActions = (data: any) => {
        if (data.type === "edit-campaign") {
            navigate("/dashboard/campaign/settings")
        }
    }

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 px-4 mt-3 position-relative">
                    {showChart && (
                        <div className='row px-3'>
                            <div className="col-12 d-flex flex-wrap justify-content-between col_padding">
                                {grpahCardsData?.map((data: any, index: number) => (
                                    <div key={index} className='card col-12 col-md-6 col_md_6 col-lg-2 col_lg_2 col_xl_2 col-xl-2 my-2 my-lg-0 text-bg-white rounded-0'>
                                        <div className='card-header bg-white border-0 add_metrics-bg cursor-pointer'>
                                            <div className="d-flex justify-content-between">
                                                <div className="fs-13">{data?.card_title}
                                                    <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                        <span className='ms-1 fs-15'>
                                                            <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 cursor-pointer" onClick={() => onRemoveMetric(data, index)}>
                                                    <i className="bi bi-x"></i>
                                                </div>
                                            </div>
                                            <div><span className='fs-21'>{data?.amount}</span><span className='fs-11'>{data?.sub_title}</span></div>
                                        </div>
                                    </div>
                                ))}
                                {grpahCardsData?.length < 5 && <div className={`${canShowAddMetric ? 'col-12 col-md-6 col-lg-2 col-xl-2 add-metric-border bg-white cursor-pointer' : 'card py-3 col-12 col-md-6 col-lg-2 col-xl-2 border-1 border-dark card-border-style bg-white  rounded-0 cursor-pointer'}`} onClick={onClickAddMetric}>
                                    <div className='card-header text-center bg-white border-0'>
                                        <span><img src={PlusIcon} alt="" /></span>
                                        add metric
                                        <div className={`${canShowAddMetric ? 'one' : ''}`}></div>
                                        <div className={`${canShowAddMetric ? 'two' : ''}`}></div>
                                    </div>
                                </div>}
                            </div>
                            {canShowAddMetric && <div className='add-metric-list col-md-12 '>
                                <div className='row add-metric-info '>
                                    {metricCardsData?.map((data: any, index: number) => (
                                        <div key={index} className='card  col-12 col-md-4 col-lg-3 metrics-cols-extra-large metrics-cols-large my-2 col-xl-2 add_metrics-bg text-bg-white rounded-0 cursor-pointer col_margin add_metrics-bg' onClick={() => onSelectMetric(data, index)}>
                                            <div className='card-header bg-white border-0 add_metrics-bg'>
                                                <div className="d-flex justify-content-between">
                                                    <div className="fs-13">{data?.card_title}
                                                        <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                            <span className='ms-1 fs-15'>
                                                                <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="fs-13">
                                                        <i className="bi bi-x"></i>
                                                    </div>
                                                </div>
                                                <div>{data?.amount} <span className='fs-11'>{data?.sub_title}</span></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                            {!canShowAddMetric &&
                                <ChartComponent />
                            }
                        </div>
                    )}
                </div>
                <div className="col-12 px-4 pt-2">
                    <CustomFilter historyFilter={true}
                        customDateRangeText='Last 30 days'
                        showChartStatus={getshowChatStatus} />
                </div>
                <div className='col-12 ad-groups-table-container'>
                    <CustomTable
                        tableData={tableData}
                        TableCols={HistoryDataGridCols}
                        tableWithoutFixedCoulmns={true}
                        noDataFoundMesageTitle=''
                        tableBottomText='Campaign history includes changes to your campaign settings in the past 90 days.'
                        tableBottomTextStatus={true}
                        placementBottomTextStyles={true}
                        onActions={(e) => onActions(e)}
                    />
                </div>
                <div className={`pb-3 ${!showChart ? "fixed-bottom " : ""}`}>
                    <CustomFooter />
                </div>
            </div>
        </div>
    )
}
