import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import IndiaFlag from './../../../assets/images/india.png';
import { Header } from '../../../components/header/header';
import { GET_AWS_ACCOUNT_LINK_URL, IS_USER_LINKED_AWS_ACCOUNT, USER_CODE_CREATION, } from '../../../app/services/common.service';
import { toast } from "react-toastify";
import AmazonButton from "./../../../assets/images/amazon button.png";
import { useLocation } from 'react-router-dom';
import { GENERATE_PROFILE_DATA, GET_USER_PROFILES } from '../../../app/services/userProfiles.service';
import { connect, useDispatch } from "react-redux";
import { ProfileData } from '../../../redux/actions';

interface Props {
    ProfileDataReducer?: any;
    profileData?: any;
}

const ManageProfile = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dotsLoading, setDotsLoading] = useState<boolean>(false);
    const [showLinkedData, setShowLinkedData] = useState<boolean>(true);
    const [showProfilePullPopup, setShowProfilePullPopup] = useState<boolean>(false);
    const [dates, setDates] = useState<any>();
    const [selectedProfileData, setSelectedProfileData] = useState<any>("")
    const [isUserLinkedAwsAccountStatus, setIsUserLinkedAwsAccountStatus] = useState<boolean>();
    const [greeting, setGreeting] = useState<any>('')
    const [manageProfilesData, setManageProfilesData] = useState<any>();
    const [manageOriginalProfilesData, setManageOriginalProfilesData] = useState<any>();
    const [showWrongIcon, setShowWrongIcon] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<any>("");
    const [datesData, setDatesData] = useState<any>({
        start_date: ""
    })
    const [formErrors, setFormErrors] = useState<any>({})
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userUuid = localStorage.getItem("user_uuid");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const userName = localStorage.getItem('user_name');
    let timer: any;

    useEffect(() => {
        if (code) {
            userCodeCreation();
            isUserLinkedAwsAccount();
        } else {
            isUserLinkedAwsAccount();
        }
        const now = new Date();
        const currentHour = now.getHours();
        let newGreeting = '';
        if (currentHour >= 5 && currentHour < 12) {
            newGreeting = 'Good Morning';
        } else if (currentHour >= 12 && currentHour < 17) {
            newGreeting = 'Good Afternoon';
        } else {
            newGreeting = 'Good Evening';
        }
        setGreeting(newGreeting);
    }, []);

    useEffect(() => {
        const generateDateList = () => {
            const currentDate = new Date();
            const dateList = [];
            currentDate.setMonth(currentDate.getMonth() - 1);
            for (let i = 0; i < 3; i++) {
                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');
                const labelDate = `${day}-${month}-${year}`;
                const valueDate = `${year}-${month}-${day}`;
                dateList.push({ label: labelDate, value: valueDate });
                currentDate.setMonth(currentDate.getMonth() - 1);
            }
            setDates(dateList);
        };
        generateDateList();
    }, [])


    const onClickOverview = (e: any, index: number, data: any) => {
        localStorage.setItem('profile_user_uuid', data?.uuid)
        localStorage.setItem('profile_id', data?.profile_id);
        localStorage.setItem('profile_name', data?.account_info?.name);
        localStorage.setItem('seller_id', data?.seller_id);
        localStorage.setItem('type', data?.account_info?.type);
        if (props?.profileData) {
            dispatch(props.profileData(data));
        }
        navigate(`/dashboard/home/campaigns`);
    }

    const onLinkAmazonAdAccount = () => {
        setDotsLoading(true);
        setShowLinkedData(true);
        setTimeout(() => {
            setDotsLoading(false);
            setShowLinkedData(false);
        }, 3000);
    }

    const getAwsAccountAccountLinkUrl = () => {
        setLoading(true);
        GET_AWS_ACCOUNT_LINK_URL().then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    setLoading(false);
                }
            }
        });
    }

    const isUserLinkedAwsAccount = () => {
        const preapredData = {
            user_uuid: userUuid
        };
        setLoading(true);
        IS_USER_LINKED_AWS_ACCOUNT(preapredData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    if (response.data === false) {
                        getAwsAccountAccountLinkUrl();
                    } else if (response.data === true) {
                        timer =
                            setTimeout(() => {
                                getUserProfiles();
                            }, 500);
                    }
                    setIsUserLinkedAwsAccountStatus(response.data);
                    setLoading(false);
                }
            }
        });
    }

    const userCodeCreation = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            code: code
        }
        setLoading(true);
        USER_CODE_CREATION(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    setLoading(false);
                }
            }
        });
    }

    const getUserProfiles = () => {
        const preparedData: any = {
            user_uuid: userUuid,
        }
        setLoading(true);
        GET_USER_PROFILES(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    if (response?.data?.length > 0) {
                        if (response?.data[0].profiles_data?.length > 0) {
                            clearTimeout(timer);
                        }
                        setManageProfilesData(response.data)
                        setManageOriginalProfilesData(response.data)
                        setLoading(false);
                    }
                }
            }
        });
    }
    const onPullProfileData = (e: any, data: any, index: any) => {
        setSelectedProfileData(data)
        setShowProfilePullPopup(true);
    }

    const validateForm = () => {
        const errors: any = {};
        if (!datesData?.start_date) {
            errors.start_date = "Please select start date"
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleChangeInput = (e: any) => {
        const { name, value } = e.target;
        setDatesData({ ...datesData, start_date: value })
        setDatesData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const validateSpecificField = (name: any, value: any) => {
        if (name === "start_date") {
            if (!value) {
                return "Please select start date"
            }
        }
        return "";
    }

    const handleCancel = () => {
        setShowProfilePullPopup(false);
        setDatesData({ ...datesData, start_date: "" })
        setFormErrors({});
    }

    const onSubmitProfilePull = () => {
        const isValid = validateForm();
        if (isValid) {
            const preparedData = {
                user_uuid: userUuid,
                profile_id: selectedProfileData?.profile_id.toString(),
                start_date: datesData?.start_date || ""
            }

            setLoading(true);
            GENERATE_PROFILE_DATA(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const response = res.data;
                        if (response?.data?.length > 0) {
                            setLoading(false);
                        }
                    }
                }
            });
        }
    }


    const onChangeSearchInputValue = (e: any) => {
        const searchTerm = e.target.value;
        setSearchValue(searchTerm);
        if (searchTerm?.trim()?.length <= 0) {
            setShowWrongIcon(false)
        } else {
            setShowWrongIcon(false)
        }
    }

    const onChangeProfileType = (e: any) => {
        const { value } = e.target;
        if (value === "seller") {
            const filterProfiles = manageOriginalProfilesData[0]?.profiles_data?.filter((el: any) => el?.account_info?.type === value)
            setManageProfilesData([{ ...manageProfilesData, count: filterProfiles?.length, profiles_data: filterProfiles, country_code: "IN" }]);
        } else if (value === "vendor") {
            const filterProfiles = manageOriginalProfilesData[0]?.profiles_data?.filter((el: any) => el?.account_info?.type === value)
            setManageProfilesData([{ ...manageProfilesData, count: filterProfiles?.length, profiles_data: filterProfiles, country_code: "IN" }]);
        }
        else {
            setManageProfilesData([{ ...manageProfilesData, count: manageOriginalProfilesData[0]?.profiles_data?.length, profiles_data: manageOriginalProfilesData[0]?.profiles_data, country_code: "IN" }]);
        }
    }

    const onClickSearch = () => {
        const profilesData = manageOriginalProfilesData[0]?.profiles_data
        if (searchValue?.trim()?.length > 0) {
            for (let i = 0; i < profilesData?.length; i++) {
                profilesData[i].account_info.profile_name = profilesData[i].account_info.name.toLowerCase()
            }
            const searchData = profilesData?.filter((el: any) => el?.account_info?.profile_name?.includes(searchValue.toLowerCase()));
            setManageProfilesData([{ ...manageProfilesData, count: searchData?.length, profiles_data: searchData, country_code: "IN" }]);
            setShowWrongIcon(true)
        } else if (searchValue?.trim()?.length <= 0) {
            setShowWrongIcon(false)
        }
    }
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            onClickSearch();
        }
    };

    const onClickWrongIcons = () => {
        setSearchValue("");
        setShowWrongIcon(false);
        getUserProfiles();
    }

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-12 px-0 fixed-top">
                    <Header profileHomeIcon={true} />
                </div>
                <div className='col-12 px-4'>
                    <div className='fs-24 py-3 pb-2 mt-5 fw-bold pt-4 rounded-2'>
                        Hi {userName}, {greeting}
                    </div>
                    {showLinkedData && !isUserLinkedAwsAccountStatus && (
                        <div className="alert alert-warning mt-3 pt-3" role="alert">
                            <div className='row d-md-flex justify-content-between'>
                                <div className="col-md-6 fs-13 my-2 d-flex">
                                    <div><i className="bi bi-info-circle-fill text-warning"></i></div>
                                    {dotsLoading &&
                                        <div className="col-12 ms-2 ">
                                            <div className="snippet" data-title="dot-pulse">
                                                <div className="stage d-flex">
                                                    <div className='ms-1 fw-bold'> Your amazon ad account is <span className='text-danger fw-bold'>syncing</span> with us.</div>
                                                    <div className="dot-pulse ms-4 mt-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!dotsLoading && (
                                        <div><span className='ms-2 fw-bold'> Link your amazon ad account to manage your profiles</span></div>
                                    )}
                                </div>
                                <div className="col-md-6 text-md-end fs-13 custom-explore-text-color logout cursor-pointer">
                                    <button className='bg-transparent border-0' onClick={onLinkAmazonAdAccount}>
                                        <a href="https://www.amazon.com/ap/oa?scope=advertising::campaign_management&response_type=code&client_id=amzn1.application-oa2-client.69ac47b49c6c43d99c01bc6f8618d35b&state=State&redirect_uri=https://localhost:3000/signin" id="LoginWithAmazon">
                                            <img src={AmazonButton} alt="Login with Amazon"
                                                className='amazon-button amazon-button-border'
                                                width="156" height="32" />
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {isUserLinkedAwsAccountStatus &&
                    <div>
                        {loading &&
                            <div className="text-center p-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        }
                        {!loading &&
                            <div>
                                {manageProfilesData?.map((profileData: any, i: number) => (
                                    <div key={i}>
                                        <div className='d-flex'>
                                            <h6 className='india-text mx-0 ms-2 ps-1'>
                                                {profileData?.country_code === "IN" && (
                                                    <span>
                                                        <span>{profileData?.country_code === "IN" && (<img src={IndiaFlag} className='india-flag-image mb-2 me-2' alt='India' />)}</span>
                                                        <span>{profileData?.country_code === "IN" && <span>India</span>}({`${profileData?.count} profiles`})</span>
                                                    </span>
                                                )}
                                            </h6>
                                            {manageProfilesData?.length > 0 && (
                                                <div className='ms-auto me-3 d-flex'>
                                                    <div className="ms-auto">
                                                        <select className='form-select custom-select border-dark drop-down-input' onChange={(e) => { onChangeProfileType(e) }}>
                                                            <option value="all" selected>All</option>
                                                            <option value="seller">Seller</option>
                                                            <option value="vendor">Vendor</option>
                                                        </select>
                                                    </div>
                                                    <div className='ms-auto '>
                                                        <div className="input-group border-dark drop-down-input mx-2 mt-0 mb-2 mb-sm-0">
                                                            <input type="text" name="custom_search" className={`form-control profile-search  drop-down-input border-dark fs-13 border-end-0 px-1 ${showWrongIcon ? "border-end-0" : ""}`}
                                                                placeholder={`Search`} defaultValue={searchValue} onChange={onChangeSearchInputValue} onKeyPress={handleKeyPress} />
                                                            {showWrongIcon && (
                                                                <span className="input-group-text cursor-pointer input drop-down-input bg-white border-dark  px-1"><i className="bi bi-x text-dark fw-bold cursor-pointer text-primary" onClick={onClickWrongIcons}></i></span>
                                                            )}
                                                            {!showWrongIcon &&
                                                                <span className="input-group-text cursor-pointer bg-white input border-dark drop-down-input  px-1" id="basic-addon1" onClick={onClickSearch}>
                                                                    <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-search search-icon-color"><path fill="rgb(130, 144, 164)" d="M194 8c102.73 0 186 83.28 186 186 0 40.17-12.73 77.37-34.39 107.77l149.31 149.31a31 31 0 0 1-43.84 43.84l-149.3-149.3A185.14 185.14 0 0 1 194 380C91.28 380 8 296.73 8 194 8 91.28 91.28 8 194 8Zm0 62c-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124 68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124Z"></path></svg>
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {loading &&
                                            <div className="text-center p-5 camapign-creation-loader-position">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </div>
                                        }
                                        {!loading &&
                                            <div className='row'>
                                                {profileData?.profiles_data?.map((data: any, index: number) => (
                                                    <div key={index} className='col-md-6 my-2 my-md-3 px-4 px-md-3'>
                                                        <div className='profile-card shadow border border-dark pt-3 ms-md-2 px-2'>
                                                            <div className='row px-1'>
                                                                <div className='col-6'>
                                                                    <div className='d-flex pt-2'>
                                                                        <h6><span className='fw-bold fs-19'>{data?.account_info?.name}</span></h6>
                                                                        <button className={`${data?.account_info?.type === "seller" ? "seller-btn" : "vendor-btn"}`}>{data?.account_info?.type === "seller" ? "Seller" : "Vendor"}</button>
                                                                    </div>
                                                                    <div className='pt-2'>
                                                                        <span className='fs-14'>{data?.account_info?.type === "seller" ? "Seller" : "Vendor"} ID: </span>
                                                                        <span className='fs-14 fw-700'>{data?.account_info?.id}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6 text-end'>
                                                                    <div>
                                                                        <button className='request-call-button custom-create-btn text-white fs-13 px-3' onClick={(e) => onPullProfileData(e, data, index)}>Pull</button>
                                                                    </div>
                                                                    <div className='pt-3 pb-2 fs-14'>Profile ID:<span className='fs-14 fw-700'>{data?.profile_id}</span></div>
                                                                </div>
                                                                <div className='col-md-12 p-0 mt-3'>
                                                                    <button className='btn btn-dark border-0 w-100 rounded-bottom-1 overview-btn cursor-pointer' onClick={(e) => onClickOverview(e, index, data)}>
                                                                        <span className="fw-bold fs-18">Overview<span><i className="bi bi-arrow-right fw-bold ms-1 fs-18"></i></span></span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                ))}
                                {manageProfilesData?.length === 0 && (
                                    <div className='text-center fs-14'>No data found</div>
                                )}
                                {manageProfilesData?.[0]?.profiles_data?.length === 0 && (
                                    <div className='text-center fs-14 d-flex align-items-center my-5 py-5'>No data found</div>
                                )}
                            </div>
                        }
                    </div>
                }
                {showProfilePullPopup && (
                    <div className={`modal modal-custom fade ${showProfilePullPopup ? "show" : "false"}  ${showProfilePullPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                        <div className="modal-dialog modal-md modal-dialog-centered " role="document">
                            <div className="modal-content mx-auto">
                                <div className="modal-header border-bottom">
                                    <h5 className="modal-title fs-17 fw-bold">{selectedProfileData?.account_info?.name} {` - (${selectedProfileData?.account_info?.type})`}</h5>
                                    <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancel}></button>
                                </div>
                                {loading &&
                                    <div className="text-center p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                }
                                {!loading &&
                                    <div className='modal-body p-3 border-bottom'>
                                        <div className='row'>
                                            <div className='col-md-10 my-3 mx-auto'>
                                                <select name="start_date" className='form-select' onChange={handleChangeInput}>
                                                    <option value="">Select start date</option>
                                                    {dates.map((date: any, index: number) => (
                                                        <option key={index} value={date?.value}>
                                                            {date?.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {formErrors?.start_date && (<div className='text-danger'>{formErrors?.start_date}</div>)}
                                            </div>
                                            <div className='my-2 d-flex justify-content-end'>
                                                <button className='custom-explore-text-color border-0 bg-transparent fs-13 logout' onClick={handleCancel}>Cancel</button>
                                                <button className='request-call-button custom-create-btn text-white fs-13 px-3' onClick={onSubmitProfilePull}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div >
    )
}

const mapStateToProps = (state: any) => {
    return {
        ProfileDataReducer: state.ProfileDataReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        profileData: (data: any) => dispatch(ProfileData(data)),
    };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(ManageProfile);
export { connectedNavBar as ManageProfile };