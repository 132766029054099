import { CustomTableCol } from "../../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const productAdsReportsDataGridColumn: CustomTableCol[] = [
  {
    title: "Campaign Name",
    control: "campaignName",
    sortable: true,
    canShowColumn: true,
    class: "col_table",
    style: { width: "350px", left: 0 },
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Product Asin",
    control: "advertisedAsin",
    sortable: true,
    canShowColumn: true,
    class: "col_table",
    style: { width: "150px", left: 350 },
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Product sku",
    control: "advertisedSku",
    sortable: true,
    canShowColumn: true,
    class: "col_table tb_left_border",
    style: { width: "140px", left: 480 },
    isLink: false,
    tooltip: {
      text: "Campaigns",
    },
  },
  {
    title: "Status",
    control: "campaignStatus",
    sortable: true,
    canShowColumn: true,
    style: { width: "140px" },
  },
  {
    title: "Impressions",
    control: "impressions",
    sortable: true,
    canShowColumn: true,
    style: { width: "160px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Impressions"
    },
  },
  {
    title: "Clicks",
    control: "clicks",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Clicks"
    },
  },
  {
    title: "CTR",
    control: "clickThroughRate",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "CTR"
    },
  },
  {
    title: "Spend ",
    control: "cost",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Spend"
    },
  },
  {
    title: "CPC",
    control: "costPerClick",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns CPC"
    },
  },
  {
    title: "Orders",
    control: "purchases30d",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Orders"
    },
  },
  {
    title: "Sales",
    control: "Sales",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Sales"
    },
  },
  {
    title: "ACOS",
    control: "ACOS",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ACOS"
    },
  },
  {
    title: "ROAS",
    control: "ROAS",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ROAS"
    },
  },
  {
    title: "Conversion rate",
    control: "conversion_rate",
    sortable: true,
    canShowColumn: true,
    style: { width: "150px" },
    icon: info,
  },
  {
    title: "Total units",
    control: "unitsSoldClicks1d",
    sortable: true,
    canShowColumn: true,
    style: { width: "130px" },
    icon: info,
  },
];
