import API from "../utils/axios";
const api_url = "/api";

export const GET_AD_GROUP_KEYWORDS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/getKeyword`, data);
};

export const CREATE_AD_GROUP_KEYWORD = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/createKeyword`, data);
};

export const UPDATE_AD_GROUP_KEYWORD = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/updateKeyword`, data);
};
