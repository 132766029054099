import API from "../utils/axios";
const api_url = "/api";

export const GET_CAMPAIGN_AD_GROUPS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/getAdGroup`, data);
};

export const GET_SPECIFIC_AD_GROUP = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/getSpecificAdGroup`, data);
};

export const CREATE_AD_GROUP = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/createAdGroup`, data);
};

export const UPDATE_AD_GROUP_STATUS = (data: any): Promise<any> => {
    return API.post(`${api_url}/userCampaign/updateAdGroupStatus`, data);
};

