import React, { useEffect, useState } from 'react'
import { CustomTable } from '../../../components/custom-table';
import { vendorDataGridColumn } from './vendors-data-grid-columns';
import { toast } from 'react-toastify';
import { GET_USER_PROFILES } from '../../../app/services/userProfiles.service';
import { VENDOR_LINKED } from '../../../app/services/vendor-links.service';

export const VendorsLinks = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSellerId, setSelectedSellerId] = useState<any>([])
    const [vendorsData, setVendorsData] = useState<any>([]);
    const userUuid = localStorage.getItem("user_uuid");

    const vendorsStaticData = {
        total: 10,
        rows: vendorsData
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getUserProfiles();
    }, [])

    const getUserProfiles = () => {
        const preparedData: any = {
            user_uuid: userUuid,
        }
        setLoading(true);
        GET_USER_PROFILES(preparedData).then((res: any) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    if (response?.data?.length > 0) {
                        const profiles_data = response.data[0]?.profiles_data;
                        const vendors = profiles_data.filter((profile: any) => profile.account_info.type === "vendor");
                        const sellers = profiles_data.filter((profile: any) => profile.account_info.type === "seller");
                        for (let i = 0; i < vendors?.length; i++) {
                            const element = vendors[i];
                            element.vendor_name = element?.account_info?.name
                            element.vendor_id = element?.account_info?.id
                            element.link_status = false
                        }
                        const sellerOptions = sellers?.map((seller: any) => ({
                            name: seller.account_info.name,
                            value: seller.profile_id
                        }));
                        sellers.map((column: any) => {
                            if (column.control === "selller") {
                                return {
                                    ...column,
                                    inputConfig: {
                                        ...column.inputConfig,
                                        options: sellerOptions
                                    }
                                };
                            }
                            return column;
                        });
                        vendorDataGridColumn[1].inputConfig.options = sellerOptions
                        setVendorsData([...vendors])
                        setLoading(false);
                    }
                }
            }
        });
    }

    const onActions = (data: any) => {
        if (data?.type === "save") {
            const payload = {
                uuid: data.data.uuid,
                seller_id: selectedSellerId,
                is_linked: true,
                user_uuid: userUuid
            }
            setLoading(true)
            VENDOR_LINKED(payload).then((res) => {
                if (res.data.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        getUserProfiles();
                        toast.success(res.data.message)
                        setLoading(false);
                    }
                }
            });
        }
    }

    const handleSellerChange = (data: any) => {
        setSelectedSellerId(data?.seller_id);
    }

    return (
        <div className='container-fluid'>
            <div className='row fs-5 fw-bold ms-0 my-2'>Vendors Link</div>
            <div>
                {loading &&
                    <div className={`text-center p-5 negative-keyword-spinner-position`}>
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&<CustomTable
                    tableData={vendorsStaticData}
                    TableCols={vendorDataGridColumn}
                    tableWithoutFixedCoulmns={true}
                    toggleStatus={false}
                    tableLoading={!loading}
                    tableFooterpositionTop={false}
                    noDataFoundMesageTitle={''}
                    onActions={(e) => onActions(e)}
                    onSellerChange={(e) => handleSellerChange(e)}
                />
                }
            </div>
        </div>
    )
}
