import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

interface Props {
    onSelectDateRange?: (data: any) => void;
    dates?: any;
    label?: any;
    index?: any;
}
export const ConfigurationDateRangeField = (props: Props) => {
    const datepickerFormRef: any = useRef();
    const currentDate: any = new Date();
    const [start, setStartDate] = useState(props?.dates?.start);
    const [end, setEndDate] = useState(props?.dates?.end);

    useEffect(() => {
        setStartDate(props?.dates?.start);
        setEndDate(props?.dates?.end);
    }, [props?.dates]);

    const handleDateChange = (dates: any, index: number) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            if (start && end) {
                const date_range: any = {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                    index: props?.index
                }
                if (props?.onSelectDateRange) {
                    props?.onSelectDateRange(date_range)
                }
            }
        }
    }

    return (
        <>
            <div className='d-flex my-1'>
                <div className=''>
                    <div ref={datepickerFormRef} className='col-md-12 d-flex custom-button-background rounded-pill fs-13 cursor-pointer text-end text-md-start mb-1 px-2'>
                        <div className='me-1 fs-13 d-flex align-items-center'>Date range - </div>
                        <DatePicker
                            selectsRange
                            className="rounded border-0 fs-13 py-1 custom-button-background"
                            startDate={start}
                            endDate={end}
                            onChange={handleDateChange}
                            maxDate={currentDate}
                            monthsShown={2}
                            isClearble={true}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}
